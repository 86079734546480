import { colors } from 'constants/design';

const Play = ({ color = colors.contrastText, size = 54 }) => (
  <svg
    width={size}
    height={(size * 22) / 20}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.759766 18.862C0.802622 20.7912 2.73009 21.9594 4.41875 21.041L17.9983 13.1407C18.7379 12.7098 19.2398 11.9204 19.2398 10.9952C19.2398 10.0701 18.7379 9.28062 17.9983 8.84976L4.41875 0.960673C2.73009 0.0422174 0.802622 1.19926 0.759766 3.12849V18.862Z"
      fill={color}
    />
  </svg>
);

export default Play;
