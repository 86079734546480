import { Box, Modal as MUIModal, Paper, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { BaseIconButton } from 'components/illume/buttons';
import { Close } from 'components/illume/icons';
import VideoRecorder from 'components/video-recorder';
import { colors, DEFAULT_PALETTE, generateColorFromTheme, PRIMARY_THEME } from 'constants/design';
import { generateBackgroundFromPalette } from 'constants/design/cardTheme';
import { PaletteDTO } from 'types';
import { noop } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';

const useModalStyles = makeStyles(() => ({
  root: {
    outline: 'none',
  },
}));
const usePaperStyles = makeStyles<Theme, { background: string }>((theme) =>
  createStyles({
    root: {
      outline: 0,
      background: ({ background }) => background,
      position: 'relative',
      margin: '0 auto',
      marginTop: theme.spacing(10),
      maxWidth: 1024,
      zIndex: theme.zIndex.modal,
      overflow: 'hidden',
      borderRadius: 16,
    },
  }),
);

interface VideoRecorderModalProps {
  open: boolean;
  onClose: () => any;
  onFinished: (blob: Blob) => any;
  theme: string;
  palette: PaletteDTO;
}
const VideoRecorderModal: React.FC<VideoRecorderModalProps> = ({
  open,
  onClose = noop,
  onFinished = noop,
  theme = PRIMARY_THEME,
  palette = DEFAULT_PALETTE,
}) => {
  const paperClasses = usePaperStyles({
    background: checkFF(FLAGS.NEW_CARD_THEMING)
      ? generateBackgroundFromPalette(palette, theme)
      : generateColorFromTheme(theme),
  });
  const modalClasses = useModalStyles();

  return (
    <MUIModal
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
      classes={modalClasses}
      onClose={onClose}
      open={open}
    >
      <Paper classes={paperClasses}>
        <Box position="absolute" top={20} right={20} zIndex={1}>
          <BaseIconButton
            onClick={onClose}
            icon={() => <Close color={colors.contrastText} size={25} />}
          />
        </Box>
        <VideoRecorder width={1024} onFinished={onFinished} theme={theme} />
      </Paper>
    </MUIModal>
  );
};

export default VideoRecorderModal;
