import { useEffect, useRef } from 'react';

import { noop } from 'utils';

export const useInterval = (ms = 1000, cb = noop) => {
  const savedCallback = useRef(cb);
  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    const interval = setInterval(() => {
      savedCallback.current();
    }, ms);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [ms, cb]);
};
