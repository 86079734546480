/* eslint-disable no-unused-vars */
import * as yup from 'yup';
import { SchemaOf } from 'yup';

// I don't like this hack so much
export function handleChange(value, setColsLength, maxCols, setValue) {
  const len = value.length;
  setValue(value);
  if (len === 0) {
    setColsLength(maxCols);
    return;
  }
  // NOTE: adding one removes some newline quirks
  len <= maxCols ? setColsLength(len + 1) : setColsLength(maxCols + 1);
}

const errorMsgText =
  "notes must have a minimum of 25 characters to ensure that they are heartfelt and authentic. otherwise, write as much as you'd like!";

export const schema: SchemaOf<NoteFormFields> = yup.object().shape({
  message: yup.string().required(errorMsgText).min(25, errorMsgText),
  anonymous: yup.boolean().required(),
  isPublic: yup.boolean().required(),
  signature: yup.string().when('anonymous', {
    is: false,
    then: yup.string().required('a signature is required unless you choose to be anonymous'),
  }),
});

export interface NoteFormFields {
  message: string;
  anonymous: boolean;
  isPublic: boolean;
  signature?: string;
}

export const VIDEO_MAX_SIZE_MB = 200;
export const VIDEO_MAX_DURATION = 15;
