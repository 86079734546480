// util
function setItalic(italic) {
  if (italic) {
    return { fontStyle: 'italic' };
  }
  return { fontStyle: 'normal' };
}

function setTextDecoration(decoration) {
  if (decoration === 'underline') {
    return {
      textDecoration: 'underline',
    };
  }
  return {
    textDecoration: '',
  };
}

// gather all style functions and export as a JS object
export function styleFunctions(italic, underline) {
  return {
    ...setItalic(italic),
    ...setTextDecoration(underline),
  };
}
