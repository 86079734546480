import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { noop } from 'utils';

const useStyles = makeStyles(() => ({
  root: {
    '&:focus': { outline: 'none' },
    '&:hover': { background: 'none' },
    padding: 0,
    minWidth: 30,
  },
}));

const BadgeButton = (props) => {
  const { color = colors.alertRed, classes: propClasses, icon, onClick = noop } = props;
  const classes = useStyles();
  const Icon = icon;
  const isMobile = !useIsDesktop();

  const size = isMobile ? 28 : 36;

  return (
    <Button disableRipple classes={{ ...classes, ...propClasses }} onClick={onClick}>
      <span
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <span style={{ position: 'absolute', zIndex: 1 }}>
          <Icon />
        </span>
        <svg
          width={size}
          height={size}
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.00381752 6.95733C-0.0767366 5.32567 1.12301 3.58808 3.08544 2.26908C5.17985 0.861243 7.50221 0.292298 10.0045 0.454609C11.4476 0.546871 12.8068 0.939836 13.9483 1.88466C14.6904 2.49803 15.2645 3.2515 15.7599 4.0716C16.634 5.51702 17.3658 7.02396 17.7789 8.67271C18.3513 10.9553 17.7926 12.9697 16.3323 14.762C14.8018 16.6396 12.7674 17.593 10.397 17.9074C8.73966 18.1278 7.12515 17.9535 5.55691 17.3726C3.64247 16.6636 2.2679 15.3531 1.40237 13.5318C0.53856 11.7173 0.0672325 9.79864 0.00381752 6.95733Z"
            fill={color}
          />
        </svg>
      </span>
    </Button>
  );
};

export default BadgeButton;
