import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';

import { searchBarStyles } from './SearchBar.styles';

export default function CustomizedInputBase({ value, onChange = (message) => message, onSubmit }) {
  const classes = searchBarStyles();
  function handleChange(e) {
    e.preventDefault();
    onChange(e.target.value);
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search Gifs"
        inputProps={{ 'aria-label': 'search gifs' }}
        onChange={handleChange}
        onSubmit={onSubmit}
        value={value}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
