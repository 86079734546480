import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ExperimentalVerificationProvider from 'components/experimental-verification-provider';
import { Loading } from 'components/Loading';
import { Seo } from 'components/seo';
import IllumeSnackbar from 'components/snackbar';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import {
  CONTRIBUTOR_ADD_NOTE_URL,
  CONTRIBUTOR_CELEBRATE_MORE_OCCASION,
  CONTRIBUTOR_CELEBRATE_MORE_REMIND,
  CONTRIBUTOR_GIFT_URL,
  CONTRIBUTOR_REPLY_URL,
  CONTRIBUTOR_SELECT_THEME_URL,
  CONTRIBUTOR_THANKS_URL,
  CONTRIBUTOR_WELCOME_URL,
  MY_CARDS_URL,
} from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import ScrollToTop from 'hooks/ScrollToTop';

import { default as AddNote } from './AddNote';
import ThanksPage from './archive/Thanks';
import ContributorStore from './Contributor.store';
import ContributorPayForGift from './ContributorGift';
import CelebrateMoreOccasionTestA from './lets-celebrate-more/lets-celebrate-more-A';
import CelebrateMoreRemindTestB from './lets-celebrate-more/lets-celebrate-more-B';
import { default as Reply } from './Reply';
import SelectTheme from './SelectTheme';
import Welcome from './Welcome';

// TODO: handle route redirection
const ContributorNotesRoutes = () => {
  const { stripeStore, routerStore, themepackStore, userProfileStore, authenticationStore } =
    useStores();
  const authenticated = authenticationStore.authenticated;
  const userId = authenticationStore.userId;
  const analytics = useAnalytics();
  const { noteService } = useServices();
  const [contributorStore] = useState(
    () =>
      new ContributorStore(
        routerStore,
        themepackStore,
        userProfileStore,
        authenticationStore,
        noteService,
      ),
  );

  // tracking
  useEffect(() => {
    if (contributorStore.contactMethod) {
      authenticated
        ? analytics.identify(userId!, contributorStore.contactMethod)
        : analytics.identify(contributorStore.contactMethod.email!);

      if (contributorStore.contactMethod.email) {
        analytics.track(EVENT_NAMES.INVITED_CONTRIBUTOR_BY_EMAIL_CONTRIBUTOR.name, {
          contributorEmail: contributorStore.contactMethod.email,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (contributorStore.initializing) {
    return <Loading text="loading card.." />;
  }

  // TODO: conditional redirection for authenticated/non-authenticated user
  if (contributorStore.getCardTask.error || !contributorStore.hasCard) {
    return <Redirect to={MY_CARDS_URL} />;
  }

  const recipientData = contributorStore.recipientData;

  const gift = contributorStore.cardGift || contributorStore.card?.externalGift;
  // we will always have a card since we only reach this if there's no error/loading
  return (
    <Elements stripe={stripeStore.stripe}>
      <Seo noIndex children={undefined} />
      <Switch>
        <ScrollToTop>
          <Route exact path={CONTRIBUTOR_WELCOME_URL}>
            <Welcome store={contributorStore} />
          </Route>
          <Route exact path={CONTRIBUTOR_REPLY_URL}>
            <ExperimentalVerificationProvider>
              <Reply
                contributorStore={contributorStore}
                recipientData={recipientData}
                //@ts-expect-error
                defaultValues={{ email: contributorStore.formStore.userData.email }}
              />
            </ExperimentalVerificationProvider>
          </Route>
          <Route exact path={CONTRIBUTOR_ADD_NOTE_URL}>
            <AddNote store={contributorStore} />
          </Route>
          <Route exact path={CONTRIBUTOR_SELECT_THEME_URL}>
            <SelectTheme store={contributorStore} />
          </Route>
          <Route
            exact
            path={CONTRIBUTOR_GIFT_URL}
            render={() =>
              gift && contributorStore.formStore.rawDollarValue ? (
                <ContributorPayForGift contributorStore={contributorStore} />
              ) : (
                <Redirect
                  to={{
                    pathname: CONTRIBUTOR_WELCOME_URL,
                    search: contributorStore.history.location.search,
                  }}
                />
              )
            }
          />
          <Route
            exact
            path={CONTRIBUTOR_CELEBRATE_MORE_REMIND}
            render={() => {
              return <CelebrateMoreRemindTestB contributorStore={contributorStore} />;
            }}
          />
          <Route
            exact
            path={CONTRIBUTOR_CELEBRATE_MORE_OCCASION}
            render={() => {
              return <CelebrateMoreOccasionTestA contributorStore={contributorStore} />;
            }}
          />
          <Route
            exact
            path={CONTRIBUTOR_THANKS_URL}
            render={() => (
              <ThanksPage
                onNext={() => {
                  contributorStore.pushToNext();
                }}
                recipientData={contributorStore.recipientData || []}
                contributorName={
                  userProfileStore.userProfileDTO?.firstName ||
                  userProfileStore.userProfileDTO?.displayName ||
                  ''
                }
              />
            )}
          />
        </ScrollToTop>
      </Switch>
      <IllumeSnackbar
        open={contributorStore.getCardTask.isExecuting}
        autoHideDuration={3000}
        severity="info"
        message={`updating card..`}
      />
    </Elements>
  );
};

export default observer(ContributorNotesRoutes);
