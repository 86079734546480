import { colors } from 'constants/design';

const CheckMark = ({ color = colors.black100 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill={color}
        d="M14 3a.965.965 0 00-.668.296L6.16 10.568l-3.594-2.82a.96.96 0 00-.963-.133.992.992 0 00-.227 1.691l4.274 3.35a.962.962 0 001.281-.08l7.772-7.882a.992.992 0 00.002-1.415.964.964 0 00-.707-.279z"
      ></path>
    </svg>
  );
};

export default CheckMark;
