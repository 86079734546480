import { css, cx } from '@emotion/css';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { match } from 'ts-pattern';

import defaultProfilePic from 'assets/png/sun.png';
import { colors } from 'constants/design';
import { getProdMarketingSiteUrl, NOTIFICATIONS_URL } from 'constants/strings';
import { useNotificationContext } from 'contexts/notification';
import { useStores } from 'contexts/store';
import { ColorsV2 } from 'domain/theme/colorsV2';
import { mq } from 'infra/emotion/breakpoints';
import { Typography } from 'infra/emotion/typography';
import { Action, Maybe } from 'types';
import { noop } from 'utils';
import { Avatar } from 'views/components-v2/core/avatar';
import Button from 'views/components-v2/core/button';
import { ArrowAndDivider } from 'views/components-v2/core/icons/Logout';
import { IllumeLogo } from 'views/components-v2/core/illume-logo';

import { AnimatedOverlay } from './components/AnimatedOverlay';
import { Menus } from './components/Menus';
import { UserMenu } from './components/UserMenu';
import { Hamburger, HamburgerMenuButton } from './HambugerMenu';
import { PencilIcon } from './icons/PencilIcon';

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active: boolean;
};

function PersonButton(props: Props) {
  const { className, active, ...rest } = props;
  return (
    <button
      className={cx(
        css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
        `,
        className,
      )}
      {...rest}
    >
      <AnimatePresence initial={false}>
        {match(active)
          .with(false, () => (
            <motion.svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <path
                d="M4.5 19.5C4.5 21.1569 5.84315 22.5 7.5 22.5H16.5C18.1569 22.5 19.5 21.1569 19.5 19.5V18.75C19.4984 17.3581 18.9447 16.0237 17.9605 15.0395C16.9763 14.0553 15.6419 13.5016 14.25 13.5H9.75C8.35811 13.5016 7.0237 14.0553 6.03949 15.0395C5.05527 16.0237 4.50163 17.3581 4.5 18.75V19.5Z"
                fill="#655B4F"
              />
              <path
                d="M6.75 6.75C6.75 7.78835 7.05791 8.80339 7.63478 9.66674C8.21166 10.5301 9.0316 11.203 9.99091 11.6004C10.9502 11.9977 12.0058 12.1017 13.0242 11.8991C14.0426 11.6966 14.9781 11.1965 15.7123 10.4623C16.4465 9.72808 16.9466 8.79262 17.1491 7.77422C17.3517 6.75582 17.2477 5.70022 16.8504 4.74091C16.453 3.7816 15.7801 2.96166 14.9167 2.38478C14.0534 1.80791 13.0384 1.5 12 1.5C10.6076 1.5 9.27226 2.05312 8.28769 3.03769C7.30312 4.02226 6.75 5.35761 6.75 6.75Z"
                fill="#655B4F"
              />
            </motion.svg>
          ))
          .with(true, () => (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Hamburger isActive={true} />
            </motion.div>
          ))
          .exhaustive()}
      </AnimatePresence>
    </button>
  );
}

interface TopNavigationProps extends React.HTMLAttributes<HTMLDivElement> {
  containerAs?: React.ElementType;
  // insignificant, only for ui
  hamburgerAsClose?: boolean;
  userIconAsClose?: boolean;

  // important
  overlayUserMenuActive: boolean;
  overlayNavActive: boolean;
  desktopUserMenuActive: boolean;

  onHamburgerClick?: () => void;
  onUserMenuChevronClick: () => void;
  onProfileContainerClick?: () => void;
  onPersonButtonClick?: () => void;

  // closing
  onNavOverlayClose?: () => void;
  onUserOverlayClose?: () => void;

  // data
  menus: IMenu[];

  // actions
  editProfileAction: Action;
  logoutAction: Action;

  user: Maybe<IUser>;
}

export interface IUser {
  name: string;
  subtitle: string;
  avatar: string;
}

const styles = {
  container: css`
    padding: 28px 32px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${ColorsV2.notWhite};
    ${mq.desktopSmall} {
      align-items: flex-start;
      justify-content: flex-start;
    }

    // positioning
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  `,
};

export const TopNavigation = observer(
  ({
    containerAs: Container = 'div',
    // only for ui
    hamburgerAsClose: hamburgerAsClose = false,
    userIconAsClose: userIconAsClose = false,

    // userMenuActive,
    desktopUserMenuActive,
    overlayNavActive,
    overlayUserMenuActive,
    onHamburgerClick,
    onUserMenuChevronClick,
    onProfileContainerClick = onUserMenuChevronClick,
    onPersonButtonClick,
    onNavOverlayClose,
    onUserOverlayClose,
    menus,
    editProfileAction = {
      loading: false,
      onAction: noop,
      text: 'edit profile',
    },
    logoutAction = {
      loading: false,
      onAction: noop,
      text: 'logout',
    },
    ...props
  }: TopNavigationProps) => {
    const notificationStore = useNotificationContext();
    const showNotificationDot = notificationStore.unread > 0;
    const history = useHistory();
    const { userProfileStore } = useStores();

    const user: IUser | undefined =
      userProfileStore.userProfileDTO && userProfileStore.userSummaryDto?.userInfo
        ? {
            avatar: userProfileStore.userProfileDTO.picture || defaultProfilePic,
            name: userProfileStore.whateverNameIsSet || '',
            subtitle: `Beamer Since ${new Date(
              userProfileStore.userSummaryDto.userInfo.joinDate,
            ).toLocaleDateString('en-US', { month: 'short' })} ’${new Date(
              userProfileStore.userSummaryDto.userInfo.joinDate,
            )
              .getDate()
              .toString()
              .padStart(2, '0')}
    `,
          }
        : undefined;

    return (
      <Container className={styles.container} {...props}>
        <div
          className={css`
            display: flex;
            align-items: center;
            ${mq.desktopSmall} {
              display: none;
            }
          `}
        >
          <HamburgerMenuButton isActive={hamburgerAsClose} onClick={onHamburgerClick} />
        </div>
        <IllumeLogo
          logoAs={'a'}
          logoProps={{
            href: getProdMarketingSiteUrl(''),
          }}
        />
        <PersonButton
          active={userIconAsClose}
          onClick={onPersonButtonClick}
          className={css`
            display: flex;
            ${mq.desktopSmall} {
              display: none;
            }
          `}
        />

        {/* DESKTOPS */}
        <Menus
          menus={menus}
          activeIndex={menus.findIndex((menu) => window.location.pathname === menu.link)}
          className={css`
            flex: 1;
            display: none;
            ${mq.desktopSmall} {
              display: flex;
            }
          `}
        />
        <UserMenu
          onNotificationClick={() => history.push(NOTIFICATIONS_URL)}
          showNotificationDot={showNotificationDot}
          logoutAction={logoutAction}
          editProfileAction={editProfileAction}
          user={user}
          className={css`
            display: none;
            ${mq.desktopSmall} {
              display: flex;
            }
          `}
          onChevronClick={onUserMenuChevronClick}
          onProfileContainerClick={onProfileContainerClick}
          active={desktopUserMenuActive}
        />

        {/* MOBILE */}
        <MobileMenuNavOverlay
          menus={menus}
          onNavOverlayClose={onNavOverlayClose}
          overlayNavActive={overlayNavActive}
        />
        <AnimatedOverlay
          isOpen={overlayUserMenuActive}
          onClose={onUserOverlayClose}
          closePosition="top-right"
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 58px 24px 42px 24px;
            ${mq.desktopSmall} {
              display: none;
            }
          `}
        >
          {/* AVATAR  */}
          <AnimatePresence>
            {overlayUserMenuActive && (
              <>
                {user && (
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ duration: 0.2 }}
                    className={css`
                      margin-top: 24px;
                      display: flex;
                      flex-direction: column;
                      row-gap: 4px;
                      align-items: center;
                    `}
                  >
                    <Avatar
                      identifier={'user-profile'}
                      imgUrl={user.avatar}
                      size={110}
                      editable={false}
                      shadow={true}
                    />
                    <h2
                      className={css`
                        ${Typography.HEADING_3}
                        color: ${ColorsV2.gray100};
                      `}
                    >
                      {user.name}
                    </h2>
                    <h3
                      className={css`
                        ${Typography.L_MEDIUM}
                        color: ${ColorsV2.gray80};
                      `}
                    >
                      {user.subtitle}
                    </h3>
                  </motion.div>
                )}
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.2 }}
                  className={css`
                    display: flex;
                    flex-direction: column;
                    row-gap: 16px;
                    width: 100%;
                    & > button {
                      padding: 16px 0;
                    }
                  `}
                >
                  <Button
                    background={ColorsV2.gray90}
                    color={ColorsV2.softGray}
                    outlineColor={'none'}
                    onClick={editProfileAction.onAction}
                    hoverBackground={ColorsV2.gray80}
                    leftIcon={<PencilIcon />}
                  >
                    {editProfileAction.text}
                  </Button>

                  <Button
                    background="transparent"
                    color={ColorsV2.gray80}
                    outlineColor={ColorsV2.ellipse401}
                    hoverBackground={colors.background}
                    hoverOutline={colors.background}
                    hoverColor={ColorsV2.gray80}
                    rightIcon={<ArrowAndDivider />}
                    onClick={logoutAction.onAction}
                  >
                    {logoutAction.text}
                  </Button>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </AnimatedOverlay>
      </Container>
    );
  },
);

interface IMenu {
  link: string;
  label: string;
}

function MobileMenuNavOverlay({
  overlayNavActive,
  onNavOverlayClose,
  menus,
}: {
  overlayNavActive: boolean;
  onNavOverlayClose?: () => void;
  menus: IMenu[];
}) {
  return (
    <AnimatedOverlay
      isOpen={overlayNavActive}
      onClose={onNavOverlayClose}
      closePosition="top-left"
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        ${mq.desktopSmall} {
          display: none;
        }
      `}
    >
      <nav>
        <ul
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 32px;
          `}
        >
          {menus.map((menu) => (
            <li key={menu.link}>
              <Link
                className={css`
                  ${Typography.HEADING_2}
                `}
                onClick={onNavOverlayClose}
                to={menu.link}
              >
                {menu.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </AnimatedOverlay>
  );
}
