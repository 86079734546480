import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

import TextInput from 'components/illume/text-inputs/TextInput';
import Text from 'components/illume/text/Text';
import { colors, rem } from 'constants/design';
import { useClipboardCopy } from 'hooks';
import { getMobileOperatingSystem } from 'utils';
import { shareLink } from 'utils/shareLink';

import { Copy, Share } from '../icons';

const copiedStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: colors.primary,
    borderRadius: 8,
    '& input': {
      width: '75%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: rem[875],
      [theme.breakpoints.up('sm')]: {
        fontSize: rem[1000],
      },
    },
    '& label + .MuiInput-formControl': {
      marginTop: 12,
    },
    cursor: 'pointer',
  },
  label: {},
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    background: colors.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    flexFlow: 'nowrap',
    alignContent: 'center',
  },
  icon: {
    margin: '0 8px',
    width: 24,
    height: 24,
  },
}));

const transitionVariant = {
  initial: {
    width: 40,
    borderRadius: '0px 8px 8px 0px',
    justifyContent: 'flex-start',
  },
  in: {
    width: '100%',
    borderRadius: '8px 8px 8px 8px',
    justifyContent: 'center',
    transition: { ease: 'anticipate', duration: 0.6, justifyContent: { delay: 0.3 } },
  },
  out: {
    width: 40,
    borderRadius: '0px 8px 8px 0px',
    justifyContent: 'flex-start',
    transition: { ease: 'anticipate', duration: 0.6, justifyContent: { delay: 0.3 } },
  },
};

export const CopyLink: React.FunctionComponent<any> = ({
  link,
  background = colors.background,
  color = colors.gray40,
  label,
  onClick = () => {},
  spelledNames: spelledNames,
  labelTextProps = { color: colors.gray40 },
  compact,
  disabled,
  containerProps,
  height,
  ...props
}) => {
  const [copied, copy] = useClipboardCopy();
  const classes = copiedStyles({ compact });
  const mobileOS = getMobileOperatingSystem();
  const iOS = mobileOS === 'iOS';
  const Icon = iOS ? Share : Copy;

  if (!link) return <div>error generating link</div>;

  const handleClick = () => {
    if (iOS) {
      if (disabled) {
        return;
      }
      shareLink({
        url: link,
      });
    } else {
      if (disabled) {
        return onClick();
      }
      copy(link);
    }
    onClick();
  };

  return (
    <div {...containerProps}>
      <Text fontSize={{ mobile: 14, desktop: 16 }} className={classes.label} {...labelTextProps}>
        {label}
      </Text>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        data-testid="copy-component-cta"
        className={classes.root}
        onClick={handleClick}
      >
        <TextInput
          style={{ color, height }}
          compact={compact}
          background={background}
          value={link}
          disabled={true}
          {...props}
        />
        <motion.div
          className={classes.iconContainer}
          initial="initial"
          animate={copied ? 'in' : 'out'}
          variants={transitionVariant}
        >
          <div className={classes.icon}>
            <Icon size={undefined} props={undefined} />
          </div>
          <Text color={colors.contrastText} italic>
            copied to clipboard
          </Text>
        </motion.div>
      </Grid>
    </div>
  );
};

export default CopyLink;
