import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { makeAutoObservable, reaction } from 'mobx';

import { NoteFormRawValues } from 'components/reusable-forms/note-form/NoteForm';
import { DEFAULT_PALETTE } from 'constants/design';
import ThemepacksStore from 'contexts/card/store';
import { CreateNoteOptions, INoteService } from 'domain/interfaces/INoteService';
import { track } from 'services/analytics';
import { ContributorCardDTO, InitiatorCardViewDTO, PaletteDTO } from 'types';
import logger from 'utils/logger';

type FormData = {
  note?: NoteFormRawValues;
  theme?: string;
  palette: PaletteDTO;
};

const initialFormData = {
  note: undefined,
  palette: DEFAULT_PALETTE,
};

export class AddNoteStore {
  formData: FormData = initialFormData;
  /** @deprecated - card should be set in constructor */
  setCard(card: InitiatorCardViewDTO | ContributorCardDTO) {
    this.card = card;
  }
  patchNote(note: NoteFormRawValues) {
    this.formData.note = note;
  }
  setPalette(palette: PaletteDTO, theme?: string) {
    this.formData.palette = palette;
    this.formData.theme = theme;
  }
  syncTask = new ObservablePromise(this.noteService.createNote);
  get serverResult() {
    return this.syncTask.getResult();
  }
  constructor(
    private themepackStore: ThemepacksStore,
    defaultValues: Partial<typeof initialFormData>,
    private noteService: INoteService,
    public card?: InitiatorCardViewDTO | ContributorCardDTO,
  ) {
    makeAutoObservable(this);
    if (defaultValues.note) {
      this.patchNote(defaultValues.note);
    }
    if (defaultValues.palette) {
      this.setPalette(defaultValues.palette);
    }
    reaction(
      () => this.serverResult,
      (result) => {
        logger.log('server result of note creation', result);

        if (this.dto) track('add note', this.dto);
      },
    );
  }

  get dto() {
    if (!this.formData.note || !this.card) {
      return undefined;
    }

    const { signature, message, isPublic, photos, videos, gifs } = this.formData.note;
    const { theme, palette } = this.formData;

    const photoUuids = photos?.map((photo) => photo.uuid || photo.photoCode);
    const videoCodes = videos?.map((video) => video.videoCode);
    const gifCodes = gifs?.map((gif) => gif.gifCode);

    const fallBackPalette =
      this.themepackStore.getThemepack(this.card.themepack)?.palettes[0] || DEFAULT_PALETTE;

    const payload: CreateNoteOptions = {
      message,
      signature,
      theme,
      // palette: checkFF('NEW_CARD_THEMING')
      //   ? palette.paletteCode
      //   : generatePaletteCodeFromTheme(theme),
      palette: palette.paletteCode || fallBackPalette.paletteCode,
      photos: photoUuids,
      videos: videoCodes,
      gifs: gifCodes,
      isPublic,
    };

    return payload;
  }

  syncNote() {
    if (!this.dto || !this.card) {
      return Promise.reject(new Error('No note or card'));
    }

    const { inviteCode } = this.card;

    return this.syncTask.execute(inviteCode, this.dto).then((res) => res);
  }
}
