import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/illume/buttons';
import { BackArrow } from 'components/illume/icons/BackArrow';
import { Eye } from 'components/illume/icons/Eye';
import { Text } from 'components/illume/text';
import { TextBox } from 'components/illume/text-box';
import { colors, spacing } from 'constants/design';
import { CARD_STATUS, MY_CARDS_URL, RECEIVE_URL } from 'constants/strings';

const useStyles = makeStyles(() => {
  return createStyles({
    replyListCard: {
      maxWidth: '100%',
      whiteSpace: 'pre-line',
      overflow: 'scroll',
    },
  });
});

export const RecipientContent = ({
  card: cardV2,
  autoResizeBox,
  showViewCardButton = true,
  onSendReplyAll,
}) => {
  const { sendCardContext, id: sendCode, status, replies = [], cardReplyContext } = cardV2 || {};
  const history = useHistory();
  const hasReply = replies?.length > 0;
  const introMessage = sendCardContext?.introMessage;
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" item spacing={4}>
        {/* Input group */}
        <Grid item container direction="column" spacing={2}>
          {introMessage && (
            <Grid item>
              <TextBox
                className="fs-mask"
                minHeight={!autoResizeBox && spacing[10] * 3}
                text={introMessage}
                label={'Intro Message'}
                color={colors.gray100}
                dark
              />
            </Grid>
          )}
          {hasReply && (
            <Grid item container direction="column">
              {replies.map(({ message, status, replier, id }) => {
                const isMyReply = id === cardReplyContext?.id;
                return (
                  <Grid item>
                    <TextBox
                      className={cx('fs-mask customIllumeScrollbar-gray', classes.replyListCard)}
                      minHeight={64}
                      text={message}
                      label={isMyReply ? 'Your Reply' : `${replier}'s Reply`}
                      shadow={status === 'Unread'}
                      color={colors.gray100}
                      dark
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
        {/** Button */}
        <Grid item container direction="column" spacing={2}>
          {showViewCardButton && (
            <Grid item>
              <Button
                startIcon={<Eye color={colors.contrastText} />}
                fullWidth
                onClick={() =>
                  history.push({
                    pathname: RECEIVE_URL,
                    search: qs.stringify({ c: sendCode }),
                    state: { from: MY_CARDS_URL },
                  })
                }
              >
                view card
              </Button>
            </Grid>
          )}
          {status === CARD_STATUS.READ && !hasReply && (
            <Grid item>
              <Button
                startIcon={<BackArrow style={{ marginRight: 4 }} color={colors.contrastText} />}
                fullWidth
                onClick={onSendReplyAll}
                customcolor="green"
              >
                <Text
                  color={colors.contrastText}
                  size={{ desktop: '16px', mobile: '14px' }}
                  fontWeight={500}
                >
                  send reply-all
                </Text>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
