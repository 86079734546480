import { useEffect } from 'react';

import { Grid, Link } from '@material-ui/core';

import Button from 'components/illume/buttons/Button';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { rem } from 'constants/design';
import { MY_CARDS_URL } from 'constants/strings';
import logger from 'utils/logger';
import './ErrorFallback.scss';

export const SentryErrorFallback = ({
  error,
  myCardsNavEnabled = true,
  componentStack,
  resetError,
}) => {
  const errMessage = error.message || 'test';
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (errMessage && chunkFailedMessage.test(errMessage)) {
      logger.error('successfully caught Loading chunk error!');
      // window.location.reload();
    } else {
      logger.error(`SentryErrorFallback - ${errMessage}`);
    }
  }, [errMessage]);

  return (
    <IllumeLayout
      hideHamburger
      disableAppbar
      contentProps={{ justifyContent: 'center', alignItems: 'center', style: { flex: 1 } }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={'sentry-error-fallback'}
      >
        <Grid container item direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Text fontWeight={900} fontSize={{ mobile: rem[1375], desktop: rem[2250] }}>
              OOPS!
            </Text>
          </Grid>
          <Grid item>
            <Text fontWeight={300} fontSize={rem[1000]} align="center">
              Something went wrong. Our team has been notified. If you would like immediate
              assistance, please contact{' '}
              <Link
                id="need_help"
                href="mailto:support@illumenotes.com"
                rel="noreferrer"
                target="_blank"
                underline="always"
                color="inherit"
              >
                illume support
              </Link>
              .
            </Text>
          </Grid>
          {myCardsNavEnabled && (
            <Grid item>
              <Button onClick={() => (window.location.href = MY_CARDS_URL)} className={'button'}>
                back to my cards
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};
