import { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import { GifDisplay } from 'components/illume/gif-display';
import PhotoDisplay from 'components/illume/photos/PhotoDisplay';
import { Surface } from 'components/illume/surface'; // should be on components/illume
import { HiddenWords, Text } from 'components/illume/text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { rem, colors } from 'constants/design';
import { useStores } from 'contexts/store';
import useClipPathID from 'hooks/useClipPathID';
import { NoteDTO } from 'types';
import { noop } from 'utils';

import { noteStyles } from './Note.styles';

export const HiddenWrapper: React.FC<any> = ({
  recipientName,
  enableTooltip = true,
  children,
  redacted,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const redactedInfo = `In order to protect the privacy of each note, you're seeing a redacted version of this
  note. Trust us, the messages will all be there when you share it with ${recipientName}!`;
  const TOOLTIP_TIMEOUT = 3000;

  useEffect(() => {
    if (showTooltip && enableTooltip) {
      const turnOffTooltip = setTimeout(() => {
        setShowTooltip(false);
      }, TOOLTIP_TIMEOUT);
      return () => {
        clearTimeout(turnOffTooltip);
      };
    }
  }, [showTooltip, enableTooltip]);

  return (
    <Tooltip
      variant="other"
      text={redactedInfo}
      showTooltip={redacted && showTooltip && enableTooltip}
      placement="top"
      widthArrow={'10%'}
      mb={undefined}
      maxWidth={undefined}
    >
      <div
        onClick={() => setShowTooltip((prev) => !prev)}
        onPointerEnter={() => setShowTooltip(true)}
        onPointerLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
    </Tooltip>
  );
};

// TODO: add typing
export interface INoteProps {
  note: NoteDTO; // this shouldn't be partial tough
  variant?: 'small' | 'medium' | 'large'; // this doesn't help. should be more flexible
  onViewNote?: () => any;
  recipientName?: string;
  fixedSize?: boolean;
  enableTooltip?: boolean;
  size?: number;
}

/**
 * @deprecated - use NoteThumbnail
 */
export const Note: React.FC<INoteProps> = observer(
  ({
    note = {},
    onViewNote = noop,
    variant,
    recipientName,
    fixedSize = false,
    enableTooltip = true,
    size,
  }) => {
    const {
      message,
      signature,
      palette: paletteCode,
      photos = [],
      videos = [],
      gifs = [],
      redacted,
      length,
    } = note as NoteDTO;

    const { themepackStore } = useStores();

    const palette = themepackStore.getPalette(paletteCode);

    const clickable = onViewNote !== noop;
    const noteClasses = noteStyles({ variant, clickable, size, fixedSize });
    const mediaPreviewSize = 80;

    const contrastColor = colors.contrastText;

    // const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    // ? generateContrastFromPalette(palette, theme)
    // : generateContrastFromTheme(theme);

    const mainColor = palette.generateBackground();
    const [photo, video, gif] = [photos[0], videos[0], gifs[0]];
    const isMediaExists = photo || video || gif;
    const photoClipPathId = useClipPathID();
    const videoClipPathId = useClipPathID();

    return (
      <Surface background={mainColor} rounded={['top', 'bottom']} className={noteClasses.root}>
        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="space-between"
          onClick={onViewNote}
        >
          {isMediaExists ? (
            <HiddenWrapper
              recipientName={recipientName}
              redacted={redacted}
              enableTooltip={enableTooltip}
            >
              <Grid item className={noteClasses.media}>
                {photo ? (
                  <PhotoDisplay
                    imgUrl={photo?.url || photo?.deliveryUrl}
                    size={mediaPreviewSize}
                    color={contrastColor}
                    redacted={redacted}
                    clipPathID={photoClipPathId}
                  />
                ) : video ? (
                  <VideoDisplay
                    vidUrl={video?.url || video?.deliveryUrl}
                    size={mediaPreviewSize}
                    color={contrastColor}
                    redacted={redacted}
                    clipPathId={videoClipPathId}
                  />
                ) : gif ? (
                  <GifDisplay
                    giphyCode={gif.giphyCode}
                    size={mediaPreviewSize}
                    color={contrastColor}
                    redacted={redacted}
                    hideBanner
                  />
                ) : null}
              </Grid>
            </HiddenWrapper>
          ) : (
            <Grid item className={noteClasses.message}>
              {redacted ? (
                <HiddenWrapper
                  recipientName={recipientName}
                  redacted={redacted}
                  enableTooltip={enableTooltip}
                >
                  <HiddenWords charLength={length} isSmall />
                </HiddenWrapper>
              ) : (
                <Text
                  className="fs-mask"
                  wordBreak="break-word"
                  fontSize={{ mobile: rem[1000], desktop: rem[1125] }}
                  color={contrastColor}
                  fontWeight={700}
                >
                  {message.split('\n').map((item, key) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
                </Text>
              )}
            </Grid>
          )}

          <Grid item className={noteClasses.signature}>
            <Text
              wordBreak="break-word"
              fontSize={{ mobile: rem[1000], desktop: rem[1125] }}
              fontWeight={900}
              data-testid="sender-text"
            >
              {signature || 'anonymous'}
            </Text>
          </Grid>
        </Grid>
      </Surface>
    );
  },
);
