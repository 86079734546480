export const menuItemContainerVariants = {
  visible: {
    display: 'block',
    transition: { staggerChildren: 0.1, delayChildren: 0.35 },
  },
  hidden: {
    display: 'none',
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const styleMenuItemVariants = {
  visible: {
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
