import { createStyles, makeStyles } from '@material-ui/core/styles';

const desktop = {
  small: 225,
  medium: 255,
  large: 300,
};

const mobile = {
  small: 155,
  large: 255,
};

export const noteStyles = makeStyles((theme) => {
  const getDimension = (props) => {
    if (props.fixedSize) {
      return { minHeight: props.size, minWidth: props.size };
    } else {
      return {
        minWidth: ({ variant }) => (variant ? mobile[variant] : '150px'),
        minHeight: ({ variant }) => (variant ? mobile[variant] : '150px'),
        height: '100%',
        [theme.breakpoints.up('md')]: {
          minWidth: ({ variant }) => (variant ? desktop[variant] : '225px'),
          minHeight: ({ variant }) => (variant ? desktop[variant] : '225px'),
        },
      };
    }
  };
  return createStyles({
    root: (props) => ({
      cursor: props.clickable && 'pointer',
      padding: theme.spacing(2.7),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...getDimension(props),
    }),
    message: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      height: ({ variant }) => (variant === 'large' ? '9.5em' : '5.5em'),
      [theme.breakpoints.up('md')]: {
        height: ({ variant }) => (variant === 'medium' ? '9.5em' : '9em'),
        '-webkit-line-clamp': 4,
      },
    },
    signature: {
      marginTop: '0.5em',
      [theme.breakpoints.up('md')]: {
        marginTop: '1em',
      },
    },
    media: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      height: ({ variant }) => (variant === 'large' ? '10em' : '6em'),
      [theme.breakpoints.up('md')]: {
        height: ({ variant }) => (variant === 'medium' ? '9.5em' : '9em'),
      },
    },
  });
});
