import { forwardRef } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

import { LightBulb } from '../icons/LightBulb';

const useStyles = makeStyles(() => ({
  root: {
    '&:focus': { outline: 'none' },
    '&:hover': { background: 'none' },
    padding: 0,
    minWidth: 30,
  },
}));

export interface LightBulbProps {
  onClick: () => any;
  classes?: any;
  color?: string;
  size?: number;
  onMouseEnter?: () => any;
  containerClassName?: string;
}

// forward the ref for Tolltip purpose
const LightBulbButton = forwardRef<any, LightBulbProps>((props, ref) => {
  const {
    onClick,
    classes: propClasses,
    color,
    size = 32,
    onMouseEnter,
    containerClassName,
  } = props;
  const classes = useStyles();
  return (
    <motion.div
      ref={ref}
      className={containerClassName}
      onMouseEnter={onMouseEnter}
      whileTap={{ scale: 0.8 }}
    >
      <Button disableRipple classes={{ ...classes, ...propClasses }} onClick={onClick}>
        <LightBulb size={size} color={color} />
      </Button>
    </motion.div>
  );
});

export default LightBulbButton;
