import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import NumericVerify from 'components/illume/text-inputs/NumericVerify';
import Text from 'components/illume/text/Text';
import { colors, rem } from 'constants/design';
import { Theme } from 'infra/mui-stylesheet';
import { noop } from 'utils';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: '100%',
    maxWidth: 700,
    margin: '0 auto',
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    paddingBottom: theme.spacing(6.5),
  },
  body: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('ip5')]: {
      marginTop: theme.spacing(10),
    },
  },
  progressCircles: {},
}));

const VerificationForm = ({
  title,
  subtitle,
  onResend = noop,
  onVerify = (x: any) => x,
  onChange = noop,
  error,
  sendDigitsError,
  expiration,
  verifying,
  background,
  color = colors.contrastText,
  titleColor = colors.contrastText,
  customButtonColor,
  alignItems = 'center',
}: any) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(376));

  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems={isDesktop ? alignItems : 'flex-start'}
        className={classes.body}
      >
        <Grid item>
          <Text
            fontWeight={900}
            color={titleColor}
            fontSize={{ mobile: rem[2250], desktop: rem[3000] }}
          >
            {title}
          </Text>
        </Grid>
        <Grid item>
          <Text
            fontSize={{ mobile: rem[1000], desktop: rem[1250] }}
            align={isDesktop ? 'center' : 'left'}
            color={titleColor}
            fontWeight={400}
          >
            {subtitle}
          </Text>
        </Grid>
        <Grid item>
          <NumericVerify
            customButtonColor={customButtonColor}
            color={color}
            background={background}
            onResend={onResend}
            onVerify={onVerify}
            expiration={expiration}
            error={error}
            sendDigitsError={sendDigitsError}
            verifying={verifying}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerificationForm;
