import { alpha, makeStyles } from '@material-ui/core/styles';

import { colors, rem } from 'constants/design';

// utils (keep this kind of logic out of the styles themselves for readability)
function setItalic(italic) {
  return italic ? 'italic' : 'normal';
}

function setBackgroundColor(color, theme) {
  if (color === 'primaryShaded') {
    return theme.palette.illume.primaryShaded;
  }
  if (color === 'gray' || color === 'grey') {
    return theme.palette.gray;
  }
  if (color === 'green') {
    return theme.palette.green;
  }
  if (color === 'white') {
    return theme.palette.white;
  }
  if (color === 'darkGray' || color === 'darkGrey') {
    return theme.palette.darkGray;
  }
  if (color === 'background') {
    return { main: colors.background, dark: colors.backgroundGrayDark };
  }
  return theme.palette.primary;
}

function setBorderRadius(props) {
  return props.rounded ? 500 : props.borderRadius;
}

// styles
export const buttonStyles = makeStyles((theme) => ({
  root: {
    height: ({ size, height }) => (size === 'compact' ? rem[2000] : height ? height : rem[2500]),
    fontSize: rem[875],
    fontWeight: 500,
    fontStyle: (props) => setItalic(props.italic),
    backgroundColor: (props) =>
      props.variant === 'outlined' ? 'transparent' : setBackgroundColor(props.color, theme).main,
    borderRadius: (props) => setBorderRadius(props),
    color: (props) =>
      props.variant === 'contained'
        ? theme.palette.primary.contrastText
        : setBackgroundColor(props.color, theme).main,
    border: (props) =>
      props.variant === 'outlined'
        ? `1px solid ${setBackgroundColor(props.color, theme).main}`
        : 'none',
    // Icon spacing as per designs
    '& svg': {
      marginRight: 6,
    },
    // Force full width for mobile
    [theme.breakpoints.down(700)]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      height: ({ size }) => (size === 'compact' ? rem[2500] : rem[2800]),
      fontSize: rem[1000],
    },
    '&:hover': {
      backgroundColor: (props) =>
        props.variant === 'contained'
          ? setBackgroundColor(props.color, theme).dark
          : alpha(setBackgroundColor(props.color, theme).main, 0.05),
      border: (props) =>
        props.variant === 'outlined'
          ? `1px solid ${setBackgroundColor(props.color, theme).dark}`
          : undefined,
    },
    '&:disabled': {
      backgroundColor: (props) =>
        props.variant === 'contained'
          ? alpha(setBackgroundColor(props.color, theme).main, 0.5)
          : undefined,
      color: theme.palette.primary.contrastText,
    },
  },
  disabled: {},
}));
