export const getVideoDuration = (file: File) =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const reader = new FileReader();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    video.addEventListener('loadedmetadata', () => {
      resolve(video.duration);
    });

    video.addEventListener('error', () => {
      reject('unable to get video duration');
    });

    reader.addEventListener('loadend', function () {
      // reader.result now contains a `blob:` url. This seems like a
      // nonsensical collection of characters, but the <video> element
      // should be able to play it.
      video.src = reader.result as string;
      // After we assigned it to the `src` of the <video>, it will be
      // act like any other video source, and will trigger the related
      // events such as `loadedmetadata`, `canplay`, etc...
    });

    // Instruct the reader to read the file as a url. When its done
    // it will trigger the `loadend` event handler above.
    reader.readAsDataURL(file);
  });
