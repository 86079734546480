import { useEffect, useMemo, useState } from 'react';

import { ShopifyShippingInfo } from '@illume/shared';
import { Observer, observer } from 'mobx-react';
import qs from 'query-string';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import ExperimentalVerificationProvider from 'components/experimental-verification-provider';
import { Loading } from 'components/Loading';
import {
  CoverAndNotesToPrint,
  usePrintRef,
} from 'components/pdf-pages-template/recipient-downloads/All';
import { Seo } from 'components/seo';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import {
  MY_CARDS_URL,
  RECEIVE_CELEBRATORY_URL,
  RECEIVE_READ_URL,
  RECEIVE_SAVE_NOTES_URL,
  RECEIVE_SHIP_TRACK_URL,
  RECEIVE_SHIP_URL,
  RECEIVE_URL,
  RECEIVE_WELCOME_URL,
} from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { Note } from 'domain/entities/initiator-card/InitiatorCard';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import ScrollToTop from 'hooks/ScrollToTop';
import { Error404 } from 'pages/illume/404';
import logger from 'utils/logger';

import { default as Celebratory } from './Celebratory';
import GiftShipmentDetails from './GiftShipmentDetails';
import { default as OpenNote } from './OpenNote';
import { default as ReceivedNote } from './ReceivedNote';
import { default as SaveNotesForm } from './SaveNotesForm';
import ShippingFormEmailOnly, { ShopifyShippingInfoOnlyEmail } from './ShippingFormEmailOnly';
import ShippingFormWithFields from './ShippingFormWithFields';
import { RecipientStore } from './store';
import { default as Welcome } from './Welcome';

const ReceiveNotesRoutes = () => {
  const location = useLocation();
  const analytics = useAnalytics();
  const { routerStore, authenticationStore } = useStores();
  const { authenticated, userId } = authenticationStore;
  const { shippingService } = useServices();
  const [recipientStore] = useState(() => new RecipientStore(routerStore, shippingService));
  const spelledNames = recipientStore.spelledNames;
  const { handlePrint: print, printRef } = usePrintRef();
  // the use case was card details => here
  // but there's card details v2 now
  // const { c: sendCode, email } = qs.parse(location.search) || {};

  const email = qs.parse(location.search).email as string | undefined;

  function handlePrint() {
    print();
    analytics.track(EVENT_NAMES.RECIPIENT_OPEN_THE_CARD_DOWNLOAD_PDF.name, { email });
  }

  useEffect(() => {
    if (recipientStore.contactMethod) {
      if (authenticated) {
        if (userId) {
          analytics.identify(userId, recipientStore.contactMethod);
        }
      } else {
        if (recipientStore.contactMethod.email) {
          analytics.identify(recipientStore.contactMethod.email);
        }
      }
    }
  }, [analytics, authenticated, recipientStore.contactMethod, userId]);

  const getFirstName = (fullName: string) => {
    const name = fullName.split(' ');
    return name[0];
  };

  const notes = useMemo(
    () => recipientStore.card?.notes.map((dto) => Note.fromDto(dto)),
    [recipientStore.card],
  );

  const [emailverify, setEmailVerify] = useState(email);

  const sendCode = recipientStore._recipientCardDto?.id;

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();

  const onSubmit = async (formState: ShopifyShippingInfoOnlyEmail | ShopifyShippingInfo) => {
    if (!sendCode) {
      logger.error('sendCode is not defined');
      return enqueueErrorSnackbar('something went wrong..');
    }
    return recipientStore.saveShippingInfoTask
      .execute(sendCode, formState)
      .then(() => enqueueSuccessSnackbar('Gift Shipped'))
      .then(() => recipientStore.pushToNext())
      .catch((e: Error) => {
        // TODO: API issue returns object instead a string for `message`
        //@ts-expect-error
        const message = typeof e.message === 'object' ? e.message?.message : e.message;
        return enqueueErrorSnackbar(message || 'something went wrong..');
      });
  };

  if (recipientStore.initializing) {
    return <Loading text="loading card.." />;
  }

  if (recipientStore.initError) {
    return <Redirect to={MY_CARDS_URL} />;
  }

  return (
    <>
      <Seo noIndex />
      <Switch>
        <ScrollToTop>
          <Route
            exact
            path={RECEIVE_URL}
            render={() => (
              <ExperimentalVerificationProvider>
                <OpenNote store={recipientStore} onDownload={handlePrint} />
              </ExperimentalVerificationProvider>
            )}
          />
          <Route
            path={RECEIVE_READ_URL}
            render={() => (
              <Observer>
                {() => {
                  if (!notes) {
                    return null;
                  }
                  return (
                    <ReceivedNote onDownload={handlePrint} notes={notes} name={spelledNames} />
                  );
                }}
              </Observer>
            )}
          />
          <Route
            path={RECEIVE_SAVE_NOTES_URL}
            render={() => (
              <ExperimentalVerificationProvider>
                <SaveNotesForm
                  defaultValues={{ email: emailverify || '', name: getFirstName(spelledNames) }}
                  setEmailVerify={(email) => setEmailVerify(email)}
                  recipientName={getFirstName(spelledNames)}
                />
              </ExperimentalVerificationProvider>
            )}
          />
          <Route path={RECEIVE_WELCOME_URL} component={Welcome} />
          <Route path={RECEIVE_SHIP_URL}>
            {recipientStore.giftMerchantProduct ? (
              recipientStore.giftMerchantProduct.isDigitalGiftcard ? (
                <ShippingFormEmailOnly
                  data={ShippingFormEmailOnly.productDataFromGiftMerchantProduct(
                    recipientStore.giftMerchantProduct,
                  )}
                  onGoBack={recipientStore.goBack}
                  onSubmitted={onSubmit}
                />
              ) : (
                <ShippingFormWithFields
                  onGoBack={recipientStore.goBack}
                  onSubmit={onSubmit}
                  data={ShippingFormWithFields.dataFromGiftMerchantProduct(
                    recipientStore.giftMerchantProduct,
                  )}
                />
              )
            ) : recipientStore.externalGift ? (
              recipientStore.externalGift.isDigitalGiftCard ? (
                <ShippingFormEmailOnly
                  data={ShippingFormEmailOnly.productDataFromExternalGift(
                    recipientStore.externalGift,
                  )}
                  onGoBack={recipientStore.goBack}
                  onSubmitted={onSubmit}
                />
              ) : (
                <ShippingFormWithFields
                  onGoBack={recipientStore.goBack}
                  onSubmit={onSubmit}
                  data={ShippingFormWithFields.dataFromExternalGift(recipientStore.externalGift)}
                />
              )
            ) : (
              <Redirect to={{ pathname: RECEIVE_URL, search: location.search }} />
            )}
          </Route>
          <Route
            path={RECEIVE_CELEBRATORY_URL}
            render={() => <Celebratory store={recipientStore} />}
          />
          <Route
            path={RECEIVE_SHIP_TRACK_URL}
            render={() => <GiftShipmentDetails store={recipientStore} />}
          />
        </ScrollToTop>
        <Route component={Error404} />
      </Switch>

      {recipientStore._recipientCardDto && (
        <CoverAndNotesToPrint
          data={{
            card: recipientStore._recipientCardDto,
          }}
          ref={printRef}
        />
      )}
    </>
  );
};

export default observer(ReceiveNotesRoutes);
