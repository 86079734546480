import { GoogleIcon } from 'components/illume/icons';
import Text from 'components/illume/text/Text';
import { rem, colors } from 'constants/design';

import Button from './Button';
const GoogleButton = ({ onClick, disabled, ...props }) => {
  return (
    //need a better svg fot google icon
    <Button
      customcolor={'white'}
      onClick={onClick}
      startIcon={<GoogleIcon size={16} />}
      disabled={disabled}
      {...props}
    >
      <Text
        color={disabled ? colors.gray40 : colors.darkText}
        fontSize={rem[1000]}
        fontWeight={500}
      >
        Sign in with Google
      </Text>
    </Button>
  );
};

export default GoogleButton;
