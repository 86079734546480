import { useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { noop } from 'lodash';
import { observer } from 'mobx-react';
import { useAsyncCallback } from 'react-async-hook';
import { useRouteMatch } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { BaseIconButton } from 'components/illume/buttons';
import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import { CloseCard } from 'components/illume/close-card/CloseCard';
import { GroupGiftModal } from 'components/illume/group-gift-modal';
import { GroupGiftModalDTOMapper } from 'components/illume/group-gift-modal/GroupGiftModalDTOMapper';
import { Info } from 'components/illume/icons';
import Arrow from 'components/illume/icons/Arrow';
import { RectangularBoxWithPlusButton } from 'components/illume/new-card';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { PreviewModal } from 'components/preview-modal';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useStores } from 'contexts/store';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import analytics from 'services/analytics';
import { ContributorCardDTO, NoteDTO } from 'types';
import { isGift } from 'types/guard';
import { spellNames } from 'utils/string';

import SentCardDetailsModal from '../../card-details-v2-sent/sent-card-details-modal/SentCardDetailsModal';
import { CardDetailsStore } from '../../CardDetaillsV2PendingStore';
import GiftPaymentFromCardDetailsStore from '../../CardDetailsV2.store';
import { NoteThubmnail } from '../../note-thumbnail';
import ContributeCardGift, {
  fromExternalGift,
  fromGiftV2Dto,
} from '../initiator/components/ContributeCardGift';
import { useRedesignedStyles } from './cardDetailsV2.styles';

interface ICardDetailsPendingProps {
  store: CardDetailsStore;
  giftPaymentFromCardDetailsStore: GiftPaymentFromCardDetailsStore;
  card: ContributorCardDTO;
  onCreateNote: () => void;
}

const ContributorCardDetailsV2Pending: React.FC<ICardDetailsPendingProps> = ({
  card: cardFromProps,
  store,
  giftPaymentFromCardDetailsStore,
  onCreateNote,
}) => {
  const history = useIllumeHistory();
  const { url } = useRouteMatch();
  const { userProfileStore } = useStores();
  const [showModalCardDetail, setShowModalCardDetail] = useState(false);
  const [groupGiftModalVisible, setGroupGiftModalVisible] = useState<boolean>(false);
  const [displayedNote, setDisplayedNote] = useState<NoteDTO | null>(null);
  const theme = useTheme();
  const card = cardFromProps;
  const externalGift = card.externalGift && ExternalGift.fromDto(card.externalGift);
  const moreThanSmallDevice = useMediaQuery(theme.breakpoints.up('sm'));
  const { recipientData, notes, giftV2 } = card;
  const spelledRecipientNames = spellNames(recipientData);
  const desktop = useIsDesktop();
  const classes = useRedesignedStyles();
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  function handleGoBack() {
    history.back();
  }

  const deleteNoteAsync = useAsyncCallback(store.deleteCardNoteTask, {
    onError: () => enqueueErrorSnackbar('oops! something went wrong when deleting the card'),
  });

  return (
    <>
      <IllumeLayout
        contentProps={{
          justifyContent: 'center',
        }}
      >
        <div className={classes.container}>
          <div className="header">
            <div className="title">
              <Text
                maxWidth={moreThanSmallDevice ? undefined : 200}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                align="center"
                fontWeight={900}
                fontSize={{ mobile: 22, desktop: 36 }}
              >
                {`${spelledRecipientNames?.toUpperCase()}'S GROUP CARD`}
              </Text>
              <BaseIconButtonNew
                icon={Info}
                iconProps={{ size: moreThanSmallDevice ? 24 : 12 }}
                onClick={() => setShowModalCardDetail(true)}
              />
            </div>
            <div className="arrow">
              <BaseIconButton onClick={handleGoBack} size={desktop ? 36 : 20} icon={Arrow} />
            </div>
          </div>
          {externalGift && (
            <ContributeCardGift
              gift={fromExternalGift(externalGift)}
              onViewGroupGiftDetails={() => setGroupGiftModalVisible(true)}
              className="gift-container"
            />
          )}
          {match(giftV2)
            .with({ product: P._ }, (gift) => (
              <ContributeCardGift
                gift={fromGiftV2Dto(gift)}
                onViewGroupGiftDetails={() => setGroupGiftModalVisible(true)}
                className="gift-container"
              />
            ))
            .with({ errMsg: P._ }, (err) => (
              <CloseCard
                style={{ width: '100%', marginBottom: 24 }}
                text={err.errMsg}
                variant="secondary"
                size="default"
                clickable={false}
                onClick={undefined}
                background={undefined}
                fixedSize={undefined}
                role={undefined}
              />
            ))
            .with(undefined, () => (
              <div className="gift-container">
                <RectangularBoxWithPlusButton
                  text={'add note'}
                  onClick={onCreateNote}
                  size="full"
                  background={undefined}
                  blobColor={undefined}
                  plusColor={undefined}
                />
              </div>
            ))
            .exhaustive()}

          <div className={'notes-container'}>
            {giftV2 && (
              <RectangularBoxWithPlusButton
                text={'add note'}
                onClick={onCreateNote}
                size="full"
                background={undefined}
                blobColor={undefined}
                plusColor={undefined}
              />
            )}
            {notes.map((note, index) => {
              const { viewCode } = note;
              const isMyNote = card.userContext.noteCodes.includes(viewCode);
              const editable = isMyNote;
              return (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                  key={note.viewCode}
                >
                  <NoteThubmnail
                    cardCode={card.inviteCode}
                    note={NoteThubmnail.noteFromDTO(note)}
                    recipientName={spellNames(card.recipientData)}
                    previewable={!note.redacted}
                    editable={editable}
                    onPencilClick={() => {
                      analytics.track(EVENT_NAMES.EDIT_NOTE_MY_CARDS.name);
                    }}
                    // the trashbin icon
                    hideBadge={!isMyNote}
                    deleteService={() => deleteNoteAsync.execute(note.viewCode)}
                    deleteModalLoading={deleteNoteAsync.loading}
                    onViewNote={noop}
                  />
                </motion.div>
              );
            })}
          </div>
        </div>
      </IllumeLayout>
      {(isGift(card.giftV2) || externalGift) && (
        <GroupGiftModal
          // contributor can't remove gift
          removable={false}
          showModal={groupGiftModalVisible}
          hideModal={() => setGroupGiftModalVisible(false)}
          gift={
            isGift(card.giftV2)
              ? GroupGiftModalDTOMapper.fromGiftV2Dto(card.giftV2)
              : externalGift
              ? GroupGiftModalDTOMapper.fromExternalGift(externalGift)
              : null
          }
          onContributeToGroupGift={(formValues) =>
            giftPaymentFromCardDetailsStore.handleContributeToGift(formValues, `${url}/pay`)
          }
          defaultValues={{
            email: userProfileStore.userProfileDTO?.email,
          }}
        />
      )}
      {displayedNote && (
        <PreviewModal
          open={!!displayedNote}
          notes={PreviewModal.notesFromDto([displayedNote])}
          showNavigationButtons={false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name={spelledRecipientNames!}
          onClose={() => setDisplayedNote(null)}
          onRetry={noop}
          showPrivacyModal={false}
        />
      )}

      <SentCardDetailsModal
        onResendSuccess={noop}
        show={showModalCardDetail}
        cardV2={card}
        onClose={() => setShowModalCardDetail(false)}
        resendCardService={() => Promise.resolve()}
        inviteService={store.inviteContributors}
      />
      {displayedNote && (
        <PreviewModal
          open={!!displayedNote}
          notes={PreviewModal.notesFromDto([displayedNote])}
          showNavigationButtons={false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name={spelledRecipientNames!}
          onClose={() => setDisplayedNote(null)}
          onRetry={noop}
          showPrivacyModal={false}
        />
      )}
    </>
  );
};
export default observer(ContributorCardDetailsV2Pending);
