import { useEffect } from 'react';

import { IReactionDisposer } from 'mobx';

export function useReaction(r: () => IReactionDisposer) {
  useEffect(() => {
    const dispose = r();

    return () => dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
