import { IAnalytics } from 'domain/interfaces/IAnalytics';
import logger from 'utils/logger';

export class MockJestAnalytics implements IAnalytics {
  track = jest.fn();
  identify = jest.fn();
  load = jest.fn();
}

export class MockLoggingAnalytics implements IAnalytics {
  track(event: string, properties?: Record<string, any> | undefined) {
    logger.log('tracking event', event, 'with props', properties);
  }
  identify(userId: string, traits?: Record<string, any> | undefined) {
    logger.log(`mock identify ${userId}`, traits);
  }
  load(...args: any[]) {
    console.log('initializing analytics..', args);
  }
}
