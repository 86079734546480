import merge from 'lodash/merge';

import baseConfig from './base.config';
import devConfig from './dev.config';
import localConfig from './local.config';
import prodConfig from './prod.config';
import testConfig from './test.config';

// SUGGESTION:
// using REACT_APP_* prefix to create custom environtment variables
// https://create-react-app.dev/docs/adding-custom-environment-variables/
// so instead of checking in the runtime like this, we can explicitly set the environtment in the deployment
// e.g REACT_APP_ILLUME_ENVIRONMENT = 'production' // 'test', 'development', 'staging', 'etc..'
const getEnvirontmentConfig = () => {
  // using NODE_ENV instead of REACT_APP_ENV
  // becuase it is set in build time, so test will never pick it up
  if (process.env.NODE_ENV === 'test') {
    return testConfig;
  }

  if (process.env.REACT_APP_ILLUME_ENVIRONMENT === 'local') {
    return localConfig;
  }

  // dev
  if (process.env.REACT_APP_ILLUME_ENVIRONMENT === 'development') {
    console.log(devConfig.testMessage, devConfig);
    return devConfig;
  }

  if (process.env.REACT_APP_ILLUME_ENVIRONMENT === 'production') {
    return prodConfig;
  }

  throw Error('Environment not specified');
};

export const isLocal = () =>
  process.env.REACT_APP_ILLUME_ENVIRONMENT === 'local' ||
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test';

const envConfig = getEnvirontmentConfig();

export default merge(baseConfig, envConfig);
