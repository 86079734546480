import { useState, useEffect, useRef } from 'react';

type ImageRef = HTMLImageElement | null;

interface UseAllImagesLoaded {
  imagesLoaded: boolean;
  setImageRef: (index: number, ref: ImageRef) => void;
}

export const useAllImagesLoaded = (): UseAllImagesLoaded => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const imageRefs = useRef<ImageRef[]>([]);

  useEffect(() => {
    const imagesCount = imageRefs.current.length;
    let loadedCount = 0;

    const handleImageLoad = () => {
      loadedCount++;
      if (loadedCount === imagesCount) {
        setImagesLoaded(true);
      }
    };

    imageRefs.current.forEach((img) => {
      if (img) {
        img.addEventListener('load', handleImageLoad);
      }
    });

    window.addEventListener('load', () => {
      setImagesLoaded(true);
    });

    return () => {
      imageRefs.current.forEach((img) => {
        if (img) {
          img.removeEventListener('load', handleImageLoad);
        }
      });

      window.removeEventListener('load', () => {
        setImagesLoaded(true);
      });
    };
  }, []);

  const setImageRef = (index: number, ref: ImageRef) => {
    imageRefs.current[index] = ref;
  };

  return { imagesLoaded, setImageRef };
};
