import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  const playButton = ({ revealed }) => {
    return revealed
      ? {
          display: 'none',
        }
      : {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
        };
  };

  const videoPlayer = ({ revealed }) => {
    return revealed
      ? {
          maxHeight: 480,
          width: '100%',
          background: 'rgba(0, 0, 0, 0.5)',
        }
      : {
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          position: 'absolute',
        };
  };

  const videoCutContainer = ({ size, revealed, display, clipPathId }) => {
    return revealed
      ? {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          '&:after': {
            background: 'rgba(0,0,0,0.6)',
            zIndex: -1,
          },
        }
      : {
          display,
          position: 'relative',
          clipPath: `url(#${clipPathId})`,
          width: size,
          height: size,
          '&:after': {
            zIndex: 1,
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.6)',
          },
        };
  };

  const svgCutout = ({ revealed }) => ({
    display: revealed ? 'none' : 'block',

    // this is a weird safari bug!
    // https://stackoverflow.com/a/12631326/15085434
    maxHeight: '100%',
    maxWidth: '100%',
  });

  const playerControl = () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: theme.spacing(2),
  });

  const bufferingSpinner = () => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  });

  return {
    playButton,
    videoPlayer,
    videoCutContainer,
    svgCutout,
    playerControl,
    bufferingSpinner,
  };
});
