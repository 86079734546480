import { Fragment } from 'react';

import { Grid, Link } from '@material-ui/core';

import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { CONTACT_US_EMAIL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from 'constants/strings';

export const Footer = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        {[
          { pageName: 'terms of service', path: TERMS_OF_SERVICE_URL },
          { pageName: 'privacy policy', path: PRIVACY_POLICY_URL },
          { pageName: 'contact us', path: CONTACT_US_EMAIL },
        ].map(({ pageName, path }, idx, arr) => {
          return (
            <Fragment key={pageName}>
              <Text fontWeight={300} fontSize={rem[875]} color={colors.gray40} display="inline">
                {pageName === 'contact us' ? (
                  <Link href={`mailto:${path}`} rel="noreferrer" target="_blank" color="inherit">
                    {pageName}
                  </Link>
                ) : (
                  <Link href={path} color="inherit">
                    {pageName}
                  </Link>
                )}
              </Text>
              {idx !== arr.length - 1 && (
                <Text fontWeight={300} color={colors.gray40} display="inline">
                  {' | '}
                </Text>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};
