import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useInitiatorCardDetailsV2PendingStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    arrowBack: {},
    // this fixes: https://github.com/elrumordelaluz/reactour/issues/192
    illumeLayoutContent: {
      overflowY: 'unset',
    },
    content: {
      flexGrow: 1,
      marginTop: 120,
      display: 'flex',
      maxWidth: '980px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 0px 30px 0px',
      },
      padding: '0 36px 30px 36px',
      flexWrap: 'wrap',
      gap: '36px',
      justifyContent: 'center',
      '& >  .title': {
        position: 'relative',
        flex: '0 1 100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 300,
        [theme.breakpoints.up('sm')]: {
          maxWidth: 300 * 2 + 36,
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: 300 * 3 + 36 * 2,
        },
      },
      '& > .section': {
        flex: `0 1 300px`,
        minHeight: 300,
        maxHeight: 381,
        paddingBottom: 12,
      },
      '& > .notes-container': {
        flex: '1 1 100%',
        justifyContent: 'center',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, 144px)`,
        gridAutoRows: '144px',
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: `repeat(auto-fit, 225px)`,
          gridAutoRows: '225px',
        },
        gap: 24,
      },
      '& > .gift-container': {
        flex: '0 1 300px',
        minHeight: 270,
        [theme.breakpoints.up('md')]: {
          flex: '1 1 100%',
        },
      },
      '& > .delete-container': {
        flex: `1 1 100%`,
        paddingBottom: 24,
      },
    },
  }),
);
