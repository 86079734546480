import { Grid } from '@material-ui/core';

import { TextBox } from 'components/illume/text-box';
import { colors } from 'constants/design';
import { CARD_STATUS } from 'constants/strings';

import { ROLES } from '../shared/constants';
import { InitiatorContributorInProgress } from '../shared/InitiatorContributorInprogress';

const Read = ({ card }) => {
  const { replies = [], cardReplyContext } = card || {};

  return (
    <Grid container direction="column" item spacing={4}>
      {/* Input group */}
      <Grid item container direction="column" spacing={1}>
        {replies.map(({ message, status, replier, id }) => {
          const isMyReply = id === cardReplyContext?.id;
          return (
            <Grid item key={id}>
              <TextBox
                className="fs-mask"
                minHeight={64}
                text={message}
                label={isMyReply ? 'Your Reply' : `${replier}'s Reply`}
                shadow={status === 'Unread'}
                color={colors.gray100}
                dark
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const ContributorContent = ({ card, inviteService }) => {
  const { status } = card || {};

  switch (status) {
    case CARD_STATUS.IN_PROGRESS:
      return (
        <InitiatorContributorInProgress
          role={ROLES.CONTRIBUTOR}
          card={card}
          inviteFeatureOn={true}
          inviteService={inviteService}
        />
      );
    case CARD_STATUS.READY_TO_SEND:
      return (
        <InitiatorContributorInProgress
          role={ROLES.CONTRIBUTOR}
          card={card}
          inviteFeatureOn={true}
          inviteService={inviteService}
        />
      );
    case CARD_STATUS.DELIVERED:
      return null; // nothing to display
    case CARD_STATUS.READ:
      return <Read card={card} />;
    default:
      return <div>unknown status</div>;
  }
};
