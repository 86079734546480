import { makeStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles(() => ({
  root: {
    animation: 'pulse-secondary 2s infinite',
    borderRadius: '50%',
  },
}));

export const CardGrid = ({ color = '#FFFDF3' }) => {
  const classes = useStyles({ color });
  return (
    <div className={classes.root}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.75 0H0.75C0.33525 0 0 0.33525 0 0.75V9.75C0 10.1648 0.33525 10.5 0.75 10.5H9.75C10.1648 10.5 10.5 10.1648 10.5 9.75V0.75C10.5 0.33525 10.1648 0 9.75 0Z"
          fill={color}
        />
        <path
          d="M23.25 0H14.25C13.8353 0 13.5 0.33525 13.5 0.75V9.75C13.5 10.1648 13.8353 10.5 14.25 10.5H23.25C23.6648 10.5 24 10.1648 24 9.75V0.75C24 0.33525 23.6648 0 23.25 0Z"
          fill={color}
        />
        <path
          d="M9.75 13.5H0.75C0.33525 13.5 0 13.8353 0 14.25V23.25C0 23.6648 0.33525 24 0.75 24H9.75C10.1648 24 10.5 23.6648 10.5 23.25V14.25C10.5 13.8353 10.1648 13.5 9.75 13.5Z"
          fill={color}
        />
        <path
          d="M23.25 13.5H14.25C13.8353 13.5 13.5 13.8353 13.5 14.25V23.25C13.5 23.6648 13.8353 24 14.25 24H23.25C23.6648 24 24 23.6648 24 23.25V14.25C24 13.8353 23.6648 13.5 23.25 13.5Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
