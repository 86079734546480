//@ts-check
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { IllumeConfig } from 'config/base.config';

export class Gtm {
  load = (config: IllumeConfig) => {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: config.gtm.gtmId,
    };
    // use the "Test" environment in GTM
    if (config.env !== 'prod') {
      tagManagerArgs.auth = config.gtm.auth;
      tagManagerArgs.preview = config.gtm.preview;
    }
    TagManager.initialize(tagManagerArgs);

    // Add the ads tag as well by combining the plugin and https://stackoverflow.com/a/54304710
    const tagManagerAdsArgs = {
      gtmId: config.gtm.adsId,
    };
    // use the "Test" environment in GTM
    if (config.env !== 'prod') {
      tagManagerArgs.auth = config.gtm.auth;
      tagManagerArgs.preview = config.gtm.preview;
    }

    return TagManager.initialize(tagManagerAdsArgs);
  };
}

class MockGtm {
  load = () => {
    console.log('loading gtm..');
  };
}

const gtm = new Gtm();

export const mockGtm = new MockGtm();

export default gtm;
