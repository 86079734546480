import cx from 'classnames';

import { Box } from 'components/box';
import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';

import { textBoxStyles } from './TextBox.styles';

interface TextBoxProps {
  shadow?: boolean;
  shadowColor?: string;
  text: string;
  dark?: boolean;
  color?: string;
  label?: string;
  minHeight?: number;
  className?: string;
  overflow?: string;
}

export const TextBox: React.FunctionComponent<TextBoxProps> = ({
  shadow = false,
  shadowColor = colors.alertRed,
  text = undefined,
  dark = false,
  color = undefined,
  label = undefined,
  minHeight = undefined,
  className = '',
  overflow = false,
}) => {
  const classes = textBoxStyles({ shadow, shadowColor, dark, minHeight, color, overflow });
  return (
    <Box className={className}>
      <label className={classes.label}>{label}</label>
      <Box className={classes.root}>
        <Text className={cx(classes.text, 'customIllumeScrollbar-gray')} fontSize={rem[875]}>
          {text}
        </Text>
      </Box>
    </Box>
  );
};
