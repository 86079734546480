import { makeStyles } from '@material-ui/core/styles';

import { colors, rem, borderRadius as borderRadiusBig, borderRadiusSmall } from 'constants/design';

export const inputStyles = makeStyles((theme) => ({
  root: ({ background, shadow, shadowColor, radius, error, border, customWidth, compact }) => {
    /**
     * @type {import('react').CSSProperties}
     */
    const compactStyles = compact
      ? {
          height: 40,
          paddingTop: 0,
          paddingBottom: 0,
        }
      : null;

    return {
      minHeight: rem[2500],
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      overflowY: 'hidden',
      backgroundColor:
        background === 'main'
          ? theme.palette.illume.textInputBackground.main
          : theme.palette.illume.textInputBackground.contrastText,
      borderRadius: radius === 'small' ? borderRadiusSmall : borderRadiusBig,
      border: error ? '2px solid red' : border ? border : null,
      boxShadow: shadow && `5px 5px ${shadowColor}`,
      justifyContent: customWidth ? 'space-between' : 'start',
      ...compactStyles,
    };
  },
  input: {
    width: ({ customWidth }) => (customWidth ? customWidth : null),
    fontWeight: 500,
    '&:read-only': {
      color: colors.gray40,
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
    '&::placeholder': {
      color: ({ placeHolderColor }) => (placeHolderColor ? placeHolderColor : colors.gray40),
      opacity: 1,
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        fontSize: 16,
      },
      lineHeight: rem[1500],
    },
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: ({ placement }) => ({
    marginRight: placement === 'start' && -theme.spacing(1),
  }),
}));

export const endImageStyles = {
  backgroundColor: colors.primary,
  minHeight: rem[2500],
  borderTopRightRadius: borderRadiusBig,
  borderBottomRightRadius: borderRadiusBig,
};

export const startImageStyles = {
  backgroundColor: colors.primary,
  minHeight: rem[2500],
  borderTopLeftRadius: borderRadiusBig,
  borderBottomLeftRadius: borderRadiusBig,
};
