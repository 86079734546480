// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// polyfilss match media, required by react-slick
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

import ReactDOM from 'react-dom';

import App from 'App';
import config from 'config';
import baseConfig from 'config/base.config';
import { createAnalytics } from 'infra/analytics/factory';
import fullstoryService from 'infra/fullstory/fullStory';
import { Gtm } from 'infra/gtm/gtm';
import getLegacyRedirectPath from 'init';

// common styles goes here
import './index.scss';

const analytics = createAnalytics();

const gtm = new Gtm();
declare global {
  interface Window {
    FF: typeof baseConfig.FF;
    analytics: any;
  }
}

fullstoryService.init();

analytics.load(config);

gtm.load(config);

getLegacyRedirectPath(window.location).then((url) => {
  if (url) {
    window.location.replace(url);
    return;
  }

  ReactDOM.render(<App analytics={analytics} />, document.getElementById('index'));
});
