import { Box, BoxProps } from '@material-ui/core';

import { colors } from 'constants/design';

import { useStyles } from './Surface.styles';

export interface ISurfaceProps extends BoxProps {
  className?: string;
  rounded?: ('top' | 'bottom')[];
  fullWidth?: boolean;
  background?: string;
}

export const Surface: React.FC<ISurfaceProps> = ({
  className = '',
  rounded,
  background = colors.backgroundGray,
  fullWidth,
  px = 2,
  py = 2,
  borderRadius = 12,
  ...props
}) => {
  const classes = useStyles({ rounded, fullWidth, background, borderRadius });
  return <Box px={px} py={py} className={`${classes.root} ${className}`} {...props} />;
};
