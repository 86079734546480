import config from 'config';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { storageAvailable } from 'utils';
import logger from 'utils/logger';

const ILLUME_SESSION_ID = 'illumeSessionId';

const getUnixTimestamp = () => new Date().valueOf();
export class SegmentAnalytics implements IAnalytics<any, any> {
  track(event: string, properties?: any): void {
    window.analytics.track(event, properties);
  }

  identify(userId: string, traits?: any): void {
    window.analytics.identify(userId, traits);
  }
  // this requires that the snippet was already run in the html - but didn't yet call load()
  load = (cfg: typeof config, analytics = window.analytics) => {
    if (!analytics) {
      return;
    }
    const obfuscate = true;
    logger.log(`analytics.load() with obfuscate: ${obfuscate}`);
    analytics.load(cfg.segmentWriteKey, { obfuscate });

    // TODO: should probably do this in a cleaner more methodical way
    // I believe it's necessary to get intercom and possible customer.io to work correctly
    analytics.ready(() => {
      logger.log('analytics calling page()');
      // no idea if we can call page() without a name
      this.page();
      // possibly call identify() too?
    });
  };

  ready = () => {
    return new Promise((resolve) => {
      window.analytics.ready(() => {
        logger.log('analytics.ready()');
        resolve(null);
      });
    });
  };

  private getSessionPayload = () => {
    if (!storageAvailable('sessionStorage')) return {};

    const setSession = () => {
      // segment's amplitude integration docs say to use the unix timestamp for the sessionId
      const sessionId = getUnixTimestamp();
      logger.log({ sessionId }, 'creating analytics session');
      window.sessionStorage.setItem(ILLUME_SESSION_ID, sessionId.toString());
      return sessionId;
    };

    // gets the sessionId from sessionStorage if it exists, otherwise initializes it and then returns it
    const getSessionId = () => {
      let sessionId = window.sessionStorage.getItem(ILLUME_SESSION_ID);
      if (!sessionId) {
        sessionId = setSession().toString();
      }
      // logger.log({ sessionId }, 'using analytics sessionId');
      return sessionId;
    };

    return {
      integrations: {
        Amplitude: {
          session_id: getSessionId(),
        },
      },
    };
  };

  private page = (name?: string, properties?: any) => {
    const sessionPayload = this.getSessionPayload();
    // assumes only a single integration at this point!
    window.analytics && window.analytics.page(name, properties, sessionPayload);
  };
}
