import { random } from 'lodash';

export const getRandomLoadingLabel = () => {
  const list = [
    'You’re pretty special. Just saying.',
    'You’re gold, baby. Solid gold.',
    'Shut up, I think you’re gorgeous.',
    'You don’t even need to ask, I got you.',
  ];

  return list[random(0, list.length - 1, false)] || 'loading...';
};
