const RecorderCheck = () => (
  <svg width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#check-button)">
      <path
        d="M0.0127254 21.7327C-0.255795 16.302 3.74344 10.5187 10.2851 6.12862C17.2666 1.44286 25.008 -0.450778 33.3492 0.0894489C38.1597 0.396526 42.6903 1.70444 46.4953 4.84914C48.9691 6.89063 50.883 9.39842 52.5341 12.128C55.4479 16.9389 57.8874 21.9544 59.2643 27.442C61.1725 35.0393 59.31 41.7438 54.4424 47.7091C49.3405 53.9586 42.5589 57.1318 34.6576 58.1781C29.1329 58.9117 23.7511 58.3316 18.5235 56.3982C12.1418 54.0383 7.55986 49.6766 4.67469 43.6147C1.79524 37.5755 0.224114 31.1895 0.0127254 21.7327Z"
        fill="#FFFDF3"
      />
      <path
        d="M43.9895 21.0787C44.0279 21.5616 43.961 22.047 43.7934 22.5015C43.6259 22.9561 43.3617 23.3688 43.019 23.7112L28.0271 38.7254C27.6088 39.1403 27.0434 39.3729 26.4543 39.3724C25.8651 39.3568 25.3019 39.1267 24.8703 38.7254L16.9839 30.8167C16.6389 30.4728 16.3732 30.0578 16.2052 29.6005C16.0372 29.1433 15.9711 28.6549 16.0115 28.1694C16.0519 27.684 16.1977 27.2132 16.4389 26.7899C16.6801 26.3667 17.0107 26.0012 17.4078 25.719C18.0766 25.2669 18.883 25.064 19.6861 25.1455C20.4893 25.2271 21.2384 25.5879 21.8027 26.1652L26.4543 30.8167L38.2002 19.0708C38.642 18.5944 39.213 18.2569 39.8434 18.0996C40.4738 17.9423 41.1364 17.9718 41.7503 18.1848C42.3642 18.3977 42.9028 18.7847 43.3004 19.2986C43.6981 19.8125 43.9375 20.431 43.9895 21.0787Z"
        fill="#282829"
        fillOpacity="0.4"
      />
    </g>
    <defs>
      <clipPath id="check-button">
        <rect width="60" height="58.481" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RecorderCheck;
