// TODO: correctly type this
import { ReactElement } from 'react';

interface ForProps {
  each: any[];
  children(el: any, index: number | string): ReactElement;
  identifier?: string;
  fallback?: ReactElement | null;
}

const For = ({ each, children, identifier, fallback = null }: ForProps) => {
  if (fallback && !each) {
    return fallback;
  }
  return each.map((e, index) => {
    const id = (identifier && e[identifier]) || index;
    return children(e, id);
  });
};

export default For;
