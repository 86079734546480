import { Box, Modal as MuiModal, Paper } from '@material-ui/core';
import { observer } from 'mobx-react';

import { BaseIconButton } from 'components/illume/buttons';
import { Close } from 'components/illume/icons';
import { colors } from 'constants/design';
import { useStores } from 'contexts/store';
import { VerificationForm } from 'pages/illume/verification-form';

import { useModalStyles, usePaperStyles } from './ExperimentalVerificationProvider.styles';

export const ExperimentalVerificationProvider: React.FC<any> = ({ children }) => {
  const { verificationStore } = useStores();
  const modalClasses = useModalStyles();
  const paperClasses = usePaperStyles({ background: colors.background });

  const subtitle = (
    <>
      We sent a 4-digit verification code
      <br />
      to {verificationStore.userProfile?.email}.
    </>
  );

  return (
    <>
      {children}
      <MuiModal
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
        classes={modalClasses}
        onClose={() => verificationStore.cancel()}
        open={verificationStore.verificationFormVisible}
      >
        <Paper classes={paperClasses}>
          <Box position="absolute" top={20} right={20} zIndex={1}>
            {/** @ts-ignore */}
            <BaseIconButton
              onClick={() => verificationStore.cancel()}
              icon={() => <Close color={colors.darkText} size={25} hasBlob={undefined} />}
            />
          </Box>
          <VerificationForm
            title="Check your email"
            subtitle={subtitle}
            color={colors.darkText}
            titleColor={colors.darkText}
            background={colors.backgroundGray}
            onResend={verificationStore.resendChallenge}
            onVerify={(otpCode: string) => verificationStore.verifyDigits(otpCode)}
            expiration={true}
            sendDigitsError={verificationStore.issueChallengeError}
            error={verificationStore.verifyDigitsError}
            verifying={verificationStore.verifyDigitsStatus === 'verifying'}
          />
        </Paper>
      </MuiModal>
    </>
  );
};

export default observer(ExperimentalVerificationProvider);
