import { ExternalGiftDTO } from '@illume/shared';

import { IMoney as IMoney } from 'domain/interfaces/IMoney';
import { IllumeMoneyFactory } from 'utils/currency';

type Discount = {
  amount: IMoney;
  percentage: number;
  promoCode?: string;
};

type ExternalGiftFinancial = {
  goal: IMoney;
  suggestedAmount: IMoney;
  discount?: Discount;
  current: IMoney;
};

type Product = {
  name: string;
};
export class ExternalGift {
  goal: IMoney;
  title: string;
  image: string;
  description: string;
  price: IMoney;
  financial: ExternalGiftFinancial;
  yourContribution?: IMoney;
  shipping: IMoney;
  id: string;
  product: Product;
  trackingNumber?: string;
  static readonly FallBackImage = `https://i.ibb.co/6HVB3VZ/500x500.gif`;

  static fromDto(dto: ExternalGiftDTO) {
    return new ExternalGift(dto);
  }
  /**
   *
   * @todo - remove dto dependency on constructor!
   */
  private constructor(private _dto: ExternalGiftDTO) {
    this.id = _dto.id;
    this.trackingNumber = _dto.trackingNumber;
    this.goal = IllumeMoneyFactory({ amount: _dto.financial.goal });
    this.product = { name: _dto.product.title };
    this.title = _dto.product.title;
    this.image = _dto.product.photo || ExternalGift.FallBackImage;
    this.description = _dto.product.description || '';
    this.price = IllumeMoneyFactory({ amount: _dto.product.price });
    this.shipping = IllumeMoneyFactory({ amount: _dto.product.shippingFee });
    this.yourContribution = _dto.yourContribution
      ? IllumeMoneyFactory({ amount: _dto.yourContribution })
      : undefined;
    this.financial = {
      goal: IllumeMoneyFactory({ amount: _dto.financial.goal }),
      suggestedAmount: IllumeMoneyFactory({ amount: _dto.financial.suggestedAmount }),
      current: IllumeMoneyFactory({ amount: _dto.financial.current }),
      discount: {
        amount: IllumeMoneyFactory({ amount: _dto.financial.discount.amount }),
        percentage: _dto.financial.discount.percentage,
        promoCode: _dto.financial.discount.promoCode,
      },
    };
  }

  get isDigitalGiftCard() {
    if (!this.title) {
      return false;
    }
    return this.description?.includes('gift card') || this.title.includes('gift card');
  }
  get taxAndHandling() {
    if (!this.price) {
      return IllumeMoneyFactory({ amount: 0 });
    }
    return this.price.percentage(this.isDigitalGiftCard ? 2 : 10);
  }
  get formattedPriceBreakdown() {
    return {
      'Product Total': this.price ? this.price.toFormat() : 'Add Product Price!',
      'Tax & Handling': this.taxAndHandling.toFormat(),
      Shipping: IllumeMoneyFactory({ amount: 0 }).toFormat(),
    };
  }
  get giftMetGoals() {
    if (this.financial) {
      return this.financial.current.greaterThanOrEqual(this.financial.goal);
    } else {
      return false;
    }
  }

  get shippable() {
    return !this.trackingNumber;
  }
  get trackable() {
    return !!this.trackingNumber;
  }
}
