import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { isEmpty } from 'lodash';
import groupBy from 'lodash/groupBy';
import { makeAutoObservable, reaction } from 'mobx';

import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { IStorage } from 'contexts/store';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { IMerchantProductsService } from 'domain/interfaces/IMerchantGiftService';
import { MerchantProduct } from 'pages/illume/marketplace/store/store';
import { ExperimentalAsyncCachedStore } from 'pages/illume/my-cards/ExperimentalAsyncCacheStore';
import { CategoryType, MerchantProductDTO } from 'types';
import { randomSlice } from 'utils';

export default class MerchantProductStore {
  merchantProductsAsyncCachedStore = new ExperimentalAsyncCachedStore(
    this._service.getMerchantProducts,
    {
      get: () => this.cache.get('merchant_products'),
      set: (data) => this.cache.set('merchant_products', data),
    },
  );
  remoteRepoByOccasion = new ObservablePromise(this._service.getMerchantProductsByOccassion);

  bestSellingProductsAsyncCachedStore = new ExperimentalAsyncCachedStore(
    this._service.getBestSellingMerchantProducts,
    {
      get: () => this.cache.get('bs_products'),
      set: (data) => this.cache.set('bs_products', data),
    },
  );

  occasion?: string = undefined;
  private readonly OCCASIONAL_PRODUCTS_LIMIT = 15;

  setOccasion(string: string) {
    this.occasion = string;
  }

  /**
   * @warn using dto directly in app's component is discouraged
   */
  get _merchantProductsDto(): MerchantProductDTO[] | undefined {
    if ('data' in this.merchantProductsAsyncCachedStore.state) {
      return this.merchantProductsAsyncCachedStore.state.data;
    }
  }
  get merchantProducts() {
    return this._merchantProductsDto?.map((dto) => new MerchantProduct(dto));
  }
  get merchantProductsByCategory() {
    return (
      this._merchantProductsDto &&
      (groupBy(this._merchantProductsDto, 'listingData.category') as Record<
        CategoryType,
        MerchantProductDTO[]
      >)
    );
  }
  get occasionMerchantProducts(): MerchantProductDTO[] | undefined {
    return this.remoteRepoByOccasion.getResult(undefined);
  }
  get merchantProductsByOccasion() {
    return this.occasionMerchantProducts;
  }
  get merchantProductByOccasionLimitted() {
    return this.occasionMerchantProducts?.slice(0, this.OCCASIONAL_PRODUCTS_LIMIT);
  }
  get loading() {
    return (
      this.merchantProductsAsyncCachedStore.state.type === 'pending' ||
      this.merchantProductsAsyncCachedStore.state.type === 'idle'
    );
  }
  get occasionLoading() {
    return this.remoteRepoByOccasion.isExecuting && this.remoteRepoByOccasion.isExecutingFirstTime;
  }
  get refreshing() {
    return this.merchantProductsAsyncCachedStore.state.type === 'refreshing';
  }
  get occasionRefreshing() {
    return !!this.occasionMerchantProducts && this.remoteRepoByOccasion.isExecuting;
  }
  get error() {
    return (
      (this.merchantProductsAsyncCachedStore.state.type === 'error' &&
        this.merchantProductsAsyncCachedStore.state.error) ||
      this.remoteRepoByOccasion.error
    );
  }
  get emptyGift() {
    return (
      this.merchantProductsAsyncCachedStore.state.type === 'success' &&
      isEmpty(this._merchantProductsDto)
    );
  }
  get emptyOccasionGift() {
    return this.remoteRepoByOccasion.wasSuccessful && this.occasionMerchantProducts === undefined;
  }

  get bestSellingProducts() {
    // random products from merchants dto

    if (false) {
      //@ts-ignore
      return this._merchantProductsDto && randomSlice(this._merchantProductsDto, 8);
    } else {
      if ('data' in this.bestSellingProductsAsyncCachedStore.state) {
        // if they are loaded, return the products
        return this.bestSellingProductsAsyncCachedStore.state.data;
      } else {
        // if they are not loaded, return an empty array
        return [];
      }
    }
  }

  get arrangedMerchantProductDto() {
    const sortPriority: CategoryType[] = [
      'Design-Obsessed',
      'Foodie',
      'Baby & Kids',
      'Wellness',
      'NYC Experiences',
      'LA Experiences',
      'Dog Lover',
      'Media Junkie',
      'Home Decor',
      'Adventurer',
      'Humanitarian',
      'Digital Giftcard',
    ];

    return (this.merchantProductsByCategory &&
      Object.entries(this.merchantProductsByCategory).sort(
        ([a], [b]) =>
          sortPriority.indexOf(a as CategoryType) - sortPriority.indexOf(b as CategoryType),
      )) as [CategoryType, MerchantProductDTO[]][];
  }
  constructor(
    private _service: Pick<
      IMerchantProductsService,
      'getMerchantProductsByOccassion' | 'getMerchantProducts' | 'getBestSellingMerchantProducts'
    >,
    private cache: Pick<IStorage<any>, 'get' | 'set'>,
    private analytics: IAnalytics,
  ) {
    makeAutoObservable(this);
    this.merchantProductsAsyncCachedStore.execute();
    this.bestSellingProductsAsyncCachedStore.execute();

    reaction(
      () => this.error,
      () => {
        this.analytics.track(EVENT_NAMES.ERROR_GIFT.name);
      },
    );

    reaction(
      () => this.occasion,
      (occasion) => {
        if (occasion) this.remoteRepoByOccasion.execute(occasion).catch();
      },
    );
  }
}
