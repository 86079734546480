const RecorderRecord = () => {
  return (
    <svg width="86" height="84" viewBox="0 0 86 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#record-icon)">
        <path
          d="M0.518134 30.969C0.135492 23.2303 5.83441 14.9891 15.1562 8.73329C25.1049 2.05608 36.1363 -0.642359 48.0227 0.127465C54.8776 0.565049 61.3337 2.42883 66.7558 6.91002C70.281 9.81914 73.0083 13.3927 75.3612 17.2824C79.5132 24.1379 82.9896 31.2851 84.9516 39.1049C87.6708 49.931 85.0167 59.4849 78.0803 67.9854C70.8102 76.8911 61.1464 81.4128 49.887 82.9038C42.0144 83.9491 34.3453 83.1226 26.896 80.3674C17.8021 77.0045 11.2728 70.7892 7.16144 62.151C3.05822 53.5451 0.819362 44.445 0.518134 30.969Z"
          fill="#FFFDF3"
        />
        <path
          d="M42.7085 18.5256C55.5794 18.5256 66.0493 28.9955 66.0493 41.8664C66.0493 54.7379 55.58 65.2073 42.7085 65.2073C29.837 65.2073 19.3677 54.7379 19.3677 41.8664C19.3677 28.9955 29.837 18.5256 42.7085 18.5256ZM42.7085 61.4725C53.5195 61.4725 62.3146 52.6775 62.3146 41.8664C62.3146 31.0554 53.5195 22.2604 42.7085 22.2604C31.8975 22.2604 23.1024 31.0554 23.1024 41.8664C23.1024 52.6775 31.8975 61.4725 42.7085 61.4725Z"
          fill="#FEB81C"
        />
        <path
          d="M42.7083 54.0039C36.0051 54.0039 30.5711 48.5699 30.5711 41.8667C30.5711 35.1635 36.0051 29.7295 42.7083 29.7295C49.4115 29.7295 54.8455 35.1635 54.8455 41.8667C54.8455 48.5699 49.4115 54.0039 42.7083 54.0039Z"
          fill="#FEB81C"
        />
      </g>
      <defs>
        <clipPath id="record-icon">
          <rect width="85.5" height="83.3354" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RecorderRecord;
