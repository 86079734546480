import { IAnalytics } from 'domain/interfaces/IAnalytics';
import logger from 'utils/logger';

import { SegmentAnalytics } from '../segment/SegmentAnalytics';

export class DevSegmentAnalytics implements IAnalytics {
  constructor(private segmentAnalytics: SegmentAnalytics) {}
  load = (config: any) => {
    this.segmentAnalytics.load(config);
  };
  track = (event: string, properties: any) => {
    this.segmentAnalytics.track(event, properties);
    logger.log('track', event, properties);
  };

  identify = (userId: string, traits: any) => {
    this.segmentAnalytics.identify(userId, traits);
    logger.log('identify', userId, traits);
  };
}
