import { ButtonBase, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const desktop = {
  small: 66,
  medium: 89,
  large: 89,
};

const mobile = {
  small: 51,
  large: 66,
};

const PlusIcon = ({ blobColor = colors.contrastText, size = 66 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0628049 24.7393C-0.232122 18.7655 4.16041 12.4038 11.3453 7.57461C19.0134 2.42018 27.5161 0.337143 36.6777 0.931403C41.9613 1.26919 46.9374 2.70793 51.1166 6.16715C53.8337 8.41283 55.9358 11.1714 57.7493 14.174C60.9496 19.4661 63.629 24.9833 65.1413 31.0197C67.2371 39.3769 65.1915 46.752 59.8451 53.3139C54.2415 60.1885 46.7931 63.679 38.1147 64.83C32.0467 65.6369 26.1356 64.9989 20.3939 62.8721C13.3847 60.2761 8.35214 55.4782 5.18324 48.81C2.02062 42.1668 0.294982 35.142 0.0628049 24.7393ZM33.3224 16.917C34.4269 16.917 35.3224 17.8124 35.3224 18.917V30.7871H47.1929C48.2975 30.7871 49.1929 31.6825 49.1929 32.7871C49.1929 33.8917 48.2975 34.7871 47.1929 34.7871H35.3224V47.084C35.3224 48.1886 34.4269 49.084 33.3224 49.084C32.2178 49.084 31.3224 48.1886 31.3224 47.084V34.7871H19.0259C17.9213 34.7871 17.0259 33.8917 17.0259 32.7871C17.0259 31.6825 17.9213 30.7871 19.0259 30.7871H31.3224V18.917C31.3224 17.8124 32.2178 16.917 33.3224 16.917Z"
        fill={blobColor}
      />
    </svg>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '&:focus': { outline: 'none' },
    borderRadius: '100%',
    width: ({ size }) => (size ? mobile[size] : '100%'),
    maxWidth: 89,
    [theme.breakpoints.up('md')]: {
      width: ({ size }) => (size ? desktop[size] : '100%'),
    },
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'default'),
  },
  image: {
    width: '100%',
  },
}));

const Plus = ({ size, clickable = true, blobColor, loading = false, plusColor, ...props }) => {
  const classes = useStyles({ size: size, clickable: clickable });
  return (
    <ButtonBase className={classes.root} {...props}>
      {loading ? (
        <CircularProgress size={24} style={{ color: plusColor }} />
      ) : (
        <PlusIcon blobColor={blobColor} plusColor={plusColor} />
      )}
    </ButtonBase>
  );
};

export default Plus;
