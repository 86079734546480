import { Select, MenuItem, FormControl, Box } from '@material-ui/core';

import { colors, rem } from 'constants/design';

import { CheckMark, DownChevron } from '../icons';
import { Text } from '../text';
import { inputStyles, useStyles, MenuProps } from './Dropdown.styles';

/**
 *
 * @deprecated - too bloated and have many issues
 */
const Dropdown = ({
  children,
  label,
  labelSuffix,
  labelTextProps,
  background,
  shadow,
  shadowColor,
  radius,
  placeholder,
  value,
  onChange,
  items = [],
  error,
  errorMessage,
  fontSize,
  icon,
  ...props
}) => {
  const classes = useStyles({ value });
  const inputClasses = inputStyles({ background, shadow, shadowColor, radius, fontSize });

  return (
    <FormControl fullWidth>
      <Box display="flex" alignItems="center">
        <Box mr={2} display="flex" alignItems="center" gridGap={5}>
          <Text
            color={colors.labelText}
            fontSize={{ mobile: rem[875], desktop: rem[1000] }}
            {...labelTextProps}
          >
            {label}
          </Text>
          {labelSuffix && (
            <Text
              color={colors.labelText}
              fontWeight={300}
              fontSize={{ mobile: rem[875], desktop: 11 }}
            >
              {labelSuffix}
            </Text>
          )}
        </Box>
      </Box>

      <Select
        displayEmpty
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
        inputProps={{
          className: classes.textInput,
          disableUnderline: true,
          'aria-label': 'Without label',
        }}
        classes={inputClasses}
        disableUnderline
        IconComponent={
          icon
            ? icon
            : () => <DownChevron className={classes.arrow} width={20} color={colors.gray40} />
        }
        renderValue={(value) => items?.find((i) => i.value === value)?.name || value || placeholder}
        {...props}
      >
        {items.map((item) => {
          if (typeof item === 'object') {
            const { name, value, style } = item;
            return (
              <MenuItem key={name + value} value={value} className={classes.menuItem} style={style}>
                {name}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={item}
                value={item}
                className={`${classes.menuItem}
                  ${value === item ? classes.selected : ''}
                   `}
              >
                {item}
                {value === item && <CheckMark className={classes.icon} />}
              </MenuItem>
            );
          }
        })}
      </Select>

      {error && (
        <Box mt={1}>
          <Text color={colors.alertRed}>
            {error.message || errorMessage || 'please provide a valid input'}
          </Text>
        </Box>
      )}
    </FormControl>
  );
};

export default Dropdown;
