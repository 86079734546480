const DEFAULT_FEATURE_FLAGS = {
  GIPHY: true,
  GOOGLE_SIGN_IN: true,
  REDESIGNED_APP: true,
  EXPERIMENTS: true,
  NOTIFICATION: true,
  VIDEO_UPLOAD: true,
  SOCIAL_SHARE: true,
  INVITE_CONTRIBUTORS: true,
  PUBLIC_TOGGLE_NOTE: true,
  SNAPCHAT_SHARE: true,
  NEW_CARD_THEMING: true,
  GROUP_GIFT: true,
  // false by default
  // most likely because under development
  SUBSCRIBE_TO_NOTES: false,
  MULTI_THREADED_VIDEO_CONVERSION: false,
  APPLE_PAY: false,
  SAVE_THE_DATE: false,
  TEAMS_BATCH_CREATION: true,
  ADD_GIFT_REQUIRES_LOGIN: false,
  DOWNLOAD_CARD_AS_PDF: true,
};

const gtmArgs: {
  gtmId: string;
  preview: undefined | string;
  auth: undefined | string;
  adsId: string;
} = {
  gtmId: 'GTM-55V2QDD',
  preview: undefined,
  auth: undefined,
  adsId: 'AW-461509962',
};

// eslint-disable-next-line import/no-anonymous-default-export
const baseConfig = {
  cacheVersion: '1.3',
  testMessage: 'Greetings from unknown environment!', // probably `Greetings from process.env(REACT_APP_ILLUME_ENVIRONMENT)` in the future
  mockEnabled: false,
  stripePublicKey: 'unset',
  env: 'unset',
  segmentWriteKey: 'unset',
  baseURL_new: '/api',
  giphyKey: 'cckPIZYN3bIFBP2S3u2bz43tqbLi7n2o',
  googleClientId: '512907429228-6sqqsd2nscu2f4tgiibek981i9e41o8m.apps.googleusercontent.com',
  FF: DEFAULT_FEATURE_FLAGS,
  gtm: gtmArgs,
  sentry: {
    dsn: 'https://6243f1ebf4774e38a5af8ace74d469ff@o444837.ingest.sentry.io/5568653',
  },
  logger: {
    console: true,
    silence: false,
  },
  uploadCareId: 'c792ee9f561b94b89683',
};

export type IllumeConfig = typeof baseConfig;

export default baseConfig;
