import { FunctionComponent, useState } from 'react';

import styled from '@emotion/styled';
import { ButtonBase } from '@material-ui/core';
import { Maybe } from 'yup/lib/types';

import { CheckIcon } from './CheckIcon';
import ChevronIcon from './ChevronIcon';
import { HeightBasedCollapsible } from './HeightBasedCollapsible';
import { theme } from './PricingCard.styled';

type IBenefit = { text: string; color: string | null; textColor?: string | undefined | null };

export type Action<T = never, U = any> = {
  name: string;
  onClick: (props: T) => U;
};

interface PricingCardProps {
  action?: Action;
  hiddenBenefitsEnabled?: boolean;
  tag: Maybe<{
    title: string;
    color: string;
  }>;
  note: Maybe<string>;
  benefits: IBenefit[];
  hiddenBenefits: Maybe<IBenefit[]>;
  subtitle: string;
  formattedPrice: string;
  label: string;
  per: Maybe<string>;
  ctaVariant: 'primary' | 'secondary';
  banner: Maybe<{
    text: string;
  }>;
}
const Layout = styled.div`
  position: relative;
  background-color: ${theme.colors.lightPeach};
  padding: 35px 40px 0 40px;
  border-radius: 12px;
  width: 100%;
  min-width: 250px;
  max-width: 352px;
  overflow: auto;
  overflow-x: hidden;
`;

const Cta = styled(ButtonBase)<{
  color: string;
  textColor: string;
  mb: string;
  hoverBackground: string;
}>`
  background-color: ${(props) => props.color};
  color: ${(props) => props.textColor};
  width: 100%;
  font-weight: 450;
  font-size: 16px;
  margin-bottom: ${(props) => props.mb};
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: ${(props) => props.hoverBackground};
  }
`;

const TitleContainer = styled.div<{ mt: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  margin-top: ${(props) => props.mt};
  & > .pricing-type {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 12px;
  }
  & > .price {
    font-size: 48px;
    line-height: 64px;
  }
  & > .per {
    font-size: 16px;
    color: ${theme.colors.gray40};
    font-weight: 400;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${theme.colors.black};
  margin-top: 52px;
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  font-family: 'brandon-grotesque';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #282829;
  margin-bottom: 28px;
`;

const Benefits = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 28px;
`;

const Benefit = styled.li`
  display: flex;
  column-gap: 8px;
  font-size: 14px;
  font-weight: 390;
  font-weight: 400;
`;

const Note = styled.p`
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 19px;
  color: ${theme.colors.gray60};
`;

const Tag = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  padding: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 12px 12px 0 0;
  & > .tag {
    font-size: 14px;
    font-weight: 700;
    color: ${theme.colors.lightPeach2};
  }
`;

const SeeAllFeaturesCta = styled.button`
  background: none;
  color: ${theme.colors.gray40};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  column-gap: 10px;
`;

const CollapsedContent = ({ benefits }: { benefits: IBenefit[] }) => {
  const [opened, setOpened] = useState(false);

  return (
    <div>
      <SeeAllFeaturesCta onClick={() => setOpened((prev) => !prev)}>
        <p>See all features</p>
        <ChevronIcon opacity={'1'} color={theme.colors.gray40} direction={opened ? 'up' : 'down'} />
      </SeeAllFeaturesCta>
      <HeightBasedCollapsible isOpen={opened}>
        <Benefits>
          {benefits.map((b, id) => (
            <Benefit key={id}>
              <div className="icon">
                {b.color ? (
                  <CheckIcon color={b.color} />
                ) : (
                  <CheckIcon color={'tranparent'} checkColor={'transparent'} />
                )}
              </div>
              <p style={b.textColor ? { color: b.textColor } : undefined}>{b.text}</p>
            </Benefit>
          ))}
        </Benefits>
      </HeightBasedCollapsible>
    </div>
  );
};

const BannerSvg = () => {
  return (
    <svg
      width="157"
      height="158"
      viewBox="0 0 157 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M156.999 133.5C154.49 124.219 151.525 118.226 144.499 111L45.4994 12C38.7591 5.75172 30.3551 2.71008 22 0L84.4994 0.5C91.7096 1.11436 94.8237 2.72999 99.4993 7L145.499 53C152.555 59.4358 156.439 63.9355 156.999 72.5L156.999 133.5Z"
        fill="#23CE6B"
      />
      <path d="M0 11.9999C15.5 -3.99993 29 -4 45.5 11.9999H0Z" fill="#19984E" />
      <path d="M145 111.5C161 127 161 141 145 157.5L145 111.5Z" fill="#19984E" />
      <rect
        x="86.4766"
        y="4"
        width="96"
        height="26"
        rx="13"
        transform="rotate(45.2818 86.4766 4)"
        fill="#23CE6B"
      />
    </svg>
  );
};

const BannerText = styled.div`
  transform: rotate(47deg);
  transform: rotate(45deg);
  position: absolute;
  top: 24px;
  right: 2px;
  z-index: 2;
  font-weight: 500;
  font-size: 18px;
  color: #fffdf3;
  transform: rotate(46deg);
`;

const PricingCard: FunctionComponent<PricingCardProps> = (props) => {
  const {
    benefits,
    ctaVariant,
    formattedPrice,
    per,
    label,
    subtitle,
    tag,
    note,
    hiddenBenefits,
    hiddenBenefitsEnabled = false,
    action,
    banner,
  } = props;
  const ctaColor = ctaVariant === 'primary' ? theme.colors.orange : theme.colors.lightPeach2;
  const ctaTextColor = ctaVariant === 'primary' ? theme.colors.lightPeach : theme.colors.gray40;
  const ctaHoverColor = ctaVariant === 'primary' ? `#E5A619` : '#EFE6CE';
  return (
    <Layout>
      {banner && (
        <>
          <div
            style={{
              position: 'absolute',
              top: -12,
              right: -12,
              zIndex: 1,
            }}
          >
            <BannerSvg />
          </div>
          <BannerText>{banner.text}</BannerText>
        </>
      )}

      {tag && (
        <Tag background={tag.color}>
          <p className="tag">{tag.title}</p>
        </Tag>
      )}
      <TitleContainer mt={tag ? '32px' : '0px'}>
        <p className="pricing-type">{label}</p>
        <p className="price">{formattedPrice}</p>
        {per && <p className="per">{per}</p>}
      </TitleContainer>
      <Divider />
      <Subtitle>{subtitle}</Subtitle>
      <Benefits>
        {benefits.map((b, id) => (
          <Benefit key={id}>
            <div className="icon">
              {b.color ? (
                <CheckIcon color={b.color} />
              ) : (
                <CheckIcon color="transparent" checkColor="transparent" />
              )}
            </div>
            <p>{b.text}</p>
          </Benefit>
        ))}
      </Benefits>
      {action && (
        <Cta
          onClick={action?.onClick}
          mb={note ? '12px' : '36px'}
          color={ctaColor}
          textColor={ctaTextColor}
          hoverBackground={ctaHoverColor}
        >
          {action?.name}
        </Cta>
      )}
      {note && <Note>{note}</Note>}
      {hiddenBenefits && hiddenBenefitsEnabled && <CollapsedContent benefits={hiddenBenefits} />}
    </Layout>
  );
};

export default PricingCard;
