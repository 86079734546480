const RecorderPlay = () => (
  <svg width="86" height="84" viewBox="0 0 86 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#play-button)">
      <path
        d="M0.518134 30.969C0.135492 23.2303 5.83441 14.9891 15.1562 8.73329C25.1049 2.05608 36.1363 -0.642359 48.0227 0.127465C54.8776 0.565049 61.3337 2.42883 66.7558 6.91002C70.281 9.81914 73.0083 13.3927 75.3612 17.2824C79.5132 24.1379 82.9896 31.2851 84.9516 39.1049C87.6708 49.931 85.0167 59.4849 78.0803 67.9854C70.8102 76.8911 61.1464 81.4128 49.887 82.9038C42.0144 83.9491 34.3453 83.1226 26.896 80.3674C17.8021 77.0045 11.2728 70.7892 7.16144 62.151C3.05822 53.5451 0.819362 44.445 0.518134 30.969Z"
        fill="#FFFDF3"
      />
      <path
        d="M43.075 15C28.0871 15 16 27.0871 16 42.075C16 57.0629 28.0871 69.15 43.075 69.15C58.0629 69.15 70.15 57.0629 70.15 42.075C70.15 27.0871 58.0629 15 43.075 15ZM53.9534 43.6463L38.9654 52.4699C38.6028 52.5907 38.361 52.5907 37.9984 52.7116C37.7567 52.7116 37.3941 52.5907 37.1523 52.4699C36.548 52.1073 36.1854 51.5029 36.1854 50.8986V33.2515C36.1854 32.6471 36.548 32.0427 37.1523 31.6801C37.7567 31.4384 38.361 31.4384 38.9654 31.6801L53.9534 40.5037C54.5577 40.8663 54.7994 41.4706 54.7994 42.075C54.7994 42.6794 54.4368 43.2837 53.9534 43.6463Z"
        fill="#FEB81C"
      />
    </g>
    <defs>
      <clipPath id="play-button">
        <rect width="85.5" height="83.3354" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RecorderPlay;
