import { css } from '@emotion/css';

import { colors } from 'constants/design';

const style = css`
  & > #logo {
    font-size: 32px;
    color: ${colors.primary};
    font-family: Avenir-Black;
    line-height: 32px;
    text-align: left;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
`;

export function IllumeLogo<LogoProps>(props: {
  logoAs?: React.ElementType<LogoProps>;
  logoProps?: LogoProps;
}): JSX.Element {
  const { logoAs: C = 'div', logoProps } = props;
  return (
    <div className={style} aria-label="illume logo">
      {/* //@ts-ignore  */}
      <C id="logo" {...logoProps}>
        illume
      </C>
    </div>
  );
}
