import { useState, useEffect, useCallback } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/illume/buttons/Button';
import Link from 'components/illume/link/Link';
import Text from 'components/illume/text/Text';
import { colors, rem } from 'constants/design';
import { wait, noop } from 'utils';

import { NumericVerifyInput } from './NumericVerifyInput';

const useStyles = makeStyles((theme) => ({
  resendText: {
    marginTop: theme.spacing(1),
  },
}));

const buttonStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
}));

const errorStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const inputContainerStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 264, // from design
    [theme.breakpoints.up('md')]: {
      maxWidth: 350, // from design
    },
  },
  box: {
    width: 40,
    height: 60,
    [theme.breakpoints.up('ip5')]: {
      width: 60,
      height: 80,
    },
    [theme.breakpoints.up('md')]: {
      width: 78.5,
      height: 100,
    },
  },
}));

const NumericVerify = ({
  onResend,
  onVerify,
  verifying,
  expiration,
  error,
  sendDigitsError,
  onFocus = noop,
  autoFocus = true,
  digitLength = 4,
  autoVerify = true,
  verifyButtonVisible = true,
  customButtonColor,
  color,
  background,
  onChange = noop,
}) => {
  const MAX_RESEND_ATTEMPT = 3;
  const RESEND_AVAILABILITY_INTERVAL = 15000;

  const [resend, setResend] = useState({
    loading: false,
    count: 0,
    resendAvailable: false,
    showSent: false,
  });
  const showResendButton = resend.count <= MAX_RESEND_ATTEMPT && resend.resendAvailable;
  const [activeInput, setActiveInput] = useState(0);
  const initialValue = Array(digitLength).fill('');
  const [otpValues, setOTPValues] = useState(initialValue);
  const otpString = otpValues.join('');
  const isValid = otpString.length === digitLength;
  const isError = !!error;

  const getErrMessage = (e) => {
    let message = e.message;
    if (e.errorCode === 'InvalidCredentials') {
      message = 'oops! you entered an incorrect code. please re-enter the code or check spam.';
    } else if (e.errorCode === 'ChallengeNotValid') {
      message = 'oops! this code is not longer valid. go back and try again.';
    } else if (e.errorCode === 'ChallengeExpired') {
      message = 'oops! this code is expired, use the link below to resend a new code'; // TODO: better wording
    }
    return message;
  };

  const classes = useStyles();

  const handleVerify = () => {
    onVerify(otpString);
  };

  const resetNumbers = (activeInput = -1) => {
    setActiveInput(activeInput);
    setOTPValues(initialValue);
  };

  useEffect(() => {
    if (isError) {
      resetNumbers(0);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isError]);

  // set a timer on the load of this component
  useEffect(() => {
    if (expiration) {
      const timer = setTimeout(() => {
        // this assumes this is only set 1 time!
        setResend((prev) => ({ ...prev, resendAvailable: true }));
      }, RESEND_AVAILABILITY_INTERVAL);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoVerify) {
      if (isValid) handleVerify();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isValid]);

  const handleResend = async () => {
    // invoke the parent's handler
    setResend((prev) => ({ ...prev, loading: true }));
    await onResend();
    resetNumbers();
    setResend((prev) => ({
      ...prev,
      count: prev.count + 1,
      resendAvailable: false,
      loading: false,
      showSent: true,
    }));
    await wait(RESEND_AVAILABILITY_INTERVAL);
    setResend((prev) => ({ ...prev, resendAvailable: true, showSent: false }));
  };

  const buttonClasses = buttonStyles();
  const errorClasses = errorStyles();
  const inputClasses = inputContainerStyles();

  // Change OTP value at focussing input
  const changeValueAtFocus = useCallback(
    (str) => {
      setOTPValues((otpValues) => {
        const updatedOTPValues = [...otpValues];
        updatedOTPValues[activeInput] = str[0] || '';
        return updatedOTPValues;
      });
    },
    [activeInput],
  );

  const focusPrevInput = useCallback(() => {
    setActiveInput((prev) => (prev > 0 ? prev - 1 : prev));
  }, []);

  const focusNextInput = useCallback(() => {
    setActiveInput((prev) => {
      const beforeLastIndex = prev < digitLength - 1;
      return beforeLastIndex ? prev + 1 : prev;
    });
  }, [digitLength]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      setActiveInput(index);
      onFocus(index);
    },
    [onFocus],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const digit = e.currentTarget.value;
      if (!digit) {
        e.preventDefault();
        return;
      }
      onChange(digit);
      changeValueAtFocus(digit);
      focusNextInput();
    },
    [changeValueAtFocus, focusNextInput, onChange],
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeValueAtFocus('');
            focusPrevInput();
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default:
          noop();
      }
    },
    [activeInput, changeValueAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .replace(/\s/g, '')
        .slice(0, digitLength - activeInput)
        .split('');
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift() || val;
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, digitLength - 1));
      }
    },
    [activeInput, digitLength, otpValues],
  );

  // TODO:: need better text and styling for errors
  if (!onResend || !onVerify) return <>Incorrectly configured component...</>;

  return (
    <>
      <Grid className={inputClasses.container} container justifyContent="space-between">
        {otpValues.map((value, index) => {
          return (
            <Grid item key={index}>
              <Box className={inputClasses.box}>
                <NumericVerifyInput
                  color={color}
                  background={background}
                  focus={activeInput === index}
                  value={value}
                  autoFocus={autoFocus}
                  error={!!error}
                  onFocus={handleOnFocus(index)}
                  onChange={handleOnChange}
                  onKeyDown={handleOnKeyDown}
                  onBlur={onBlur}
                  onPaste={handleOnPaste}
                />
              </Box>
            </Grid>
          );
        })}
        {error && (
          <Grid item xs={12} classes={errorClasses}>
            <Text color={colors.errorText} fontSize={rem[875]} fontWeight={400} italic>
              {getErrMessage(error)}
            </Text>
          </Grid>
        )}
        <Grid xs={12} item container direction="column" alignItems="center" classes={buttonClasses}>
          {verifyButtonVisible ? (
            <Grid item style={{ alignSelf: 'stretch' }}>
              <Button customcolor={customButtonColor} fullWidth onClick={handleVerify}>
                <Text
                  color={colors.contrastText}
                  fontWeight={700}
                  fontSize={{ desktop: '16px', mobile: '14px' }}
                >
                  {verifying ? 'verifying..' : 'verify'}
                </Text>
              </Button>
            </Grid>
          ) : // loading state for non button version
          verifying ? (
            <>
              <Grid item>
                <CircularProgress style={{ color: color }} />
              </Grid>
              <Grid item>
                <Text color={colors.contrastText}>verifying..</Text>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>

      {/* Might be an enhacement to add a countadown timer when the resend is not yet an option */}
      {showResendButton ? (
        <Grid container justifyContent="center" className={classes.resendText}>
          {resend.loading ? (
            <Text color={colors.gray60}>sending new code..</Text>
          ) : sendDigitsError ? (
            <Text color={colors.alertRed}>oops! failed to resend the code</Text>
          ) : (
            <Link onClick={handleResend} italic underline>
              resend code
            </Link>
          )}
        </Grid>
      ) : resend.showSent ? (
        <Grid container justifyContent="center" className={classes.resendText}>
          <Text color={colors.alertGreenDark}>a new code has been sent!</Text>
        </Grid>
      ) : null}
    </>
  );
};

export default NumericVerify;
