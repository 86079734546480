import { css, keyframes } from '@emotion/css';

// wiggle effect
export const getWiggleOnHoverStyle = () => {
  return css`
    &:hover {
      transform-origin: 50% 20%;
      animation: ${keyframes`
            0% { transform: rotate(2deg); }
            35% { transform: rotate(-7deg); }
            50% { transform: rotate(3deg); }
            75% { transform: rotate(-2deg); }
            100% { transform: rotate(0deg); }
          `} 1.2s;
    }
  `;
};
