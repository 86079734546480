import { css, cx } from '@emotion/css';
import classNames from 'classnames';

import { ColorsV2 } from 'domain/theme/colorsV2';

const buttonWidth = 18;
const colorMain = ColorsV2.butternutStone;

const eleven = 6;

const styles = {
  buttonContainer: css`
    cursor: pointer;
    display: flex;
    row-gap: 4px;
    flex-direction: column;
    width: ${buttonWidth}px;

    &.active {
      .top {
        transform: translateY(${eleven}px) translateX(0) rotate(45deg);
      }
      .middle {
        opacity: 0;
      }
      .bottom {
        transform: translateY(-${eleven}px) translateX(0) rotate(-45deg);
      }
    }

    span {
      display: block;
      background: ${colorMain};
      outline: none;
      border: none;
      height: 2px;
      width: 100%;
      transition: transform 0.35s ease;
      border-radius: 2px;
    }
  `,
};

export function HamburgerMenuButton({
  className,
  isActive,
  onClick: onClick,
  ...rest
}: {
  className?: string;
  isActive: boolean;
  onClick?: () => void;
}) {
  return (
    <button onClick={onClick} className={classNames(css``, className)} {...rest}>
      <Hamburger isActive={isActive} />
    </button>
  );
}

export function Hamburger({ isActive, className }: { isActive: boolean; className?: string }) {
  return (
    <div className={cx(styles.buttonContainer, { active: isActive }, className)}>
      <span className="top"></span>
      <span className="middle"></span>
      <span className="bottom"></span>
    </div>
  );
}
