import { IHttpClient } from 'domain/interfaces/IHttpClient';
import {
  IOccasionService,
  OccasionCategory,
  OccasionType,
} from 'domain/interfaces/IOccasionService';
import { CARD_ERRORS } from 'types';
import { wait } from 'utils';
import logger from 'utils/logger';

const mockResponse = {
  success: true,
  occasionTypes: [
    OccasionType.ANNIVERSARY,
    OccasionType.BABY_SHOWER,
    OccasionType.BIRTHDAY,
    OccasionType.CONGRATULATIONS,
    OccasionType.FAREWELL,
    OccasionType.GRADUATION,
    OccasionType.JUST_BECAUSE,
    OccasionType.THANK_YOU,
    OccasionType.THINKING_OF_YOU,
    OccasionType.WEDDING,
    OccasionType.OTHER,
    OccasionType.ENGAGEMENT,
  ],
  familyOccasions: [
    OccasionType.WEDDING,
    OccasionType.MOTHER_FATHERS_DAY,
    OccasionType.JUST_BECAUSE,
    OccasionType.BIRTHDAY,
  ],
  friendsOccasions: [
    OccasionType.THINKING_OF_YOU,
    OccasionType.BIRTH,
    OccasionType.WEDDING,
    OccasionType.GET_WELL_SOON,
    OccasionType.NEW_JOB,
    OccasionType.BIRTHDAY,
  ],
  colleaguesOccasions: [
    OccasionType.CELEBRATE_MILESTONE,
    OccasionType.WELCOME_TEAM_MEMBER,
    OccasionType.GREAT_COLLEAGUE,
    OccasionType.HARD_WORK,
  ],
  specialOccasions: [
    OccasionType.CHRISTMAS,
    OccasionType.MOTHERS_DAY,
    OccasionType.CELEBRATION_OF_LIFE_DAY,
    OccasionType.VALENTINES,
    OccasionType.INTERNATIONAL_COWORKING_DAY,
    OccasionType.NEW_YEARS,
    OccasionType.MEMORIAL_DAY,
    OccasionType.FATHERS_DAY,
    OccasionType.FATHER_IN_LAW_DAY,
    OccasionType.WORLD_LISTENING_DAY,
    OccasionType.PARENTS_DAY,
  ],
};

export class OccasionService implements IOccasionService {
  constructor(private httpClient: IHttpClient) {}
  getOccasions: () => Promise<Record<OccasionCategory, OccasionType[]>> = async () => {
    const requestObj = {
      url: '/occasion/types',
      method: 'get',
    };
    const resp = await wait(1000).then(() => mockResponse);
    const {
      occasionTypes,
      specialOccasions,
      colleaguesOccasions,
      familyOccasions,
      friendsOccasions,
    } = resp;

    return {
      occasionTypes,
      specialOccasions,
      colleaguesOccasions,
      familyOccasions,
      friendsOccasions,
    };
  };

  addOccasion = async (occasions: string) => {
    const requestData = {
      occasions,
    };

    const requestObj = {
      url: '/occasion',
      method: 'post',
      data: requestData,
    };

    const resp = await this.httpClient.makeRequest(requestObj);
    if (resp.success) {
      return true;
    } else {
      const errMsg = resp?.message || 'Failed to create the new occasions';
      if (resp?.errorCode === CARD_ERRORS.UNEXPECTED_ERROR) {
        logger.error(errMsg, resp);
      }
      throw Error(errMsg);
    }
  };
}
