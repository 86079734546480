import { Box, useMediaQuery } from '@material-ui/core';
import { AnimatePresence } from 'framer-motion';

import { BaseIconButton } from 'components/illume/buttons';
import { Gift } from 'components/illume/icons';
import { Note } from 'components/illume/note';
import { Surface } from 'components/illume/surface';
import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { noop } from 'utils';

import { useStyles, useYouGotGiftStyles } from './SummaryCards.styles';

export const YouGotGift = ({ background, onClick = noop }) => {
  const classes = useYouGotGiftStyles();
  const upSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const upMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <Surface
      rounded={['top', 'bottom']}
      background={background}
      className={classes.root}
      onClick={onClick}
    >
      <Box mb={2}>
        <BaseIconButton
          icon={Gift}
          onClick={onClick}
          color={colors.contrastText}
          size={upMd ? 112 : upSm ? 64 : 120}
          iconProps={{ fillOpacity: 1 }}
        />
      </Box>
      <Text
        color={colors.contrastText}
        fontWeight={900}
        fontSize={{ mobile: rem[1125], desktop: rem[1250] }}
      >
        You got a group gift!
      </Text>
    </Surface>
  );
};

export const SummaryGridCards = ({ notes = [], onViewNote }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardWrapper}>
      <AnimatePresence>
        {notes?.map((note) => {
          return (
            <div className={classes.cardList}>
              <Note
                note={note}
                onViewNote={() => {
                  onViewNote(note);
                }}
              />
            </div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};
