import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import { colors, spacing, fontFamily, DESKTOP_MIN_WIDTH } from 'constants/design';

const {
  primary,
  primaryDark,
  primaryDarker,
  textInputBackground,
  contrastText,
  background,
  backgroundGray,
  backgroundGrayDark,
  lightBlue,
  lightGreen,
  lightPurple,
  darkOrange,
  pink,
  alertGreen,
  alertGreenDark,
  gray40,
  gray60,
} = colors;

const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
  shadows: ['none'],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: DESKTOP_MIN_WIDTH,
      lg: 1280,
      xl: 1920,
      //
      ip5: 340,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.uploadcare': () => {
          const primaryStyles = {
            borderColor: primary,
            background: primary,
          };

          const primaryDarkStyles = {
            background: primaryDark,
            borderColor: primaryDark,
          };

          return {
            '&--text': {
              fontFamily,
            },
            '&--menu': {
              fontFamily,
              fontWeight: 500,
            },
            '&--powered-by': {
              display: 'none !important',
            },
            '&--file-source_all': primaryStyles,
            '&--button': {
              '&:not(&_icon), &:not(&_icon)': {
                ...primaryStyles,
                '&:hover, &:focus': primaryDarkStyles,
              },
              '&.uploadcare--preview__back': {
                background: 'none',
                color: primary,
                '&:hover, &:focus': {
                  color: contrastText,
                  background: `${primary} !important`,
                  borderColor: `${primary} !important`,
                },
              },
              fontFamily,
              '&:focus': {
                outlineColor: primaryDark,
              },
            },
            '&--menu__item': {
              '&:hover, &:focus': {
                color: primaryDark,
              },
            },
            '&--dialog__close': {
              '&:hover, &:focus': {
                color: primary,
              },
            },
          };
        },
      },
    },
    MuiInput: {
      input: {
        height: '1.688em',
        paddingLeft: spacing[4],
        [breakpoints.up('md')]: {
          height: 40,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: colors.labelText,
        },
      },
    },
  },
  palette: {
    illume: {
      primaryShaded: {
        main: primaryDark,
        dark: primaryDarker,
      },
      textInputBackground: {
        main: textInputBackground,
        contrastText,
      },
    },
    primary: {
      main: primary,
      dark: primaryDark,
      contrastText,
    },
    lightBlue: {
      main: lightBlue,
    },
    lightGreen: {
      main: lightGreen,
    },
    lightPurple: {
      main: lightPurple,
    },
    darkOrange: {
      main: darkOrange,
    },
    pink: {
      main: pink,
    },
    gray: {
      main: backgroundGray,
      dark: backgroundGrayDark,
    },
    green: {
      main: alertGreen,
      dark: alertGreenDark,
    },
    white: {
      main: '#FFF',
      dark: '#FFF',
    },
    darkGray: {
      main: gray40,
      dark: gray60,
    },
    background: {
      default: background,
      gray: backgroundGray,
    },
  },
  typography: {
    fontWeightRegular: 500,
    fontFamily,
    button: {
      textTransform: 'none',
    },
  },
  mixins: {
    flexy: (justifyContent = 'center', alignItems = 'center') => ({
      display: 'flex',
      justifyContent,
      alignItems,
    }),
  },
});

export type Theme = typeof theme;

export default theme;
