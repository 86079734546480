import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Button } from 'components/illume/buttons';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { rem } from 'constants/design';
import { APPBAR_ICONS } from 'constants/types';
import { useConfettiCannon } from 'hooks';

import { RecipientStore } from './store';

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      width: '100%',
      height: '100vh',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      overflow: 'hidden',
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    headingBox: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(5),
      width: 264,
      [theme.breakpoints.up('md')]: {
        width: 350,
      },
    },
    wrapper: {
      margin: 'auto',
    },
  });
});

const Celebratory: React.FunctionComponent<{ store: RecipientStore }> = ({ store }) => {
  const fireConfettiCannon = useConfettiCannon({ auto: false });

  const classes = useStyles();

  const giftTitle =
    store.cardGift?.product.name || store.externalGift?.title || 'title not defined';

  React.useEffect(() => {
    fireConfettiCannon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IllumeLayout
      hideHamburger
      topRightButtons={[
        {
          icon: <APPBAR_ICONS.TILED size={30} />,
          action: {
            onAction: () => store.pushToNext(),
          },
        },
      ]}
      contentProps={{
        justifyContent: 'center',
      }}
    >
      <Grid className={classes.container}>
        <div className={classes.wrapper}>
          <Grid className={classes.headingBox}>
            <Text fontWeight={800} fontSize={{ mobile: rem[1250], desktop: rem[2250] }}>
              woohoo! your gift is on the way!
            </Text>
          </Grid>

          <Text fontWeight={300} fontSize={rem[1000]}>
            Get pumped! Your {giftTitle} is on its way to you! Check your email for details.
            {store.card?.externalGift && 'Your gift order will be submitted within 24 hours'}
          </Text>
          <Grid item className={classes.button}>
            <Button fullWidth onClick={() => store.pushToNext()}>
              Back to my card
            </Button>
          </Grid>
        </div>
      </Grid>
    </IllumeLayout>
  );
};

export default Celebratory;
