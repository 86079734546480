import { colors } from 'constants/design';

const RightArrowFixed = ({
  size = 61,
  blobColor = colors.contrastText,
  arrowColor = 'none',
  fillOpacity = 1,
}) => (
  <svg
    width={size}
    height={(size * 59) / 60}
    viewBox="0 0 61 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2851 6.12862C3.74344 10.5187 -0.255795 16.302 0.0127253 21.7327C0.224114 31.1895 1.79524 37.5755 4.67469 43.6147C7.55986 49.6766 12.1418 54.0383 18.5235 56.3982C23.7511 58.3316 29.1329 58.9117 34.6576 58.1781C42.5589 57.1318 49.3405 53.9586 54.4424 47.7091C59.31 41.7438 61.1725 35.0393 59.2643 27.442C57.8874 21.9544 55.4479 16.9389 52.5341 12.128C50.883 9.39842 48.9691 6.89063 46.4953 4.84914C42.6903 1.70444 38.1597 0.396526 33.3492 0.089449C25.008 -0.450778 17.2666 1.44286 10.2851 6.12862ZM45.0101 28.1161L37.0552 20.1612C36.567 19.673 35.7755 19.673 35.2874 20.1612C34.7992 20.6493 34.7992 21.4408 35.2874 21.9289L41.1085 27.75H15V30.25H41.1085L35.2874 36.0711C34.7992 36.5592 34.7992 37.3507 35.2874 37.8388C35.7755 38.327 36.567 38.327 37.0552 37.8388L45.0101 29.8839C45.4983 29.3957 45.4983 28.6043 45.0101 28.1161Z"
      fill={blobColor}
      fillOpacity={fillOpacity}
    />
    <path
      d="M45.0101 29.8839C45.4983 29.3957 45.4983 28.6043 45.0101 28.1161L37.0552 20.1612C36.567 19.673 35.7755 19.673 35.2874 20.1612C34.7992 20.6493 34.7992 21.4408 35.2874 21.9289L42.3585 29L35.2874 36.0711C34.7992 36.5592 34.7992 37.3507 35.2874 37.8388C35.7755 38.327 36.567 38.327 37.0552 37.8388L45.0101 29.8839ZM15 30.25H44.1262V27.75H15V30.25Z"
      fill={arrowColor}
    />
  </svg>
);

export default RightArrowFixed;
