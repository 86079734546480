import { css } from '@emotion/css';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { colors } from 'constants/design';

import { createRipple } from '../createRipple';
import { getRippleContext } from '../index';

export type MenuProps = {
  menus: Array<{ label: string; link: string }>;
  activeIndex?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const menuActiveClass = css`
  color: #000000;
  background-color: ${colors.primary};
`;

export const menuInactiveClass = css`
  color: #606060;
  &:hover {
    color: #323232;
    background-color: #f5f0e1;
  }
`;

export const menuStyle = css`
  // typography
  padding: 2px 8px;
  font-family: brandon-grotesque, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 24px;
  transition: background-color 0.2s ease-in-out;
`;

export const Menus = (props: MenuProps) => {
  const { menus, className, activeIndex, ...rest } = props;

  return (
    <div
      className={classNames(
        css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 32px;
          column-gap: 24px;
          ${className}
        `,
      )}
      {...rest}
    >
      {menus.map((menu, i) => (
        <RouterLink
          to={menu.link}
          onClick={(e) => {
            createRipple(e);
          }}
          key={menu.link}
          className={classNames(
            menuStyle,
            activeIndex === i ? menuActiveClass : menuInactiveClass,
            getRippleContext(),
          )}
        >
          {menu.label}
        </RouterLink>
      ))}
    </div>
  );
};
