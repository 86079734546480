import { useState } from 'react';

import { NoteDTO } from '@illume/shared';
import { Box, CircularProgress, Grid, Modal as MUIModal, Paper, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import RetryIcon from '@material-ui/icons/Replay';
import { observer } from 'mobx-react';

import { BaseIconButton, Button } from 'components/illume/buttons';
import { Close } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import {
  colors,
  generateColorFromTheme,
  generateContrastFromTheme,
  PRIMARY_THEME,
  rem,
  spacing,
} from 'constants/design';
import {
  generateBackgroundFromPalette,
  generateContrastFromPalette,
} from 'constants/design/cardTheme';
import { useCardContext } from 'contexts/card';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { INoteDisplayContentNote, NoteDisplayContent } from 'pages/illume/note-display/NoteDisplay';
import { noop } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';

const useModalStyles = makeStyles(() => ({
  root: {
    outline: 'none',
  },
}));
const usePaperStyles = makeStyles<Theme, { background: string }>((theme) =>
  createStyles({
    close: {
      position: 'absolute',
      right: -6,
      top: '-72px',
      zIndex: 10000000,
      height: 200,
    },
    scroll: {
      overflowY: 'auto',
      height: '100%',
      paddingTop: theme.spacing(5),
      '&::-webkit-scrollbar': {
        width: 5,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.contrastText,
        borderRadius: '5px',
        backgroundClip: 'padding-box',
      },
    },
    root: {
      position: 'absolute',
      outline: 0,
      zIndex: theme.zIndex.modal,
      top: 85,
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(7),
      paddingRight: theme.spacing(3),
      right: 25,
      left: 25,
      bottom: 73,
      borderRadius: 16,
      background: ({ background }) => background,
      [theme.breakpoints.up('md')]: {
        top: 64,
        bottom: 64,
        left: 71,
        right: 71,
        borderRadius: 22,
        paddingTop: theme.spacing(15),
        paddingRight: theme.spacing(5),
      },
    },
  }),
);

const Loading = ({ color = undefined }) => (
  <Grid
    style={{ height: '100%' }}
    container
    alignItems="center"
    direction="column"
    justifyContent="center"
  >
    <Grid item>
      <CircularProgress size={64} style={{ color }} />
    </Grid>
    <Grid item>
      <Text color={colors.contrastText}>Loading notes..</Text>
    </Grid>
  </Grid>
);

type PreviewModalProps = {
  onClose?: () => any;
  onRetry?: () => any;
  open: boolean;
  notes: INoteDisplayContentNote[];
  name?: string;
  loading?: boolean;
  error?: Error | null;
  showPrivacyModal?: boolean;
  showNavigationButtons?: boolean;
};
const PreviewModal = ({
  open,
  onClose = noop,
  notes,
  name,
  onRetry = noop,
  loading,
  error,
  showPrivacyModal,
  showNavigationButtons = true,
}: PreviewModalProps) => {
  const [noteIndex, setNoteIndex] = useState(0);
  const currentNote = notes[noteIndex];
  const { theme = PRIMARY_THEME, palette: paletteCode } = currentNote || {};
  const { getPaletteDto: getPalette } = useCardContext();
  const palette = getPalette(paletteCode);
  const isDesktop = useIsDesktop();

  const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateContrastFromPalette(palette, theme)
    : generateContrastFromTheme(theme);

  const mainColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateBackgroundFromPalette(palette, theme, { isMobile: !isDesktop })
    : generateColorFromTheme(theme);

  const paperClasses = usePaperStyles({
    background: mainColor,
    // contrastColor,
  });

  const modalClasses = useModalStyles();

  const handleClose = () => {
    setNoteIndex(0);
    onClose();
  };

  return (
    <MUIModal classes={modalClasses} onClose={onClose} open={open}>
      <Paper classes={paperClasses}>
        {/* TODO: better err handling */}
        {loading ? (
          <Loading color={contrastColor} />
        ) : error ? (
          <Grid
            direction="column"
            style={{ height: '100%' }}
            item
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Text color={colors.contrastText} fontSize={rem[2250]}>
                Oops! There is an error
              </Text>
            </Grid>
            <Grid item>
              <Button fullWidth onClick={onRetry}>
                <RetryIcon />
                <Text>Retry</Text>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <div style={{ position: 'relative', height: '100%' }}>
            <Box className={paperClasses.close}>
              <BaseIconButton
                icon={Close}
                iconProps={{ color: colors.contrastText }}
                onClick={handleClose}
                size={20}
              />
            </Box>
            <div className={paperClasses.scroll}>
              <NoteDisplayContent
                showNavigationButtons={showNavigationButtons}
                showPrivacyModal={showPrivacyModal}
                recipientName={name}
                notes={notes}
                noteIndex={noteIndex}
                setNoteIndex={setNoteIndex}
                onLastNoteClick={handleClose}
                paddingRight={0}
                marginTop={spacing[1]}
                paddingBottom={undefined}
                paddingLeft={undefined}
              />
            </div>
          </div>
        )}
      </Paper>
    </MUIModal>
  );
};

PreviewModal.notesFromDto = (notes: NoteDTO[]): INoteDisplayContentNote[] => {
  return notes.map((note) => ({
    ...note,
    videos: note.videos.map((v) => ({ url: v.url || v.deliveryUrl })),
    photos: note.photos.map((p) => ({ url: p.url || p.deliveryUrl })),
    gifs: note.gifs.map((g) => ({ url: g.giphyCode })),
  }));
};

export default observer(PreviewModal);
