import { PaymentRequest, PaymentRequestOptions, StripeError } from '@stripe/stripe-js';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { autorun } from 'mobx';

import { ICardService } from 'domain/interfaces/ICardService';
import { IPricing } from 'pages/illume/initiator/paywall/constants';
import stripeService from 'services/stripeService';
import { MakeOptional } from 'types';
import { noop } from 'utils';
import logger from 'utils/logger';

export type IProduct = {
  type: 'external' | 'shopify';
  id: string;
  product: { name: string } | { title: string };
};

export default class StripeStore {
  getStripeTask = new ObservablePromise(stripeService.stripePromise);
  paymentRequest: PaymentRequest | null = null;
  constructor(private cardService: ICardService) {
    this.getStripeTask.execute().catch();
    autorun(() => {
      logger.log('payment request readyness', this.paymentRequest);
    });
  }
  private _setPaymentRequest(pr: PaymentRequest | null) {
    this.paymentRequest = pr;
  }

  get stripe() {
    return this.getStripeTask.getResultOrDefault(null);
  }
  private createPaymentRequest(opts: MakeOptional<PaymentRequestOptions, 'country' | 'currency'>) {
    if (!this.stripe) {
      console.error('no stripe instance');
      return null;
    }
    const defaultOpts = {
      country: 'US',
      currency: 'usd',
      requestPayerName: true,
      requestPayerEmail: true,
    } as Pick<
      PaymentRequestOptions,
      'country' | 'currency' | 'requestPayerName' | 'requestPayerEmail'
    >;
    const pr = this.stripe.paymentRequest({ ...defaultOpts, ...opts });
    this._setPaymentRequest(pr);
    return pr;
  }

  private _onGoogleOrApplePaySuccess = (amount: number) => {
    logger.log('successful apple/google payment with amount: ', amount);
  };

  setGoogleOrApplePaySuccessCallback(cb: (amount: number) => any) {
    this._onGoogleOrApplePaySuccess = cb;
  }

  public async initStripePaymentButtonForCardPlan(
    product: IPricing,
    opts: {
      onSuccess: () => any;
      onError: (err: StripeError | Error) => any;
      promoCode?: string;
      discount?: number;
    },
  ) {
    const { onSuccess, onError, promoCode, discount } = opts || {
      onSuccess: noop,
      onError: noop,
    };

    logger.log('init stripe payment button for card plan', { product, opts });

    const amount = product.price.getAmount();
    const discountedprice = discount ? product.price.percentage(100 - discount).getAmount() : null;

    const payload = {
      total: {
        label: product.pricingType,
        amount: discountedprice || amount,
      },
    };

    console.log('payment request payload', payload);

    const pr = this.createPaymentRequest(payload);

    if (!pr || !this.stripe) {
      logger.error('no payment request or stripe instance', { pr, stripe: this.stripe });
      return null;
    }

    pr.on('paymentmethod', async (ev) => {
      let paymentIntentError: any;
      let clientSecret;

      try {
        clientSecret = await this.cardService.getClientSecretForCardPayment({
          plan: product.pricingType,
          promoCode,
        });
      } catch (e) {
        paymentIntentError = e;
      }

      if (!clientSecret || !this.stripe) return;

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      );

      if (confirmError || paymentIntentError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
        logger.log('ev fail', { confirmError, paymentIntentError });
        return onError(confirmError || paymentIntentError);
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`
        if (paymentIntent?.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error: handleActionError } = await this.stripe.confirmCardPayment(clientSecret);
          if (handleActionError) {
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            onError(handleActionError);
          } else {
            // The payment has succeeded.
            onSuccess();
          }
        } else {
          // The payment has succeeded.
          onSuccess();
        }
      }
    });

    const ready = await pr.canMakePayment();

    if (ready) {
      return pr;
    } else {
      return null;
    }
  }

  public async initStripePaymentButtonForGift(product: IProduct, amount: number, email: string) {
    const pr = this.createPaymentRequest({
      total: {
        label: 'name' in product.product ? product.product.name : product.product.title,
        amount,
      },
    });
    if (!pr || !this.stripe) {
      logger.error('no payment request or stripe instance', { pr, stripe: this.stripe });
      return null;
    }

    pr.on('paymentmethod', async (ev) => {
      let setupIntentError;
      let clientSecret;
      const service =
        product.type === 'shopify' ? stripeService.setupIntent : stripeService.anyGiftSetupIntent;
      try {
        clientSecret = await service({
          amount: amount,
          identifier: product.id.toString(),
          receiptEmail: email,
        });
      } catch (e) {
        setupIntentError = e;
      }

      if (!clientSecret || !this.stripe) return;
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { setupIntent, error: confirmError } = await this.stripe.confirmCardSetup(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      );

      if (confirmError || setupIntentError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
        logger.error('ev fail', { confirmError, setupIntentError });
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        logger.error('setup intent status', setupIntent.status);
        if (setupIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.

          console.log('setup intent requires action');
          const { error } = await this.stripe.confirmCardPayment(clientSecret);
          if (error) {
            logger.error('confirm card payment error', error);
            // The payment failed -- ask your customer for a new payment method.
          } else {
            console.log('confirm card payment has succeded', error);
            // The payment has succeeded.
            this._onGoogleOrApplePaySuccess(amount);
          }
        } else {
          console.log('not requires action', setupIntent);
          this._onGoogleOrApplePaySuccess(amount);
        }
      }
    });
    const ready = await pr.canMakePayment();
    if (ready) {
      this._setPaymentRequest(pr);
      return pr;
    } else {
      this._setPaymentRequest(null);
      return null;
    }
  }
}
