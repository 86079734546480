import { useEffect } from 'react';

/**
 * @param {string} title - title variable to sync
 */
export const useDocumentTitle = (title) => {
  useEffect(() => {
    if (typeof title === 'string') {
      document.title = title;
    }
  }, [title]);
};
