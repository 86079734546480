import CircularProgress from '@material-ui/core/CircularProgress';

import { colors } from 'constants/design';

import { Text } from '../text';
import BaseButton from './BaseButton';

const CancelButton: React.FC<any> = ({ children, onClick, disabled, loading, size, ...props }) => {
  return (
    <BaseButton size={size} onClick={onClick} customcolor="gray" {...props}>
      {loading ? (
        <CircularProgress size={24} color="primary" />
      ) : (
        <Text color={colors.gray40} fontWeight={500} fontSize={{ desktop: '16px', mobile: '14px' }}>
          {children}
        </Text>
      )}
    </BaseButton>
  );
};

export default CancelButton;
