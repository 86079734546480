import * as Sentry from '@sentry/react';

import config from '../../config';

// IIFE to ensure it gets loaded on first use
(() => {
  const dsn = config.sentry.dsn;
  const autoSessionTracking = true;
  const environment = config.env;

  Sentry.init({ dsn, autoSessionTracking, environment });
})();

export default Sentry;
