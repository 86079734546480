import { Grid, Link } from '@material-ui/core';

import { CloseButton } from 'components/illume/buttons';
import { Surface } from 'components/illume/surface';
import { colors, rem } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

import { Text } from '../text';
import { closeCardStyles } from './CloseCard.styles';

export const CloseCard = ({
  text,
  onClick,
  clickable = true,
  size,
  fixedSize,
  variant = 'primary',
  background,
  loading = false,
  ...props
}) => {
  const desktop = useIsDesktop();
  const closeCardUI = closeCardStyles({ size, fixedSize, clickable });
  const desktopFont = size === 'large' ? rem[1500] : rem[1125];
  const mobileFont = size === 'large' ? rem[1125] : rem[1000];
  const bg = background
    ? background
    : variant === 'primary'
    ? colors.primary
    : colors.textInputBackground;
  const textColor = variant === 'primary' ? colors.contrastText : colors.gray40;

  return (
    <Surface
      rounded={['top', 'bottom']}
      background={bg}
      className={closeCardUI.root}
      onClick={onClick}
      {...props}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={closeCardUI.container}
        spacing={1}
      >
        <Grid item>
          <CloseButton clickable={clickable} loading={loading} onClick={onClick} size={size} />
        </Grid>
        <Grid item>
          <Text
            maxWidth={desktop ? 250 : 150}
            color={textColor}
            fontWeight={700}
            fontSize={desktop ? desktopFont : mobileFont}
          >
            {text}{' '}
            <Link
              id="need_help"
              href="mailto:support@illumenotes.com"
              rel="noreferrer"
              target="_blank"
              underline="always"
              color="inherit"
            >
              illume support
            </Link>
          </Text>
        </Grid>
      </Grid>
    </Surface>
  );
};
