import { isAndroid } from 'react-device-detect';
import { assign, createMachine } from 'xstate';

import { UPLOAD_CARE_SOURCE } from 'constants/strings';
import { multiThreadedFFmegTranscode, singleThreadedFFmpegTranscode } from 'infra/ffmpeg/ffmpeg';
import mediaUploader from 'infra/media-uploader/MediaUploader';
import { checkFF, FLAGS } from 'utils/featureFlag';
import logger from 'utils/logger';

import { getVideoDuration } from './getVideoDuration';
import { VIDEO_MAX_DURATION, VIDEO_MAX_SIZE_MB } from './utils';

const ffmpegTranscoder =
  ({ media }) =>
  async (send) => {
    const transcode = checkFF(FLAGS.MULTI_THREADED_VIDEO_CONVERSION)
      ? multiThreadedFFmegTranscode
      : singleThreadedFFmpegTranscode;

    // give the user faux sense of progress
    // sometimes the onProgress callback takes a while to kick in
    setTimeout(() => {
      send({ type: 'PROGRESS_UPDATE', progress: 5 });
    }, 500);

    const result = await transcode({
      media,
      // params: '-i input -c:v libx264 -preset superfast -crf 28 output.mp4', // slower, but the output filesize is 2x smaller
      params: '-i input -c:v libx264 -preset ultrafast -crf 28 output.mp4',
      outputFileName: 'output.mp4',
      onProgress: (res) => {
        if (res && res.ratio > 0) {
          send({ type: 'PROGRESS_UPDATE', progress: res.ratio * 100 });
        }
      },
    });
    logger.log('succesfully compressed the file', result);
    return result;
  };

const videoUploadService =
  ({ media }) =>
  async (send) => {
    logger.log('preparing to upload', media);
    const video = await mediaUploader.uploadVideo(media, {
      onProgress: ({ value }) => send({ type: 'PROGRESS_UPDATE', progress: value * 100 }),
    });
    return { video, message: 'successfully uploaded video' };
  };

const imageUploadService =
  ({ media }) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (send) => {
    const crop = {};
    if (media.crop) {
      crop.left = media.crop?.left || 0;
      crop.top = media.crop?.top || 0;
      crop.height = media.crop?.height || 0;
      crop.width = media.crop?.width || 0;
    }

    const photoData = {
      source: UPLOAD_CARE_SOURCE,
      sourceId: media.uuid,
      fileName: media.name,
      fileSize: media.size,
      mimeType: media.mimeType,
      sourceBaseUrl: media.originalUrl,
      sourceDeliveryUrl: media.cdnUrl,
      sourceDeliveryModifiers: media.cdnUrlModifiers,
      crop: media.crop ? crop : null,
    };
    const photo = await mediaUploader.uploadPhoto(photoData);
    return { photo, message: 'successfully uploaded an image' };
  };

const gifSaveService = async ({ media }) => {
  const gifInstance = media;
  const gif = await mediaUploader.uploadGif(gifInstance);
  return { gif };
};

const videoValidation = async ({ media }) => {
  const MAX_MB = VIDEO_MAX_SIZE_MB; // arbitary large size until we implement better way to tell the user of video limitation
  const MAX_TIME_SECONDS = process.env.NODE_ENV === 'development' ? 20 : VIDEO_MAX_DURATION;
  const maxVideoMB = (MB) => MB * 1024 * 1024;
  if (media.size > maxVideoMB(MAX_MB))
    throw new Error('Oops! Your video is too large, try making a shorter recording.');
  const duration = await getVideoDuration(media);
  if (Math.floor(duration) > MAX_TIME_SECONDS) {
    // I think it's ok if the user records 15.2 secs
    let message = `Oops, your video size is ${duration.toFixed(
      2,
    )} seconds! you can only record a video for up to ${MAX_TIME_SECONDS} seconds!`;
    if (isAndroid) {
      message += " but don't worry your recorded video is safe within your galery";
    }
    throw new Error(message);
  }
  return media;
};

//actions
const updateProgress = assign({ uploadProgress: (_, event) => event.progress });
const updateTranscodingProgress = assign({ transcodeProgress: (_ctx, ev) => ev.progress });
const resetProgress = assign({ uploadProgress: 0, transcodeProgress: 0 });
const setError = assign({ error: (_, event) => event.data.message });
const setCurrentWorkingMedia = assign({ media: (_, ev) => ev.media });
const setVideos = assign({ videos: (_, event) => [event.data.video] });
const setGifs = assign({ gifs: (_, event) => [event.data.gif] });
const setPhotos = assign({ photos: (_, event) => [event.data.photo] });

export const uploadMachine = createMachine({
  id: 'upload',
  initial: 'idle',
  context: {
    media: null,
    uploadProgress: 0,
    transcodeProgress: 0,
    photos: [],
    videos: [],
    gifs: [],
    isAndroid,
  },
  states: {
    idle: {
      entry: resetProgress,
      on: {
        START_VIDEO_UPLOAD: {
          target: 'validating',
          actions: [setCurrentWorkingMedia],
        },
        START_IMAGE_UPLOAD: {
          target: 'imageUpload',
          actions: [setCurrentWorkingMedia],
        },
        SAVE_GIF: {
          target: 'gifSave',
          actions: [setCurrentWorkingMedia],
        },
        ASSIGN_GIF: {
          target: 'idle',
          actions: assign({ gifs: (_, ev) => [ev.media] }),
        },
        CLEAR: {
          actions: assign({ photos: [], videos: [], gifs: [] }),
        },
      },
    },
    validating: {
      invoke: {
        src: videoValidation,
        onError: { target: 'idle', actions: 'alertErr' },
        onDone: [
          {
            target: 'transcoding',
            cond: (ctx) => {
              logger.log(`device is ${ctx.isAndroid ? 'android' : 'not android'}`);
              if (ctx.isAndroid) {
                logger.log('therefore compressing the file..');
              }
              logger.log('th');
              return ctx.isAndroid;
            },
          },
          { target: 'videoUpload' },
        ],
      },
    },
    transcoding: {
      invoke: {
        src: ffmpegTranscoder,
        onDone: {
          target: 'videoUpload',
          actions: assign({ media: (_, ev) => ev.data }),
        },
        onError: {
          target: 'videoUpload',
          actions: ['ffmpegAlert', setError],
        }, // NOTE: upload anyway if ffmpeg not supported or there's an error
      },
      on: {
        PROGRESS_UPDATE: {
          actions: updateTranscodingProgress,
        },
      },
    },
    imageUpload: {
      initial: 'uploading',
      onDone: 'idle',
      states: {
        uploading: {
          invoke: {
            src: imageUploadService,
            onDone: {
              target: 'success',
              actions: ['alertSuccess', setPhotos],
            },
            onError: 'failure',
            on: {
              PROGRESS_UPDATE: {
                actions: updateProgress,
              },
            },
          },
        },
        success: {
          type: 'final',
        },
        failure: {
          on: { RETRY: 'uploading' },
        },
      },
    },
    videoUpload: {
      initial: 'uploading',
      onDone: 'idle',
      states: {
        uploading: {
          invoke: {
            src: videoUploadService,
            onDone: {
              target: 'success',
              actions: ['alertSuccess', setVideos],
            },
            onError: 'failure',
          },
          on: {
            PROGRESS_UPDATE: { actions: updateProgress },
          },
        },
        success: {
          type: 'final',
        },
        failure: {
          on: { RETRY: 'uploading' },
        },
      },
    },
    gifSave: {
      invoke: {
        src: gifSaveService,
        onDone: {
          target: 'idle',
          actions: [setGifs],
        },
        onError: { target: 'idle', actions: ['alertErr', setError] },
      },
    },
  },
});
