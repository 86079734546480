import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

export const columns = [
  columnHelper.accessor('firstName', {
    cell: (info) => info.getValue() || '...',
    minSize: 200,
    header: () => 'Name',
  }),
  columnHelper.accessor('email', {
    cell: (info) => info.getValue() || '...',
    header: () => 'Email',
  }),
  columnHelper.accessor('deadline', {
    cell: (info) => (info.getValue() ? info.getValue().toLocaleDateString() : '...'),
    header: () => 'Deadline',
  }),
];
