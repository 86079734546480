const RecorderTrash = () => (
  <svg width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#trash-button)">
      <path
        d="M0.0127254 21.7327C-0.255795 16.302 3.74344 10.5187 10.2851 6.12862C17.2666 1.44286 25.008 -0.450778 33.3492 0.0894489C38.1597 0.396526 42.6903 1.70444 46.4953 4.84914C48.9691 6.89063 50.883 9.39842 52.5341 12.128C55.4479 16.9389 57.8874 21.9544 59.2643 27.442C61.1725 35.0393 59.31 41.7438 54.4424 47.7091C49.3405 53.9586 42.5589 57.1318 34.6576 58.1781C29.1329 58.9117 23.7511 58.3316 18.5235 56.3982C12.1418 54.0383 7.55986 49.6766 4.67469 43.6147C1.79524 37.5755 0.224114 31.1895 0.0127254 21.7327Z"
        fill="#FFFDF3"
      />
      <path
        d="M20.0048 21.9161H39.9968C40.7808 21.9161 41.0608 20.9081 40.4168 20.4881C38.7928 19.4521 36.3848 18.8641 33.5848 18.6121C33.2208 16.9601 31.7648 15.7001 30.0008 15.7001C28.2368 15.7001 26.7808 16.9601 26.4448 18.6121C23.6168 18.8641 21.2088 19.4801 19.6128 20.4881C18.9408 20.9081 19.2208 21.9161 20.0048 21.9161ZM30.0008 17.2121C30.8688 17.2121 31.5968 17.7441 31.9328 18.4721C31.2888 18.4441 30.6448 18.4161 30.0008 18.4161C29.3568 18.4161 28.6848 18.4441 28.0688 18.4721C28.4048 17.7441 29.1328 17.2121 30.0008 17.2121Z"
        fill="#282829"
        fillOpacity="0.4"
      />
      <path
        d="M39.632 23.0921H20.368C19.724 23.0921 19.22 23.5961 19.22 24.2401C19.22 24.8841 19.724 25.3881 20.368 25.3881H21.04L22.916 40.3121C23.056 41.4601 24.036 42.3001 25.184 42.3001H34.844C35.992 42.3001 36.972 41.4321 37.112 40.3121L38.96 25.3601H39.632C40.276 25.3601 40.78 24.8561 40.78 24.2121C40.78 23.5681 40.276 23.0921 39.632 23.0921ZM26.416 38.9401C26.388 38.9401 26.388 38.9401 26.36 38.9401C25.996 38.9401 25.716 38.6601 25.688 38.3241L24.848 28.1321C24.82 27.7681 25.1 27.4321 25.464 27.4041C25.828 27.3761 26.164 27.6561 26.192 28.0201L27.032 38.2121C27.088 38.5761 26.808 38.9121 26.416 38.9401ZM30.672 38.2681C30.672 38.6601 30.364 38.9401 30 38.9401C29.636 38.9401 29.328 38.6321 29.328 38.2681V28.0761C29.328 27.6841 29.636 27.4041 30 27.4041C30.364 27.4041 30.672 27.7121 30.672 28.0761V38.2681ZM34.312 38.3241C34.284 38.6881 33.976 38.9401 33.64 38.9401C33.612 38.9401 33.612 38.9401 33.584 38.9401C33.22 38.9121 32.94 38.5761 32.968 38.2121L33.808 28.0201C33.836 27.6561 34.172 27.3761 34.536 27.4041C34.9 27.4321 35.18 27.7681 35.152 28.1321L34.312 38.3241Z"
        fill="#282829"
        fillOpacity="0.4"
      />
    </g>
    <defs>
      <clipPath id="trash-button">
        <rect width="60" height="58.481" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RecorderTrash;
