export const TwoPeople = ({ color = '#282829', size = 24, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7715 6.09236C13.0695 6.09236 11.6903 7.47197 11.6903 9.17361C11.6903 10.8753 13.0695 12.2549 14.7715 12.2549C16.4732 12.2549 17.8528 10.8753 17.8528 9.17361C17.8528 7.47197 16.4732 6.09236 14.7715 6.09236V6.09236Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7716 12.7989C12.4086 12.7989 10.3849 14.4894 9.5432 16.8879C10.8966 18.1956 12.7395 19 14.7716 19C16.8037 19 18.6466 18.1956 20 16.8879C19.1583 14.4894 17.1346 12.7989 14.7716 12.7989Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12852 6C6.70982 6 5.56029 7.14997 5.56029 8.56823C5.56029 9.98648 6.70982 11.136 8.12852 11.136C9.54677 11.136 10.6967 9.98648 10.6967 8.56823C10.6967 7.14997 9.54677 6 8.12852 6V6Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12852 11.5897C6.15904 11.5897 4.4725 12.9986 3.7707 14.9978C4.89891 16.0878 6.43487 16.7581 8.12852 16.7581C8.28664 16.7581 8.44299 16.7523 8.59801 16.7408C9.13147 15.2266 9.95897 13.9447 11.2791 12.999C10.4449 12.1231 9.34023 11.5897 8.12852 11.5897V11.5897Z"
        fill={color}
      />
    </svg>
  );
};
