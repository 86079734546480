import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';

import { Modal } from 'components/illume/modal';
import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { IIllumeModal } from 'types';
import { noop } from 'utils';
import { isValid } from 'utils/form';

import { TextInput } from '../../../../../../../components/illume/text-inputs';
import { pageStyles } from '../../../../MyCards.styles';

export type InitiatorModalFormValues = {
  initiatorName: string;
};
export interface InitiatorModalProps extends IIllumeModal {
  onSave: (values: InitiatorModalFormValues) => any;
  defaultValues: Partial<InitiatorModalFormValues>;
}
export const InitiatorNameModal: React.FunctionComponent<InitiatorModalProps> = ({
  show = false,
  onClose = noop,
  onSave = noop,
  defaultValues,
}) => {
  const pageUIStyles = pageStyles();

  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitted, isSubmitting },
  } = useForm<InitiatorModalFormValues>({
    defaultValues,
  });

  const disableSubmitButton = !isValid(errors) && isSubmitted;

  return (
    <Modal
      show={show}
      onClose={onClose}
      promptConfig={{
        onOk: handleSubmit(onSave),
        okText: 'save',
        okLoading: isSubmitting,
        disabled: disableSubmitButton,
      }}
    >
      <Grid item container direction="column">
        <Grid item>
          <Text
            fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
            align="center"
            fontWeight={900}
            color={colors.gray100}
          >
            EDIT ORGANIZER
          </Text>
        </Grid>
        <Grid className={classNames(pageUIStyles.contributorModal, 'customIllumeScrollbar-orange')}>
          <TextInput
            inputId="edit-initiator-name"
            label="Organizer Name"
            placeholder={defaultValues.initiatorName || 'name'}
            name="initiatorName"
            data-testid="rename-initiator"
            ref={register}
            error={get(errors, 'initiatorName')}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
