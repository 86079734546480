import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { MerchantProduct } from 'pages/illume/marketplace/store';
import { match } from 'ts-pattern';

import { BaseIconButton, Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';

import { useGiftSectionStyles } from '../sections/sections.styles';

function IllumeChevron({
  size = 12,
  orientation = 'right',
}: {
  orientation?: 'right' | 'left';
  size?: number;
}) {
  return (
    <svg
      width={size}
      style={{
        transform: match(orientation)
          .with('right', () => 'rotate(0deg)')
          .with('left', () => 'rotate(180deg')
          .exhaustive(),
      }}
      height={(18 / 12) * size}
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7291 8.19162L2.86081 0.323353C2.40272 -0.107784 1.70212 -0.107784 1.27099 0.323353C0.839847 0.754491 0.839847 1.45509 1.27099 1.91317L8.35781 9L1.27099 16.0868C0.839847 16.518 0.839847 17.2455 1.27099 17.6766C1.70212 18.1078 2.40272 18.1078 2.86081 17.6766L10.7291 9.78144C11.1602 9.3503 11.1602 8.6497 10.7291 8.19162Z"
        fill="#282829"
        fillOpacity="0.4"
      />
    </svg>
  );
}
export function Carousel({
  products = [],
  loading = true,
  onRight,
  onLeft,
  onAddGift,
}: {
  products: MerchantProduct[] | undefined;
  loading: boolean;
  onRight: () => any;
  onLeft: () => any;
  onAddGift: (product: MerchantProduct) => any;
}) {
  const arr = products;
  const giftClasses = useGiftSectionStyles();

  if (loading) {
    return <div className={giftClasses.carouselContainer}>loading..</div>;
  }

  if (isEmpty(products)) {
    return <div className={giftClasses.carouselContainer}>nothing to show</div>;
  }
  return (
    <div className={giftClasses.carouselContainer}>
      {arr.map((product, i) => {
        return (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            className={`card card--${i}`}
            key={product._dto.listingData.id}
          >
            <div
              className="img"
              style={{
                background: `${colors.gray10} url(${product.defaultImage}) center/cover no-repeat`,
              }}
            ></div>
            <div className="title">
              <Text
                fontSize={14}
                fontWeight={'bold'}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                {product.title}
              </Text>
              <Text fontSize={11}>by: {product.vendor}</Text>
            </div>
            <Button
              borderRadius={6}
              fullWidth
              size="compact"
              style={{ padding: '7px 0' }}
              onClick={() => onAddGift(product)}
            >
              <Text fontWeight={500} fontSize={11}>
                ADD GIFT | {product.productPrice.toFormat()}{' '}
              </Text>
            </Button>
            {i !== 1 && <div className={`overlay--${i}`}></div>}
          </motion.div>
        );
      })}
      <div className="btn-left">
        <BaseIconButton onClick={onLeft} icon={IllumeChevron} iconProps={{ orientation: 'left' }} />
      </div>
      <div className="btn-right">
        <BaseIconButton onClick={onRight} icon={IllumeChevron} />
      </div>
    </div>
  );
}
