import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { action, makeAutoObservable } from 'mobx';
import { plural } from 'pluralize';
import { catchError, concat, from, of, retry, tap } from 'rxjs';

import {
  IOccasionService,
  OccasionCategory,
  OccasionType,
} from 'domain/interfaces/IOccasionService';
import OccasionToIconMapper from 'pages/illume/contributor/lets-celebrate-more/lets-celebrate-more-A/occasionMapping';
import { wait } from 'utils';

import { IStorage } from '..';

export class Occasion {
  icon: any;
  get pluralizedName() {
    // RULES: https://www.notion.so/illumenotes/Grammar-incorrect-in-the-title-5c87116a83724e97a7e0c2add3458b17
    if (this.name.endsWith('Because')) {
      return this.name;
    }
    if (this.name.endsWith('You')) {
      return this.name.concat(`'s`);
    }
    return plural(this.name);
  }
  constructor(public name: OccasionType) {
    this.icon = new OccasionToIconMapper().getOcassionIcon(name);
  }
}
export default class OccasionStore {
  occasionsDTO?: Record<OccasionCategory, OccasionType[]>;

  get legacyOccasions() {
    return this.occasionsDTO?.occasionTypes.map((o) => new Occasion(o));
  }

  error? = undefined;

  private getServerOccasionSource = () =>
    // just to demonstrate that the app's still reliable even without server's data
    from(wait(0).then(() => this.occasionService.getOccasions())).pipe(
      retry(3),
      catchError(() => of(null)),
      tap((data) => console.log('occasion from server', data)),
      tap((data) => data && this._cache.set('occasion', data)),
    );
  private getCacheOccasionSource = () =>
    from(this._cache.get('occasion')).pipe(
      tap((cache) => console.log('occasions from cache', cache)),
    );

  get ABTestOccasions() {
    if (!this.occasionsDTO) {
      return undefined;
    }
    return (
      Object.values(omit(this.occasionsDTO, ['occasionTypes']))
        .flat()
        // filter duplicate
        .filter((item, index, array) => array.indexOf(item) === index)
        .map((dto) => new Occasion(dto))
    );
  }

  /**
   * get all occasions, old ones(before A/B) are prioritized
   */
  get oldAndContributorABTestOccasions() {
    const all = this.legacyOccasions?.concat(this.ABTestOccasions || []) || [];
    return uniq(all);
  }

  get loading() {
    return !this.error || !this.occasionsDTO;
  }

  constructor(
    private _cache: IStorage<Record<OccasionCategory, OccasionDTO[]>>,
    private occasionService: IOccasionService,
  ) {
    makeAutoObservable(this);
    concat(this.getCacheOccasionSource(), this.getServerOccasionSource()).subscribe({
      next: action((data) => {
        if (data) {
          this.occasionsDTO = data;
        }
      }),
    });
  }
}
