import { Switch as MUISwitch } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { colors, rem } from 'constants/design';

const NOTE_FORM_MAX_WIDTH = '75%';
export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: NOTE_FORM_MAX_WIDTH,
    [theme.breakpoints.up('md')]: {
      width: NOTE_FORM_MAX_WIDTH,
    },
  },
  lightIcon: {
    display: 'inline-block',
    width: 10,
    [theme.breakpoints.up('md')]: {
      width: 44,
    },
    position: 'relative',
    zIndex: 10,
    overflow: 'visible',
    marginRight: 5,
    marginLeft: 10,
  },
  bulb: {
    position: 'relative',
    top: 4,
    marginLeft: 8,
    [theme.breakpoints.up('md')]: {
      marginLeft: 18,
    },
  },
  addMedia: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      '& img': {
        width: 36,
      },
    },
  },
  button: {
    marginRight: theme.spacing(2),
  },
  mediaButtons: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  footer: {
    marginTop: theme.spacing(15),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(30),
    },
  },
  navArrows: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  arrowLeft: {
    display: 'block',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  arrowRight: {
    display: 'block',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  progressCircle: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  busyText: {
    marginTop: theme.spacing(1),
  },
  note: {
    background: colors.primary,
    fontSize: rem[1500],
    outline: 'none',
    resize: 'none',
    fontWeight: 700,
  },
  name: {
    background: colors.primary,
    fontSize: rem[1500],
    outline: 'none',
    resize: 'none',
    fontWeight: 900,
  },
  avatar: {
    marginBottom: theme.spacing(2),
    // width: '50vh',
  },
  skipButton: {
    color: colors.contrastText,
    border: `solid 1px ${colors.contrastText}`,
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    minWidth: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(10),
      minWidth: theme.spacing(8),
    },
  },
  closeButton: {
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    minWidth: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(8),
    },
  },
  imageWrapper: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(12),
    },
  },
}));

export const inputStyles = makeStyles((theme) => ({
  root: {
    '&::placeholder': {
      color: colors.contrastText,
    },
    color: colors.contrastText,
    background: 'transparent',
    fontSize: rem[1500],
    outline: 'none',
    resize: 'none',
    fontWeight: 700,
    width: '100%',
    lineHeight: rem[2500],
    overflow: 'visible',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem',
      lineHeight: 1.5,
    },
  },
  ghost: {
    width: '100%',
    color: 'rgba(0,0,0,0)',
    fontSize: rem[1500],
    wordBreak: 'break-word',
    fontWeight: 700,
    lineHeight: rem[2500],
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem',
      lineHeight: 1.5,
    },
    '& span': {
      color: colors.gray20,
      '& span': {
        color: colors.contrastText,
      },
    },
  },
  container: {
    position: 'relative',
    '& textarea': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
  },
  name: {
    position: 'relative',
    width: '100%',
    '&::placeholder': {
      color: colors.gray20,
    },
    color: colors.gray40,
    background: 'transparent',
    fontSize: rem[1500],
    outline: 'none',
    resize: 'none',
    fontWeight: 900,
    zIndex: 6,
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem',
    },
  },
}));

export const Toggle = withStyles({
  switchBase: {
    color: colors.contrastText,
    '&$checked': {
      color: colors.contrastText,
    },

    '&$checked + $track': {
      backgroundColor: colors.contrastText,
      opacity: '70%',
    },
  },
  checked: {},
  track: {
    backgroundColor: colors.gray80,
  },
})(MUISwitch);
