import { Grid } from '@material-ui/core';

import { Text } from 'components/illume/text';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';

const vidUrl =
  'https://assets.mixkit.co/videos/preview/mixkit-tree-with-yellow-flowers-1173-large.mp4';

const VideoPlayerExp = () => {
  return (
    <IllumeLayout background={colors.primary}>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        style={{ marginTop: '20px' }}
        spacing={4}
      >
        <Grid item>
          <Text fontWeight={700} fontSize={rem[2000]} align="center">
            Video Player Experiments
          </Text>
          <Text fontWeight={700} fontSize={rem[1500]}>
            it's a test for desktop
          </Text>
        </Grid>

        <Grid item container direction="column" alignItems="center">
          <VideoDisplay vidUrl={vidUrl} size={300} />
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default VideoPlayerExp;
