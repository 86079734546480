import { makeStyles } from '@material-ui/core/styles';

export const textAreaStyles = makeStyles(() => ({
  root: ({ dark, shadow, shadowColor }) => {
    const darkBox = {
      opacity: '60%',
    };

    return {
      ...(dark ? darkBox : {}),
      marginTop: 5,
      width: '100%',
      backgroundColor: '#F5F0E1',
      padding: 15,
      resize: 'none',
      boxShadow: shadow && `5px 5px ${shadowColor}`,
      borderRadius: 10,
      '&:focus': {
        outline: 'none',
      },
    };
  },

  labelParrent: {
    display: 'flex',
    alignItems: 'center',
  },
  mainLabel: {
    marginRight: 5,
  },
}));
