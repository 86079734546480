export const IENotSupported = () => {
  return (
    <div container item direction="column" alignItems="center">
      <p>OOPS!</p>
      <p>
        Currently Internet Explorer is not supported, try using{' '}
        <a href="https://www.google.com/chrome/" underline="always" color="inherit">
          Chrome
        </a>{' '}
        or Safari.
        <br /> Or, if you would like immediate assistance, please contact{' '}
        <a
          id="need_help"
          href="mailto:support@illumenotes.com"
          rel="noreferrer"
          target="_blank"
          underline="always"
          color="inherit"
        >
          illume support
        </a>
        .
      </p>
    </div>
  );
};
