import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { action, makeAutoObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { isDesktop } from 'react-device-detect';

import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { INotificationService } from 'domain/interfaces/INotificationService';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useReaction } from 'hooks/useReaction';
import { NotificationList as NotificationListDTO } from 'types';
import logger from 'utils/logger';

export class StateLoading {
  constructor() {
    makeAutoObservable(this);
  }
}

export class StateError {
  constructor(public error: Error) {
    makeAutoObservable(this);
  }
}

export class StateSuccess {
  private readonly LOAD_MORE_COUNT = 5;
  error?: Error;
  constructor(
    public notifications: NotificationListDTO,
    public refreshing: boolean,
    public total: number,
    private root: NotificationStore,
  ) {
    makeAutoObservable(this);
  }

  get isNotificationsEmpty() {
    return isEmpty(this.notifications);
  }
  get hasMore() {
    return this.total > this.notifications.length;
  }

  refreshNotifications = async () => {
    this.refreshing = true;
    await this.root.service
      .getNotifications({
        offset: this.root.INITIAL_OFFSET,
        count: isDesktop ? this.root.DESKTOP_INITIAL_COUNT : this.root.INITIAL_COUNT,
      })
      .then(
        action((result) => {
          // replace instead append
          this.notifications = result.notifications;
          this.total = result.total;
        }),
      )
      .catch(
        action((e) => {
          this.error = e;
        }),
      )
      .finally(
        action(() => {
          this.refreshing = false;
        }),
      );
  };

  private appendNotifications = (notifications: NotificationListDTO) => {
    this.notifications = [...this.notifications, ...notifications];
    return notifications; // not sure why not returning the updated one, probably something todo with react-inifinte-scroll-component
  };

  loadMoreNotifications = async () => {
    return this.root.service
      .getNotifications({
        count: this.LOAD_MORE_COUNT,
        offset: this.notifications.length,
      })
      .then((result) => this.appendNotifications(result.notifications));
  };
}

type State = StateLoading | StateError | StateSuccess;

const NotificationContext = createContext<NotificationStore>({} as NotificationStore);

export const useNotificationContext = () => useContext(NotificationContext);

class NotificationStore {
  readonly DESKTOP_INITIAL_COUNT = 15;
  readonly INITIAL_COUNT = this.DESKTOP_INITIAL_COUNT;
  readonly INITIAL_OFFSET = 0;
  state: State = new StateLoading();
  unread: number = 0;
  private setState(s: State) {
    this.state = s;
  }
  constructor(public service: Pick<INotificationService, 'getNotifications' | 'getUnreadCount'>) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  fetchUnreadCount = () => {
    return this.service
      .getUnreadCount()
      .then(
        action((unread) => {
          return (this.unread = unread);
        }),
      )
      .catch(logger.error);
  };

  fetchNotifications = async () => {
    try {
      const result_1 = await this.service.getNotifications({
        offset: this.INITIAL_OFFSET,
        count: isDesktop ? this.DESKTOP_INITIAL_COUNT : this.INITIAL_COUNT,
      });
      this.setState(new StateSuccess(result_1.notifications, false, result_1.total, this));
    } catch (e) {
      this.setState(new StateError(e as Error));
    }
  };
}

export const NotificationProvider = observer(({ children }: { children: ReactNode }) => {
  const { notificationService } = useServices();
  const [store] = useState(() => new NotificationStore(notificationService));
  const { authenticationStore } = useStores();

  const { enqueueErrorSnackbar } = useIllumeSnackbar();

  useReaction(() =>
    reaction(
      () => {
        if (store.state instanceof StateError) {
          return store.state.error;
        }
      },
      (e) => {
        if (e) {
          enqueueErrorSnackbar(e.message);
        }
      },
    ),
  );

  useEffect(() => {
    const dispose = reaction(
      () => authenticationStore.authenticated,
      () => {
        store.fetchUnreadCount();
        store.fetchNotifications();
      },
      { fireImmediately: true },
    );
    return () => dispose();
  }, []);

  return <NotificationContext.Provider value={store}>{children}</NotificationContext.Provider>;
});
