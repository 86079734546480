import { match } from 'ts-pattern';

import config from 'config';
import { CardStatus } from 'types';

export const HOMEPAGE_URL = '/';
export const PROD_MARKETING_SITE_URL = 'https://tryillume.com';

export function getProdMarketingSiteUrl(url: string) {
  const env = config.env as 'dev' | 'prod' | 'local';
  return (
    match(env)
      .with('dev', () => `https://www.dev.tryillume.com`)
      .with('local', () => 'http://localhost:8000')
      .with('prod', () => `https://www.tryillume.com`)
      .exhaustive() + url
  );
}

export const MARKETING_SITE_RELATIVE_URLS = {
  B2C_PRICING: '/pricing',
};

// Marketing site
export const MARKETING_SITE_SPLIT_GIFT = getProdMarketingSiteUrl('/splitanygifts');
export const MARKETING_SITE_PRICING = getProdMarketingSiteUrl('/pricing');
export const KUDOBOARD_BLOG_URL = getProdMarketingSiteUrl('/blog/illume-joining-kudoboard');

// Snackbar
// TODO: These strings should be improved before we go to production.
export const NOT_LOGGED_IN =
  'Oops! You need to be logged in to view this page. Redirecting you to the login page now :)';
export const LOGGED_IN = "You're already logged in :)";
export const CARD_MISSING = 'That card does not exist.';
export const AUTO_HIDE_DURATION = 3000;

// Photo
export const UPLOAD_CARE_SOURCE = 'UploadCare';

// Error message
export const LOW_SENTIMENT_SCORE =
  'Oops, your note isn’t positive enough. We run every note through sentiment analysis to ensure that illume remains hate-free.';

// EXPERIMENTS
export const EXPERIMENTS_URL = '/experiments';

export const CARD_STATUS: Record<string, CardStatus> = {
  IN_PROGRESS: 'InProgress',
  READY_TO_SEND: 'ReadyToSend',
  DELIVERED: 'Delivered',
  READ: 'Read',
};

export const MAX_RECIPIENT_NUMBER = 10;

export const HARD_CODED_SUGGESTED_CENTS = 110;
export const MOCK_TAGS = [
  {
    name: 'sustainable',
    color: '#54AD67',
  },
  { name: 'zero-waste', color: '#677690' },
  { name: 'woman-owned', color: '#FCC3AA' },
];

export type URL_MODULE = typeof import('./urls');

export * from './urls';
