import { createContext, useContext } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'contexts/store';

interface AuthContextValue {
  authenticated: boolean;
}
export const AuthenticationContext = createContext<AuthContextValue>({
  authenticated: false,
});

/**
 *
 * @deprecated - use store from mobx
 */
export const useAuth = () => useContext(AuthenticationContext);

/**
 * @deprecated this will be depricated in favor of mobx global store
 * making the authentication provider as observer also make the children render not fine grained
 * which will affect performance
 */
export const AuthenticationProvider: React.FC = observer(({ children }) => {
  const store = useStores().authenticationStore;

  const value = {
    authenticated: store.authenticated,
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
});
