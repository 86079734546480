import { createContext, useContext } from 'react';

import { Maybe } from '@illume/shared';
import { RouterStore } from '@superwf/mobx-react-router';
import { getConfiguredCache } from '@thezano/money-clip';
import ms from 'miliseconds';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import config from 'config';
import ThemepacksStore from 'contexts/card/store';
import MerchantProductStore from 'contexts/merchant-products/merchantProduct.store';
import { IServices } from 'contexts/services';
import { UserProfileStore } from 'contexts/user';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IllumeApiHttpClient } from 'infra/client';
import { routerStore } from 'infra/history/history';
import { IRootVerifyStore } from 'infra/mobx-stores/verification-store/IRootVerifyStore';
import { RootVerifyStoreSimplified } from 'infra/mobx-stores/verification-store/RootVerifyStoreSimplified';
import MarketplaceStore from 'pages/illume/marketplace/store/store';
import { BatchedCardStore } from 'pages/illume/my-cards/MyCards.store';
import logger from 'utils/logger';
import { MyCardsV2Store } from 'views/pages-v2/my-cards/MyCardsV2.store';

import AuthenticationStore from './auth-store/auth.store';
import { IStore } from './IStore';
import OccasionStore from './occasion-store/Occasion.store';
import StripeStore from './StripeStore.store';

export interface IStorage<T> {
  set: (key: string, data: T) => any;
  get: (key: string) => Promise<Maybe<T>>;
  del: (key: string) => Promise<any>;
  clear: () => any;
}
export class Store implements IStore {
  routerStore: RouterStore = routerStore;
  verificationStore: IRootVerifyStore;
  themepackStore: ThemepacksStore;
  stripeStore: StripeStore;
  userProfileStore: UserProfileStore;
  merchantProductStore: MerchantProductStore;
  authenticationStore: AuthenticationStore;
  occasionStore: OccasionStore;
  myCardsV2Store: MyCardsV2Store;
  batchedCardStore: BatchedCardStore;
  marketplaceStore: MarketplaceStore;
  private client: IHttpClient;
  get cache() {
    const maxAge = new ms().days(10).value();
    let _cache = getConfiguredCache({
      version: config.cacheVersion + this.client.authCredentials.jwt,
      maxAge,
      name: 'illume-db',
    });
    return _cache;
  }
  // right now is tightly coupled to the implementation of illume http api
  constructor(client: IllumeApiHttpClient, analytics: IAnalytics, private services: IServices) {
    this.client = makeObservable(client, { authCredentials: observable, setInMemoryJWT: action });
    autorun(() => logger.log('cache now: ', this.cache));
    this.authenticationStore = new AuthenticationStore(
      this.services.authenticationService.loginWithGoogle,
      this.client,
      this.cache.clear,
    );
    this.merchantProductStore = new MerchantProductStore(
      this.services.merchantGiftService,
      this.cache,
      analytics,
    );
    this.userProfileStore = new UserProfileStore(
      this.authenticationStore,
      this.services.userService,
    );
    this.verificationStore = new RootVerifyStoreSimplified(
      {
        issueChallenge: this.services.verificationService.issueChallenge,
        verifyChallenge: (args) => this.services.verificationService.verifyChallenge(args).then(),
      },
      analytics,
    );
    this.themepackStore = new ThemepacksStore();
    this.stripeStore = new StripeStore(this.services.cardService);

    /**
     * @deprecated
     */
    this.batchedCardStore = new BatchedCardStore(
      this.services.batchCardService.getBatchedCards,
      this.cache,
      this.authenticationStore,
      this.services.batchCardService.deleteBatch,
    );

    this.marketplaceStore = new MarketplaceStore(this.routerStore, this.merchantProductStore);
    this.occasionStore = new OccasionStore(this.cache, this.services.occasionService);
    this.myCardsV2Store = new MyCardsV2Store(this.services.cardService, this.cache, 'my-cards');

    reaction(
      () => this.authenticationStore.authenticated,
      () => this.myCardsV2Store.asyncCachedCardsMap.forEach((s) => s.refresh()),
    );
  }
}

export const StoreContext = createContext({} as IStore);

export const StoreProvider: React.FC<{ store: IStore }> = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
export const useStores = () => useContext(StoreContext);
