import { ReactNode } from 'react';

import { cx, css } from '@emotion/css';
import { createPortal } from 'react-dom';

import { ColorsV2 } from 'domain/theme/colorsV2';

const colorMain = ColorsV2.primary;

const overlayStyles = css`
  position: fixed;
  background: ${colorMain};
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  opacity: 0;

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 1000;
  }
`;

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  onClose?: () => void;
  closePosition?: 'top-right' | 'top-left';
  className?: string;
};

export function AnimatedOverlay({ isOpen, onClose, children, closePosition, className }: Props) {
  const closePositionStyle = closePosition === 'top-right' ? { right: 12 } : { left: 10, top: 12 };
  return createPortal(
    <div className={cx(overlayStyles, isOpen && 'open', className)}>
      {children}
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 15,
          padding: '16px',
          ...closePositionStyle,
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            width: '32px',
            height: '32px',
          }}
        >
          <path
            d="M18.2953 17.2963L6.96394 5.96492C6.57342 5.5744 5.94025 5.57439 5.54973 5.96492C5.1592 6.35544 5.1592 6.98861 5.54973 7.37913L16.8811 18.7105C17.2716 19.101 17.9048 19.101 18.2953 18.7105C18.6859 18.32 18.6859 17.6868 18.2953 17.2963Z"
            fill="#655B4F"
          />
          <path
            d="M17.0208 5.97918L5.70711 17.2929C5.31658 17.6834 5.31658 18.3166 5.70711 18.7071C6.09763 19.0976 6.7308 19.0976 7.12132 18.7071L18.435 7.3934C18.8256 7.00287 18.8256 6.36971 18.435 5.97918C18.0445 5.58866 17.4113 5.58866 17.0208 5.97918Z"
            fill="#655B4F"
          />
        </svg>
      </button>
    </div>,
    document.getElementById('animated-overlay') as HTMLElement,
  );
}
