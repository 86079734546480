import React, { CSSProperties } from 'react';

import { Fade, Modal as MUIModal, ModalProps, Paper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { colors, rem, spacing } from 'constants/design';
import { Theme } from 'infra/mui-stylesheet';

import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';

const useStyles = makeStyles<Theme, { zIndex: any; maxWidth: CSSProperties['maxWidth'] }>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      border: 'none',
      outline: 0,
      boxShadow: theme.shadows[5],
      borderRadius: rem[1000],
      marginLeft: spacing[8],
      marginRight: spacing[8],
      padding: '32px 36px 24px 36px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
      width: '100%',
      maxWidth: 800,
      maxHeight: 800,
      overflow: 'auto',
      '& > .steps': {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        columnGap: 24,
        rowGap: 36,
        [theme.breakpoints.up('md')]: {
          flexWrap: 'nowrap',
        },
        '& > .step': {
          // background: 'papayaWhip',
          flex: '0 1 250px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& > .img-container': {
            marginBottom: 16,
            [theme.breakpoints.up('sm')]: {
              display: 'flex',
              alignItems: 'center',
              height: 100,
            },
            '& > img': {
              width: 80,
              [theme.breakpoints.up('sm')]: {
                width: 120,
              },
            },
          },
          [theme.breakpoints.up('md')]: {
            flex: '0 1 250px',
          },
        },
      },
      '& > .button': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 12,
        marginTop: 24,
        '& > .btn': {
          width: 250,
          [theme.breakpoints.up('md')]: {
            width: '100%',
            maxWidth: 350,
          },
        },
      },
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  }),
);

interface Props extends Omit<ModalProps, 'children'> {
  maxWidth?: CSSProperties['maxWidth'];
  zIndex?: CSSProperties['zIndex'];
  onGotIt: () => any;
}

export const HowItWorksModal = (props: Props) => {
  const { maxWidth = 664, zIndex = 1300, onGotIt, ...rest } = props;

  const styles = useStyles({ maxWidth, zIndex });
  const elements = [
    {
      image: step1,
      title: 'Select a group gift',
      text: 'Choose a gift from illume’s marketplace and generate a shareable link. ',
    },
    {
      image: step2,
      title: 'Split the cost',
      text: 'Invite others to pool funds for the gift. Users are charged when the total price is met.',
    },
    {
      image: step3,
      title: 'Send to recipient',
      text: 'Send the gift via email or SMS. The recipient will enter their own address, if needed.',
    },
  ];

  return (
    <MUIModal BackdropProps={{ className: styles.backdrop }} className={styles.modal} {...rest}>
      <Fade timeout={{ appear: 800, exit: 0, enter: 800 }} in={props.open}>
        <Paper className={classNames(styles.paper, 'customIllumeScrollbar-orange')}>
          <div className="title">
            <Text align="center" fontWeight={900} fontSize={{ mobile: 22, desktop: 28 }}>
              HOW DOES GROUP GIFTING WORK?
            </Text>
          </div>
          <div className="steps">
            {elements.map(({ image: src, title, text }, i) => (
              <div key={i} className="step">
                <div className="img-container">
                  <img src={src} alt={`step ${i + 1}`} />
                </div>
                <div className="info" style={{ textAlign: 'center' }}>
                  <Text fontSize={12} color={colors.primary}>
                    Step {i + 1}
                  </Text>
                  <Text fontWeight="bold" fontSize={16}>
                    {title}
                  </Text>
                  <Text fontWeight={300} fontSize={{ desktop: 16, mobile: 14 }}>
                    {text}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className="button">
            <Button className="btn" onClick={onGotIt}>
              got it
            </Button>
            <Text fontSize={12} color={colors.gray40}>
              Still have questions?{' '}
              <a
                href="https://intercom.help/illume/en/articles/6699584-gifting-faqs"
                target={'_blank'}
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Learn more.
              </a>
            </Text>
          </div>
        </Paper>
      </Fade>
    </MUIModal>
  );
};
