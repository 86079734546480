import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DESKTOP_MAX_WIDTH } from 'constants/design';

const WELCOME_PAGE_MAX_WIDTH = 700;
const useStyles = makeStyles<Theme, { notesCount: number; desktop: boolean }>((theme) => ({
  container: {
    maxWidth: WELCOME_PAGE_MAX_WIDTH,
    paddingLeft: ({ notesCount }) => (notesCount < 3 ? theme.spacing(3) : theme.spacing(1.5)),
    paddingRight: ({ notesCount }) => (notesCount < 3 ? theme.spacing(3) : theme.spacing(1.5)),
    paddingBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    height: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: DESKTOP_MAX_WIDTH,
    },
  },
  header: {
    marginTop: theme.spacing(8),
    maxWidth: WELCOME_PAGE_MAX_WIDTH,
  },
  ctaText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(7),
  },
  reminderText: {
    marginTop: theme.spacing(3),
  },
  navButtonContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
      justifyContent: 'flex-start',
    },
  },
  progressCircles: {
    marginTop: theme.spacing(1),
  },
  subText: {
    marginTop: theme.spacing(5),
  },
  rightArrow: {
    marginLeft: theme.spacing(-0.5),
    paddingBottom: theme.spacing(1),
  },
  cardListing: {
    paddingTop: 20,
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  topContent: {
    marginBottom: theme.spacing(3),
  },
  cardWrapper: {
    display: 'grid',
    gap: 10,
    gridTemplateColumns: 'repeat(2, 150px)',
    gridTemplateRows: 'auto',
    // Used desktop here because justifyContent can't be overridden by the breakpoints somehow
    justifyContent: ({ notesCount, desktop }) =>
      !desktop ? (notesCount < 3 ? 'left' : 'center') : 'center',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit,225px)',
      gridTemplateRows: 'repeat(auto,225px)',
      width: 1043,
      gridGap: 20,
    },
  },
  cardList: {
    position: 'relative',
    width: '100%',
    height: '100%',

    borderRadius: '16px',
  },
  containerWithoutGift: {
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / -1',
    },
  },
  containerWithGift: {
    gridColumn: '1 / -1',
  },
}));

export const cardGridStyles = {
  position: 'relative',
  y: 200,
  opacity: 0,
  height: '100%',
};

export const newCardMotionStyles = {
  y: 200,
  opacity: 0,
  height: '100%',
};

export const cardVariants = (delay: number) => ({
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'backOut',
      delay: delay + custom * 0.14,
      duration: 0.6,
    },
  }),
  out: (custom: number) => ({
    y: 50,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'backIn',
      delay: custom * 0.04,
      duration: 0.45,
    },
  }),
});
export default useStyles;
