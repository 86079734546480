import { Decoder, tools, Reader } from 'ts-ebml';

import { singleThreadedFFmpegTranscode, multiThreadedFFmegTranscode } from 'infra/ffmpeg/ffmpeg';
import { checkFF, FLAGS } from 'utils/featureFlag';
import logger from 'utils/logger';

// alternative way to fix metadata beside injectMediaMetadata
// it also converts to .mp4
// the downside is the user have to download the ffmpeg.asm which roughly 6mb compressed
// there's smaller ffmpeg.asm build for mp4 only though
// see: https://github.com/Kagami/ffmpeg.js/
export const ffmpegTranscoder =
  ({ blob }) =>
  async () => {
    const transcode = checkFF(FLAGS.MULTI_THREADED_VIDEO_CONVERSION)
      ? multiThreadedFFmegTranscode
      : singleThreadedFFmpegTranscode;
    return transcode({
      media: blob,
      // TODO: should be args, an array would be better
      params: '-i input -c:v copy -c:a aac output.mp4',
      outputFileName: 'output.mp4',
      onProgress: (res) => {
        logger.log(res);
        // send some event here, if we want to track the progress!
      },
    });
  };

export const injectMediaMetadata = ({ blob }) => {
  logger.log('injecting metadata');
  const decoder = new Decoder();
  const reader = new Reader();
  reader.logging = false;
  reader.drop_default_duration = false;

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const buffer = fileReader.result;

      // const elms = decoder.decode(buffer);
      // see: https://github.com/legokichi/ts-ebml/issues/33
      const fullElms = decoder.decode(buffer);
      const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd']; // This is from elm type of the lib
      const elms = fullElms?.filter((elm) => validEmlType.includes(elm.type));

      elms.forEach((elm) => {
        reader.read(elm);
      });
      reader.stop();

      const refinedMetadataBuf = tools.makeMetadataSeekable(
        reader.metadatas,
        reader.duration,
        reader.cues,
      );
      const body = buffer.slice(reader.metadataSize);

      const result = new Blob([refinedMetadataBuf, body], { type: blob.type });

      resolve(result);
    };
    fileReader.onerror = (ev) => {
      reject(ev.error);
    };

    fileReader.readAsArrayBuffer(blob);
  });
};
