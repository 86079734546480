import { colors } from 'constants/design';

const PhotoDisplay = ({
  size,
  imgUrl,
  color = colors.background,
  redacted = false,
  clipPathID = 'user-space',
}) => {
  const photo = (
    <svg
      width={size}
      height={size}
      viewBox="0 0 245 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id={clipPathID} clipPathUnits="userSpaceOnUse">
          <path
            d="M0.478018 88.3773C-0.613938 66.293 15.6492 42.7749 42.2511 24.9225C70.642 5.86749 102.123 -1.83312 136.043 0.36375C155.605 1.6125 174.029 6.93124 189.503 19.7193C199.563 28.0212 207.346 38.2193 214.06 49.3193C225.909 68.883 235.829 89.2792 241.429 111.595C249.188 142.49 241.614 169.754 221.82 194.012C201.073 219.426 173.495 232.33 141.364 236.585C118.897 239.568 97.0116 237.21 75.7533 229.347C49.8019 219.75 31.169 202.013 19.4362 177.362C7.72675 152.803 1.33764 126.834 0.478018 88.3773Z"
            fill="#FEB81C"
          />
        </clipPath>
      </defs>
      <image
        width="100%"
        height="100%"
        preserveAspectRatio="xMinYMin slice"
        xlinkHref={imgUrl}
        clipPath={`url(#${clipPathID})`}
      />
    </svg>
  );

  const redactedPhoto = (
    <svg
      width={size}
      height={size}
      viewBox="0 0 264 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0559903 95.6214C-1.12547 71.7269 16.4708 46.2811 45.2531 26.9653C75.9711 6.34843 110.032 -1.98338 146.733 0.393566C167.899 1.74467 187.833 7.49938 204.574 21.3357C215.459 30.318 223.88 41.352 231.145 53.3619C243.965 74.5292 254.698 96.5972 260.757 120.742C269.152 154.169 260.958 183.668 239.541 209.915C217.093 237.412 187.255 251.374 152.49 255.977C128.182 259.205 104.502 256.653 81.5014 248.146C53.4228 237.763 33.2626 218.572 20.5682 191.9C7.89888 165.328 0.986076 137.23 0.0559903 95.6214Z"
        fill={colors.background}
      />
    </svg>
  );

  return redacted ? redactedPhoto : photo;
};

export default PhotoDisplay;
