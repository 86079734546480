import { isLocal } from 'config';
import { PaletteDTO } from 'types';
import { isHex } from 'utils';

import { colors, generateColorFromTheme, generateContrastFromTheme } from '.';

export const DEFAULT_THEMEPACK = {
  themepackCode: 'classic',
  name: 'classic',
  palettes: [
    { paletteCode: 'classic-1', main: '#FEB81C', contrastText: '#FFFDF3' },
    { paletteCode: 'classic-2', main: '#C1DADE', contrastText: '#3E4323' },
    { paletteCode: 'classic-3', main: '#E5CDD1', contrastText: '#677690' },
    { paletteCode: 'classic-4', main: '#DDDCBE', contrastText: '#B04E34' },
    { paletteCode: 'classic-5', main: '#D5AC3A', contrastText: '#F8F8F8' },
    { paletteCode: 'classic-6', main: '#FCCFBA', contrastText: '#837B54' },
  ],
};

export const DEFAULT_PALETTE = DEFAULT_THEMEPACK.palettes[0];

export const oldToClassicThemepackMapping = {
  background: 'background',
  transparent: 'transparent',
  primary: DEFAULT_THEMEPACK.palettes[0],
  lightBlue: DEFAULT_THEMEPACK.palettes[1],
  lightPurple: DEFAULT_THEMEPACK.palettes[2],
  lightGreen: DEFAULT_THEMEPACK.palettes[3],
  darkOrange: DEFAULT_THEMEPACK.palettes[4],
  pink: DEFAULT_THEMEPACK.palettes[5],
};

export function generateContrastFromPalette(palette, theme) {
  if (!palette) return generateContrastFromTheme(theme);

  return palette?.contrastText || DEFAULT_PALETTE.contrastText;
}

/**
 * use palette object to generate color, use
 * @param {object} palette - palette object
 * @param {string} palette.main - main color or image background
 * @param {string} palette.contrastText - secondary color, usually for text
 * @param {string} palette.icon - image url of the icon
 * @param {string=} theme - legacy theme string for fallback
 * @param {boolean} isIcon - wether to use the icon url
 * @returns {string} css background shorthand properties string
 */
export const generateBackgroundFromPalette = (palette, theme, config) => {
  const { isMobile = false, isIcon = false } = config || {};
  if (!palette) {
    return generateColorFromTheme(theme) || DEFAULT_PALETTE.main;
  }

  /* css shorthand syntax
   * https://www.w3schools.com/css/css_background_shorthand.asp
   */
  const bg = isHex(palette.main)
    ? palette.main
    : isIcon && palette.icon // fallback to main background if there's no icon url
    ? `${colors.primary} url(${palette.icon}) center/cover no-repeat`
    : isMobile && palette.mobile
    ? `${colors.primary} url(${palette.mobile}) center/cover no-repeat`
    : `${colors.primary} url(${palette.main}) center/cover no-repeat`;

  /** throws an error on local mode */
  if (!bg && isLocal) throw new Error('invalid string');

  return bg || colors.primary;
};

/**
 * convert old themestring into palette
 * @param {string} theme - old theme string
 */
export function generatePaletteCodeFromTheme(theme) {
  if (!theme && isLocal) {
    throw new Error("theme can't be undefined ");
  }
  return (oldToClassicThemepackMapping[theme] || DEFAULT_PALETTE).paletteCode;
}

export class Palette {
  constructor(private value: PaletteDTO = DEFAULT_PALETTE) {}
  generateContrast() {
    return this.value.contrastText;
  }
  generateBackground(config?: { lowres: 'icon' | 'mobile' }) {
    const isColor = isHex(this.value.main);
    if (isColor) {
      return this.value.main;
    } else {
      // check if we prefer low res images
      const imageBackgroundCssString = `${colors.primary} url(${this.value.main}) center/cover no-repeat`;
      if (config?.lowres) {
        if (config.lowres === 'icon') {
          return this.value.icon
            ? `white url(${this.value.icon}) center/cover no-repeat`
            : imageBackgroundCssString;
        } else if (config.lowres === 'mobile') {
          return this.value.mobile
            ? `white url(${this.value.mobile}) center/cover no-repeat`
            : imageBackgroundCssString;
        }
      }

      return imageBackgroundCssString;
    }
  }
  toDto() {
    return this.value;
  }
}
