//@ts-check
import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseISO } from 'date-fns';
import { omit } from 'lodash';
import { autorun } from 'mobx';
import { Observer, observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Avatar } from 'components/avatar';
import { Footer } from 'components/footer';
import Button from 'components/illume/buttons/Button';
import { DisabledLock } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { DatePicker } from 'components/illume/text-inputs';
import TextInput from 'components/illume/text-inputs/TextInput';
import { Loading } from 'components/Loading';
import { colors, rem } from 'constants/design';
import { PROFILE_URL } from 'constants/strings';
import { useStores } from 'contexts/store';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { mq } from 'infra/emotion/breakpoints';
import { isValid } from 'utils/form';
import { LayoutV2 } from 'views/components-v2/elements/layout/LayoutV2';

const EDIT_PROFILE_MAX_WIDTH = 1000;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6.5),
    paddingTop: theme.spacing(7),
    maxWidth: EDIT_PROFILE_MAX_WIDTH,
    margin: '0 auto',
  },
  header: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
    },
  },
}));

const EditProfile = () => {
  const { userProfileStore } = useStores();

  const {
    userProfileDTO: userProfile,
    updating,
    updateUserProfile,
    fetchUserTask: { error },
  } = userProfileStore;

  const { email, displayName, firstName, picture } = userProfile || {};
  const [photoUploading, setPhotoUploading] = useState();
  const classes = useStyles();
  const FIELDS = [
    {
      name: 'displayName',
      label: 'Your Name',
      placeholder: 'e.g maxine', // not sure if it is intentional to put the value as placeholder, but it seems it is, based on FIGMA
      validation: yup.string().max(30, 'Maximum 30 characters'),
      type: 'text',
    },
    {
      name: 'birthday',
      label: 'Birthday',
      placeholder: 'e.g August 16th',
      type: 'date',
    },
    {
      name: 'email',
      label: 'Your Email',
      // TODO: currently there's no clear distinction beetween disabled input and (normal state + placeholder)
      // also the input style is inconsistent from screen to screen e.g with the 'reply' flow
      disabled: true,
      border: `1px solid ${colors.gray60}`,
      placeholder: 'e.g max@outlook.com',
      validation: yup.string().email('must be a valid email'),
      endIcon: () => <DisabledLock size={28} color={colors.gray60} />,
      type: 'text',
      tooltip:
        'Please contact illume support—via the chat button on the bottom right of the page or email us at email@illumenotes.com—if you wish to change the email address associated with this account.',
    },
  ];
  const schema = yup.object().shape(
    Object.assign({}, ...FIELDS.map((item) => ({ [item.name]: item.validation }))),
    // e.g firstName: yup.string().max(50, `Maximum 50 characters`),
  );
  const { register, handleSubmit, errors, formState, reset, control } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const unsub = autorun(() => {
      reset({
        displayName: userProfile?.displayName || userProfile?.firstName || userProfile?.lastName,
        email: userProfile?.email,
        birthday: userProfile?.birthday ? parseISO(userProfile.birthday) : null,
      });
    });
    return () => unsub();
  }, [
    reset,
    userProfile?.birthday,
    userProfile?.displayName,
    userProfile?.email,
    userProfile?.firstName,
    userProfile?.lastName,
  ]);

  const { isSubmitted, isSubmitting } = formState;
  const disableSubmitButton = !isValid(errors) && isSubmitted;
  const history = useHistory();
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();

  const handleError = (message) => {
    enqueueErrorSnackbar(message);
    setPhotoUploading(false);
  };

  const onSave = async (res, redirect = true) => {
    const payload = omit(
      {
        ...res,
        birthday: new IllumeDate(res.birthday).toAPIString(),
        timezone: new IllumeDate(res.birthday).getTz(),
      },
      'email',
    );
    await updateUserProfile(payload)
      .then(() => {
        if (redirect) history.push(PROFILE_URL);
        else {
          enqueueSuccessSnackbar('Profile photo changed');
          reset(userProfile);
        }
      })
      .catch((e) => handleError(e.message));
  };

  const handlePhotoSelect = async (file) => {
    await onSave({ picture: file?.cdnUrl }, false);
    setPhotoUploading(false);
  };

  if (error) {
    return <Redirect to={PROFILE_URL} />;
  }

  if (!userProfile) {
    return <Loading text="getting user.." />;
  }

  return (
    <LayoutV2
      containerAs={Grid}
      containerProps={{
        className: css`
          padding-top: 50px !important;
          ${mq.desktopSmall} {
            padding-top: 70px !important;
          }
        `,
      }}
    >
      {() => {
        return (
          <Grid classes={{ root: classes.root }}>
            <Grid container direction="column">
              {/* TITLE */}
              <Grid item xs={12} className={classes.header}>
                <Text fontWeight={900} fontSize={{ mobile: rem[1375], desktop: rem[2250] }}>
                  EDIT PROFILE
                </Text>
              </Grid>

              <Grid item container justifyContent="center" spacing={4}>
                {/* Avatar */}
                <Grid item>
                  <Box minWidth={250}>
                    <Avatar
                      onError={handleError}
                      imgUrl={picture}
                      size={225}
                      name={displayName || email || firstName}
                      editable
                      beforeFileSelect={() => {}}
                      onFileSelect={handlePhotoSelect}
                    />
                  </Box>
                </Grid>
                {/* Form and Button */}
                <Grid item xs={12} md={6}>
                  <form onSubmit={handleSubmit(onSave)}>
                    <Grid container spacing={8}>
                      <Grid item container spacing={2}>
                        {FIELDS.map(
                          (
                            {
                              name: fieldName,
                              label,
                              placeholder,
                              disabled,
                              endIcon,
                              type = 'text',
                              tooltip,
                              border,
                            },
                            idx,
                          ) => (
                            <Grid key={idx} item xs={12}>
                              {type === 'text' && (
                                <TextInput
                                  border={border}
                                  ref={register}
                                  label={label}
                                  disabled={disabled}
                                  name={fieldName}
                                  placeholder={placeholder}
                                  endIcon={endIcon}
                                  tooltip={tooltip}
                                  error={errors[fieldName]}
                                  errorMessage={errors[fieldName]?.message}
                                />
                              )}
                              {/* )} */}
                              {type === 'date' && (
                                <Controller
                                  control={control}
                                  name={fieldName}
                                  render={({ onChange, value, name }) => {
                                    return (
                                      <Observer>
                                        {() => (
                                          <DatePicker
                                            format="MMMM do"
                                            placeholder={placeholder}
                                            icon={true}
                                            shadow={!userProfile.birthday}
                                            label={label}
                                            name={name}
                                            error={errors.birthday}
                                            onChange={(e) => {
                                              onChange(e);
                                            }}
                                            value={value}
                                          />
                                        )}
                                      </Observer>
                                    );
                                  }}
                                />
                              )}
                            </Grid>
                          ),
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {/** ISSUE: When the user press our button, it goes gray until it blurs. this happens in all page */}
                        <Button
                          fullWidth
                          loading={(updating && !photoUploading) || isSubmitting}
                          disabled={disableSubmitButton}
                          type="submit"
                        >
                          save changes
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Footer />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </LayoutV2>
  );
};

export default observer(EditProfile);
