import { createContext, useContext } from 'react';

import { IBatchCardService } from 'domain/interfaces/IBatchCardService';
import { ICardService } from 'domain/interfaces/ICardService';
import { IMerchantProductsService } from 'domain/interfaces/IMerchantGiftService';
import { INoteService } from 'domain/interfaces/INoteService';
import { INotificationService } from 'domain/interfaces/INotificationService';
import { IOccasionService } from 'domain/interfaces/IOccasionService';
import { IProductLineService } from 'domain/interfaces/IProductLineService';
import { IShippingService } from 'domain/interfaces/IShippingService';
import { ISuggestionService } from 'domain/interfaces/ISuggestionService';
import { IUserService } from 'domain/interfaces/IUserService';
import { IVerificationService } from 'domain/interfaces/IVerificationService';
import { AuthenticationService } from 'infra/authentication-service/AuthService';

export type IServices = {
  suggestionService: ISuggestionService;
  occasionService: IOccasionService;
  merchantGiftService: IMerchantProductsService;
  cardService: ICardService;
  shippingService: IShippingService;
  noteService: INoteService;
  verificationService: IVerificationService;
  authenticationService: AuthenticationService;
  batchCardService: IBatchCardService;
  notificationService: INotificationService;
  userService: IUserService;
  productLineService: IProductLineService;
};

const ServiceContext = createContext({} as IServices);

export const ServiceProvider: React.FC<{ services: IServices }> = ({ children, services }) => {
  return <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>;
};

export const useServices = () => useContext(ServiceContext);
