import React from 'react';

import { ValueOf } from '@illume/shared';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';

import { Text } from 'components/illume/text';
import { colors } from 'constants/design';

import { CustomRadio } from './Radio';

interface Option {
  value: string;
  label: string;
  title: string;
  subtitle: string;
  disabled?: boolean;
}

interface Props {
  options: Option[];
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onDisabledClick?: () => void;
  subtitleColor?: ValueOf<typeof colors>;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 20,
  },
  labelGroup: {
    // column
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: 5,
  },
  title: {
    flex: 1,
    textAlign: 'right',
  },
  subtitle: {
    flex: 1,
    textAlign: 'right',
  },
  radioGroup: {
    flex: 1,
  },
  contanier: {
    // justify between
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

export default function RadioSelect({
  options,
  value,
  onChange,
  containerProps,
  onDisabledClick,
  subtitleColor,
}: Props) {
  const classes = useStyles();

  return (
    <div {...containerProps}>
      <RadioGroup value={value} className={classes.contanier} onChange={onChange}>
        {options.map((option, i) => (
          <div
            className={classes.container}
            onClick={option.disabled ? onDisabledClick : undefined}
            key={i}
          >
            <FormControlLabel
              disabled={option.disabled}
              style={{ margin: 0 }}
              value={option.value}
              control={<CustomRadio />}
              label={null}
            />
            <div className={classes.labelGroup}>
              <div className={classes.title}>
                <Text
                  color={option.disabled ? colors.gray40 : colors.gray100}
                  fontSize={{ desktop: 18, mobile: 16 }}
                  fontWeight={700}
                >
                  {option.title}
                </Text>
              </div>
              <div className={classes.subtitle}>
                <Text
                  fontSize={{ desktop: 16, mobile: 14 }}
                  fontWeight={400}
                  color={
                    option.disabled ? colors.gray20 : subtitleColor ? subtitleColor : colors.gray60
                  }
                >
                  {option.subtitle}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
}
