import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { flowRight as compose } from 'lodash';

import config from 'config';
import { LOGIN_URL } from 'constants/strings';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IllumeErrorDTO, Maybe } from 'types';
import logger from 'utils/logger';

import { ClientConfig, IllumeHttpClientRequestObject } from './client.types';
import { deleteAuthCookie, getAuthCookie, getTrackValues, writeAuthCookie } from './cookie';
import { injectAuthHeader, injectHeader, inject_x_illumeHeaders } from './utils/addHeaders';

type Suc<T> = {
  success: true;
} & T;

export type Result<T> = Suc<T> | IllumeErrorDTO;
export class IllumeApiHttpClient implements IHttpClient {
  private clientConfig: ClientConfig = { ...config, baseURL: config.baseURL_new };
  private trackValues = getTrackValues();
  public authCredentials: { jwt: string | undefined } = { jwt: '' };
  initialized = false;

  initialize = async () => {
    await this.retrieveJwtFromCookieOrServer().then(this.setInMemoryJWT);

    this.initialized = true;
    return this;
  };

  setInMemoryJWT = (jwt: string) => {
    const currentJwtCookieValue = getAuthCookie()?.token;
    // update cookie jwt if we have a different value set
    if (jwt !== currentJwtCookieValue) {
      logger.log(
        'attempt to update in memory jwt value with a different value comes from cookie, updating the cookie value..',
      );
      writeAuthCookie(jwt);
    }
    return (this.authCredentials.jwt = jwt);
  };

  makeRequest = async <SuccessData>(
    requestObj: AxiosRequestConfig,
    headerOverrides?: AxiosRequestConfig['headers'],
    retries = 0,
  ): Promise<Suc<SuccessData> | IllumeErrorDTO> => {
    const MAX_RETRIES = 1;
    if (!this.authCredentials.jwt) {
      return Promise.reject('attempting to request without auth header');
    }
    const token = this.authCredentials.jwt;

    const reqObj = compose(
      // inject headers
      (r: IllumeHttpClientRequestObject) => injectAuthHeader(r, token),
      (r: IllumeHttpClientRequestObject) => injectHeader(r, headerOverrides),
      (r: IllumeHttpClientRequestObject) => inject_x_illumeHeaders(r, this.trackValues),
    )(requestObj);

    return this.illumeAxios
      .request<Suc<SuccessData> | IllumeErrorDTO>(reqObj)
      .then((r) => r.data)
      .catch(async (err: AxiosError | Error) => {
        if (axios.isAxiosError(err)) {
          // handling 300 error
          if (err.response?.status === 403) {
            // If we have already retried twice, throw an error
            if (retries >= MAX_RETRIES) {
              throw new Error(`unable to recover from 403 error after ${MAX_RETRIES} retries`);
            }

            const exchangedToken = await this.recoverBadToken(requestObj).catch((e) => {
              // redirect to login url when token exchange is failed
              deleteAuthCookie();
              window.location.href = LOGIN_URL;
            });
            if (!exchangedToken) {
              throw new Error("unexpected authentication issue, don't worry just re-login");
            }
            this.setInMemoryJWT(exchangedToken);
            // Retry the request
            return this.makeRequest(requestObj, headerOverrides, retries + 1);
          }

          const errResponse = err?.response?.data as Maybe<IllumeErrorDTO>;
          // 400 - bad request, doesn't throw error
          // old behaviour
          // as per v1.1.0, validation error can have statusCode 200, which is inconsistent
          // probably we want to handle it in CustomError?

          if (!errResponse) {
            const noErrResponse: IllumeErrorDTO = {
              message: 'unexpected error: no info provided by the server',
              errorCode: 'UnexpectedError',
              statusCode: 500,
              success: false,
            };
            return noErrResponse;
          }
          if (errResponse.validation) {
            const res: IllumeErrorDTO = {
              message: errResponse.validation.body.message || errResponse.message,
              statusCode: 400,
              success: false,
              validation: {
                body: {
                  message: errResponse.validation.body.message,
                  reasons: errResponse.validation.body.reasons,
                },
              },
            };
            return res;
          }
          if (errResponse.statusCode === 400) {
            const res: IllumeErrorDTO = {
              message: errResponse.message || 'might be a validation error',
              statusCode: 400,
              success: false,
            };
            return res;
          }

          logger.error('unexpected axios error', err);
          throw err;
        } else {
          logger.error('unexpected error', err);
          throw Error('unexpected error');
        }
      });
  };

  private retrieveJwtFromCookieOrServer = async () => {
    // look for previous jwt in cookies
    const auth = getAuthCookie();

    if (auth && auth.token) {
      return auth.token;
    }
    return this.fetchAnonymousToken();
  };

  private fetchAnonymousToken = async () => {
    const requestObj: IllumeHttpClientRequestObject = {
      url: '/token',
      method: 'get',
      baseURL: this.clientConfig.baseURL,
    };
    const res = await this.illumeAxios.request<{ success: boolean; token: string }>(
      inject_x_illumeHeaders(requestObj, this.trackValues),
    );
    if (res.data.success && res.data.token) {
      return res.data.token;
    } else {
      throw new Error('Anonymous Token Request Failed');
    }
  };

  private recoverBadToken = async (requestObj: IllumeHttpClientRequestObject) => {
    // try to exchange the previous token via a normal call
    const exchangeReqObject: IllumeHttpClientRequestObject = {
      ...requestObj,
      url: '/token/exchange',
      method: 'post',
    };

    const res = await this.illumeAxios.request<{ success: boolean; token: string }>(
      exchangeReqObject,
    );

    if (res.data.token && res.data.success) {
      return res.data.token;
    } else {
      return this.fetchAnonymousToken();
    }
  };

  constructor(private illumeAxios: AxiosInstance) {
    axiosRetry(this.illumeAxios, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: axiosRetry.isIdempotentRequestError,
    });
  }
}
