export const DownloadIcon = ({ size = 17, color = '#FFFDF3' }) => {
  return (
    <svg
      width={size}
      height={size * (24 / 17)}
      viewBox="0 0 17 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0227 10.1067L8.44942 18.4267C8.26278 18.64 7.91608 18.64 7.70271 18.4267L0.129378 10.1067C-0.163932 9.78672 0.0760809 9.28004 0.502734 9.28004H4.2094V0.480044C4.2094 0.213378 4.42277 0 4.68944 0H11.4094C11.6761 0 11.8895 0.213369 11.8895 0.480044V9.28004H15.5961C16.0761 9.28004 16.2895 9.78664 16.0228 10.1067H16.0227ZM16.1293 23.6V20.9333C16.1293 20.72 15.9427 20.5333 15.7293 20.5333H0.422687C0.209318 20.5333 0.0226874 20.72 0.0226874 20.9333V23.6C0.0226874 23.8134 0.209318 24 0.422687 24H15.756C15.9693 24 16.1293 23.84 16.1293 23.6H16.1293Z"
        fill={color}
      />
    </svg>
  );
};
