import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';
import { flexCenter } from 'utils/styles.util';

const MAX_WIDTH = 810;
export const useCardDetailStyles = makeStyles((theme) => ({
  container: {
    padding: '35px 35px 0 35px',
    marginTop: theme.spacing(2),
    maxWidth: MAX_WIDTH,
  },

  newContainer: {
    marginTop: theme.spacing(2),
    maxWidth: MAX_WIDTH,
    paddingTop: theme.spacing(6.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6.5),
    },
  },
  replyBoxContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    maxWidth: MAX_WIDTH,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6.5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  cardListing: {
    maxWidth: 1200,
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    marginBottom: theme.spacing(6.5),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6.5),
      paddingRight: theme.spacing(6.5),
    },
  },
  giftSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: 390,
    },
  },
  gifting: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  moneyPot: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150,
    [theme.breakpoints.up('md')]: {
      maxWidth: 180,
    },
  },
  button: {
    width: 350,
  },
  arrowBack: {
    paddingRight: theme.spacing(2),
  },
  noGift: {
    height: 144,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: 160,
    },
  },
  replyBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6.5),
    [theme.breakpoints.up('md')]: {
      width: MAX_WIDTH,
    },
  },
  cardWrapper: {
    marginTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 150px)',
    gridTemplateRows: 'repeat(auto, 150px)',
    gridGap: '20px',
    justifyContent: 'center',
    width: '380px',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7),
      gridTemplateColumns: 'repeat(auto-fit, 225px)',
      gridTemplateRows: 'repeat(auto, 225px)',
      width: '988px',
    },
  },
  cardList: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
  },
  giftDetailsContainer: {
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    gridArea: 'main',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gridTemplateColumns: 'auto',
    gridTemplateAreas: '"giftImage" "giftDetails"',
    rowGap: 20,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '600px 225px',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '"giftDetails giftImage"',
    },
    justifyContent: 'center',
    backgroundColor: '#F5F0E1',
    borderRadius: '24px',
  },
  giftDetails: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginRight: 150,
      justifyContent: 'space-between',
      padding: theme.spacing(4),
    },
    rowGap: 16,
    gridArea: 'giftDetails',
  },
  giftInfo: {
    display: 'grid',
    rowGap: 16,
  },
  giftTopText: {
    display: 'grid',
    rowGap: 8,
    [theme.breakpoints.up('md')]: {
      rowGap: 4,
    },
  },
  giftImage: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
    },
    gridArea: 'giftImage',
  },
  giftDetailsButton: {
    color: colors.gray60,
  },
  imgPlaceholder: {
    width: '225px',
    height: '225px',
    objectFit: 'contain',
  },
  replyListCard: {
    maxWidth: '100%',
    whiteSpace: 'pre-line',
  },
  downloadPdfCta: {
    marginTop: theme.spacing(4),
    ...flexCenter(),
  },
}));

export const badgeStyles = makeStyles(() => ({
  width: '100%',
  anchorOriginTopRightRectangular: {
    top: '-10px',
    right: '-10px',
    width: '100%',
  },
}));

export const cardVariants = (delay) => ({
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'backOut',
      delay: delay + custom * 0.14,
      duration: 0.6,
    },
  }),
  out: (custom) => ({
    y: 50,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'backIn',
      delay: custom * 0.04,
      duration: 0.45,
    },
  }),
});

export const useRedesignedStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      flexGrow: 1,
      marginTop: 120,
      display: 'flex',
      maxWidth: '980px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 0px 30px 0px',
      },
      padding: '0 36px 30px 36px',
      flexWrap: 'wrap',
      gap: '36px',
      justifyContent: 'center',
      '& > .header': {
        flex: '0 1 300px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          flex: '0 1 500px',
        },
        [theme.breakpoints.up('md')]: {
          flex: '1 1 100%',
        },
        '& > .title': { display: 'flex', columnGap: 8, alignItems: 'center' },
        '& > .arrow': {
          zIndex: 1,
          position: 'absolute',
          left: 0,
        },
      },
      '& > .gift-container': {
        flex: '0 1 300px',
        minHeight: 270,
        [theme.breakpoints.up('sm')]: {
          flex: '0 1 500px',
        },
        [theme.breakpoints.up('md')]: {
          flex: '1 1 100%',
        },
      },

      '& > .notes-container': {
        flex: '1 1 100%',
        justifyContent: 'center',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, 144px)`,
        gridAutoRows: '144px',
        gap: 12,
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: `repeat(auto-fit, 225px)`,
          gridAutoRows: '225px',
          gap: 24,
        },
      },
    },
  });
});
