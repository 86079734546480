import { useEffect, useReducer } from 'react';

import { isSafari } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

const useClipPathID = (enabled) => {
  const [counter, increment] = useReducer(() => uuidv4(), uuidv4());
  const id = `id_${counter}`;

  useEffect(() => {
    if (isSafari) {
      increment();
    }
  }, [increment, enabled]);

  return id;
};

export default useClipPathID;
