import { Theme, CircularProgressProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Text } from '../text';

const useStyles = makeStyles<Theme, Pick<IIllumeCircularProgressProps, 'color'>>(() =>
  createStyles({
    root: {
      color: ({ color }) => color,
    },
  }),
);

interface IIllumeCircularProgressProps extends Omit<CircularProgressProps, 'color'> {
  value?: number;
  color?: string;
  label: string;
  size?: number;
}

const IllumeCircularProgress: React.FunctionComponent<IIllumeCircularProgressProps> = ({
  value,
  color,
  label,
  size,
  ...props
}) => {
  const classes = useStyles({ color });
  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box position="relative">
        <CircularProgress value={value} classes={classes} size={size} {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {
            // eslint-disable-next-line eqeqeq
            value != undefined && (
              <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                value,
              )}%`}</Typography>
            )
          }
        </Box>
      </Box>
      <Box>
        <Text color={color} align="center">
          {label}
        </Text>
      </Box>
    </Box>
  );
};

export default IllumeCircularProgress;
