import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors, borderRadius } from 'constants/design';

export const datePickerStyles = makeStyles((theme) =>
  createStyles({
    label: {
      color: colors.labelText,
    },
    textInput: ({ shadow, background, compact, value }) => {
      /**
       * @type {import('react').CSSProperties}
       */
      const compactStyles = compact
        ? {
            height: 40,
            paddingTop: 0,
            paddingBottom: 0,
            [theme.breakpoints.up('md')]: {
              fontSize: 14,
            },
          }
        : null;

      return {
        boxShadow: shadow ? `6px 6px 0px 0px ${colors.alertRed}` : 'none',
        fontWeight: 500,
        backgroundColor:
          background === 'main'
            ? theme.palette.illume.textInputBackground.main
            : theme.palette.illume.textInputBackground.contrastText,
        color: value ? colors.gray100 : colors.gray25,
        borderRadius,
        // TODO: find how to styling the placeholder to be still 14px
        // so it will be same like the other fields
        fontSize: 14,
        [theme.breakpoints.up('md')]: {
          fontSize: 16,
        },
        '&::before, &::after': {
          borderBottom: 'none',
        },
        ...compactStyles,
      };
    },
  }),
);

export const labelStyles = makeStyles(() => ({
  label: {
    alignItems: 'center',
  },
  button: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));
