import { useState } from 'react';

import { NoteDTO } from '@illume/shared';
import { Badge, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { BaseIconButton } from 'components/illume/buttons';
import BadgeButton from 'components/illume/buttons/BadgeButton';
import { GifDisplay } from 'components/illume/gif-display';
import { Pencil, TrashBin } from 'components/illume/icons';
import { Modal } from 'components/illume/modal';
import { HiddenWrapper } from 'components/illume/note/Note';
import PhotoDisplay from 'components/illume/photos/PhotoDisplay';
import { Surface } from 'components/illume/surface'; // should be on components/illume
import { HiddenWords, Text } from 'components/illume/text';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { PreviewModal } from 'components/preview-modal';
import { colors, generateColorFromTheme, generateContrastFromTheme, rem } from 'constants/design';
import {
  generateBackgroundFromPalette,
  generateContrastFromPalette,
} from 'constants/design/cardTheme';
import { EDIT_NOTE_URL } from 'constants/strings';
import { useCardContext } from 'contexts/card';
import { Note } from 'domain/entities/initiator-card/InitiatorCard';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import useClipPathID from 'hooks/useClipPathID';
import { noop } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';

import { badgeStyles } from '../card-details-v2-pending/contributor/cardDetailsV2.styles';
import { noteStyles } from './NoteThumbnail.styles';

interface INote {
  message: string;
  signature: string;
  theme: string;
  palette: string;
  photos: { url: string }[];
  videos: { url: string }[];
  gifs: { url: string }[];
  redacted: boolean;
  length: number;
  viewCode: string;
}

interface NoteThubmnailProps {
  note: INote;
  onViewNote: () => void;
  size?: {
    desktop: number;
    mobile: number;
  };
  onPencilClick: () => void;
  deleteService: () => Promise<any>;
  deleteModalLoading: boolean;
  editable: boolean;
  hideBadge: boolean;
  recipientName: string;
  previewable: boolean;
  cardCode: string;
}
const NoteThumbnail = ({
  note,
  onViewNote,
  size = { desktop: 225, mobile: 125 },
  onPencilClick = noop,
  deleteService = () => Promise.resolve(null),
  deleteModalLoading = false,
  editable,
  hideBadge = true,
  recipientName,
  previewable,
  cardCode,
}: NoteThubmnailProps) => {
  const {
    message,
    signature,
    theme,
    palette: paletteCode,
    photos = [],
    videos = [],
    gifs = [],
    redacted,
    length,
    viewCode: noteViewCode,
  } = note;

  const { getPaletteDto: getPalette } = useCardContext();
  const [showPreview, setShowPreview] = useState(false);
  const palette = getPalette(paletteCode);
  const badgeClasses = badgeStyles();
  const clickable = onViewNote || previewable;
  const noteClasses = noteStyles({ clickable, size });
  const desktop = useIsDesktop();
  const mediaPreviewSize = desktop ? 100 : 54;
  const [deleteNoteModalShown, setDeleteNoteModalShown] = useState(false);
  const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateContrastFromPalette(palette, theme)
    : generateContrastFromTheme(theme);

  const mainColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateBackgroundFromPalette(palette, theme, { isMobile: !desktop })
    : generateColorFromTheme(theme);

  const [photo, video, gif] = [photos[0], videos[0], gifs[0]];
  const isMediaExists = photo || video || gif;

  const showModal = () => setShowPreview(true);
  const videoClipPathId = useClipPathID();
  const imageClipPathId = useClipPathID();

  return (
    <>
      <Badge
        className={noteClasses.wrapper}
        classes={badgeClasses}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          <BadgeButton
            icon={() => <TrashBin color={colors.contrastText} size={desktop ? 25 : 20} />}
            onClick={() => setDeleteNoteModalShown(true)}
          />
        }
        invisible={hideBadge}
      >
        <Surface
          background={mainColor}
          px={3}
          py={3}
          rounded={['top', 'bottom']}
          className={noteClasses.root}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const func = onViewNote || showModal;
            func();
          }}
        >
          {isMediaExists ? (
            <HiddenWrapper redacted={redacted} recipientName={recipientName}>
              <Box className={noteClasses.media}>
                {photo ? (
                  <PhotoDisplay
                    imgUrl={photo.url}
                    size={mediaPreviewSize}
                    color={contrastColor}
                    redacted={redacted}
                    clipPathID={imageClipPathId}
                  />
                ) : video ? (
                  <VideoDisplay
                    vidUrl={video.url}
                    size={mediaPreviewSize}
                    color={contrastColor}
                    redacted={redacted}
                    clipPathId={videoClipPathId}
                  />
                ) : gif ? (
                  <GifDisplay
                    giphyCode={gif.url}
                    size={mediaPreviewSize}
                    color={contrastColor}
                    redacted={redacted}
                    hideBanner
                  />
                ) : null}
              </Box>
            </HiddenWrapper>
          ) : redacted ? (
            <HiddenWrapper recipientName={recipientName} redacted={redacted}>
              <HiddenWords charLength={length} isSmall />
            </HiddenWrapper>
          ) : (
            <Box className={noteClasses.message}>
              <Text
                className="fs-mask"
                wordBreak="break-word"
                fontSize={{ mobile: rem[1000], desktop: rem[1125] }}
                color={contrastColor}
                fontWeight={700}
              >
                {message.split('\n').map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </Text>
            </Box>
          )}
          <Box>
            <Text
              fontSize={{ mobile: rem[1000], desktop: rem[1125] }}
              fontWeight={900}
              data-testid="sender-text"
            >
              {signature || 'anonymous'}
            </Text>
          </Box>
          {editable && (
            <Link
              to={`${EDIT_NOTE_URL}/${cardCode}/${noteViewCode}`}
              onClick={onPencilClick}
              className={noteClasses.overlay}
            >
              <Box>
                <BaseIconButton
                  onClick={onPencilClick}
                  icon={Pencil}
                  iconProps={{
                    color: colors.contrastText,
                    size: desktop ? 40 : 30,
                  }}
                />
              </Box>
              <Box>
                <Text
                  color={colors.contrastText}
                  fontSize={{ mobile: rem[875], desktop: rem[1500] }}
                  fontWeight={900}
                >
                  EDIT MY NOTE
                </Text>
              </Box>
            </Link>
          )}
        </Surface>
      </Badge>

      {previewable && (
        <PreviewModal
          showNavigationButtons={false}
          notes={[note]}
          open={showPreview}
          onClose={() => setShowPreview(false)}
        />
      )}

      <Modal
        show={deleteNoteModalShown}
        title="are you sure you want to delete this note?"
        onClose={() => setDeleteNoteModalShown(false)}
        promptConfig={{
          onOk: () => deleteService().then(() => setDeleteNoteModalShown(false)),
          okLoading: deleteModalLoading,
          okText: 'yes, delete',
          onCancel: () => setDeleteNoteModalShown(false),
          cancelText: 'no, cancel',
        }}
      >
        <Text align="center" fontWeight={300}>
          This cannot be undone
        </Text>
      </Modal>
    </>
  );
};

NoteThumbnail.noteFromDomain = (note: Note): INote => {
  return {
    gifs: note.gifs,
    length: note.length,
    photos: note.photos,
    videos: note.videos,
    message: note.message,
    palette: note.palette,
    redacted: note.redacted,
    signature: note.signature,
    theme: note.theme,
    viewCode: note.viewCode,
  };
};

NoteThumbnail.noteFromDTO = (note: NoteDTO): INote => {
  return {
    ...note,
    videos: note.videos.map((v) => ({ url: v.url || v.deliveryUrl })),
    photos: note.photos.map((p) => ({ url: p.url || p.deliveryUrl })),
    gifs: note.gifs.map((g) => ({ url: g.giphyCode })),
  };
};

export default observer(NoteThumbnail);
