import { ContributorCardDTO, InitiatorCardViewDTO } from '@illume/shared';
import { Grid, ModalProps, Paper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { match } from 'ts-pattern';

import { BaseIconButton } from 'components/illume/buttons';
import { Calendar, Close, TwoPeople } from 'components/illume/icons';
import { MUIModal } from 'components/illume/mui/modal';
import { Text } from 'components/illume/text';
import { colors, rem, spacing } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { wait } from 'utils';
import { spellNames } from 'utils/string';

import { SendCardSchema } from '../../card-details-v2-pending/initiator/constants/constants';
import { ContributorContent } from '../ContributorContent';
import { InitiatorSentContent } from './InitiatorContent.sent';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '1540!important' as unknown as number,
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(4),
      },
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      border: 'none',
      outline: 0,
      boxShadow: theme.shadows[5],
      borderRadius: rem[1000],
      marginLeft: spacing[8],
      marginRight: spacing[8],
      padding: spacing[4],
      width: '100%',
      maxWidth: 286,
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxHeight: '510px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 400,
      },
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  }),
);

const contentStyles = makeStyles(() => ({
  box: {
    maxWidth: 440,
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 300,
  },
}));

interface SentCardDetailsModalProps extends Omit<ModalProps, 'open' | 'onClose' | 'children'> {
  cardV2: InitiatorCardViewDTO | ContributorCardDTO;
  show: boolean;
  onClose: () => any;
  resendCardService: (payload: SendCardSchema) => Promise<any>;
  inviteService?: (contacts: string[]) => Promise<any>;
  onResendSuccess: () => any;
}

/**
 *
 * @deprecated - the name doesn't make sense anymore. try to separate the logic
 */
const SentCardDetailsModal = (props: SentCardDetailsModalProps) => {
  const {
    show,
    onClose,
    cardV2,
    resendCardService: resendCardService,
    inviteService,
    ...rest
  } = props;
  const styles = useStyles();
  const classes = contentStyles();

  const {
    recipientData: recipientData,
    initiatorName,
    deadline,
    sentAt,
    contributorCount,
  } = cardV2 || {};
  const analytics = useAnalytics();

  return (
    <MUIModal
      BackdropProps={{ className: styles.backdrop }}
      onClose={onClose}
      open={show}
      className={styles.modal}
      {...rest}
    >
      <Paper className={cx(styles.paper, 'customIllumeScrollbar-gray')}>
        <Grid container justifyContent="flex-end" direction="row">
          <Grid item>
            <BaseIconButton
              icon={Close}
              iconProps={{ color: colors.gray100 }}
              onClick={onClose}
              size={16}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.box} spacing={3}>
          <Grid item container direction="column" spacing={1}>
            {/*To and from*/}
            {[`To: ${spellNames(recipientData)}`, `From: ${initiatorName}`].map((text, index) => (
              <Grid
                key={index}
                item
                container
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                wrap="nowrap"
              >
                <Grid item className={classes.name}>
                  <Text
                    fontSize={rem[1750]}
                    fontWeight={700}
                    //@ts-ignore
                    noWrap
                  >
                    {text}
                  </Text>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item container spacing={2} direction="column">
            {[
              {
                text: sentAt
                  ? `sent: ${new IllumeDate(sentAt).toMonthDateYearString()}`
                  : `deadline: ${
                      deadline ? new IllumeDate(deadline).toMonthDateYearString() : 'not set'
                    }`,
                Icon: () => <Calendar color={colors.gray60} size={24} />,
              },
              {
                text: `# of contributers: ${contributorCount}`,
                Icon: () => <TwoPeople color={colors.gray60} size={28} />,
              },
            ].map(({ text, Icon }, index) => {
              return (
                <Grid item container justifyContent="space-between" key={index} wrap="nowrap">
                  <Grid item container spacing={1} xs={8} alignItems="center">
                    <Grid item xs={2}>
                      <Icon />
                    </Grid>
                    <Grid item>
                      <Text fontWeight={500} fontSize={rem[875]} color={colors.gray60}>
                        {text}
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" item spacing={2}>
            {match(cardV2)
              .with({ userContext: { role: 'Initiator' } }, (card) => (
                <InitiatorSentContent
                  card={card}
                  resendCardService={(payload) => {
                    // not a good place to add tracking
                    analytics.track(EVENT_NAMES.RESEND_TO_RECIPIENT_MY_CARDS.name);
                    return resendCardService(payload);
                  }}
                  onResendSuccess={() => {
                    onClose();
                    return wait(300);
                  }}
                />
              ))
              .with({ userContext: { role: 'Contributor' } }, (c) => (
                <ContributorContent inviteService={inviteService} card={c} />
              ))
              .exhaustive()}
          </Grid>
        </Grid>
      </Paper>
    </MUIModal>
  );
};

export default SentCardDetailsModal;
