import { RouterStore } from '@superwf/mobx-react-router';
import { makeObservable, observable, override } from 'mobx';

import { GroupGiftModalFormValues } from 'components/illume/group-gift-modal/GroupGiftModal';
import StripeStore, { IProduct } from 'contexts/store/StripeStore.store';
import { UserProfileStore } from 'contexts/user';
import { ContributorCardDTO, InitiatorCardViewDTO, Maybe } from 'types';
import { wait } from 'utils';
import { IllumeMoneyFactory } from 'utils/currency';

import { ContributionForm } from '../contributor/Contributor.store';
import { BasePayForGiftStore, IPayForGiftStore } from '../contributor/ContributorGift';

export class GiftPaymentFromCardDetailsStore
  extends BasePayForGiftStore
  implements IPayForGiftStore
{
  formStore: ContributionForm;
  card: InitiatorCardViewDTO | ContributorCardDTO;
  constructor(
    public stripeStore: StripeStore,
    userProfileStore: UserProfileStore,
    private routerStore: RouterStore,
    private refreshCard: () => Promise<any>,
    card: InitiatorCardViewDTO | ContributorCardDTO,
  ) {
    super();
    makeObservable(this, { onPaid: override, card: observable });
    this.formStore = new ContributionForm(userProfileStore);
    this.card = card;
    const locationState = this.routerStore.history.location.state as { amount: number } | undefined;

    // for refreshing use case
    if (locationState?.amount) {
      this.formStore.setDollar(
        IllumeMoneyFactory({ amount: locationState.amount }).toUnit().toString(),
      );
    }
  }

  onPaid() {
    this.routerStore.history.back();
    return this.refreshCard();
  }

  handleContributeToGift = async (formValues: GroupGiftModalFormValues, path: string) => {
    this.formStore.setUserData({ email: formValues.email });
    this.formStore.setDollar(formValues.amount);
    this.stripeStore.setGoogleOrApplePaySuccessCallback(() => this.onPaid());
    const gift: Maybe<IProduct> = this.gift
      ? { type: 'shopify', id: this.gift.id, product: this.gift.product }
      : this.externalGift
      ? { type: 'external', id: this.externalGift.id, product: this.externalGift.product }
      : null;
    if (!gift || !this.formStore.cents || !this.formStore?.userData?.email) {
      return console.error('invalid config, no card/amount/email set');
    }
    await wait(200); // nothing but UI purpose
    await this.stripeStore.initStripePaymentButtonForGift(
      gift,
      this.formStore.cents,
      this.formStore.userData.email,
    );
    this.routerStore.history.push(
      {
        pathname: path,
      },
      { amount: this.formStore.cents },
    );
  };
}

export default GiftPaymentFromCardDetailsStore;
