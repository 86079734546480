import { BaseClipboard } from 'domain/interfaces/IClipboard';

// and provides a copy method that works on Internet Explorer
// The InternetExplorerClipboard class implements the Clipboard interface

export class InternetExplorerClipboard extends BaseClipboard {
  copy(text: string): Promise<string> {
    return new Promise((res, rej) => {
      try {
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        this.copiedText = text;
        res(text);
      } catch (e) {
        rej(e);
      }
    });
  }
}
