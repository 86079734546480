export const wrapslice = <T>(arr: T[], start: number, end: number) => {
  const copiedArr = [...arr];
  return end < start
    ? copiedArr.slice(start).concat(copiedArr.slice(0, end))
    : copiedArr.slice(start, end);
};

export const truncateHtmlString = (str: string, maxChars: number) => {
  if (!str) {
    return;
  }

  if (str.length <= maxChars) {
    return str;
  }

  // Match HTML tags with attributes
  const re = /<[^>]+>/g;

  let segments = str.split(re); // Split the string into segments and tags
  let tags = str.match(re) || []; // Match the HTML tags

  console.log({ segments, tags });

  let charCount = 0;
  let output = '';

  for (let i = 0; i < segments.length; i++) {
    let segment = segments[i];
    let tag = tags[i] || '';

    if (charCount + segment.length <= maxChars) {
      output += segment + tag;
      charCount += segment.length;
    } else {
      let charsRemaining = maxChars - charCount;
      if (charsRemaining > 0) {
        let lastSpaceIndex = segment.lastIndexOf(' ', charsRemaining);
        if (lastSpaceIndex === -1) {
          output += segment.substr(0, charsRemaining) + '...';
        } else {
          output += segment.substr(0, lastSpaceIndex) + '...';
        }
      }
      break;
    }
  }

  return output;
};
