import { Dinero } from 'dinero.js';

import { Money as IllumeMoney } from 'domain/entities/money/Money';

/**
 * @deprecated
 */
export const centToDollarValue = (value = 0) => value / 100;

export { parseDollarStrAsCent as toCent } from '@illume/shared/utils';

/**
 *
 * @deprecated = use the money instance from domain folder directly
 */
export const IllumeMoneyFactory = (opts: Dinero.Options) => IllumeMoney.fromDineroOpts(opts);
