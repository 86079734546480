export const CircleChecklist = ({ color = '#FFFDF3' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9962 8.81857 22.7307 5.76854 20.4811 3.51893C18.2315 1.26932 15.1814 0.00381086 12 0V0ZM17.8608 10.0608L11.2608 16.6608C11.1215 16.8001 10.9561 16.9107 10.7741 16.9861C10.5921 17.0615 10.397 17.1003 10.2 17.1003C10.003 17.1003 9.8079 17.0615 9.62589 16.9861C9.44387 16.9107 9.2785 16.8001 9.1392 16.6608L6.1392 13.6608C5.85786 13.3795 5.69981 12.9979 5.69981 12.6C5.69981 12.2021 5.85786 11.8205 6.1392 11.5392C6.42055 11.2579 6.80213 11.0998 7.2 11.0998C7.59788 11.0998 7.97946 11.2579 8.2608 11.5392L10.2 13.4784L15.7392 7.9392C15.8785 7.79989 16.0439 7.68939 16.2259 7.614C16.4079 7.53861 16.603 7.4998 16.8 7.4998C16.997 7.4998 17.1921 7.53861 17.3741 7.614C17.5561 7.68939 17.7215 7.79989 17.8608 7.9392C18.0001 8.07851 18.1106 8.24389 18.186 8.4259C18.2614 8.60791 18.3002 8.80299 18.3002 9C18.3002 9.19701 18.2614 9.39209 18.186 9.5741C18.1106 9.75611 18.0001 9.92149 17.8608 10.0608Z"
        fill={color}
      />
    </svg>
  );
};
