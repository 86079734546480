import { useState } from 'react';

import { observer } from 'mobx-react';

import { IllumeLayout } from 'components/layouts';
import { Palette } from 'constants/design/cardTheme';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { APPBAR_ICONS, PREVIEW_TOOLTIP_TEXT } from 'constants/types';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { PaletteDTO } from 'types';

import { SelectThemeForm } from '../select-theme-form';
import ContributorStore from './Contributor.store';

const SelectTheme: React.FC<{ store: ContributorStore }> = ({ store }) => {
  // this should be on the form store
  const noteStore = store.addnoteStore;
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const palette = noteStore.noteData?.palette || noteStore.themepack?.palettes[0];
  const bg = new Palette(palette).generateBackground();
  const analytics = useAnalytics();
  const card = store.cardDto;
  const desktop = useIsDesktop();
  return (
    <IllumeLayout
      topRightButtons={[
        {
          tooltipText: PREVIEW_TOOLTIP_TEXT,
          icon: <APPBAR_ICONS.PREVIEW size={desktop ? 51 : 44} />,
          action: {
            onAction: () => setShowPreviewModal(true),
          },
        },
      ]}
      background={bg}
    >
      <SelectThemeForm
        onPaletteChange={(palette: PaletteDTO) => noteStore.updateNote({ palette })}
        value={palette}
        palettes={noteStore.themepack?.palettes}
        submitting={noteStore.syncNoteTask.isExecuting || store.getCardTask.isExecuting}
        message={noteStore.noteData?.message}
        signature={noteStore.noteData?.signature}
        onBack={store.backFromTheme}
        onSubmit={() => {
          analytics.track(EVENT_NAMES.SUBMIT_NOTE_CONTRIBUTOR.name, {
            recipientName: card?.recipientData,
            initiatorName: card?.initiatorName,
            deadline: card?.deadline,
            anonymous: noteStore.noteData?.anonymous,
          });
          return noteStore.syncNote();
        }}
        photos={noteStore.noteData?.photos}
        videos={noteStore.noteData?.videos}
        gifs={noteStore.noteData?.gifs}
        setShowPreviewModal={setShowPreviewModal}
        showPreviewModal={showPreviewModal}
      />
    </IllumeLayout>
  );
};

export default observer(SelectTheme);
