import { Route, Switch } from 'react-router-dom';

import ExperimentalVerificationProvider from 'components/experimental-verification-provider';
import { Seo } from 'components/seo';
import { MY_CARDS_URL, TEAMS_URL, TEAM_SUCCESFUL_CREATE_URL } from 'constants/strings';
import ScrollToTop from 'hooks/ScrollToTop';
import { Error404 } from 'pages/illume/404';

import SuccessfulCreate from './SuccesfulCreate';
import { Teams } from './teams';

const TeamsRoute = () => {
  return (
    <>
      <Seo noIndex />
      <Switch>
        <Route exact path={TEAMS_URL}>
          <Teams />
        </Route>
        <Route
          exact
          path={TEAM_SUCCESFUL_CREATE_URL}
          render={() => (
            <ScrollToTop>
              <ExperimentalVerificationProvider>
                <SuccessfulCreate continueUrl={MY_CARDS_URL} />
              </ExperimentalVerificationProvider>
            </ScrollToTop>
          )}
        />
        <Route component={Error404} />
      </Switch>
    </>
  );
};

export default TeamsRoute;
