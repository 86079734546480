export const CheckIcon = ({ color = '#FEB81C', size = 19, checkColor = '#FFFDF3' }) => {
  return (
    <svg
      width={size}
      height={(18 / 19) * size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0039087 11.3112C-0.0785696 12.9827 1.14983 14.7626 3.15914 16.1138C5.30357 17.5559 7.68141 18.1387 10.2435 17.9725C11.7211 17.878 13.1127 17.4754 14.2814 16.5076C15.0413 15.8792 15.6292 15.1074 16.1363 14.2673C17.0313 12.7866 17.7806 11.243 18.2035 9.55407C18.7897 7.21582 18.2176 5.15234 16.7224 3.31639C15.1554 1.39294 13.0723 0.416331 10.6454 0.0942968C8.94842 -0.131478 7.29533 0.0470412 5.68965 0.642107C3.72948 1.36843 2.32208 2.71082 1.43587 4.57651C0.551423 6.43523 0.0688385 8.4007 0.0039087 11.3112Z"
        fill={color}
      />
      <g clipPath="url(#clip0_2766_2323)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29342 11.0684C2.49874 10.3992 3.5862 10.1065 4.0881 10.2319C4.7573 10.3574 5.38468 10.6502 5.9284 11.0266C6.63943 11.5285 7.39228 10.692 7.81053 10.1901C9.23258 8.47528 10.8638 6.92776 12.6622 5.54753C13.8333 4.71103 14.5444 5.54753 13.4987 6.42585C11.1565 8.39163 9.10711 10.6502 7.47593 13.2015C7.22498 13.5361 6.9322 13.9125 6.55578 13.7871C6.30483 13.7034 3.66985 11.403 3.29342 11.0684Z"
          fill={checkColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2766_2323">
          <rect width="11" height="11" fill="white" transform="translate(3 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
