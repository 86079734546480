import jwtDecode from 'jwt-decode';
import { autorun, makeAutoObservable } from 'mobx';

import { LOGIN_URL } from 'constants/strings';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { User } from 'infra/client/client.types';
import { deleteAuthCookie } from 'infra/client/cookie';
import logger from 'utils/logger';

export type UserType = 'anonymous' | 'user';

export default class AuthenticationStore {
  get token() {
    return this._client.authCredentials.jwt;
  }
  get userType(): UserType {
    return jwtDecode<User>(this._client.authCredentials.jwt as string).userType || 'anonymous';
  }
  get userId() {
    return jwtDecode<User>(this._client.authCredentials.jwt as string).userId;
  }

  get authenticated() {
    return this.userType === 'user';
  }
  // for readability reason
  get notAuthenticated() {
    return !this.authenticated;
  }

  googleLogin = async (tokenId: string) => {
    return this.loginWithGoogleService(tokenId);
  };

  private _client: IHttpClient;

  constructor(
    private loginWithGoogleService: (id: string) => Promise<string>,
    _client: IHttpClient,
    private onLogout: () => void,
  ) {
    makeAutoObservable(this);
    this.loginWithGoogleService = loginWithGoogleService;
    this._client = _client;
    autorun(() =>
      logger.log(
        'user now: ',
        this._client.authCredentials.jwt && jwtDecode(this._client.authCredentials.jwt),
      ),
    );
  }

  logout = () => {
    deleteAuthCookie();
    if (window) {
      window.location.href = LOGIN_URL;
    }
    this.onLogout();
  };
}
