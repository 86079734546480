export class GifValueObject {
  constructor(public url: string) {}
}

export class VideoValueObject {
  constructor(public url: string) {}
}

export class PhotoValueObject {
  constructor(public url: string) {}
}
