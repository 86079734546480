import { css, cx } from '@emotion/css';
import { MyCardsType } from '@illume/shared';
import { engineName } from 'react-device-detect';

import { ColorsV2 } from 'domain/theme/colorsV2';
import { mq } from 'infra/emotion/breakpoints';
import { Typography } from 'infra/emotion/typography';
import { Action } from 'types';

import { CalendarIcon } from './CalendarIcon';
import { LBold } from './CardList';
import { PeopleIcon } from './PeopleIcon';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  containerAs?: React.ElementType;
};

const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(new Date(date));
};

export const CardLayout: React.FC<Props> = ({
  children,
  className,
  onClick,
  containerAs,
  ...props
}) => {
  const layoutStyles = css`
    min-height: 144px;
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    background-color: ${ColorsV2.softGray};

    cursor: pointer;
    // remove blue highlight on mobile
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */

    &:hover {
      box-shadow: 5px 5px 0px 0px #a3a097;
    }

    flex: 0 0 calc(100%);

    &.new-card {
      flex: 0 0 100%;
    }

    ${mq.iphone6} {
      flex: 0 0 calc(50% - 16px);
    }

    ${mq.iphoneXsMax} {
      // flex divide by half
      flex: 0 0 calc(50% - 12px);

      &.new-card {
        flex: 0 0 100%;
      }
    }

    ${mq.tablet} {
      cursor: default;
      min-height: 171px;
      padding-left: 24px;
      flex: 0 0 calc(33.333333% - 16px);
      &.new-card {
        flex: 0 0 calc(33.333333% - 16px);
      }

      &:hover {
        .view-details {
          display: inline-block;
        }
      }
    }
    ${mq.desktopSmall} {
      flex: 0 0 calc(25% - 24px);
      &.new-card {
        flex: 0 0 calc(25% - 24px);
      }
    }
  `;
  const Wraper = containerAs || 'div';
  return (
    <Wraper className={cx(layoutStyles, className)} onClick={onClick} {...props}>
      {children}
    </Wraper>
  );
};

const PlusBlob: React.FC<
  React.SVGProps<SVGSVGElement> & {
    className?: string;
  }
> = (props) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="blob"
        d="M1.51087 21.0036C1.28157 16.1299 4.69664 10.9398 10.2827 7.00004C16.2445 2.79487 22.8551 1.09546 29.978 1.58027C34.0858 1.85586 37.9546 3.02963 41.2038 5.85178C43.3163 7.68389 44.9506 9.93446 46.3606 12.3841C48.8487 16.7015 50.9319 21.2027 52.1076 26.1274C53.7371 32.9455 52.1467 38.9623 47.99 44.3158C43.6334 49.9243 37.8424 52.772 31.0952 53.711C26.3775 54.3694 21.7818 53.8488 17.3178 52.1137C11.8683 49.9958 7.9556 46.0815 5.49187 40.6413C3.03301 35.2216 1.69138 29.4905 1.51087 21.0036Z"
        fill="#FEB81C"
      />
      <path
        id="plus"
        d="M27.5 16.5V38.5M38.5 27.5L16.5 27.5"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
      />
    </svg>
  );
};

const ExlamantionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.00763504 13.0592C-0.153473 9.79587 2.24601 6.32069 6.17088 3.6827C10.3597 0.867017 15.0044 -0.270873 20.0091 0.05375C22.8953 0.238273 25.6136 1.0242 27.8965 2.91385C29.3808 4.14059 30.5291 5.64752 31.5197 7.28773C33.2679 10.1786 34.7316 13.1925 35.5577 16.4899C36.7026 21.0552 35.5851 25.0839 32.6646 28.6684C29.6036 32.4238 25.5347 34.3306 20.794 34.9593C17.4793 35.4001 14.2503 35.0516 11.1138 33.8898C7.28493 32.4717 4.53581 29.8508 2.80475 26.2081C1.07712 22.5792 0.134465 18.7418 0.00763504 13.0592Z"
        fill="#F35410"
      />
      <path
        d="M19.6881 24.6748C19.6881 25.5381 18.9879 26.2377 18.1246 26.2377C17.2612 26.2377 16.5615 25.5381 16.5615 24.6748C16.5615 23.8108 17.2612 23.1113 18.1246 23.1113C18.9879 23.1115 19.6881 23.811 19.6881 24.6748Z"
        fill="#F5F0E1"
      />
      <path
        d="M20.2726 10.0914C20.2339 9.64708 20.0628 9.22871 19.8019 8.88547C19.4032 8.361 18.7941 8.01169 18.125 8.0127C17.4758 8.01189 16.8833 8.34055 16.4844 8.8389C16.2029 9.19044 16.0178 9.62662 15.9773 10.0914C15.9666 10.2141 15.9678 10.3471 15.9773 10.4664L16.8665 20.6509C16.8934 20.9583 17.0283 21.2299 17.2302 21.432C17.461 21.663 17.7794 21.8034 18.125 21.8029C18.4361 21.8034 18.725 21.6895 18.9478 21.4986C19.1894 21.2916 19.3534 20.9932 19.3834 20.6507L20.2726 10.4662C20.2823 10.3469 20.2833 10.2141 20.2726 10.0914Z"
        fill="#F5F0E1"
      />
    </svg>
  );
};

export function CreateNewCardThumbnail({
  className,
  action,
}: {
  className?: string;
  action: Action;
}) {
  return (
    <CardLayout
      onClick={action.onAction}
      className={cx(
        css`
          display: flex;
          justify-content: center;
          row-gap: 8px;
          align-items: center;
          flex-direction: column;
          transition: background-color 0.3s ease-in-out;
          &:hover {
            background-color: ${ColorsV2.primary};
            cursor: pointer;
            box-shadow: none;
          }

          .plus-blob {
            transition: transform 0.3s ease-in-out;
          }

          &:hover {
            .plus-blob {
              transform: rotate(-90deg);

              #blob {
                fill: ${ColorsV2.realWhite};
              }
              #plus {
                stroke: ${ColorsV2.primary};
              }
            }
          }
        `,
        className,
      )}
    >
      <PlusBlob className={'plus-blob'} />
      <p
        className={css`
          ${Typography.XL_BOLD}
        `}
      >
        {action.text}
      </p>
    </CardLayout>
  );
}

export interface ICardThumbnail {
  recipientName: string;
  date: Date;
  contributorsCount: number;
  code: string;
  onViewDetails: () => void;
  onContainerClick: () => void;
  onPeopleIconClick: () => void;
  type: MyCardsType;
  alert: boolean;
  initiatorName: string;
}

export interface CardThumbnailProps extends React.HTMLAttributes<HTMLElement> {
  exlamation?: boolean;
  data: ICardThumbnail;
  onViewDetails: () => void;
  containerAs?: React.ElementType;
  onPeopleIconClick: () => void;
  onContainerClick: () => void;
}

export function CardThumbnail({
  exlamation = false,
  data,
  onViewDetails,
  onPeopleIconClick,
  onContainerClick,
  containerAs,
  ...props
}: CardThumbnailProps) {
  const contentClass = css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `;

  return (
    <CardLayout
      containerAs={containerAs}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onContainerClick();
      }}
      className={css`
        position: relative;
        transition: transform 0.2s ease-in-out;
        transition: box-shadow 0.2s ease-in-out;
        &:hover {
          .exclamation-icon {
            filter: drop-shadow(4px 0px 0px #a3a097);
            // add shadow without filter
            // box-shadow: 4px 0px 0px #a3a097;
          }

          // lift position a bit
          transform: translateY(-2px);

          // make cursor
          cursor: pointer;
        }
      `}
      {...props}
    >
      {exlamation && (
        <ExlamantionIcon
          className={cx(
            css`
              position: absolute;
              top: -12px;
              right: -12px;
            `,
            'exclamation-icon',
            engineName === 'WebKit' &&
              css`
                top: -13px;
                right: -14px;
                padding-right: 6px;
              `,
          )}
        />
      )}
      <section className={contentClass} onClick={(e) => {}}>
        <div
          className={css`
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            margin-bottom: 20px;
            align-items: flex-start;
            ${mq.tablet} {
              flex-direction: row-reverse;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 28px;
            }
          `}
        >
          <button
            className={css`
              display: flex;
              column-gap: 8px;
              align-items: center;
              background-color: ${ColorsV2.realWhite};
              padding: 2px 8px;
              // capsule
              border-radius: 16px;
              min-width: 50px;
            `}
            onClick={(e) => {
              e.stopPropagation();
              onPeopleIconClick?.();
            }}
          >
            <PeopleIcon />
            <span
              className={css`
                ${LBold}
                color: ${ColorsV2.gray60};
              `}
            >
              {data.contributorsCount}
            </span>
          </button>
          <h3
            className={css`
              display: flex;
              column-gap: 8px;
              min-width: 80px;
            `}
          >
            <span
              className={css`
                font-size: 16px;
                font-weight: 420;
                line-height: 24px;
                color: ${ColorsV2.gray40};
              `}
            >
              {data.type === MyCardsType.RECEIVED ? 'From' : 'To'}:
            </span>
            <span
              className={css`
                color: ${ColorsV2.gray100};
                font-size: 16px;
                font-weight: 420;
                line-height: 24px;
                // elipsis
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 60px;
                ${mq.tablet} {
                  max-width: 80px;
                }
              `}
            >
              {data.type === MyCardsType.RECEIVED ? data.initiatorName : data.recipientName}
            </span>
          </h3>
        </div>
        <p
          className={css`
            display: flex;
            align-items: center;
            column-gap: 6px;
            font-size: 16px;
            font-weight: 410;
            line-height: 24px;
            color: ${ColorsV2.butternutStone};
          `}
        >
          <CalendarIcon />
          <span>{formatDate(data.date)}</span>
        </p>
        <button
          onClick={onViewDetails}
          className={cx(
            'view-details',
            css`
              display: none;
              text-align: left;
              margin-top: auto;
              font-size: 16px;
              font-weight: 450;
              line-height: 24px;
              color: ${ColorsV2.gray70};
              text-decoration: underline;
            `,
          )}
        >
          View Details
        </button>
      </section>
    </CardLayout>
  );
}
