import { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

import Button from 'components/illume/buttons/Button';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { rem } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { HOMEPAGE_URL } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import logger from 'utils/logger';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  button: {
    width: 180,

    [theme.breakpoints.up('md')]: {
      width: 256,
    },
  },
}));

// TODO: make sure the style doesn't break
export const Error404 = () => {
  const classes = useStyles();
  const location = useLocation();
  const analytics = useAnalytics();
  useEffect(() => {
    logger.error('error - page not found');
    logger.log('user shown by 404');
    analytics.track(EVENT_NAMES.ERROR_PAGE_NOT_FOUND.name, { path: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <IllumeLayout
      hideHamburger
      contentProps={{
        justifyContent: 'center',
        alignItems: 'center',
        style: { flex: 1 },
      }}
    >
      <Grid container justifyContent="center" className={classes.root}>
        <Grid container item direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Text align="center" fontWeight={900} fontSize={rem[3750]}>
              &#128563;
            </Text>
            <Text
              align="center"
              fontWeight={900}
              fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
            >
              PAGE NOT FOUND...
            </Text>
          </Grid>
          <Grid item>
            <Text align="center" fontWeight={300} fontSize={rem[1000]}>
              Oops! The page you tried to reach doesn’t exist. Please check your link or go back to
              the home page.
            </Text>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              onClick={() => (window.location.href = HOMEPAGE_URL)}
            >
              back to home page
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default Error404;
