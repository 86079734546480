import React from 'react';

import { colors } from 'constants/design';

const Dollar = ({ color = colors.contrastText, size = 16 }) => {
  return (
    <svg
      width={size}
      height={(22 / 16) * size}
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5691 10.7938C12.6753 10.0375 11.3691 9.41875 9.58156 9.00625V5.43125C10.5441 5.6375 11.4378 6.05 12.2628 6.66875C12.4003 6.7375 12.5378 6.80625 12.6753 6.7375C12.8128 6.7375 12.9503 6.66875 13.0191 6.53125L14.6003 4.2625C14.6691 4.125 14.7378 3.9875 14.6691 3.85C14.6691 3.7125 14.5316 3.575 14.4628 3.50625C12.9503 2.475 11.3003 1.85625 9.58156 1.65V0.89375C9.58156 0.61875 9.37532 0.34375 9.03157 0.34375H7.45031C7.17531 0.34375 6.90031 0.55 6.90031 0.89375V1.65C5.45656 1.7875 4.21906 2.3375 3.25656 3.23125C2.15656 4.19375 1.60656 5.5 1.60656 7.08125C1.60656 8.6625 2.08781 9.83125 3.05031 10.6563C3.94406 11.4125 5.18156 11.9625 6.90031 12.375V16.0875C5.73156 15.8125 4.63156 15.1938 3.53156 14.2313C3.39406 14.1625 3.25656 14.0938 3.11906 14.0938C2.98156 14.0938 2.84406 14.1625 2.77531 14.3L0.987814 16.4313C0.781564 16.6375 0.850314 16.9813 1.05656 17.1875C2.77531 18.7 4.76906 19.5938 6.90031 19.9375V21.2438C6.90031 21.5188 7.10656 21.7938 7.45031 21.7938H9.03157C9.30657 21.7938 9.58156 21.5875 9.58156 21.2438V19.9375C11.1628 19.8 12.4003 19.25 13.4316 18.3563C14.5316 17.3938 15.1503 16.0875 15.1503 14.5063C15.0816 12.8563 14.6003 11.6188 13.5691 10.7938ZM11.0253 14.6438C11.0253 15.0563 10.8878 15.4 10.5441 15.675C10.2691 15.8813 9.92532 16.0188 9.51282 16.1563V13.0625C10.1316 13.3375 10.4753 13.5438 10.6128 13.6813C10.9566 13.8875 11.0253 14.2313 11.0253 14.6438ZM5.66282 6.7375C5.66282 6.325 5.80032 6.05 6.14407 5.775C6.35032 5.56875 6.62532 5.43125 6.96907 5.3625V8.25C6.41906 8.04375 6.14407 7.8375 6.00657 7.7C5.73157 7.425 5.66282 7.15 5.66282 6.7375Z"
        fill={color}
      />
    </svg>
  );
};

export default Dollar;
