import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { makeAutoObservable, when } from 'mobx';

import AuthenticationStore from 'contexts/store/auth-store/auth.store';
import { IUserService } from 'domain/interfaces/IUserService';
import { UserProfileDTO, UserProfileWithPlan } from 'types';

import { UserSummary } from './types';

export class UserProfileStore {
  fetchUserTask = new ObservablePromise(this._userService.getUser);
  updateUserTask = new ObservablePromise(this._userService.updateUser);
  summaryTask = new ObservablePromise(this._userService.getUserSummary);

  constructor(
    private _authStore: Pick<AuthenticationStore, 'authenticated'>,
    private _userService: IUserService,
  ) {
    makeAutoObservable(this);
    when(
      () => this._authStore.authenticated,
      () => {
        this.fetchUserProfile();
        this.fetchUserSummary();
      },
    );
  }

  get fetching() {
    return this.fetchUserTask.isExecuting;
  }

  get updating() {
    return this.updateUserTask.isExecuting;
  }

  /**
   * @deprecated - will be private in the future, using DTO directly is discouraged
   */
  get userProfileDTO(): (UserProfileDTO & UserProfileWithPlan) | undefined {
    return this.fetchUserTask.getResultOrDefault(undefined);
  }

  get email() {
    return this.userProfileDTO?.email;
  }

  get fullName() {
    if (!this.userProfileDTO) return undefined;
    if (this.userProfileDTO.firstName && this.userProfileDTO.lastName) {
      return `${this.userProfileDTO.firstName} ${this.userProfileDTO.lastName}`;
    }
  }

  get firstName() {
    return this.userProfileDTO?.firstName;
  }

  get lastName() {
    return this.userProfileDTO?.lastName;
  }

  get displayName() {
    return this.userProfileDTO?.displayName;
  }

  get whateverNameIsSet() {
    return this.fullName || this.displayName || this.firstName || this.lastName || this.email;
  }

  get userSummaryDto(): UserSummary | undefined {
    return this.summaryTask.getResultOrDefault(undefined);
  }

  get birthday() {
    return this.userProfileDTO?.birthday;
  }

  get hasNameSet() {
    return !!(this.lastName || this.displayName || this.firstName);
  }

  get belowBasicLimit() {
    if (!this.userSummaryDto) {
      return undefined;
    }
    const MAX_CARD_LIMIT = 2;
    return this.userSummaryDto.userStats.cardsCreated < MAX_CARD_LIMIT;
  }

  /**
   * a state required to wether or not user should be redirected,
   * if they have choose plan, create card. if not redirect to paywall
   */
  get userHaveChoosenPlan() {
    if (this.userProfileDTO) {
      return (
        this.userProfileDTO.subscriptionPlans.standard ||
        this.userProfileDTO.subscriptionPlans.premium
      );
    }
    // if user at least sent one card and they are on Basic plan, assume that he has choosen plan
    if (this.userProfileDTO && this.userSummaryDto) {
      return this.userSummaryDto.userStats.cardsCreated >= 1;
    }
  }

  get plans() {
    if (!this.userProfileDTO) {
      return undefined;
    }

    let plans = [];
    for (const key in this.userProfileDTO.subscriptionPlans) {
      const index = key as keyof typeof this.userProfileDTO.subscriptionPlans;
      if (this.userProfileDTO.subscriptionPlans[index]) {
        plans.push(key);
      }
    }

    return plans;
  }

  /**
   * temporary solution to display plan type in the UI
   */
  planTypeCopy(type?: string) {
    if (!type) {
      return 'unknown plan';
    }
    return (
      new Map([
        ['Basic', 'Basic'],
        ['Premium', 'Standard'],
        ['Standard', 'Premium'],
      ]).get(type) || 'unknown plan'
    );
  }

  get userSubscribedToPlan() {
    if (!this.userProfileDTO) {
      return undefined;
    }
    if (this.userProfileDTO) {
      return (
        this.userProfileDTO.subscriptionPlans.standard ||
        this.userProfileDTO.subscriptionPlans.premium
      );
    } else {
      false;
    }
  }

  fetchUserProfile = () => this.fetchUserTask.execute().catch(console.error);
  updateUserProfile = (payload: Partial<UserProfileDTO>) =>
    this.updateUserTask.execute(payload).then(this.fetchUserProfile);
  fetchUserSummary = () => this.summaryTask.execute().catch(console.error);
}
