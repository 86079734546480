import merge from 'lodash/merge';

import base from './base.config';
import local from './local.config';
import { DeepPartial } from './type';

// mostly the same with local config
const env: DeepPartial<typeof base> = {
  testMessage: 'Greetings from test!',
  logger: {
    console: false,
    silence: true,
  },
};

export default merge(
  // merge is mutative
  // avoid mutations with {}
  {},
  local,
  env,
);
