import { v4 } from 'uuid';

import { MerchantProduct } from 'pages/illume/marketplace/store/store';
import { slugify } from 'utils/string/url.utils';

import { FeaturedProductLineupDTO } from '../../../infra/merchant-product-service/MerchantProductsService';

export class ProductLine {
  static fromDto = (dto: FeaturedProductLineupDTO) => {
    const id = slugify(dto.vendor);
    return new ProductLine(
      id,
      dto.products.map((p) => new MerchantProduct(p)),
      dto.vendor,
      dto.productsTitle,
      dto.shortDesc,
      dto.bannerImage,
      dto.thumbnailImage,
      dto.longDesc,
    );
  };
  private constructor(
    public id: string,
    public products: MerchantProduct[],
    public vendor: string,
    public title: string,
    public longDesc: string,
    public bannerImage: string,
    public image: string,
    public bannerDesc: string,
  ) {
    this.id = id || v4();
  }

  titleFromTitleAndVendor() {
    return `${this.title} from ${this.vendor}`;
  }
}
