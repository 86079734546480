const Heart = ({ size = 12, color = '#282828', ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8119 1.47981C10.2239 0.891797 9.43188 0.591797 8.65188 0.591797C7.87188 0.591797 7.09188 0.891797 6.49188 1.47981L5.9879 1.98379L5.48392 1.47981C4.89592 0.891797 4.10393 0.591797 3.32393 0.591797C2.51995 0.591797 1.71593 0.903787 1.11593 1.53981C-0.0360706 2.75181 0.0359293 4.67181 1.21194 5.84782L5.61594 10.2518C5.81992 10.4558 6.14395 10.4558 6.33594 10.2518L10.7879 5.79982C11.9999 4.61181 11.9999 2.66781 10.8119 1.47981H10.8119Z"
      fill={color}
    />
  </svg>
);

export default Heart;
