import { makeStyles } from '@material-ui/core/styles';

import { colors, rem } from 'constants/design';

export const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    width: 187,
    color: colors.black60,
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      width: 258,
    },
  },
  container: {
    width: '100%',
    position: 'relative',
    marginTop: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
      marginTop: theme.spacing(12),
    },
  },
  contentContainer: {
    zIndex: 1,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  containerLayout: {
    zIndex: 5,
    maxWidth: 'calc(579px + 145px + 416px)',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    flexDirection: ({ direction }) => (direction === 'left' ? 'row-reverse' : 'row'),
    alignItems: 'center',
    rowGap: rem[1125],
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
    padding: '0 20px',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      justifyContent: ({ direction }) => direction,
      gap: rem[1250],
      marginBottom: theme.spacing(2),
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: '0 12px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  wavyBackground: {
    height: 'calc(100% - 168px)',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 138px)',
    },
  },
  subtitle: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  urlText: {},
  imageContainer: {
    '& .img': {
      display: 'block',
      width: '100%',
      borderRadius: theme.spacing(2),
    },
    '& .shadow': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(2),
      zIndex: -1,
      borderRadius: theme.spacing(2),
    },
  },
  paragraphBox: {
    width: 320,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 534,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  textLink: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  parrentBox: {
    width: '92%',
    margin: 'auto',
    borderRadius: 16,
    background: '#f9f5e8',
    marginTop: theme.spacing(5),
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 1016,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
  },
  selectOccasion: {
    marginBottom: theme.spacing(2),
  },
  gridBox: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 150px)',
    gridAutoRows: '150px',
    gridGap: '20px',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, 210px)',
      gridAutoRows: 210,
      width: '988px',
      margin: 'unset',
    },
  },
  gridItem: {
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 12,
    color: '#565659',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    transitionProperty: 'background',
    '& .accent': {
      fill: colors.primary,
    },
    '&:hover': {
      color: colors.background,
      background: colors.primary,
      '& .accent': {
        fill: colors.background,
      },
    },
    background: colors.background,
  },
  occasionName: {
    marginTop: theme.spacing(1),
    width: 130,
    [theme.breakpoints.up('md')]: {
      width: 200,
      marginTop: theme.spacing(2),
    },
  },
}));
