import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { colors, rem, spacing } from 'constants/design';

export const contentStyles = makeStyles<Theme>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      //@ts-ignore
      zIndex: '1540!important',
    },
    paper: {
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      border: 'none',
      outline: 0,
      boxShadow: theme.shadows[5],
      borderRadius: rem[1000],
      marginLeft: spacing[8],
      marginRight: spacing[8],
      padding: spacing[5],
      width: '100%',
      maxWidth: 316,
      height: 600,
      overflowY: 'scroll',
      [theme.breakpoints.up('md')]: {
        maxWidth: 861,
        height: 'auto',
        overflowY: 'hidden',
        padding: spacing[10],
      },
    },
    tagContainer: {
      borderRadius: 4,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      padding: `4px 6px 5px`,
      justifyContent: 'center',
      alignItems: 'center',
    },
    contributionInputContainer: {
      marginTop: theme.spacing(2),
    },
    contributeButton: {
      marginTop: theme.spacing(1),
    },
    whyWeLoveIt: {
      width: '100%',
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(1),
    },
    priceBreakdown: {
      width: '100%',
      marginTop: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    breakdown: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      rowGap: 5,
    },
    inputAmount: {
      backgroundColor: 'white',
      marginTop: theme.spacing(0.6),
      marginBottom: theme.spacing(0.6),
    },
    desktopRemoveButton: {
      position: 'absolute',
      bottom: -20,
      left: '20%',
    },
    closeIcon: {
      position: 'absolute',
      top: '15px',
      right: '15px',
      zIndex: 2,
      cursor: 'pointer',
    },
    productTitle: {
      marginTop: 5,
      [theme.breakpoints.up('md')]: {
        marginTop: 30,
      },
    },
  }),
);

export const carouselStyles = makeStyles<Theme, { img: string; backgroundColor?: string }>(
  (theme) =>
    createStyles({
      image: {
        background: ({ img, backgroundColor }) =>
          img ? `url(${img}) center/contain no-repeat` : backgroundColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 200,
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
          width: 270,
          height: 270,
        },
      },
      htmlRenderer: {
        textAlign: 'center',
        width: '100%',
        fontWeight: 500,
        fontSize: rem[875],
        color: colors.contrastText,
      },
    }),
);
