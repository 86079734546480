import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';

import For from 'components/control-flow/For';
import { Gift } from 'components/illume/icons';
import ConfettiIcon from 'components/illume/icons/Confetti';
import ThreePeople from 'components/illume/icons/ThreePeople';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

const MiniBlob: React.FC<any> = ({
  backgroundColor = '#FFFDF3',
  children,
  style,
  size = 50,
  ...props
}) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      {...props}
    >
      <svg
        width={size}
        height={(48 / 50) * size}
        viewBox="0 0 50 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.701362 17.8367C0.480979 13.3795 3.76328 8.633 9.13218 5.02995C14.8621 1.1842 21.2157 -0.369968 28.0616 0.0734134C32.0098 0.325441 35.7282 1.39889 38.851 3.97983C40.8814 5.65535 42.4522 7.71357 43.8073 9.95381C46.1987 13.9022 48.2009 18.0187 49.3309 22.5225C50.8971 28.7578 49.3684 34.2604 45.3734 39.1563C41.1861 44.2855 35.6203 46.8898 29.1354 47.7485C24.6012 48.3506 20.1841 47.8746 15.8937 46.2877C10.6561 44.3508 6.89552 40.7711 4.52758 35.7959C2.16433 30.8394 0.874855 25.5982 0.701362 17.8367Z"
          fill={backgroundColor}
        />
      </svg>
      <div style={{ position: 'absolute', zIndex: 1 }}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  const desktop = theme.breakpoints.up('md');
  return {
    footer: {
      background: colors.backgroundGray,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      [desktop]: {
        paddingLeft: 90,
        paddingRight: 90,
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
    innerContainer: {
      display: 'grid',
      columnGap: 10,
      rowGap: 10,
      gridTemplateColumns: 'repeat(3, 1fr)',
      [desktop]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
        columnGap: 20,
        maxWidth: 910,
      },
    },
    step: {
      display: 'flex',
      columnGap: 7,
      textAlign: 'center',
      alignItems: 'center',
      flex: 1,
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'unset',
      },
    },
    heading: {
      gridColumnEnd: `span 3`,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnEnd: `span 1`,
      },
    },
  };
});

type FooterProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Footer: FunctionComponent<FooterProps> = ({ className, ...props }) => {
  const classes = useStyles();
  const desktop = useIsDesktop();
  const desktopReduceRatio = useMemo(() => (desktop ? 1 : 0.7), [desktop]);
  return (
    <div className={cs(classes.footer, className)} {...props}>
      <div className={classes.innerContainer}>
        <div className={classes.heading}>
          <Text fontSize={{ mobile: 16, desktop: 18 }} fontWeight={700}>
            How it Works
          </Text>
        </div>

        <For
          each={[
            {
              icon: <Gift fillOpacity={1} size={20 * desktopReduceRatio} color={colors.gray60} />,
              text: 'Pick the item you want to gift',
            },
            {
              icon: <ThreePeople size={26 * desktopReduceRatio} color={colors.gray60} />,
              text: 'Split the cost amongst a group',
            },
            {
              icon: <ConfettiIcon size={22 * desktopReduceRatio} color={colors.gray60} />,
              text: 'Make the recipient’s day!',
            },
          ]}
        >
          {(e, id) => (
            <div className={classes.step}>
              <MiniBlob size={desktop ? 50 : 36} key={id}>
                {e.icon}
              </MiniBlob>
              <Text align={desktop ? 'left' : 'center'} color={colors.gray60} fontSize={12}>
                {e.text}
              </Text>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};

export default Footer;
