import { IllumeGifDTO, PhotoDTO, VideoDTO } from '@illume/shared';
import UploadClient, { BrowserFile, FileFromOptions } from '@uploadcare/upload-client';

import { UPLOAD_CARE_SOURCE } from 'constants/strings';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IMediaUploaderService, ImageMetadata } from 'domain/interfaces/IImageUploaderService';
import { CustomError } from 'utils';
import logger from 'utils/logger';

import illumeApiHttpClient from '../client';
import { client as uploadCareClient } from '../uploadcare/uploadCare';

interface PhotoMetadata extends ImageMetadata {
  source: string;
  sourceId: string;
  fileName: string;
  fileSize: number;
  mimeType: any;
  sourceBaseUrl: string;
  sourceDeliveryModifiers: string;
  sourceDeliveryUrl: string;
  crop: any;
}

class MediaUploader implements IMediaUploaderService {
  uploadPhoto = async (photoMetadata: PhotoMetadata) => {
    const requestObj = {
      url: '/photo',
      method: 'post',
      data: photoMetadata,
    };

    const resp = await this.api.makeRequest<{ photo: PhotoDTO }>(requestObj);

    if (resp.success) {
      return {
        // just want to maintain old compatibility
        ...resp.photo,
        url: resp.photo.url || resp.photo.deliveryUrl,
        deliveryUrl: resp.photo.deliveryUrl,
      };
    } else {
      const errMsg = 'Failed to upload the photo';
      logger.error(errMsg, resp);
      throw Error(errMsg);
    }
  };

  uploadVideo = async (data: string | Buffer | BrowserFile, options: FileFromOptions) => {
    const file = await this.uploadCareClient.uploadFile(data, options);
    logger.log('uploaded a video', file);
    const requestData = {
      source: UPLOAD_CARE_SOURCE,
      sourceId: file.uuid,
      fileName: file.name,
      fileSize: file.size,
      //@ts-ignore
      mimeType: file.mimeType,
      sourceBaseUrl: file.originalUrl,
      sourceDeliveryUrl: file.cdnUrl,
      sourceDeliveryModifiers: file.cdnUrlModifiers || '', // throws error if null/undefined, must be a string
    };

    const requestObj = {
      url: '/video',
      method: 'post',
      data: requestData,
    };

    const resp = await illumeApiHttpClient.makeRequest<{ video: VideoDTO }>(requestObj);
    if (resp.success) {
      return {
        // just want to maintain old compatibility
        ...resp.video,
        url: resp.video.url || resp.video.deliveryUrl,
        deliveryUrl: resp.video.deliveryUrl,
      };
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  constructor(private api: IHttpClient, private uploadCareClient: UploadClient) {}

  uploadGif = async ({ rating, id }: any) => {
    const requestObj = {
      url: '/gif',
      method: 'post',
      data: { rating, id },
    };
    const resp = await illumeApiHttpClient.makeRequest<{ gif: IllumeGifDTO }>(requestObj);
    if (resp.success) {
      // just want to maintain old compatibility
      return {
        ...resp.gif,
        url: resp.gif.giphyCode,
        giphyCode: resp.gif.giphyCode,
        gifCode: resp.gif.gifCode,
      };
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };
}

const mediaUploader = new MediaUploader(illumeApiHttpClient, uploadCareClient);

export default mediaUploader;
