import { ProductLine } from 'domain/entities/product-line/ProductLine';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IProductLineService } from 'domain/interfaces/IProductLineService';
import { FeaturedProductLineupDTO } from 'infra/merchant-product-service/MerchantProductsService';
import { CustomError } from 'utils';

export class ProductLineService implements IProductLineService {
  cachedProductLines: ProductLine[] | null = null;
  constructor(private httpClient: IHttpClient) {}
  getFeaturedProductLines = async () => {
    const requestObj = {
      url: `/cardGift/merchantProducts/featured`,
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{
      featuredMerchants: FeaturedProductLineupDTO[];
    }>(requestObj);
    if (resp.success) {
      return (this.cachedProductLines = resp.featuredMerchants.map((dto) =>
        ProductLine.fromDto(dto),
      ));
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };
  /** should have a dedicated endpoint */
  getProductLine = async (id: string) => {
    let result;
    // check for cached product lines
    if (this.cachedProductLines) {
      result = this.cachedProductLines.find((p) => p.id === id);
    }
    // if not cached, fetch from server
    result = await this.getFeaturedProductLines().then((result) => {
      return result.find((p) => p.id === id);
    });

    if (result) {
      return result;
    } else {
      throw new Error('product line not found');
    }
  };
}
