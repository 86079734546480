import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import { MY_CARDS_URL } from 'constants/strings';

const comingSoonStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: theme.spacing(6.5),
    paddingLeft: theme.spacing(6.5),
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(7),
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  emoji: { fontSize: '90px', textAlign: 'center' },
}));

export const ComingSoon = () => {
  const classes = comingSoonStyles();
  const history = useHistory();

  const handleBackToMyCards = () => {
    history.push(MY_CARDS_URL);
  };

  return (
    <IllumeLayout
      background="transparent"
      contentProps={{
        justifyContent: 'center',
      }}
    >
      <Grid container className={classes.root} justifyContent="center" spacing={5}>
        <Grid xs={12} item container alignItems="center" direction="column" spacing={2}>
          <Grid item>
            <p className={classes.emoji}>&#129323;</p>
          </Grid>
          <Grid item>
            <Text
              fontWeight={900}
              align="center"
              fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
            >
              COMING SOON...
            </Text>
          </Grid>
          <Grid item>
            <Text
              fontWeight={300}
              align="center"
              fontSize={{ mobile: rem[1000], desktop: rem[1000] }}
            >
              We are still working to create this page. Check back soon to see your very own illume
              notification center!
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button fullWidth onClick={handleBackToMyCards}>
            <Text
              color={colors.contrastText}
              fontSize={{ desktop: '16px', mobile: '14px' }}
              fontWeight={500}
            >
              back to my cards
            </Text>
          </Button>
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};
