import base from './base.config';
import { DeepPartial } from './type';

const env: DeepPartial<typeof base> = {
  testMessage: 'Greetings from dev!',
  stripePublicKey: 'pk_test_taQJ10KWtWGHWa1q04YFugQi00xZtJnU0O',
  env: 'dev',
  baseURL_new: 'https://web.dev.illumenotes.com/api',
  segmentWriteKey: 'GJt9C7sMOhhdPRdc40LNAIDFwjPhbqrZ',
  mockEnabled: false,
  FF: {
    APPLE_PAY: true,
    EXPERIMENTS: true,
    SAVE_THE_DATE: true,
    TEAMS_BATCH_CREATION: true,
  },
  gtm: {
    auth: 'DAui4H3SKKYyU78fgF7F4A',
    preview: 'env-24',
  },
};

export default env;
