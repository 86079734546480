import { makeStyles } from '@material-ui/core/styles';

const desktop = {
  small: 225,
  medium: 255,
  large: 300,
};

const mobile = {
  small: 155,
  large: 255,
};

export const closeCardStyles = makeStyles((theme) => {
  const getDimension = (props) => {
    if (props.fixedSize) {
      return { minHeight: props.size, minWidth: props.size };
    } else {
      return {
        minHeight: 170,
        minWidth: ({ size }) => size && mobile[size],
        height: '100%',
        [theme.breakpoints.up('md')]: {
          minHeight: ({ size }) => (size ? desktop[size] : 170),
          minWidth: ({ size }) => size && desktop[size],
        },
      };
    }
  };
  return {
    root: (props) => ({
      cursor: props.clickable && 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...getDimension(props),
    }),
    container: {
      padding: theme.spacing(1.3),
      height: '100%',
    },
  };
});
