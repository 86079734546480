export default function FullScreen({ size = 27 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6 11.2H7.52C9.548 11.2 11.2 9.548 11.2 7.52V1.6C11.2 0.716 10.484 0 9.6 0C8.716 0 8 0.716 8 1.6V7.52C8 7.784 7.784 8 7.52 8H1.6C0.716 8 0 8.716 0 9.6C0 10.484 0.716 11.2 1.6 11.2Z"
        fill="#FFFDF3"
      />
      <path
        d="M16.8002 0C15.9162 0 15.2002 0.716 15.2002 1.6V7.52C15.2002 9.548 16.8522 11.2 18.8802 11.2H24.8002C25.6842 11.2 26.4002 10.484 26.4002 9.6C26.4002 8.716 25.6842 8 24.8002 8H18.8802C18.6162 8 18.4002 7.784 18.4002 7.52V1.6C18.4002 0.716 17.6842 0 16.8002 0Z"
        fill="#FFFDF3"
      />
      <path
        d="M1.6 18.4002H7.52C7.784 18.4002 8 18.6162 8 18.8802V24.8002C8 25.6842 8.716 26.4002 9.6 26.4002C10.484 26.4002 11.2 25.6842 11.2 24.8002V18.8802C11.2 16.8522 9.548 15.2002 7.52 15.2002H1.6C0.716 15.2002 0 15.9162 0 16.8002C0 17.6842 0.716 18.4002 1.6 18.4002Z"
        fill="#FFFDF3"
      />
      <path
        d="M18.4002 24.8002V18.8802C18.4002 18.6162 18.6162 18.4002 18.8802 18.4002H24.8002C25.6842 18.4002 26.4002 17.6842 26.4002 16.8002C26.4002 15.9162 25.6842 15.2002 24.8002 15.2002H18.8802C16.8522 15.2002 15.2002 16.8522 15.2002 18.8802V24.8002C15.2002 25.6842 15.9162 26.4002 16.8002 26.4002C17.6842 26.4002 18.4002 25.6842 18.4002 24.8002Z"
        fill="#FFFDF3"
      />
    </svg>
  );
}
