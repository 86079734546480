import { css, cx } from '@emotion/css';

import { ColorsV2 } from 'domain/theme/colorsV2';
import { getRippleContext } from 'views/components-v2/elements/top-navigation';
import { createRipple } from 'views/components-v2/elements/top-navigation/createRipple';

export type ButtonProps = {
  color: string;
  background: string;
  outlineColor?: string;
  hoverBackground?: string;
  hoverColor?: string;
  hoverOutline?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = ({
  color,
  background,
  outlineColor = background,
  hoverBackground = background,
  hoverColor = color,
  hoverOutline = outlineColor,
  children,
  leftIcon,
  rightIcon,
  className,
  onClick,
}: ButtonProps) => (
  <button
    onClick={(e) => {
      createRipple(e);
      onClick?.(e);
    }}
    className={cx(
      css`
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 4px;
        padding: 4px 6px;
        border-radius: 1000px;
        box-shadow: 0 0 0 2px ${outlineColor};
        color: ${color};
        background: ${background};
        transition: all 100ms ease-in-out;

        // typo
        font-size: 16px;
        font-weight: 420;
        line-height: 24px;

        &:hover {
          color: ${hoverColor};
          background: ${hoverBackground};
          box-shadow: 0 0 0 2px ${outlineColor};
        }
      `,
      getRippleContext(),
      className,
    )}
  >
    {leftIcon}
    {children}
    {rightIcon}
  </button>
);

Button.Default = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      background={ColorsV2.primary}
      color={ColorsV2.softGray}
      hoverBackground={ColorsV2.gray90}
      hoverColor={ColorsV2.softGray}
    />
  );
};

export default Button;
