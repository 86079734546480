import { format, parse } from 'date-fns';

const DateFormats = {
  'January 1st': 'MMMM do',
  'January 1st 2020': 'MMMM do yyyy',
  '31/01/2020': 'mm/dd/yy',
};

type Keys = keyof typeof DateFormats;

export class IllumeDate extends Date {
  constructor(sourceDate: any) {
    // This is handling for an ISO string date (i.e. 'YYYY-MM-DD') passed in without a time or timezone
    let parsedDate = undefined;
    if (sourceDate && (typeof sourceDate == 'string' || sourceDate instanceof String)) {
      // note: this will create the date in the timezone of the user!
      parsedDate = parse(sourceDate as string, 'yyyy-MM-dd', new Date());
    }
    // create the extended date object at 00:00:00 in the user's timezone
    if (parsedDate && !isNaN(parsedDate.getTime())) {
      super(parsedDate.getTime());
    } else {
      // this is the default behavior:
      sourceDate ? super(sourceDate) : super();
    }
    // see: https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, IllumeDate.prototype);
  }

  getTz() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  toFormat(type: Keys) {
    const formatStr = DateFormats[type];
    return format(this, formatStr);
  }

  // these doesn't describe it very well
  toMobileReadeableString() {
    return format(this, 'MMMM do, yyyy');
  }

  toHumanReadableString() {
    return format(this, 'MMMM d, yyyy');
  }

  toAbbreviatedReadeableString() {
    return format(this, 'MMMM do');
  }

  getMonthYear() {
    return format(this, 'MMMM yyyy');
  }

  toAPIString() {
    // formats using the browser's local TZ so it's the correct date
    return format(this, 'yyyy-MM-dd');
  }

  toMonthDateYearString() {
    return format(this, 'MM / dd / yy');
  }
}
