import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { rem, spacing } from 'constants/design';

export const modalStyles = makeStyles<Theme>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      //@ts-ignore
      zIndex: '1540!important',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      border: 'none',
      outline: 0,
      boxShadow: theme.shadows[5],
      borderRadius: rem[1000],
      marginLeft: spacing[8],
      marginRight: spacing[8],
      padding: `40px 32px 32px`,
      width: '100%',
      maxWidth: 316,
      height: 'auto',
      overflowY: 'auto',
      position: 'relative',
      maxHeight: '80vh',
      [theme.breakpoints.up('md')]: {
        maxWidth: 861,
      },
    },
    tagWrapper: {
      rowGap: 4,
    },
    tagContainer: {
      borderRadius: 4,
      marginRight: theme.spacing(1),
      padding: `4px 6px 5px`,
      justifyContent: 'center',
      alignItems: 'center',
    },
    closeButton: {
      marginTop: theme.spacing(1),
    },
    closeModalIcon: {
      position: 'absolute',
      top: 0,
      right: 5,
    },
  }),
);
