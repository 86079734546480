import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import { colors } from 'constants/design';

const snackbarStyles = makeStyles(() => ({
  error: {
    backgroundColor: `${colors.alertRed} !important`,
  },
  success: {
    backgroundColor: `${colors.alertGreen} !important`,
  },
}));

const IllumeSnackbarProvider = ({ children, ...props }) => {
  const classes = snackbarStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{ variantError: classes.error, variantSuccess: classes.success }}
      {...props}
    >
      {children}
    </SnackbarProvider>
  );
};

export default IllumeSnackbarProvider;
