import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'components/illume/buttons';
import { Dollar } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { TextInput } from 'components/illume/text-inputs';
import { VALIDATION_ERRORS } from 'constants/strings/copywriting';
import { Money } from 'domain/entities/money/Money';
import { IMoney } from 'domain/interfaces/IMoney';
import { GiftV2Dto } from 'types';
import { noop } from 'utils';
import { toCent } from 'utils/currency';
import { isValid } from 'utils/form';

export interface ContributonFormValues {
  dollar: string;
  email: string;
}

type FormValues = ContributonFormValues;

interface IContributionFormProps {
  gift: GiftV2Dto;
  defaultValues?: Partial<ContributonFormValues>;
  onSubmit?: (values: ContributonFormValues) => any;
}

export const getPaymentFormSchema = (
  restOfTheGoal: IMoney,
  fieldNames = { amount: 'amount', email: 'email' },
) =>
  yup.object().shape({
    [fieldNames.amount]: yup
      .number()
      // TODO: please suggest a better copywriting
      // .integer('enter whole dollar amounts only')
      .min(1, VALIDATION_ERRORS.giftContribution.min)
      .typeError(VALIDATION_ERRORS.giftContribution.required)
      .max(
        restOfTheGoal.toUnit(),
        `Can't contribute more than the rest of the goal, max contribution is: ${restOfTheGoal.toFormat()}`,
      )
      .required(VALIDATION_ERRORS.giftContribution.required)
      .test(
        'customMax',
        "can't left remaining contribution less than 1 dollar, please fulfill it",
        (valInDollar) => {
          const $val = new Money({ amount: toCent(valInDollar || '0') });
          if (restOfTheGoal.subtract($val).equalsTo(new Money({ amount: 0 }))) {
            return true;
          }
          if (restOfTheGoal.subtract($val).lessThan(new Money({ amount: 100 }))) {
            return false;
          } else return true;
        },
      ),
    [fieldNames.email]: yup.string().email('must be a valid email').required('this is required'),
  });

const ContributionForm: React.FunctionComponent<IContributionFormProps> = ({
  gift,
  defaultValues,
  onSubmit = noop,
}) => {
  const restOfTheGoal = new Money({ amount: gift.financial.goal }).subtract(
    new Money({ amount: gift.financial.current }),
  );

  const paymentFormSchema = getPaymentFormSchema(restOfTheGoal, {
    amount: 'dollar',
    email: 'email',
  });

  const {
    errors,
    register,
    formState: { isSubmitted, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(paymentFormSchema),
    defaultValues,
    reValidateMode: 'onChange',
  });

  const formattedSuggestedAmount = new Money({
    amount: gift?.financial?.suggestedAmount,
  }).toFormat();

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Text fontWeight={400} fontSize={{ mobile: '14px', desktop: '16px' }}>
          The suggested amount is {formattedSuggestedAmount}
        </Text>
      </Grid>
      <Grid item>
        <TextInput
          inputId="Email address"
          label="Email address"
          placeholder=""
          error={errors.email}
          name="email"
          ref={register}
          data-testid="email"
        />
      </Grid>
      <Grid item>
        <TextInput
          inputId="total-amount"
          label="Your contribution amount"
          placeholder={`${formattedSuggestedAmount.substring(1)}`}
          startIcon={Dollar}
          iconBackground={true}
          name="dollar"
          error={errors.dollar}
          type="number"
          ref={register}
          data-testid="amount"
          inputProps={{ role: 'numberInput', inputMode: 'decimal' }}
        />
      </Grid>

      <Grid item>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={!isValid(errors) && isSubmitted}
          fullWidth
          customcolor="green"
        >
          {!defaultValues?.dollar ? 'Continue to payment' : 'Update and continue'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default observer(ContributionForm);
