import {
  BatchDetails,
  InitiatorCardViewDTO,
  Maybe,
  NoteDTO,
  RecipientDTO,
  UserContextDTO,
} from '@illume/shared';

import { isGift } from 'types/guard';
import { spellNames } from 'utils/string';

import { ExternalGift } from '../external-gift/externalGift';
import Gift, { GiftError } from '../gift/gift';
import { GifValueObject, PhotoValueObject, VideoValueObject } from './valueObjects';

export class Note {
  static fromDto(dto: NoteDTO) {
    return new Note(
      dto.signature,
      dto.viewCode,
      dto.redacted,
      dto.gifs.map((gifDto) => new GifValueObject(gifDto.giphyCode)),
      dto.photos.map((dto) => new PhotoValueObject(dto.url || dto.deliveryUrl)),
      dto.videos.map((dto) => new VideoValueObject(dto.url || dto.deliveryUrl)),
      dto.length,
      dto.theme,
      dto.message,
      dto.palette,
    );
  }
  private constructor(
    public signature: string,
    public viewCode: string,
    public redacted: boolean,
    public gifs: GifValueObject[],
    public photos: PhotoValueObject[],
    public videos: VideoValueObject[],
    public length: number,
    public theme: string,
    public message: string,
    public palette: string,
  ) {}
}
class Recipient {
  static fromDto(dto: RecipientDTO) {
    return new Recipient(dto.id, dto.name, dto.contact);
  }
  private constructor(public id: string, public name: string, public contact: string) {}
}

class Deadline {
  readonly type = 'deadline';
  public value: Date;
  constructor(value: string | Date) {
    this.value = new Date(value);
  }
}

class ScheduledTimestamp {
  readonly type = 'scheduled_timestamp';
  public value: Date;
  constructor(value: string | Date) {
    this.value = new Date(value);
  }
}

type TimeLimit = Deadline | ScheduledTimestamp;

type UserContext = UserContextDTO;

export class InitiatorCard {
  static fromDto(dto: InitiatorCardViewDTO) {
    const gift = isGift(dto.giftV2)
      ? Gift.fromDto(dto.giftV2)
      : dto.giftV2?.errType
      ? new GiftError(dto.giftV2.errType, dto.giftV2.errMsg)
      : undefined;
    const externalGift = dto.externalGift && ExternalGift.fromDto(dto.externalGift);

    return new InitiatorCard(
      dto.initiatorName,
      externalGift,
      gift,
      dto.contributorCount,
      dto.inviteList,
      dto.contributorList,
      dto.notes.map(Note.fromDto),
      dto.viewCode,
      dto.recipientData,
      dto.deadline ? new Deadline(dto.deadline) : new ScheduledTimestamp(dto.scheduledTimestamp!),
      new Date(dto.createdAt),
      dto.contributerLink,
      dto.userContext,
      dto.batch,
      dto.introMessage,
      dto.recipientMessage,
    );
  }
  private constructor(
    public initiatorName: string,
    public externalGift: Maybe<ExternalGift>,
    public giftV2OrError: Maybe<Gift | GiftError>,
    public contributorCount: number,
    public inviteList: string[],
    public contributorList: Maybe<string[]>,
    public notes: Note[],
    public viewCode: string,
    public recipientData: Recipient[],
    public timeLimit: TimeLimit,
    public createdAt: Date,
    public contributerLink: string,
    public userContext: UserContext,
    public batch: Maybe<BatchDetails>,
    public contributorIntroMessage: Maybe<string>,
    public recipientMessage: Maybe<string>,
  ) {}

  get hasEitherGift() {
    return !!this.externalGift || !!this.giftV2OrError;
  }

  get eitherGiftMetGoals() {
    const externalGiftMetGoals = this.externalGift?.giftMetGoals || false;
    const shopifyGiftMetGoals =
      this.giftV2OrError?.type === 'success' ? this.giftV2OrError.giftMetGoals : false;
    return shopifyGiftMetGoals || externalGiftMetGoals;
  }

  get spelledRecipientNames() {
    return spellNames(this.recipientData);
  }

  get giftV2() {
    return isGift(this.giftV2OrError) ? (this.giftV2OrError as Gift) : undefined;
  }
}
