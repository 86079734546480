import { Slide, useScrollTrigger } from '@material-ui/core';

const HideOnScroll: React.FC<any> = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
