import { ObservablePromise } from '@thezano/mobx-observable-promise';
import get from 'lodash/get';
import { makeAutoObservable } from 'mobx';

import { PRIMARY_THEME } from 'constants/design';
import { generatePaletteCodeFromTheme } from 'constants/design/cardTheme';
import ThemepacksStore from 'contexts/card/store';
import { INoteService } from 'domain/interfaces/INoteService';
import { IllumeGifDTO, PaletteDTO, PhotoDTO, VideoDTO } from 'types';
import { checkFF } from 'utils/featureFlag';

import ContributorStore from './Contributor.store';

type NoteFormData = {
  message?: string;
  signature?: string;
  isPublic?: boolean;
  viewCode?: string;
  anonymous?: boolean;
  photos?: PhotoDTO[];
  videos?: VideoDTO[];
  gifs?: IllumeGifDTO[];
  theme?: string;
  palette?: PaletteDTO;
};
export default class AddNoteStore {
  noteData?: NoteFormData = {};

  get themepack() {
    return this.contributorStore.themepack;
  }

  updateNote = (payload: Partial<NoteFormData>) => {
    return (this.noteData = {
      ...this.noteData,
      ...payload,
    });
  };

  get fallbackPalette() {
    if (!this.contributorStore.card) {
      return undefined;
    }
    const themepack = this.themepackStore.getThemepack(this.contributorStore.card.themepackCode);
    const fallbackPalette = themepack?.palettes[0];
    return fallbackPalette;
  }

  private _syncNote = async () => {
    if (!this.noteData || !this.contributorStore.card) {
      throw new Error('configuration failed');
    }

    const {
      signature,
      message,
      palette,
      isPublic,
      photos,
      videos,
      gifs,
      theme = PRIMARY_THEME,
    } = this.noteData;

    const { inviteCode } = this.contributorStore.card;

    const photoUuids = photos?.map((photo) => photo.uuid || photo.photoCode);
    const videoCodes = videos?.map((video) => video.videoCode);
    const gifCodes = gifs?.map((gif) => gif.gifCode);

    let payload = {
      sentInviteCode: inviteCode, // not sure what it is
      signature,
      theme,
      palette: checkFF('NEW_CARD_THEMING')
        ? palette?.paletteCode || this.fallbackPalette?.paletteCode
        : generatePaletteCodeFromTheme(theme),
      photos: photoUuids,
      videos: videoCodes,
      gifs: gifCodes,
      isPublic,
    };

    if (!this.noteData.viewCode) {
      if (!message) {
        throw new Error('message must be provided');
      }
      const createNotePayload = {
        message,
        ...payload,
      };
      const { viewCode, ...rest } = await this.noteService.createNote(
        inviteCode,
        createNotePayload,
      );
      const code = get(rest, 'code');
      this.updateNote({ viewCode: code || viewCode });
    } else {
      await this.noteService.updateNote(this.noteData.viewCode, payload);
    }
  };

  syncNoteTask = new ObservablePromise(this._syncNote);

  syncNote = () => {
    return this.syncNoteTask
      .execute()
      .catch()
      .then(() => this.contributorStore.refetchCard())
      .then(() => this.contributorStore.pushToNext());
  };

  constructor(
    private contributorStore: ContributorStore,
    private themepackStore: ThemepacksStore,
    private noteService: INoteService,
  ) {
    makeAutoObservable(this);
  }
}
