import { Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import { useConfettiCannon } from 'hooks';
import { showNames } from 'utils/string';

import { cardDetailsStyles } from '../card-details-v2-pending/CardDetails.style';

export const CardSendSuccess = ({ recipientData: propRecipientNames = '' }) => {
  const classes = cardDetailsStyles();
  const history = useHistory();
  const location = useLocation();
  // TODO: should probably return a modal warning and redirect. showing "unknown" when (re)loading this page doesn't make a lot of sense.
  // An alternative is we can put the name in the URL query so it's there on a reload.

  const recipientData = location.state.recipientData
    ? JSON.parse(location.state.recipientData)
    : propRecipientNames
    ? propRecipientNames
    : ['recipients'];

  const handleBackToMyCards = () => {
    history.back();
  };

  useConfettiCannon();

  // if (!recipientNames) {
  //   return <Redirect to={backUrl} />;
  // }

  return (
    <IllumeLayout
      background={colors.primary}
      contentProps={{
        direction: 'column',
        alignItems: 'center',
      }}
      hideHamburger
    >
      <Grid container item className={classes.cardSent} direction="column">
        <Grid item container justifyContent="center" spacing={2} className={classes.textContainer}>
          <Grid item>
            <Text
              align="center"
              fontWeight={900}
              color={colors.contrastText}
              fontSize={{ mobile: rem[2000], desktop: rem[3000] }}
            >
              {`You're awesome.`}
            </Text>
          </Grid>
          <Grid item>
            <Text align="center" fontWeight={500} color={colors.contrastText} fontSize={rem[1125]}>
              You sent {showNames(recipientData)} their card. We’ll notify you when they open the
              link and if they send a reply.
            </Text>
          </Grid>
        </Grid>
        <Grid item>
          <Button fullWidth customcolor="primaryShaded" onClick={handleBackToMyCards}>
            <Text color={colors.contrastText} fontSize={{ desktop: '16px', mobile: '14px' }}>
              go back
            </Text>
          </Button>
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};
