import { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { AnimatePresence } from 'framer-motion';
import { identity } from 'lodash';
import { makeAutoObservable, when } from 'mobx';
import { observer } from 'mobx-react';

import { GiftIntroModal } from 'components/illume/gift-intro-modal';
import { GroupGiftModal } from 'components/illume/group-gift-modal';
import { GroupGiftModalFormValues } from 'components/illume/group-gift-modal/GroupGiftModal';
import { GroupGiftModalDTOMapper } from 'components/illume/group-gift-modal/GroupGiftModalDTOMapper';
import { RectangularBoxWithPlusButton } from 'components/illume/new-card';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { rem, colors } from 'constants/design';
import { CONTRIBUTOR_ADD_NOTE_URL } from 'constants/strings';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import StripeStore, { IProduct } from 'contexts/store/StripeStore.store';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { INoteService } from 'domain/interfaces/INoteService';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { useValue } from 'hooks/useValue';
import { Maybe, NoteDTO } from 'types';
import { isGift } from 'types/guard';
import { wait } from 'utils';
import { IllumeMoneyFactory } from 'utils/currency';
import logger from 'utils/logger';
import { showNames } from 'utils/string';

import { NoteThubmnail } from '../card-details-v2/note-thumbnail';
import ContributeToGiftCard from '../initiator/components/ContributeToGiftCard';
import ContributorStore from './Contributor.store';
import useStyles from './Welcome.styles';

type ModalTypes = 'preview' | 'giftIntro' | 'groupGift' | 'anygift' | null;
class WelcomeViewStore {
  constructor(
    private contributorStore: ContributorStore,
    private stripeStore: StripeStore,
    private noteService: INoteService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    when(
      () => !!this.introMessage,
      () => {
        wait(1000).then(() => this.setModalShown('giftIntro'));
      },
    );
  }

  modalShown: ModalTypes = null;
  setModalShown(type: ModalTypes) {
    this.modalShown = type;
  }

  get hasPaid() {
    if (this.giftV2?.yourContribution) {
      return this.giftV2.yourContribution > 0 ? true : false;
    } else return false;
  }

  get giftV2Error() {
    return !isGift(this._card?.giftV2) ? this._card?.giftV2 : undefined;
  }

  get giftV2() {
    return isGift(this._card?.giftV2) ? this._card?.giftV2 : undefined;
  }

  get externalGift() {
    return this._card?.externalGift && ExternalGift.fromDto(this._card.externalGift);
  }

  private get _card() {
    return this.contributorStore.card;
  }
  get notesCount() {
    return this.notes.length;
  }

  get deadlineStr() {
    return new IllumeDate(
      this._card?.deadline || this._card?.scheduledTimestamp,
    ).toAbbreviatedReadeableString();
  }
  get hasGift() {
    return !!this._card?.giftV2;
  }
  get introMessage() {
    return this._card?.introMessage;
  }
  get notes() {
    return this._card?.notes || [];
  }
  get recipientData() {
    return this._card?.recipientData || [];
  }

  get spelledRecipientNames() {
    return showNames(this.recipientData);
  }
  get initiatorName() {
    return this._card?.initiatorName;
  }

  get userNoteCodes() {
    return this._card?.userContext.noteCodes || [];
  }

  get email() {
    return this.contributorStore.formStore.userData.email;
  }

  get occasionName() {
    return this._card?.occasionName;
  }
  selectedNote?: NoteDTO;
  setSelectedNote(note: NoteDTO) {
    this.selectedNote = note;
  }

  deleteNote = async (code: string) => {
    await this.noteService.deleteNote(code);
    return this.contributorStore.refetchCard();
  };

  deleteNoteObservable = new ObservablePromise(this.deleteNote);

  get noteSender() {
    if (!this._card) return '';
    return this._card.initiatorName === undefined || this._card.initiatorName === '(anonymous)'
      ? 'Someone'
      : this._card.initiatorName;
  }

  contributeToGroupGift({ amount, email }: GroupGiftModalFormValues) {
    const gift: Maybe<IProduct> = this.giftV2
      ? { type: 'shopify', id: this.giftV2.id, product: this.giftV2.product }
      : this.externalGift
      ? { type: 'external', id: this.externalGift.id, product: this.externalGift.product }
      : null;

    if (!gift) {
      return console.error('no gift in card');
    }
    this.contributorStore.formStore.setDollar(amount);
    this.contributorStore.formStore.setUserData({ email });
    this.stripeStore.setGoogleOrApplePaySuccessCallback(
      this.contributorStore.handleSuccessfulPayment,
    );
    return wait(300)
      .then(() => {
        if (!this.contributorStore.formStore.cents) {
          throw new Error('no amount set');
        }

        return this.stripeStore.initStripePaymentButtonForGift(
          gift,
          this.contributorStore.formStore.cents,
          email,
        );
      })
      .then(() => {
        logger.log('pushing to next..');
        return this.contributorStore.pushToNext();
      });
  }

  get alreadyHaveNotes() {
    return this.userNoteCodes.length > 0;
  }
}

const Welcome: React.FC<{ store: ContributorStore }> = ({ store }) => {
  const { stripeStore } = useStores();
  const { noteService } = useServices();
  const ui = useValue(new WelcomeViewStore(store, stripeStore, noteService));
  const desktop = useIsDesktop();
  const classes = useStyles({ notesCount: ui.notesCount, desktop: desktop });
  const history = useIllumeHistory();
  const gift = ui.giftV2;
  const goCreateCard = () => {
    history.pushWithSearch(CONTRIBUTOR_ADD_NOTE_URL);
  };

  const goalHasMet = gift ? gift.financial.current >= gift.financial.goal : false;

  useEffect(() => {
    store.resetAddNoteFlow();
  }, [store]);

  return (
    <IllumeLayout contentProps={{ style: { flex: 1 }, justifyContent: 'center' }}>
      <Grid container direction="column" alignItems="center" className={classes.container}>
        <Grid item className={classes.header}>
          <Text
            align="center"
            fontSize={{ mobile: rem[1500], desktop: rem[2250] }}
            fontWeight={900}
          >
            {(ui.hasGift
              ? `add to ${ui.spelledRecipientNames}'s group gift`
              : `${ui.initiatorName} invited you to add a note to ${ui.spelledRecipientNames}'s card`
            ).toUpperCase()}
          </Text>
        </Grid>
        <Grid item className={classes.ctaText}>
          {ui.hasGift ? (
            <Text align="center" fontWeight={300}>
              {ui.noteSender} invited you to contribute to {showNames(ui.recipientData)}'s group
              card and gift. The deadline for this card is <strong>{ui.deadlineStr}</strong>.
            </Text>
          ) : (
            <Text align="center" fontWeight={300}>
              Only {ui.spelledRecipientNames} will be able to see what you write. Tap below to add
              your note. Add your note by {ui.deadlineStr}.
            </Text>
          )}
        </Grid>

        <div className={classes.cardList}>
          <div className={gift ? classes.containerWithGift : classes.containerWithoutGift}>
            {gift && (
              <Grid item className={classes.topContent} container>
                {gift.yourContribution > 0 ? (
                  <ContributeToGiftCard
                    actionVariant="secondary"
                    title={gift.product.name}
                    current={gift.financial.current}
                    image={gift.product.variant.img}
                    yourContribution={gift.yourContribution}
                    max={gift.financial.goal}
                    onAction={() => ui.setModalShown('groupGift')}
                    actionText={'View group gift details'}
                  />
                ) : (
                  <ContributeToGiftCard
                    actionVariant="primary"
                    title={gift.product.name}
                    current={gift.financial.current}
                    image={gift.product.variant.img}
                    yourContribution={gift.yourContribution}
                    max={gift.financial.goal}
                    onAction={() => ui.setModalShown('groupGift')}
                    actionText={goalHasMet ? 'Goal fulfilled' : 'contribute to group gift'}
                    disabled={goalHasMet}
                  />
                )}
              </Grid>
            )}

            {store.externalGift && (
              <Grid item className={classes.topContent} container>
                {store.externalGift.yourContribution &&
                store.externalGift.yourContribution.greaterThan(
                  IllumeMoneyFactory({ amount: 0 }),
                ) ? (
                  <ContributeToGiftCard
                    actionVariant="secondary"
                    title={store.externalGift.title}
                    current={store.externalGift.financial.current.getAmount()}
                    image={store.externalGift.image}
                    yourContribution={store.externalGift.yourContribution.getAmount()}
                    max={store.externalGift.goal.getAmount()}
                    onAction={() => ui.setModalShown('anygift')}
                    actionText={'View group gift details'}
                  />
                ) : (
                  <ContributeToGiftCard
                    actionVariant="primary"
                    title={store.externalGift.title}
                    current={store.externalGift.financial.current.getAmount()}
                    image={store.externalGift.image}
                    yourContribution={store.externalGift.yourContribution?.getAmount()}
                    max={store.externalGift.goal.getAmount()}
                    onAction={() => ui.setModalShown('anygift')}
                    actionText={
                      store.externalGift.giftMetGoals
                        ? 'Goal fulfilled'
                        : 'contribute to group gift'
                    }
                    disabled={store.externalGift.giftMetGoals}
                  />
                )}
              </Grid>
            )}
            {ui.giftV2Error && (
              <Alert severity="warning">
                <Text>
                  we cannot find your product, this probably our mistake or the product have been
                  removed
                </Text>
              </Alert>
            )}
          </div>
        </div>

        <Grid container spacing={2} item alignItems="center" className={classes.cardListing}>
          <AnimatePresence>
            <div className={classes.cardWrapper}>
              {!ui.alreadyHaveNotes && (
                <RectangularBoxWithPlusButton
                  text="add note"
                  onClick={goCreateCard}
                  size="small"
                  fixedSize={undefined}
                  background={undefined}
                  blobColor={undefined}
                  plusColor={undefined}
                />
              )}

              {ui.notes.map((note, index) => {
                const isMyCard = ui.userNoteCodes.includes(note.viewCode);
                const editable = isMyCard;
                const previewable = !note.redacted && !editable;
                const deletable = isMyCard;
                return (
                  <NoteThubmnail
                    key={index}
                    cardCode={store.cardCode}
                    note={NoteThubmnail.noteFromDTO(note)}
                    previewable={previewable}
                    editable={editable}
                    // the trashbin icon
                    hideBadge={!deletable}
                    onViewNote={undefined}
                    recipientName={ui.spelledRecipientNames}
                    deleteService={() =>
                      ui.deleteNoteObservable.execute(note.viewCode).then(identity)
                    }
                    deleteModalLoading={ui.deleteNoteObservable.isExecuting}
                  />
                );
              })}
            </div>
          </AnimatePresence>
        </Grid>
        <Grid container justifyContent="center" className={classes.subText}>
          <Text fontSize={rem[875]} italic color={colors.labelText} fontWeight={400}>
            patent pending
          </Text>
        </Grid>
      </Grid>

      {ui.introMessage && (
        <GiftIntroModal
          onContributeToGift={() => ui.setModalShown(null)}
          showModal={ui.modalShown === 'giftIntro'}
          hideModal={() => ui.setModalShown(null)}
          initiatorName={ui.initiatorName}
          introMessage={ui.introMessage}
          recipientData={ui.recipientData}
        />
      )}
      {gift && (
        <GroupGiftModal
          removable={false}
          showModal={ui.modalShown === 'groupGift'}
          hideModal={() => ui.setModalShown(null)}
          gift={GroupGiftModalDTOMapper.fromGiftV2Dto(gift)}
          onContributeToGroupGift={ui.contributeToGroupGift}
          defaultValues={{
            email: ui.email,
          }}
        />
      )}

      {store.externalGift && (
        <GroupGiftModal
          removable={false}
          showModal={ui.modalShown === 'anygift'}
          hideModal={() => ui.setModalShown(null)}
          gift={GroupGiftModalDTOMapper.fromExternalGift(store.externalGift)}
          onContributeToGroupGift={ui.contributeToGroupGift}
          defaultValues={{
            email: ui.email,
          }}
        />
      )}
    </IllumeLayout>
  );
};

export default observer(Welcome);
