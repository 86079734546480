import { css } from '@emotion/css';

export function getRippleContext(): string | undefined {
  return css`
    position: relative;
    overflow: hidden;
    span.ripple {
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 600ms linear;
      background-color: rgba(255, 255, 255, 0.4);
    }

    @keyframes ripple {
      to {
        transform: scale(4);
        opacity: 0;
      }
    }
  `;
}

export * from './TopNavigation';
