const Gift = ({ color, size = 24, fillOpacity = 0.6 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.32 25.6H2.25599C1.94799 25.6 1.69599 25.348 1.69599 25.04V12.72H10.32V25.6ZM13.68 12.72V25.6H21.744C22.052 25.6 22.304 25.348 22.304 25.04V12.72H13.68ZM23.312 6.72797V11.236C23.312 11.544 23.06 11.796 22.752 11.796H13.68V6.27997H13.456H10.488H10.32V11.796H1.24799C0.939988 11.796 0.687988 11.544 0.687988 11.236V6.72797C0.687988 6.41997 0.939988 6.16797 1.24799 6.16797H7.29599C6.09199 6.02797 4.85999 5.66397 4.18799 4.79597C3.85199 4.34797 3.51599 3.59197 3.82399 2.44397C4.04799 1.63197 4.55199 1.01597 5.30799 0.679965C6.70799 0.0639655 8.55599 0.511965 9.84399 1.15597C11.02 1.74397 11.804 2.63997 12.336 3.45197C12.84 2.83597 13.512 2.10797 14.352 1.49197C15.5 0.595965 17.264 0.287965 18.608 0.735965C19.616 1.07197 20.316 1.77197 20.568 2.75197C20.876 3.84397 20.54 4.57197 20.232 4.99197C19.644 5.74797 18.636 6.05597 17.544 6.19597H22.78C23.06 6.19597 23.312 6.44797 23.312 6.72797ZM11.048 4.62797C10.656 3.95597 10.04 3.14397 9.08799 2.66797C8.33199 2.27597 7.49199 2.07997 6.81999 2.07997C6.48399 2.07997 6.20399 2.13597 5.97999 2.21997C5.69999 2.35997 5.50399 2.55597 5.41999 2.89197C5.27999 3.42397 5.39199 3.64797 5.50399 3.78797C5.72799 4.09597 6.51199 4.59997 9.08799 4.59997H11.048V4.62797ZM13.512 4.62797H15.248C16.34 4.62797 18.356 4.62797 18.86 3.98397C18.916 3.92797 19.056 3.73197 18.916 3.19997C18.804 2.77997 18.524 2.49997 18.048 2.33197C17.236 2.05197 16.06 2.27597 15.332 2.83597C14.576 3.39597 13.988 4.06797 13.512 4.62797Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default Gift;
