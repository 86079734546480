import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const desktop = theme.breakpoints.up('md');
  return createStyles({
    container: {
      maxWidth: 300 + 130 + 391,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      rowGap: 36,
      paddingTop: 200,
      columnGap: 24,
      [theme.breakpoints.up('md')]: {
        paddingTop: 100,
        justifyContent: 'space-between',
        rowGap: 0,
      },
      width: '100%',
      position: 'relative',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    leftContent: {
      maxWidth: 300,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 36,
    },
    giftThumbnailContainer: {
      justifyContent: 'center',
      '& > .title-and-sub': {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
    rightContent: {
      maxWidth: 391,
      '& > .title-and-sub': {
        textAlign: 'left',
        display: 'none',
        flexDirection: 'column',
        rowGap: 8,
        marginBottom: 37,
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      '& > .input-and-cta': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 13,
      },
    },
    tags: {
      display: 'flex',
      columnGap: 8,
      rowGap: 8,
      flexWrap: 'wrap',
    },
    thumbnail: {
      minWidth: 250,
      maxWidth: 250,
      width: '100%',
    },
    leftArrow: {
      display: 'none',
      [desktop]: {
        display: 'block',
        position: 'absolute',
        left: -60,
        top: '50%',
      },
    },
    infoContainer: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 16,
      },
    },
    image: {},
  });
});

export default useStyles;
