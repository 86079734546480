interface AvatarPictureProps extends React.HTMLAttributes<HTMLDivElement> {
  size: number;
  imgUrl: string;
  editable?: boolean;
  shadow?: boolean;
  flipVertically?: boolean;
  identifier: string;
}

export const Avatar = ({
  size,
  imgUrl,
  flipVertically = false,
  identifier = 'default',
  shadow,
  ...props
}: AvatarPictureProps) => {
  const id = `user-space-avatar-${identifier}`;

  const scaleSize = size > 100 ? 1.03 : 1.05;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: size,
        height: size,
      }}
      {...props}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 229 224"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          overflow: 'visible',
          position: 'absolute',
        }}
      >
        <defs>
          <clipPath id={id} clipPathUnits="userSpaceOnUse">
            <path
              // flip vertically
              transform={flipVertically ? 'scale(1, -1) translate(0, -224)' : undefined}
              d="M0.0468707 135.572C-0.942155 155.605 13.788 176.939 37.8824 193.134C63.597 210.419 92.1104 217.404 122.833 215.411C140.552 214.278 157.239 209.454 171.254 197.853C180.365 190.323 187.415 181.072 193.496 171.003C204.228 153.256 213.214 134.754 218.285 114.511C225.313 86.4858 218.453 61.7538 200.525 39.7488C181.733 16.6949 156.755 4.98972 127.652 1.12993C107.304 -1.57613 87.481 0.563538 68.2265 7.69576C44.7214 16.4013 27.8448 32.4907 17.218 54.8523C6.61232 77.1301 0.825465 100.687 0.0468707 135.572Z"
              fill="#FEB81C"
            />
          </clipPath>
        </defs>
        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          xlinkHref={imgUrl}
          clipPath={`url(#${id})`}
        />
      </svg>
      {shadow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 229 224"
          // doesn't work in safari
          // transform={false ? `scale(${scaleSize}, -${scaleSize})` : `scale(${scaleSize})`}
          style={{
            position: 'absolute',
            zIndex: -1,
            transform: flipVertically
              ? `scale(${scaleSize}, -${scaleSize})`
              : `scale(${scaleSize})`,
          }}
        >
          <path
            d="M0.0468707 135.572C-0.942155 155.605 13.788 176.939 37.8824 193.134C63.597 210.419 92.1104 217.404 122.833 215.411C140.552 214.278 157.239 209.454 171.254 197.853C180.365 190.323 187.415 181.072 193.496 171.003C204.228 153.256 213.214 134.754 218.285 114.511C225.313 86.4858 218.453 61.7538 200.525 39.7488C181.733 16.6949 156.755 4.98972 127.652 1.12993C107.304 -1.57613 87.481 0.563538 68.2265 7.69576C44.7214 16.4013 27.8448 32.4907 17.218 54.8523C6.61232 77.1301 0.825465 100.687 0.0468707 135.572Z"
            fill="#fff"
          />
        </svg>
      )}
    </div>
  );
};
