import { colors } from 'constants/design';

export const LightBulb = ({
  color = colors.contrastText,
  background = colors.gray60,
  size = 32,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#lightbulb)">
      <path
        d="M0.00696311 11.8918C-0.139967 8.9202 2.04836 5.75568 5.62784 3.3535C9.44803 0.789513 13.684 -0.24666 18.2482 0.0489453C20.8805 0.216973 23.3595 0.932648 25.4416 2.65338C26.7952 3.77046 27.8425 5.14268 28.746 6.63627C30.3403 9.26871 31.6752 12.0132 32.4286 15.0159C33.4727 19.173 32.4536 22.8416 29.7901 26.1057C26.9984 29.5254 23.2876 31.2617 18.9641 31.8342C15.9411 32.2356 12.9963 31.9183 10.1358 30.8603C6.64385 29.569 4.13665 27.1824 2.55793 23.8654C0.982332 20.5608 0.122632 17.0665 0.00696311 11.8918Z"
        fill={background}
      />
      <path
        d="M15.5 10.0098C12.7341 10.0098 11.6242 12.1474 11.6242 13.6507C11.6242 15.8466 13.3441 17.4674 13.7383 19.1708H17.2617C17.6561 17.4674 19.3758 15.8466 19.3758 13.6507C19.3758 12.1474 18.2661 10.0098 15.5 10.0098Z"
        fill={color}
      />
      <path
        d="M13.7383 20.8174C13.7383 21.3275 13.9389 21.5281 14.4491 21.5281H14.5126H14.5215L14.5912 21.7374C14.6973 22.0563 14.8271 22.15 15.1632 22.15H15.8369C16.1732 22.15 16.3027 22.0563 16.409 21.7374L16.4785 21.5281H16.4874H16.5511C17.0614 21.5281 17.2617 21.3275 17.2617 20.8174V19.7547H13.7383V20.8174Z"
        fill={color}
      />
      <path
        d="M15.5 9.10506C15.7142 9.10506 15.8522 8.96668 15.8522 8.75265V7.20225C15.8522 6.98822 15.7142 6.85001 15.5 6.85001C15.286 6.85001 15.1478 6.98822 15.1478 7.20242V8.75282C15.1478 8.96685 15.286 9.10506 15.5 9.10506Z"
        fill={color}
      />
      <path
        d="M18.6132 10.0336C18.7822 10.1652 18.9762 10.1414 19.1079 9.97291L20.0623 8.75112C20.1942 8.58248 20.1704 8.38817 20.0018 8.25659C19.8331 8.12467 19.6388 8.14847 19.5071 8.31728L18.5529 9.5389C18.4206 9.70771 18.4447 9.90185 18.6132 10.0336Z"
        fill={color}
      />
      <path
        d="M22.4967 12.1834C22.4447 11.9757 22.2772 11.8749 22.0695 11.9267L20.565 12.3019C20.3572 12.3537 20.2566 12.5212 20.3083 12.7289C20.3601 12.9368 20.5277 13.0373 20.7355 12.9856L22.2398 12.6106C22.4477 12.5588 22.5485 12.3912 22.4967 12.1834Z"
        fill={color}
      />
      <path
        d="M21.7487 16.2816L20.3324 15.6511C20.1369 15.564 19.9542 15.6342 19.8673 15.8299C19.7803 16.0256 19.8503 16.208 20.046 16.2952L21.4621 16.9254C21.6576 17.0128 21.8403 16.9426 21.9272 16.7469C22.0144 16.5516 21.9442 16.369 21.7487 16.2816Z"
        fill={color}
      />
      <path
        d="M11.8924 9.9729C12.0242 10.1415 12.2183 10.1652 12.387 10.0336C12.5556 9.90167 12.5794 9.7077 12.4476 9.53889L11.4931 8.31727C11.3613 8.14863 11.1672 8.12483 10.9986 8.25658C10.8299 8.38833 10.8063 8.58247 10.938 8.75111L11.8924 9.9729Z"
        fill={color}
      />
      <path
        d="M10.6915 12.7291C10.7434 12.5212 10.6426 12.3538 10.4348 12.3019L8.93052 11.9267C8.72278 11.8752 8.55516 11.9758 8.50331 12.1836C8.4518 12.3913 8.55227 12.5588 8.76018 12.6106L10.2643 12.9856C10.4721 13.0373 10.6397 12.9369 10.6915 12.7291Z"
        fill={color}
      />
      <path
        d="M10.6678 15.6511L9.25149 16.2819C9.05582 16.3688 8.98578 16.5516 9.07299 16.7469C9.15986 16.9426 9.34261 17.0126 9.53811 16.9257L10.9544 16.2949C11.15 16.208 11.2201 16.0252 11.1329 15.8299C11.0458 15.6344 10.8634 15.5644 10.6678 15.6511Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="lightbulb">
        <rect width="33" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
