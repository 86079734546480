import { createContext, useCallback, useContext, useState } from 'react';

import { noop } from 'lodash';

import { Modal } from 'components/illume/modal';
import { Text } from 'components/illume/text';

export type PromptConfig = {
  onOk?: () => Promise<any> | any;
  onCancel?: () => any;
  okText?: string;
  cancelText?: string;
  okLoading: boolean;
};

const PromptContext = createContext({
  prompt: undefined as unknown as (
    config: Omit<PromptConfig, 'okLoading'> & { title: string; subtitle: string },
  ) => any,
  cleanup: () => {},
});

export const usePrompContext = () => useContext(PromptContext);

export const PromptProvider: React.FC<any> = withPrompt(({ children, prompt, cleanup }) => {
  return <PromptContext.Provider value={{ prompt, cleanup }}>{children}</PromptContext.Provider>;
});

export function withPrompt<T>(C: React.FC<T>) {
  const initialState = {
    show: false,
    texts: {
      title: '',
      subtitle: '',
    },
  };
  return (props: T) => {
    const [show, setShow] = useState(initialState.show);
    const [texts, setTexts] = useState(initialState.texts);

    const [config, setPromptConfig] = useState<PromptConfig>({
      onOk: () => Promise.resolve(null),
      onCancel: noop,
      okLoading: false,
      cancelText: 'cancel',
      okText: 'ok',
    });

    const prompt = useCallback(
      ({
        title = '',
        subtitle = '',
        onOk,
        okText,
        onCancel = undefined,
        cancelText = undefined,
      }) => {
        setTexts({ title, subtitle });
        setPromptConfig({
          okLoading: false,
          onOk: async () => {
            setPromptConfig((prev) => ({ ...prev, okLoading: true }));
            await onOk();
            setShow(false);
          },
          onCancel: onCancel
            ? async () => {
                await onCancel();
                setShow(false);
              }
            : undefined,
          okText,
          cancelText,
        });
        setShow(true);
      },
      [],
    );
    const cleanup = useCallback(() => {
      setTexts(initialState.texts);
      setShow(initialState.show);
    }, []);
    return (
      <>
        <C {...props} prompt={prompt} cleanup={cleanup} />
        <Modal zIndex={3000} maxWidth={400} show={show} title={texts.title} promptConfig={config}>
          <Text fontSize={16}>
            <Text align="center" fontWeight={300}>
              {texts.subtitle}
            </Text>
          </Text>
        </Modal>
      </>
    );
  };
}
