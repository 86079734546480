import * as React from 'react';
import { FunctionComponent } from 'react';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { observer } from 'mobx-react';

import { Button } from 'components/illume/buttons';
import { Modal } from 'components/illume/modal';
import { Text } from 'components/illume/text';
import { DatePicker } from 'components/illume/text-inputs';
import { colors } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

import useStyles from './BirthdayModal.style';

interface BirthdayModalProps {
  show: () => any;
  hide: () => any;
  visible: boolean;
  action: {
    loading: boolean;
    onAction: (deadline: Date) => any;
  };
}

const BirthdayModal: FunctionComponent<BirthdayModalProps> = ({ visible, hide, action }) => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const [birthdayDate, setBirthdayDate] = React.useState<MaterialUiPickersDate>(new Date());

  const handleChange = (value: MaterialUiPickersDate) => {
    setBirthdayDate(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (birthdayDate) {
      action.onAction(birthdayDate);
    }
  };

  return (
    <Modal
      title="add your birthday!"
      titleProps={{ fontSize: isDesktop ? 36 : 28, align: 'center' }}
      maxWidth={450}
      zIndex={1200}
      show={visible}
      onClose={() => hide()}
    >
      <Text fontSize={16} fontWeight={300} align="center">
        Enter your birthday below so the illume team can send you something special on your big day.
      </Text>
      <form onSubmit={handleSubmit}>
        <div className={classes.content}>
          <DatePicker
            onChange={handleChange}
            value={birthdayDate}
            placeholder="e.g. August 17th"
            format="MMMM do"
            icon={true}
            label="Birthday"
            name="birthday"
          />

          <Button fullWidth type="submit" loading={action.loading}>
            <Text
              color={colors.contrastText}
              fontSize={{ desktop: '16px', mobile: '14px' }}
              fontWeight={500}
            >
              save
            </Text>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(BirthdayModal);
