//@ts-check
import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

import { CountBadge } from '../count-badge';
import { navButtonStyles, navButtonSvgStyles, navButtonPathStyles } from './MenuToggle.styles';

const badgeStyles = makeStyles(() => ({
  anchorOriginTopRightRectangular: {
    top: '-7px',
    right: '-7px',
  },
}));

export const MenuToggle = ({ toggle = false, menuVisible = false, unread = 0 }) => {
  const badgeClasses = badgeStyles();
  const hideBadge = unread <= 0 || menuVisible === true;
  return (
    <button onClick={toggle} style={navButtonStyles}>
      <Badge
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // hidden={true} // doesn't work with the current badge content
        badgeContent={hideBadge ? null : <CountBadge count={unread} />}
        classes={badgeClasses}
      >
        <svg width="23" height="23" viewBox="0 0 23 23" style={navButtonSvgStyles}>
          <motion.path
            variants={{
              hidden: { d: 'M 2 2.5 L 20 2.5' },
              visible: { d: 'M 3 16.5 L 17 2.5' },
            }}
            style={navButtonPathStyles}
          />
          <motion.path
            d="M 2 9.423 L 20 9.423"
            variants={{
              hidden: { opacity: 1 },
              visible: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
            style={navButtonPathStyles}
          />
          <motion.path
            variants={{
              hidden: { d: 'M 2 16.346 L 20 16.346' },
              visible: { d: 'M 3 2.5 L 17 16.346' },
            }}
            style={navButtonPathStyles}
          />
        </svg>
      </Badge>
    </button>
  );
};
