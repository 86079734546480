import { css } from '@emotion/css';

import { ColorsV2 } from 'domain/theme/colorsV2';

export const thinOrangeScrollbar = css`
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${ColorsV2.primary};
    border-radius: 5px;
  }
`;

/**
 *
 */
export const thinGrayScrollbar = css`
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${ColorsV2.gray40};
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${ColorsV2.gray60};
  }
`;
