import { css, cx } from '@emotion/css';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

import { CardThumbnail as CardThumbnailEntity } from 'domain/entities/card-thumbnail/CardThumbnail';
import { Action } from 'types';

import {
  CardThumbnail,
  CreateNewCardThumbnail as CreateNewCardThumbnail,
  ICardThumbnail,
} from './CardThumbnail';

export const LBold = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const variants4 = {
  hidden: (i: number) => ({
    opacity: isMobile ? 1 : 0,
    y: 500,
    transition: {
      type: 'spring',
      damping: 14,
      stiffness: 90,
      delay: i * 0.1,
    },
  }),
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 14,
      stiffness: 90,
      delay: i * 0.1,
    },
  }),
};

const styles = css`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: left;
`;

export function CardList({
  className,
  data,
  newCardAction,
}: {
  className?: string;
  data: ICardThumbnail[];
  newCardAction?: Action;
}) {
  return (
    <div className={cx(styles, className)}>
      {newCardAction && <CreateNewCardThumbnail action={newCardAction} className={'new-card'} />}
      {data.map((e, i, l) => {
        // random element index
        const animationProps = (i: number) => ({
          variants: variants4,
          initial: 'hidden',
          animate: 'visible',
          exit: 'hidden',
          custom: i,
        });
        return (
          <CardThumbnail
            exlamation={e.alert}
            onContainerClick={e.onContainerClick}
            onViewDetails={e.onViewDetails}
            onPeopleIconClick={e.onPeopleIconClick}
            key={e.code}
            containerAs={motion.div}
            data={e}
            {...animationProps(i)}
          />
        );
      })}
    </div>
  );
}

CardList.fromPendingCardDomain = (
  domain: CardThumbnailEntity,
  onContainerClick: () => void,
  onViewDetails: () => void,
  onPeopleIconClick: () => void,
): ICardThumbnail => {
  return {
    code: domain.code,
    contributorsCount: domain.contributorsCount,
    date: domain.date,
    recipientName: domain.formattedRecipientsName,
    initiatorName: domain.formattedInitiatorName,
    type: domain.type,
    onContainerClick,
    onViewDetails,
    onPeopleIconClick,
    alert: domain.shouldAlert,
  };
};
