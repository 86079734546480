import { Box } from '@material-ui/core';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';

import { Bell, FourSquares, Person, MarketplaceIcon } from 'components/illume/icons';
import Text from 'components/illume/text/Text';
import { colors, spacing } from 'constants/design';
import {
  PROFILE_URL,
  NOTIFICATIONS_URL,
  MY_CARDS_URL,
  LOGIN_URL,
  INITIATOR_MARKETPLACE_URL,
  TEAMS_URL,
  // SUCCESFUL_CREATE_URL,
} from 'constants/strings';
import { useStores } from 'contexts/store';

import { CountBadge } from '../count-badge';
import ThreePeople from '../icons/ThreePeople';
import { menuItemContainerStyles } from './Navigation.styles';
import { menuItemContainerVariants } from './Navigation.variants';
import { NavigationItem } from './NavigationItem';

export const Navigation: React.FC<{ unread: number; menuVisible: boolean }> = observer(
  ({ unread, menuVisible }) => {
    const authenticated = useStores().authenticationStore.authenticated;

    return (
      <motion.ul
        variants={
          menuVisible ? menuItemContainerVariants.visible : menuItemContainerVariants.hidden
        }
        style={menuItemContainerStyles}
      >
        {menuVisible && (
          <>
            {' '}
            <NavigationItem>
              <FourSquares />
              <Text>
                <RouterLink to={MY_CARDS_URL}>my cards</RouterLink>
              </Text>
            </NavigationItem>
            <NavigationItem>
              <Box mr={1}>
                <CountBadge count={unread} />
              </Box>
              <Box>
                <Bell />
              </Box>
              <Text>
                <RouterLink to={NOTIFICATIONS_URL}>notifications</RouterLink>
              </Text>
            </NavigationItem>
            <NavigationItem>
              <>
                <Box>
                  <MarketplaceIcon />
                </Box>
                <Text>
                  <RouterLink to={INITIATOR_MARKETPLACE_URL}>marketplace</RouterLink>
                </Text>
              </>
            </NavigationItem>
            <NavigationItem>
              <>
                <Box mr={1}>
                  <ThreePeople className="img" color={colors.contrastText} size={22} />
                </Box>
                <Text>
                  <RouterLink to={TEAMS_URL}>teams</RouterLink>
                </Text>
              </>
            </NavigationItem>
            <NavigationItem>
              {authenticated ? (
                <>
                  <Person className="img" />
                  <Text>
                    <RouterLink to={PROFILE_URL}>profile</RouterLink>
                  </Text>
                </>
              ) : (
                <>
                  <Person className="img" size={25} hasBadge style={{ marginRight: spacing[2] }} />
                  <Text>
                    <RouterLink to={LOGIN_URL}>login</RouterLink>
                  </Text>
                </>
              )}
            </NavigationItem>
          </>
        )}
      </motion.ul>
    );
  },
);
