import { makeStyles } from '@material-ui/core/styles';

export const useModalStyles = makeStyles(() => ({
  root: {
    outline: 'none',
  },
  giphyLogo: {
    margin: 'auto',
  },
}));

export const useContainerStyles = makeStyles((theme) => {
  return {
    root: {
      outline: 'none',
      margin: `0 ${theme.spacing(2)}px`,
      [theme.breakpoints.up('md')]: {
        maxWidth: 1024,
        margin: '0 auto',
      },
    },
  };
});

export const usePaperStyles = makeStyles((theme) => ({
  root: {
    outline: 'none',
    padding: theme.spacing(4),
    position: 'relative',
    marginTop: theme.spacing(10),
    zIndex: theme.zIndex.modal,
    borderRadius: 16,
    maxHeight: '80vh',
    overflow: 'auto',
    background: 'rgba(1,1,1,0.9)',

    // scroll style
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      borderRadius: '5px',
      backgroundClip: 'padding-box',
    },
  },
}));
