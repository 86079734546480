import { Gif } from '@giphy/react-components';
import { Box } from '@material-ui/core';
import { useAsync } from 'react-async-hook';

import { colors } from 'constants/design';
import giphyService from 'infra/giphy/giphy';

import IllumeCircularProgress from '../circular-progress';
import { PoweredByGiphy } from '../icons/PoweredBy';
import { Text } from '../text';
import { gifDisplayStyles } from './GifDisplay.styles';

const GifRender = ({ giphyCode, size, hideBanner = false }) => {
  const classes = gifDisplayStyles();

  const {
    error,
    loading,
    result: giphyGifInstance,
  } = useAsync((code) => giphyService.gif(code).then(({ data }) => data), [giphyCode]);

  return (
    <div className={classes.root}>
      {loading && <IllumeCircularProgress color={colors.contrastText} size={64} />}
      {error && <Text color={colors.contrastText}>an error occured when fetching the gif.</Text>}
      {giphyGifInstance && (
        <div>
          <Gif
            hideAttribution
            width={size}
            gif={giphyGifInstance}
            style={hideBanner && { maxHeight: size, maxWidth: size }}
          />
          {!hideBanner && (
            <Box py={1}>
              <PoweredByGiphy style={{ maxWidth: 150 }} />
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

const GifDisplay = ({
  size = 300,
  giphyCode,
  color = colors.background,
  hideBanner = false,
  redacted = false,
}) => {
  const gif = <GifRender size={size} giphyCode={giphyCode} hideBanner={hideBanner} />;

  const redactedGif = (
    <svg
      width={size}
      height={size}
      viewBox="0 0 264 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0559903 95.6214C-1.12547 71.7269 16.4708 46.2811 45.2531 26.9653C75.9711 6.34843 110.032 -1.98338 146.733 0.393566C167.899 1.74467 187.833 7.49938 204.574 21.3357C215.459 30.318 223.88 41.352 231.145 53.3619C243.965 74.5292 254.698 96.5972 260.757 120.742C269.152 154.169 260.958 183.668 239.541 209.915C217.093 237.412 187.255 251.374 152.49 255.977C128.182 259.205 104.502 256.653 81.5014 248.146C53.4228 237.763 33.2626 218.572 20.5682 191.9C7.89888 165.328 0.986076 137.23 0.0559903 95.6214Z"
        fill={color}
      />
    </svg>
  );

  return redacted ? redactedGif : gif;
};

export default GifDisplay;
