import * as yup from 'yup';

import { emailRegex, phoneRegex } from './constants';

export const schemaRecipient = yup.object({
  recipientData: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        name: yup.string(),
        contact: yup
          .string()
          .test('email-phone-test', 'must be a valid email/phone number', (value?: string) => {
            if (!value) {
              return false;
            }
            const isValidEmail = emailRegex.test(value);
            const isValidPhone = phoneRegex.test(value);
            if (!isValidEmail && !isValidPhone) {
              return false;
            }
            return true;
          })
          .required(),
      }),
    )
    .required(),
});

export const setAReminderSchema = yup.object({
  deadline: yup.date().required(),
});

export const automaticDeliverySchema = yup.object({
  date: yup.date().required(),
  time: yup.date().required(),
});

export type SetReminderSchema = yup.InferType<typeof setAReminderSchema>;

export type AutomaticDeliverySchema = yup.InferType<typeof automaticDeliverySchema>;
