import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const useStyles = makeStyles((theme) => {
  const desktop = theme.breakpoints.up('md');
  return createStyles({
    container: {
      paddingTop: 80,
      gridArea: 'main',
      // grid system
      columnGap: 80,
      rowGap: 20,
      display: 'grid',
      gridTemplateRows: '1fr auto',
      gridTemplateColumns: '300px',
      gridTemplateAreas: '"giftWelcome" "giftThumbnail" "giftForm"',
      [desktop]: {
        gridTemplateColumns: '350px 400px',
        gridTemplateRows: '1fr auto auto',
        gridTemplateAreas:
          '"giftThumbnail giftWelcome" "giftThumbnail giftForm" "giftDetails giftForm"',
      },
    },
    leftArrow: {
      display: 'none',
      [desktop]: {
        display: 'block',
        position: 'fixed',
        left: 60,
        top: '50%',
      },
    },
    giftDetails: {
      padding: '0 30px',
      display: 'none',
      rowGap: 16,
      [desktop]: {
        display: 'grid',
        padding: 0,
      },
      gridArea: 'giftDetails',
      position: 'relative',
    },
    giftName: {
      display: 'grid',
      rowGap: 4,
    },
    tags: {
      order: 2,
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: 8,
    },
    giftThumbnail: {
      display: 'flex',
      alignItems: 'center',
      [desktop]: {
        padding: 0,
      },
      gridArea: 'giftThumbnail',
    },
    giftDetailContainer: {
      padding: '0 16px',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    giftImageContainer: {
      borderRadius: 8,
    },
    giftImage: {
      margin: 'auto 0',
      borderRadius: 8,
      height: 150,
      width: 150,
      objectFit: 'contain',
      [desktop]: {
        margin: 0,
        borderRadius: 0,
        width: 300,
        height: 300,
      },
    },
    giftButton: {
      position: 'absolute',
      borderWidth: 1,
      borderColor: colors.primary,
      borderRadius: 4,
      width: 116,
      '& .MuiButton-root': {
        padding: 0,
      },
      display: 'block',
    },
    giftWelcome: {
      padding: '0 30px',
      textAlign: 'center',
      [desktop]: {
        padding: 0,
        textAlign: 'left',
      },
      gridArea: 'giftWelcome',
    },
    giftForm: {
      display: 'grid',
      gridArea: 'giftForm',
    },
    input: {
      display: 'grid',
      rowGap: 12,
    },
    rowInput: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: 12,
    },
    dropDownArrow: {
      top: 'calc(50% - 8px)',
      color: 'rgba(0, 0, 0, 0.54)',
      right: 20,
      position: 'absolute',
      pointerEvents: 'none',
    },
    wFull: {
      width: '100%',
    },
  });
});

export default useStyles;
