import { useEffect, useRef, useState } from 'react';

import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UploadClient from '@uploadcare/upload-client';

import { Button } from 'components/illume/buttons';
import { RecorderRecordButton, RecorderStopButton } from 'components/illume/recorder-buttons';
import { IllumeLayout } from 'components/layouts';
import config from 'config';
import { colors } from 'constants/design';
import useMediaRecorder from 'hooks/useMediaRecord';
import logger from 'utils/logger';
const { uploadCareId } = config;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6.5),
    paddingTop: theme.spacing(7),
  },
  header: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
    },
  },
}));

function VideoPlayer({ srcBlob, audio }) {
  if (!srcBlob) {
    return null;
  }

  if (audio) {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
  const mediaUrl = URL.createObjectURL(srcBlob);

  return (
    <div>
      <video src={mediaUrl} width={520} height={480} controls />
    </div>
  );
}

const VideoLiveStream = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <video ref={videoRef} width={500} height={500} autoPlay controls />;
};

const VideoRecordPage = () => {
  const classes = useStyles();
  const { error, status, mediaBlob, stopRecording, liveStream, startRecording, download } =
    useMediaRecorder({
      blobOptions: { type: 'video/mp4' },
      mediaStreamConstraints: { audio: true, video: true },
    });

  const [progress, setProgress] = useState();

  // eslint-disable-next-line no-unused-vars
  async function uploadVideo() {
    // do something with mediaBlobUrl
    const client = new UploadClient({ publicKey: uploadCareId });
    const res = await client.uploadFile(mediaBlob, {
      onProgress: ({ value }) => {
        logger.log(value);
        setProgress(value * 100);
      },
    });
    setProgress(0);
    logger.log(res);
  }

  if (error) {
    return <div>there's an error</div>;
  }
  return (
    <IllumeLayout contentProps={{ justifyContent: 'center' }}>
      <Grid item xs={6} classes={{ root: classes.root }}>
        <Grid container>
          <Grid item xs={12}>
            <p>{status}</p>
          </Grid>
          <Grid item xs={12}>
            <div>recorded vid</div>
            <VideoPlayer srcBlob={mediaBlob} />
          </Grid>
          <Grid item>
            {/* ISSUE: the camera stop working when stopped */}
            <div>live preview</div>
            <VideoLiveStream stream={liveStream} />
          </Grid>
          <Grid item container>
            <Grid item>
              <RecorderRecordButton onClick={startRecording} />
            </Grid>
            <Grid item>
              <RecorderStopButton onClick={stopRecording} />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                size="compact"
                onClick={() => download({ fileName: 'recording_' + new Date().getTime() })}
              >
                Download
              </Button>
            </Grid>
            <Grid item>
              <Button fullWidth size="compact" onClick={uploadVideo}>
                {!progress ? (
                  'upload'
                ) : (
                  <CircularProgress
                    variant="determinate"
                    style={{ color: colors.contrastText }}
                    size={24}
                    value={progress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default VideoRecordPage;
