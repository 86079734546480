import { ValueOf } from '@illume/shared';
import { motion } from 'framer-motion';

import { colors } from 'constants/design';

const loadingContainerStyle = {
  width: 20,
  height: '1rem',
  display: 'flex',
  justifyContent: 'space-around',
};

const loadingCircleStyle = {
  display: 'block',
  width: 4,
  height: 4,
  borderRadius: '0.25rem',
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: '50%',
  },
  end: {
    y: '150%',
  },
};

const loadingCircleTransition = (x: number) => ({
  duration: 0.5,
  delay: x * 0.2,
  yoyo: Infinity,
  ease: 'easeInOut',
});

const ThreeDotsWave: React.FunctionComponent<{
  number?: number;
  color: ValueOf<typeof colors>;
}> = ({ number = 3, color = colors.primary }) => {
  return (
    <motion.div
      style={loadingContainerStyle}
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      {Array.from({ length: number }).map((_, i) => {
        return (
          <motion.span
            key={i}
            style={{
              ...loadingCircleStyle,
              backgroundColor: color,
            }}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition(i)}
          />
        );
      })}
    </motion.div>
  );
};

export default ThreeDotsWave;
