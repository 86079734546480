import { colors } from 'constants/design';

const Bell = ({ color = colors.contrastText, opacity = 0.6 }) => {
  return (
    <svg
      className="img"
      height="20px"
      width="20px"
      fill={color}
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 96 96"
    >
      <g>
        <path d="M54.6,9.9c5.5,1.4,10.4,4.6,14.3,9.1c5.3,6,8.6,14.3,8.6,23.5c0.1,21,3,24.5,6,27.9c1.8,2.1,3.5,4.1,4.8,8.8   c0.4,1.2-0.3,2.5-1.5,2.8c-0.2,0.1-0.4,0.1-0.7,0.1l0,0H59.4c0,3.2-1.3,6-3.3,8.1c-2.1,2.1-4.9,3.3-8.1,3.3c-3.1,0-6-1.3-8.1-3.3h0   c-2.1-2.1-3.3-4.9-3.3-8.1H9.9c-1.3,0-2.3-1-2.3-2.3c0-0.3,0.1-0.6,0.2-0.9c1.3-4.5,3.1-6.5,4.8-8.6c2.9-3.5,5.8-6.9,6-27.9   c0-9.2,3.3-17.5,8.6-23.5c3.9-4.4,8.8-7.6,14.3-9.1c0-0.3-0.1-0.6-0.1-0.9c0-1.8,0.7-3.5,2-4.7c1.2-1.2,2.9-2,4.7-2   c1.8,0,3.5,0.7,4.7,2l0,0c1.2,1.2,2,2.9,2,4.7C54.7,9.4,54.6,9.7,54.6,9.9L54.6,9.9z" />
      </g>
    </svg>
  );
};

export default Bell;
