import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

export const useContributeCardStyles = makeStyles((theme) => ({
  boxCard: {
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: 12,
    padding: '16px 18px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '22px 80px',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    background: colors.backgroundGray,
    borderRadius: 8,
    '& > .info': {
      maxWidth: 450,
      minWidth: 250,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 12,
      [theme.breakpoints.up('md')]: {
        rowGap: 18,
      },
    },
    '& > .image': {
      display: 'flex',
      alignItems: 'center',
      width: 200,
      height: 200,
      borderRadius: 8,
      // just in case
      overflow: 'hidden',
      '& > img': {
        width: '100%',
        borderRadius: 8,
      },
      [theme.breakpoints.up('sm')]: {
        width: 250,
        height: 250,
      },
    },
  },

  progress: {
    height: 10,
    borderRadius: 100,
    backgroundColor: colors.contrastText,
  },

  groupDetailsButton: {
    color: colors.gray60,
    marginTop: 10,
  },
  cardGiftParrent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'unset',
    },
  },
  titleParrent: {
    order: 2,
    marginTop: 15,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      order: 1,
    },
  },
  imageThumb: {
    width: 200,
    height: 200,
    margin: 'auto',
    borderRadius: 6,
    order: 1,
    objectFit: 'contain',
    [theme.breakpoints.up('md')]: {
      width: 75,
      height: 75,
      order: 2,
      margin: 0,
    },
  },
  contributedText: {
    marginBottom: 10,
  },
}));
