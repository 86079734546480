import { motion } from 'framer-motion';

import { navigationItem } from './Navigation.styles';
import { styleMenuItemVariants } from './Navigation.variants';

export const NavigationItem = ({ children }) => {
  const navigationItemStyles = navigationItem();

  return (
    <motion.li
      variants={styleMenuItemVariants}
      whileTap={{ scale: 0.95 }}
      className={navigationItemStyles.root}
    >
      {children}
    </motion.li>
  );
};
