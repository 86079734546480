// Added a subfolder to avoid this feature/bug: https://github.com/microsoft/TypeScript/issues/38846
// This can help us ensure that we namespace our constants in the future anyway.

import { REDESIGNED_APP_FEATURE_FLAG } from 'constants/strings';
import { checkFF } from 'utils/featureFlag';
import logger from 'utils/logger';

import { oldToClassicThemepackMapping } from './cardTheme';

// Gloabl theme consts
export const PRIMARY_THEME = 'primary';

// Once we have a better sense of a data structure for our colors, we can organize this better
export const colors = {
  transparent: 'transparent',
  primary: '#FEB81C',
  primarySoft: 'rgb(254 184 28 / 40%)',
  primaryDark: '#E5A619',
  primaryDarker: '#ce9516',
  primaryTooltip: '#FED472',
  background: '#FFFDF3',
  background40: 'rgb(255, 253, 243, 0.4)',
  themeBlue: '#C1DADE',
  themePink: '#E3C3C9', // not sure if it's pink or purple
  themeLightGreen: '#DDDCBE', // not sure if it's green
  themeDarkerYellow: '#D5AC3A', // i'm not sure about this one too please help T.T
  themePeach: '#FCC3AA',
  themeGray: '#BFBFBF', // gray hex color without opacity
  contrastText: '#FFFDF3',
  textInputBackground: '#F5F0E1',
  darkText: '#282829',
  gray100: '#282829', // or rgba(40, 40, 41, 1) same thing
  gray10: 'rgba(40, 40, 41, 0.1)',
  gray20: 'rgba(40, 40, 41, 0.2)',
  gray25: 'rgba(40, 40, 41, 0.25)',
  gray40: 'rgba(40, 40, 41, 0.4)',
  gray50: 'rgba(40, 40, 41, 0.5)',
  gray60: 'rgba(40, 40, 41, 0.6)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black100: '#000000',
  // TODO: To UI/UX designer, I don't think we have an error state yet in the design. So I randomly select a color which makes sense
  errorText: '#F35410',

  // TODO start to think about giving these text colors numeric values like rem
  labelText: 'rgba(40, 40, 41, 0.4)',
  tooltipText: 'rgb(40, 40, 41, 0.25)',
  backgroundGray70: 'rgba(245, 240, 225, 0.6)',
  lightTooltipBackground: '#F5F0E1',
  backgroundGray: '#F5F0E1',
  backgroundGrayDark: '#EFE6CE',
  backgroundGray60: '#FBF8EC',
  alertRed: '#F35410',
  alertGreen: '#23CE6B',
  alertGreenDark: '#1fb860',
  lightBlue: '#C1DADE',
  lightPurple: '#E5CDD1',
  lightGreen: '#DDDCBE',
  darkOrange: '#D5AC3A',
  darkOrangeNew: '#E8A000',
  pink: '#FCCFBA',
  black: '#000000',
  // thre dots wave brown
  threeDotsBrown: 'rgb(102, 60, 0)',

  // unknown colors
  unknownGrayColor: '#A3A097',
  subHeaderGray: '#565659',
} as const;

// We always want to space in increments of 4
export const spacing = {
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
  11: 44,
  12: 48,
  13: 52,
  14: 56,
  15: 60,
  16: 64,
  17: 68,
  // ...
  20: 80,
};

// scale: 1000 = 1rem, 2000 = 2rem, etc.
export const rem = {
  // rem defaults to 16px in a standard browser
  3750: '3.750rem', // 60px
  3000: '3.000rem', // 48px
  2800: '2.800rem', // 44px
  2500: '2.500rem', // 40px
  2250: '2.250rem', // 36px
  2000: '2.000rem', // 32px
  1750: '1.750rem', // 28px
  1500: '1.500rem', // 24px
  1375: '1.375rem', // 22px
  1250: '1.250rem', // 20px
  1125: '1.125rem', // 18px
  1000: '1rem', // 16px
  875: '0.875rem', // 14px
  800: '0.800rem',
  750: '0.750rem', // 12px
  687.5: '0.6875rem', // 11px
};

// We want to make sure our border radius is always the same, or if we want
// a new one that we have a good enough reason to add a new variable here.
export const borderRadius = 8;

// Some components have 6px radius and it makes a big difference
// TODO: maybe merge the border radius into an object
export const borderRadiusSmall = 6;

export const fontFamily = checkFF(REDESIGNED_APP_FEATURE_FLAG)
  ? [
      // 'Brandon Grotesque',
      'brandon-grotesque',
      'Roboto',
      '-apple-system',
    ]
  : [
      'Avenir',
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(',');

export const generateColorFromTheme = (theme) => {
  try {
    // eslint-disable-next-line eqeqeq
    if (theme == undefined) return;
    switch (theme) {
      case 'background':
        return colors.background;
      case 'transparent':
        return 'transparent';
      default:
        return oldToClassicThemepackMapping[theme].main;
    }
  } catch (err) {
    logger.error('error with theme:', theme);
    throw err;
  }
};

export const generateContrastFromTheme = (theme) => {
  // eslint-disable-next-line eqeqeq
  if (theme == undefined) return;
  if (theme === 'background') return colors.background;
  return oldToClassicThemepackMapping[theme].contrastText;
};

export const dateFormats = {
  US_READABLE: 'MMMM do, yyyy',
  US_COMPACT: 'MM/dd/yyyy',
};

// Dimensions
export const DESKTOP_MAX_WIDTH = 1124;
export const DESKTOP_MIN_WIDTH = 1024;

export { DEFAULT_PALETTE } from './cardTheme';

export const Z_INDEXES = {
  TOOLTIP: 1500,
  MODAL: '1200!important',
};

export const mixins = {
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
