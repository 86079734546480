import { HTMLAttributes, useMemo, useRef, useState } from 'react';

import { cx } from '@emotion/css';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { map, Subject, throttleTime } from 'rxjs';
import { useObservable } from 'rxjs-hooks';

import { PROFILE_URL } from 'constants/strings';
import { useStores } from 'contexts/store';
import { TopNavigation } from 'views/components-v2/elements/top-navigation';
import { MENUS } from 'views/components-v2/elements/top-navigation/constants';

import { styles, toggle } from '../../../pages-v2/my-cards/MyCards';

export interface LayoutV2Props<T = HTMLAttributes<HTMLDivElement>> {
  // children as render props
  containerAs?: React.ElementType<T>;
  containerProps?: T;
  children: (props: {
    navActive: boolean;
    userMenuActive: boolean;
    desktopUserMenuActive: boolean;
    scrollRef: React.RefObject<HTMLDivElement>;
  }) => React.ReactNode;
}

export const LayoutV2 = observer(function <T>({
  children,
  containerAs: C = 'div',
  containerProps = {},
}: LayoutV2Props<T>) {
  const [navActive, setNavActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [desktopUserMenuActive, setDesktopUserMenuActive] = useState(false);
  const { authenticationStore } = useStores();
  const history = useHistory();
  const TRIGGER_HEIGHT = 300;
  const scrollRef = useRef<HTMLDivElement>(null);
  const $scroll = useMemo(() => new Subject(), []);

  const { className, ...rest } = containerProps;

  const showNavbar = useObservable(
    () =>
      $scroll.pipe(
        throttleTime(50),
        map((scrollTop) => {
          return scrollTop < TRIGGER_HEIGHT;
        }),
      ),
    true,
  );

  // listen to scroll event rxjs
  return (
    <C
      className={cx(styles.container, className)}
      ref={scrollRef}
      onScroll={(e: any) => $scroll.next(e.currentTarget.scrollTop)}
      {...rest}
    >
      <AnimatePresence initial={false}>
        {showNavbar && (
          <TopNavigation
            containerAs={motion.div}
            // animate appearance form bottom and exit to the top
            // @ts-expect-error
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -100, opacity: 0 }}
            // smooth
            transition={{ duration: 0.3 }}
            // mobile cta and states
            onHamburgerClick={() => setNavActive(true)}
            hamburgerAsClose={navActive}
            /** user menu should be activated to toggle */
            overlayUserMenuActive={userMenuActive}
            desktopUserMenuActive={desktopUserMenuActive}
            // on desktop
            onUserMenuChevronClick={() => setDesktopUserMenuActive(toggle)}
            onProfileContainerClick={() => setDesktopUserMenuActive(toggle)}
            // on mobile
            onPersonButtonClick={() => setUserMenuActive(true)}
            userIconAsClose={false}
            onNavOverlayClose={() => setNavActive(false)}
            overlayNavActive={navActive}
            onUserOverlayClose={() => setUserMenuActive(false)}
            menus={MENUS}
            editProfileAction={{
              loading: false,
              onAction: () => {
                setUserMenuActive(false);
                return history.push(PROFILE_URL);
              },
              text: 'profile',
            }}
            logoutAction={{
              loading: false,
              onAction: authenticationStore.logout,
              text: 'logout',
            }}
          />
        )}
      </AnimatePresence>
      {children({
        navActive,
        desktopUserMenuActive,
        userMenuActive,
        scrollRef,
      })}
    </C>
  );
});
