import { FunctionComponent } from 'react';

import { colors } from 'constants/design';

interface MailIconProps {
  color: string;
  size: number;
}

export const MailIcon: FunctionComponent<MailIconProps> = ({
  color = colors.gray60,
  size = 16,
}) => {
  return (
    <svg
      width={size}
      height={(12 / 16) * size}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75315 7.0025L15.6648 11.599C15.5609 11.7296 15.4032 11.8061 15.2361 11.8068H0.80808C0.577575 11.8067 0.3582 11.7078 0.20544 11.5352L6.03579 7.0025L7.64919 8.36944C7.7178 8.42727 7.80463 8.45903 7.89441 8.45903C7.98418 8.45903 8.071 8.42727 8.13963 8.36944L9.75315 7.0025ZM0 1.89215V10.7342L5.44425 6.50165L0 1.89215ZM6.2931 6.22685L7.89435 7.58213L9.49545 6.22663L9.49768 6.22481L15.7822 0.903862H15.7822C15.7581 0.708037 15.6634 0.527752 15.5157 0.396847C15.3681 0.266007 15.1778 0.193582 14.9804 0.193237H0.552368C0.405884 0.193413 0.265433 0.251713 0.161903 0.355312C0.0583685 0.458965 0.000187978 0.599407 6.79779e-05 0.745898V0.898357L6.29122 6.22486L6.2931 6.22685ZM10.3445 6.50236L15.7887 10.7342V1.89196L10.3445 6.50236Z"
        fill={color}
      />
    </svg>
  );
};
