import { useCallback, useEffect, useState } from 'react';

import { IGif } from '@giphy/js-types';
import { Box, Modal as MUIModal, Paper } from '@material-ui/core';

import poweredByImg from 'assets/svg/PoweredBy_200px-Black_HorizLogo.png';
import { BaseIconButton } from 'components/illume/buttons';
import { Close } from 'components/illume/icons';
import { ResponsiveGiphyGrid } from 'components/illume/responsive-giphy-grid';
import { SearchBar } from 'components/illume/search-bar';
import {
  colors,
  DEFAULT_PALETTE,
  generateContrastFromTheme,
  PRIMARY_THEME,
} from 'constants/design';
import { generateContrastFromPalette } from 'constants/design/cardTheme';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import giphyService from 'infra/giphy/giphy';
import { PaletteDTO } from 'types';
import { noop } from 'utils';
import { checkFF } from 'utils/featureFlag';
import logger from 'utils/logger';

import { useContainerStyles, useModalStyles, usePaperStyles } from './styles';
interface GiphyModalProps {
  open: boolean;
  onClose: () => any;
  onSelectGif: (gif: IGif) => any;
  theme: string;
  palette: PaletteDTO;
}
const GiphyModal: React.FC<GiphyModalProps> = ({
  open,
  onClose = noop,
  onSelectGif = noop,
  theme = PRIMARY_THEME,
  palette = DEFAULT_PALETTE,
}) => {
  const paperClasses = usePaperStyles({
    backgroundColor: checkFF('NEW_CARD_THEMING')
      ? generateContrastFromPalette(palette, theme)
      : generateContrastFromTheme(theme),
  });
  const modalClasses = useModalStyles();
  const containerClasses = useContainerStyles();
  const isDesktop = useIsDesktop();
  const [term, setTerm] = useState('');
  const fetchGifs = useCallback(
    (offset) =>
      term
        ? giphyService.search(term, { offset, limit: 10 })
        : giphyService.trending({ offset, limit: 10 }),
    [term],
  );

  useEffect(() => {
    // since the modal doesn't unmounted, we need to explicitly reset the state
    if (!open) {
      setTerm('');
    }
  }, [open]);

  return (
    <MUIModal
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
      classes={modalClasses}
      onClose={onClose}
      open={open}
      keepMounted={false}
    >
      <Box position="relative" className={containerClasses.root}>
        <Box position="absolute" top={20} right={20} zIndex={2000}>
          <BaseIconButton
            onClick={onClose}
            icon={() => <Close color={colors.contrastText} size={25} />}
          />
        </Box>
        <Paper classes={paperClasses}>
          <Box mb={2}>
            <img src={poweredByImg} className={modalClasses.giphyLogo} alt="powered by Giphy" />
          </Box>
          <Box>
            <Box mb={4}>
              <SearchBar
                value={term}
                // it seems that the debounce feature is built in within the <Grid /> component
                // https://github.com/Giphy/giphy-js/blob/master/packages/react-components/src/components/grid.tsx#L65
                onChange={setTerm}
                onSubmit={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  logger.log(e);
                }}
              />
            </Box>
            <ResponsiveGiphyGrid
              // key will recreate the component,
              // this is important for when you change fetchGifs
              // e.g. changing from search term dogs to cats or type gifs to stickers
              // you want to restart the gifs from the beginning and changing a component's key does that
              key={term}
              columns={isDesktop ? 3 : 2}
              fetchGifs={fetchGifs}
              onSelectGif={(gif) => {
                onSelectGif(gif);
                onClose();
              }}
            />
          </Box>
        </Paper>
      </Box>
    </MUIModal>
  );
};

export default GiphyModal;
