import { colors } from 'constants/design';

export const Close = ({
  color = colors.gray100,
  size = 100,
  hasBlob = false,
  blobSize = 40,
}: any) => {
  const close = (
    <svg
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <path d="M60.386,49.999l32.461-32.461c2.87-2.869,2.87-7.517,0-10.386c-2.869-2.87-7.517-2.87-10.386,0L50,39.613L17.538,7.152      c-2.869-2.87-7.517-2.87-10.386,0c-2.87,2.869-2.87,7.517,0,10.386l32.461,32.461L7.152,82.461c-2.87,2.869-2.87,7.517,0,10.386      C8.587,94.283,10.466,95,12.345,95c1.879,0,3.758-0.717,5.193-2.152L50,60.386l32.462,32.461C83.896,94.283,85.776,95,87.655,95      c1.88,0,3.759-0.717,5.193-2.152c2.87-2.869,2.87-7.517,0-10.386L60.386,49.999z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  const closeBlob = (
    <svg
      width={blobSize}
      height={blobSize}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M7.17561 26.966C5.29496 25.2626 4.70626 22.0345 5.41624 18.4273C6.1738 14.5773 8.10346 11.3979 11.0342 8.82366C12.7229 7.3376 14.6495 6.27418 16.9428 6.0563C18.4328 5.91364 19.8919 6.1096 21.3374 6.46548C23.8864 7.0915 26.3465 7.94155 28.6116 9.29801C31.7483 11.1753 33.3461 14.0023 33.7082 17.577C34.087 21.3227 32.8962 24.6076 30.6342 27.5602C29.0532 29.6253 27.0859 31.2098 24.7229 32.2968C21.8383 33.6239 18.8871 33.6967 15.9347 32.6484C12.9917 31.6057 10.366 30.0169 7.17561 26.966Z"
          fill={color}
          fillOpacity="0.6"
        />
        <line
          x1="16.2785"
          y1="15.7339"
          x2="23.8979"
          y2="23.3533"
          stroke="#F5F0E1"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="15.7676"
          y1="23.3529"
          x2="23.387"
          y2="15.7335"
          stroke="#F5F0E1"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="28"
            height="27.2911"
            fill="white"
            transform="translate(0 19.7988) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  return hasBlob ? closeBlob : close;
};
