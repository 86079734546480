import { makeStyles } from '@material-ui/core/styles';

export const fullwidthSwitchStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {
    height: 24,
    width: '100%',
  },
}));
