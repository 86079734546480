// breakpoints with device name and iphones
export const breakpoints = {
  // mobiles
  mobile: 0,
  iphone5: 320,
  iphone6: 375,
  iphone6Plus: 414,
  iphoneX: 375,
  iphoneXsMax: 414,
  // tablets
  ipad: 768,
  tablet: 768,
  ipadPro: 1024,

  // desktops
  desktopSmall: 1024,
  desktop: 1280,
  desktopLarge: 1440,
  desktopXl: 1920,
  desktopXxl: 2560,
} as const;
