import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Modal as MUIModal, Paper } from '@material-ui/core';
import classNames from 'classnames';
import { Dictionary } from 'lodash';
import { useForm } from 'react-hook-form';
import Carousel from 'react-material-ui-carousel';

import { Button } from 'components/illume/buttons';
import { Close, Dollar, Heart, PriceTag, TrashBin } from 'components/illume/icons';
import { Surface } from 'components/illume/surface';
import { TextInput } from 'components/illume/text-inputs';
import Text from 'components/illume/text/Text';
import { LinearProgress } from 'components/linear-progress';
import { Link } from 'components/link';
import { colors, rem } from 'constants/design';
import { usePrompContext } from 'contexts/prompt';
import { IMoney as Money } from 'domain/interfaces/IMoney';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { getPaymentFormSchema } from 'pages/illume/initiator/add-gift/forms/ContributionForm';
import { HighlightDTO } from 'types';
import { noop } from 'utils';

import { HtmlRenderer } from '../html-renderer';
import { carouselStyles, contentStyles } from './GroupGiftModal.styles';

const CarouselItem: React.FC<{ img: string; icon?: React.ElementType<any> }> = (props) => {
  const { img, icon } = props;
  const Icon = icon as React.ElementType<any>;
  const classes = carouselStyles({ img });

  return (
    <div style={{ borderRadius: 6 }}>
      <Box className={classes.image}>{icon && <Icon />}</Box>
    </div>
  );
};

export interface IGroupGiftModalGift {
  product: {
    img: string;
    description: string;
    vendor?: string;
    highlights?: HighlightDTO[];
    opinion?: string;
    name: string;
  };
  financial: {
    suggested: Money;
    current: Money;
    goal: Money;
    myContribution: Money;
    progressRatio: number;
    goalRemaining: Money;
    giftMetGoals: boolean;
    formattedPriceBreakdown: Dictionary<string>;
  };
}

export interface GroupGiftModalProps {
  gift: IGroupGiftModalGift;
  showModal: boolean;
  removable: boolean;
  hideModal: () => any;
  defaultValues: Partial<GroupGiftModalFormValues>;
  onContributeToGroupGift: (values: GroupGiftModalFormValues) => any;
  removeAction?: {
    remove: () => any;
    excecuting: boolean;
    error?: Error | null;
  };
}

export interface GroupGiftModalFormValues {
  amount: string;
  email: string;
}
const GroupGiftModal: React.FC<GroupGiftModalProps> = ({
  showModal,
  gift,
  onContributeToGroupGift,
  defaultValues,
  hideModal,
  removable,
  removeAction,
}) => {
  const desktop = useIsDesktop();
  const classes = contentStyles({ desktop });

  const shownRatio = useMemo(() => {
    const ratio = gift.financial.progressRatio;
    if (ratio === 0) {
      return 1; // add arbitary number to meet design purpose
    } else return ratio * 100;
  }, [gift.financial.progressRatio]);

  const formattedSuggestedAmount = gift.financial.suggested.toFormat();
  const formattedCurrent = gift.financial.current.toFormat();
  const formattedGoal = gift.financial.goal.toFormat();
  const formattedYourContribution = gift.financial.myContribution.toFormat();
  const restOfTheGoal = gift.financial.goalRemaining;
  const goalHasMet = gift.financial.giftMetGoals;

  // we might want to include variants product here
  const paymentFormSchema = getPaymentFormSchema(restOfTheGoal);
  const product = gift.product;

  const {
    errors,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ resolver: yupResolver(paymentFormSchema), defaultValues });

  const { prompt } = usePrompContext();

  // Overriding Carousel default icon
  const carouselIconImage = product.img;
  const byStoreName = product.vendor && `by ${product.vendor}`;
  const description = product.description;
  const whyWeLoveProduct = product.opinion;

  // TODO: Disabled when we have more clear way to handle variants
  // right now, in contribution flow. it's assumed that the initiator has choosen a specific variant
  // so it doesn't make sense if we show the other variant images here
  // unless a variant has multiple images
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const arrayOfCarouselIcon = [
    <img alt="test" width={36} height={36} src={carouselIconImage} />,
    <img alt="test" width={36} height={36} src={carouselIconImage} />,
    <img alt="test" width={36} height={36} src={carouselIconImage} />,
  ];

  const handleCloseGroupGiftModal = hideModal;

  function handleGiftRemove(e: React.SyntheticEvent) {
    e.preventDefault();
    prompt({
      title: 'ARE YOU SURE YOU WANT TO REMOVE THIS GIFT?',
      subtitle:
        'Don’t worry, no one’s been charged yet. People are charged what they pledge when the gift is sent. Haven’t met the price of a gift? No problem, easily remove your gift below.',
      onOk: removeAction?.remove || noop,
      onCancel: noop,
      okText: 'yes, remove',
      cancelText: 'no, cancel',
    });
  }

  return (
    <MUIModal onClose={handleCloseGroupGiftModal} open={showModal} className={classes.modal}>
      <Paper className={classNames(classes.paper, 'customIllumeScrollbar-gray')}>
        {desktop && removable && (
          <Box className={classes.desktopRemoveButton} mt={4} mb={4}>
            <Grid container justifyContent="center" alignItems="center">
              <TrashBin color={colors.gray40} size={20} />
              <Link onClick={handleGiftRemove} underline="always" style={{ color: colors.gray40 }}>
                {removeAction?.excecuting
                  ? 'removing...'
                  : removeAction?.error
                  ? 'there was an error, retry?'
                  : 'remove gift'}
              </Link>
            </Grid>
          </Box>
        )}
        <div className={classes.closeIcon} onClick={handleCloseGroupGiftModal}>
          <Close size={16} color={colors.gray100} />
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6} direction="column">
            <Grid item>
              {desktop ? (
                <Carousel
                  IndicatorIcon={false}
                  indicatorIconButtonProps={{
                    style: {
                      padding: '10px',
                    },
                  }}
                >
                  {[product].map((mp, index) => (
                    <CarouselItem key={index} img={mp.img} />
                  ))}
                </Carousel>
              ) : (
                <Carousel IndicatorIcon={false}>
                  {[product].map((mp, index) => (
                    <CarouselItem key={mp.img + index} img={mp.img} />
                  ))}
                </Carousel>
              )}
            </Grid>
            <Grid item className={classes.productTitle}>
              <Text fontWeight={700} lineHeight={'28px'} fontSize={{ desktop: 28, mobile: 24 }}>
                {product.name}
              </Text>
            </Grid>
            {byStoreName && (
              <Grid item>
                <Text lineHeight={'50px'} fontWeight={400} fontSize={18}>
                  {byStoreName}
                </Text>
              </Grid>
            )}
            <Grid
              item
              style={{ maxHeight: 100, marginTop: 10, overflowY: 'scroll' }}
              className="customIllumeScrollbar-gray"
            >
              <Text italic lineHeight={'18px'} fontWeight={400} fontSize={14}>
                <HtmlRenderer html={description} />
              </Text>
            </Grid>
            {product.highlights && (
              <Grid item container direction="row">
                {product.highlights.map(({ highlight, color }) => {
                  return (
                    <Surface
                      key={highlight}
                      className={classes.tagContainer}
                      background={color}
                      borderRadius={4}
                    >
                      <Text fontSize={rem[687.5]} color={colors.contrastText}>
                        {highlight}
                      </Text>
                    </Surface>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={6} container>
            <Grid item alignItems="center" spacing={1} container>
              <Grid item>
                <Text fontWeight={800} fontSize={rem[1750]}>
                  {formattedCurrent}
                </Text>
              </Grid>
              <Grid item>
                <Text color={colors.gray60}>raised of {formattedGoal} goal</Text>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress value={shownRatio} variant="determinate" />
            </Grid>

            {formattedYourContribution && (
              <Text color={colors.alertGreen} lineHeight={'36px'} fontWeight={400} fontSize={16}>
                You've already contributed {formattedYourContribution}
              </Text>
            )}

            {whyWeLoveProduct && (
              <div className={classes.whyWeLoveIt}>
                <Text fontWeight={700} prefix={<Heart size={15} />}>
                  WHY WE LOVE IT
                </Text>
                <Text fontSize={`14px`} lineHeight={`18px`} fontWeight={400}>
                  {whyWeLoveProduct}
                </Text>
              </div>
            )}
            <div className={classes.priceBreakdown}>
              <div>
                <Text fontWeight={700} prefix={<PriceTag size={15} />}>
                  PRICE BREAKDOWN
                </Text>
              </div>
              <div className={classes.breakdown}>
                {Object.entries(gift.financial.formattedPriceBreakdown).map(
                  ([key, formattedPrice], i, arr) => {
                    const last = i === arr.length - 1;
                    const isDiscount = key.toLocaleLowerCase().includes('discount');
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text
                          fontSize={`14px`}
                          lineHeight={`18px`}
                          fontWeight={last ? 'bold' : 400}
                        >
                          {key}:
                        </Text>
                        <Text
                          fontSize={`14px`}
                          lineHeight={`18px`}
                          fontWeight={last ? 'bold' : 400}
                          color={
                            last ? colors.primary : isDiscount ? colors.alertRed : colors.gray100
                          }
                        >
                          {formattedPrice}
                        </Text>
                      </div>
                    );
                  },
                )}
              </div>
            </div>

            <Grid
              item
              direction="column"
              spacing={1}
              container
              className={classes.contributionInputContainer}
            >
              <Surface rounded={['top', 'bottom']} fullWidth>
                <Grid item style={{ marginBottom: 10 }}>
                  <Text fontWeight={800} fontSize={rem[1000]}>
                    The suggested contribution amount is {formattedSuggestedAmount}
                  </Text>
                </Grid>
                <Grid item container direction="column" spacing={1} style={{ marginBottom: 12 }}>
                  <Grid item>
                    <TextInput
                      background="secondary"
                      inputId="total-amount"
                      label="Enter your contribution amount"
                      disabled={goalHasMet}
                      style={{ height: 45 }}
                      placeholder={`${formattedSuggestedAmount.substring(1)}`}
                      startIcon={Dollar}
                      iconBackground={goalHasMet ? colors.primarySoft : colors.primary}
                      name="amount"
                      error={errors.amount}
                      type="number"
                      ref={register}
                      data-testid="amount"
                      inputProps={{ role: 'numberInput', inputMode: 'decimal' }}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      background="secondary"
                      inputId="Email address"
                      label="Email address"
                      disabled={goalHasMet}
                      placeholder="e.g contributor@mail.com"
                      error={errors.email}
                      name="email"
                      ref={register}
                      data-testid="email"
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  {goalHasMet ? (
                    <Button
                      disabled={true}
                      onClick={handleSubmit(onContributeToGroupGift)}
                      fullWidth
                      className={classes.contributeButton}
                    >
                      Goal Fulfilled
                    </Button>
                  ) : (
                    <Button
                      loading={isSubmitting}
                      onClick={handleSubmit(onContributeToGroupGift)}
                      fullWidth
                      className={classes.contributeButton}
                    >
                      Contribute to group gift
                    </Button>
                  )}
                </Grid>
                {!desktop && removable && (
                  <Box mt={4} mb={4}>
                    <Grid container justifyContent="center" alignItems="center">
                      <TrashBin color={colors.gray40} size={20} />
                      <Link
                        onClick={handleGiftRemove}
                        underline="always"
                        style={{ color: colors.gray40 }}
                      >
                        {removeAction?.excecuting
                          ? 'removing...'
                          : removeAction?.error
                          ? 'there was an error, retry?'
                          : 'remove gift'}
                      </Link>
                    </Grid>
                  </Box>
                )}
              </Surface>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MUIModal>
  );
};

export default GroupGiftModal;
