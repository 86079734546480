export const PaperPlane = ({ color, size = 24 }) => (
  <svg
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.49352 11.842C2.81186 12.1997 2.84014 12.7234 3.55479 13.0062L5.67224 13.844C6.38749 14.128 7.46978 13.973 8.07603 13.4999L17.2711 6.24853C17.8756 5.77308 17.9404 5.84319 17.4154 6.40525L10.1463 14.1857C9.61963 14.746 9.77517 15.4342 10.4928 15.7117L10.7408 15.8083C11.4584 16.0858 12.6279 16.5518 13.3408 16.8417L15.6892 17.7979C16.4027 18.0878 17.165 17.746 17.3677 17.0037L20.9469 3.84769C21.149 3.10535 20.7566 2.79015 20.0749 3.14718L3.49352 11.842Z" />
    <path d="M9.30442 20.455C9.26258 20.5805 10.7526 18.2975 10.7526 18.2975C11.1738 17.6541 10.9346 16.8865 10.2229 16.5954L8.59801 15.9302C7.8863 15.6392 7.54518 15.9809 7.83976 16.692C7.83976 16.692 9.34742 20.326 9.30442 20.455Z" />
  </svg>
);
