export const Save = ({ circleColor = '#FFFDF3', shapeColor = '#FEB81C', size = 36 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill={circleColor}
        d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
      ></path>
      <path
        fill={shapeColor}
        d="M13.55 17.205a.56.56 0 01.512-.33h2.25V9.562c0-.31.252-.562.563-.562h2.25c.31 0 .562.252.562.563v7.312h2.25a.563.563 0 01.423.933l-3.937 4.5a.56.56 0 01-.846 0l-3.938-4.5a.56.56 0 01-.088-.603z"
      ></path>
      <path
        fill={shapeColor}
        d="M11.813 21.375v3.375h12.374v-3.375h2.25v4.5c0 .622-.504 1.125-1.125 1.125H10.688a1.124 1.124 0 01-1.124-1.125v-4.5h2.25z"
      ></path>
    </svg>
  );
};
