/**
 * from: https://github.com/fullstorydev/fullstory-browser-sdk/issues/2
 */
let readyCalled;

export const init = () => {
  window._fs_ready = () => {
    readyCalled = true;
  };
};

export const isFullstoryReady = () =>
  new Promise((resolve, reject) => {
    const { FS } = window || {};
    let FsSessionUrl, FsSessionId;
    try {
      if (readyCalled) {
        FsSessionUrl = FS && FS.getCurrentSessionURL();
        FsSessionId = FS && FS.getCurrentSession();
        return resolve({ FsSessionUrl, FsSessionId });
      }
      let iteration = 0;
      const id = setInterval(() => {
        if (readyCalled) {
          FsSessionUrl = FS && FS.getCurrentSessionURL();
          FsSessionId = FS && FS.getCurrentSession();
          clearInterval(id);
          return resolve({ FsSessionUrl, FsSessionId });
        }
        iteration++;
        if (iteration > 10) {
          clearInterval(id);
          return reject('timeout');
        }
      }, 500);
    } catch (e) {
      reject(e);
    }
  });

// eslint-disable-next-line import/no-anonymous-default-export
export default { isFullstoryReady, init };
