import qs from 'query-string';

import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { IniviteContributeNotificationDTO } from 'types';
import { spellNames } from 'utils/string';

import distanceFormatter from '../../../utils/date/distanceFormatter';
import { Notification } from './Notification';
import { ConcreateNotification } from './types';

export type IInviteContributeNotification = ConcreateNotification<IniviteContributeNotificationDTO>;

const InviteContribute: React.FC<IInviteContributeNotification> = ({
  notification,
  markAsRead,
  ...props
}) => {
  const { timestamp, read, payload, id } = notification || {};
  const { recipientNames, initiatorName, inviteCode } = payload || {};
  const createdTime = new Date(timestamp); // TODO: better naming

  const history = useIllumeHistory();

  const handleClick = () => {
    const config = {
      pathname: '/contributor/welcome',
      search: qs.stringify({ inviteCode }),
    };
    history.push(config);
    markAsRead(id);
  };

  return (
    <Notification dotted={!read} onClick={handleClick} {...props}>
      <Text display="inline" fontSize={rem[875]} color={colors.gray40}>
        {initiatorName} invited you to <b>add a note</b> to{' '}
        <b>{spellNames(recipientNames)}'s card.</b>
      </Text>
      <Text fontSize={rem[875]} display="inline" color={colors.gray20}>
        {' '}
        {distanceFormatter(createdTime)}
      </Text>
    </Notification>
  );
};

export default InviteContribute;
