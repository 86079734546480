import { useState } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { TabsDivider } from 'components/illume/divider';
import Link from 'components/illume/link/Link';
import { Modal } from 'components/illume/modal';
import Text from 'components/illume/text/Text';
import { colors, rem } from 'constants/design';
import { IIllumeModal } from 'types';
import { noop } from 'utils';

const useStyles = makeStyles((theme) => ({
  contributorModal: {
    maxHeight: theme.spacing(52),
    overflowY: 'scroll',
  },
  tab: {
    color: colors.gray40,
    '& p': {
      fontSize: rem[1000],
      [theme.breakpoints.up('md')]: {
        fontSize: rem[1125],
      },
    },
  },
}));

const CONTRIBUTORS: Record<string, Tab> = {
  INVITED: 'Invited',
  CONTRIBUTED: 'Contributed',
};

type Tab = 'Invited' | 'Contributed';
interface ContributorModalProps extends IIllumeModal {
  inviteList: string[];
  contributorList: string[];
  showInviteList?: boolean;
}
const ContributorsModal: React.FunctionComponent<ContributorModalProps> = ({
  onClose = noop,
  show,
  contributorList = [],
  inviteList = [],
  showInviteList = true,
}) => {
  const [selectedTabModal, setSelectedTabModal] = useState(CONTRIBUTORS.CONTRIBUTED);
  const classes = useStyles();
  const hideInviteList = !showInviteList;

  function renderInviteList({
    selectedTabModal,
    contributorList,
    inviteList,
    hideInviteList,
  }: Pick<ContributorModalProps, 'contributorList' | 'inviteList'> & {
    selectedTabModal: Tab;
    hideInviteList: boolean;
  }) {
    return selectedTabModal === CONTRIBUTORS.CONTRIBUTED ? (
      contributorList.length > 0 ? (
        contributorList.map((contributer, index) => (
          <Text align="center" key={index} fontWeight={300} fontSize={rem[1000]}>
            {contributer === '' ? 'anonymous' : contributer}
          </Text>
        ))
      ) : (
        <Text align="center" fontWeight={300} fontSize={rem[1000]}>
          none has contributed yet.
        </Text>
      )
    ) : selectedTabModal === CONTRIBUTORS.INVITED ? (
      hideInviteList ? (
        <Text align="center" fontWeight={300} fontSize={rem[1000]}>
          oops! you haven't invited anyone via email yet. you can do this from the card details
          page.
        </Text>
      ) : inviteList.length > 0 ? (
        inviteList.map((email, index) => {
          return (
            <Text align="center" key={index} fontWeight={300} fontSize={rem[1000]}>
              {email}
            </Text>
          );
        })
      ) : (
        <Text align="center" fontWeight={300} fontSize={rem[1000]}>
          oops! you haven't invited anyone via email yet. you can do this from the card details
          page.
        </Text>
      )
    ) : (
      'state is undefined'
    );
  }

  return (
    <Modal
      name="contributers"
      promptConfig={{
        onOk: onClose,
        okText: 'close',
      }}
      onClose={onClose}
      show={show}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Text fontSize={rem[1375]} align="center" fontWeight={900} color={colors.gray100}>
            CONTRIBUTOR LIST
          </Text>
        </Grid>

        <TabsDivider height={20} variant="small">
          {[
            {
              id: CONTRIBUTORS.CONTRIBUTED,
              text: 'contributed',
            },
            { id: CONTRIBUTORS.INVITED, text: 'invited', hidden: hideInviteList },
          ].map(({ id, text }) => (
            <Link
              key={id}
              onClick={() => setSelectedTabModal(id)}
              active={selectedTabModal === id}
              className={classes.tab}
              underline={selectedTabModal === id ? true : false}
            >
              {text}
            </Link>
          ))}
        </TabsDivider>

        <Grid item className={classNames(classes.contributorModal, 'customIllumeScrollbar-orange')}>
          {renderInviteList({ selectedTabModal, contributorList, inviteList, hideInviteList })}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ContributorsModal;
