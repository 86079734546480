import { ValueOf } from '@illume/shared';
import { Grid } from '@material-ui/core';

import { Plus } from 'components/illume/buttons';
import { Surface } from 'components/illume/surface';
import { colors, rem } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

import { Text } from '../text';
import { newCardStyles } from './NewCard.styles';

interface Props {
  text: string;
  onClick?: () => void;
  clickable?: boolean;
  size?: 'small' | 'large' | 'full' | number;
  fixedSize?: boolean;
  background?: string;
  blobColor?: string;
  plusColor?: string;
  loading?: boolean;
  textColor?: ValueOf<typeof colors>;
}

export const RectangularBoxWithPlusButton: React.FunctionComponent<Props> = ({
  text,
  onClick,
  clickable = true,
  size,
  fixedSize,
  background = colors.primary,
  blobColor,
  plusColor = colors.contrastText,
  loading = false,
  textColor = colors.contrastText,
  ...props
}) => {
  const desktop = useIsDesktop();
  const newCardStylesUI = newCardStyles({ size, fixedSize, clickable });
  const desktopFont = size === 'large' ? rem[1500] : rem[1125];
  const mobileFont = size === 'large' ? rem[1125] : rem[1000];

  return (
    <Surface
      rounded={['top', 'bottom']}
      background={background}
      className={newCardStylesUI.root}
      onClick={onClick}
      {...props}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={newCardStylesUI.container}
        spacing={1}
      >
        <Plus
          loading={loading}
          clickable={clickable}
          size={size}
          blobColor={blobColor}
          plusColor={plusColor}
        />

        <Grid item>
          <Text color={textColor} fontWeight={700} fontSize={desktop ? desktopFont : mobileFont}>
            {text}
          </Text>
        </Grid>
      </Grid>
    </Surface>
  );
};
