import { colors } from 'constants/design';

export default function Arrow({ color = colors.gray100, size = 36 }) {
  return (
    <svg width={size} viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.47561 14.368L12.4556 25.348C13.2116 26.104 14.4356 26.104 15.1556 25.348C15.9116 24.592 15.9116 23.368 15.1556 22.648L7.41561 14.908L33.1916 14.908C34.2356 14.908 35.0996 14.044 35.0996 13C35.0996 11.956 34.2356 11.092 33.1916 11.092L7.45161 11.092L15.1556 3.35204C15.9116 2.59604 15.9116 1.37204 15.1556 0.652037C14.7956 0.292037 14.2916 0.0760375 13.7876 0.0760375C13.2836 0.0760375 12.8156 0.256037 12.4196 0.652037L1.40361 11.668C1.04361 12.028 0.827609 12.532 0.827609 13.036C0.899609 13.504 1.11561 14.008 1.47561 14.368Z"
        fill={color}
      />
    </svg>
  );
}
