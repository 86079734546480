import { useEffect, useState } from 'react';

import { ContributorCardDTO, InitiatorCardViewDTO } from '@illume/shared';
import { Grid } from '@material-ui/core';
import { identity } from 'lodash';
import { useAsyncCallback } from 'react-async-hook';
import { match } from 'ts-pattern';

import { CopyLink } from 'components/illume/text-inputs/CopyLink';
import PhoneAndEmailInvites from 'components/mulitple-contacts-sender/MultipleContactsSender';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { spellNames } from 'utils/string';

type InitiatorContributorinProgress =
  | {
      card: InitiatorCardViewDTO;
      inviteFeatureOn: boolean;
      inviteService: (contacts: string[]) => Promise<any>;
    }
  | { card: ContributorCardDTO; inviteFeatureOn: false };

export const InitiatorContributorInProgress: React.FunctionComponent<
  InitiatorContributorinProgress
> = ({ card, inviteFeatureOn, ...rest }) => {
  const { recipientData: recipientData } = card || {};

  // right now API returns contributerLink,
  // but I think it used to return contributeLink, so just in case
  const link = match(card)
    .with({ userContext: { role: 'Initiator' } }, (c) => c.contributerLink)
    .with({ userContext: { role: 'Contributor' } }, (c) => c.contributeLink)
    .exhaustive();

  const spelledNames = spellNames(recipientData);

  const [emailInputError, setEmailInputError] = useState(false);
  const ERROR_TIMEOUT = 5000;

  const query = useAsyncCallback('inviteService' in rest ? rest.inviteService : identity);
  const analytics = useAnalytics();

  useEffect(() => {
    if (emailInputError) {
      const removeErrorText = setTimeout(() => {
        setEmailInputError(false);
      }, ERROR_TIMEOUT);
      return () => {
        clearTimeout(removeErrorText);
      };
    }
  }, [emailInputError]);

  return (
    <Grid container item spacing={2}>
      {inviteFeatureOn && (
        <Grid item xs={12}>
          <PhoneAndEmailInvites
            label="Invite via email or SMS"
            placeholder="example@illumenotes.com"
            onSendInvitation={query.execute}
            error={query.error}
            loading={query.loading}
            sent={query.status === 'success'}
            reset={query.reset}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CopyLink
          label={inviteFeatureOn ? 'Invite via shareable link' : 'Invite Contributors'}
          background="main"
          link={link}
          onClick={() => {
            analytics.track(EVENT_NAMES.INVITE_CONTRIBUTORS_MY_CARDS.name, {
              noteCount: card.notes.length,
            });
          }}
          spelledNames={spelledNames}
        />
      </Grid>
    </Grid>
  );
};
