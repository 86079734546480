import { useEffect } from 'react';

import { useStores } from 'contexts/store';

export const useUserSummary = ({ onError } = {}) => {
  const { userProfileStore } = useStores();
  const { isExecuting, error, execute } = userProfileStore.summaryTask;
  const useSummaryDto = userProfileStore.userSummaryDto;

  useEffect(() => {
    userProfileStore.fetchUserSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: isExecuting && !useSummaryDto,
    userSummaryDto: useSummaryDto,
    error,
    fetchUser: () => execute().catch(onError),
  };
};
