import { IThemeService } from 'domain/interfaces/IThemeService';
import { ThemepackDTO } from 'types';

import mockThemepacks from './themepacks.json';

class MockThemeService implements IThemeService {
  /**
   * fake service to mimic getting themepacks from an external resource
   * @returns Promise
   */
  getThemepacks = () =>
    new Promise<ThemepackDTO[]>((resolve) => {
      setTimeout(() => {
        resolve(mockThemepacks);
      }, 0);
    });
}

export const mockThemeService = new MockThemeService();
