import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import qs from 'query-string';
import { useForm } from 'react-hook-form';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { GoogleLogin } from 'components/google-login';
import Button from 'components/illume/buttons/Button'; // eslint-disable-line import/no-unresolved
import { Link } from 'components/illume/link';
import { Modal } from 'components/illume/modal';
import { TextInput } from 'components/illume/text-inputs';
import LinedText from 'components/illume/text/LinedText';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { rem, spacing, colors } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import {
  TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
  MY_CARDS_URL,
  LOGIN_EMAIL_URL,
  SAVE_THE_DATE_URL,
  TEAMS_URL,
  INITIATOR_BASIC_INFO_URL,
  INITIATOR_MARKETPLACE_URL,
  INITIATOR_PAYWALL_URL,
  SLACK_APP_PLANS,
  PROFILE_URL,
} from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useStores } from 'contexts/store';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { wait } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';
import { isValid } from 'utils/form';
import logger from 'utils/logger';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(6.5)}px`,
    maxWidth: 500,
    marginTop: theme.spacing(16),
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: 350,
      marginTop: theme.spacing(15),
    },
  },
  text: {
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  policyText: {
    textAlign: 'center',
    paddingLeft: spacing[5],
    paddingRight: spacing[5],
    marginTop: theme.spacing(2),
  },
  welcomeText: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  googleButton: {
    fontSize: rem[1000],
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1125],
    },
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  dividerText: {
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1125],
    },
    marginTop: theme.spacing(4),
    fontSize: '1.125rem',
  },
  continueButton: {
    marginTop: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(3),
  },
}));

interface LoginProps {
  showEmailForm: boolean;
}
const Login = ({ showEmailForm }: LoginProps) => {
  const { authenticationStore, marketplaceStore } = useStores();
  const history = useHistory();
  const location = useLocation();
  const analytics = useAnalytics();
  const from = location?.state?.from as string | undefined;
  const fromSearch = new URLSearchParams(location.search);
  const { userEmail, autoSubmit } = qs.parse(location.search) || {};
  const [httpErrMessage, setHttpErrMessage] = useState<string | undefined>(undefined);
  const [googleErr, setGoogleErr] = useState({});
  const [errModal, setErrModal] = useState(false);
  const [status, setStatus] = useState('idle');

  console.log('location', location);

  const saveDatePattern = new RegExp(`${SAVE_THE_DATE_URL}`);
  const cardDetailsMatch =
    from && matchPath<{ cardCode: string }>(from, { path: `${MY_CARDS_URL}/:cardCode` });
  const fromCardDetails = cardDetailsMatch && !!cardDetailsMatch;
  const fromSaveDate = from ? saveDatePattern.test(from) : false;

  const slackPaymentMatch = from && matchPath(from, { path: SLACK_APP_PLANS });
  const fromSlackPayment = slackPaymentMatch && !!slackPaymentMatch;

  const fromUserProfile = from && matchPath(from, { path: PROFILE_URL });

  const fromMarketplaceRoutes =
    from &&
    !!matchPath(from, {
      path: INITIATOR_MARKETPLACE_URL,
    });

  const fromTeams = from
    ? !!matchPath(from, {
        path: TEAMS_URL,
        exact: true,
        strict: false,
      })
    : false;

  const fromPaywall = !!fromSearch.get('from');

  useEffect(() => {
    logger.log({
      cardDetailsMatch,
      fromCardDetails,
      fromSaveDate,
      fromTeams,
      fromMarketplaceRoutes,
      fromSearch,
      fromPaywall,
      from,
      fromSlackPayment,
    });
  }, [
    cardDetailsMatch,
    from,
    fromCardDetails,
    fromMarketplaceRoutes,
    fromPaywall,
    fromSaveDate,
    fromSearch,
    fromTeams,
    fromSlackPayment,
  ]);

  const replyFormSchema = yup.object().shape({
    email: yup.string().required('please provide your email').email('must be a valid email'),
    name: yup.string().required('please provide your name'),
  });

  console.log('slackPaymentmatch', slackPaymentMatch);
  console.log('state', location.state);

  const {
    handleSubmit,
    register,
    errors,
    trigger,
    formState: { isSubmitted, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(replyFormSchema),
    defaultValues: { email: userEmail },
  });

  const sendToEmailLogin = () => {
    // persist state
    history.push(
      { pathname: LOGIN_EMAIL_URL, search: window.location.search },
      { from, search: window.location.search },
    );
  };

  const sendToOnSuccess = () => {
    // from marketplace flow
    if (fromMarketplaceRoutes) {
      logger.log('they are from marketplace store');
      // if the user has choosen their gift
      if (marketplaceStore?.form?.data?.merchantProductDto) {
        // no need to re-select their gift
        // redirect straight to basic info
        return history.push(INITIATOR_BASIC_INFO_URL);
      } else {
        // redirect page to the page
        return history.push(from);
      }
    }

    if (fromTeams) {
      return history.push(from);
    }

    if (fromPaywall) {
      const paywalUrl = fromSearch.get('from') || INITIATOR_PAYWALL_URL;
      return history.push(paywalUrl);
    }

    if (fromSlackPayment) {
      const slackPaymentUrl = fromSearch.get('from') || SLACK_APP_PLANS;
      return history.push(slackPaymentUrl + location.state.search || '');
    }

    // extract params
    if (fromCardDetails || fromSaveDate || fromUserProfile) {
      const cardCode = cardDetailsMatch && cardDetailsMatch.params.cardCode;
      logger.log('do something with card code: ', cardCode);
      history.replace(from);
    } else {
      // delay for a bit to allow the intro card to get initialized!
      // Update: not sure if we still need to wait!
      wait(500).then(() =>
        history.push({
          pathname: MY_CARDS_URL,
        }),
      );
    }
  };

  const handleGoogleLoginSuccess = async ({ tokenId }) => {
    setStatus('authenticating');
    try {
      await authenticationStore.googleLogin(tokenId);
      sendToOnSuccess();
      setStatus('authenticated');
    } catch (error) {
      setHttpErrMessage(error.message);
      setErrModal(true);
      setStatus('error');
    }
  };

  const GOOGLE_ERRORS = {
    IDPIFRAME_INITIALIZATION_FAILED: 'idpiframe_initialization_failed', // initialization of the Google Auth API failed (this will occur if a client doesn't have [third party cookies enabled](https://github.com/google/google-api-javascript-client/issues/260))
    POPUP_CLOSED_BY_USER: 'popup_closed_by_user', // The user closed the popup before finishing the sign in flow.
    ACCESS_DENIED: 'access_denied', // The user denied the permission to the scopes required
    IMMEDIATE_FAILED: 'immediate_failed', // No user could be automatically selected without prompting the consent flow.
  };

  const handleGoogleLoginFailure = ({ error, details }) => {
    logger.error(error, { error, details });
    // this one is user initiated - so not an error!
    // this can be deceiving as sometimes the user does do anything but the browser closes it on their behalf!
    if (error === GOOGLE_ERRORS.POPUP_CLOSED_BY_USER) return;

    setGoogleErr({ error, details });
    analytics.track(EVENT_NAMES.ERROR_GOOGLE_LOGIN.name, { error, details });
    if (error === GOOGLE_ERRORS.IMMEDIATE_FAILED) {
      setErrModal(true);
      setHttpErrMessage(
        "sorry, sign in with google isn't working right now. This sometimes occurs when third party cookie setting is disabled",
      );
    }
  };

  const showGoogle =
    !showEmailForm &&
    checkFF(FLAGS.GOOGLE_SIGN_IN) === true &&
    // hide component if third party cookie is disabled
    // alternative: a disabled state on the button, and show a tooltip to enable third party cookie
    googleErr.error !== GOOGLE_ERRORS.IDPIFRAME_INITIALIZATION_FAILED;

  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const { verificationStore } = useStores();
  const onSubmit = async ({ email, name }) => {
    return verificationStore
      .beginVerification(email, name)
      .then(sendToOnSuccess)
      .catch((e) => enqueueErrorSnackbar(e?.message || 'something went wrong during verification'));
  };

  useEffect(() => {
    if (userEmail && autoSubmit) {
      onSubmit({ email: userEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IllumeLayout
        background={colors.primary}
        hideHamburger={!desktop}
        contentProps={{ justifyContent: 'center' }}
      >
        <Grid container direction="column" className={classes.container} item>
          <Grid container direction="column" className={classes.text}>
            <Grid item>
              <Text
                color={colors.contrastText}
                fontSize={{ mobile: rem[2250], desktop: rem[3000] }}
                fontWeight={900}
              >
                hello, sunshine.
              </Text>
            </Grid>
            {status !== 'authenticating' && (
              <Grid item className={classes.welcomeText}>
                <Text color={colors.contrastText} fontSize={rem[1125]}>
                  Welcome to illume. {showGoogle && <>To continue, sign in with Google or email.</>}
                </Text>
              </Grid>
            )}
          </Grid>
          {showEmailForm ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item container className={classes.form} direction="column" spacing={4}>
                <Grid item container spacing={2} direction="column">
                  <Grid item>
                    <TextInput
                      inputId="name"
                      name="name"
                      data-testid="name"
                      label="Your name"
                      ref={register}
                      placeholder="John Doe"
                      error={errors?.name}
                      errorMessage={errors?.name?.message}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      inputId="email"
                      name="email"
                      data-testid="email"
                      label="Email Address"
                      ref={register}
                      placeholder="jack@illumenotes.com"
                      error={errors?.email}
                      errorMessage={errors?.email?.message}
                    />
                  </Grid>
                </Grid>

                <Grid item onClick={() => trigger()}>
                  <Button
                    loading={isSubmitting}
                    disabled={!isValid(errors) && isSubmitted}
                    fullWidth
                    customcolor="primaryShaded"
                    type="submit"
                  >
                    <Text
                      color={colors.contrastText}
                      fontSize={{ desktop: rem[1125], mobile: rem[1000] }}
                    >
                      next
                    </Text>
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <>
              {showGoogle && status !== 'authenticating' && (
                <>
                  <Grid className={classes.googleButton}>
                    <GoogleLogin
                      onFailure={handleGoogleLoginFailure}
                      onSuccess={handleGoogleLoginSuccess}
                    />
                  </Grid>
                  <Grid className={classes.dividerText}>
                    <LinedText
                      textBackground={colors.primary}
                      color={colors.labelText}
                      fontWeight={500}
                      text="or"
                    />
                  </Grid>
                </>
              )}
              {status === 'authenticating' && (
                <Text fontSize={24} align="center" color={colors.contrastText}>
                  authenticating...
                </Text>
              )}
              <Grid className={classes.continueButton}>
                <Button fullWidth customcolor="primaryShaded" onClick={sendToEmailLogin}>
                  <Text
                    color={colors.contrastText}
                    fontSize={{ desktop: rem[1000], mobile: rem[1000] }}
                  >
                    Continue with Email
                  </Text>
                </Button>
              </Grid>
              <Grid
                container
                className={classes.policyText}
                direction="row"
                justifyContent="center"
              >
                <Text color={colors.gray40}>by continuing, I agree to illume’s &nbsp;</Text>
                <Link href={TERMS_OF_SERVICE_URL} underline>
                  terms of service
                </Link>
                <Text color={colors.gray40}>&nbsp; and &nbsp;</Text>
                <Link href={PRIVACY_POLICY_URL} underline>
                  privacy policy
                </Link>
                <Text color={colors.gray40}>.</Text>
              </Grid>
            </>
          )}
        </Grid>
      </IllumeLayout>

      <Modal
        show={errModal}
        title={'Oops!'.toUpperCase()}
        onClose={() => setErrModal(false)}
        promptConfig={{
          onOk: () => setErrModal(false),
          okText: 'close',
        }}
      >
        <Text align="center" fontWeight={300}>
          {httpErrMessage}
        </Text>
      </Modal>
    </>
  );
};

export default observer(Login);
