import { action, makeAutoObservable, reaction } from 'mobx';

import OccasionStore, { Occasion } from 'contexts/store/occasion-store/Occasion.store';
import { OccasionCategory, OccasionType } from 'domain/interfaces/IOccasionService';

type PeopleType = { text: string; value: OccasionCategory };

type State = {
  peopleType: PeopleType;
};

export default class CelebrateMoreStore {
  peopleOptions: PeopleType[] = [
    { text: 'Family', value: 'familyOccasions' },
    { text: 'Friends', value: 'friendsOccasions' },
    { text: 'Colleagues', value: 'colleaguesOccasions' },
  ];
  peopleType: PeopleType;
  selectedOccasion?: OccasionType;
  get currentOccasions() {
    if (!this.occasionStore.occasionsDTO) {
      return [];
    }
    return this.occasionStore.occasionsDTO[this.peopleType?.value].map((dto) => new Occasion(dto));
  }
  constructor(initialState: State, private occasionStore: OccasionStore) {
    this.peopleType = initialState.peopleType;
    makeAutoObservable(this);
    reaction(
      () => this.peopleType,
      action(() => (this.selectedOccasion = undefined)),
    );
  }
}
