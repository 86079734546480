import { ButtonBase, CircularProgress, Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Modal } from 'components/illume/modal';
import { Surface } from 'components/illume/surface';
import { Text } from 'components/illume/text';
import { Spinner } from 'components/spinner';
import { rem, colors, spacing } from 'constants/design';
import { useChunk } from 'hooks/illume/useChunk';

import { suggestionModalStyles } from './SuggestionModal.styles';

const SuggestionList: React.FC<{
  renderedData: string[];
  handleSelectMessage: (msg: string) => any;
  hasMore: boolean;
  loadMore: () => Promise<void>;
}> = ({ handleSelectMessage, renderedData = [], hasMore, loadMore }) => {
  const classes = suggestionModalStyles();
  return (
    <InfiniteScroll
      className={classes.suggestionList}
      dataLength={renderedData.length}
      hasMore={hasMore}
      next={loadMore}
      loader={
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      }
      height={400}
    >
      {renderedData.map((text, index) => (
        <ButtonBase
          key={index}
          onClick={() => handleSelectMessage(text)}
          className={classes.suggestion}
        >
          <Surface
            className={classes.text}
            borderRadius={6}
            py={1}
            px={2}
            rounded={['bottom', 'top']}
          >
            <Text
              fontWeight={400}
              fontSize={{ mobile: rem[875], desktop: rem[1000] }}
              color={colors.gray100}
            >
              {text}
            </Text>
          </Surface>
        </ButtonBase>
      ))}
    </InfiniteScroll>
  );
};

export interface SuggestionModalProps {
  suggestions?: string[];
  onSelect: (msg: string) => any;
  onClose: () => any;
  show: boolean;
  error?: Error | null;
  loading: boolean;
}

export const SuggestionModal: React.FC<SuggestionModalProps> = ({
  suggestions = [],
  onSelect = () => {},
  onClose = () => {},
  show = false,
  loading = true,
  error = undefined,
}) => {
  const SUGGESTION_CHUNKS_LENGTH = 30; // anything smaller will cause performance issue
  const { loadMore, renderedData, hasMore, reset } = useChunk<string>({
    data: suggestions,
    chunkLength: SUGGESTION_CHUNKS_LENGTH,
    fakeLoading: true,
    delay: 250,
  });

  const handleSelectMessage = (msg: string) => {
    onSelect(msg);
    onClose();
  };

  const ErrorDisplay = () => (
    <Text color={colors.errorText}>sorry our suggestions didn't load correctly</Text>
  );

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      onClose={handleClose}
      promptConfig={{ okText: 'close', onOk: handleClose }}
      show={show}
      buttonTextProps={{
        fontWeight: 700,
        fontSize: rem[1125],
      }}
    >
      <Grid item container direction="column">
        <Grid item>
          <Text
            fontWeight={900}
            fontSize={{ mobile: rem[1375], desktop: rem[2000] }}
            align="center"
          >
            INSPIRATION LIBRARY
          </Text>
        </Grid>
        <Grid item>
          <Text fontWeight={300} align="center">
            tap a message to add it to your note.
          </Text>
        </Grid>
        <Grid item container justify="center">
          {loading ? (
            <Grid item style={{ marginTop: spacing[5] }}>
              <Spinner className={undefined} />
            </Grid>
          ) : !error && suggestions ? (
            <SuggestionList
              renderedData={renderedData}
              hasMore={hasMore}
              loadMore={loadMore}
              handleSelectMessage={handleSelectMessage}
            />
          ) : (
            <Surface borderRadius={6} py={1} px={2} rounded={['bottom', 'top']}>
              <ErrorDisplay />
            </Surface>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};
