export type OccasionCategory =
  | 'specialOccasions'
  | 'colleaguesOccasions'
  | 'friendsOccasions'
  | 'familyOccasions'
  | 'occasionTypes';
export enum OccasionType {
  ANNIVERSARY = 'Anniversary',
  BABY_SHOWER = 'Baby Shower',
  BIRTHDAY = 'Birthday',
  CONGRATULATIONS = 'Congratulations',
  FAREWELL = 'Farewell',
  GRADUATION = 'Graduation',
  JUST_BECAUSE = 'Just Because',
  THANK_YOU = 'Thank You',
  THINKING_OF_YOU = 'Thinking of You',
  WEDDING = 'Wedding',
  OTHER = 'Other',
  MOTHER_FATHERS_DAY = 'Mothers/fathers day',
  BIRTH = 'Birth',
  GET_WELL_SOON = 'Get well soon',
  NEW_JOB = 'New job',
  CELEBRATE_MILESTONE = 'Celebrate Milestone',
  WELCOME_TEAM_MEMBER = 'Welcome team member',
  GREAT_COLLEAGUE = 'Great colleague',
  HARD_WORK = 'Hard work',
  CHRISTMAS = 'Christmas',
  MOTHERS_DAY = "Mother's Day",
  CELEBRATION_OF_LIFE_DAY = 'Celebration of Life Day',
  VALENTINES = 'Valentines',
  INTERNATIONAL_COWORKING_DAY = 'International Coworking Day',
  NEW_YEARS = 'New Years',
  MEMORIAL_DAY = 'Memorial',
  FATHERS_DAY = "Father's Day",
  FATHER_IN_LAW_DAY = 'Father-in-law Day',
  WORLD_LISTENING_DAY = 'World Listening Day',
  PARENTS_DAY = "Parent's Day",
  ENGAGEMENT = 'Engagement',
}

export interface IOccasionService {
  getOccasions(): Promise<Record<OccasionCategory, OccasionType[]>>;
  addOccasion(occasions: string): Promise<boolean>;
}
