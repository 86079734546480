import { colors } from 'constants/design';

export default function Warn({ color = colors.alertRed }) {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.4695 10.5421L25.9579 1.03053C25.6547 0.689474 25.2 0.5 24.7074 0.5H11.2926C10.8 0.5 10.3453 0.689474 10.0421 1.03053L0.530526 10.5421C0.189474 10.8453 0 11.3 0 11.7926V25.2453C0 25.7 0.189474 26.1547 0.530526 26.4579L10.0421 35.9695C10.3832 36.3105 10.8379 36.5 11.2926 36.5H24.7453C25.2 36.5 25.6547 36.3105 25.9958 35.9695L35.4695 26.4579C35.8105 26.1168 36 25.6621 36 25.2074V11.7926C36 11.3 35.8105 10.8453 35.4695 10.5421ZM18 7.47263C19.44 7.47263 20.6147 8.68526 20.5389 10.1632L20.0463 20.0158C20.0084 21.1147 19.0989 21.9484 18 21.9484C16.9011 21.9484 16.0295 21.1147 15.9537 20.0158L15.4611 10.1632C15.3853 8.68526 16.56 7.47263 18 7.47263ZM18 29.5274C16.5221 29.5274 15.3095 28.3147 15.3095 26.8368C15.3095 25.3589 16.5221 24.1463 18 24.1463C19.4779 24.1463 20.6905 25.3589 20.6905 26.8368C20.6905 28.3147 19.4779 29.5274 18 29.5274Z"
        fill={color}
      />
    </svg>
  );
}
