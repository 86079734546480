import { useEffect, useState } from 'react';

import { ContributorCardDTO, InitiatorCardViewDTO, NoteDTO, PaletteDTO } from '@illume/shared';
import { makeAutoObservable, when } from 'mobx';
import { observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory, useParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { IllumeLayout } from 'components/layouts';
import { Loading } from 'components/Loading';
import { NoteFormRawValues } from 'components/reusable-forms/note-form/NoteForm';
import { DEFAULT_THEMEPACK, generateBackgroundFromPalette } from 'constants/design/cardTheme';
import { APPBAR_ICONS, PREVIEW_TOOLTIP_TEXT } from 'constants/types';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { CreateNoteOptions, INoteService } from 'domain/interfaces/INoteService';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import cardService, { CodeType, determineCodeType } from 'infra/card-service/cardService';
import { SelectThemeForm } from 'pages/illume/select-theme-form';
import logger from 'utils/logger';
import { spellNames } from 'utils/string';

import { NoteForm } from '../../../components/reusable-forms/note-form';

const FORM_STEPS = {
  NOTE: 'NOTE',
  THEME: 'THEME',
};

class EditNoteStore {
  noteFormValues: NoteFormRawValues;
  setNoteFormvalues(value: NoteFormRawValues) {
    this.noteFormValues = value;
  }
  palette: PaletteDTO;
  setPalette(p: PaletteDTO) {
    this.palette = p;
  }
  updateNote = () => {
    return this.noteService.updateNote(this.note.viewCode, this.updateDto);
  };

  private get updateDto() {
    const { photos = [], videos = [], gifs = [] } = this.noteFormValues;

    const photoUuids = photos.map((photo) => photo.photoCode);
    const videoUuids = videos.map((video) => video.videoCode);
    const gifCodes = gifs.map((gif) => gif.gifCode);

    const payload: CreateNoteOptions = {
      ...this.noteFormValues,
      palette: this.palette.paletteCode,
      photos: photoUuids,
      videos: videoUuids,
      gifs: gifCodes,
      isPublic: this.noteFormValues.isPublic,
    };

    return payload;
  }
  constructor(palette: PaletteDTO, private note: NoteDTO, private noteService: INoteService) {
    makeAutoObservable(this);
    this.palette = palette;
    this.noteFormValues = {
      anonymous: !note.signature,
      isPublic: note.isPublic,
      message: note.message,
      gifs: note.gifs,
      photos: note.photos,
      videos: note.videos,
      signature: note.signature,
    };
  }
}

const EditNoteContent: React.FC<{
  note: NoteDTO;
  card: InitiatorCardViewDTO | ContributorCardDTO;
  noteService: INoteService;
}> = observer(({ note, card, noteService }) => {
  const history = useHistory();
  const [formStep, setFormStep] = useState(FORM_STEPS.NOTE);
  const {
    themepackStore: { getThemepack, getPaletteDto: getPalette },
  } = useStores();

  const [store] = useState(() => new EditNoteStore(getPalette(note.palette), note, noteService));

  const desktop = useIsDesktop();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const analytics = useAnalytics();
  const { suggestionService } = useServices();

  const { message, signature, photos, videos, gifs } = store.noteFormValues || {};

  const themepack = getThemepack(note.themepack);

  const palettes = themepack?.palettes || DEFAULT_THEMEPACK.palettes;
  const { recipientData: recipientData = [], occasionName, inviteCode } = card || {};
  const mainColor = generateBackgroundFromPalette(store.palette, undefined, { isMobile: !desktop });
  const { loading, execute: handleFinalSubmission } = useAsyncCallback(store.updateNote, {
    onError: (e) => enqueueErrorSnackbar(`something went wrong: ${e.message}`),
    onSuccess: (note) => {
      logger.log({ note });
      analytics.track('submit note - edit note', {
        noteLength: store.noteFormValues,
        anonymous: !store.noteFormValues.signature,
      });
      enqueueSuccessSnackbar(`woohoo! your note has been updated.`);
      return history.back();
    },
  });

  const handleThemeBack = () => {
    setFormStep(FORM_STEPS.NOTE);
  };

  const handleDraftSubmissionSuccess = (note: NoteFormRawValues) => {
    analytics.track('draft submission - edit note', { noteLength: note.message.length });
    store.setNoteFormvalues(note);
    setFormStep(FORM_STEPS.THEME);
  };

  const handleNoteformBack = () => {
    history.back();
  };

  return (
    <IllumeLayout
      background={mainColor}
      hideHamburger={true}
      topRightButtons={
        formStep === FORM_STEPS.THEME
          ? [
              {
                tooltipText: PREVIEW_TOOLTIP_TEXT,
                icon: <APPBAR_ICONS.PREVIEW size={desktop ? 51 : 44} />,
                action: {
                  onAction: () => setShowPreviewModal(true),
                },
              },
            ]
          : null
      }
    >
      {/* step 1 form */}
      {formStep === FORM_STEPS.NOTE && (
        <NoteForm
          suggestionService={suggestionService}
          role={card.userContext.role}
          analytics={analytics}
          draftValidationService={(val) =>
            noteService.createNoteDraft(note?.viewCode || inviteCode, {
              ...val,
              photos: val.photos && val.photos.map((p) => p.photoCode),
              videos: val.videos && val.videos.map((v) => v.videoCode),
              gifs: val.gifs && val.gifs.map((gif) => gif.gifCode),
            })
          }
          theme={note.theme}
          onBack={handleNoteformBack}
          recipientName={spellNames(recipientData)}
          defaultValues={store.noteFormValues}
          onSubmitSuccess={handleDraftSubmissionSuccess}
          occasionName={occasionName}
          palette={store.palette}
        />
      )}

      {formStep === FORM_STEPS.THEME && (
        <SelectThemeForm
          submitting={loading}
          message={message}
          signature={signature}
          palettes={palettes}
          onPaletteChange={(palette) => store.setPalette(palette)}
          value={store.palette}
          onBack={handleThemeBack}
          photos={photos}
          videos={videos}
          gifs={gifs}
          onSubmit={handleFinalSubmission}
          setShowPreviewModal={setShowPreviewModal}
          showPreviewModal={showPreviewModal}
        />
      )}
    </IllumeLayout>
  );
});

const EditNote: React.FC = () => {
  const { noteCode, cardCode } = useParams<{ noteCode: string; cardCode: string }>();
  const history = useHistory();
  const { noteService } = useServices();

  const [fp] = useState(() =>
    fromPromise(
      Promise.all([
        noteService.getNote(noteCode),
        match(determineCodeType(cardCode))
          .with(CodeType.InviteCode, () => cardService.getContributorCard(cardCode))
          .with(CodeType.ViewCode, () => cardService.getInitiatorCard(cardCode))
          .with(CodeType.UUIDRecipientOrContributorInvite, () =>
            cardService.getContributorCard(cardCode),
          )
          .with(undefined, () => Promise.reject('Invalid Card Code'))
          .exhaustive(),
      ]),
    ),
  );

  const { enqueueErrorSnackbar } = useIllumeSnackbar();

  useEffect(() => {
    const dispose = when(
      () => fp.state === 'rejected',
      () => {
        enqueueErrorSnackbar(`something went wrong when preparing data for edit, e: ${fp.value}`);
        history.back();
      },
    );

    return dispose();
  }, []);

  return fp.case({
    fulfilled: ([note, card]) => {
      return <EditNoteContent card={card} note={note} noteService={noteService} />;
    },
    pending: () => <Loading text="preparing card for edit.." />,
    rejected: () => null,
  });
};

export default observer(EditNote);
