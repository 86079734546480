import logger from './logger';

export function spellNames(recipients: { name: string }[]) {
  if (recipients.length === 0) return '';
  if (recipients.length === 1) return recipients[0].name;
  if (recipients.length === 2) return `${recipients[0].name} & ${recipients[1].name}`;
  if (recipients.length > 2) return `${recipients[0].name} and +${recipients.length - 1} others`;

  return '';
}

export function showNames(recipients: { name: string }[] | undefined | null): string {
  if (!recipients) {
    return '';
  }

  if (recipients.length === 0) {
    logger.error('missing names');
    return '';
  }
  if (recipients.length === 1) return recipients[0].name;
  if (recipients.length === 2) return `${recipients[0].name} & ${recipients[1].name}`;

  if (recipients.length > 2) {
    const manyRecipientNames = recipients
      .map((val, idx) => {
        if (idx === recipients.length - 1) {
          return `& ${val.name}`;
        }
        return val.name;
      })
      .join(', ');
    return manyRecipientNames;
  }
  return '';
}

export function showContactInfos(
  recipients: { recipientEmail: string | void; recipientPhone: string | void }[],
): string | void {
  if (!recipients) {
    logger.error('missing contact infos');
    return;
  }
  const contactInfos = recipients
    .map((recipient) => {
      // Backend type is either email or phone, so if email is defined phone is not
      if (recipient.recipientEmail) {
        return recipient.recipientEmail;
      }
      return recipient.recipientPhone;
    })
    .join(', ');

  return contactInfos;
}
