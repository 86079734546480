import random from 'lodash/random';

export type Schema = {
  firstName: string;
  email: string;
  deadline: Date;
};

class Random {
  readonly names = ['Jane Smith', 'Eric Cohen', 'Alexander Hildale', 'Sarah Li'];
  readonly domain = ['gmail', 'tryillume', 'yahoo', 'tryillume'];
  getRandomName(index?: number) {
    const i = index ?? random(0, this.names.length - 1, false);
    return this.names[i];
  }
  getRandomEmailDomain(index = undefined) {
    const i = index ?? random(0, this.names.length - 1, false);
    return this.domain[i];
  }
  randomDate(futureYearCount = 1) {
    const yearAhead = new Date(new Date().setFullYear(new Date().getFullYear() + futureYearCount));
    const start = new Date();
    return new Date(start.getTime() + Math.random() * (yearAhead.getTime() - start.getTime()));
  }
}

const mockData: Schema[] = Array.from({ length: 3 }).map((_, i) => {
  const r = new Random();
  const randomName = r.getRandomName(i);
  const randomDomain = r.getRandomEmailDomain();
  const email = `${randomName.split(' ')[0]}@${randomDomain}.com`;
  return {
    deadline: r.randomDate(),
    email: email,
    firstName: randomName,
  };
});

export default mockData;
