import { makeStyles } from '@material-ui/core/styles';

import { borderRadius, colors, rem } from 'constants/design';
const useStyles = makeStyles((theme) => {
  return {
    root: ({ compact, fullWidth }) => {
      const compactStyles = compact
        ? {
            paddingBottom: 0,
            padding: 0,
            fontSize: 14,
            alignItems: 'center',
            maxHeight: 40,
            paddingTop: 5,
            overflowY: 'hidden',
          }
        : null;
      return {
        flexWrap: 'unset',
        fontSize: 16,
        fontWeight: 500,
        background: colors.backgroundGray,
        borderTopLeftRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        padding: 6,
        paddingTop: 4,
        paddingBottom: 4,
        marginRight: 40,
        overflowX: 'scroll',
        // safari cropped fix
        width: fullWidth ? '100%' : undefined,
        ...compactStyles,
      };
    },
    label: {
      color: colors.labelText,
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        fontSize: rem[1000],
      },
    },
    icon: {},
    sendIcon: {
      position: 'absolute',
      whiteSpace: 'nowrap',
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      zIndex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexWrap: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '50%',
      right: 0,
      background: colors.alertGreen,
      height: '100%',
      width: 36,
      transform: 'translateY(-50%)',
    },
  };
});

export default useStyles;
