import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      borderRadius: 16,
      marginTop: theme.spacing(12),
    },
  },
  headingText: {
    width: 329,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 998,
    },
  },
  paragraphBox: {
    width: 278,
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 612,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      margin: 'unset',
    },
  },
  willRemindYou: {
    marginBottom: theme.spacing(4),
  },
  textLink: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  parrentBox: {
    width: '92%',
    margin: 'auto',
    borderRadius: 16,
    background: colors.backgroundGray70,
    marginTop: theme.spacing(5),
    padding: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 1016,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginBottom: 0,
    },
  },
  buttonContainer: {
    width: '92%',
    margin: 'auto',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 1016,
      marginBottom: 0,
    },
  },
  selectOccasion: {
    marginBottom: theme.spacing(2),
  },
  gridBox: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 100px)',
    gridAutoRows: '100px',
    gridGap: 10,
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, 130px)',
      gridAutoRows: '130px',
      width: '988px',
      margin: 'unset',
      gridGap: 15,
    },
  },
  iconContainer: {},
  gridItem: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '9px',
    display: 'flex',
    alignItems: 'stretch',
    color: '#565659',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    transitionProperty: 'background',
    paddingBottom: theme.spacing(1),
    border: `3px solid transparent`,
    background: colors.background,
  },
  activeCard: {
    background: colors.primary,
    color: colors.background,
    '& .accent': {
      fill: colors.background,
    },
  },
  notActiveCard: {
    '& .accent': {
      fill: colors.primary,
    },
    background: colors.background,
    '&:hover': {
      border: `3px solid ${colors.primary}`,
    },
  },
  thumbIcon: {
    width: 31.72,
    height: 30.74,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      width: 37.64,
      height: 42.09,
    },
  },
  sendReminderBtn: {
    [theme.breakpoints.up('md')]: {
      width: 258,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
    },
  },
  checklistContainer: {
    position: 'absolute',
    top: 7,
    right: 7,
  },
  labelContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15%',
    rowGap: 5,
    // justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: '20%',
      rowGap: 10,
    },
  },
  inputChecked: {
    display: 'none',
  },
}));
