import { FunctionComponent } from 'react';

interface ThreePeopleProps {
  color: string;
  size: number;
}

const ThreePeople: FunctionComponent<ThreePeopleProps> = ({
  color = 'rgba(40, 40, 40, 0.6)',
  size = 26,
  opacity = 1,
  ...props
}) => {
  return (
    <svg
      width={size}
      opacity={opacity}
      height={(17 * size) / 26}
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.54432 3.88859C8.54432 5.59453 7.16142 6.97738 5.45551 6.97738C3.74956 6.97738 2.3667 5.59449 2.3667 3.88859C2.3667 2.18274 3.7496 0.799805 5.45551 0.799805C7.16137 0.799805 8.54432 2.1827 8.54432 3.88859Z"
        fill={color}
      />
      <path
        d="M9.38923 9.3799C8.80848 8.42952 7.77886 7.7959 6.59084 7.7959H4.29404C2.63079 7.7959 1.23164 9.03665 1.04684 10.6735L0.729994 13.3663C0.677229 13.7095 0.914759 14.0263 1.25799 14.1055C2.57799 14.3959 3.97719 14.5279 5.45559 14.5279C6.19484 14.5279 6.90759 14.5015 7.62035 14.4223L7.91072 12.1255C8.04272 11.0431 8.59719 10.0663 9.38919 9.3799L9.38923 9.3799Z"
        fill={color}
      />
      <path
        d="M24.1738 3.88859C24.1738 5.59453 22.7909 6.97738 21.0849 6.97738C19.3791 6.97738 17.9961 5.59449 17.9961 3.88859C17.9961 2.18274 19.379 0.799805 21.0849 0.799805C22.7909 0.799805 24.1738 2.1827 24.1738 3.88859Z"
        fill={color}
      />
      <path
        d="M25.4938 10.6989C25.309 9.06213 23.9098 7.82129 22.2466 7.82129H19.9498C18.7618 7.82129 17.7322 8.45491 17.1514 9.40529C17.9697 10.0653 18.5241 11.042 18.6561 12.1509L18.9465 14.4477C19.6593 14.5269 20.3721 14.5533 21.1113 14.5533C22.5633 14.5533 23.9889 14.3949 25.3089 14.1309C25.6521 14.0517 25.8632 13.7349 25.8369 13.3917L25.4938 10.6989Z"
        fill={color}
      />
      <path
        d="M14.4047 9.37988H12.1079C10.4447 9.37988 9.04552 10.6207 8.86072 12.2575L8.54396 14.924C8.49119 15.2672 8.72872 15.6103 9.07196 15.6632C10.392 15.9536 11.8176 16.0856 13.2696 16.0856C14.7216 16.0856 16.1472 15.9272 17.4672 15.6632C17.8104 15.584 18.0215 15.2672 17.9952 14.924L17.6784 12.2575C17.4671 10.6207 16.0678 9.37988 14.4048 9.37988H14.4047Z"
        fill={color}
      />
      <path
        d="M16.3583 5.44622C16.3583 7.15217 14.9754 8.53502 13.2695 8.53502C11.5636 8.53502 10.1807 7.15212 10.1807 5.44622C10.1807 3.74036 11.5636 2.35742 13.2695 2.35742C14.9754 2.35742 16.3583 3.74032 16.3583 5.44622Z"
        fill={color}
      />
    </svg>
  );
};

export default ThreePeople;
