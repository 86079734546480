import { alpha, makeStyles } from '@material-ui/core/styles';

import { colors, rem, spacing } from 'constants/design';

export const textBoxStyles = makeStyles((theme) => ({
  root: ({ shadow, shadowColor, dark, minHeight, color }) => ({
    background: dark ? colors.backgroundGray60 : colors.background,
    fontWeight: 500,
    borderRadius: 6,
    padding: '8px 16px',
    minHeight,
    boxShadow: shadow ? `6px 6px 0px 0px ${shadowColor}` : 'none',
    color: color ? color : dark ? colors.gray60 : colors.gray100,
  }),
  label: ({ dark }) => ({
    color: dark ? alpha(colors.gray40, 0.3) : colors.labelText,
    fontSize: rem[875],
    lineHeight: '22px',
    fontWeight: 500,
    marginBottom: spacing[1],
    display: 'block',
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1000],
    },
  }),
  text: ({ overflow }) => ({
    overflowX: overflow,
  }),
}));
