import { createStyles, makeStyles } from '@material-ui/core/styles';

export const cardVariants = (delay: number) => ({
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'backOut',
      delay: delay + custom * 0.14,
      duration: 0.6,
    },
  }),
  out: (custom: number) => ({
    y: 50,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'backIn',
      delay: custom * 0.04,
      duration: 0.45,
    },
  }),
});

export const cardGridStyles: React.CSSProperties = {
  position: 'relative',
  y: 200,
  opacity: 0,
  height: '100%',
};

export const useCardDetailsV2SentStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      flexGrow: 1,
      marginTop: 120,
      display: 'flex',
      maxWidth: '980px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 0px 30px 0px',
      },
      padding: '0 36px 30px 36px',
      flexWrap: 'wrap',
      gap: '36px',
      justifyContent: 'center',
      '& > .header': {
        flex: '0 1 300px',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          flex: '0 1 500px',
        },
        [theme.breakpoints.up('md')]: {
          flex: '1 1 100%',
        },
        '& > .title': { display: 'flex', columnGap: 8, alignItems: 'center' },
        '& > .status': {
          flex: '1 1 100%',
          textAlign: 'center',
        },
        '& > .arrow': {
          zIndex: 1,
          position: 'absolute',
          left: 0,
        },
      },
      '& > .gift-container': {
        flex: '0 1 300px',
        minHeight: 270,
        [theme.breakpoints.up('sm')]: {
          flex: '0 1 500px',
        },
        [theme.breakpoints.up('md')]: {
          flex: '1 1 100%',
        },
      },

      '& > .notes-container': {
        flex: '1 1 100%',
        justifyContent: 'center',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, 144px)`,
        gridAutoRows: '144px',
        gap: 12,
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: `repeat(auto-fit, 225px)`,
          gridAutoRows: '225px',
          gap: 24,
        },
      },

      '& > .replies-container': {
        flex: '1 1 100%',
        maxWidth: '720px',
        display: 'flex',
        rowGap: 10,
        flexDirection: 'column',
      },
    },
  });
});
