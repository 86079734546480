/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef } from 'react';

import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useMachine } from '@xstate/react';

import {
  RecorderCheckButton,
  RecorderPauseButton,
  RecorderPlayButton,
  RecorderRecordButton,
  RecorderStopButton,
  RecorderTrashButton,
} from 'components/illume/recorder-buttons';
import { Text } from 'components/illume/text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import stateDebug from 'components/video-recorder/stateMachineDebug';
import { DEFAULT_PALETTE, generateColorFromTheme, PRIMARY_THEME, rem } from 'constants/design';
import { generateContrastFromPalette } from 'constants/design/cardTheme';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { PaletteDTO } from 'types';
import { noop } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';

import { MAX_RECORDING_TIME } from './constants';
import { recorderMachine } from './machines';
import { useStyles } from './styles';

const Timer = ({ time = '' }) => {
  const classes = useStyles();
  return (
    <div className={classes.timer}>
      <Text fontWeight={400} fontSize={rem[875]}>
        {time}
      </Text>
    </div>
  );
};

interface VideoRecorderProps {
  width?: number | string;
  onFinished?: (blob: Blob) => any;
  theme?: string;
  palette?: PaletteDTO;
}

const VideoRecorder: React.FC<VideoRecorderProps> = ({
  width,
  onFinished = noop,
  theme = PRIMARY_THEME,
  palette = DEFAULT_PALETTE,
}) => {
  // THOUGHT: these probably something to store on state machine's context
  const videoRef = useRef();
  const video = videoRef.current!;
  const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateContrastFromPalette(palette, theme)
    : generateColorFromTheme(theme);

  const classes = useStyles({ width });

  function playStream(context, event) {
    video.srcObject = new MediaStream(context?.stream?.getVideoTracks());
    video.play();
  }

  function switchSource(context) {
    video.srcObject = null;
    video.src = URL.createObjectURL(context.blob);
  }

  function play() {
    video.play();
  }

  function pause() {
    video.pause();
  }

  function reset() {
    video.currentTime = 0;
  }

  const handleFinished = (context, _event) => {
    onFinished(context.blob);
  };
  const { enqueueErrorSnackbar } = useIllumeSnackbar();

  const alertErr = (_ctx, event) => {
    enqueueErrorSnackbar(event?.data?.message || "oops! there's an error");
  };

  const [state, send] = useMachine(recorderMachine, {
    services: { onFinished: handleFinished },
    actions: {
      playStream,
      alertErr,
      play,
      pause,
      reset,
      switchSource,
    },
  });

  const renderButtons = (state) => {
    const { matches } = state;
    const readyMatches = (match) => {
      return matches({ ready: match });
    };
    return readyMatches('idle') ? (
      <Tooltip
        text={TOOLTIP_TEXT}
        variant="secondary"
        widthArrow={'10%'}
        placement="top"
        showTooltip={state.matches('ready.idle.tooltip_active')}
      >
        <div>
          <RecorderRecordButton
            style={{ width: 60 }}
            onClick={() => {
              send('RECORD');
            }}
          />
        </div>
      </Tooltip>
    ) : readyMatches('recording') ? (
      <RecorderStopButton style={{ width: 60 }} onClick={() => send('STOP')} />
    ) : readyMatches('recorded') ? (
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item>
          <RecorderTrashButton onClick={() => send('THROW_AWAY')} style={{ width: 40 }} />
        </Grid>
        <Grid item>
          {matches('ready.recorded.playing') ? (
            <RecorderPauseButton style={{ width: 60 }} onClick={() => send('PAUSE')} />
          ) : (
            <RecorderPlayButton onClick={() => send('PLAY')} style={{ width: 60 }} />
          )}
        </Grid>
        <Grid item>
          <RecorderCheckButton onClick={() => send('ACCEPT')} style={{ width: 40 }} />
        </Grid>
      </Grid>
    ) : null;
  };

  const recordingElapsedTime = ({ minutes, seconds }) => {
    const sec = seconds < 10 ? `0${seconds}` : seconds;
    const { seconds: maxSeconds, minutes: maxMinutes } = MAX_RECORDING_TIME;
    return `${minutes}:${sec}/${maxMinutes}:${maxSeconds}`;
  };

  const TOOLTIP_TEXT = 'tap here to start recording your video!';

  return (
    <div className={classes.videoContainer}>
      {stateDebug(state)}
      {state.matches('ready.recording') && (
        <div className={classes.timerContainer}>
          <Timer time={recordingElapsedTime(state.context.elapsed)} />
        </div>
      )}
      {state.matches('initializing') && (
        <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Text fontSize={rem[2000]} align="center" color={contrastColor}>
            Initializing...
          </Text>
          <CircularProgress size={64} style={{ color: contrastColor, marginTop: 16 }} />
        </Box>
      )}
      {state.matches('initFail') && (
        <div>
          <Text align="center" fontSize={rem[2250]}>
            You need to enable camera to access this feature
          </Text>
        </div>
      )}
      {state.matches('ffmpegTranscoding') && (
        <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {/** Probably need to review the UI design on this one */}
          <Text fontSize={rem[2000]} align="center" color={contrastColor}>
            Processing video before upload...
          </Text>
          <CircularProgress
            size={64}
            style={{ color: contrastColor, marginTop: 16 }}
            // value={state?.context?.post_process_progress}
          />
        </Box>
      )}
      <video
        style={{
          width: '100%',
          display: ['initFail', 'initializing', 'ffmpegTranscoding'].some(state.matches)
            ? 'none'
            : 'block',
        }}
        ref={videoRef}
        onEnded={() => send('END')}
      />
      <div className={classes.bottomMenu}>
        {state.matches('finished') ? (
          <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
            <Text fontSize={rem[1500]} align="center" color={'white'}>
              recording session has ended, we will decide what to do on the actual implementation.
              <br />
              for now we can reload this page
            </Text>
          </div>
        ) : (
          renderButtons(state)
        )}
      </div>
    </div>
  );
};

export default VideoRecorder;
