export const menuBlobVariants = {
  visible: {
    translateX: 0,
    translateY: 0,
    translateZ: 0,
    originX: 1,
    originY: 1,
    d: 'M0.0668418 114.328C-1.3436 85.759 19.663 55.3352 54.0237 32.2406C90.6951 7.5904 131.358 -2.37139 175.172 0.47056C200.439 2.08598 224.237 8.9665 244.223 25.5096C257.217 36.2492 267.27 49.4419 275.943 63.8012C291.248 89.1095 304.062 115.495 311.294 144.363C321.317 184.33 311.534 219.6 285.966 250.981C259.168 283.858 223.547 300.551 182.044 306.055C153.025 309.914 124.756 306.863 97.2972 296.692C63.7767 284.277 39.7092 261.332 24.5545 229.442C9.42976 197.672 1.17719 164.077 0.0668418 114.328Z',
    transition: {
      type: 'spring',
      stiffness: 240,
      damping: 12,
    },
  },
  hidden: {
    translateX: 129,
    translateY: 23,
    translateZ: 0,
    originX: 1,
    originY: 1,
    d: 'M0.00933172 15.9612C-0.187578 11.9727 2.74513 7.72529 7.54219 4.50108C12.6619 1.05969 18.3387 -0.331068 24.4555 0.0656944C27.9831 0.291222 31.3055 1.2518 34.0957 3.56138C35.9098 5.06072 37.3133 6.90253 38.5241 8.90722C40.6608 12.4405 42.4497 16.1241 43.4594 20.1544C44.8588 25.7341 43.493 30.6581 39.9234 35.0392C36.1821 39.6291 31.2091 41.9596 25.4149 42.728C21.3636 43.2668 17.417 42.8408 13.5836 41.4208C8.9038 39.6876 5.54376 36.4843 3.42803 32.0322C1.31648 27.5968 0.164346 22.9067 0.00933172 15.9612Z',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 360,
      damping: 26,
    },
  },
};

export const menuBlobBackgroundVariants = {
  visible: {
    translateX: -9,
    translateY: 9,
    translateZ: 0,
    originX: 1,
    originY: 1,
    d: 'M0.0668418 114.328C-1.3436 85.759 19.663 55.3352 54.0237 32.2406C90.6951 7.5904 131.358 -2.37139 175.172 0.47056C200.439 2.08598 224.237 8.9665 244.223 25.5096C257.217 36.2492 267.27 49.4419 275.943 63.8012C291.248 89.1095 304.062 115.495 311.294 144.363C321.317 184.33 311.534 219.6 285.966 250.981C259.168 283.858 223.547 300.551 182.044 306.055C153.025 309.914 124.756 306.863 97.2972 296.692C63.7767 284.277 39.7092 261.332 24.5545 229.442C9.42976 197.672 1.17719 164.077 0.0668418 114.328Z',
    transition: {
      type: 'spring',
      stiffness: 240,
      damping: 12,
    },
  },
  hidden: {
    translateX: 129,
    translateY: 23,
    translateZ: 0,
    originX: 1,
    originY: 1,
    d: 'M0.00933172 15.9612C-0.187578 11.9727 2.74513 7.72529 7.54219 4.50108C12.6619 1.05969 18.3387 -0.331068 24.4555 0.0656944C27.9831 0.291222 31.3055 1.2518 34.0957 3.56138C35.9098 5.06072 37.3133 6.90253 38.5241 8.90722C40.6608 12.4405 42.4497 16.1241 43.4594 20.1544C44.8588 25.7341 43.493 30.6581 39.9234 35.0392C36.1821 39.6291 31.2091 41.9596 25.4149 42.728C21.3636 43.2668 17.417 42.8408 13.5836 41.4208C8.9038 39.6876 5.54376 36.4843 3.42803 32.0322C1.31648 27.5968 0.164346 22.9067 0.00933172 15.9612Z',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 360,
      damping: 26,
    },
  },
};
