import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

export const suggestionModalStyles = makeStyles((theme) => ({
  suggestionList: {
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(1),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 5,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.primary,
      borderRadius: '5px',
    },
  },
  suggestion: {
    textAlign: 'left',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  text: {
    width: '100%',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
