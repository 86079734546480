import FFmpeg from '@ffmpeg/ffmpeg';

import runFFmpeg from 'infra/ffmpeg/utils';

import logger from '../../utils/logger';
import readFromBlobOrFileAsArrayBuffer from '../../utils/readBlobOrFileAsArrayBuffer';

// older version using multi threaded build, using official FFmpeg wrapper
export const multiThreadedFFmegTranscode = async ({ onProgress, media, params }) => {
  const { createFFmpeg, fetchFile } = FFmpeg;

  const ffmpeg = createFFmpeg({
    log: process.env.NODE_ENV === 'development',
    corePath: '/ffmpeg-mt/ffmpeg-core.js',
    progress: onProgress,
  });

  logger.log('loading ffmpeg');
  await ffmpeg.load();
  logger.log('writing files');
  ffmpeg.FS('writeFile', 'input', await fetchFile(media));

  const command = params || '-i input output.mp4'; // use default ffmpeg optimization

  await ffmpeg.run(...command.split(' '));
  const data = ffmpeg.FS('readFile', 'output.mp4');
  const newBlob = new Blob([data.buffer], { type: 'video/mp4' });
  return newBlob;
};

// single threaded version
export const singleThreadedFFmpegTranscode = async ({
  onProgress,
  media,
  params,
  outputFileName = 'output.mp4',
  inputFileName = 'input',
}) => {
  const input = new Uint8Array(await readFromBlobOrFileAsArrayBuffer(media));
  const command = params || `-i ${inputFileName} ${outputFileName}`; // use default ffmpeg optimization
  const args = command.split(' ');
  const { file } = await runFFmpeg(inputFileName, input, args, outputFileName, onProgress);
  const result = new Blob([file.buffer], { type: 'video/mp4' });
  logger.log('succesfully transcode the file', result);
  return result;
};
