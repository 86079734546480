import { useCallback, useContext, useEffect, useState } from 'react';

import { ClipboardContext } from 'contexts/clipboard';

export const useClipboardCopy = (timeout = 2000) => {
  const [text, setText] = useState<string | null>(null);
  const copied = !!text;
  const clipboard = useContext(ClipboardContext);
  const copy = useCallback((text) => {
    return clipboard.copy(text).then((text) => setText(text));
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (copied) {
      timer = setTimeout(() => {
        setText('');
      }, timeout);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [timeout, copied]);

  return [copied, copy] as const;
};
