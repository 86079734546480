import { FC } from 'react';

import BaseButton, { IllumeBaseButtonProps } from './BaseButton';

const Button: FC<IllumeBaseButtonProps> = (props) => <BaseButton {...props} />;

Button.defaultProps = {
  type: 'submit',
};

export default Button;
