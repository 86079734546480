import { useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { Maybe } from 'yup/lib/types';

import stripeService from 'services/stripeService';

export const usePayWithCC = (service: typeof stripeService.setupIntent) => {
  const elements = useElements();
  const stripe = useStripe();
  const [paymentIntentError, setPaymentIntentError] = useState<Maybe<Error>>();
  const [stripeError, setStripeError] = useState<Maybe<StripeError>>(null);
  const [paying, setPaying] = useState(false);

  const pay = async (amount: number, cardGiftId: string, name: string, email: string) => {
    let clientSecret;

    if (!stripe || !elements) {
      const error = new Error('error initializing stripe');
      setStripeError({ type: 'card_error', ...error });
      return { stripeError: error };
    }

    setPaying(true);
    try {
      clientSecret = await service({
        amount,
        identifier: cardGiftId,
        receiptEmail: email,
      });
    } catch (e) {
      setPaymentIntentError(new Error('unable to request payment intent'));
      console.error(e);
      setPaying(false);
      return { paymentIntentError: e };
    }

    const confirmPaymentResponse = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
        billing_details: {
          name,
          email,
        },
      },
    });
    const { error: stripeError, setupIntent } = confirmPaymentResponse;

    setPaying(false);
    if (stripeError) setStripeError(stripeError);
    return { setupIntent, stripeError };
  };

  function clearErrors() {
    setPaymentIntentError(null);
    setStripeError(null);
  }

  return { pay, stripeError, paymentIntentError, clearErrors, paying };
};
