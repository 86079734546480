const QuestionMark = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0961 11.2426C13.2728 10.1564 14 8.65706 14 7.00084C14 5.34294 13.2728 3.84357 12.0961 2.75741C10.9195 1.67126 9.29515 1 7.50091 1C5.70485 1 4.08053 1.67126 2.90387 2.75741C1.7272 3.84357 1 5.34294 1 7.00084C1 8.65706 1.7272 10.1564 2.90387 11.2426C4.08053 12.3287 5.70485 13 7.50091 13C9.29515 13 10.9195 12.3287 12.0961 11.2426ZM6.84132 5.21307C6.84132 5.48798 6.59831 5.7123 6.29867 5.7123C6.00084 5.7123 5.75784 5.48798 5.75784 5.21307C5.75784 4.32593 6.53985 3.60408 7.50091 3.60408C8.46016 3.60408 9.24217 4.32593 9.24217 5.21307C9.24217 6.08166 8.83837 6.50836 8.4565 6.90808C8.24273 7.1324 8.04174 7.34322 8.04174 7.64512C8.04174 7.92171 7.79874 8.14603 7.50091 8.14603C7.20127 8.14603 6.95826 7.92171 6.95826 7.64512C6.95826 6.97217 7.29079 6.62136 7.64343 6.252C7.89375 5.9889 8.15868 5.71061 8.15868 5.21307C8.15868 4.87576 7.86451 4.60422 7.50091 4.60422C7.13549 4.60422 6.84132 4.87576 6.84132 5.21307V5.21307ZM7.50091 8.89656C7.94856 8.89656 8.31216 9.23219 8.31216 9.6454C8.31216 10.0603 7.94856 10.3959 7.50091 10.3959C7.05144 10.3959 6.68784 10.0603 6.68784 9.6454C6.68784 9.23219 7.05144 8.89656 7.50091 8.89656ZM11.3306 10.5359C12.3501 9.5948 12.9165 8.33155 12.9165 7.00084C12.9165 5.66845 12.3501 4.4052 11.3306 3.46409C10.311 2.52298 8.94252 2.00014 7.50091 2.00014C6.05748 2.00014 4.68897 2.52298 3.66943 3.46409C2.64989 4.4052 2.08349 5.66845 2.08349 7.00084C2.08349 8.33155 2.64989 9.5948 3.66943 10.5359C4.68897 11.477 6.05748 11.9999 7.50091 11.9999C8.94252 11.9999 10.311 11.477 11.3306 10.5359Z"
        fill="#282829"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export default QuestionMark;
