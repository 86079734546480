// eslint-disable-next-line import/no-anonymous-default-export
export default ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00593851 10.1419C-0.119371 7.60758 1.74694 4.90872 4.79969 2.86002C8.05774 0.673335 11.6704 -0.210363 15.563 0.0417427C17.8079 0.185045 19.9221 0.795407 21.6978 2.26293C22.8523 3.21563 23.7454 4.38593 24.5159 5.65973C25.8757 7.9048 27.0141 10.2454 27.6567 12.8063C28.5472 16.3517 27.678 19.4804 25.4064 22.2642C23.0256 25.1807 19.8608 26.6615 16.1735 27.1498C13.5953 27.4921 11.0838 27.2214 8.64429 26.3192C5.66619 25.2178 3.52793 23.1824 2.18152 20.3535C0.83778 17.5352 0.104586 14.5551 0.00593851 10.1419Z"
      fill="#282829"
      fillOpacity="0.6"
    />
    <path
      d="M20.0284 10.5838L17.213 12.2022V11.68C17.213 11.2344 17.036 10.8071 16.7209 10.4921C16.4059 10.177 15.9786 10 15.533 10H8.68C8.23444 10 7.80712 10.177 7.49206 10.4921C7.177 10.8071 7 11.2344 7 11.68V16.9104C7 17.356 7.177 17.7833 7.49206 18.0983C7.80712 18.4134 8.23444 18.5904 8.68 18.5904H15.533C15.9786 18.5904 16.4059 18.4134 16.7209 18.0983C17.036 17.7833 17.213 17.356 17.213 16.9104V16.3882L20.0284 18.0066C20.5618 18.3132 21 18.0612 21 17.4466V11.1466C21 10.5292 20.5632 10.2772 20.0284 10.5838Z"
      fill="#FFFDF3"
    />
  </svg>
);
