import { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'components/illume/buttons';
import { Surface } from 'components/illume/surface';
import { Text } from 'components/illume/text';
import { LinearProgress } from 'components/linear-progress';
import { colors } from 'constants/design';
import { Money } from 'domain/entities/money/Money';

const useStyles = makeStyles((theme) => {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'unset',
      },
      padding: theme.spacing(4),
      alignItems: 'center',
      columnGap: 20,
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 5,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'unset',
      width: '100%',
      rowGap: 20,
      flex: 3,
      order: 2,
      marginTop: '16px',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        order: 1,
        marginTop: 0,
      },
    },
    imageContainer: {
      flex: 2,
      order: 1,
      borderRadius: 8,
      overflow: 'hidden',
      height: 200,
      width: 200,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 225,
        maxWidth: 225,
        order: 2,
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  };
});

interface IProps {
  image: string;
  title: string;
  max: number;
  current: number;
  yourContribution?: number;
  onAction?: () => any;
  loading?: boolean;
  disabled?: boolean;
  actionText?: string;
  actionVariant?: 'secondary' | 'primary';
}

const ContributeToGiftCard: React.FC<IProps> = ({
  actionText = 'Contribute to group gift',
  actionVariant = 'primary',
  onAction,
  loading,
  disabled,
  ...rest
}) => {
  const styles = useStyles();

  const { image, title: name, current, max, yourContribution } = rest;

  const shownRatio = useMemo(() => {
    const ratio = current / max;

    if (ratio === 0) {
      return 5; // add arbitary number to meet design purpose
    } else return ratio * 100;
  }, [current, max]);

  const [c, m, y] = [current, max, yourContribution].map((v) =>
    v ? new Money({ amount: v }).toFormat() : '',
  );
  return (
    <Surface fullWidth className={styles.main} rounded={['top', 'bottom']}>
      <div className={styles.info}>
        <div>
          <Text fontWeight={700} fontSize={28}>
            {name}
          </Text>
          <div className={styles.text}>
            <Text fontSize={28} fontWeight={700}>
              {c}{' '}
            </Text>
            <Text>raised of {m} goal</Text>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
          <LinearProgress
            style={{ backgroundColor: colors.contrastText }}
            variant="determinate"
            value={shownRatio}
          />
          {yourContribution !== undefined && yourContribution > 0 && (
            <div>
              <Text color={colors.alertGreen}>You've already contributed {y}</Text>
            </div>
          )}
        </div>
        <div>
          <Button
            customcolor={actionVariant === 'primary' ? undefined : 'background'}
            fullWidth
            onClick={onAction}
            loading={loading}
            disabled={disabled}
          >
            <Text color={actionVariant === 'primary' ? undefined : colors.labelText}>
              {actionText}
            </Text>
          </Button>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={image} alt={name} />
      </div>
    </Surface>
  );
};

export default ContributeToGiftCard;
