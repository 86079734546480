import { useState } from 'react';

import { RecipientCardV2Dto } from '@illume/shared';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { noop } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { HtmlRenderer } from 'components/illume/html-renderer';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { Theme } from 'infra/mui-stylesheet';
import { NoteThubmnail } from 'pages/illume/card-details-v2/note-thumbnail';
import { pluralize } from 'utils';
import { showNames } from 'utils/string';

//immutable funtion to repeat array
function repeat<T>(arr: T[], times: number) {
  const result: T[] = [];
  for (let i = 0; i < times; i++) {
    result.push(...arr);
  }
  return result;
}

interface CoverProps {
  data: {
    recipientData: RecipientCardV2Dto['recipientData'];
    initiatorName: string;
    contributorCount: number;
    notes: RecipientCardV2Dto['notes'];
  };
  printRef?: React.RefObject<HTMLDivElement>;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      maxWidth: 800,
      margin: '0 auto',
      paddingTop: 72,
      backgroundColor: colors.background,
      minHeight: '100vh',
    },
    title: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
    },
    notes: {
      // page breaks styles
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '1rem',
      marginBottom: '1rem',
      gap: 16,
      '& > .note': {
        width: '225px',
        height: '225px',
      },
    },
    subsequentNotes: {
      minHeight: '100vh',
      paddingTop: 24,
      pageBreakBefore: 'auto',
      pageBreakInside: 'avoid',
      pageBreakAfter: 'auto',
    },
  }),
);

// group an array to 12 items per group
function groupArray<T>(arr: T[], groupSize: number) {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += groupSize) {
    result.push(arr.slice(i, i + groupSize));
  }
  return result;
}

class viewModel {
  constructor(private data: CoverProps['data']) {
    makeAutoObservable(this);
  }

  get otherNoteSenderCount() {
    if (!this.data.contributorCount) {
      return 0;
    }
    return this.data.contributorCount - 1;
  }

  get contributorSummary() {
    const { contributorCount, initiatorName } = this.data;
    const noteSender =
      initiatorName === undefined || initiatorName === '(anonymous)' ? 'Someone' : initiatorName;

    const singleContributorText = `${noteSender} shared appreciation.`;

    const multipleContributorsText = `${noteSender} + ${
      this.otherNoteSenderCount
    } other ${pluralize(
      'individual',
      this.otherNoteSenderCount,
    )} came together to share their appreciation.`;

    let text = contributorCount === 1 ? singleContributorText : multipleContributorsText;

    // Override it for the illume team account. Using name not ID because that's all the info we have in this scope
    if (noteSender === 'the illume team' && contributorCount === 1)
      text = 'the illume team shared their appreciation';

    return text;
  }

  get title() {
    const formatted = `HEY ${this.spelledNames.toUpperCase()},<br /> YOU’RE CARED FOR.`;
    return formatted;
  }

  get spelledNames() {
    const { recipientData } = this.data;
    return showNames(recipientData);
  }
}

export const Cover = ({ data, printRef }: CoverProps) => {
  const classes = useStyles();
  const [vm] = useState(() => new viewModel(data));
  const FIRST_PAGE_LENGTH = 9;

  return (
    <div className={classes.container} ref={printRef}>
      <div className={classes.title}>
        <Text fontSize={36} fontWeight={900}>
          <HtmlRenderer html={vm.title} />
        </Text>
        <Text fontWeight={300}>{vm.contributorSummary}</Text>
      </div>
      <div className={classes.notes}>
        {data.notes.slice(0, FIRST_PAGE_LENGTH).map((note) => (
          <div className="note" key={note.viewCode}>
            <NoteThubmnail
              cardCode={'fakeCode'}
              editable={false}
              previewable={false}
              key={note.viewCode}
              note={NoteThubmnail.noteFromDTO(note)}
              onViewNote={noop}
              onPencilClick={noop}
              deleteService={() => Promise.resolve()}
              deleteModalLoading={false}
              hideBadge={true}
              recipientName={'fakeName'}
            />
          </div>
        ))}
      </div>
      {groupArray(data.notes.slice(FIRST_PAGE_LENGTH), 12).map((notes, index) => (
        <div className={classes.subsequentNotes} key={index}>
          <div className={classes.notes}>
            {notes.map((note) => (
              <div className="note" key={note.viewCode}>
                <NoteThubmnail
                  cardCode={'fakeCode'}
                  editable={false}
                  previewable={false}
                  key={note.viewCode}
                  note={NoteThubmnail.noteFromDTO(note)}
                  onViewNote={noop}
                  onPencilClick={noop}
                  deleteService={() => Promise.resolve()}
                  deleteModalLoading={false}
                  hideBadge={true}
                  recipientName={'fakeName'}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      {/* )} */}
      {/* {data.notes.slice(FIRST_PAGE_LENGTH).length > 0 && (
        <div className={classes.subsequentNotes}>
          {groupArray(data.notes.slice(FIRST_PAGE_LENGTH), 12).map((notes, index) => (
            <div className={classes.notes} key={index}>
              {notes.map((note) => (
                <div className="note" key={note.viewCode}>
                  <NoteThubmnail
                    cardCode={'fakeCode'}
                    editable={false}
                    previewable={false}
                    key={note.viewCode}
                    note={NoteThubmnail.noteFromDTO(note)}
                    onViewNote={noop}
                    onPencilClick={noop}
                    deleteService={() => Promise.resolve()}
                    deleteModalLoading={false}
                    hideBadge={true}
                    recipientName={'fakeName'}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};
