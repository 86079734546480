import { UserProfileWithPlan, UserSummaryDTO } from '@illume/shared/types';
import { AxiosRequestConfig } from 'axios';

import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IllumeHttpClientRequestObject } from 'infra/client/client.types';
import { UserProfileDTO } from 'types';
import logger from 'utils/logger';

import { IUserService } from '../../domain/interfaces/IUserService';

export class UserService implements IUserService {
  constructor(private httpClient: IHttpClient) {}
  getUserSummary = async () => {
    const requestObj: AxiosRequestConfig = {
      url: '/user/summary',
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{ userSummary: UserSummaryDTO }>(requestObj);
    if (resp.success) {
      return resp.userSummary;
    } else {
      // why did/can it fail? should we forward to login page? or would that only happen on a try/catch?
      // TODO: Send this to Sentry, also create some kind of errorMsg on the response when success=false
      const errMsg = 'failed to get user summary';
      logger.error(errMsg, resp);
      throw Error(errMsg);
    }
  };

  getUser = async () => {
    const requestObj: IllumeHttpClientRequestObject = {
      url: '/user',
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{
      userProfile: UserProfileDTO & UserProfileWithPlan;
    }>(requestObj);
    if (resp.success) {
      return resp.userProfile;
    } else {
      const errMsg = 'Failed to get the user';
      logger.error(errMsg, resp);
      throw Error(errMsg);
    }
  };

  updateUser = async (userProfile: Partial<UserProfileDTO>) => {
    const requestObj: AxiosRequestConfig = {
      url: '/user',
      method: 'patch',
      data: userProfile,
    };
    const resp = await this.httpClient.makeRequest<{
      userProfile: UserProfileDTO & UserProfileWithPlan;
    }>(requestObj);
    if (resp.success) {
      return resp.userProfile;
    } else {
      const errMsg = 'Failed to update the user';
      logger.error(errMsg, resp);
      throw Error(errMsg);
    }
  };

  setBirthday = (deadline: Date) => {
    const illumeDate = new IllumeDate(deadline);
    return this.updateUser({ birthday: illumeDate.toAPIString(), timezone: illumeDate.getTz() });
  };
  getBillingRedirectionUrl = async () => {
    const requestObj: AxiosRequestConfig = {
      url: '/user/stripePortal',
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{ url: string }>(requestObj);
    if (resp.success) {
      return resp.url;
    } else {
      const errMsg = 'Failed to get the billing url';
      logger.error(errMsg, resp);
      throw Error(errMsg);
    }
  };
}
