import { useLocation } from 'react-router-dom';

import { SentryErrorFallback } from 'components/sentry-error-fallback';
import { RECEIVE_URL } from 'constants/strings';
import { APPBAR_ICONS, GRID_TOOLTIP_TEXT } from 'constants/types';
import { Note } from 'domain/entities/initiator-card/InitiatorCard';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import Sentry from 'infra/sentry/sentry';

import { NoteDisplay } from '../note-display';

interface IProps {
  notes: Note[];
  name: string;
  onDownload: () => void;
}

const ReceivedNote = ({ notes = [], name, onDownload }: IProps) => {
  const history = useIllumeHistory();
  const location = useLocation();
  const { noteViewCode } = location.state || {};
  const foundIndex = noteViewCode ? notes.findIndex((note) => note.viewCode === noteViewCode) : 0;
  const noteIndex = foundIndex === -1 ? 0 : foundIndex;

  function pushToWelcome() {
    history.pushWithSearch(RECEIVE_URL);
  }

  return (
    <>
      <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
        <NoteDisplay
          showNavigationButtons={true}
          showPrivacyModal={false}
          hideHamburger
          topRightButtons={[
            {
              tooltipText: GRID_TOOLTIP_TEXT,
              showTooltipAfter: 2000,
              icon: <APPBAR_ICONS.GRID />,
              action: {
                onAction: pushToWelcome,
              },
            },
            {
              tooltipText: 'download group card as PDF',
              icon: <APPBAR_ICONS.DOWNLOAD />,
              action: {
                onAction: onDownload,
              },
            },
          ].reverse()}
          recipientName={name}
          notes={notes}
          onLastNoteClick={pushToWelcome}
          index={noteIndex}
          lastNoteTooltipText="reply"
        />
      </Sentry.ErrorBoundary>
    </>
  );
};

export default ReceivedNote;
