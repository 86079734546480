import { useState } from 'react';

import { ProviderProps, TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { Button } from 'components/illume/buttons';
import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import { LeftArrow, RightArrow } from 'components/illume/icons';
import { colors } from 'constants/design';

type IllumeTourProviderProps = ProviderProps;

export const IllumeTourProvider = (props: IllumeTourProviderProps) => {
  const radius = 8;
  const [showDots, setShowDots] = useState(true);

  return (
    <TourProvider
      startAt={0}
      prevButton={(props) => {
        const first = props.currentStep === 0;
        return (props?.steps?.length || 0) > 1 ? (
          <BaseIconButtonNew
            iconProps={{
              blobColor: colors.backgroundGray,
              size: 32,
              arrowColor: colors.gray100,
            }}
            onClick={() =>
              first
                ? props.setCurrentStep(props.stepsLength - 1)
                : props.setCurrentStep((prev) => prev - 1)
            }
            icon={LeftArrow}
          />
        ) : (
          <></>
        );
      }}
      nextButton={(props) => {
        const last = props.stepsLength - 1 === props.currentStep;
        setShowDots((props?.steps?.length || 0) > 1); // this is tricky way to know the updated steps length by setSteps
        return (props?.steps?.length || 0) > 1 ? (
          <BaseIconButtonNew
            iconProps={{
              blobColor: colors.backgroundGray,
              size: 32,
              arrowColor: colors.gray100,
            }}
            onClick={() => {
              if (last) {
                props.setIsOpen(false);
                props.setCurrentStep(0);
              } else {
                props.setCurrentStep((prev) => prev + 1);
              }
            }}
            icon={RightArrow}
          />
        ) : (
          <Button
            className="btn"
            onClick={() => {
              if (last) {
                props.setIsOpen(false);
                props.setCurrentStep(0);
              } else {
                props.setCurrentStep((prev) => prev + 1);
              }
            }}
          >
            got it
          </Button>
        );
      }}
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': colors.primary,
          borderRadius: radius,
          background: colors.background,
        }),
        maskArea: (base) => ({ ...base, rx: radius }),
        dot: (base) => {
          return { ...base, display: showDots ? 'block' : 'none' };
        },
      }}
      scrollSmooth
      disableDotsNavigation
      beforeClose={(e) => e && enableBodyScroll(e)}
      afterOpen={(e) => e && disableBodyScroll(e)}
      showBadge={false}
      {...props}
    />
  );
};
