export const DownChevron = ({
  color = '#282829',
  className = null,
  width = '34',
  height = '17',
}) => {
  return (
    // probably better to wrap this in 24 24 viewbox like the others
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <line
        x1="2"
        y1="-2"
        x2="19.513"
        y2="-2"
        transform="matrix(0.770937 -0.636912 0.530223 0.847858 16.915 17)"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="2"
        y1="-2"
        x2="19.5131"
        y2="-2"
        transform="matrix(0.770913 0.63694 -0.530252 0.84784 0.5 3)"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
