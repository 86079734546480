import { omitBy, uniqBy } from 'lodash';

import { Money as Money } from 'domain/entities/money/Money';
import { IMoney } from 'domain/interfaces/IMoney';
import {
  CategoryType,
  FinancialDTO,
  GiftV2Dto,
  Maybe,
  MerchantProductFromGiftV2Object,
} from 'types';

export class GiftMerchantProduct {
  constructor(private productDto: MerchantProductFromGiftV2Object) {}

  private toMoney(cents: number | undefined) {
    return cents ? new Money({ amount: cents }) : new Money({ amount: 0 });
  }

  get listingId() {
    return this.productDto.listingId;
  }

  get shippingFee() {
    return this.toMoney(this.productDto.shippingFee);
  }

  get handlingFee() {
    return this.toMoney(this.productDto.handlingFee);
  }

  get isDigitalGiftcard() {
    const digitalCategoryTypes: CategoryType[] = [
      'Digital Giftcard',
      'Humanitarian',
      'Media Junkie',
    ];

    return digitalCategoryTypes.includes(this.productDto.category as CategoryType);
  }

  get total() {
    return this.shippingFee.add(this.productPrice).add(this.handlingFee);
  }

  get productPrice() {
    return this.toMoney(this.productDto.price);
  }

  get variant() {
    return this.productDto.variant;
  }

  get vendor() {
    return this.productDto.vendor;
  }

  get description() {
    return this.productDto.description;
  }

  get opinion() {
    return this.productDto.opinion;
  }

  get name() {
    return this.productDto.name;
  }

  get highlights() {
    return uniqBy(this.productDto.highlights, (e) => e.highlight);
  }

  get formattedPriceBreakdown() {
    const product = this;
    const formattedPrice = {
      'Product Total': product.productPrice.toFormat(),
      Handling: product.handlingFee.toFormat(),
      Shipping: product.shippingFee.toFormat(),
      Total: product.total.toFormat(),
    };

    // filter 0$ value
    return omitBy(formattedPrice, (el) => el.startsWith('$0.00'));
  }
}

class Discount {
  amount: IMoney;
  percentage: number;
  constructor(amount: number, percentage: number) {
    this.amount = new Money({ amount });
    this.percentage = percentage;
  }
}

class Financial {
  static toMoney(cents: number | undefined) {
    return cents ? new Money({ amount: cents }) : new Money({ amount: 0 });
  }
  static fromDto(dto: FinancialDTO) {
    const discount = new Discount(dto.discount.amount, dto.discount.percentage);
    return new Financial(
      Financial.toMoney(dto.goal),
      Financial.toMoney(dto.current),
      Financial.toMoney(dto.suggestedAmount),
      discount,
    );
  }
  private constructor(
    public goal: IMoney,
    public current: IMoney,
    public suggested: IMoney,
    public discount: Discount,
  ) {}

  /**
   * @description - progress in 0 - 1 ratio
   */
  get progress() {
    return parseFloat((this.current.getAmount() / this.goal.getAmount()).toFixed(2));
  }
}

export class GiftError {
  readonly type = 'error';
  constructor(public errType: string, public errMsg: string) {}
}

export default class Gift {
  readonly type = 'success';
  static fromDto(dto: GiftV2Dto) {
    console.log('building discount', {
      amount: dto.financial.discount.amount,
      percentage: dto.financial.discount.percentage,
    });
    return new Gift(
      dto.id,
      new GiftMerchantProduct(dto.product),
      Financial.fromDto(dto.financial),
      new Money({ amount: dto.yourContribution }),
      dto.product.name,
      dto.orderId,
    );
  }

  private constructor(
    public id: string,
    public product: GiftMerchantProduct,
    public financial: Financial,
    public myContribution: IMoney,
    public name: string,
    public orderId?: Maybe<string>,
  ) {}

  private toMoney(cents: number | undefined) {
    return cents ? new Money({ amount: cents }) : new Money({ amount: 0 });
  }

  get giftMetGoals() {
    return this.goal.subtract(this.current).lessThanOrEqual(this.toMoney(0));
  }

  get giftMerchantProduct() {
    return this.product;
  }

  get goal() {
    return this.financial.goal;
  }

  get productPrice() {
    return this.giftMerchantProduct.productPrice;
  }

  get current() {
    return this.financial.current;
  }

  get suggested() {
    return this.financial.suggested;
  }

  get goalRemaining() {
    return this.goal.subtract(this.current);
  }

  get isDigitalGiftcard() {
    return this.giftMerchantProduct.isDigitalGiftcard;
  }

  get shippingFee() {
    return this.giftMerchantProduct.shippingFee;
  }

  get handlingFee() {
    return this.giftMerchantProduct.handlingFee;
  }

  get discount() {
    return this.financial.discount.amount;
  }
  get discountPercentage() {
    return this.financial.discount.percentage;
  }

  get total() {
    return this.giftMerchantProduct.total.subtract(this.discount);
  }

  get progressRatio() {
    return this.financial.progress;
  }

  get formattedPriceBreakdown() {
    // no discount zero
    if (this.discount.equalsTo(this.toMoney(0))) {
      return this.giftMerchantProduct.formattedPriceBreakdown;
    } else {
      const product = this.giftMerchantProduct;
      const formattedPrice = {
        'Product Price': product.productPrice.toFormat(),
        Handling: product.handlingFee.toFormat(),
        Shipping: product.shippingFee.toFormat(),
        Discount: '-'.concat(this.discount.toFormat()),
        Total: this.total.toFormat(),
      };
      return formattedPrice;
    }
  }
  get orderTrackable() {
    return !!this.orderId;
  }
}
