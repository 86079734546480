export const theme = {
  colors: {
    orange: '#FEB81C',
    dirtOrange: '#e5a619',
    blackText: '#282829',
    blackTextLight: '#28282966',
    cloudBlue: '#c1dade',
    lightPeach: '#f5f0e1',
    lightPeach2: '#fffdf3',
    lightPeach3: '#efe6cd',
    lightPeach4: '#FAF6EA',
    olive: '#3e4323',
    alertGreen: '#23CE6B',
    lightOlive: '#DDDCBE',
    pink: '#e5cdd1',
    salmon: '#fac4ac',
    salmon2: '#FCCFBA',
    grey: '#f3edda',
    oldGold: '#D5AC3A',
    dustStorm: '#E5CDD1',
    hemlock: '#675F42',
    alabaster: '#F8F8F8',
    comet: '#505B71',
    white: '#FFFFFF',
    black: '#000000',
    darkGray: '#565659',
    gray40: `rgba(40, 40, 41, 0.4)`,
    gray60: `rgba(40, 40, 41, 0.6)`,
    gray100: `#282829`,
  },
  breakpoints: {
    mobile: 'only screen and (min-width: 320px) and (max-width: 767px)',
    tablet: 'only screen and (min-width: 768px)',
    tabletP:
      'only screen and (min-device-width: 768px) and (max-device-width: 1025px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)',
    tabletL:
      'only screen and (min-device-width: 768px) and (max-device-width: 1025px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)',
    laptopMax: 'only screen and (max-width: 1025px)',
    laptop: 'only screen and (min-width: 1025px)',
    laptopM: 'only screen and (min-width: 1240px)',
    laptopL: 'only screen and (min-width: 1440px)',
  },
  breakpointsNumbers: {
    tablet: 768,
    laptop: 1025,
    laptopL: 1440,
    UHD: 3840,
  },
  containerWidthForBreakpoints: {
    laptop: '900px',
  },
};

export type ThemeType = typeof theme;
