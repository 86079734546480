import { observer } from 'mobx-react';

import { IllumeLayout } from 'components/layouts';
import { NoteForm } from 'components/reusable-forms/note-form';
import { DEFAULT_PALETTE, DEFAULT_THEMEPACK, Palette } from 'constants/design/cardTheme';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { spellNames } from 'utils/string';

import { ROLES } from '../card-details-v2/shared/constants';
import ContributorStore from './Contributor.store';

interface AddNoteProps {
  store: ContributorStore;
}

const AddNoteContainer: React.FC<AddNoteProps> = ({ store }) => {
  const { card, addnoteStore } = store;
  const { recipientData, inviteCode, occasionName } = card!;
  const spelledNames = spellNames(recipientData) || 'unknown';
  const note = addnoteStore.noteData;
  const analytics = useAnalytics();
  const { noteService } = useServices();

  const handleDraftSubmissionSuccess = (args: any) => {
    const { message } = args || {};
    analytics.track(EVENT_NAMES.DRAFT_SUBMISSION_CONTRIBUTOR.name, { noteLength: message.length });
    // TODO: update note data
    store.addnoteStore.updateNote(args);
    store.pushToNext();
  };

  const { themepackStore } = useStores();
  const t = card?.themepackCode
    ? themepackStore.getThemepack(card.themepackCode) || DEFAULT_THEMEPACK
    : DEFAULT_THEMEPACK;
  const palette = t ? t.palettes[0] : DEFAULT_PALETTE;
  const bg = new Palette(palette).generateBackground();
  const { suggestionService } = useServices();

  return (
    <IllumeLayout background={bg} hideHamburger>
      <NoteForm
        suggestionService={suggestionService}
        role={ROLES.CONTRIBUTOR}
        analytics={analytics}
        onBack={store.backFromAddNote}
        recipientName={spelledNames}
        defaultValues={note}
        onSubmitSuccess={handleDraftSubmissionSuccess}
        theme={note?.theme}
        palette={note?.palette}
        ableToBack={false}
        occasionName={occasionName}
        enableLightBulbTooltip={false}
        draftValidationService={(val) =>
          noteService.createNoteDraft(note?.viewCode || inviteCode, {
            ...val,
            photos: val.photos && val.photos.map((p) => p.photoCode),
            videos: val.videos && val.videos.map((v) => v.videoCode),
            gifs: val.gifs && val.gifs.map((gif) => gif.gifCode),
          })
        }
      />
    </IllumeLayout>
  );
};

export default observer(AddNoteContainer);
