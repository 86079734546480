import { observer } from 'mobx-react';
import { Router as BrowserRouter } from 'react-router-dom';

import { useStores } from 'contexts/store';

export const Router = observer(({ history = null, ...props }) => {
  const { routerStore } = useStores();
  const h = routerStore.history || history;
  return <BrowserRouter history={h} {...props} />;
});
