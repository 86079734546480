import { Grid } from '@material-ui/core';

import { Text } from 'components/illume/text';
import { Link } from 'components/link';
import { rem } from 'constants/design';
import { EXPERIMENTS_URL } from 'constants/strings';

import { experiments } from './ExperimentRoutes';

const TOC = () => {
  return (
    <Grid
      item
      container
      alignItems="center"
      direction="column"
      style={{ marginTop: '20px' }}
      spacing={4}
    >
      <Grid item>
        <Text fontWeight={700} fontSize={rem[2000]}>
          Table of content
        </Text>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        {experiments.map(({ title, path, status }) => (
          <Grid item key={title}>
            <Link
              style={{
                fontSize: 20,
                textDecoration: status === 'disabled' ? 'line-through' : null,
              }}
              to={EXPERIMENTS_URL + path}
            >
              {title}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TOC;
