import { makeStyles } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

import { colors } from 'constants/design';

export const useSectionStyles = makeStyles((theme) => {
  const containerStyles: CSSProperties = {
    background: colors.background,
    border: `2px solid #F5F0E1`,
    boxShadow: `8px 8px 0px #F5F0E1`,
    borderRadius: 8,
    padding: `25px 24px 0 24px`,
    //TODO: remove this
    overflowX: 'hidden',
  };
  return {
    container: containerStyles,
    giftContainer: Object.assign({}, containerStyles, {
      padding: '24px 25px',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      rowGap: 30,
      [theme.breakpoints.up('md')]: {
        padding: '17px 36px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      flexWrap: 'wrap',
    }),
  };
});

export const useRecipientInfoSectionStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  formContainer: {
    rowGap: 12,
    display: 'flex',
    flexDirection: 'column',
    '& > .date-time-container': {
      display: 'flex',
      columnGap: 10,
      '& > div': {
        flex: 1,
      },
    },
  },
  textIntro: {
    height: '80px !important',
    '&::placeholder': {
      color: colors.gray60,
    },
  },
}));

export const userShareLinkSectionStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 22,
  },
  contributorsInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  contributorsMessage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  share: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },
}));

export const useReadyToSendSectionStyles = makeStyles(() => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  contributorsInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  share: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },
  textIntro: {
    height: '80px !important',
    '&::placeholder': {
      color: colors.gray60,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    '& > .date-time-container': {
      display: 'flex',
      columnGap: 10,
      marginBottom: 35,
      '& > div': {
        flex: 1,
      },
    },
  },
}));

export const useGiftSectionStyles = makeStyles((t) =>
  createStyles({
    info: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 8,
      textAlign: 'center',
      [t.breakpoints.up('md')]: {
        rowGap: 24,
        textAlign: 'left',
        alignItems: 'flex-start',
      },
      maxWidth: 385,
      '& > .button': {
        width: '100%',
        [t.breakpoints.up('md')]: {
          width: 250,
        },
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 5,
    },
    carouselContainer: {
      width: 230,
      [t.breakpoints.up('md')]: {
        height: 237,
        width: 456,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: 12,
      position: 'relative',
      '& > .btn-left': {
        position: 'absolute',
        top: '50%',
        left: '-8px',
        [t.breakpoints.up('md')]: {
          left: '-4px',
        },
        tranform: 'translate(0, 50%)',
      },
      '& > .btn-right': {
        position: 'absolute',
        right: '-8px',
        [t.breakpoints.up('md')]: {
          right: '-4px',
        },
        top: '50%',
        tranform: 'translate(0, 50%)',
      },
      '& > .card': {
        borderRadius: 6,
        width: '144px',
        height: '100%',
        transition: '500ms',
        position: 'absolute',
        background: colors.backgroundGray,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px 9px 9px 10px',
        justifyContent: 'space-between',
        '& > .title': {
          alignSelf: 'flex-start',
          maxWidth: '90%',
          textOverflow: 'ellipsis',
        },
        '& > .img': {
          height: 125,
          width: 125,
          borderRadius: 6,
        },
      },
      '& > .card--0': {
        transform: `translateX(-35%) scale(1)`,
        [t.breakpoints.up('md')]: {
          transform: `translateX(calc(-100% - 12px))`,
        },
        position: 'relative',
        '& > .overlay--0': {
          position: 'absolute',
          right: 0,
          left: 0,
          top: 0,
          background: 'linear-gradient(90deg, #FFFDF3 0%, rgba(255, 253, 243, 0) 100%)',
          bottom: 0,
          zIndex: '1',
          pointerEvents: 'none',
        },
      },
      '& > .card--1': {
        transform: 'scale(1.1)',
        [t.breakpoints.up('md')]: {
          transform: `scale(1)`,
        },
        zIndex: 1,
      },
      '& > .card--2': {
        transform: `translateX(40%) scale(1)`,
        [t.breakpoints.up('md')]: {
          transform: `translateX(calc(100% + 12px)) `,
        },
        '& > .overlay--2': {
          position: 'absolute',
          right: 0,
          left: 0,
          top: 0,
          background: 'linear-gradient(90deg, #FFFDF3 0%, rgba(255, 253, 243, 0) 100%)',
          bottom: 0,
          zIndex: '1',
          pointerEvents: 'none',
          transform: `rotate(-180deg)`,
        },
      },
    },
  }),
);
