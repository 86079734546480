import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { Box } from '@material-ui/core';
import { useTour } from '@reactour/tour';
import { isPast } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { omit } from 'lodash';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import qs from 'query-string';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import CloseCard from 'components/illume/close-card';
import { ContributorsModal } from 'components/illume/contributors-modal';
import { GroupGiftModal } from 'components/illume/group-gift-modal';
import { GroupGiftModalDTOMapper } from 'components/illume/group-gift-modal/GroupGiftModalDTOMapper';
import { TrashBin } from 'components/illume/icons';
import { Link } from 'components/illume/link';
import { Modal } from 'components/illume/modal';
import { RectangularBoxWithPlusButton } from 'components/illume/new-card';
import { Text } from 'components/illume/text';
import { PreviewModal } from 'components/preview-modal';
import { colors } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { CARD_SEND_SUCCESS, INITIATOR_CHOOSE_GROUP_GIFT } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { usePrompContext } from 'contexts/prompt';
import { useStores } from 'contexts/store';
import { InitiatorCard } from 'domain/entities/initiator-card/InitiatorCard';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { mq } from 'infra/emotion/breakpoints';
import { noop, pluralize } from 'utils';
import logger from 'utils/logger';
import { spellNames } from 'utils/string';
import { LayoutV2 } from 'views/components-v2/elements/layout/LayoutV2';

import { CardDetailsStore } from '../../CardDetaillsV2PendingStore';
import GiftPaymentFromCardDetailsStore from '../../CardDetailsV2.store';
import { NoteThubmnail } from '../../note-thumbnail';
import ContributeCardGift, { fromExternalGift, fromGift } from './components/ContributeCardGift';
import { ContributorIntroMessageModal } from './components/modals/ContributorIntroMessage.modal';
import { HowItWorksModal } from './components/modals/HowItWorksModal';
import {
  InitiatorModalFormValues,
  InitiatorNameModal,
} from './components/modals/InitiatorName.modal';
import RecipientNamesModal, {
  RecipientNameModalFormValues,
} from './components/modals/RecipientName.modal';
import { RecipientInfoStore, Section, SendCardStore } from './sections/sections';

export type InitiatorModalNames =
  | 'anygift'
  | 'editRecipientName'
  | 'editInitiatorName'
  | 'contributor'
  | 'delete'
  | 'howSendCard'
  | 'gift'
  | 'preview'
  | 'how_it_works'
  | 'no_note'
  | 'contributor_intro_message';

interface InitiatorCardDetailsProps {
  onDeleteCardSuccess: (card: InitiatorCard) => any;
  store: CardDetailsStore;
  card: InitiatorCard;
  giftPaymentFromCardDetailsStore: GiftPaymentFromCardDetailsStore;
  onCreateNote: () => void;
  /**
   * @deprecated - mark as deprecated because we are using this temporarily to disable gifting
   */
  disableGifting: boolean;

  disableContactEditing: boolean;
}

export const InitiatorCardDetailsContent: React.FunctionComponent<InitiatorCardDetailsProps> =
  observer(
    ({
      store,
      card,
      onDeleteCardSuccess,
      giftPaymentFromCardDetailsStore,
      onCreateNote,
      disableGifting,
      disableContactEditing,
    }) => {
      const {
        initiatorName,
        contributorCount,
        inviteList,
        contributorList: contributorListFromAPI,
        notes,
      } = card;

      const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();
      const [shownModalName, setShownModalName] = useState<InitiatorModalNames | null>(null);
      const removeCardAsync = useAsyncCallback(store.removeCardTask);
      const analytics = useAnalytics();
      const deleteNoteAsync = useAsyncCallback(store.deleteCardNoteTask, {
        onError: () => enqueueErrorSnackbar('oops'),
        onSuccess: () =>
          analytics.track(EVENT_NAMES.DELETE_NOTE_MY_CARDS.name, {
            numberOfContributors: card.contributorCount,
          }),
      });
      const removeGift = useAsyncCallback(store.removeCardGiftTask);
      const removeExternalGift = useAsyncCallback(store.removeExternalGiftTask);
      const history = useHistory();
      const { url } = useRouteMatch();
      const { userProfileStore, merchantProductStore, cache } = useStores();
      const { prompt } = usePrompContext();
      const [recipientInfoStore] = useState(() => new RecipientInfoStore(analytics));
      const [sendCardStore] = useState(() => new SendCardStore(analytics));

      const [hasSavedEmail, setHasSavedEmail] = useState(false);

      // for recipient card, `v2/card/view/:code it returns the contributorList
      // but for contributor, `v2/card/contribute/:code it is missing
      // so we get from notes as the fallback data
      const contributorList = contributorListFromAPI || notes?.map((note) => note?.signature);

      function handleErr(err: Error) {
        enqueueErrorSnackbar(err.message);
      }

      const handleRenameRecipients = ({ recipientList }: RecipientNameModalFormValues) => {
        const cleanedUpRecipientList = recipientList
          //remove falsy value
          .filter((r) => r)
          // server doesn't lik {id: ''}
          .map((r) => (r.id ? r : omit(r, 'id')));
        analytics.track('change recipients - my cards', { recipients: cleanedUpRecipientList });
        return store
          .updateCard({
            recipientData: cleanedUpRecipientList,
          })
          .then(() => {
            return setShownModalName(null);
          })
          .catch(handleErr);
      };

      const handleRenameInitiator = async ({ initiatorName }: InitiatorModalFormValues) => {
        return store
          .updateCard({
            initiatorName,
          })
          .then(() => setShownModalName(null))
          .catch(handleErr);
      };

      const handleSaveIntroMessage = async (message: string) => {
        return store
          .upsertIntroMessage(message)
          .then(() => setShownModalName(null))
          .catch(handleErr);
      };

      const handleLeftArrowClick = () => history.replace(store.cardlistUrl);

      const handleExploreGift = () => {
        card.recipientData.length > 1
          ? prompt({
              title: 'Gifting only supports one recipient!',
              subtitle:
                'currently our gifting feature only support one recipient at a time, please remove other recipients to enable gifting feature',
              onOk: noop,
              onCancel: undefined,
              okText: 'alright',
            })
          : // Go directly to the middle of the card creation flow for now
            history.push({
              pathname: INITIATOR_CHOOSE_GROUP_GIFT,
              search: qs.stringify({ viewCode: card.viewCode }),
              state: { from: location.pathname },
            });
      };

      const { setIsOpen, setCurrentStep } = useTour();

      const asyncSendCard = useAsyncCallback(
        () => store.sendCard({ message: card.recipientMessage || '' }),
        {
          onError: (e) =>
            enqueueErrorSnackbar(`something went wrong: ${e.message || 'unexpected error'}`),
          onSuccess: () => {
            setIsOpen(false);
            history.push(CARD_SEND_SUCCESS, {
              recipientData: JSON.stringify(card.recipientData),
            });
          },
        },
      );

      // effect to register viewing tutorial logic
      useEffect(() => {
        when(() => store.state.type === 'loaded')
          .then(() => cache.get('has_view_tutorial'))
          .then((hasView) =>
            card.timeLimit.type === 'deadline'
              ? isPast(new Date(card.timeLimit.value)) && !hasView
              : false,
          )
          .then((dateHasPassed) => setIsOpen(dateHasPassed))
          .then(() => cache.set('has_view_tutorial', { timestamp: new Date() }));
      }, []);

      useEffect(() => {
        if (card.recipientData[0]?.contact) {
          setHasSavedEmail(true);
        }
      }, []);

      const handleSendCard = () => {
        analytics.track(EVENT_NAMES.SEND_TO_RECIPIENT_MY_CARDS.name, {
          recipient: card.recipientData,
          noteCount: card.notes.length,
        });
        const cardEntity = card;
        if (recipientInfoStore.isValid) {
          setIsOpen(false);
          if (cardEntity.hasEitherGift) {
            if (cardEntity.eitherGiftMetGoals) {
              return asyncSendCard.execute();
            } else {
              prompt({
                title: 'GIFT IS NOT GOING TO BE INCLUDED',
                subtitle:
                  'the gift contributions are less than the gift goal. As a result, the gift will not be sent with the card. are you sure you want to send the card?',
                onOk: () => {
                  asyncSendCard.execute();
                },
                onCancel: noop,
                okText: 'yes',
                cancelText: 'no, cancel',
              });
            }
          } else {
            return asyncSendCard.execute();
          }
        } else {
          setIsOpen(true);
        }
      };

      return (
        <>
          <LayoutV2
            containerProps={{
              className: css`
                justify-content: center;
                // this fixes: https://github.com/elrumordelaluz/reactour/issues/192
                // overflow-y: unset;
                display: flex;
                ${mq.mobile} {
                  padding-top: 20px;
                }
              `,
            }}
          >
            {() => (
              <Section>
                <Section.Title
                  onLeftArrow={handleLeftArrowClick}
                  spelledNames={card.spelledRecipientNames}
                />
                <Section.RecipientInfo
                  disableContactEditing={disableContactEditing}
                  store={recipientInfoStore}
                  onPencilClick={() => setShownModalName('editRecipientName')}
                  cardUpdateService={(val) =>
                    store
                      .updateCard(val)
                      .then(() => enqueueSuccessSnackbar('card updated!'))
                      .then(() => setCurrentStep(1))
                      .then(() => setHasSavedEmail(true))
                      .catch((e) => {
                        enqueueErrorSnackbar(e?.message || 'unexpected error');
                        logger.error(e);
                      })
                  }
                  card={card}
                />
                <Section.ShareLink
                  onPencilClick={() => setShownModalName('editInitiatorName')}
                  onAddOrEdit={() => setShownModalName('contributor_intro_message')}
                  onShowList={() => {
                    analytics.track(EVENT_NAMES.VIEW_CONTRIBUTOR_LIST_MY_CARDS.name);
                    return setShownModalName('contributor');
                  }}
                  sendinvitationService={store.inviteContributors}
                  card={card}
                />
                <Section.SendCard
                  store={sendCardStore}
                  onPreviewCard={() => {
                    setIsOpen(false);
                    if (notes.length) {
                      setShownModalName('preview');
                    } else {
                      setShownModalName('no_note');
                    }
                  }}
                  action={{
                    onAction: handleSendCard,
                    disabled: asyncSendCard.loading,
                    loading: asyncSendCard.loading,
                  }}
                  cardUpdateService={(val) =>
                    store
                      .updateCard(val)
                      .then(() => {
                        if (val.deadlineTz) {
                          enqueueSuccessSnackbar('card updated!');
                        } else {
                          const date = new Date(val?.scheduledTimestamp);
                          const formattedDate = date?.toLocaleDateString('en-US');
                          const formattedTime = date.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true,
                          });
                          enqueueSuccessSnackbar(
                            `card schedule to be sent ${formattedDate} at ${formattedTime}! `,
                          );
                        }
                      })
                      .then(() => setCurrentStep(1))
                      .catch((e) => {
                        enqueueErrorSnackbar(e?.message || 'unexpected error');
                        logger.error(e);
                      })
                  }
                  card={card}
                  hasSavedEmail={hasSavedEmail}
                />
                {!card.giftV2OrError && !card.externalGift && !disableGifting && (
                  <Section.AddAGroupGift
                    onInfoClick={() => setShownModalName('how_it_works')}
                    onAddGift={(product) =>
                      history.push(
                        {
                          pathname: INITIATOR_CHOOSE_GROUP_GIFT,
                          search: qs.stringify({ viewCode: card.viewCode }),
                        },
                        { productDto: JSON.stringify(product._dto) },
                      )
                    }
                    products={merchantProductStore.merchantProducts || []}
                    onExplore={handleExploreGift}
                  />
                )}
                {card.externalGift && (
                  <AnimatePresence>
                    <ContributeCardGift
                      component={motion.div}
                      //@ts-ignore
                      initial={{ y: 200, opacity: 0 }}
                      transition={{ delay: 0.1 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -200, opacity: 0 }}
                      gift={fromExternalGift(card.externalGift)}
                      onViewGroupGiftDetails={() => setShownModalName('anygift')}
                      className="gift-container"
                    />
                  </AnimatePresence>
                )}
                {card.giftV2OrError &&
                  match(card.giftV2OrError)
                    .with({ id: P._ }, (gift) => (
                      <AnimatePresence>
                        <ContributeCardGift
                          component={motion.div}
                          //@ts-ignore
                          initial={{ y: 200, opacity: 0 }}
                          transition={{ delay: 0.1 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -200, opacity: 0 }}
                          gift={fromGift(gift)}
                          onViewGroupGiftDetails={() => setShownModalName('gift')}
                          className="gift-container"
                        />
                      </AnimatePresence>
                    ))
                    .with({ errType: P._ }, (gift) => {
                      <CloseCard
                        style={{ width: '100%' }}
                        text={gift.errMsg || 'something went wrong'}
                        variant="secondary"
                        size="default"
                        clickable={false}
                        onClick={undefined}
                        background={undefined}
                        fixedSize={undefined}
                        role={undefined}
                      />;
                    })
                    .exhaustive()}
                <div className={'notes-container'}>
                  <RectangularBoxWithPlusButton
                    text={match(store.state.type)
                      .with('refreshing', () =>
                        card.notes.length > 1 ? 'updating note..' : 'add note',
                      )
                      .otherwise(() => 'add note')}
                    onClick={onCreateNote}
                    size="full"
                    background={undefined}
                    blobColor={undefined}
                    plusColor={undefined}
                  />
                  {notes.map((note, index) => {
                    const { viewCode } = note;
                    const isMyNote = card.userContext.noteCodes.includes(viewCode);
                    const editable = isMyNote;
                    return (
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.1 * index }}
                        key={note.viewCode}
                      >
                        <NoteThubmnail
                          cardCode={card.viewCode}
                          note={NoteThubmnail.noteFromDomain(note)}
                          recipientName={spellNames(card.recipientData)}
                          previewable={!note.redacted}
                          editable={editable}
                          onPencilClick={noop}
                          // the trashbin icon
                          hideBadge={!isMyNote}
                          deleteService={() => deleteNoteAsync.execute(note.viewCode)}
                          deleteModalLoading={deleteNoteAsync.loading}
                          onViewNote={noop}
                        />
                      </motion.div>
                    );
                  })}
                </div>
                <Box
                  className="delete-container"
                  display="flex"
                  mb={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TrashBin color={colors.gray40} size={20} />
                  <Link onClick={() => setShownModalName('delete')} underline={true}>
                    delete card
                  </Link>
                </Box>
              </Section>
            )}
          </LayoutV2>
          {/* MODALS */}
          <Modal
            show={shownModalName === 'delete'}
            title={'are you sure you want to delete this card?'.toUpperCase()}
            onClose={() => setShownModalName(null)}
            promptConfig={{
              onOk: () =>
                removeCardAsync
                  .execute()
                  .then(() => setShownModalName(null))
                  .then(() => onDeleteCardSuccess(card))
                  .catch((e) => logger.error('something went wrong when deleting the note', e)),
              okLoading: removeCardAsync.loading,
              okText: 'yes, delete',
              onCancel: () => {
                setShownModalName(null);
              },
              cancelText: 'no, cancel',
            }}
          >
            {contributorCount > 0 && (
              <Text align="center" fontWeight={300}>
                {/* The font appears less bold in this <b /> tag due to tailwind's css reset override */}
                This card has{' '}
                <b>
                  {contributorCount} {pluralize('contributor', contributorCount)}
                </b>
                <br />
                By deleting the card, you will delete their notes as well.
              </Text>
            )}
          </Modal>

          {card.giftV2 && (
            <GroupGiftModal
              removable={true}
              removeAction={{
                remove: () => {
                  // don't return otherwise the modal stays
                  removeGift.execute().then(() => setShownModalName(null));
                },
                excecuting: removeGift.loading,
                error: removeGift.error,
              }}
              showModal={shownModalName === 'gift'}
              hideModal={() => setShownModalName(null)}
              gift={GroupGiftModalDTOMapper.formGiftV2(card.giftV2)}
              onContributeToGroupGift={(formValues) =>
                giftPaymentFromCardDetailsStore.handleContributeToGift(formValues, `${url}/pay`)
              }
              defaultValues={{
                email: userProfileStore.userProfileDTO?.email,
              }}
            />
          )}
          {card.externalGift && (
            <GroupGiftModal
              removable={true}
              removeAction={{
                remove: () => {
                  // don't return otherwise the modal stays
                  removeExternalGift.execute().then(() => setShownModalName(null));
                },
                excecuting: removeExternalGift.loading,
                error: removeExternalGift.error,
              }}
              showModal={shownModalName === 'anygift'}
              hideModal={() => setShownModalName(null)}
              gift={GroupGiftModalDTOMapper.fromExternalGift(card.externalGift)}
              onContributeToGroupGift={(formValues) =>
                giftPaymentFromCardDetailsStore.handleContributeToGift(formValues, `${url}/pay`)
              }
              defaultValues={{
                email: userProfileStore.userProfileDTO?.email,
              }}
            />
          )}
          <Modal
            show={shownModalName === 'howSendCard'}
            title={'how to send your group card'.toUpperCase()}
            onClose={() => setShownModalName(null)}
            promptConfig={{
              onOk: () => setShownModalName(null),
              okText: 'got it',
            }}
          >
            <Text align="center" fontWeight={300}>
              When the deadline arrives, you’ll be notified to enter the recipient’s email address
              at which point the card will get sent.
              <br />
              <br />
              (pssst if you want to send the card today, change the deadline to today!)
            </Text>
          </Modal>

          {/*workaround to refresh the default values*/}
          {shownModalName === 'editRecipientName' && (
            <RecipientNamesModal
              canAddMore={!card.batch}
              disableRecipientEdit={false}
              onSave={handleRenameRecipients}
              show={true}
              recipientData={card.recipientData}
              onClose={() => setShownModalName(null)}
            />
          )}

          {shownModalName === 'contributor' && (
            <ContributorsModal
              inviteList={inviteList}
              contributorList={contributorList}
              show={true}
              onClose={() => setShownModalName(null)}
              showInviteList={true}
            />
          )}
          {shownModalName === 'editInitiatorName' && (
            <InitiatorNameModal
              key={initiatorName}
              show={true}
              onClose={() => setShownModalName(null)}
              defaultValues={{ initiatorName }}
              onSave={handleRenameInitiator}
            />
          )}

          <PreviewModal
            open={shownModalName === 'preview'}
            notes={notes}
            name={card.spelledRecipientNames}
            onClose={() => setShownModalName(null)}
            showPrivacyModal={false}
            showNavigationButtons={notes.length > 1}
            //impossible states:
            // we will always have note
            loading={false}
            error={undefined}
            onRetry={noop}
          />

          <Modal
            show={shownModalName === 'no_note'}
            title={'NO PREVIEW YET!'}
            onClose={() => setShownModalName(null)}
            promptConfig={{
              onOk: () => setShownModalName(null),
              okText: 'got it',
            }}
          >
            <Text align="center" fontWeight={300}>
              Invite individuals to contribute to the card!
            </Text>
          </Modal>
          <HowItWorksModal
            open={shownModalName === 'how_it_works'}
            onClose={() => setShownModalName(null)}
            onGotIt={() => setShownModalName(null)}
          />
          {shownModalName === 'contributor_intro_message' && (
            <ContributorIntroMessageModal
              onCancel={() => setShownModalName(null)}
              show={true}
              onClose={() => setShownModalName(null)}
              defaultValues={{ message: card.contributorIntroMessage || undefined }}
              onSave={({ message }) => handleSaveIntroMessage(message)}
            />
          )}
        </>
      );
    },
  );
