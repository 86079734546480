import { useState } from 'react';

import { GifsResult } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Grid as GiphyGrid } from '@giphy/react-components';
import ResizeObserver from 'react-resize-observer';

interface ResponsiveGiphyGridProps {
  fetchGifs: (offset: number) => Promise<GifsResult>;
  onSelectGif: (gif: IGif) => any;
  columns: number;
}

const ResponsiveGiphyGrid: React.FunctionComponent<ResponsiveGiphyGridProps> = ({
  fetchGifs,
  onSelectGif,
  columns = 3,
}) => {
  const [width, setWidth] = useState(800);
  // we don't have loading state here.
  // since it's built in within the Grid component
  // but it's interesting how they don't want them shown on the first fetch
  // https://github.com/Giphy/giphy-js/blob/master/packages/react-components/src/components/grid.tsx#L39
  return (
    <div>
      <GiphyGrid
        fetchGifs={fetchGifs}
        width={width}
        hideAttribution
        columns={columns}
        gutter={6}
        onGifClick={(gif, e) => {
          e.preventDefault();
          onSelectGif(gif);
        }}
      />
      <ResizeObserver onResize={({ width }) => setWidth(width)} />
    </div>
  );
};

export default ResponsiveGiphyGrid;
