import { createAnalytics } from 'infra/analytics/factory';

const analyticsInstance = createAnalytics();

/**
 * @deprecated - old way to do things, hard to test
 */
export const track = (name, properties) => {
  analyticsInstance.track(name, properties);
  // const sessionPayload = getSessionPayload();
  // // assumes only a single integration at this point!
  // logger.log(`tracking ${name}`, properties);
  // window.analytics && window.analytics.track(name, properties, sessionPayload);
};

const analytics = {
  track,
};

export default analytics;
