import { useEffect, useState } from 'react';

import { Badge, Box, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import qs from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { BaseIconButton, Button } from 'components/illume/buttons';
import { CloseCard } from 'components/illume/close-card/CloseCard';
import { CountBadge } from 'components/illume/count-badge';
import { HtmlRenderer } from 'components/illume/html-renderer';
import { BackArrow, Info } from 'components/illume/icons';
import Arrow from 'components/illume/icons/Arrow';
import { Link } from 'components/illume/link';
import { TextBox } from 'components/illume/text-box';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { Loading } from 'components/Loading';
import ThreeDotsWave from 'components/Loading/ThreeDotsWaveLoading';
import {
  CoverAndNotesToPrint,
  usePrintRef,
} from 'components/pdf-pages-template/recipient-downloads/All';
import { colors, rem } from 'constants/design';
import {
  LOGIN_URL,
  MY_CARDS_URL,
  RECEIVE_READ_URL,
  RECEIVE_SHIP_TRACK_URL,
  RECEIVE_SHIP_URL,
} from 'constants/strings';
import { useAuth } from 'contexts/authentication';
import { useServices } from 'contexts/services';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { IShippingService } from 'domain/interfaces/IShippingService';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import cardServiceV2 from 'infra/card-service/cardService';
import { DetailsModal } from 'pages/illume/card-details-v2/card-details-v2-received/details-modal/DetailsModal';
import { ROLES } from 'pages/illume/card-details-v2/shared/constants';
import { getRandomLoadingLabel } from 'pages/illume/marketplace/pages/getRandomLoadingLabel';
import { TABS } from 'pages/illume/my-cards/MyCards';
import { ReplyAllModal } from 'pages/illume/recipient/OpenNote';
import { CARD_ERRORS, RecipientCardV2Dto } from 'types';
import { isGift } from 'types/guard';
import { CustomError, pluralize } from 'utils';
import { checkFF } from 'utils/featureFlag';
import logger from 'utils/logger';
import { spellNames } from 'utils/string';
import { truncateHtmlString } from 'utils/wrapSlice';

import {
  badgeStyles,
  cardVariants,
  useCardDetailStyles,
} from '../card-details-v2-pending/contributor/cardDetailsV2.styles';
import { NoteThubmnail } from '../note-thumbnail';

/**
 * i think we can share the store with the store from recipient routes
 */
class CardDetailsV2ReceivedStore {
  cardCode: string;
  fetchCardTask = new ObservablePromise((code: string) => cardServiceV2.getReceivedCard(code));
  fetchTrackingInfoTask = new ObservablePromise(this.shippingService.getProductTrackingInfo);

  errHandler?: (e: any) => any;

  constructor(
    code: string,
    errHandler: (e: any) => any,
    private shippingService: IShippingService,
    public readonly cardListUrl: string,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.cardCode = code;
    console.log('setting card code with', code);

    this.errHandler = errHandler;
    this.fetchCardTask.execute(this.cardCode).then((card) => {
      if (card.giftV2 || card.externalGift) {
        this.fetchTrackingInfoTask.execute(this.cardCode).catch((e) => {
          if (e.message.toLowerCase().includes('no order found')) {
            // expected error
            return;
          }
          console.error(e);
        });
      }
    });
  }

  fetchCard() {
    return this.fetchCardTask.execute(this.cardCode).catch(this.errHandler);
  }

  refetch = this.fetchCard;

  /**
   * doesnt make sense? should always be a recipient?
   */
  get role() {
    return this.fetchedCard?.role;
  }

  private get fetchedCard(): RecipientCardV2Dto | undefined {
    return this.fetchCardTask.getResultOrDefault();
  }

  get fethCardError() {
    return this.fetchCardTask.error;
  }

  get gift() {
    return this.fetchedCard?.giftV2 && isGift(this.fetchedCard.giftV2)
      ? this.fetchedCard.giftV2
      : undefined;
  }

  get externalGift() {
    return this.fetchedCard?.externalGift && ExternalGift.fromDto(this.fetchedCard.externalGift);
  }

  get giftError() {
    return this.fetchedCard?.giftV2 && !isGift(this.fetchedCard.giftV2)
      ? this.fetchedCard.giftV2
      : undefined;
  }

  get trackingInfo() {
    return this.fetchTrackingInfoTask.getResult();
  }

  get initializing() {
    return !this.fetchedCard && !this.fetchCardTask.error;
  }
}

function CardDetailsV2Received() {
  const { cardCode } = useParams<{ cardCode: string }>();
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const handleFetchV2Err = (e: CustomError) => {
    const message =
      e.errorCode === CARD_ERRORS.PERMISSION_DENIED
        ? 'please login with the account assoicated with the card 🙂'
        : e.message;
    enqueueErrorSnackbar(message);
    logger.error(e);
  };
  const { shippingService } = useServices();

  const [store] = useState(
    () => new CardDetailsV2ReceivedStore(cardCode, handleFetchV2Err, shippingService, MY_CARDS_URL),
  );

  const role = store.role;
  const history = useIllumeHistory();
  const [showModalCardDetail, setShowModalCardDetail] = useState(false);
  const { authenticated } = useAuth();
  const location = useLocation();
  const email = qs.parse(location.search)?.email as string;

  const [showReplyModal, setShowReplyModal] = useState(false);

  const card = store.fetchedCard;
  const {
    notes = [],
    initiatorName,
    recipientData,
    status,
    contributorCount,
    id: sendCode,
    occasionName,
    replies = [],
    cardReplyContext,
  } = card! || ({} as RecipientCardV2Dto);

  const { handlePrint, printRef } = usePrintRef();

  const handleDownloadPdf = () => {
    handlePrint();
  };

  const otherNoteSenderCount = contributorCount ? contributorCount - 1 : 0;
  const noteSender =
    initiatorName === undefined || initiatorName === '(anonymous)' ? 'Someone' : initiatorName;
  const singleContributorText = `${noteSender} shared appreciation.`;
  const multipleContributorsText = `${noteSender} + ${otherNoteSenderCount} other ${pluralize(
    'individual',
    otherNoteSenderCount,
  )} came together to share their appreciation.`;
  let text = contributorCount === 1 ? singleContributorText : multipleContributorsText;
  const hasReply = !!replies.length;
  const hasMyReply = !!cardReplyContext;

  const gift = store.gift
    ? {
        hasMetGoal: store.gift.financial.current >= store.gift.financial.goal,
        image: store.gift.product.variant.img,
        vendor: store.gift.product.vendor,
        description: store.gift.product.description,
        title: store.gift.product.name,
      }
    : store.externalGift
    ? {
        hasMetGoal: store.externalGift.giftMetGoals,
        image: store.externalGift.image,
        vendor: null,
        description: store.externalGift.description,
        title: store.externalGift.title,
      }
    : null;

  // TODO: might want to remove this
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  const gridOpts = {
    xs: 6,
    md: 3,
    component: motion.div,
    item: true,
    animate: 'visible',
    exit: 'out',
  };

  const desktop = useIsDesktop();
  const classes = useCardDetailStyles({ gift });
  const badgeClasses = badgeStyles();

  const cardGridStyles = {
    position: 'relative',
    y: 200,
    opacity: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  };

  function handleGoBack() {
    history.push(`${store.cardListUrl}?type=${TABS.RECEIVED}`);
  }

  useEffect(() => {
    if (store.fethCardError) {
      switch (store.fethCardError.errorCode) {
        case CARD_ERRORS.PERMISSION_DENIED:
          if (!authenticated) {
            history.push(LOGIN_URL, { from: location.pathname });
          } else {
            history.push(store.cardListUrl);
          }
          break;
        default:
          history.push(store.cardListUrl);
          break;
      }
    }
  }, [authenticated, store.cardListUrl, store.fethCardError]);

  if (store.initializing) {
    return <Loading text={getRandomLoadingLabel()} />;
  }

  // error case redirection
  if (store.fethCardError) {
    return null;
  }

  return (
    <IllumeLayout contentProps={{ direction: 'column', alignItems: 'center' }}>
      <Grid container className={classes.newContainer} spacing={2}>
        <Grid item container alignItems="center" xs={12} wrap="nowrap">
          <Grid item className={classes.arrowBack} onClick={handleGoBack}>
            <BaseIconButton size={desktop ? 36 : 20} icon={Arrow} onClick={handleGoBack} />
          </Grid>

          <Grid
            item
            container
            xs
            spacing={2}
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <Text
                align="center"
                fontWeight={900}
                fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
              >
                {spellNames(recipientData || [])?.toUpperCase()}'S GROUP CARD
              </Text>
            </Grid>
            <Badge
              classes={badgeClasses}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={<CountBadge count={!!cardReplyContext ? 0 : 1} isSmall />}
            >
              <Grid
                item
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowModalCardDetail(true);
                }}
              >
                <Info color={colors.gray40} isBlob />
              </Grid>
            </Badge>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container item spacing={1} justifyContent="center" alignItems="center">
            <Grid item>
              <Text fontWeight={300} fontSize={rem[1000]} data-testid="intro-text">
                {store.fetchCardTask.isExecuting ? <CircularProgress size={12} /> : text}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} item justifyContent="center" className={classes.cardListing}>
        {!!gift && gift.hasMetGoal && (
          <>
            <div className={classes.giftDetailsContainer}>
              <div className={classes.giftImage}>
                <img className={classes.imgPlaceholder} src={gift.image} alt={'Product'} />
              </div>
              <div className={classes.giftDetails}>
                <div className={classes.giftInfo}>
                  <div className={classes.giftTopText}>
                    <Text
                      fontWeight="bold"
                      lineHeight={'36px'}
                      fontSize={{ desktop: 28, mobile: 24 }}
                    >
                      {gift.title}
                    </Text>
                    {gift.vendor && (
                      <Text lineHeight={'18px'} fontWeight={400} fontSize={16}>
                        {gift.vendor}
                      </Text>
                    )}
                  </div>
                  <Text lineHeight={'18px'} fontWeight={400} fontSize={{ desktop: 16, mobile: 14 }}>
                    <HtmlRenderer html={truncateHtmlString(gift.description, 250)} />
                  </Text>
                </div>
                <div>
                  {store.fetchTrackingInfoTask.isExecuting ? (
                    <Text>getting tracking info..</Text>
                  ) : store.trackingInfo ? (
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: RECEIVE_SHIP_TRACK_URL,
                          search: qs.stringify({ c: sendCode }),
                        });
                      }}
                      customcolor="white"
                      className={classes.giftDetailsButton}
                      fullWidth
                    >
                      Track Gift
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: RECEIVE_SHIP_URL,
                          search: qs.stringify({ c: sendCode }),
                        })
                      }
                      fullWidth
                    >
                      SEND MY GIFT
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {store.giftError && (
              <CloseCard
                style={{ width: '100%', marginBottom: 24 }}
                text={store.giftError.errMsg}
                variant="secondary"
                size="default"
                clickable={false}
                onClick={undefined}
                background={undefined}
                fixedSize={undefined}
                role={undefined}
              />
            )}
          </>
        )}
        {notes?.map((note, index) => {
          return (
            <Grid
              {...gridOpts}
              key={index}
              custom={index + 1}
              variants={cardVariants(0)}
              style={cardGridStyles}
            >
              <NoteThubmnail
                note={NoteThubmnail.noteFromDTO(note)}
                recipientName={spellNames(recipientData)}
                onViewNote={() =>
                  history.push(
                    //TODO: hacky
                    {
                      pathname: RECEIVE_READ_URL,
                      search: qs.stringify({ c: sendCode }),
                    },
                    {
                      from: MY_CARDS_URL,
                      noteViewCode: note.viewCode,
                    },
                  )
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        direction="column"
        alignItems={hasMyReply ? undefined : 'center'}
        item
        className={classes.replyBox}
        spacing={2}
      >
        {hasReply && (
          <Grid item container xs direction="column" spacing={2} style={{ textAlign: 'left' }}>
            {replies.map(({ message, status, replier, id }, index) => {
              const isMyReply = id === cardReplyContext?.id;
              return (
                <Grid item key={id + index}>
                  <TextBox
                    className={cx('fs-mask', classes.replyListCard)}
                    minHeight={64}
                    text={message}
                    label={isMyReply ? 'Your Reply' : `${replier}'s Reply`}
                    shadow={status === 'Unread'}
                    color={colors.gray100}
                    dark
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {!hasMyReply ? (
          <Grid item className={classes.button}>
            <Button
              onClick={() => setShowReplyModal(true)}
              fullWidth
              startIcon={<BackArrow style={{ marginRight: -5 }} color={colors.contrastText} />}
            >
              send reply all
            </Button>
          </Grid>
        ) : (
          <Grid item className={classes.button}>
            <Button
              onClick={() => {
                history.push({
                  pathname: MY_CARDS_URL,
                });
              }}
              fullWidth
            >
              next
            </Button>
          </Grid>
        )}
        {checkFF('DOWNLOAD_CARD_AS_PDF') && (
          <Grid item className={classes.downloadPdfCta}>
            <Link
              underline
              fontSize={16}
              fontWeight={500}
              color={colors.gray40}
              onClick={handleDownloadPdf}
            >
              Download as PDF
            </Link>
          </Grid>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={store.fetchCardTask.isExecuting}
      >
        <Grid item>
          <Alert severity="warning" icon={false}>
            <Box display="flex" alignItems={'center'}>
              <Box mr={1}>
                <Text>refreshing</Text>
              </Box>
              <Box mt={1}>
                <ThreeDotsWave color={'rgb(102, 60, 0)'} />
              </Box>
            </Box>
          </Alert>
        </Grid>
      </Snackbar>
      {card && (
        <DetailsModal
          onSendReplyAll={() => {
            setShowModalCardDetail(false);
            setShowReplyModal(true);
          }}
          role={role || ROLES.RECIPIENT} // it must be an initiator in RECEIVED tab, but just in case
          open={showModalCardDetail}
          show={showModalCardDetail}
          cardV2={card}
          onClose={() => setShowModalCardDetail(false)}
        />
      )}
      <ReplyAllModal
        sendCode={sendCode}
        onReplySuccess={() => {
          store.refetch();
          setShowReplyModal(false);
        }}
        showReplyModal={showReplyModal}
        setShowReplyModal={(val) => setShowReplyModal(val)}
        recipientEmail={email}
      />
      {card && <CoverAndNotesToPrint data={{ card }} ref={printRef} />}
    </IllumeLayout>
  );
}

export default observer(CardDetailsV2Received);
