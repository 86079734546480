export const Edit = ({ size = 36 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
        fill="#FFFDF3"
      />
      <path
        d="M22.8567 14.7312L24.0598 13.5079C24.9613 12.5935 24.8346 10.9786 23.7776 9.90792C23.211 9.33048 22.465 9 21.7314 9C21.1374 9 20.593 9.22032 20.2006 9.6192L18.9961 10.8425L11.0192 18.941C10.9609 19.0008 10.9177 19.0714 10.8838 19.1462C10.873 19.1686 10.8666 19.1916 10.8586 19.2146C10.8493 19.2406 10.8356 19.265 10.8291 19.2924L10.017 22.9961C9.96369 23.2366 10.0378 23.4871 10.2121 23.6606C10.3482 23.796 10.5318 23.8694 10.7197 23.8694C10.7722 23.8694 10.8255 23.8637 10.8788 23.8522L14.5263 23.027C14.5501 23.0213 14.5717 23.009 14.5947 23.0011C14.6185 22.9925 14.6408 22.9853 14.6646 22.9745C14.7344 22.9428 14.7985 22.9018 14.8554 22.8499C14.8604 22.8442 14.8676 22.8427 14.8726 22.8377C14.8734 22.8362 14.8741 22.8348 14.8748 22.8341C14.8762 22.8319 14.8784 22.8312 14.8798 22.8298L22.8567 14.7312ZM21.7314 10.44C22.0762 10.44 22.4578 10.6186 22.7523 10.9181C23.2513 11.4235 23.3809 12.1471 23.0346 12.4978L22.3441 13.2005L20.5189 11.3479L21.2259 10.6294C21.3807 10.4731 21.5859 10.44 21.7314 10.44ZM11.9509 20.8973L12.9459 21.9074L11.6658 22.1976L11.9509 20.8973ZM14.3672 21.2983L12.5427 19.4465L19.5087 12.3739L21.3332 14.2265L14.3672 21.2983Z"
        fill="#FEB81C"
      />
      <path
        d="M25.5303 20.5142H23.8448V18.72C23.8448 18.3218 23.5222 18 23.1248 18C22.7274 18 22.4048 18.3218 22.4048 18.72V20.5142H20.72C20.3226 20.5142 20 20.8361 20 21.2342C20 21.6324 20.3226 21.9542 20.72 21.9542H22.4048V23.7492C22.4048 24.1474 22.7274 24.4692 23.1248 24.4692C23.5222 24.4692 23.8448 24.1474 23.8448 23.7492V21.9542H25.5303C25.9278 21.9542 26.2503 21.6324 26.2503 21.2342C26.2503 20.8361 25.9278 20.5142 25.5303 20.5142Z"
        fill="#FEB81C"
      />
    </svg>
  );
};
