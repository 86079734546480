import { css } from '@emotion/css';
import classNames from 'classnames';

interface Props extends React.SVGProps<SVGSVGElement> {
  open: boolean;
  className?: string;
}

export const ChevronDownAndClose = ({ className, open, ...props }: Props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(
      css`
        & > path {
          transition: transform 0.2s ease-in-out;
        }
      `,
      className,
    )}
    {...props}
  >
    <path
      d="M12 16.4992L4.5 8.99922L5.55 7.94922L12 14.3992L18.45 7.94922L19.5 8.99922L12 16.4992Z"
      fill="inherit"
      transform={open ? 'rotate(-90 12 12) translate(0, -3)' : ''}
    />
    <path
      transform={open ? 'rotate(90 12 12) translate(0, -4)' : ''}
      d="M12 16.4992L4.5 8.99922L5.55 7.94922L12 14.3992L18.45 7.94922L19.5 8.99922L12 16.4992Z"
      fill="inherit"
    />
  </svg>
);
