import { useMemo } from 'react';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { colors } from 'constants/design';
import {
  generateBackgroundFromPalette,
  generateContrastFromPalette,
} from 'constants/design/cardTheme';
import { PaletteDTO } from 'types';
import { noop, toKeyValuePairs } from 'utils';

import { ThemeSelectItemIcon } from '../icons';

const themeControlStyles = makeStyles(() => ({
  child: {
    backgroundColor: colors.gray60,
  },
  root: {
    padding: 0,
  },
}));

const ThemeIcon = ({ palette, selected, size, activeColor }) => {
  return (
    <ThemeSelectItemIcon
      activeColor={activeColor}
      selected={selected}
      size={size}
      innerFill={generateContrastFromPalette(palette)}
      outerFill={generateBackgroundFromPalette(palette, null, { isIcon: true })}
    />
  );
};

// Note: checkedIcon must be set or else the visual will revert to a regular radio button.
const ThemeOption = ({ palette, value, size, activeColor }) => {
  const { child, ...classes } = themeControlStyles();

  return (
    <FormControlLabel
      value={value}
      style={{ margin: 0 }}
      control={
        <Radio
          disableRipple
          TouchRippleProps={{ classes: { child } }}
          icon={<ThemeIcon size={size} palette={palette} selected={false} />}
          checkedIcon={
            <ThemeIcon size={size} palette={palette} selected={true} activeColor={activeColor} />
          }
          data-testid={`${palette?.paletteCode}-theme-selector`}
          classes={classes}
        />
      }
    />
  );
};

export interface NewThemeSelectorProps {
  onChange: (paletteDto: PaletteDTO) => any;
  activeColor?: string;
  keyIdentifier?: keyof PaletteDTO;
  palettes: PaletteDTO[];
  paletteSize?: number;
  gap: number;
  value: PaletteDTO;
}
/**
 *
 * @todo rename to PaletteSelector
 */
const NewThemeSelector: React.FunctionComponent<NewThemeSelectorProps> = ({
  activeColor = colors.contrastText,
  keyIdentifier = 'paletteCode',
  onChange = noop,
  paletteSize = 37,
  palettes,
  value = {},
  gap,
}) => {
  const palettesObject = useMemo(
    () => toKeyValuePairs(palettes, keyIdentifier),
    [keyIdentifier, palettes],
  );

  function handleChange(e) {
    onChange(palettesObject[e.target.value]);
  }
  return (
    <FormControl component="fieldset" style={{ width: '100%' }}>
      <FormLabel component="legend" aria-label="Theme selector" />
      <RadioGroup
        aria-label="theme"
        value={value.paletteCode}
        onChange={handleChange}
        row
        style={{ justifyContent: 'space-between', flexWrap: 'nowrap', columnGap: gap }}
      >
        {Object.keys(palettesObject).map((paletteCode) => (
          <ThemeOption
            size={paletteSize}
            activeColor={activeColor}
            key={paletteCode}
            palette={palettesObject[paletteCode]}
            value={paletteCode}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default NewThemeSelector;
