import { Maybe } from '@illume/shared';
import { action, makeAutoObservable } from 'mobx';

import logger from 'utils/logger';

type Idle = { type: 'idle' };
type CacheRetrieve = { type: 'cache_retrieve' };
type Pending = { type: 'pending' };
type Success<T> = { type: 'success'; data: T };
type Refreshing<T> = { type: 'refreshing'; data?: T };
type Reject<T> = { type: 'error'; error: Error; data?: T };

export interface IAsyncStoreCache<T> {
  set: (data: T) => Promise<void>;
  get: () => Promise<Maybe<T>>;
}

export class ExperimentalAsyncCachedStore<T> {
  state: Idle | CacheRetrieve | Pending | Success<T> | Refreshing<T> | Reject<T> = {
    type: 'idle',
  };
  constructor(private task: () => Promise<T>, private cache: IAsyncStoreCache<T>) {
    makeAutoObservable(this);
  }
  updateData = (newData: T) => {
    if ('data' in this.state) {
      this.state.data = newData;
      this.cache.set(newData);
    }
  };
  execute = () => {
    this.state = { type: 'pending' };
    return this.cache
      .get()
      .then((data) => {
        if (data) {
          logger.log('got data from cache: ', data);
          return (this.state = { type: 'refreshing', data: data });
        } else {
          return logger.log('cache data not found, getting it from server');
        }
      })
      .then(() => this.task())
      .then(action((r) => (this.state = { type: 'success', data: r })))
      .then((state) => {
        // cache data
        logger.log('setting cache..', state.data);
        this.cache.set(state.data);
        return state.data;
      })
      .catch(action((e) => (this.state = { type: 'error', error: e })));
  };
  refresh = () => {
    if (this.state.type === 'success') {
      this.state = { type: 'refreshing', data: this.state.data };
      return this.task()
        .then(action((r) => (this.state = { type: 'success', data: r })))
        .then(async (state) => {
          await this.cache.set(state.data);
          return state.data;
        })
        .then((data) => logger.log('cache set', data))
        .catch(
          action((e) => {
            if (this.state.type === 'success')
              return (this.state = {
                type: 'error',
                error: e,
                data: this.state.data,
              });
          }),
        );
    }
  };
}
