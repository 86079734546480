import Sentry from 'infra/sentry/sentry';

import config from '../config';
import { isFullstoryReady } from '../infra/fullstory/fullStory';
import CustomError from './CustomError';

export interface ILogger {
  log(...args: any[]): void;
  error(msg: string | { message: string }, obj?: any): void;
  warn(...args: any[]): void;
}

class SentryXFullStoryLogger implements ILogger {
  private useConsole: boolean;

  constructor(config: { console: boolean }) {
    this.useConsole = config.console;
  }

  public log(...args: any[]) {
    if (this.useConsole) {
      // might need to do some conditional logging about whether to pass in `undefined` values
      console.log(...args);
    } else {
      // do something else in the future!
    }
  }

  public error(messageOrErr: string | Error | CustomError, obj?: any) {
    const msg = typeof messageOrErr === 'string' ? messageOrErr : messageOrErr.message;

    // log fullstory session to sentry
    isFullstoryReady()
      .then(({ FsSessionUrl, FsSessionId }) => {
        Sentry.captureMessage(msg, { contexts: { FsSessionUrl, FsSessionId } });
      })
      .catch((e) => {
        console.error('something went wrong with fullstory analytics:', e);
        Sentry.captureMessage(msg, { contexts: { errObj: obj, msg: msg as any } });
      });
  }

  public warn(...args: any[]) {
    if (this.useConsole) {
      console.warn(...args);
    } else {
      // do something else in the future!
    }
  }
}

class SilentLogger implements ILogger {
  public log(...args: any[]) {}
  public error(msg: string, obj?: any) {}
  public warn(...args: any[]) {}
}

const createLogger = (config: { logger: { console: boolean; silence: boolean } }): ILogger => {
  if (config.logger.silence) {
    return new SilentLogger();
  }
  return new SentryXFullStoryLogger(config.logger);
};

export default createLogger(config);
