import React from 'react';

import Radio, { RadioProps } from '@material-ui/core/Radio';
import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { colors } from 'constants/design';

const useStyles = makeStyles({
  root: {
    padding: 0,
    paddingTop: 5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '100%',
    width: 16,
    height: 16,
    border: `1px solid #28282966`,
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: lighten(colors.primary, 0.8),
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'none',
      border: `1px solid ${colors.gray25}`,
    },
  },
  checkedIcon: {
    backgroundColor: colors.primary,
    position: 'relative',
    border: 'none',
    'input:hover ~ &': {
      backgroundColor: lighten(colors.primary, 0.3),
    },
    '& > .white-dot': {
      display: 'block',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      top: '50%',
      left: '50%',
      width: 5,
      height: 5,
      borderRadius: '100%',
      backgroundColor: colors.background,
    },
  },
});

type Props = RadioProps;

// Inspired by blueprintjs
export function CustomRadio(props: Props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checkedIcon)}>
          <span className="white-dot"></span>
        </span>
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
