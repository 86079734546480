import { useEffect, useMemo, useState } from 'react';

import chunk from 'lodash/chunk';

type Options<T> = { data: T[]; delay: number; fakeLoading: boolean; chunkLength: number };

export function useChunk<T>(options: Options<T>) {
  const { data = [], chunkLength = 1, fakeLoading, delay } = options || {};
  const initialPage = 0;
  const pages = useMemo(() => chunk(data, chunkLength), [data, chunkLength]);
  const [renderedData, setRenderedData] = useState(pages[initialPage] || []);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const hasMore = renderedData?.length < data.length;

  useEffect(() => {
    setRenderedData(pages[initialPage] || []);
  }, [pages]);

  const loadMore = async () => {
    const concatChunk = () => {
      setRenderedData((prev) => prev.concat(pages[currentPage + 1]));
      setCurrentPage((prev) => prev + 1);
    };
    if (hasMore) {
      if (fakeLoading) {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            concatChunk();
            resolve();
          }, delay);
        });
      } else {
        concatChunk();
      }
    }
  };

  const reset = () => {
    setRenderedData(pages[initialPage]);
    setCurrentPage(initialPage);
  };

  return { loadMore, renderedData, reset, hasMore };
}
