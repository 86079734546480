const ProgressCircles = ({ type }) => {
  const primary = (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00162312 3.25295C-0.0326266 2.56027 0.477475 1.82261 1.31186 1.26266C2.20235 0.664993 3.18976 0.42346 4.25369 0.492366C4.86726 0.531534 5.44514 0.698358 5.93046 1.09946C6.246 1.35985 6.49012 1.67972 6.70072 2.02788C7.07236 2.6415 7.38352 3.28124 7.55914 3.98118C7.80254 4.95021 7.56497 5.80537 6.94411 6.56623C6.29336 7.36337 5.42838 7.7681 4.42056 7.90156C3.71589 7.99512 3.02944 7.92114 2.36267 7.67453C1.54869 7.37352 0.964258 6.8172 0.596256 6.044C0.228983 5.27371 0.0285856 4.45917 0.00162312 3.25295Z"
        fill="#FEB81C"
      />
    </svg>
  );

  const light = (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00162312 3.25295C-0.0326266 2.56027 0.477475 1.82261 1.31186 1.26266C2.20235 0.664993 3.18976 0.42346 4.25369 0.492366C4.86726 0.531534 5.44514 0.698358 5.93046 1.09946C6.246 1.35985 6.49012 1.67972 6.70072 2.02788C7.07236 2.6415 7.38352 3.28124 7.55914 3.98118C7.80254 4.95021 7.56497 5.80537 6.94411 6.56623C6.29336 7.36337 5.42838 7.7681 4.42056 7.90156C3.71589 7.99512 3.02944 7.92114 2.36267 7.67453C1.54869 7.37352 0.964258 6.8172 0.596256 6.044C0.228983 5.27371 0.0285856 4.45917 0.00162312 3.25295Z"
        fill="#FFFDF3"
      />
    </svg>
  );

  const dark = (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.175207 3.25295C0.140957 2.56027 0.651059 1.82261 1.48544 1.26266C2.37593 0.664993 3.36334 0.42346 4.42727 0.492366C5.04085 0.531534 5.61872 0.698358 6.10405 1.09946C6.41958 1.35985 6.6637 1.67972 6.8743 2.02788C7.24594 2.6415 7.55711 3.28124 7.73273 3.98118C7.97612 4.95021 7.73856 5.80537 7.11769 6.56623C6.46695 7.36337 5.60196 7.7681 4.59414 7.90156C3.88948 7.99512 3.20303 7.92114 2.53625 7.67453C1.72227 7.37352 1.13784 6.8172 0.76984 6.044C0.402567 5.27371 0.20217 4.45917 0.175207 3.25295Z"
        fill="#282829"
        fillOpacity="0.25"
      />
    </svg>
  );

  return type === 'primary' ? primary : type === 'light' ? light : dark;
};

export default ProgressCircles;
