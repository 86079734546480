import { colors } from 'constants/design';

const MarketplaceIcon = ({ color = colors.contrastText }) => {
  return (
    <svg
      className="img"
      height="20px"
      width="20px"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8754 5.47625L18.4379 0.66375C18.3004 0.485 18.0941 0.375 17.8741 0.375H4.12414C3.90414 0.375 3.69789 0.485 3.56039 0.66375L0.122887 5.47625L-0.000863078 5.8475C-0.0146131 7.37375 1.22289 8.625 2.74914 8.625C3.57414 8.625 4.30289 8.2675 4.81164 7.69C5.32039 8.2675 6.04914 8.625 6.87414 8.625C7.69914 8.625 8.42789 8.2675 8.93664 7.69C9.44539 8.2675 10.1741 8.625 10.9991 8.625C11.8241 8.625 12.5529 8.2675 13.0616 7.69C13.5704 8.2675 14.2991 8.625 15.1241 8.625C15.9491 8.625 16.6779 8.2675 17.1866 7.69C17.6954 8.2675 18.4241 8.625 19.2491 8.625C20.7754 8.625 22.0129 7.37375 21.9991 5.8475L21.8754 5.47625Z"
        fill={color}
      />
      <path
        d="M17.1875 9.44448C15.9445 10.1636 14.3055 10.1636 13.0625 9.44448C11.8195 10.1636 10.1792 10.1636 8.9375 9.44448C7.69584 10.1636 6.05422 10.1636 4.8125 9.44448C3.77989 10.044 2.50122 10.121 1.375 9.73188V16.8749C1.375 17.6339 1.99104 18.2499 2.75 18.2499H13.75V11.3749H17.875V18.2499H19.25C20.009 18.2499 20.625 17.6339 20.625 16.8749V9.74819C20.1877 9.9036 19.7271 9.99985 19.25 9.99985C18.5199 9.99985 17.809 9.80464 17.1875 9.44434V9.44448ZM9.625 15.5H4.125V11.375H9.625V15.5Z"
        fill={color}
      />
    </svg>
  );
};

export default MarketplaceIcon;
