import { makeStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles(() => ({
  root: {
    animation: 'pulse-primary 2s infinite',
    borderRadius: '50%',
  },
}));

export const CardTiles = ({ color = '#FEB81C', size = 28 }) => {
  const classes = useStyles({ color });
  return (
    <div className={classes.root}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5664 3.11719V20.043C25.5664 20.4504 25.2355 20.7812 24.8281 20.7812H21.875V7.90234C21.875 6.89062 21.052 6.07031 20.043 6.07031H7.16406V3.11719C7.16406 2.70977 7.49492 2.37891 7.90234 2.37891H24.8281C25.2355 2.37891 25.5664 2.70977 25.5664 3.11719Z"
          fill={color}
        />
        <path
          d="M20.7812 7.90234V24.8281C20.7812 25.2355 20.4504 25.5664 20.043 25.5664H3.11719C2.70977 25.5664 2.37891 25.2355 2.37891 24.8281V7.90234C2.37891 7.49492 2.70977 7.16406 3.11719 7.16406H20.043C20.4504 7.16406 20.7812 7.49492 20.7812 7.90234Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
