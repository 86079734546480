import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: ({ rounded, fullWidth, background, borderRadius }) => {
    const topRounded = {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    };

    const bottomRounded = {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    };

    return {
      background,
      transition: theme.transitions.create(['background'], {
        duration: 300,
        easing: 'ease',
      }),
      ...(rounded?.includes('top') && topRounded),
      ...(rounded?.includes('bottom') && bottomRounded),
      width: fullWidth && '100%',
    };
  },
}));
