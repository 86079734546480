import { useStyles } from './LinedText.styles';
const LinedText = ({ text, color, textBackground, fontWeight }) => {
  const classes = useStyles({ color, textBackground, fontWeight });
  return (
    <div className={classes.divStyles}>
      <span className={classes.spanStyles}>{text}</span>
    </div>
  );
};

export default LinedText;
