import { createContext, ReactNode, useContext, useState } from 'react';

import { observer } from 'mobx-react';
import { useAsyncCallback } from 'react-async-hook';

import BirthdayModal from 'components/birthday-modal/BirthdayModal';
import IllumeSnackbar from 'components/snackbar';
import { useStores } from 'contexts/store';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { UserProfileDTO } from 'types';

const BirthdayModalProviderContext = createContext({
  visible: false,
  hide: () => {},
  show: () => {},
});

export const useBirthdayModalContext = () => useContext(BirthdayModalProviderContext);

export const BirthdayModalProvider = observer(
  ({
    children,
    service,
    analytics: identify,
  }: {
    children: ReactNode;
    service: (deadline: Date) => Promise<UserProfileDTO>;
    analytics: IAnalytics;
  }) => {
    const [showBirthday, setShowBirthday] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const { userProfileStore, authenticationStore } = useStores();
    const { userId } = authenticationStore;

    const birthdayTask = useAsyncCallback(service, {
      onSuccess: (user) => {
        if (userId) {
          identify.identify(userId, {
            email: userProfileStore.email,
            birthday: new IllumeDate(new Date(user.birthday)).toAPIString(),
          });
        }
        setShowSuccessAlert(true);
        setShowBirthday(false);
      },
      onError: () => {
        setShowErrorAlert(true);
      },
    });

    return (
      <BirthdayModalProviderContext.Provider
        value={{
          hide: () => setShowBirthday(false),
          show: () => setShowBirthday(true),
          visible: showBirthday,
        }}
      >
        {children}

        <BirthdayModal
          visible={showBirthday}
          hide={() => setShowBirthday(false)}
          show={() => setShowBirthday(true)}
          action={{
            loading: birthdayTask.loading,
            onAction: (bd) => birthdayTask.execute(bd),
          }}
        />
        <IllumeSnackbar
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          autoHideDuration={2000}
          message="added your birthday!"
          onClose={() => setShowSuccessAlert(false)}
          open={showSuccessAlert}
          severity="success"
        />
        <IllumeSnackbar
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          autoHideDuration={2000}
          message="something went wrong"
          onClose={() => setShowErrorAlert(false)}
          open={showErrorAlert}
          severity="error"
        />
      </BirthdayModalProviderContext.Provider>
    );
  },
);
