import React from 'react';

import qs from 'query-string';
import { match } from 'ts-pattern';

import { Button } from 'components/illume/buttons';
import { HtmlRenderer } from 'components/illume/html-renderer';
import { Close } from 'components/illume/icons';
import { Surface } from 'components/illume/surface';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import { RECEIVE_SHIP_URL } from 'constants/strings';
import Gift from 'domain/entities/gift/gift';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import logger from 'utils/logger';

import useStyles from './GiftShipmentDetails.styles';
import { RecipientStore } from './store';

export interface ShippingFormProps {
  store: RecipientStore;
}

const GiftShipmentDetails: React.FC<ShippingFormProps> = ({ store }) => {
  const history = useIllumeHistory();
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const desktop = useIsDesktop();

  const { id: sendCode } = store.card! || {};

  const classes = useStyles();

  const onTrack = async () => {
    return store.getTrackingShipmentInfoTask
      .execute(sendCode)
      .then((res) => window.open(res, '_blank'))
      .catch((e: Error) => {
        history.back();
        logger.error(e);
        return enqueueErrorSnackbar(
          "unable to get shipment details, probably the merchant hasn't shipped their product",
        );
      });
  };

  const gift = store.cardGift
    ? {
        type: 'cardGift',
        hasMetGoal: store.cardGift.financial.current >= store.cardGift.financial.goal,
        image: store.cardGift.product.variant.img,
        vendor: store.cardGift.product.vendor,
        description: store.cardGift.product.description,
        title: store.cardGift.product.name,
        highlights: store.cardGift.product.highlights,
        orderShippable: !Gift.fromDto(store.cardGift).orderTrackable,
        orderTrackable: Gift.fromDto(store.cardGift).orderTrackable,
      }
    : store.externalGift
    ? {
        type: 'externalGift',
        hasMetGoal: store.externalGift.giftMetGoals,
        image: store.externalGift.image,
        vendor: null,
        description: store.externalGift.description,
        title: store.externalGift.title,
        orderShippable: store.externalGift.shippable,
        orderTrackable: store.externalGift.trackable,
      }
    : null;

  return (
    <IllumeLayout
      hideHamburger
      topRightButtons={[
        {
          icon: <Close size={desktop ? 51 : 44} />,
          action: {
            onAction: () => history.back(),
          },
        },
      ]}
      contentProps={{
        justifyContent: 'center',
      }}
    >
      {gift && (
        <div className={classes.container}>
          <div className={classes.productImageContainer}>
            <img src={gift?.image} alt={'gift'} className={classes.productImage} />
          </div>
          <div className={classes.productDetails}>
            {
              <div className={classes.productTopTexts}>
                <div className={classes.productName}>
                  <Text fontSize={rem[2250]} fontWeight={800}>
                    {gift.title}
                  </Text>
                  {gift?.vendor && (
                    <Text fontSize={rem[1125]} fontWeight={400}>
                      {gift.vendor}
                    </Text>
                  )}
                </div>
                <div>
                  <Text fontSize={rem[1000]} fontWeight={400} italic>
                    <HtmlRenderer html={gift.description} />
                  </Text>
                </div>
                {gift.highlights && (
                  <div className={classes.tags}>
                    {gift.highlights.map(({ highlight, color }) => {
                      return (
                        <Surface
                          rounded={['top', 'bottom']}
                          paddingX={1}
                          paddingY={0.7}
                          borderRadius={4}
                          background={color}
                          key={highlight}
                        >
                          <Text fontSize={{ desktop: 14, mobile: 14 }} color={colors.contrastText}>
                            {highlight}
                          </Text>
                        </Surface>
                      );
                    })}
                  </div>
                )}
              </div>
            }
            {store.giftError && <Text>an error has occured</Text>}
            <div className={classes.productButtons}>
              {gift.orderTrackable &&
                match(gift.type)
                  .with('externalGift', () => {
                    return (
                      <Text fontSize={16} fontWeight={500} color={colors.gray60} italic>
                        *you will receive the gift information to your email within 24 hours.
                      </Text>
                    );
                  })
                  .otherwise(() => {
                    return (
                      <Button className={classes.productTrackButton} onClick={onTrack} fullWidth>
                        Track Order
                      </Button>
                    );
                  })}
              {gift.orderShippable && (
                <Button
                  onClick={() =>
                    history.push({
                      pathname: RECEIVE_SHIP_URL,
                      search: qs.stringify({ c: sendCode }),
                    })
                  }
                  fullWidth
                >
                  SEND MY GIFT
                </Button>
              )}
              <Button
                className={classes.productHelpButton}
                onClick={() => {
                  window.location.href = 'mailto: email@tryillume.com';
                }}
                customcolor="gray"
                fullWidth
              >
                Help with order
              </Button>
            </div>
          </div>
        </div>
      )}
    </IllumeLayout>
  );
};

export default GiftShipmentDetails;
