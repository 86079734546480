import { useEffect, useState } from 'react';

import { ButtonBase, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { wait } from '@testing-library/user-event/dist/utils';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';

import { Menu } from 'components/illume/menu';
import Tooltip, { TooltipProps } from 'components/illume/tooltip/Tooltip';
import { getProdMarketingSiteUrl } from 'constants/strings';
import { useNotificationContext } from 'contexts/notification';
import { Action } from 'types';
import { noop } from 'utils';

import { IllumeText } from '../../illume/icons';
import { styleContainer } from './Appbar.styles';

const TooltippedComponent: React.FC<TooltippedComponentProps> = ({
  tooltipProps,
  timeProps,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { waitTime = 300, showTime = 3000 } = timeProps || {};

  const { text, variant = 'other', widthArrow: width = '10%', placement = 'bottom' } = tooltipProps;

  useEffect(() => {
    wait(waitTime)
      .then(() => setShowTooltip(true))
      .then(() => wait(showTime))
      .then(() => setShowTooltip(false));
  }, []);

  return (
    <Tooltip
      text={text}
      variant={variant}
      widthArrow={width}
      placement={placement}
      showTooltip={showTooltip}
    >
      <motion.div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </motion.div>
    </Tooltip>
  );
};

const usePreviewButtonClass = makeStyles(() => ({
  root: {
    '&:focus': { outline: 'none' },
    '&:hover': { background: 'none' },
    padding: 0,
  },
}));

const useAppBarClass = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
  },
}));
export interface AppBarProps {
  illumeLogoColor?: string;
  hideHamburger?: boolean;
  showIllumeTitle?: boolean;
  topRightButtons:
    | {
        action: Omit<Action, 'text' | 'loading'>;
        icon: JSX.Element;
        tooltipText?: string;
      }[]
    | null
    | undefined;
}

const Appbar = ({
  illumeLogoColor,
  hideHamburger: hideHamburgerProp,
  showIllumeTitle = true,
  topRightButtons,
}: AppBarProps) => {
  const buttonClasses = usePreviewButtonClass();
  const appBarClasses = useAppBarClass();

  // If the user passed a topRightButtons prop, or the hideHamburger prop, then
  // the hamburger should be hidden.
  const shouldHamburgerHidden = !!topRightButtons || hideHamburgerProp;

  const { unread } = useNotificationContext();

  // wait for 300ms then show the tooltip then hide after defineds seconds
  return (
    <Box zIndex={9000} className={appBarClasses.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        width="100%"
        style={styleContainer}
      >
        <div>
          {showIllumeTitle && (
            <a href={getProdMarketingSiteUrl('')}>
              <IllumeText onClick={noop} backgroundColor={illumeLogoColor} />
            </a>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            columnGap: 42,
          }}
        >
          {topRightButtons?.map(({ action, icon, tooltipText }, index) => {
            function calculateTooltipDelayAndDuration(index: number) {
              const delay = 1000;
              const baseDuration = 3000;

              const tooltipDelay = delay * index + baseDuration * index;

              const tooltipDuration = baseDuration + delay;

              return { waitTime: tooltipDelay, showTime: tooltipDuration };
            }

            if (tooltipText) {
              return (
                <TooltippedComponent
                  key={index}
                  tooltipProps={{
                    text: tooltipText,
                    variant: 'other',
                    widthArrow: '10%',
                    placement: 'bottom',
                  }}
                  timeProps={calculateTooltipDelayAndDuration(index)}
                >
                  <ButtonBase className={buttonClasses.root} onClick={action.onAction}>
                    {icon}
                  </ButtonBase>
                </TooltippedComponent>
              );
            }
            return (
              <ButtonBase key={index} className={buttonClasses.root} onClick={action.onAction}>
                {icon}
              </ButtonBase>
            );
          })}
        </div>
      </Box>

      {!shouldHamburgerHidden && <Menu unread={unread} />}
    </Box>
  );
};

interface TooltippedComponentProps {
  tooltipProps: Omit<TooltipProps, 'children'>;
  timeProps?: {
    waitTime?: number;
    showTime?: number;
  };
  children: JSX.Element | JSX.Element[];
}

export default observer(Appbar);
