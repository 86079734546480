import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'components/illume/buttons';
import { Dropdown } from 'components/illume/dropdown';
import { HtmlRenderer } from 'components/illume/html-renderer';
import { DownPolygonArrow, LeftArrow } from 'components/illume/icons';
import { ShipGiftModal } from 'components/illume/ship-gift-modal';
import { Surface } from 'components/illume/surface';
import { Text } from 'components/illume/text';
import { TextInput } from 'components/illume/text-inputs';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design/index';
import { USStateCodes } from 'constants/us-state-codes/getStateCodes';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { GiftMerchantProduct } from 'domain/entities/gift/gift';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { HighlightDTO, Maybe, ShopifyShippingInfo } from 'types';
import { isValid } from 'utils/form';

import useStyles from './ShippingForm.styles';

const shippingFormSchema = yup.object().shape({
  firstName: yup.string().required('first name is required'),
  lastName: yup.string().required('last name is required'),
  email: yup.string().email('must be a valid email').required('email is required'),
  streetAddress: yup.string().required('street address is required'),
  other: yup.string(),
  zipCode: yup.string().required('zip code is required'),
  city: yup.string().required('city is required'),
  state: yup.string().required('state is required'),
});

export interface ShippingFormProps {
  data: IShippingFormProduct;
  onSubmit: (formState: ShopifyShippingInfo) => any;
  onGoBack: () => any;
}

interface IShippingFormProduct {
  name: string;
  description: string;
  img: string;
  vendor: Maybe<string>;
  highlights: Maybe<HighlightDTO[]>;
}

const ShippingFormWithFields = ({ data: product, onSubmit, onGoBack }: ShippingFormProps) => {
  const classes = useStyles();
  const desktop = useIsDesktop();
  const [showGiftModal, setShowGiftModal] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    errors,
    formState: { isSubmitted, isDirty, isSubmitting },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(shippingFormSchema),
  });

  const disableSubmitButton = (!isValid(errors) && isSubmitted) || !isDirty || isSubmitting;

  const onViewGiftDetails = () => {
    setShowGiftModal(true);
  };

  // const onSubmit = async (formState: ShopifyShippingInfo) => {
  //   return store.saveShippingInfoTask
  //     .execute(sendCode, formState)
  //     .then(() => enqueueSuccessSnackbar('Gift Shipped'))
  //     .then(() => store.pushToNext())
  //     .catch((e: Error) => {
  //       // TODO: API issue returns object instead a string for `message`
  //       const message = typeof e.message === 'object' ? e.message?.message : e.message;
  //       return enqueueErrorSnackbar(message || 'something went wrong..');
  //     });
  // };

  return (
    // Commented because header on mobile and desktop is different on figma
    <IllumeLayout contentProps={{ direction: 'column', alignItems: 'center' }}>
      <div className={classes.container}>
        <div onClick={onGoBack} className={classes.leftArrow}>
          <LeftArrow blobColor={colors.backgroundGray} arrowColor={colors.gray100} size={32} />
        </div>
        <div className={classes.giftThumbnail}>
          {desktop ? (
            product ? (
              <img src={product.img} alt="Shipped Gift" className={classes.giftImage}></img>
            ) : (
              <Text>Oops! there was a mistake, no product to show</Text>
            )
          ) : (
            <div className={classes.giftDetailContainer}>
              <div className={classes.giftImageContainer}>
                <img src={product?.img} alt={'gift'} className={classes.giftImage} />
              </div>
              <div className={classes.giftButton}>
                <Button fullWidth size="compact" customcolor="white" onClick={onViewGiftDetails}>
                  <Text color="#FEB81C" fontSize={{ mobile: 11, desktop: 11 }}>
                    VIEW DETAILS
                  </Text>
                </Button>
              </div>
            </div>
          )}
        </div>
        {product && (
          <div className={classes.giftDetails}>
            <div className={classes.giftName}>
              <Text fontSize={rem[1750]} fontWeight={800} lineHeight={'28px'}>
                {product.name}
              </Text>
              {product.vendor && (
                <Text fontSize={rem[1000]} fontWeight={400} lineHeight={'16px'}>
                  {product.vendor}
                </Text>
              )}
            </div>
            <div>
              <Text fontSize={rem[875]} fontWeight={400} italic>
                <HtmlRenderer html={product.description} />
              </Text>
            </div>
            {product.highlights && (
              <div className={classes.tags}>
                {product.highlights.map(({ highlight, color }) => {
                  return (
                    <Surface
                      rounded={['top', 'bottom']}
                      paddingX={1}
                      paddingY={0.7}
                      borderRadius={4}
                      background={color}
                      key={highlight}
                    >
                      <Text fontSize={{ desktop: 14, mobile: 14 }} color={colors.contrastText}>
                        {highlight}
                      </Text>
                    </Surface>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className={classes.giftWelcome}>
          <div>
            <Text fontSize={desktop ? rem[1750] : rem[1375]} fontWeight={900}>
              LET'S SHIP YOUR GIFT!
            </Text>
          </div>
          <div>
            <Text fontSize={rem[1000]} fontWeight={400}>
              {`The group came together to get you a ${product?.name}. Enter your shipping information to
              get your gift below.`}
            </Text>
          </div>
        </div>
        <div className={classes.giftForm}>
          <div className={classes.input}>
            <div className={classes.rowInput}>
              <div className={classes.wFull}>
                <TextInput
                  inputId="first-name"
                  ref={register}
                  placeholder="First Name"
                  name="firstName"
                  error={errors.firstName}
                  data-testid="firstName"
                />
              </div>
              <div className={classes.wFull}>
                <TextInput
                  inputId="last-name"
                  ref={register}
                  placeholder="Last Name"
                  name="lastName"
                  error={errors.lastName}
                  data-testid="lastName"
                />
              </div>
            </div>
            <div className={classes.wFull}>
              <TextInput
                inputId="email"
                ref={register}
                placeholder="Email"
                name="email"
                error={errors.email}
                data-testid="email"
              />
            </div>
            <div className={classes.wFull}>
              <TextInput
                inputId="street-address"
                ref={register}
                placeholder="Street Address"
                name="streetAddress"
                error={errors.streetAddress}
                data-testid="streetAddress"
              />
            </div>
            <TextInput
              inputId="other"
              ref={register}
              placeholder="Apt / Suite / other"
              name="other"
              error={errors.place}
              data-testid="other"
            />
            <div className={classes.rowInput}>
              <div className={classes.wFull}>
                <TextInput
                  inputId="zip-code"
                  ref={register}
                  placeholder="Zip code"
                  name="zipCode"
                  error={errors.zipCode}
                  data-testid="zip-code"
                />
              </div>
              <div className={classes.wFull}>
                <TextInput
                  inputId="City"
                  ref={register}
                  placeholder="City"
                  name="city"
                  error={errors.city}
                  data-testid="city"
                />
              </div>
            </div>
            <Controller
              control={control}
              name="state"
              render={({ onChange, value }) => (
                <Dropdown
                  background="main"
                  items={USStateCodes}
                  placeholder={'State'}
                  name="state"
                  onChange={(arg: any) => {
                    onChange(arg);
                  }}
                  error={errors.state}
                  value={value}
                  icon={() => <DownPolygonArrow className={classes.dropDownArrow} />}
                  children={undefined}
                  label={undefined}
                  labelTextProps={undefined}
                  shadow={undefined}
                  shadowColor={undefined}
                  radius={undefined}
                  errorMessage={undefined}
                  fontSize={undefined}
                  labelSuffix=""
                />
              )}
            />
            <Button
              fullWidth
              disabled={disableSubmitButton}
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              Ship my gift
            </Button>
            <Text fontSize={rem[750]} color={colors.labelText} italic align="center">
              By clicking, “Ship my gift,” you agree to illume's privacy and terms of service.
            </Text>
          </div>
        </div>
      </div>
      {product && (
        <ShipGiftModal
          product={{
            description: product.description,
            image: product.img,
            name: product.name,
            vendor: product.vendor,
          }}
          showModal={showGiftModal}
          hideModal={() => setShowGiftModal(false)}
          tags={product.highlights?.map((h, index) => ({
            text: h.highlight,
            color: h.color,
            id: index.toString(),
          }))}
        />
      )}
    </IllumeLayout>
  );
};

ShippingFormWithFields.dataFromExternalGift = (gift: ExternalGift): IShippingFormProduct => {
  return {
    description: gift.description,
    img: gift.image,
    name: gift.title,
    vendor: null,
    highlights: null,
  };
};

ShippingFormWithFields.dataFromGiftMerchantProduct = (
  gift: GiftMerchantProduct,
): IShippingFormProduct => {
  return {
    description: gift.description,
    img: gift.variant.img,
    name: gift.name,
    highlights: gift.highlights,
    vendor: gift.vendor,
  };
};

export default observer(ShippingFormWithFields);
