import { useEffect } from 'react';

import confetti from 'canvas-confetti';
import random from 'lodash/random';

export const shootConfetti = ({ number = 5, delay = 1000 } = {}) => {
  setTimeout(() => {
    Array.from({ length: number }).forEach((_el, index) => {
      const spaceInterval = 1 / number;
      confetti({
        particleCount: (180 * random(3, 5)) / number, // make sure we have the same density
        angle: 270, // shot from up to bottom
        spread: random(120, 200),
        origin: { x: index * spaceInterval, y: -0.3 }, // make it spread along the upper horizontal axis
        ticks: 300, // default 200. how quickly the particles disappear
        startVelocity: random(45, 90, false),
      });
    });
  }, delay);
};
function fireConfettis({ number = 5, delay = 1000 } = {}) {
  setTimeout(() => {
    Array.from({ length: number }).forEach((_el, index) => {
      const spaceInterval = 1 / number;
      confetti({
        particleCount: (180 * random(3, 5)) / number, // make sure we have the same density
        angle: 270, // shot from up to bottom
        spread: random(120, 200),
        origin: { x: index * spaceInterval, y: -0.3 }, // make it spread along the upper horizontal axis
        ticks: 300, // default 200. how quickly the particles disappear
        startVelocity: random(45, 90, false),
      });
    });
  }, delay);
}

export const useConfettiCannon = ({ auto = true } = {}) => {
  useEffect(() => {
    if (auto) {
      fireConfettis({ number: 8 });
    }

    return () => confetti.reset();
  }, [auto]);

  return fireConfettis;
};
