import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IllumeLogo } from 'components/illume/icons';
import { IllumeLayout } from 'components/layouts';

const useStyles = makeStyles({
  root: {
    height: '90vh',
  },
});

const LogoScreen = () => {
  const classes = useStyles();
  return (
    <IllumeLayout>
      <Grid container justifyContent="center" classes={classes}>
        <IllumeLogo />
      </Grid>
    </IllumeLayout>
  );
};

export default LogoScreen;
