import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      minHeight: '100vh',
      overflowX: 'hidden',
      position: 'relative',
    },
    section: {
      position: 'relative',
      zIndex: 2,
    },
    buttonContainer: {
      width: 264,
      marginTop: 10,
      order: 3,
      alignSelf: 'center',
      [theme.breakpoints.up('md')]: {
        width: 248,
      },
    },
    buttonContainerBottom: {
      width: 264,
      marginTop: 50,
      order: 3,
      alignSelf: 'center',
    },
    s1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        height: '75vh',
      },
      '& > *': {
        width: 529,
        rowGap: 16,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      },
    },
    s2: {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0 30px',
      [theme.breakpoints.up('md')]: {
        height: 'auto',
        flexDirection: 'row',
      },
    },
    s2Left: {
      order: 2,
      width: 300,
      height: 400,
      display: 'flex',
      alignSelf: 'center',
      [theme.breakpoints.up('md')]: {
        order: 1,
        width: 524,
        height: '100%',
      },
    },
    s2Right: {
      width: 335,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      order: 1,
      marginTop: 25,
      [theme.breakpoints.up('md')]: {
        order: 2,
        padding: 60,
        width: 600,
        marginTop: 0,
      },
    },
    blobContainter: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      top: '-20vh',
      right: '-30vh',
      width: '215%',
      [theme.breakpoints.up('md')]: {
        top: '-55vh',
        right: '-35vh',
        left: '-30vw',
        width: '100%',
      },
    },
    blob: {
      opacity: 0.5,
      width: 1000,
    },
    footer: {
      height: '30vh',
      padding: '0 10px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        padding: '0 120px',
      },
    },
    secondBlob: {
      position: 'absolute',
      left: -100,
      bottom: -100,
      width: 300,
      zIndex: 1,
      opacity: 0.5,
    },
    headingText: {
      width: 300,
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
    paragraphBox: {
      marginTop: 20,
      marginBottom: 30,
    },
    csvLink: {
      textDecoration: 'underline',
      color: colors.darkText,
    },
  }),
);
