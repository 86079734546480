import { StepType as ReactourStep } from '@reactour/tour';

import { Text } from 'components/illume/text';

export const steps: ReactourStep[] = [
  {
    selector: '[data-tut="section_recipient_info"]',
    content: (
      <Text fontSize={20}>Input the recipient email or phone number and click “save changes”</Text>
    ),
    position: 'bottom',
    highlightedSelectors: ['.date_picker_content', '#edit_recipient_content'],
    mutationObservables: ['.date_picker_dialog', '#edit_recipient_modal'],
  },
  {
    selector: '[data-tut="section_send_card"]',
    content: (
      <Text fontSize={20}>
        {`Schedule to send your card or send your card now to the recipient.`}
      </Text>
    ),
    position: 'bottom',
  },
];
