import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

import { colors } from 'constants/design';

const useStyles = makeStyles(() => ({
  tabs: {
    textAlign: 'center',
    justifyContent: 'center',
    padding: '10px 0',
  },
}));

const Divider = ({ color = colors.gray100, height = 30 }) => (
  <svg width="3" height={height} viewBox="0 0 3 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 1.79999V28.2"
      stroke={color}
      strokeOpacity="0.4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const TabsDivider = ({
  children,
  color = colors.gray100,
  height = 30,
  className = '',
  variant = 'large',
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={variant === 'large' ? 4 : 2}
      className={cx(classes.tabs, className)}
      alignItems="center"
    >
      <Grid item>{children[0]}</Grid>
      <Grid item>
        <Divider color={color} height={height} />
      </Grid>
      <Grid item>{children[1]}</Grid>
    </Grid>
  );
};

export default TabsDivider;
