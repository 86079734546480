import { Box, Modal as MUIModal, Paper, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { colors, rem, spacing } from 'constants/design';
import { noop } from 'utils';

import { BaseIconButton, Button, CancelButton } from '../buttons';
import Arrow from '../icons/Arrow';
import { Text } from '../text';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: (props) => `${props.zIndex} !important`,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    outline: 0,
    boxShadow: theme.shadows[5],
    borderRadius: rem[1000],
    marginLeft: spacing[8],
    marginRight: spacing[8],
    padding: spacing[4],
    width: '100%',
    maxWidth: 324,
    [theme.breakpoints.up('md')]: {
      maxWidth: (props) => props.maxWidth,
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}));

export const Modal = (props) => {
  const {
    show,
    onClose,
    children,
    title,
    titleProps = { align: 'center' },
    promptConfig,
    className,
    buttonTextProps,
    onBack = noop,
    showBackButton = false,
    maxWidth = 664,
    zIndex = 1300,
    contentId,
    ...rest
  } = props;
  const {
    onOk = () => {},
    onCancel,
    okText = 'ok',
    okLoading,
    cancelText = 'cancel',
    disabled = false,
  } = promptConfig || {};
  const styles = useStyles({ maxWidth, zIndex });
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const buttonSize = desktop ? 'large' : 'compact';
  const {
    fontWeight: buttonFontWeight = 500,
    fontSize: buttonFontSize = { desktop: '16px', mobile: '14px' },
    ...buttonTextRest
  } = buttonTextProps || {};

  return (
    // using <Modal /> actually more customizable, but Dialog is more battery included
    <MUIModal
      // PaperProps={{ elevation: 1 }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={onClose}
      open={show}
      className={styles.modal}
      // classes={styles}
      {...rest}
    >
      <Paper className={styles.paper} id={contentId}>
        {title && (
          /* TODO: we may specify weight in props instead of font-bold */
          <Box pb={3} px={1} display="flex" alignItems="center">
            {showBackButton && (
              <Box>
                <BaseIconButton onClick={onBack} icon={() => <Arrow size={24} />} />
              </Box>
            )}
            <Box flex="1">
              <Text fontSize={rem[1375]} fontWeight={900} color={colors.gray100} {...titleProps}>
                {title}
              </Text>
            </Box>
          </Box>
        )}
        <Box pb={1}>{children}</Box>
        {promptConfig && (
          <Box pt={3}>
            <Grid container spacing={1}>
              {onCancel && (
                <Grid item xs={6}>
                  <CancelButton fullWidth size={buttonSize} onClick={onCancel}>
                    {cancelText}
                  </CancelButton>
                </Grid>
              )}
              <Grid item xs>
                <Button
                  fullWidth
                  size={buttonSize}
                  loading={okLoading}
                  onClick={onOk}
                  disabled={disabled}
                >
                  <Text fontWeight={buttonFontWeight} fontSize={buttonFontSize} {...buttonTextRest}>
                    {okText}
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </MUIModal>
  );
};
