import { FC } from 'react';

import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CardElement, CardElementProps } from '@stripe/react-stripe-js';
import cx from 'classnames';

import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // border
      border: `1px solid ${colors.gray40}`,
      backgroundColor: colors.background,
      borderRadius: 8,
      position: 'relative',
      width: '100%',
      padding: 10,
      [theme.breakpoints.up('md')]: {
        padding: 14.5,
      },
    },
  }),
);

export type CreditCardInputProps = CardElementProps & {
  error?: any;
  errorMessage?: string;
  className?: string;
};

export const CreditCardInputLight: FC<CreditCardInputProps> = ({
  error,
  errorMessage = '',
  className,
  ...props
}) => {
  // will do the styling for the field here
  const classes = useStyles();
  const cardStyle = {
    style: {
      base: {
        fontSize: '16px',
        '::placeholder': {
          color: colors.gray40,
          fontSize: rem[1000],
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  return (
    <div>
      <CardElement options={cardStyle} className={cx(classes.root, className)} {...props} />
      {error && (
        <Box data-testid="input-error" role="alert" mt={1}>
          <Text color={colors.alertRed}>
            {error.message || errorMessage || 'please provide a valid input'}
          </Text>
        </Box>
      )}
    </div>
  );
};
