//@ts-check
import { useMemo, useState } from 'react';

import { Link } from '@material-ui/core';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { AnimatedBlob } from 'components/animated-blob';
import BatchCardUploadModal from 'components/batch-card-upload-modal/BatchCardUploadModal';
import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { Table } from 'components/table';
import mockData from 'components/table/data.mock';
import { colors, rem } from 'constants/design';
import { TEAM_SUCCESFUL_CREATE_URL } from 'constants/strings';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { wait } from 'utils';

import ExperimentalLoginDialog, {
  useExperimentalLoginDialog as useDialog,
} from '../components/ExperimentalLoginDialog';
import { columns } from './columns';
import Footer from './Footer';
import { useStyles } from './Teams.style';

const Teams = () => {
  const mobile = !useIsDesktop();
  const classes = useStyles();
  const { authenticationStore, batchedCardStore } = useStores();
  const { batchCardService } = useServices();
  const authenticated = authenticationStore.authenticated;
  const [showBatchUploadModal, setShowBatchUploadModal] = useState(false);
  const {
    hide: hideExperimentalDialog,
    show: showExperimentalDialog,
    visible: isExperimentalDialogVisible,
  } = useDialog();

  const history = useHistory();

  const blobProps = {
    gradient: ['rgb(245, 240, 225)', 'rgb(245, 240, 225)'],
    ms: 8000,
    opacity: 0.8,
  };
  const showMainBlob = true;

  /**@ts-ignore */
  const data = useMemo(() => mockData.concat(Array.from({ length: 11 }).map((_) => ({}))), []);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  function handleUploadCsv() {
    if (authenticated) {
      setShowBatchUploadModal(true);
    } else {
      showExperimentalDialog();
    }
  }
  return (
    <IllumeLayout
      contentProps={{
        className: classes.content,
      }}
      appBarVariant={authenticated ? 'default' : 'login_and_create_card_cta'}
      contentComponent={'div'}
    >
      {showMainBlob && (
        <div className={classes.blobContainter}>
          <AnimatedBlob {...blobProps} className={classes.blob} />
        </div>
      )}
      <div className={classNames(classes.section, classes.s1)}>
        <div>
          <Text color={colors.primaryDark} fontWeight={500} lineHeight={'27px'} fontSize={20}>
            illume for teams
          </Text>
          <Text
            className={classes.headingText}
            fontWeight={700}
            lineHeight={mobile ? '52px' : '64px'}
            fontSize={{ desktop: 64, mobile: 36 }}
          >
            create multiple links in just one step
          </Text>

          <Text
            className={classes.headingText}
            fontWeight={400}
            maxWidth={448}
            lineHeight={'27px'}
            fontSize={{ desktop: 18, mobile: 16 }}
            color={colors.subHeaderGray}
          >
            We made it easy to generate multiple recipient links by uploading a .csv file. See below
            for instructions...
          </Text>
          <div className={classes.buttonContainer}>
            <Button onClick={handleUploadCsv} fullWidth height={rem[3000]}>
              <Text color={colors.contrastText} fontSize={'16px'} fontWeight={420}>
                Upload .csv file
              </Text>
            </Button>
          </div>
        </div>
      </div>
      <div className={classNames(classes.section, classes.s2)}>
        <div className={classes.s2Left}>
          <Table shadow table={table} />
        </div>
        <div className={classes.s2Right}>
          <div>
            <Text color={colors.primaryDark} fontWeight={500} lineHeight={'27px'} fontSize={20}>
              How it works
            </Text>
            <Text
              className={classes.headingText}
              fontWeight={700}
              lineHeight={mobile ? '52px' : '64px'}
              fontSize={{ desktop: 64, mobile: 36 }}
              style={{ marginTop: 10 }}
            >
              Upload a .csv file with basic info
            </Text>

            <Text
              className={classes.paragraphBox}
              maxWidth={mobile ? 264 : 453}
              fontWeight={400}
              lineHeight={'27px'}
              fontSize={{ desktop: 18, mobile: 16 }}
              color={colors.subHeaderGray}
            >
              Make sure to format your file with separate columns for recipient first name, last
              name, email and deadline! See an example or copy the formatting{' '}
              <Link
                className={classes.csvLink}
                href="/teams_example.csv"
                target="_blank"
                rel="noreferrer"
              >
                here.
              </Link>
            </Text>

            {!mobile && (
              <Button
                className={classes.buttonContainer}
                onClick={handleUploadCsv}
                fullWidth={mobile}
                height={rem[3000]}
              >
                <Text color={colors.contrastText} fontSize={'16px'} fontWeight={420}>
                  Upload .csv file
                </Text>
              </Button>
            )}
          </div>
        </div>
        {mobile && (
          <div className={classes.buttonContainerBottom}>
            <Button onClick={handleUploadCsv} fullWidth={mobile} height={rem[3000]}>
              <Text color={colors.contrastText} fontSize={'16px'} fontWeight={420}>
                Upload .csv file
              </Text>
            </Button>
          </div>
        )}
      </div>
      <div className={classNames(classes.section, classes.footer)}>
        <AnimatedBlob {...blobProps} opacity={0.8} className={classes.secondBlob} />

        <div style={{ position: 'relative', zIndex: 2 }}>
          <Footer />
        </div>
      </div>

      {
        // make sure to re-initiate everything
        showBatchUploadModal && (
          <BatchCardUploadModal
            uploadService={(
              { files, batchName, occasionName, initiatorName },
              onProgress,
              abortSignal,
            ) => {
              const file = files[0];

              const payload = {
                batchName,
                initiatorName,
                presetRecipients: file,
                occasionName,
              };

              return (
                batchCardService
                  .uploadBatch(payload, onProgress, abortSignal)
                  .then(() => batchedCardStore.__experimentalRefresh())
                  // for ui purpose only
                  .then(() => wait(1000))
                  .then(() => history.push(TEAM_SUCCESFUL_CREATE_URL))
              );
            }}
            onSuccessDismiss={() => setShowBatchUploadModal(false)}
            show={true}
            onCancel={() => {
              setShowBatchUploadModal(false);
            }}
          />
        )
      }
      {isExperimentalDialogVisible && (
        <ExperimentalLoginDialog
          onLoginSuccess={() => setShowBatchUploadModal(true)}
          open={isExperimentalDialogVisible}
          hideModal={hideExperimentalDialog}
        />
      )}
    </IllumeLayout>
  );
};

export default observer(Teams);
