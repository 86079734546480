import {
  INITIATOR_MARKETPLACE_GIFTS_URL,
  MARKETING_SITE_SPLIT_GIFT,
  MY_CARDS_URL,
  TEAMS_URL,
} from 'constants/strings';

export const MENUS = [
  {
    label: 'My Cards',
    link: `${MY_CARDS_URL}`,
  },
  {
    label: 'Curated Gifts',
    link: INITIATOR_MARKETPLACE_GIFTS_URL,
  },
  {
    label: 'Split Gift',
    link: MARKETING_SITE_SPLIT_GIFT,
  },
  {
    label: 'Bulk Create',
    link: TEAMS_URL,
  },
];
