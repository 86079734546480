import { useCallback, useEffect, useRef, useState } from 'react';

//@ts-ignore
import Tags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { BaseIconButton } from 'components/illume/buttons';
import { PaperPlane } from 'components/illume/icons';
import Text, { TextProps } from 'components/illume/text/Text';
import { colors } from 'constants/design';
import { wait } from 'utils';

import useStyles from './MultipleContactsSender.styles';

import './MultipleContactsSender.scss';

export const transitionVariants = {
  initial: {
    width: 40,
  },
  in: {
    width: '100%',
    transition: { ease: 'anticipate', duration: 0.6, justifyContent: { delay: 0.3 } },
  },
  out: {
    width: 40,
    transition: { ease: 'anticipate', duration: 0.6, justifyContent: { delay: 0.3 } },
  },
};

interface IPhoneAndEmailInvitesProps {
  onChange?: (values: string[]) => void;
  label?: string;
  loading?: boolean;
  sent?: boolean;
  onSendInvitation: (arg: string[]) => any;
  error?: Error;
  defaultValue?: string[];
  placeholder?: string;
  reset: () => any;
  className?: string;
  labelTextProps?: TextProps;
  compact?: boolean;
  containerProps?: any;
}
const PhoneAndEmailInvites: React.FC<IPhoneAndEmailInvitesProps> = ({
  onChange = () => null,
  label,
  loading,
  sent,
  onSendInvitation,
  defaultValue,
  error,
  placeholder,
  reset = () => null,
  className,
  compact,
  labelTextProps,
  containerProps,
  ...props
}) => {
  const classes = useStyles({ fullWidth: true, compact });
  // const classes = inlineStyles();
  const [contacts, setContacts] = useState(defaultValue);
  const ref = useRef();
  const handleChange = useCallback(
    (e) => {
      console.log(
        'CHANGED:',
        e.detail.tagify.value, // Array where each tag includes tagify's (needed) extra properties
        e.detail.tagify.getCleanValue(), // Same as above, without the extra properties
        e.detail.value, // a string representing the tags
      );
      onChange(e.detail.tagify.getCleanValue());
      setContacts(e.detail.tagify.getCleanValue());
    },
    [onChange],
  );

  useEffect(() => {
    if (sent || error) {
      wait(3000).then(reset);
    }
  }, [error, reset, sent]);

  const handleSendInvitation = useCallback(
    () =>
      onSendInvitation &&
      contacts &&
      onSendInvitation(
        contacts.map((r) => {
          if (typeof r === 'object') {
            return r.value;
          } else return r;
        }),
      ),
    [contacts, onSendInvitation],
  );

  const settings = {
    pattern:
      /^(?:([A-Za-z0-9_.+-]+@[\dA-Za-z.-]+\.[A-Za-z.]{2,6})|\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?)$/,
    delimiters: ' |,',
    trim: false,
    backspace: false,
    duplicates: false,
    keepInvalidTags: false,
    editTags: 1,
  };

  return (
    <div style={{ lineHeight: 1.2 }} {...containerProps}>
      {label && (
        <label htmlFor="tags" className={classes.label}>
          <Text {...labelTextProps}>{label}</Text>
        </label>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 8,
          marginTop: 6,
          paddingRight: 38,
        }}
      >
        <Tags
          id="tags"
          placeholder={placeholder}
          settings={settings} // tagify settings object
          tagifyRef={ref}
          defaultValue={defaultValue}
          onChange={handleChange}
          className={classNames(
            'multiple-contact-sender',
            classes.root,
            'customIllumeScrollbar-gray',
            className,
          )}
          {...props} // dynamic props such as "loading", "showDropdown:'abc'", "value"
        />

        <motion.div
          variants={transitionVariants}
          className={classes.sendIcon}
          initial="initial"
          animate={sent ? 'in' : 'out'}
        >
          {sent ? (
            <Text color={colors.contrastText} italic>
              the invitation has been sent!
            </Text>
          ) : (
            <BaseIconButton
              loading={loading}
              icon={PaperPlane}
              iconProps={{ color: colors.contrastText, size: 20 }}
              onClick={handleSendInvitation}
              classes={undefined}
              size={24}
              color={undefined}
              disabled={!contacts?.length}
            />
          )}
        </motion.div>
      </div>

      {error && <Text color={colors.alertRed}>Oops! there's an error: {error.message}</Text>}
    </div>
  );
};

export default PhoneAndEmailInvites;
