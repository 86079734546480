const PriceTag = ({ size = 12, color = '#282828', ...props }) => (
  <svg
    width={size}
    height={(size * 11) / 12}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.7375 0.58375C5.52734 0.37133 5.24129 0.25125 4.9425 0.25H1.875C1.57664 0.25 1.29047 0.36852 1.07949 0.57949C0.86851 0.79047 0.75 1.07664 0.75 1.375V4.4388C0.750273 4.73708 0.86895 5.02302 1.08 5.2338L6.2138 10.375C6.45646 10.6177 6.7856 10.7541 7.1288 10.7541C7.472 10.7541 7.80114 10.6177 8.0438 10.375L10.875 7.5438C11.1177 7.30114 11.2541 6.972 11.2541 6.6288C11.2541 6.2856 11.1177 5.95646 10.875 5.7138L5.7375 0.58375ZM3.8625 3.38125C3.66051 3.62754 3.36355 3.77668 3.04535 3.7916C2.72715 3.80652 2.41754 3.68586 2.19336 3.45957C1.96918 3.23324 1.85148 2.9225 1.86941 2.60449C1.88734 2.28644 2.03929 1.9909 2.2875 1.79125C2.49848 1.58055 2.78445 1.46219 3.08266 1.46223C3.38082 1.46227 3.66676 1.58067 3.8777 1.79145C4.08864 2.00219 4.20723 2.28809 4.2075 2.58625C4.20723 2.88453 4.08855 3.17047 3.8775 3.38125H3.8625Z"
      fill={color}
    />
  </svg>
);

export default PriceTag;
