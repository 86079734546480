import { useState } from 'react';

import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import addGroupGift from 'assets/svg/add_group_gift.svg';
import findPerfectGift from 'assets/svg/find_perfect_gift.svg';
import maxGift from 'assets/svg/max_group_gift.svg';
import maxGiftMobile from 'assets/svg/max_group_gift_mobile.svg';
import wavyBg from 'assets/svg/wavy_bg.svg';
import wavyBgMobile from 'assets/svg/wavy_bg_mobile.svg';
import { Button } from 'components/illume/buttons';
import { Link as IllumeLink } from 'components/illume/link';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { Loading } from 'components/Loading';
import { colors } from 'constants/design';
import {
  INITIATOR_BASIC_INFO_URL,
  MARKETING_SITE_SPLIT_GIFT,
  TEAMS_URL,
  INITIATOR_MARKETPLACE_GIFTS_URL,
} from 'constants/strings';
import { useStores } from 'contexts/store';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { useAllImagesLoaded } from 'hooks/useAllImagesLoaded';

import ContributorStore from '../../Contributor.store';
import CelebrateMoreStore from '../celebratemore.store';
import { useStyles } from './CelebrateMoreA.style';
import ContentSection from './ContentSection';

const CelebrateMoreA: React.FunctionComponent<{ contributorStore: ContributorStore }> = ({
  contributorStore,
}) => {
  const isMobile = !useIsDesktop();
  const history = useIllumeHistory();
  const { routerStore, occasionStore } = useStores();
  const [store] = useState(
    () =>
      new CelebrateMoreStore(
        { peopleType: { text: 'Colleagues', value: 'colleaguesOccasions' } },
        occasionStore,
      ),
  );
  const classes = useStyles();

  const { imagesLoaded, setImageRef } = useAllImagesLoaded();

  return (
    <IllumeLayout
      contentProps={{
        justifyContent: 'center',
      }}
    >
      {!imagesLoaded && <Loading text="loading card.." />}
      <Grid className={classes.container} style={{ display: !imagesLoaded ? 'none' : '' }}>
        <Text fontWeight={700} lineHeight={'48px'} fontSize={{ desktop: 64, mobile: 36 }}>
          illume is the {isMobile && <br />} celebration platform
        </Text>
        <Text
          className={classes.paragraphBox}
          fontSize={{ desktop: 14, mobile: 16 }}
          color={colors.subHeaderGray}
          style={{ fontWeight: 390 }}
        >
          <span style={{ fontWeight: 490 }}>{'>'}300K</span> notes sent with love &#10084;&#65039;
        </Text>
        <Grid item className={classes.button}>
          <Button
            onClick={() => {
              return history.push({
                pathname: INITIATOR_BASIC_INFO_URL,
              });
            }}
            fullWidth
          >
            <Text color={colors.black} fontSize={18}>
              Create Card
            </Text>
          </Button>
        </Grid>
        <Grid className={classes.contentContainer}>
          {isMobile ? (
            <Grid item className={classes.button}>
              <Button
                onClick={() => {
                  return history.push({
                    pathname: contributorStore.cardListUrl,
                  });
                }}
                color="secondary"
                fullWidth
                customcolor="gray"
              >
                <Text color={colors.black} fontSize={18}>
                  Take me to my Cards
                </Text>
              </Button>
            </Grid>
          ) : (
            <Link to={contributorStore.cardListUrl} className={classes.paragraphBox}>
              <IllumeLink
                fontWeight={420}
                color={colors.black}
                fontSize={18}
                italic
                hover={{ textDecoration: 'underline' }}
              >
                Take me to my Cards
              </IllumeLink>
            </Link>
          )}
        </Grid>

        <ContentSection
          url={MARKETING_SITE_SPLIT_GIFT}
          title="Split the cost of any gift online"
          subtitle="Save $75/year on gifting costs by having others chip in."
          mainImg={isMobile ? maxGiftMobile : maxGift}
          mainImgAlt="group gift page"
          imgRef={(ref) => setImageRef(1, ref)}
        />

        <ContentSection
          url={TEAMS_URL}
          title="Bulk create cards"
          subtitle="Get, on average, 12 hours of your life back by creating cards for everyone by the bulk"
          mainImg={addGroupGift}
          mainImgAlt="group gift page"
          direction="right"
          imgRef={(ref) => setImageRef(2, ref)}
        />

        <ContentSection
          url={INITIATOR_MARKETPLACE_GIFTS_URL}
          title="Find the perfect gift"
          subtitle="Make finding the right gift fast and easy. Plus, you don't need the recipient's address."
          mainImg={findPerfectGift}
          mainImgAlt="group gift page"
          imgRef={(ref) => setImageRef(1, ref)}
        />
        <Grid className={classes.wavyBackground}>
          <img
            src={isMobile ? wavyBgMobile : wavyBg}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            alt=""
            ref={(ref) => setImageRef(0, ref)}
          />
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default observer(CelebrateMoreA);
