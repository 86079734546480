import { ElementType, useMemo, useState } from 'react';

import { ValueOf } from '@illume/shared';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

import { colors } from 'constants/design';
import { useInterval } from 'hooks/illume/useInterval';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import getSubArrayCyclic from 'utils/getSubArrayCyclic';

import { BaseIconButtonNew } from '../buttons/BaseIconButton';
import { Info } from '../icons';
import BaseText, { BaseTextProps } from './BaseText';

// better to type with css properteis
// better inherit this from MUI
export interface TextProps extends Omit<BaseTextProps, 'prefix'> {
  prefix?: React.ReactNode;
  labelColor?: ValueOf<typeof colors>;
  component?: ElementType<any>;
}

const Text = ({ children, prefix, labelColor, component, ...props }: TextProps) => {
  if (prefix) {
    return (
      <span style={{ display: 'flex', alignItems: 'center', columnGap: 5 }}>
        {prefix}
        <BaseText color={labelColor}>{children}</BaseText>
      </span>
    );
  }
  return (
    <BaseText component={component} {...props}>
      {children}
    </BaseText>
  );
};

// animate words sequentially
const SplitText = ({
  children,
  color,
  lowerCase,
  ...rest
}: {
  children: string;
  color: string;
  lowerCase: boolean;
} & HTMLMotionProps<'span'>) => {
  let words = children.split(' ');
  return (
    <>
      {words.map((word, i) => {
        return (
          <motion.span
            {...rest}
            style={{ display: 'inline-block', willChange: 'transform', color }}
            custom={i}
            key={i + word}
          >
            {(lowerCase ? word.toLowerCase() : word) + (i !== words.length - 1 ? '\u00A0' : '')}
          </motion.span>
        );
      })}
    </>
  );
};

interface IShuffledTextProps {
  texts?: string[];
  color: string;
  interval?: number;
  lowerCase?: boolean;
  animateFromBottom?: boolean;
  onInfo: () => any;
  info?: boolean;
}

const ShuffledText: React.FC<IShuffledTextProps> = ({
  texts = [],
  color,
  interval = 1000,
  animateFromBottom = true,
  lowerCase = false,
}) => {
  const [index, setIndex] = useState(0);
  function reducer(currentIndex: number) {
    if (currentIndex === texts.length - 1) {
      return 0;
    }
    return currentIndex + 1;
  }
  useInterval(interval, () => setIndex(reducer));
  return (
    <AnimatePresence key={texts[index]}>
      <motion.span initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <SplitText
          initial={{ y: animateFromBottom ? '100%' : '-100%' }}
          animate="visible"
          color={color}
          lowerCase={lowerCase}
          variants={{
            visible: (i: number) => ({
              y: 0,
              transition: {
                delay: i * 0.1,
              },
            }),
          }}
        >
          {texts[index]}
        </SplitText>
      </motion.span>
    </AnimatePresence>
  );
};

const MarketingShuffledText: React.FC<IShuffledTextProps> = ({
  texts = [],
  color,
  interval = 1000,
  lowerCase = false,
  onInfo,
  info,
  onClick,
}) => {
  const [index, setIndex] = useState(0);
  useInterval(interval, () => setIndex((prev) => prev + 1));
  const [current] = useMemo(() => getSubArrayCyclic(texts, index, 2), [index, texts]); // only two array, current and next text
  const desktop = useIsDesktop();

  return (
    <AnimatePresence>
      <motion.span
        onClick={onClick}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        // exit={{ opacity: 0, y: 100 }}
        key={current}
        style={{ display: 'inline-block', color }}
      >
        {lowerCase ? current.toLocaleLowerCase() : current}

        {info && (
          <BaseIconButtonNew
            style={{ marginLeft: 5 }}
            onClick={onInfo}
            icon={Info}
            iconProps={{ size: desktop ? 20 : 14 }}
          />
        )}
      </motion.span>
    </AnimatePresence>
  );
};

Text.Shuffle = ShuffledText;
Text.MarketingShuffle = MarketingShuffledText;
Text.Highlight = (props: TextProps) => (
  <Text fontWeight={700} color={colors.primary} component="span" display="inline" {...props} />
);
Text.Bold = (props: TextProps) => (
  <Text fontWeight={700} display="inline" component="b" {...props} />
);

export default Text;
