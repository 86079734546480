import { Box, CircularProgress, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';

import { colors } from 'constants/design';

import { BaseIconButton } from '../buttons';
import { Close, Pause, Play, Fullscreen } from '../icons';

export const SliderInput = withStyles({
  root: {
    color: colors.primary,
  },
  thumb: {
    backgroundColor: 'transparent',
    color: 'transparent',
    display: 'none', // disable slider thumb for now
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    background: colors.contrastText,
    opacity: 1,
  },
})(Slider);

export const PreviewPlayButton = ({ size = 54, ...props }) => (
  <BaseIconButton icon={Play} size={size} {...props} />
);
export const PauseButton = (props) => <BaseIconButton icon={Pause} {...props} />;
export const PlayButton = (props) => <BaseIconButton icon={Play} size={20} {...props} />;
export const VideoCloseButton = (props) => (
  <BaseIconButton icon={isMobile ? Fullscreen : Close} color={colors.contrastText} {...props} />
);

export const Loading = ({ size }) => {
  return (
    <Box width={size} display="flex" justifyContent="center">
      <CircularProgress style={{ color: colors.contrastText }} size={size} />
    </Box>
  );
};

export const Redacted = ({ size, color = colors.background }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 264 258"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0559903 95.6214C-1.12547 71.7269 16.4708 46.2811 45.2531 26.9653C75.9711 6.34843 110.032 -1.98338 146.733 0.393566C167.899 1.74467 187.833 7.49938 204.574 21.3357C215.459 30.318 223.88 41.352 231.145 53.3619C243.965 74.5292 254.698 96.5972 260.757 120.742C269.152 154.169 260.958 183.668 239.541 209.915C217.093 237.412 187.255 251.374 152.49 255.977C128.182 259.205 104.502 256.653 81.5014 248.146C53.4228 237.763 33.2626 218.572 20.5682 191.9C7.89888 165.328 0.986076 137.23 0.0559903 95.6214Z"
      fill={color}
    />
  </svg>
);
