import { CARD_STATUS } from 'constants/strings';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { SendCardSchema } from 'pages/illume/card-details-v2/card-details-v2-pending/initiator/constants/constants';
import { InitiatorCardViewDTO } from 'types';

import { Delivered } from './InitiatorContent.delivered';
import { Read } from './InitiatorContent.read';

export const InitiatorSentContent = (props: {
  card: InitiatorCardViewDTO;
  resendCardService: (payload: SendCardSchema) => Promise<any>;
  onResendSuccess: () => any;
}) => {
  const { card, resendCardService, onResendSuccess } = props;
  const status = card.status;
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();
  if (status === CARD_STATUS.DELIVERED) {
    return (
      // there is logic to resend card
      <Delivered
        batch={!!card.batch}
        recipientMessage={card.recipientMessage}
        recipientData={card.recipientData}
        sendCardContext={card.sendCardContext}
        onResendCard={(payload) => {
          return resendCardService({
            message: payload.message,
            recipientsContacts: payload.recipientsContacts,
          })
            .then(() => enqueueSuccessSnackbar('card has been re-sent!'))
            .then(onResendSuccess)
            .catch((e) =>
              enqueueErrorSnackbar(
                `something went wrong, err: ${e?.message || 'unexpected error'}`,
              ),
            );
        }}
      />
    );
  } else if (status === CARD_STATUS.READ) {
    return (
      <Read
        recipientMessage={card.recipientMessage}
        replies={card.replies}
        sendCardContext={card.sendCardContext}
      />
    );
  } else {
    return <div>invalid sent card status</div>;
  }
};
