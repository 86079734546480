import { useEffect } from 'react';

import { Maybe } from '@illume/shared';
import { matchPath, useLocation } from 'react-router-dom';

import { VIEW_PAGE_EVENTS } from 'constants/event-names/EventNames';
import { IAnalytics } from 'domain/interfaces/IAnalytics';

export function usePageViewTrack(
  analytics: IAnalytics<any, any>,
  location?: Maybe<{ pathname: string }>,
) {
  const loc = location || useLocation();
  useEffect(() => {
    for (const key in VIEW_PAGE_EVENTS) {
      const event = VIEW_PAGE_EVENTS[key as keyof typeof VIEW_PAGE_EVENTS];
      const { page, name: eventName } = event;
      const pageMatchespathname = !!matchPath(loc.pathname, {
        path: page,
        exact: true,
        strict: false,
      });
      if (eventName && pageMatchespathname) {
        console.log('calling analytics event..', { eventName, page });
        analytics.track(eventName);
      }
    }
  }, [loc.pathname]);
}
