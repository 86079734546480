import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { colors } from 'constants/design';

const FileUploadLinearProgress = withStyles((theme) => ({
  root: {
    height: 30,
    borderRadius: 100,
  },
  colorPrimary: {
    backgroundColor: colors.textInputBackground,
  },
  bar: {
    backgroundColor: colors.primary,
  },
}))(LinearProgress);

export default FileUploadLinearProgress;
