import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from 'constants/strings';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'unset',
      marginBottom: theme.spacing(5),
      alignItems: 'flex-end',
    },
  },
  barrier: {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    color: colors.darkText,
  },
}));

function Footer() {
  const style = useStyle();
  const mobile = !useIsDesktop();

  return (
    <div className={style.container}>
      <div>
        <Text fontWeight={600} lineHeight={'27px'} fontSize={{ desktop: 18, mobile: 16 }}>
          <Link href={TERMS_OF_SERVICE_URL} className={style.barrier}>
            Terms of Service
          </Link>
          <span>{mobile && '|'}</span>
          <Link href={PRIVACY_POLICY_URL} className={style.barrier}>
            Privacy Policy
          </Link>
        </Text>
      </div>

      <div>
        <Text fontWeight={400} lineHeight={'27px'} fontSize={{ desktop: 14, mobile: 12 }}>
          {mobile
            ? '©Illume 2022. all rights reserved. dedicated to those who shine.'
            : ' copyright 2022. all rights reserved. dedicated to those who shine.'}
        </Text>
      </div>
    </div>
  );
}

export default Footer;
