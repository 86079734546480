import { useRef, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { BaseIconButton } from 'components/illume/buttons';
import { VideoUpload } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { IllumeLayout } from 'components/layouts';
import {
  colors,
  DEFAULT_PALETTE,
  generateContrastFromTheme,
  PRIMARY_THEME,
} from 'constants/design';
import { generateContrastFromPalette } from 'constants/design/cardTheme';
import { checkFF, FLAGS } from 'utils/featureFlag';

const MobileVideoRecord = () => {
  const videoUploadRef = useRef();
  const [url, setVideoUrl] = useState();
  const [status, setStatus] = useState('idle');
  const theme = PRIMARY_THEME;
  const palette = DEFAULT_PALETTE;
  const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateContrastFromPalette(palette, theme)
    : generateContrastFromTheme(theme);
  const handleVideoUpload = async (e) => {
    const file = e?.target?.files[0];
    setStatus('loading');
    setTimeout(() => {
      setVideoUrl(URL.createObjectURL(file));
      setStatus('ready');
    }, 3000);
  };

  return (
    <IllumeLayout
      contentProps={{ justifyContent: 'center', alignItems: 'center', style: { flex: 1 } }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Text align="center" color={colors.contrastText} fontSize={'2rem'}>
          Click the video icon to test native camera feature
        </Text>
        {status === 'loading' ? (
          <CircularProgress size={64} style={{ color: colors.contrastText }} />
        ) : status === 'idle' ? (
          <BaseIconButton
            onClick={() => videoUploadRef.current.click()}
            icon={VideoUpload}
            size={64}
          />
        ) : status === 'ready' ? (
          <VideoDisplay vidUrl={url} size={284} color={contrastColor} />
        ) : null}
        <input
          ref={videoUploadRef}
          style={{ display: 'none' }}
          type="file"
          onChange={handleVideoUpload}
          // capture using OS's video recorder
          accept="video/*"
          capture="user"
        />
      </div>
    </IllumeLayout>
  );
};

export default MobileVideoRecord;
