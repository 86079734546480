import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    color: ({ backgroundColor }) =>
      backgroundColor === 'background'
        ? theme.palette.primary.main
        : theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
}));

/**
 * @type {React.CSSProperties}
 */
export const styleContainer = {
  zIndex: 1000,
  position: 'relative',
};
