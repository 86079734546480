import { ColorsV2 } from 'domain/theme/colorsV2';

interface Props extends React.SVGProps<SVGSVGElement> {
  showRedDot: boolean;
}
export const NotificationIcon = ({ showRedDot, color = ColorsV2.notBlack, ...rest }: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.1187 18.8818L24.75 16.5131V13.3755C24.7472 11.2072 23.9405 9.11694 22.4859 7.50894C21.0313 5.90094 19.0322 4.8894 16.875 4.66992H15.125C12.9678 4.8894 10.9687 5.90094 9.51409 7.50894C8.0595 9.11694 7.25281 11.2072 7.25 13.3755V16.5131L4.88129 18.8818C4.71721 19.0459 4.62503 19.2684 4.625 19.5005V22.1255C4.625 22.3575 4.71719 22.5801 4.88128 22.7442C5.04538 22.9083 5.26794 23.0005 5.5 23.0005H11.625H13.375H18.625H20.375H26.5C26.7321 23.0005 26.9546 22.9083 27.1187 22.7442C27.2828 22.5801 27.375 22.3575 27.375 22.1255V19.5005C27.375 19.2684 27.2828 19.0459 27.1187 18.8818Z"
        fill={color}
      />
      <rect x="15.125" y="2.875" width="1.75" height="2.625" fill={color} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6247 22.125V23.6797C11.6057 24.7897 11.997 25.8677 12.7236 26.7071C13.4502 27.5465 14.4609 28.0882 15.5622 28.2286C16.1704 28.2889 16.7846 28.2213 17.3651 28.0301C17.9457 27.8388 18.4798 27.5282 18.9331 27.1181C19.3863 26.708 19.7488 26.2077 19.9971 25.6491C20.2454 25.0906 20.374 24.4862 20.3747 23.875V22.125H18.6247V23.875C18.6247 24.5712 18.3481 25.2389 17.8558 25.7312C17.3636 26.2234 16.6959 26.5 15.9997 26.5C15.3035 26.5 14.6358 26.2234 14.1435 25.7312C13.6512 25.2389 13.3747 24.5712 13.3747 23.875V22.125H11.6247Z"
        fill={color}
      />
      {showRedDot && (
        <path
          d="M25.9998 9.74461C25.9998 12.2549 23.9649 14.2898 21.4544 14.2898C18.9441 14.2898 16.9092 12.2549 16.9092 9.74461C16.9092 7.2343 18.9441 5.19922 21.4544 5.19922C23.9649 5.19922 25.9998 7.2343 25.9998 9.74461Z"
          fill="#E15F2D"
          stroke="#FFFDF3"
          stroke-width="1.33333"
        />
      )}
    </svg>
  );
};
