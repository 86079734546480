import { useEffect, useState } from 'react';

import { Box, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';

import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import { Close } from 'components/illume/icons';
import { colors } from 'constants/design';
type UseAnnouncement = {
  wiggle: boolean;
  triggerWiggle: () => void;
};
export const useAnnouncement = (): UseAnnouncement => {
  const [wiggle, setWiggle] = useState(false);

  useEffect(() => {
    if (wiggle) {
      const timer = setTimeout(() => setWiggle(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [wiggle]);

  const triggerWiggle = () => {
    setWiggle(true);
  };

  return { wiggle, triggerWiggle };
};

const wiggleAnimation = {
  wiggle: {
    rotate: [-1.5, 1.5, -1.5], // Rotation angles for wiggle
    transition: { repeat: 3, duration: 0.3, ease: 'easeInOut' },
  },
  initial: {
    rotate: 0,
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  announcmentText: string;
  position?: 'top' | 'bottom';
  wiggle?: boolean;
};

// styles
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiAlert-action': {
        alignItems: 'flex-start',
        paddingTop: 10,
      },
    },
  }),
);

export const AnnouncmentToast = ({
  open,
  onClose,
  announcmentText,
  position = 'top',
  wiggle,
}: Props) => {
  const classes = useStyles();

  return (
    <Snackbar anchorOrigin={{ horizontal: 'center', vertical: position }} open={open}>
      <motion.div variants={wiggleAnimation} animate={wiggle ? 'wiggle' : 'initial'}>
        <Alert
          className={classes.root}
          action={
            <Box pr={1}>
              <BaseIconButtonNew
                icon={Close}
                iconProps={{ color: colors.contrastText, size: 12 }}
                onClick={onClose}
              />
            </Box>
          }
          variant="filled"
          severity="info"
        >
          {announcmentText}
        </Alert>
      </motion.div>
    </Snackbar>
  );
};
