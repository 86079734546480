import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

import { AvatarInitialName } from 'components/avatar';
import { Modal } from 'components/illume/modal';
import Text from 'components/illume/text/Text';
import { colors, rem } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { spellNames } from 'utils/string';

const contentStyles = makeStyles((theme) => ({
  introMessage: {
    maxHeight: 300,
    minHeight: 200,
    backgroundColor: colors.backgroundGray,
    borderRadius: '8px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const GiftIntroModal = ({
  hideModal,
  showModal,
  initiatorName = '',
  introMessage = '',
  recipientData = [],
  onContributeToGift,
}) => {
  const desktop = useIsDesktop();
  const classes = contentStyles();

  return (
    <Modal
      name="giftIntroModal"
      promptConfig={{
        onOk: onContributeToGift,
        okText: `Continue to ${spellNames(recipientData)}'s group card`,
      }}
      onClose={hideModal}
      show={showModal}
    >
      <Grid container direction="column" spacing={2}>
        <Grid container item spacing={1} alignItems="center">
          <Grid item>
            <AvatarInitialName size={desktop ? 37 : 29} name={initiatorName} />
          </Grid>

          <Grid item>
            <Text fontSize={rem[1750]} fontWeight={700} color={colors.darkText}>
              {initiatorName}
            </Text>
          </Grid>
        </Grid>

        <Grid item className={classNames(classes.introMessage, 'customIllumeScrollbar-gray')}>
          <Text fontWeight={400} fontSize={rem[1125]} color={colors.darkText}>
            {`“${introMessage}”`}
          </Text>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default GiftIntroModal;
