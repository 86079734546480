import { css } from '@emotion/css';
import { MyCardsType } from '@illume/shared';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { ColorsV2 } from 'domain/theme/colorsV2';
import { mq } from 'infra/emotion/breakpoints';
import Button, { ButtonProps } from 'views/components-v2/core/button';

export interface ITab {
  state: MyCardsType;
  count: number;
  id: number;
}

export interface TabContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  data: ITab[];
  selectedTab: ITab;
  onTabClick?: (tab: ITab) => void;
  title: string;
}

export const TabContainer = observer(({ className, data, title, ...props }: TabContainerProps) => {
  const activeConfig: ButtonProps = {
    background: ColorsV2.primary,
    color: ColorsV2.notBlack,
    outlineColor: 'none',
  };

  const defaultConfig: ButtonProps = {
    hoverBackground: ColorsV2.softGray,
    background: ColorsV2.transparent,
    color: ColorsV2.gray90,
    outlineColor: ColorsV2.softGray,
  };

  const activeClass = css`
    background: ${ColorsV2.realWhite};
  `;

  const defaultClass = css`
    transition: background 300ms ease-in-out;
    border-radius: 16px;
    background: ${ColorsV2.softGray};
    color: ${ColorsV2.gray90};
  `;

  return (
    <div
      className={css`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 16px;
        ${className}
      `}
      {...props}
    >
      <h2
        className={css`
          min-width: 200px;
          font-weight: 700;
          margin-right: auto;
          ${mq.mobile} {
            font-size: 28px;
            line-height: 40px;
          }
          ${mq.desktop} {
            font-size: 40px;
            line-height: 48px;
          }
          $
        `}
      >
        {title}
      </h2>
      <div
        className={css`
          display: flex;
          column-gap: 16px;
        `}
      >
        {data.map((item) => {
          const active = item.id === props.selectedTab.id;
          return (
            <Button
              onClick={() => props.onTabClick?.(item)}
              className={css`
                padding-right: 16px;
                &:hover {
                  .icon {
                    background: ${ColorsV2.realWhite};
                  }
                }
              `}
              key={item.state}
              leftIcon={
                <div className={classNames(defaultClass, 'icon', active ? activeClass : '')}>
                  <span
                    className={css`
                      font-size: 14px;
                      text-align: center;
                      margin-right: 8px;
                      width: 16px;
                      margin-left: 8px;
                    `}
                  >
                    {item.count}
                  </span>
                </div>
              }
              {...(active ? activeConfig : defaultConfig)}
            >
              {item.state}
            </Button>
          );
        })}
      </div>
    </div>
  );
});
