import { makeStyles, createStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const useStyles = makeStyles((theme) => {
  const desktop = theme.breakpoints.up('md');
  return createStyles({
    container: {
      padding: 80,
      width: '100%',
      zIndex: 1,
      gridArea: 'main',
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'clip',
      gridTemplateRows: '1fr auto',
      gridTemplateColumns: '315px',
      gridTemplateAreas: '"productImageContainer" "productDetails"',
      [desktop]: {
        columnGap: 80,
        height: '100vh',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '400px 350px',
        gridTemplateAreas: '"productImageContainer productDetails"',
      },
    },
    productImageContainer: {
      display: 'grid',
      gridArea: 'productImageContainer',
    },
    productImage: {
      margin: 'auto 0',
      borderRadius: 6,
      width: 315,
      height: 315,
      objectFit: 'contain',
      [desktop]: {
        width: 400,
        height: 400,
      },
    },
    productDetails: {
      display: 'flex',
      flexDirection: 'column',
      gridArea: 'productDetails',
    },
    productTopTexts: {
      display: 'grid',
      rowGap: 20,
      marginBottom: 24,
      [desktop]: {
        rowGap: 16,
        marginBottom: 115,
      },
    },
    productName: {
      display: 'grid',
      rowGap: 12,
    },
    tags: {
      display: 'flex',
      columnGap: 8,
    },
    productButtons: {
      display: 'grid',
      rowGap: 10,
      [desktop]: {
        rowGap: 16,
      },
    },
    productTrackButton: {
      height: 40,
      [desktop]: {
        height: 48,
      },
    },
    productHelpButton: {
      color: colors.gray60,
      height: 40,
      [desktop]: {
        height: 48,
      },
    },
  });
});

export default useStyles;
