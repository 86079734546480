import { Grid, Link } from '@material-ui/core';

import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { rem } from 'constants/design';

const OpenInstagram = () => {
  const x = [
    { title: 'Normal web URLs, @illumenotes', link: 'http://instagram.com/_u/illumenotes/' },
    { title: 'Open instagram', link: 'instagram://app' },
    { title: 'Open instagram camera', link: 'instagram://camera' },
    { title: 'Open instagram profile', link: 'instagram://user?username=illumenotes' },
    {
      title: 'Open instagram android',
      link: 'intent://instagram.com/_n/mainfeed/#Intent;package=com.instagram.android;scheme=https;end',
    },
  ];
  return (
    <IllumeLayout>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        style={{ marginTop: '200px' }}
        spacing={4}
      >
        <Grid item>
          <Text fontWeight={700} fontSize={rem[2000]}>
            Opening instagram
          </Text>
        </Grid>
        <Grid item container direction="column" alignItems="center">
          {x.map(({ link, title }) => (
            <Grid item>
              <Link style={{ fontSize: 20 }} href={link}>
                {title}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default OpenInstagram;
