import config from 'config';
import { IAnalytics } from 'domain/interfaces/IAnalytics';

import { MockLoggingAnalytics } from './mockAnalytics/MockAnalytics';
import { DevSegmentAnalytics } from './segment-dev';
import { SegmentAnalytics } from './segment/SegmentAnalytics';

export const createAnalytics = (): IAnalytics => {
  return config.env === 'local'
    ? new MockLoggingAnalytics()
    : config.env === 'dev'
    ? new DevSegmentAnalytics(new SegmentAnalytics())
    : new SegmentAnalytics();
};
