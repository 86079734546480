export const CalendarDatePicker = ({ color, size }) => {
  return (
    <svg
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M18.3307 20H5.66933C4.74933 20 4 19.2513 4 18.3307V7.00333C4 6.08267 4.74933 5.33333 5.67 5.33333H18.33C19.2507 5.33333 20 6.08267 20 7.00333V18.3307C20 19.2513 19.2513 20 18.3307 20V20ZM5.67 6.66667C5.48467 6.66667 5.33333 6.818 5.33333 7.00333V18.3307C5.33333 18.516 5.484 18.6667 5.66933 18.6667H18.3307C18.516 18.6667 18.6667 18.516 18.6667 18.3307V7.00333C18.6667 6.818 18.5153 6.66667 18.33 6.66667H5.67V6.66667ZM19.3333 9.33333H4.66667C4.29867 9.33333 4 9.03467 4 8.66667C4 8.29867 4.29867 8 4.66667 8H19.3333C19.702 8 20 8.29867 20 8.66667C20 9.03467 19.702 9.33333 19.3333 9.33333ZM7.33333 7.33333C6.96533 7.33333 6.66667 7.03467 6.66667 6.66667V4.66667C6.66667 4.29867 6.96533 4 7.33333 4C7.70133 4 8 4.29867 8 4.66667V6.66667C8 7.03467 7.70133 7.33333 7.33333 7.33333ZM16.6667 7.33333C16.298 7.33333 16 7.03467 16 6.66667V4.66667C16 4.29867 16.298 4 16.6667 4C17.0353 4 17.3333 4.29867 17.3333 4.66667V6.66667C17.3333 7.03467 17.0353 7.33333 16.6667 7.33333Z" />
      <path d="M8.00001 17.3333C8.3682 17.3333 8.66668 17.0349 8.66668 16.6667C8.66668 16.2985 8.3682 16 8.00001 16C7.63182 16 7.33334 16.2985 7.33334 16.6667C7.33334 17.0349 7.63182 17.3333 8.00001 17.3333Z" />
      <path d="M8.00001 14.6666C8.3682 14.6666 8.66668 14.3682 8.66668 14C8.66668 13.6318 8.3682 13.3333 8.00001 13.3333C7.63182 13.3333 7.33334 13.6318 7.33334 14C7.33334 14.3682 7.63182 14.6666 8.00001 14.6666Z" />
      <path d="M10 17.3333C10.3682 17.3333 10.6667 17.0349 10.6667 16.6667C10.6667 16.2985 10.3682 16 10 16C9.63183 16 9.33335 16.2985 9.33335 16.6667C9.33335 17.0349 9.63183 17.3333 10 17.3333Z" />
      <path d="M10 14.6666C10.3682 14.6666 10.6667 14.3682 10.6667 14C10.6667 13.6318 10.3682 13.3333 10 13.3333C9.63183 13.3333 9.33335 13.6318 9.33335 14C9.33335 14.3682 9.63183 14.6666 10 14.6666Z" />
      <path d="M10 12C10.3682 12 10.6667 11.7015 10.6667 11.3334C10.6667 10.9652 10.3682 10.6667 10 10.6667C9.63183 10.6667 9.33335 10.9652 9.33335 11.3334C9.33335 11.7015 9.63183 12 10 12Z" />
      <path d="M14 17.3333C14.3682 17.3333 14.6667 17.0349 14.6667 16.6667C14.6667 16.2985 14.3682 16 14 16C13.6318 16 13.3333 16.2985 13.3333 16.6667C13.3333 17.0349 13.6318 17.3333 14 17.3333Z" />
      <path d="M14 14.6666C14.3682 14.6666 14.6667 14.3682 14.6667 14C14.6667 13.6318 14.3682 13.3333 14 13.3333C13.6318 13.3333 13.3333 13.6318 13.3333 14C13.3333 14.3682 13.6318 14.6666 14 14.6666Z" />
      <path d="M14 12C14.3682 12 14.6667 11.7015 14.6667 11.3334C14.6667 10.9652 14.3682 10.6667 14 10.6667C13.6318 10.6667 13.3333 10.9652 13.3333 11.3334C13.3333 11.7015 13.6318 12 14 12Z" />
      <path d="M16 14.6666C16.3682 14.6666 16.6667 14.3682 16.6667 14C16.6667 13.6318 16.3682 13.3333 16 13.3333C15.6318 13.3333 15.3334 13.6318 15.3334 14C15.3334 14.3682 15.6318 14.6666 16 14.6666Z" />
      <path d="M16 12C16.3682 12 16.6667 11.7015 16.6667 11.3334C16.6667 10.9652 16.3682 10.6667 16 10.6667C15.6318 10.6667 15.3334 10.9652 15.3334 11.3334C15.3334 11.7015 15.6318 12 16 12Z" />
      <path d="M12 17.3333C12.3682 17.3333 12.6667 17.0349 12.6667 16.6667C12.6667 16.2985 12.3682 16 12 16C11.6318 16 11.3333 16.2985 11.3333 16.6667C11.3333 17.0349 11.6318 17.3333 12 17.3333Z" />
      <path d="M12 14.6666C12.3682 14.6666 12.6667 14.3682 12.6667 14C12.6667 13.6318 12.3682 13.3333 12 13.3333C11.6318 13.3333 11.3333 13.6318 11.3333 14C11.3333 14.3682 11.6318 14.6666 12 14.6666Z" />
      <path d="M12 12C12.3682 12 12.6667 11.7015 12.6667 11.3334C12.6667 10.9652 12.3682 10.6667 12 10.6667C11.6318 10.6667 11.3333 10.9652 11.3333 11.3334C11.3333 11.7015 11.6318 12 12 12Z" />
    </svg>
  );
};
