import { Collapse } from '@material-ui/core';

interface HeightBasedCollapsibleProps {
  isOpen: boolean;
  children: React.ReactNode;
  containerProps?: any;
}

export const HeightBasedCollapsible: React.FC<HeightBasedCollapsibleProps> = ({
  isOpen,
  children,
}) => {
  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  );
};
