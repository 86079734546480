const Share = ({ size, props }) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m11.251 10.399 1.666-1.001c.895-.538 1.992-.662 2.969-.29.578.22 1.23.288 1.907.16a3.452 3.452 0 0 0 2.715-2.685A3.473 3.473 0 0 0 16.52 2.45c-1.286.216-2.352 1.183-2.728 2.431a3.46 3.46 0 0 0-.123.579c-.13 1.036-.792 1.934-1.687 2.472l-1.018.61c-.873.522-1.93.558-2.884.206a3.46 3.46 0 0 0-1.196-.214 3.476 3.476 0 0 0-3.393 4.183 3.452 3.452 0 0 0 2.714 2.686c.677.13 1.33.06 1.908-.16.977-.373 2.071-.248 2.969.29l1.66.996c.562.335.903.943.903 1.596v.01a3.475 3.475 0 0 0 4.167 3.395 3.459 3.459 0 0 0 2.7-2.7 3.475 3.475 0 0 0-3.396-4.166c-.42 0-.824.077-1.196.213-.955.353-2.008.317-2.882-.206l-1.79-1.075a1.849 1.849 0 0 1-.898-1.587v-.019c-.002-.65.34-1.255.9-1.591Z"
        fill="#FFFDF3"
      />
    </svg>
  );
};

export default Share;
