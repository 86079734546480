import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import qs from 'query-string';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { useBirthdayModalContext } from 'components/birthday-modal-provider/BirthdayModalProvider';
import { Button } from 'components/illume/buttons';
import { BackArrow } from 'components/illume/icons';
import { Link } from 'components/illume/link';
import { Modal } from 'components/illume/modal';
import { TextArea } from 'components/illume/text-area';
import { TextBox } from 'components/illume/text-box';
import { TextInput } from 'components/illume/text-inputs';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { Link as IllumeRouterLink } from 'components/link';
import { SentryErrorFallback } from 'components/sentry-error-fallback';
import { colors, rem } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { MY_CARDS_URL, RECEIVE_READ_URL, RECEIVE_SHIP_URL } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useAuth } from 'contexts/authentication';
import { useStores } from 'contexts/store';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import Gift from 'domain/entities/gift/gift';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { shootConfetti } from 'hooks/useConfettiCanon';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import cardServiceV2 from 'infra/card-service/cardService';
import Sentry from 'infra/sentry/sentry';
import { noop } from 'utils';
import { checkFF } from 'utils/featureFlag';
import { isValid } from 'utils/form';
import { showNames } from 'utils/string';

import AnimatedGift from './gift.animated.min';
import { RecipientStore } from './store';
import { SummaryGridCards } from './SummaryCards';

// Will be moved to separate file after API wiring.
const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      width: '100%',
      zIndex: 1,
      marginTop: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    headingBox: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(5.5),
    },
    headingGift: {
      [theme.breakpoints.up('md')]: {
        width: '28.125rem',
      },
      textAlign: 'start',
    },
    paragraphBox: {
      paddingRight: theme.spacing(6.5),
      paddingLeft: theme.spacing(6.5),
      marginTop: theme.spacing(2.5),
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
      },
    },
    replyBox: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
      [theme.breakpoints.up('md')]: {
        width: 780,
        padding: theme.spacing(0),
      },
    },
    buttonBox: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(3),
      width: 264,
      [theme.breakpoints.up('md')]: {
        width: 350,
      },
    },
    downloadPdfCta: {
      marginTop: theme.spacing(4),
    },
    giftWrapper: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(40),
        display: 'block',
        width: '39.93rem',
        height: '20.56rem',
      },
      width: '16.09rem',
    },
    giftImage: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        width: '100%',
        height: '100%',
      },
    },
    giftImageMobile: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    productThumbnail: {
      textAlign: 'center',
      marginBottom: 20,
      paddingBottom: 50,
      background: 'rgba(255, 253, 243)',
    },
    giftProductText: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    overlay: {
      background: 'rgba(255, 253, 243, 0.95)',
      inset: 0,
      position: 'fixed',
      zIndex: 2,
      overflowY: 'scroll',
    },
    giftPosition: { transform: 'translateY(20%)' },
    form: {
      marginTop: theme.spacing(3),
    },
    boxAnimation: {
      cursor: 'pointer',
      width: 281,
      [theme.breakpoints.up('md')]: {
        width: 500,
        margin: 'auto',
      },
    },
    replyMessage: {
      fontWeight: 500,
      fontSize: 16,
      maxHeight: 210,
      overflowY: 'scroll !important' as any,
    },
    policeText: {
      width: 250,
      marginTop: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    replyListCard: {
      maxWidth: '100%',
      whiteSpace: 'pre-line',
    },
  });
});

interface IOverlayProduct {
  vendor?: string;
  title: string;
  image: string;
  formattedPrice: string;
}

function Overlay({
  product,
  onOverlayClick,
  sendCode,
  email,
  showNotice,
}: {
  product: IOverlayProduct;
  onOverlayClick: () => void;
  sendCode: string;
  email: string;
  showNotice: boolean;
}) {
  const classes = useStyles();
  const { vendor, title, image, formattedPrice } = product;
  const history = useIllumeHistory();
  const formattedVendor = vendor && `by ${vendor}`;
  const formattedProductTitle = `${title} Gift Card`;
  return (
    <motion.div
      // slide in bottom to top with opacity
      initial={{ opacity: 0, y: 500 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 500 }}
      transition={{ duration: 0.3 }}
      className={classes.overlay}
      onClick={onOverlayClick}
    >
      <div className={classes.giftPosition}>
        <div className={classes.productThumbnail}>
          <img style={{ width: 300, margin: 'auto' }} src={image} alt={'Shopify Product'} />
          <div className={classes.giftProductText}>
            <Text fontWeight="bold" lineHeight={'36px'} fontSize={{ desktop: 28, mobile: 24 }}>
              {formattedProductTitle}
            </Text>
            {formattedVendor && (
              <Text lineHeight={'18px'} fontWeight={300} fontSize={16}>
                {formattedVendor}
              </Text>
            )}
          </div>

          <Grid item className={classes.button}>
            <Button
              onClick={() => {
                return history.push({
                  pathname: RECEIVE_SHIP_URL,
                  search: qs.stringify({ c: sendCode }),
                });
              }}
              fullWidth
            >
              SEND MY GIFT
            </Button>
            {showNotice && (
              <Text
                italic
                align="center"
                className={classes.policeText}
                lineHeight={'18px'}
                fontWeight={300}
                fontSize={13}
                color={colors.gray60}
              >
                *you will receive the gift card information to your email within 24 hours.
              </Text>
            )}
          </Grid>
          <IllumeRouterLink
            style={{ paddingBottom: 20 }}
            to={{
              pathname: `${MY_CARDS_URL}/received/${sendCode}`,
              search: qs.stringify({ c: sendCode, email: email }),
            }}
            color="textPrimary"
          >
            <Text italic color={colors.labelText} decoration="underline">
              ship later
            </Text>
          </IllumeRouterLink>
        </div>
      </div>
    </motion.div>
  );
}

let shotNumber = 0;

const OpenNote: React.FunctionComponent<{ store: RecipientStore; onDownload: () => any }> = ({
  store,
  onDownload,
}) => {
  const {
    recipientData: recipientData = [],
    notes = [],
    id: sendCode,
    cardReplyContext,
    replies = [],
  } = store.card || {};

  const gift = store.cardGift;
  const g = gift && Gift.fromDto(gift);
  const errorMessage = store.card?.giftV2?.errMsg;
  const giftErrorMsg = errorMessage + ', please contact';
  const analytics = useAnalytics();

  const { userProfileStore } = useStores();

  const spelledNames = showNames(recipientData);

  const history = useIllumeHistory();
  const location = useLocation();
  const email = qs.parse(location.search).email as unknown as string;
  useDocumentTitle(`${spelledNames}'s card`);

  const isMobile = !useIsDesktop();

  const externalGiftEntity =
    store.card?.externalGift && ExternalGift.fromDto(store.card.externalGift);

  const hasReply = replies.length > 0;
  const hasMyReply = !!cardReplyContext;

  const [showReplyModal, setShowReplyModal] = useState(false);
  const [showReplySentModal, setShowReplySentModal] = useState(false);
  const [showShipmentOverlay, setShowShipmentOverlay] = useState(false);
  const showAnimatedGift =
    !showShipmentOverlay && (store.giftV2MetGoal || store.externalGiftMetGoal);

  const { show: showBirthdayModal } = useBirthdayModalContext();

  const classes = useStyles();

  async function handleReplySent() {
    await when(() => !!userProfileStore.userProfileDTO, { timeout: 2000 });
    if (!userProfileStore.birthday) {
      showBirthdayModal();
    }
    return;
  }

  useEffect(() => {
    if (shotNumber < 1) {
      shootConfetti();
      shotNumber++;
    }
  }, []);

  useEffect(() => {
    if (email) {
      analytics.track(EVENT_NAMES.RECIPIENT_OPEN_THE_CARD_RECIPIENT.name, { email });
    }
  }, [analytics, email]);

  function handleDownloadPdf() {
    onDownload();
  }

  const productToShow = store.giftMerchantProduct
    ? {
        image: store.giftMerchantProduct.variant.img,
        title: store.giftMerchantProduct.name,
        vendor: store.giftMerchantProduct.vendor,
        formattedPrice: store.giftMerchantProduct.productPrice.toFormat(),
      }
    : externalGiftEntity
    ? {
        image: externalGiftEntity.image,
        title: externalGiftEntity.title,
        formattedPrice: externalGiftEntity.price.toFormat(),
      }
    : null;

  return (
    <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
      <AnimatePresence>
        {showShipmentOverlay && sendCode && productToShow && (
          <Overlay
            showNotice={!!g?.isDigitalGiftcard}
            onOverlayClick={() => setShowShipmentOverlay(false)}
            product={productToShow}
            email={email}
            sendCode={sendCode}
          />
        )}
      </AnimatePresence>

      <IllumeLayout
        contentProps={{
          justifyContent: 'center',
        }}
      >
        <Grid className={classes.container}>
          <Grid className={classes.headingBox}>
            <Text fontWeight={800} fontSize={{ mobile: rem[1250], desktop: rem[2250] }}>
              Hey {spelledNames},<br /> you’re cared for.
            </Text>
          </Grid>
          {errorMessage && giftErrorMsg && (
            <Grid item style={{ marginBottom: 24 }}>
              <Alert severity="warning">
                {giftErrorMsg}{' '}
                <IllumeRouterLink
                  id="need_help"
                  href="mailto:support@illumenotes.com"
                  rel="noreferrer"
                  target="_blank"
                  underline="always"
                  color="inherit"
                >
                  illume support
                </IllumeRouterLink>
              </Alert>
            </Grid>
          )}

          <Grid className={classes.paragraphBox}>
            <Text fontWeight={300} fontSize={rem[1000]} data-testid="intro-text">
              {store.contributorSummary}
            </Text>
          </Grid>
          {showAnimatedGift && (
            <>
              <div className={classes.giftWrapper}>
                <AnimatedGift
                  text={isMobile ? 'tap to open' : 'click to open'}
                  className={classes.boxAnimation}
                  onClick={() => {
                    shootConfetti();
                    setShowShipmentOverlay(true);
                  }}
                />
              </div>
              <Grid className={classes.paragraphBox}>
                <Text fontWeight={300} fontSize={rem[1000]} data-testid="intro-text">
                  {isMobile
                    ? 'Tap anywhere on the gift to open.'
                    : 'Click anywhere on the gift to open.'}
                </Text>
              </Grid>
            </>
          )}

          <SummaryGridCards
            notes={notes}
            onViewNote={(note) =>
              history.push(
                { pathname: RECEIVE_READ_URL, search: location.search },
                { noteViewCode: note.viewCode },
              )
            }
          />
          <Grid
            container
            direction="column"
            spacing={3}
            alignItems={'center'}
            className={classes.replyBox}
          >
            {hasReply && (
              <Grid item container direction="column" spacing={2} xs style={{ textAlign: 'left' }}>
                {replies.map(({ message, status, replier, id }) => {
                  const isMyReply = id === cardReplyContext?.id;
                  return (
                    <Grid item key={id}>
                      <TextBox
                        className={cx('fs-mask', classes.replyListCard)}
                        minHeight={64}
                        text={message}
                        label={isMyReply ? 'Your Reply' : `${replier}'s Reply`}
                        shadow={status === 'Unread'}
                        color={colors.gray100}
                        overflow="scroll"
                        dark
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {!hasMyReply ? (
              <Grid item className={classes.button}>
                <Button
                  onClick={() => {
                    analytics.track(EVENT_NAMES.CLICK_REPLY_ALL_RECIPIENT.name);
                    return setShowReplyModal(true);
                  }}
                  startIcon={<BackArrow style={{ marginRight: -5 }} color={colors.contrastText} />}
                  fullWidth
                >
                  send reply all
                </Button>
              </Grid>
            ) : (
              <Grid item className={classes.button}>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: MY_CARDS_URL,
                    });
                  }}
                  fullWidth
                >
                  next
                </Button>
              </Grid>
            )}

            {checkFF('DOWNLOAD_CARD_AS_PDF') && (
              <Grid item className={classes.downloadPdfCta}>
                <Link
                  underline
                  fontSize={16}
                  fontWeight={500}
                  color={colors.gray40}
                  onClick={handleDownloadPdf}
                >
                  Download as PDF
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </IllumeLayout>
      <ReplyAllModal
        showReplyModal={showReplyModal}
        setShowReplyModal={(val: boolean) => setShowReplyModal(val)}
        onReplySentOk={handleReplySent}
        sendCode={sendCode}
        recipientEmail={email}
        showReplySentModal={showReplySentModal}
        onReplySuccess={() => {
          setShowReplySentModal(true);
          store.refetchCard();
          analytics.track(EVENT_NAMES.SEND_REPLY_ALL_RECIPIENT.name);
        }}
      />
    </Sentry.ErrorBoundary>
  );
};

export function ReplyAllModal({
  showReplyModal = false,
  setShowReplyModal = (val: boolean) => {
    val;
  },
  onReplySuccess = noop,
  sendCode = '',
  recipientEmail = '',
  showReplySentModal = false,
  onReplySentOk = noop,
}) {
  const classes = useStyles();
  const { authenticated } = useAuth();
  const { verificationStore } = useStores();
  const { enqueueErrorSnackbar } = useIllumeSnackbar();

  const minMessageChar = 'thanks'.length;
  const notAuthenticatedFormSchema = yup.object().shape({
    email: yup.string().required(`please provide your email`).email('must be a valid email'),
    name: yup.string().required('please provide your name'),
    message: yup
      .string()
      .required('message is required')
      .min(minMessageChar, `Minimum ${minMessageChar} characters`),
  });

  const authenticatedFormSchema = yup.object().shape({
    message: yup
      .string()
      .required('message is required')
      .min(minMessageChar, `Minimum ${minMessageChar} characters`),
  });

  const {
    handleSubmit,
    register,
    errors,
    trigger,
    formState: { isSubmitted, isSubmitting },
  } = useForm({
    resolver: yupResolver(authenticated ? authenticatedFormSchema : notAuthenticatedFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: !authenticated ? { email: recipientEmail } : {},
  });

  const defaultOnSettled = () => {
    // always revalidate just to be sure
    //noop, used to be queryClient.invalidateQueries(CARD_QUERY_KEY);
  };
  const defaultOnSuccess = (_, variables) => {
    // noop
  };
  // Todo: refactor POST/UPDATE/DELETE request with mutation
  const { mutateAsync: sendReply } = useMutation(
    ({ message, sendCode }) => cardServiceV2.sendReply(sendCode, { message }),
    {
      onError: (err) => enqueueErrorSnackbar(err?.message),
      onSuccess: defaultOnSuccess,
      onSettled: defaultOnSettled,
    },
  );

  const notAuthenticatedReplyAll = async ({ email, message, name }) => {
    return verificationStore
      .beginVerification(email, name)
      .then(() => sendReply({ message, sendCode }))
      .then(onReplySuccess)
      .catch((e) =>
        enqueueErrorSnackbar(e?.message || 'something went wrong during verification process'),
      );
  };

  const handleFormSubmit = async (formData) => {
    const result = authenticated
      ? await sendReply({ message: formData.message, sendCode }).then(onReplySuccess)
      : await notAuthenticatedReplyAll(formData);
    return result;
  };

  const modalConfig = showReplySentModal
    ? {
        title: 'REPLY SENT!',
        promptConfig: {
          okText: 'done',
          onOk: () => {
            setShowReplyModal(false);
            onReplySentOk();
          },
        },
      }
    : {
        title: 'SEND A REPLY-ALL!',
      };

  return (
    <Modal
      show={showReplyModal}
      showBackButton
      onClose={() => setShowReplyModal(false)}
      onBack={() => setShowReplyModal(false)}
      {...modalConfig}
    >
      {showReplySentModal ? (
        <Text fontWeight={300} align="center">
          woohoo! you're awesome :)
        </Text>
      ) : (
        <>
          <Text fontWeight={300} align="center">
            want to thank your senders? send a reply-all using the space below.
          </Text>
          <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container direction="column" spacing={2}>
              {!authenticated && (
                <Grid item container direction="column" spacing={1}>
                  <Grid item>
                    <TextInput
                      inputId="name"
                      name="name"
                      data-testid="name"
                      label="First Name"
                      placeholder={`e.g. Sohale`}
                      ref={register}
                      error={errors?.name}
                      errorMessage={errors?.name?.message}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      inputId="email"
                      name="email"
                      data-testid="email"
                      label="Your Email"
                      placeholder="e.g. sohale@illumenotes.com"
                      ref={register}
                      error={errors?.email}
                      errorMessage={errors?.email?.message}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <TextArea
                  label="Reply message"
                  className={cx(classes.replyMessage, 'fs-mask customIllumeScrollbar-orange')}
                  placeholder="type your reply here."
                  name="message"
                  ref={register}
                  minRows={8}
                  data-testid="note"
                  error={errors?.message}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.buttonBox}
              onClick={() => trigger()}
            >
              <Button
                data-testid="send-reply-all"
                loading={isSubmitting}
                fullWidth
                disabled={!isValid(errors) && isSubmitted}
                type="submit"
              >
                <Text fontSize={{ desktop: '16px', mobile: '14px' }} color={colors.contrastText}>
                  send reply-all
                </Text>
              </Button>
            </Grid>
          </form>
        </>
      )}
    </Modal>
  );
}

export default observer(OpenNote);
