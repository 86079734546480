import { FunctionComponent, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Modal as MuiModal, Paper, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { GoogleLogin } from 'components/google-login';
import { BaseIconButton, Button } from 'components/illume/buttons';
import { Close } from 'components/illume/icons';
import { Link } from 'components/illume/link';
import { LeftArrowButton } from 'components/illume/nav-arrows';
import { TextInput } from 'components/illume/text-inputs';
import LinedText from 'components/illume/text/LinedText';
import Text from 'components/illume/text/Text';
import { rem, spacing, colors } from 'constants/design';
import { TERMS_OF_SERVICE_URL, PRIVACY_POLICY_URL } from 'constants/strings';
import { useStores } from 'contexts/store';
import AuthenticationStore from 'contexts/store/auth-store/auth.store';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { IRootVerifyStore } from 'infra/mobx-stores/verification-store/IRootVerifyStore';
import { Theme } from 'infra/mui-stylesheet';
import { VerificationForm } from 'pages/illume/verification-form';
import { checkFF } from 'utils/featureFlag';
import logger from 'utils/logger';

import { useModalStyles, usePaperStyles } from './ExperimentalLoginDialog.styles';

const useStyles = makeStyles<Theme, { state: Store['state'] }>((theme) => ({
  container: {
    padding: ({ state }) =>
      state.name === 'verification' ? `20px 0px` : `20px ${theme.spacing(3.5)}px`,
    maxWidth: 500,
    [theme.breakpoints.up('md')]: {
      padding: ({ state }) => (state.name === 'verification' ? `0px 0px 61px 0px` : `61px 0px`),
      maxWidth: 500,
    },
  },
  text: {
    marginTop: theme.spacing(3.5),
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  policyText: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingLeft: spacing[5],
      paddingRight: spacing[5],
    },
  },
  welcomeText: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  googleButton: {
    fontSize: rem[1000],
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1125],
    },
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  dividerText: {
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1125],
    },
    marginTop: theme.spacing(4),
    fontSize: '1.125rem',
  },
  continueButton: {
    marginTop: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(3),
  },
}));

interface ExperimentalLoginDialogProps {
  hideModal: () => any;
  open: boolean;
  onLoginSuccess: () => any;
}

type Idle = {
  name: 'idle';
  mode: 'google' | 'email';
};

type AuthError = {
  name: 'authErr';
  error: Error;
};

type Verification = {
  name: 'verification';
};

type Authenticating = {
  name: 'authenticating';
};

class Store {
  constructor(
    private _verificationStore: IRootVerifyStore,
    private _authenticationStore: AuthenticationStore,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    when(
      () => this._verificationStore.verificationFormVisible,
      () => {
        runInAction(() => {
          this.state = {
            name: 'verification',
          };
        });
      },
    );
  }

  state: Idle | Authenticating | Verification | AuthError = { name: 'idle', mode: 'google' };

  googleAuthTask = new ObservablePromise(this._authenticationStore.googleLogin);

  private readonly GOOGLE_ERRORS = {
    IDPIFRAME_INITIALIZATION_FAILED: 'idpiframe_initialization_failed', // initialization of the Google Auth API failed (this will occur if a client doesn't have [third party cookies enabled](https://github.com/google/google-api-javascript-client/issues/260))
    POPUP_CLOSED_BY_USER: 'popup_closed_by_user', // The user closed the popup before finishing the sign in flow.
    ACCESS_DENIED: 'access_denied', // The user denied the permission to the scopes required
    IMMEDIATE_FAILED: 'immediate_failed', // No user could be automatically selected without prompting the consent flow.
  } as const;

  get showGoogle() {
    if (this.state.name === 'idle')
      return this.state.mode === 'google' && checkFF('GOOGLE_SIGN_IN') === true;
  }

  get showEmailForm() {
    return this.state.name === 'idle' && this.state.mode === 'email';
  }

  emailIsValid: boolean = false;
  get emailIsSubmitting() {
    return this._verificationStore.issueChallengeStatus === 'issuing';
  }
  googleError: typeof this.GOOGLE_ERRORS[keyof typeof this.GOOGLE_ERRORS] | null = null;
  serverError: null | Error = null;

  handleContinueWithEmail() {
    if (this.state.name === 'idle') this.state.mode = 'email';
  }

  handleGoogleLoginFailure({ error }: any) {
    runInAction(() => (this.googleError = error));
  }

  authenticateGoogleToken(tokenId: string) {
    runInAction(() => (this.state = { name: 'authenticating' }));
    return this._authenticationStore.googleLogin(tokenId);
  }

  handleEmailSubmit(email: string) {
    logger.log('beginning verification');
    return this._verificationStore.beginVerification(email);
  }

  handleBack() {
    runInAction(() => {
      if (this.state.name === 'idle') {
        this.state.mode = 'google';
      }
    });
  }
}

export const useExperimentalLoginDialog = () => {
  const [vis, setVis] = useState(false);
  return {
    hide: () => setVis(false),
    show: () => setVis(true),
    visible: vis,
  };
};

const ExperimentalLoginDialog: FunctionComponent<ExperimentalLoginDialogProps> = ({
  hideModal,
  open,
  onLoginSuccess,
}) => {
  const desktop = useIsDesktop();
  const { verificationStore, authenticationStore, userProfileStore } = useStores();
  const [store] = useState(() => new Store(verificationStore, authenticationStore));
  const modalClasses = useModalStyles();
  const paperClasses = usePaperStyles({ background: colors.primary });
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();

  const classes = useStyles({ state: store.state });
  const ref = useRef();

  const {
    showEmailForm,
    showGoogle,
    emailIsSubmitting: isSubmitting,
    emailIsValid: isValid,
    handleContinueWithEmail,
    handleGoogleLoginFailure,
  } = store;

  const verificationSubtitle = (
    <Text align={desktop ? 'center' : 'left'}>
      We sent a 4-digit verification code
      <br />
      to {verificationStore.userProfile?.email}.
    </Text>
  );

  const notifiySuccess = () => {
    when(() => !!userProfileStore.firstName, { timeout: 2000 })
      .then(() => {
        enqueueSuccessSnackbar(`welcome back ${userProfileStore.firstName}!`);
      })
      .catch(() => enqueueSuccessSnackbar('welcome back!'));
  };

  const onEmailSubmit = ({ email }: { email: string }) => {
    return store
      .handleEmailSubmit(email)
      .then(notifiySuccess)
      .then(hideModal)
      .then(onLoginSuccess)
      .catch((e) => enqueueErrorSnackbar(e.message));
  };

  function handleGoogleLoginSuccess({ tokenId }: { tokenId: string }) {
    return store
      .authenticateGoogleToken(tokenId)
      .then(notifiySuccess)
      .then(hideModal)
      .then(onLoginSuccess)
      .catch((e) => enqueueErrorSnackbar(e.message));
  }

  const replyFormSchema = yup.object().shape({
    email: yup.string().required('please provide your email').email('must be a valid email'),
    // name: yup.string().required('please provide your name'),
  });

  const userEmail = new URLSearchParams(window.location.search).get('userEmail') || '';

  const { handleSubmit, register } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(replyFormSchema),
    defaultValues: { email: userEmail },
  });

  return (
    <MuiModal
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
      className={modalClasses.root}
      onClose={() => {
        hideModal();
        verificationStore.cancel();
      }}
      open={open}
      ref={ref}
    >
      <Slide direction="up" in={open}>
        <Paper className={paperClasses.root}>
          <Box position="absolute" top={20} right={20} zIndex={1}>
            {/** @ts-ignore */}
            <BaseIconButton
              onClick={() => {
                hideModal();
                verificationStore.cancel();
              }}
              icon={() => <Close color={colors.contrastText} size={16} hasBlob={undefined} />}
            />
          </Box>
          <Grid container justifyContent="center">
            <Grid container direction="column" className={classes.container} item>
              {store.state.name === 'idle' && (
                <Grid container direction="column" className={classes.text}>
                  <Grid container item justifyContent="center" alignItems="center" spacing={2}>
                    {store.state.mode === 'email' && (
                      <Grid item>
                        <LeftArrowButton
                          onClick={(e) => {
                            e.preventDefault();
                            store.handleBack();
                          }}
                          iconProps={{
                            size: 32,
                            // arrowColor: colors.,
                            blobColor: colors.contrastText,
                          }}
                          color={undefined}
                          inverted={undefined}
                          classes={undefined}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <Text
                        lineHeight={desktop ? '16px' : '46px'}
                        color={colors.contrastText}
                        fontSize={{ mobile: rem[2250], desktop: rem[3000] }}
                        fontWeight={900}
                        align="center"
                      >
                        hello, sunshine.
                      </Text>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.welcomeText}>
                    <Text
                      lineHeight="26px"
                      color={colors.contrastText}
                      fontSize={'18px'}
                      align="center"
                      fontWeight={400}
                    >
                      Welcome to illume.{' '}
                      {showGoogle && <>To continue, sign in with Google or email.</>}
                    </Text>
                  </Grid>
                </Grid>
              )}
              {showEmailForm && (
                <form onSubmit={handleSubmit(onEmailSubmit)}>
                  <Grid item container className={classes.form} direction="column" spacing={4}>
                    <Grid item container spacing={2} direction="column">
                      <Grid item>
                        <TextInput
                          inputId="email"
                          name="email"
                          data-testid="email"
                          label="Email Address"
                          placeholder="jack@illumenotes.com"
                          ref={register}
                        />
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Button
                        loading={isSubmitting}
                        disabled={isValid}
                        fullWidth
                        customcolor="primaryShaded"
                        type="submit"
                      >
                        <Text
                          color={colors.contrastText}
                          fontSize={{ desktop: rem[1125], mobile: rem[1000] }}
                        >
                          next
                        </Text>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              {showGoogle && (
                <>
                  <Grid className={classes.googleButton}>
                    <GoogleLogin
                      unavailable={store.googleError === 'idpiframe_initialization_failed'}
                      onFailure={handleGoogleLoginFailure}
                      onSuccess={handleGoogleLoginSuccess}
                    />
                  </Grid>
                  <Grid className={classes.dividerText}>
                    <LinedText
                      textBackground={colors.primary}
                      color={colors.labelText}
                      fontWeight={500}
                      text="or"
                    />
                  </Grid>
                </>
              )}

              {store.state.name === 'idle' && store.state.mode === 'google' && (
                <Grid className={classes.continueButton}>
                  <Button fullWidth customcolor="primaryShaded" onClick={handleContinueWithEmail}>
                    <Text
                      color={colors.contrastText}
                      fontSize={{ desktop: rem[1000], mobile: rem[1000] }}
                    >
                      Continue with Email
                    </Text>
                  </Button>
                </Grid>
              )}
              {store.state.name === 'authenticating' && (
                <Text fontSize={24} align="center" color={colors.contrastText}>
                  authenticating...
                </Text>
              )}
              {store.state.name === 'verification' && (
                <VerificationForm
                  customButtonColor="primaryShaded"
                  title="check your email."
                  subtitle={verificationSubtitle}
                  titleColor={colors.contrastText}
                  color={colors.darkText}
                  background={colors.contrastText}
                  onResend={verificationStore.resendChallenge}
                  onVerify={(otpCode: string) => verificationStore.verifyDigits(otpCode)}
                  expiration={true}
                  sendDigitsError={verificationStore.issueChallengeError}
                  error={verificationStore.verifyDigitsError}
                  verifying={verificationStore.verifyDigitsStatus === 'verifying'}
                  alignItems={desktop ? 'center' : 'start'}
                />
              )}
              <Grid
                container
                className={classes.policyText}
                direction="row"
                justifyContent="center"
              >
                <Text color={colors.gray40}>by continuing, I agree to illume’s &nbsp;</Text>
                <Link href={TERMS_OF_SERVICE_URL} underline={true}>
                  terms of service
                </Link>
                <Text color={colors.gray40}>&nbsp; and &nbsp;</Text>
                <Link href={PRIVACY_POLICY_URL} underline={true}>
                  privacy policy
                </Link>
                <Text color={colors.gray40}>.</Text>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Slide>
    </MuiModal>
  );
};

export default observer(ExperimentalLoginDialog);
