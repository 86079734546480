// these data is copy pasted from https://gitlab.com/illumenotes/marketing-site
// I think whe should pout these in @illume/shared

import { Maybe } from '@illume/shared';

import { colors } from 'constants/design';
import { Money } from 'domain/entities/money/Money';

export const allBenefitList = [
  'Add messages, photos and GIFs',
  'Add videos',
  'Desktop and mobile friendly',
  'Advertisement free',
  'Confetti',
  'Themed backgrounds',
  'Invite contributors via URL',
  'Invite contributors via SMS or email',
  'Schedule delivery',
  'Accessible forever',
  'Privacy between notes',
  'Note suggestion library',
  'Reminders',
  'Collect funds for gift',
  'Download card as PDF',
  'Send to multiple recipients',
  'Priority support',
  'Member-pricing on curated gifts',
  '“Split the gift” of any online gift',
  'Bulk upload cards via .csv',
  'Birthday reminders',
];

const basicBenefits = [
  '2 group cards',
  'Unlimited contributors',
  'Add messages, photos, GIFs & videos',
];

const standardBenefits = [
  'Unlimited group cards',
  'Bulk creation of cards',
  '10% off illume’s curated gifting marketplace',
  'Split any gift with URL',
];

// remove first one, according to figma
const benefits = allBenefitList.slice(1);

const premiumBenefits = [
  'Unlimited contributors',
  'Collect funds for a group gift',
  'Send to multiple recipients',
];

type IBenefit = { text: string; textColor?: string | null; color: string | null };

type FilterCallback<T = any> = (element: T, index: number, self: T[]) => boolean;

export interface IPricing {
  tag: Maybe<{
    title: string;
    color: string;
  }>;
  note: Maybe<string>;
  banner: Maybe<{ text: string }>;
  benefits: IBenefit[];
  hiddenBenefits: Maybe<IBenefit[]>;
  subtitle: string;
  description: Maybe<string>;
  price: Money;
  pricingType: 'Premium' | 'Basic' | 'Standard' | 'Slack Standard' | 'Slack Basic';
  copy: string;
  frequency: Maybe<string>;
}

const dup: FilterCallback = (elem, index, self) => self.indexOf(elem) === index;

const theme = {
  colors: {
    orange: colors.primary,
    gray40: colors.gray40,
    alertGreen: colors.alertGreen,
  },
};

export const SLACK_APP_PRICING_DATA: IPricing[] = [
  {
    banner: null,
    pricingType: 'Basic',
    copy: 'Basic',
    price: Money.fromCents(0),
    description:
      'A Basic illume group plan allows unlimited contributors on a single card (and gift if you desire) for your first <b>TWO </b>cards. It’s perfect for larger groups on birthdays, work anniversaries, and other special occasions!',
    subtitle:
      'Everything you need to bring a group together and celebrate someone special. For any occasion or just because.',
    benefits: basicBenefits.map((text) => ({ color: theme.colors.alertGreen, text })),
    frequency: undefined,
    note: undefined,
    tag: undefined,
    hiddenBenefits: basicBenefits
      .concat(benefits)
      // remove already included benefits
      .filter(dup)
      // from "Reminders" to the rest, greys it
      .map((text, index, arr) => {
        const reminderIndex = arr.findIndex((e) => e === 'Reminders');
        const moreThanReminderIndex = index > reminderIndex;
        return {
          color: moreThanReminderIndex ? null : theme.colors.orange,
          text,
          textColor: moreThanReminderIndex ? theme.colors.gray40 : null,
        };
      }),
  },
  {
    banner: { text: 'Save 20%' },
    pricingType: 'Slack Standard',
    copy: 'Premium',
    description: `A Standard illume group plan allows <b>unlimited contributors</b>, <b>unlimited cards</b> (and access to gifting, if you desire) <b>for an entire year</b>. It’s perfect for larger groups on birthdays, work anniversaries, and other special occasions!`,
    tag: {
      color: theme.colors.orange,
      title: 'MOST POPULAR',
    },
    benefits: standardBenefits.map((text) => ({ color: theme.colors.alertGreen, text })),
    frequency: 'Per Year',
    note: null,
    price: Money.fromDollarString('199'),
    subtitle:
      'Everything you need for unlimited group celebration—on special occasion and all the moments in-between.',
    hiddenBenefits: standardBenefits
      .concat(benefits)
      .filter(dup)
      .map((text) => ({ text, color: theme.colors.orange })),
  },
];

export const PRICING_DATA: IPricing[] = [
  {
    banner: null,
    pricingType: 'Basic',
    copy: 'Basic',
    price: Money.fromCents(0),
    description:
      'A Basic illume group plan allows unlimited contributors on a single card (and gift if you desire) for your first <b>TWO </b>cards. It’s perfect for larger groups on birthdays, work anniversaries, and other special occasions!',
    subtitle:
      'Everything you need to bring a group together and celebrate someone special. For any occasion or just because.',
    benefits: basicBenefits.map((text) => ({ color: theme.colors.alertGreen, text })),
    frequency: undefined,
    note: undefined,
    tag: undefined,
    hiddenBenefits: basicBenefits
      .concat(benefits)
      // remove already included benefits
      .filter(dup)
      // from "Reminders" to the rest, greys it
      .map((text, index, arr) => {
        const reminderIndex = arr.findIndex((e) => e === 'Reminders');
        const moreThanReminderIndex = index > reminderIndex;
        return {
          color: moreThanReminderIndex ? null : theme.colors.orange,
          text,
          textColor: moreThanReminderIndex ? theme.colors.gray40 : null,
        };
      }),
  },

  {
    banner: null,
    pricingType: 'Premium',
    copy: 'Standard',
    price: Money.fromDollarString('5.99'),
    frequency: 'Per Card',
    note: null,
    tag: null,
    description: `A Premium illume group plan allows <b>unlimited contributors</b> on a single card
                  (and gift if you desire) for a <b>one-time charge of $5.99</b>. It’s perfect for
                  larger groups on birthdays, work anniversaries, and other special occasions!`,
    subtitle:
      'Everything you need to bring a group together and celebrate someone special. For any occasion or just because.',
    benefits: premiumBenefits.map((text) => ({ text, color: theme.colors.alertGreen })),
    hiddenBenefits: premiumBenefits
      .concat(benefits)
      .filter(dup)
      .map((text) => ({ text, color: theme.colors.orange })),
  },
  {
    banner: { text: 'Save 20%' },
    pricingType: 'Standard',
    copy: 'Premium',
    description: `A Standard illume group plan allows <b>unlimited contributors</b>, <b>unlimited cards</b> (and access to gifting, if you desire) <b>for an entire year</b>. It’s perfect for larger groups on birthdays, work anniversaries, and other special occasions!`,
    tag: {
      color: theme.colors.orange,
      title: 'MOST POPULAR',
    },
    benefits: standardBenefits.map((text) => ({ color: theme.colors.alertGreen, text })),
    frequency: 'Per Year',
    note: null,
    price: Money.fromDollarString('99'),
    subtitle:
      'Everything you need for unlimited group celebration—on special occasion and all the moments in-between.',
    hiddenBenefits: standardBenefits
      .concat(benefits)
      .filter(dup)
      .map((text) => ({ text, color: theme.colors.orange })),
  },
];

export const FAQS = [
  {
    question: 'What is the right plan for me?',
    answer: `If you plan to send more than three cards a year, the annual plan is the best option. Not only do you save on postage, stamps, and all the costs that come with physical cards, but you receive competitive pricing compared to other digital solutions. Plus, you’re able to split the cost of a gift—something that illume is pioneering, which will delight and create savings for everyone.`,
  },
  {
    question: 'Is there a limit to the number of contributors per card?',
    answer: `There's no limit to the number of contributors on a card. Go crazy and invite as many individuals as you'd like!`,
  },
  {
    question: 'I’m looking to gift outside the illume marketplace, can I do that?',
    answer: `Yes! You can input the URL of any product on the Internet and split the cost of the gift on illume.`,
  },
];
