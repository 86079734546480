import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { RECEIVE_URL } from 'constants/strings';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { ReceiveCardNotificationDTO } from 'types';
import { noop, pluralize } from 'utils';
import distanceFormatter from 'utils/date/distanceFormatter';

import { Notification } from './Notification';
import { ConcreateNotification } from './types';

const ReceiveCard: React.FC<ConcreateNotification<ReceiveCardNotificationDTO>> = ({
  notification,
  rounded,
  style,
  markAsRead = noop,
}) => {
  const { timestamp, read, payload, id } = notification || {};
  const { sendCode, initiatorName, noteCount } = payload || {};

  const history = useIllumeHistory();

  const handleClick = () => {
    const config = {
      pathname: RECEIVE_URL,
      search: `?c=${sendCode}`,
    };
    history.push(config);
    markAsRead(id);
  };

  const hasMoreSenders = noteCount > 1;
  const sendersCopyText = () =>
    hasMoreSenders
      ? `${initiatorName} + ${noteCount - 1} ${pluralize('other', noteCount - 1)}`
      : `${initiatorName}`;

  return (
    <Notification dotted={!read} rounded={rounded} style={style} onClick={handleClick}>
      <Text display="inline" fontSize={rem[875]} color={colors.gray40}>
        <b>you’re cared for.</b> {sendersCopyText()} sent you a card!
      </Text>
      <Text fontSize={rem[875]} display="inline" color={colors.gray20}>
        {' '}
        {distanceFormatter(new Date(timestamp))}
      </Text>
    </Notification>
  );
};

export default ReceiveCard;
