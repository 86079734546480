import { makeAutoObservable, reaction } from 'mobx';

import { IStorage } from 'contexts/store';
import AuthenticationStore from 'contexts/store/auth-store/auth.store';
import { CardBatchDTO, MyCardsType } from 'types';

import { ExperimentalAsyncCachedStore } from './ExperimentalAsyncCacheStore';

// TODO: missing specs from react query
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CARDS_REFRESH_INTERVAL = 60 * 1000; // polling
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REFETCH_ON_RECONNECT = true; // reconnect on DC

export type CardBatchesRecord = Record<Exclude<MyCardsType, MyCardsType.RECEIVED>, CardBatchDTO[]>;

export class BatchedCardStore {
  asyncCachedStore: ExperimentalAsyncCachedStore<CardBatchesRecord>;

  deletePendingBatch = async (batchId: string) => {
    await this.deleteBatchService(batchId);
    if ('data' in this.asyncCachedStore.state && this.asyncCachedStore.state.data) {
      this.asyncCachedStore.updateData({
        // sent data is untouched
        Sent: this.asyncCachedStore.state.data.Sent,
        Pending: this.asyncCachedStore.state.data.Pending.filter(
          (batch) => batch.batchId !== batchId,
        ),
      });
    }
  };

  constructor(
    private _batchCardService: (
      type: Omit<MyCardsType, MyCardsType.RECEIVED>,
    ) => Promise<CardBatchDTO[]>,
    private cache: IStorage<CardBatchesRecord>,
    private authStore: AuthenticationStore,
    private deleteBatchService: (id: string) => Promise<any>,
  ) {
    makeAutoObservable(this);
    this.asyncCachedStore = new ExperimentalAsyncCachedStore<CardBatchesRecord>(
      () =>
        Promise.all([
          this._batchCardService(MyCardsType.PENDING),
          this._batchCardService(MyCardsType.SENT),
        ]).then(([pending, sent]) => {
          return { Pending: pending, Sent: sent };
        }),
      {
        get: () => this.cache.get('batch-cards'),
        set: (data) => this.cache.set('batch-cards', data),
      },
    );
    this.asyncCachedStore.execute();
    reaction(
      () => this.authStore.authenticated,
      () => this.asyncCachedStore.refresh(),
    );
  }

  __experimentalRefresh() {
    return this.asyncCachedStore.refresh();
  }
}
