import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => (props.color ? `${props.color}` : theme.palette.primary.contrastText),
    fontSize: (props) => `${props.fontSize}` ?? 'default',
    fontWeight: (props) => `${props.fontWeight}` ?? 500,
    textAlign: (props) => props.align,
  },
}));

const Header1 = ({ children, fontSize, color, decoration, fontWeight, align, className }) => {
  const classes = useStyles({ fontSize, color, decoration, fontWeight, align });

  const generateClasses = (classes, className) => (className ? { className } : { classes });

  return (
    <Typography variant="h1" {...generateClasses(classes, className)}>
      {children}
    </Typography>
  );
};

export default Header1;
