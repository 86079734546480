import { Helmet } from 'react-helmet';

import config from 'config';

export const HtmlHeaders = () => {
  return (
    <>
      {/*TODO: refactor with ts-pattern*/}
      {config.env === 'dev' && (
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="fake-key-this-should-be-shown-on-dev-build"
          />
        </Helmet>
      )}
      {config.env === 'prod' && (
        <Helmet>
          <meta name="facebook-domain-verification" content="hf9v70fqlk6p7p0eo371ow12oo2fwg" />
          <script type="text/javascript" src="/vendor/metaPixel.js"></script>
          {/* noscript elements */}
          <noscript>
            {
              '<img width="1" style="display: none" src="https://www.facebook.com/tr?id=921825765451331&ev=PageView&noscript=1" />'
            }
          </noscript>
        </Helmet>
      )}
    </>
  );
};
