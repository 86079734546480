import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import qs from 'query-string';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import Button from 'components/illume/buttons/Button'; // eslint-disable-line import/no-unresolved
import TextInput from 'components/illume/text-inputs/TextInput';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { SentryErrorFallback } from 'components/sentry-error-fallback';
import { colors, rem } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { MY_CARDS_URL } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useStores } from 'contexts/store';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import Sentry from 'infra/sentry/sentry';
import { TABS } from 'pages/illume/my-cards/MyCards';
import { isValid } from 'utils/form';

const SAVE_NOTE_MAX_WIDTH = 550;
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: '0 auto',
    maxWidth: SAVE_NOTE_MAX_WIDTH,
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    marginTop: theme.spacing(20),
  },
  headerBox: {
    paddingTop: theme.spacing(5),
  },
  textBox: {
    paddingTop: theme.spacing(2),
    color: colors.contrastText,
  },
  buttonBox: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      width: '80%',
    },
  },
}));

const SaveNotesForm = ({
  setEmailVerify = (email = '') => {},
  recipientName,
  defaultValues = { name: recipientName, email: '' },
}) => {
  const classes = useStyles();
  const { verificationStore } = useStores();
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const analytics = useAnalytics();

  const verifyFormSchema = yup.object().shape({
    name: yup.string().required('please provide your name'),
    email: yup.string().required('please provide your email').email('must be a valid email'),
  });

  const {
    handleSubmit,
    register,
    trigger,
    errors,
    formState: { isSubmitted, isSubmitting },
  } = useForm({
    resolver: yupResolver(verifyFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onEmailVerifyFormSuccess = () => {
    analytics.track(EVENT_NAMES.SAVE_NOTES_TO_EMAIL_RECIPIENT.name);
    history.push({ pathname: MY_CARDS_URL, search: qs.stringify({ type: TABS.RECEIVED }) });
  };

  // I copied this from the contributer flow
  const handleEmailVerifyFormSubmit = ({ email, name }) => {
    setEmailVerify(email);
    const handleErr = (e) => enqueueErrorSnackbar(e?.message || 'something went wrong');
    return verificationStore
      .beginVerification(email, name)
      .then(onEmailVerifyFormSuccess)
      .catch(handleErr);
  };

  const text = isDesktop ? "don't lose your notes!" : 'save your notes!';

  return (
    <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
      <IllumeLayout background={colors.primary} hideHamburger>
        <Grid container direction="column" className={classes.container}>
          <Grid className={classes.headerBox}>
            <Text
              color={colors.contrastText}
              fontSize={{ mobile: rem[2250], desktop: rem[3000] }}
              fontWeight={800}
              align={isDesktop ? 'center' : 'left'}
            >
              {text}
            </Text>
          </Grid>
          <Grid className={classes.textBox}>
            <Text align={isDesktop ? 'center' : 'left'} fontSize={rem[1125]} fontWeight={300}>
              sign up for illume by entering your email below to save your notes.
            </Text>
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit(handleEmailVerifyFormSubmit)}>
            <Grid>
              <TextInput
                label="Your Name"
                error={errors.name}
                errorMessage={errors?.name?.message}
                inputId="name"
                ref={register}
                name="name"
                data-testid="name"
                placeholder="e.g Sohale"
              />
            </Grid>
            <Grid>
              <TextInput
                label="Your Email"
                error={errors.email}
                errorMessage={errors?.email?.message}
                inputId="email"
                ref={register}
                name="email"
                data-testid="email"
                placeholder={`e.g. ${recipientName}`}
              />
            </Grid>

            <Grid className={classes.buttonBox} onClick={() => trigger()}>
              <Button
                fullWidth
                type="submit"
                disabled={isSubmitted && !isValid(errors)}
                loading={isSubmitting}
                customcolor="primaryShaded"
              >
                <Text fontSize={{ desktop: '16px', mobile: '14px' }} fontWeight={300}>
                  verify email address
                </Text>
              </Button>
            </Grid>
          </form>
        </Grid>
      </IllumeLayout>
    </Sentry.ErrorBoundary>
  );
};

export default SaveNotesForm;
