import { makeStyles } from '@material-ui/core/styles';

// import { colors } from 'constants/design';

const useStyles = makeStyles((theme) => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      padding: 10,
      [theme.breakpoints.up('md')]: {
        rowGap: 36,
        padding: 30,
      },
    },
  };
});

export default useStyles;
