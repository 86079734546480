const storageAvailableCache = {};
export function storageAvailable(type) {
  if (storageAvailableCache[type]) return storageAvailableCache[type];
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    storageAvailableCache[type] = true;
    return true;
  } catch (e) {
    storageAvailableCache[type] = false;
    return false;
  }
}
