/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef, useLayoutEffect } from 'react';

import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors, rem, borderRadius } from 'constants/design';
import usePreviousValue from 'hooks/usePrevValue';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: rem[3750],
    },
    color: ({ color }) => color,
    background: ({ background }) => background,
    borderRadius,
    boxShadow: ({ error }) => (error ? `4px 4px 0px ${colors.alertRed}` : ''),
  },
  input: {
    // aligning the number center
    textAlign: 'center',
    height: '100%',
    fontWeight: 700,
    padding: `0 ${rem[1000]}`,
    [theme.breakpoints.up('md')]: {
      fontSize: rem[3750],
    },
    fontSize: rem[2250],

    // remove up down arrow
    // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

export const NumericVerifyInput = ({
  focus,
  autoFocus,
  error,
  color,
  background = colors.background,
  ...props
}) => {
  const inputRef = useRef(null);
  const classes = useStyles({ error, color, background });
  const prevFocus = usePreviousValue(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <Input
      classes={classes}
      disableUnderline
      type="number"
      inputProps={{ role: 'numberInput', inputMode: 'decimal' }}
      inputRef={inputRef}
      {...props}
    />
  );
};
