import {
  CONTRIBUTOR_WELCOME_URL,
  INITIATOR_BASIC_INFO_URL,
  RECEIVE_WELCOME_URL,
} from 'constants/strings';

export const EVENT_NAMES = {
  RECIPIENT_OPEN_THE_CARD_DOWNLOAD_PDF: { name: 'recipient welcome page - download pdf' },
  ENTER_EMAIL_BASIC_INFO: { name: 'enter email - basic info' },
  CREATE_ACCOUNT_BASIC_INFO: { name: 'create account - basic info' },
  CREATE_GROUP_CARD_BASIC_INFO: { name: 'create group card - basic info' },
  SKIP_NOTE_INITIATOR: { name: 'skip note - initiator' },
  DRAFT_SUBMISSION_INITIATOR: { name: 'draft submission - initiator' },
  SUBMIT_NOTE_INITIATOR: { name: 'submit note - initiator' },
  SHARE_LINK_INITIATOR: { name: 'share link - initiator' },
  VISIT_MY_CARDS_INITIATOR: { name: 'visit my cards - initiator' },
  CHANGE_DEADLINE_MY_CARDS: { name: 'change deadline - my cards' },
  CHANGE_REMINDER_DATE_MY_CARDS: { name: 'change reminder date - my cards' },
  INVITE_CONTRIBUTORS_MY_CARDS: { name: 'invite contributors - my cards' },
  SEND_TO_RECIPIENT_MY_CARDS: { name: 'send to recipient - my cards' },
  RESEND_TO_RECIPIENT_MY_CARDS: { name: 'resend to recipient - my cards' },
  DELETE_NOTE_MY_CARDS: { name: 'delete note - my cards' },
  RESEND_CODE_VERIFY: { name: 'resend code - verify' },
  NEW_NOTES_ALERT_INITIATOR: { name: 'new notes alert - initiator' },
  CREATE_GIFT: { name: 'create gift' },
  INITIATOR_PAYS_GIFT: { name: 'initiator pays gift' },
  UPLOAD_PHOTO: { name: 'upload photo' },
  UPLOAD_VIDEO: { name: 'upload video' },
  UPLOAD_GIF: { name: 'upload gif' },
  DRAFT_SUBMISSION_CONTRIBUTOR: { name: 'draft submission - contributor' },
  PICK_THEME_CONTRIBUTOR: { name: 'pick theme - contributor' },
  PICK_THEME_INITIATOR: { name: 'pick theme - initiator' },
  SUBMIT_NOTE_CONTRIBUTOR: { name: 'submit note - contributor' },
  SUBMIT_NOTE_JOIN_CONTRIBUTOR: { name: 'submit note join - contributor' },
  SELECT_EMAIL_REPLY_CONTRIBUTOR: { name: 'select email reply - contributor' },
  CONTRIBUTOR_PAYS_GIFT: { name: 'contributor pays gift' },
  INVITED_CONTRIBUTOR_BY_EMAIL_CONTRIBUTOR: { name: 'invited contributor by email - contributor' },
  SAVE_NOTES_TO_EMAIL_RECIPIENT: { name: 'save notes to email - recipient' },
  CLICK_REPLY_ALL_RECIPIENT: { name: 'click reply all - recipient' },
  REPLY_LATER_RECIPIENT: { name: 'reply later - recipient' },
  SEND_REPLY_ALL_RECIPIENT: { name: 'send reply all - recipient' },
  RECIPIENT_OPEN_THE_CARD_RECIPIENT: { name: 'recipient open the card - recipient' },
  LOGIN_MARKETING: { name: 'login - marketing' },
  ENTERED_CORRECT_CODE_VERIFY: { name: 'entered correct code - verify' },
  EDIT_NOTE_MY_CARDS: { name: 'edit note - my cards' },
  DRAFT_SUBMISSION_EDIT_NOTE: { name: 'draft submission - edit note' },
  SUBMIT_NOTE_EDIT_NOTE: { name: 'submit note - edit note' },
  ERROR_GOOGLE_LOGIN: { name: 'error - google login' },
  ERROR_PAGE_NOT_FOUND: { name: 'error - page not found' },
  ERROR_VERIFY: { name: 'error - verify' },
  ERROR_GIFT: { name: 'error - gift' },
  RECEIVE_CARD_INITIATOR: { name: 'receive card - initiator' },
  RECEIVE_CARD_CONTRIBUTOR: { name: 'receive card - contributor' },
  CREATE_ACCOUNT: { name: 'Create Account' },
  LOGIN: { name: 'Login' },
  READ_CARD_INITIATOR: { name: 'read card - initiator' },
  READ_CARD_CONTRIBUTOR: { name: 'read card - contributor' },
  SOCIAL_SHARE_RECIPIENT: { name: 'social share - recipient' },

  // not exactly a page view event
  VIEW_CONTRIBUTOR_LIST_MY_CARDS: { name: 'view contributor list - my cards' },
  VIEW_PAGE_VERIFY: { name: 'view page - verify' },
};

export const VIEW_PAGE_EVENTS = {
  VIEW_COVER_PAGE_RECIPIENT: { name: 'view cover page - recipient', page: RECEIVE_WELCOME_URL },
  VIEW_PAGE_BASIC_INFO: { name: 'view page - basic info', page: INITIATOR_BASIC_INFO_URL },
  VIEW_COVER_PAGE_CONTRIBUTOR: {
    name: 'view cover page - contributor',
    page: CONTRIBUTOR_WELCOME_URL,
  },
} as const;
