export const navButtonStyles = {
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  position: 'absolute',
  top: 18,
  right: 15,
  width: 44,
  height: 44,
  borderRadius: '50%',
  background: 'transparent',
  color: '#fff',
};

export const navButtonSvgStyles = {
  display: 'inline-block',
};

export const navButtonPathStyles = {
  stroke: '#fff',
  fill: 'transparent',
  strokeWidth: 3,
  strokeLinecap: 'round',
};
