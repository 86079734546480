// eslint-disable-next-line import/no-anonymous-default-export
export default ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00593851 10.1419C-0.119371 7.60758 1.74694 4.90872 4.79969 2.86002C8.05774 0.673335 11.6704 -0.210363 15.563 0.0417427C17.8079 0.185045 19.9221 0.795407 21.6978 2.26293C22.8523 3.21563 23.7454 4.38593 24.5159 5.65973C25.8757 7.9048 27.0141 10.2454 27.6567 12.8063C28.5472 16.3517 27.678 19.4804 25.4064 22.2642C23.0256 25.1807 19.8608 26.6615 16.1735 27.1498C13.5953 27.4921 11.0838 27.2214 8.64429 26.3192C5.66619 25.2178 3.52793 23.1824 2.18152 20.3535C0.83778 17.5352 0.104586 14.5551 0.00593851 10.1419Z"
      fill="#282829"
      fillOpacity="0.6"
    />
    <path
      d="M20.0476 8.62402H7.95161C7.61561 8.62402 7.34961 8.89002 7.34961 9.22602V18.774C7.34961 19.11 7.61561 19.376 7.95161 19.376H20.0476C20.3836 19.376 20.6496 19.11 20.6496 18.774V9.22602C20.6496 8.89002 20.3836 8.62402 20.0476 8.62402ZM18.4096 11.844C18.4096 12.376 17.9756 12.81 17.4436 12.81C16.9116 12.81 16.4776 12.376 16.4776 11.844C16.4776 11.312 16.9116 10.878 17.4436 10.878C17.9756 10.878 18.4096 11.312 18.4096 11.844ZM8.55361 18.172V17.122L11.8716 13.314C12.1516 12.992 12.6556 12.992 12.9356 13.314L15.2736 15.988L16.3376 14.826C16.5896 14.546 17.0376 14.546 17.2896 14.826L19.4596 17.206V18.172H8.55361Z"
      fill="#FFFDF3"
    />
  </svg>
);
