import { Modal as MUIModal, Paper, Slide, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import { Dictionary } from 'lodash';
import { observer } from 'mobx-react';

import { BaseIconButton } from 'components/illume/buttons';
import { HtmlRenderer } from 'components/illume/html-renderer';
import { PriceTag } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { spacing, colors } from 'constants/design';
import Footer from 'pages/illume/marketplace/Footer';

const contentStyles = makeStyles<Theme>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      //@ts-ignore
      zIndex: '1540!important',
    },
    paper: {
      backgroundColor: theme.palette.background.default,
      border: 'none',
      outline: 0,
      boxShadow: theme.shadows[5],
      borderRadius: 12,
      marginLeft: spacing[8],
      marginRight: spacing[8],
      width: '100%',
      maxWidth: '350px',
      overflowY: 'scroll',
      height: 650,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        maxWidth: 810,
        height: 450,
        overflowY: 'hidden',
      },
    },
    modalOverlay: {
      backgroundColor: 'rgb(0 0 0 / 60%)',
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1100,
      inset: 0,
    },
    footer: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 810,
      },
      borderRadius: '0px 0px 12px 12px',
    },
    gridContent: {
      display: 'grid',
      flex: 1,
      gridTemplateColumns: 'auto',
      padding: '44px 33px 0px 33px',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: '350px auto',
      },
    },
    box1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    box2: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      rowGap: 12,
      order: 1,
      margin: '24px 0',
      [theme.breakpoints.up('md')]: {
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    desc: {
      maxHeight: 100,
      overflowY: 'scroll',
    },
    priceBreakdown: {
      order: 5,
      [theme.breakpoints.up('md')]: {
        order: 4,
        rowGap: 5,
      },
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
    breakdown: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 5,
    },
    closeIcon: {
      position: 'absolute',
      top: 10,
      right: 15,
      zIndex: 10,
    },
  }),
);

export interface IAnyGiftProduct {
  image: string;
  title: string;
  description: string;
  formattedPriceBreakdown: Dictionary<string>;
}

export interface AnyGiftDetailsModalProps {
  showModal: boolean;
  hideModal: () => any;
  product: IAnyGiftProduct;
}

const AnyGiftDetailsModal: React.FC<AnyGiftDetailsModalProps> = ({
  hideModal,
  showModal,
  product: merchantProduct,
}) => {
  const classes = contentStyles();
  const formattedPricesEntries = Object.entries(merchantProduct.formattedPriceBreakdown);
  const image = merchantProduct.image;
  const title = merchantProduct.title;
  const desc = merchantProduct.description.repeat(3);

  return (
    <MUIModal disableRestoreFocus onClose={hideModal} open={showModal} className={classes.modal}>
      <Slide direction="up" in={showModal}>
        <Paper className={classes.paper}>
          <div className={classes.closeIcon}>
            <BaseIconButton
              role="close"
              icon={Close}
              iconProps={{ color: colors.gray100 }}
              onClick={hideModal}
              size={16}
              classes={undefined}
              color={undefined}
              loading={undefined}
            />
          </div>
          <div className={classes.gridContent}>
            <div className={classes.box1}>
              <div
                style={{
                  background: `${colors.backgroundGray} url(${image}) center/cover no-repeat`,
                  height: 250,
                  width: 250,
                  borderRadius: 12,
                }}
              ></div>
            </div>
            <div className={classes.box2}>
              <Text fontWeight={700} lineHeight={'36px'} fontSize={{ desktop: 28, mobile: 24 }}>
                {title}
              </Text>
              <div className={classNames(classes.desc, 'customIllumeScrollbar-gray')}>
                <Text
                  style={{ marginTop: 4 }}
                  lineHeight={'18px'}
                  italic
                  fontWeight={400}
                  fontSize={14}
                >
                  <HtmlRenderer html={desc} />
                </Text>
              </div>

              <div className={classes.priceBreakdown}>
                <div>
                  <Text
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={'17px'}
                    prefix={<PriceTag size={15} />}
                  >
                    PRICE BREAKDOWN
                  </Text>
                </div>
                <div className={classes.breakdown}>
                  {formattedPricesEntries.map(([name, price], index, arr) => {
                    const last = arr.length - 1 === index;
                    const isFree = price === '$0.00';
                    return (
                      <div
                        style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 18 }}
                        key={index}
                      >
                        <Text fontWeight={last ? 'bold' : 400} fontSize={14} lineHeight={'25px'}>
                          {name}:
                        </Text>
                        <Text
                          fontWeight={last || isFree ? 'bold' : 400}
                          fontSize={14}
                          color={last ? colors.primary : colors.gray100}
                          lineHeight={'25px'}
                          italic={isFree}
                        >
                          {isFree ? 'FREE' : price}
                        </Text>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Footer className={classes.footer} />
        </Paper>
      </Slide>
    </MUIModal>
  );
};

export default observer(AnyGiftDetailsModal);
