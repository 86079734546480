export const ColorsV2 = {
  realWhite: '#FFFFFF',
  notWhite: '#fffdf3',
  notBlack: `#282829`,
  softGray: '#F5F0E1',
  gray70: `#606060`,
  gray80: `#494949`,
  gray90: '#323232',
  gray100: '#1B1B1B',
  gray60: `#767676`,
  gray40: `#A4A4A4`,
  ellipse401: '#D5AC3A',
  primary: '#FEB81C',
  transparent: 'transparent',
  // Theme Colors Pack/Butternut/Stone
  // #655B4F
  butternutStone: '#655B4F',
} as const;
