import { makeStyles } from '@material-ui/core/styles';

import { rem } from 'constants/design';

export const noteStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    cursor: ({ clickable }) => clickable && 'pointer',
    [theme.breakpoints.up('md')]: {
      height: ({ size }) => size.desktop,
      width: ({ size }) => size.desktop,
      margin: 'auto',
    },
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  message: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.up('md')]: {
      '-webkit-line-clamp': 4,
    },
  },
  overlay: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
    borderRadius: rem[750],
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
}));
