import { useState } from 'react';

import { fullwidthSwitchStyles } from './FullwidthSwitch.styles';

export const FullwidthSwitch = (props: any) => {
  const classes = fullwidthSwitchStyles(props);
  const [state, setState] = useState<'on' | 'off'>('on');
  return (
    <label className={classes.root}>
      <input
        className={classes.input}
        type="checkbox"
        name="color_mode"
        id="color_mode"
        value="1"
      />
      <label className="inner-label"></label>
    </label>
  );
};
