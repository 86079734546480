import { makeStyles } from '@material-ui/core/styles';

import { colors, spacing } from 'constants/design';

export const cardDetailsStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 0,
  },
  arrow: {
    marginTop: spacing[8],
    paddingTop: spacing[7],
    paddingBottom: spacing[3],
    '& button': {
      color: colors.gray100,
      padding: '10px 14px',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  box: {
    maxWidth: 800,
  },
  textInput: {
    marginBottom: theme.spacing(3.5),
  },
  cardSent: {
    paddingRight: theme.spacing(6.5),
    paddingLeft: theme.spacing(6.5),
    marginTop: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(24),
    },
    maxWidth: 500,
    margin: '0 auto',
  },
  textContainer: {
    marginBottom: theme.spacing(4),
  },
  moneyPot: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  name: {
    maxWidth: 300,
  },
  textOverflow: {
    overflow: 'auto',
    whiteSpace: 'pre-line',
  },
}));
