export const flex = (): Pick<React.CSSProperties, 'display' | 'flexWrap'> => ({
  display: 'flex',
  flexWrap: 'wrap',
});

// return type for this function
// use Pick
type FlexReturnType = Pick<
  React.CSSProperties,
  'display' | 'flexWrap' | 'alignItems' | 'justifyContent'
>;
export const flexCenter = (): FlexReturnType => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
});
