import { ServiceErrorDTO } from '@illume/shared';

interface CustomErrorParams<T = string> {
  statusCode: number;
  message: string;
  errorCode?: T;
}

export default class CustomError<ErrorCode = string> extends Error {
  static UnknownError = 'UnknownErrorCode' as const;
  statusCode: number;
  errorCode: ErrorCode | typeof CustomError.UnknownError = CustomError.UnknownError;

  constructor(error: CustomErrorParams, ...params: any[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.name = 'CustomError';
    this.statusCode = error.statusCode;
    this.message = error.message;
    this.errorCode = error.errorCode || 'UnknownErrorCode';
  }

  static fromServiceErrorDto(err: ServiceErrorDTO) {
    return new CustomError({
      message: err.message,
      statusCode: err.statusCode,
      errorCode: err.errorCode,
    });
  }
}
