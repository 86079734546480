import { yupResolver } from '@hookform/resolvers/yup';
import { RecipientDTO, SendCardContextDTO } from '@illume/shared';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';

import { Button } from 'components/illume/buttons';
import { PaperPlane } from 'components/illume/icons/PaperPlane';
import { Text } from 'components/illume/text';
import { TextArea } from 'components/illume/text-area';
import { TextInput } from 'components/illume/text-inputs';
import { colors } from 'constants/design';
import { isValid } from 'utils/form';

import {
  SendCardSchema,
  schema,
} from '../../card-details-v2-pending/initiator/constants/constants';

const FIELDS_NAME = 'recipientsContacts';

export const Delivered: React.FunctionComponent<{
  sendCardContext: SendCardContextDTO[];
  batch?: boolean;
  onResendCard: (opts: {
    recipientsContacts: {
      contact: string;
      recipientId: string;
    }[];
    message?: string;
  }) => Promise<any>;
  recipientMessage?: string;
  recipientData: RecipientDTO[];
}> = ({ sendCardContext, recipientMessage, recipientData, onResendCard, batch = false }) => {
  const recipients = sendCardContext || [];

  // all intromessage is the same across recipients
  const introMessage = get(recipients, ['0', 'introMessage'], '');
  const defaultValues: Partial<SendCardSchema> = {
    recipientsContacts: recipients.map((r) => ({
      emailOrPhone: 'recipientEmail' in r ? r.recipientEmail : r.recipientPhone,
    })),
    message: batch ? recipientMessage : introMessage,
  };
  const {
    register,
    handleSubmit,
    errors,
    trigger,
    formState: { isSubmitted, isSubmitting },
  } = useForm<SendCardSchema>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const disableSubmitButton = !isValid(errors) && isSubmitted;

  const onSubmit2 = async (props: SendCardSchema) => {
    const { message, recipientsContacts: r } = props;
    const recipientsContacts = r.map(({ emailOrPhone }, i) => ({
      contact: emailOrPhone,
      recipientId: recipients[i].recipientId,
    }));
    return onResendCard({ message, recipientsContacts });
  };

  return (
    <Grid container direction="column" item spacing={4}>
      {/* Input group */}
      {recipients && recipients.length > 0 && (
        <Grid item container direction="column" spacing={2}>
          <Grid
            item
            container
            spacing={2}
            style={recipientData.length > 3 ? { maxHeight: 400, overflowY: 'scroll' } : undefined}
          >
            {recipients.map((r, i, people) => {
              const error = get(errors, [FIELDS_NAME, i, 'emailOrPhone']);
              return (
                <Grid item xs={12} md={people.length === 1 ? 12 : 6} key={r.recipientId}>
                  <TextInput
                    ref={register()}
                    error={
                      error?.type === 'required'
                        ? {
                            ...error,
                            message: `please provide ${r.name}'s email or phone number`,
                          }
                        : error
                    }
                    name={`${FIELDS_NAME}[${i}].emailOrPhone`}
                    radius="small"
                    shadow
                    label={`${r.name}'s Email or Phone Number`}
                    placeholder={`e.g ${r.name}@illumenotes.com`.toLowerCase()}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item>
            <TextArea
              className="fs-mask"
              name="message"
              ref={register}
              defaultValue={introMessage}
              placeholder="type your message here"
              label={'Intro Message'}
              error={errors?.message}
              errorMessage={errors?.message?.message}
              labelSuffix="*optional*"
            />
          </Grid>
        </Grid>
      )}

      {/** Buttons */}
      <Grid container direction="column" item spacing={2}>
        <Grid item onClick={() => trigger()}>
          <Button
            fullWidth
            loading={isSubmitting}
            disabled={disableSubmitButton}
            onClick={handleSubmit(onSubmit2)}
          >
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
              <PaperPlane color={colors.contrastText} />
              <Text
                color={colors.contrastText}
                fontSize={{ desktop: 16, mobile: 14 }}
                fontWeight={500}
              >
                resend card
              </Text>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
