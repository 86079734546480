const LeftArrow = ({ arrowColor = 'none', blobColor = 'none', size = 60 }) => (
  <svg
    width={size}
    height={(59 / 60) * size}
    viewBox="0 0 60 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#left-arrow)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2851 6.12862C3.74344 10.5187 -0.255795 16.302 0.0127254 21.7327C0.224114 31.1895 1.79524 37.5755 4.67469 43.6147C7.55986 49.6766 12.1418 54.0383 18.5235 56.3982C23.7511 58.3316 29.1329 58.9117 34.6576 58.1781C42.5589 57.1318 49.3405 53.9586 54.4424 47.7091C59.31 41.7438 61.1725 35.0393 59.2643 27.442C57.8874 21.9544 55.4479 16.9389 52.5341 12.128C50.883 9.39842 48.9691 6.89063 46.4953 4.84914C42.6903 1.70444 38.1597 0.396526 33.3492 0.0894488C25.008 -0.450778 17.2666 1.44286 10.2851 6.12862ZM14.8439 29.8351C14.3557 29.3469 14.3557 28.5554 14.8439 28.0673L22.7989 20.1123C23.287 19.6242 24.0785 19.6242 24.5666 20.1123C25.0548 20.6005 25.0548 21.3919 24.5666 21.8801L18.7456 27.7012H44.854V30.2012H18.7456L24.5666 36.0222C25.0548 36.5104 25.0548 37.3019 24.5666 37.79C24.0785 38.2782 23.287 38.2782 22.7989 37.79L14.8439 29.8351Z"
        fill={blobColor}
      />
      <path
        d="M14.8439 28.0673C14.3557 28.5554 14.3557 29.3469 14.8439 29.8351L22.7989 37.79C23.287 38.2782 24.0785 38.2782 24.5666 37.79C25.0548 37.3019 25.0548 36.5104 24.5666 36.0222L17.4956 28.9512L24.5666 21.8801C25.0548 21.3919 25.0548 20.6005 24.5666 20.1123C24.0785 19.6242 23.287 19.6242 22.7989 20.1123L14.8439 28.0673ZM44.854 27.7012H15.7278V30.2012H44.854V27.7012Z"
        fill={arrowColor}
      />
    </g>
    <defs>
      <clipPath id="left-arrow">
        <rect width="60" height="59" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LeftArrow;
