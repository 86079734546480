import { makeStyles } from '@material-ui/core/styles';

import { colors, rem } from 'constants/design';
const NOTE_MAX_WIDTH = 800;

export const noteDisplayStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: ({ background }) => background,
    maxWidth: NOTE_MAX_WIDTH,
    margin: '0 auto',
    // there must be better way to inject styles to reusable component
    paddingLeft: ({ paddingLeft }) => paddingLeft ?? theme.spacing(6.5),
    paddingRight: ({ paddingRight }) => paddingRight ?? theme.spacing(6.5),
    paddingTop: ({ paddingTop }) => paddingTop ?? undefined,
    paddingBottom: ({ paddingBottom }) => paddingBottom ?? theme.spacing(6.5),
    marginTop: ({ marginTop }) => marginTop ?? theme.spacing(10),
    margniBottom: ({ marginBottom }) => marginBottom ?? undefined,
  },
  textBox: (props) => {
    return {
      fontSize: rem[1750],
      color: colors.contrastText,
      // make ellipsis
      maxHeight: props.textBoxMaxHeight,
    };
  },
  senderBox: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  buttonBox: {
    flexGrow: 1,
    alignContent: 'flex-end',
    bottom: theme.spacing(8),
  },
  pageNumberBox: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    bottom: theme.spacing(3),
    width: '100%',
    left: theme.spacing(0),
  },
  hiddenWords: {
    background: colors.contrastText,
    height: 48,
    borderRadius: 8,
  },
}));
