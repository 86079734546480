import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, BaseIconButton } from 'components/illume/buttons';
import { IllumeLayout } from 'components/layouts';
import { colors } from 'constants/design';
import { useAuth } from 'contexts/authentication';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { noop } from 'utils';
import { isValid } from 'utils/form';

import ThinPlus from '../icons/ThinPlus';
import { Text } from '../text';
import { DatePicker, TextInput } from '../text-inputs';
import useStyles from './SaveTheDate.styles';

function SaveTheDate() {
  const classes = useStyles();
  const { authenticated } = useAuth();
  const { verificationStore } = useStores();
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();
  const { occasionService } = useServices();

  const FIELDS_NAME = 'occasionForm';

  const occasionSchema = yup.object().shape({
    occasionName: yup.string().required('this is required'),
    deadline: yup
      .date()
      .typeError("don't forget to remind the event! this is required.")
      .required("don't forget to remind the event! this is required."),
  });

  const specialCaseSchema = occasionSchema.shape({
    email: yup.string().required('email is required').email('must be a valid email'),
  });

  const applicationSchema = yup.object().shape({
    [FIELDS_NAME]: yup.array().of(
      yup.lazy((item, options) => {
        const isFirstIndex = options.index === 0;
        return isFirstIndex && !authenticated ? specialCaseSchema : occasionSchema;
      }),
    ),
  });

  const {
    handleSubmit,
    register,
    control,
    errors,
    formState: { isSubmitted, isSubmitting },
  } = useForm({
    resolver: yupResolver(applicationSchema),
    defaultValues: {
      [FIELDS_NAME]: [
        {
          occasionName: null,
          date: null,
        },
      ],
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: FIELDS_NAME, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const handleErr = (errMessage) => {
    enqueueErrorSnackbar(errMessage);
  };

  const onSubmit = async ({ occasionForm }) => {
    const occasions = occasionForm?.map((occasion) => {
      const deadline = new IllumeDate(new Date(occasion?.deadline));
      return {
        name: occasion.occasionName,
        deadline: deadline.toAPIString(),
        deadlineTz: deadline.getTz(),
      };
    });

    async function addOccasion() {
      try {
        await occasionService.addOccasion(occasions);
        remove();
        enqueueSuccessSnackbar('successfully added occasions!');
        // TODO: redirect on success for unauthenticated user
      } catch (error) {
        handleErr(error.message);
      }
    }

    async function onVerifySuccess() {
      await addOccasion();
      // TODO: redirect on success for unauthenticated user

      // history.push({
      //   pathname: BASIC_INFO_URL,
      //   search: qs.stringify({ isNewUser: true }),
      // });
    }

    if (authenticated) {
      return addOccasion();
    } else {
      return verificationStore
        .beginVerification(occasionForm[0]?.email, 'unknown')
        .then(onVerifySuccess)
        .catch(handleErr);
    }
  };

  function handleAddDate() {
    append({});
    setClosableIndex(fields.length);
  }

  const [closableIndex, setClosableIndex] = useState(0); // set first column closable

  return (
    <IllumeLayout contentProps={{ justifyContent: 'center' }}>
      <div className={classes.root}>
        <Grid direction="column" spacing={1} container className={classes.header}>
          <Grid item>
            <Text
              align="center"
              fontSize={
                // I feel like this is easier than using rem from constant/design
                { desktop: 36, mobile: 22 }
              }
              fontWeight={800}
            >
              NEVER FORGET A{' '}
              <Text.Shuffle
                interval={2000}
                color={colors.primary}
                texts={['THANK YOU', 'WEDDING', 'BABY SHOWER', 'GRADUATION']}
              />{' '}
              AGAIN
            </Text>
          </Grid>
          <Grid item>
            <Text align="center" fontWeight={300}>
              Input the date of a future occasion and we’ll send you a reminder 3 days before to
              start a group card!
            </Text>
          </Grid>
        </Grid>
        <div className={classes.content}>
          {/*Inputs*/}
          <AnimatePresence initial={false}>
            {fields.map((item, index) => {
              const isFirstIndex = index === 0;
              const closable = index === closableIndex;
              return (
                <motion.div
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 300, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  key={item.id}
                >
                  <OcassionField
                    fieldName={FIELDS_NAME}
                    formObj={{ register, control, errors }}
                    index={index}
                    item={item}
                    showEmailField={!authenticated && isFirstIndex}
                    onClose={() => {
                      remove(index);
                      setClosableIndex((prev) => {
                        if (prev - 1 < 0) {
                          return 0;
                        } else {
                          return prev - 1;
                        }
                      });
                    }}
                    closable={closable}
                    onClick={() => setClosableIndex(index)}
                  />
                </motion.div>
              );
            })}
          </AnimatePresence>
          {/* Buttons */}
          <Grid className={classes.buttonGroup} container direction="column" spacing={2}>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <BaseIconButton
                  onClick={handleAddDate}
                  style={{ background: colors.backgroundGray, padding: 10 }}
                  size={12}
                  icon={ThinPlus}
                />
              </Grid>
              <Grid item>
                <Text onClick={handleAddDate} color={colors.gray40}>
                  add another date
                </Text>
              </Grid>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Button
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  loading={isSubmitting}
                  disabled={!isValid(errors) && isSubmitted}
                >
                  set reminder
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </IllumeLayout>
  );
}

function OcassionField({ index, item, fieldName, formObj, onClose, closable, onClick = noop }) {
  const { register, errors, control } = formObj;
  const FIELDS_NAME = fieldName;
  const classes = useStyles({ closable });
  function getError(name, index) {
    return get(errors, [FIELDS_NAME, index, name], undefined);
  }

  return (
    <Box position="relative">
      {closable && (
        <Box onClick={onClose} position="absolute" top={-10} right={-10} zIndex={1000}>
          <BaseIconButton icon={BlackIcon} />
        </Box>
      )}

      <Grid
        onClick={onClick}
        className={classes.formField}
        container
        item
        direction="column"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item>
          <TextInput
            background={closable ? 'secondary' : 'main'}
            inputId={`occasion-name-${index}`}
            label="Occasion Type"
            defaultValue={item.firstName}
            name={`${FIELDS_NAME}[${index}].occasionName`}
            placeholder="e.g Sohale's Birthday"
            ref={register}
            error={getError('occasionName', index)}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name={`${FIELDS_NAME}[${index}].deadline`}
            defaultValue={item.deadline}
            render={({ onChange, value, name }) => (
              <DatePicker
                placeholder="e.g. February 28th, 2021"
                background={closable ? 'secondary' : 'main'}
                disablePast={true}
                icon={true}
                label="Set a Reminder"
                name={name}
                onChange={onChange}
                value={value}
                error={getError('deadline', index)}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function BlackIcon() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00657462 11.2454C-0.132158 8.43533 1.93407 5.44282 5.31382 3.17121C8.92085 0.746598 12.9205 -0.233252 17.23 0.0462847C19.7154 0.205179 22.0561 0.881953 24.022 2.50915C25.3001 3.56551 26.2889 4.86314 27.142 6.27554C28.6474 8.76489 29.9078 11.3602 30.6191 14.1997C31.605 18.1308 30.6428 21.6 28.1279 24.6867C25.492 27.9205 21.9882 29.5624 17.906 30.1038C15.0516 30.4834 12.2711 30.1833 9.57024 29.1828C6.27313 27.9617 3.90583 25.7048 2.4152 22.5681C0.92752 19.4432 0.115789 16.1388 0.00657462 11.2454Z"
        fill="#282829"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4406 9.46357C10.2371 9.67314 10.1252 9.95496 10.1295 10.247C10.1337 10.5391 10.2539 10.8175 10.4634 11.021L14.3453 14.9029L10.463 18.7851C10.3581 18.8868 10.2745 19.0083 10.217 19.1426C10.1595 19.2769 10.1293 19.4213 10.1282 19.5674C10.1271 19.7135 10.155 19.8583 10.2103 19.9935C10.2657 20.1287 10.3474 20.2515 10.4507 20.3548C10.554 20.458 10.6768 20.5397 10.812 20.5951C10.9471 20.6505 11.092 20.6784 11.2381 20.6773C11.3841 20.6761 11.5285 20.6459 11.6628 20.5884C11.7971 20.531 11.9187 20.4473 12.0203 20.3424L15.9026 16.4602L19.7845 20.3421C19.8861 20.4473 20.0076 20.5313 20.142 20.5891C20.2764 20.6469 20.4209 20.6773 20.5672 20.6786C20.7135 20.6799 20.8586 20.6521 20.994 20.5967C21.1294 20.5413 21.2525 20.4595 21.3559 20.356C21.4594 20.2526 21.5412 20.1295 21.5966 19.9941C21.6519 19.8587 21.6798 19.7136 21.6785 19.5673C21.6772 19.4211 21.6468 19.2765 21.589 19.1421C21.5312 19.0077 21.4472 18.8862 21.342 18.7846L17.4601 14.9027L21.3423 11.0205C21.4472 10.9188 21.5308 10.7972 21.5883 10.6629C21.6458 10.5286 21.676 10.3842 21.6771 10.2382C21.6783 10.0921 21.6504 9.94726 21.595 9.81209C21.5396 9.67691 21.4579 9.55411 21.3546 9.45082C21.2514 9.34753 21.1285 9.26583 20.9934 9.21046C20.8582 9.15509 20.7134 9.12717 20.5673 9.12831C20.4212 9.12946 20.2768 9.15965 20.1425 9.21714C20.0082 9.27462 19.8867 9.35824 19.785 9.46314L15.9027 13.3454L12.0209 9.4635C11.9182 9.3578 11.7954 9.27378 11.6597 9.21641C11.524 9.15903 11.3781 9.12948 11.2308 9.12948C11.0834 9.12949 10.9376 9.15906 10.8019 9.21645C10.6661 9.27383 10.5433 9.35787 10.4406 9.46357Z"
        fill="#FFFDF3"
      />
    </svg>
  );
}

export default observer(SaveTheDate);
