import { RouterStore } from '@superwf/mobx-react-router';
import Fuse from 'fuse.js';
import { To } from 'history';
import { isEmpty, uniqBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  INITIATOR_BASIC_INFO_URL,
  INITIATOR_MARKETPLACE_GIFTS_URL,
  INITIATOR_MARKETPLACE_GIFTS_SEARCH_RESULTS_URL,
} from 'constants/strings';
import MerchantProductStore from 'contexts/merchant-products/merchantProduct.store';
import { ProductLine } from 'domain/entities/product-line/ProductLine';
import { CategoryType, Maybe, MerchantProductDTO } from 'types';
import { IllumeMoneyFactory } from 'utils/currency';
import { slugify } from 'utils/string/url.utils';

import { FormData } from '../../my-cards/edit-batch-detail-modal/formData';

interface EntityMapper<DTO, Domain> {
  toDto(domain: Domain): DTO;
  toDomain(dto: DTO): Domain;
}

export class MerchantProductMapper implements EntityMapper<MerchantProductDTO, MerchantProduct> {
  toDto(p: MerchantProduct): MerchantProductDTO {
    return p._dto;
  }
  toDomain(dto: MerchantProductDTO) {
    return new MerchantProduct(dto);
  }
}

export class MerchantProduct {
  //TODO: probably make it private
  _dto: MerchantProductDTO;
  setData = (data: MerchantProductDTO) => {
    this._dto = data;
  };

  constructor(dto: MerchantProductDTO) {
    this._dto = dto;
  }
  get listingId() {
    return this._dto.listingData.id;
  }

  private toMoney(cents: number | undefined) {
    return cents ? IllumeMoneyFactory({ amount: cents }) : IllumeMoneyFactory({ amount: 0 });
  }

  get shippingFee() {
    return this.toMoney(this._dto.listingData.shippingFee);
  }

  get handlingFee() {
    return this.toMoney(this._dto.listingData.handlingFee);
  }

  get isDigitalGiftcard() {
    const digitalCategoryTypes: CategoryType[] = [
      'Digital Giftcard',
      'Humanitarian',
      'Media Junkie',
    ];
    return digitalCategoryTypes.includes(this._dto.listingData.category as CategoryType);
  }

  get totalPrice() {
    return this.shippingFee.add(this.productPrice).add(this.handlingFee);
  }

  get productPrice() {
    return this.toMoney(this._dto.listingData.price);
  }

  get formattedPriceBreakdown() {
    const product = this;
    const formattedPrice = {
      'Product Total': product.productPrice.toFormat(),
      Handling: product.handlingFee.toFormat(),
      Shipping: product.shippingFee.toFormat(),
      Total: product.totalPrice.toFormat(),
    };

    return formattedPrice;
    // filter 0$ value
    // return omitBy(formattedPrice, (el) => el.startsWith('$0.00'));
  }

  /**
   * @deprecated - use {@link MerchantProduct#productPrice}
   */
  get price() {
    return this._dto.listingData.price;
  }

  get title() {
    return this._dto.productData.title;
  }

  get defaultImage() {
    return this._dto.productData.defaultImage;
  }

  getVariantImages = (variantId: number | string) => {
    return this._dto?.productData.images.filter((i) => i.variant_ids.includes(Number(variantId)));
  };

  get allImages() {
    return this._dto.productData.images;
  }

  get imgSrcs() {
    return this._dto.productData.images.map((i) => i.src);
  }
  get highlightTags() {
    return uniqBy(this._dto.listingData.highlights, (h) => h.highlight);
  }

  get description() {
    return this._dto.productData.description;
  }
  get opinion() {
    return this._dto.listingData.opinion;
  }
  get vendor() {
    return this._dto.productData.vendor;
  }

  get label() {
    return this._dto.listingData.tag;
  }

  get formattedPriceRange() {
    const lowRange = this.productPrice.divide(10).toRoundedUnit(0);
    const highRange = this.productPrice.divide(2).toRoundedUnit(0);
    const formattedPrice = `$${lowRange}-${highRange} / each`;
    return formattedPrice;
  }
}

export default class MarketplaceStore {
  form!: FormData<{ selectedVariantId: string; merchantProductDto?: MerchantProductDTO }>;
  searchTerm: string = '';
  productToShowInDetails: Maybe<MerchantProduct>;
  productLineToShowInDetails: Maybe<ProductLine>;
  setProductToShowInDetails = (mp: Maybe<MerchantProduct>) => {
    this.productToShowInDetails = mp;
  };
  getProductDetailsUrl() {
    if (!this.productToShowInDetails) {
      throw new Error('no product selected');
    }
    return `${INITIATOR_MARKETPLACE_GIFTS_URL}/${slugify(this.productToShowInDetails.title)}/${
      this.productToShowInDetails.listingId
    }`;
  }

  setSearchTerm(term: string) {
    this.searchTerm = term;
  }
  search(term: string) {
    return new Fuse(this._merchantProductStore.merchantProducts || [], {
      keys: ['title', 'description', 'highlightTags.highlight'].map((k, i, arr) => ({
        name: k,
        weight: arr.length - i,
      })),
    }).search(term);
  }

  get selectedVariantId() {
    return this.form.data?.selectedVariantId;
  }

  get merchantProduct() {
    if (!this.form.data?.merchantProductDto || isEmpty(this.form.data.merchantProductDto)) {
      return undefined;
    }
    return new MerchantProductMapper().toDomain(this.form.data.merchantProductDto);
  }

  private get nextRoutes(): Record<string, To> | null {
    return {
      [INITIATOR_MARKETPLACE_GIFTS_URL]: {
        // pathname: INITIATOR_MARKETPLACE_GIFTS_URL + `/${this.merchantProduct._dto?.listingData.id}`,
        // new req: https://www.notion.so/illumenotes/Add-gift-vs-view-details-vs-add-to-card-faa0b8cb41434ecc9ba588f523d8e94f
        pathname: INITIATOR_BASIC_INFO_URL,
      },
      [INITIATOR_MARKETPLACE_GIFTS_SEARCH_RESULTS_URL]: {
        pathname: INITIATOR_BASIC_INFO_URL,
      },
    };
  }

  get nextRouteConfig() {
    const currentRoute = this._routerStore.location.pathname;
    if (this.nextRoutes) return this.nextRoutes[currentRoute];
  }

  get imagesSource() {
    return this.merchantProduct?._dto?.productData.images.map((e) => e.src) || [];
  }

  get productMatches() {
    return this.search(this.searchTerm).map((r) => r.item);
  }

  constructor(
    private _routerStore: RouterStore,
    private _merchantProductStore: MerchantProductStore,
  ) {
    makeAutoObservable(this);
    this.initForm();
  }

  initForm() {
    this.form = new FormData(() => Promise.resolve(), 'select_gift', {
      merchantProductDto: undefined,
      selectedVariantId: '',
    });
  }

  pushToNext = (pathname?: string) => {
    let config = pathname && this.nextRoutes ? this.nextRoutes[pathname] : this.nextRouteConfig;

    if (config) {
      return this._routerStore.history.push(config, {
        from: this._routerStore.location.pathname,
      });
    } else console.error('next path config not configured');
  };
}
