import { Fragment, ReactNode } from 'react';

import { Paper } from '@material-ui/core';
import { CreateCSSProperties, CSSProperties } from '@material-ui/core/styles/withStyles';
import { NavigateNext } from '@material-ui/icons';
import { makeStyles, createStyles, PropsFunc } from '@material-ui/styles';
import { To } from 'history';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';

import { IllumeText } from 'components/illume/icons';
import Text, { TextProps } from 'components/illume/text/Text';
import { colors } from 'constants/design';
import { getProdMarketingSiteUrl } from 'constants/strings';
import { Theme } from 'infra/mui-stylesheet';

const useStyles = makeStyles<Theme>((theme) => {
  const listItem: CSSProperties | CreateCSSProperties<{}> | PropsFunc<{}, CreateCSSProperties<{}>> =
    {
      color: colors.gray50,
      fontSize: 11,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      // hack to center the text with the chevron
      marginTop: 4,

      [theme.breakpoints.up('md')]: {
        fontSize: 15,
      },
    };

  const listItemActive:
    | CSSProperties
    | CreateCSSProperties<{}>
    | PropsFunc<{}, CreateCSSProperties<{}>> = {
    ...listItem,
  };

  return createStyles({
    list: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 22,
      colors: colors.gray60,
      fontWeight: 500,
      gap: 4,
      [theme.breakpoints.up('md')]: {
        gap: 10,
      },
    },
    listItem,
    listItemActive,
    logo: {
      display: 'none',
      padding: 30,
      position: 'absolute',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    paper: {
      width: '100%',
      borderRadius: 0,
      minHeight: 60,
      [theme.breakpoints.up('md')]: {
        minHeight: 85,
      },
      boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      alignItems: 'center',
    },
    nextLogo: {
      color: colors.gray50,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 4,
    },
    iconContainer: {
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 12,
      height: 12,
      [theme.breakpoints.up('md')]: {
        width: 15,
        height: 15,
      },
    },
  });
});

export type BreadcrumbsNav = {
  label: string;
  darken: boolean;
  endIcon: ReactNode;
  startIcon: ReactNode;
  linkTo: To;
  textProps: TextProps;
};

export interface BreadCrumbsAppBarProps {
  navs: BreadcrumbsNav[];
}

const BreadCrumbsAppBar: React.FC<BreadCrumbsAppBarProps> = (props) => {
  const classes = useStyles();
  const { navs } = props;
  return (
    <Paper square className={classes.paper}>
      <a className={classes.logo} href={getProdMarketingSiteUrl('')}>
        <IllumeText onClick={noop} backgroundColor={colors.primary} />
      </a>
      <ul className={classes.list}>
        {navs.map((nav, index, arr) => {
          const { label, endIcon, startIcon, linkTo, textProps } = nav || {};
          const last = arr.length - 1 === index;
          return (
            <Fragment key={index}>
              <li className={classes.listItem}>
                <div className={classes.content}>
                  {startIcon && <div className={classes.iconContainer}>{startIcon}</div>}

                  {linkTo ? (
                    <Link to={linkTo}>
                      <Text fontSize={{ mobile: 11, desktop: 16 }} {...textProps}>
                        {label}
                      </Text>
                    </Link>
                  ) : (
                    <Text fontSize={{ mobile: 11, desktop: 16 }} {...textProps}>
                      {label}
                    </Text>
                  )}

                  {endIcon && (
                    <div className={classes.iconContainer} style={{ background: colors.primary }}>
                      {endIcon}
                    </div>
                  )}
                </div>
              </li>
              {!last && <div>{<NavigateNext className={classes.nextLogo} fontSize="small" />}</div>}
            </Fragment>
          );
        })}
      </ul>
    </Paper>
  );
};

export default BreadCrumbsAppBar;
