import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';

import { DESKTOP_MAX_WIDTH } from 'constants/design';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(7),
    maxWidth: DESKTOP_MAX_WIDTH,
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardWrapper: {
    marginTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 9.375rem)',
    gridTemplateRows: 'repeat(auto, 9.375rem)',
    gridGap: '25px',
    justifyContent: 'center',
    width: '23.75rem',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, 14.06rem)',
      gridTemplateRows: 'repeat(auto, 14.06rem)',
      width: '61.75rem',
    },
  },
  cardList: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
  },
  greenArrow: {
    position: 'absolute',
    top: -15,
    right: -15,
  },
}));

export const cardGridStyles = {
  y: 200,
  opacity: 0,
};

export const newCardMotionStyles = {
  position: 'relative',
  y: 200,
  opacity: 0,
};

export const cardVariants = (delay) => ({
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'backOut',
      delay: delay + custom * 0.14,
      duration: 0.6,
    },
  }),
  out: (custom) => ({
    y: 50,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'backIn',
      delay: custom * 0.04,
      duration: 0.45,
    },
  }),
});

export const gridOpts = {
  xs: 6,
  sm: 3,
  md: 3,
  component: motion.div,
  item: true,
  animate: 'visible',
  exit: 'out',
};

export const useYouGotGiftStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minWidth: 150,
      minHeight: 150,
      [theme.breakpoints.up('md')]: {
        minWidth: 225,
        minHeight: 225,
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
});
