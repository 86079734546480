import { useCallback } from 'react';

import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

export const useIllumeHistory = () => {
  const history = useHistory();
  const location = useLocation();
  const pushWithSearch = useCallback(
    (param) => {
      const currentSearch = qs.parse(location.search);
      if (typeof param === 'string') {
        history.push({
          pathname: param,
          search: qs.stringify(currentSearch),
        });
      } else {
        history.push({
          ...param,
          search: qs.stringify({ ...currentSearch, ...param.search }),
        });
      }
    },
    [history, location.search],
  );

  return { pushWithSearch, ...history };
};
