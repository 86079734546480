import { createStyles, makeStyles } from '@material-ui/core/styles';

const desktop = {
  small: 225,
  medium: 255,
  large: 300,
  full: '100%',
};

const mobile = {
  small: 155,
  large: 255,
  full: '100%',
};

export const newCardStyles = makeStyles((theme) => {
  const getDimension = (props) => {
    if (props.fixedSize) {
      return { minHeight: props.size, minWidth: props.size };
    } else {
      return {
        height: ({ size }) => size && mobile[size],
        width: ({ size }) => size && mobile[size],
        [theme.breakpoints.up('md')]: {
          height: 170,
          minHeight: ({ size }) => (size ? desktop[size] : 150),
          width: ({ size }) => size && desktop[size],
        },
      };
    }
  };
  return createStyles({
    root: (props) => ({
      cursor: props.clickable && 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...getDimension(props),
    }),
    container: {
      padding: theme.spacing(1.3),
      display: 'grid',
      justifyItems: 'center',
    },
  });
});
