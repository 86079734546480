import React, { useEffect, useState } from 'react';

import { reaction } from 'mobx';
import { Observer, observer } from 'mobx-react';
import { Switch, Route, useHistory, generatePath } from 'react-router-dom';
import { P, match as tsPatternMatch } from 'ts-pattern';

import { IllumeRoute } from 'components/illume-route';
import { Loading } from 'components/Loading';
import {
  SLACK_TEAMS_CARDS_DETAILS_ADD_NOTE_URL,
  SLACK_TEAMS_CARDS_DETAILS_URL,
  SLACK_TEAMS_CARDS_URL,
} from 'constants/strings';
import { useHttpClient } from 'contexts/httpClient';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { IllumeAPISlackCardService } from 'infra/card-service/slackCardService';
import { MyCardsV2Store } from 'views/pages-v2/my-cards/MyCardsV2.store';
import { TeamsCards } from 'views/pages-v2/teams-cards/TeamsCards';

import { CardDetailsStore } from '../card-details-v2/CardDetaillsV2PendingStore';
import InitiatorContributorCardDetailsHandler from '../card-details-v2/InitiatorContributorCardDetailsHandler';
import { getRandomLoadingLabel } from '../marketplace/pages/getRandomLoadingLabel';
import { MyCardsAddNoteRoutes } from '../my-cards-routes/MyCardsAddNoteRoutes';

const CardCRUDModule = observer(
  ({
    myCardsV2Store,
    cardCode,
    workspaceUrl,
  }: {
    myCardsV2Store: MyCardsV2Store;
    match: any;
    cardCode: string;
    workspaceUrl: string;
  }) => {
    const { authenticationStore, stripeStore, userProfileStore, routerStore, cache } = useStores();
    const history = useHistory();
    const email = new URLSearchParams(location.search).get('email');
    const { enqueueErrorSnackbar } = useIllumeSnackbar();
    const { noteService } = useServices();

    const [store] = useState(
      () =>
        new CardDetailsStore(
          cardCode,
          email,
          authenticationStore,
          enqueueErrorSnackbar,
          history.push,
          routerStore,
          userProfileStore,
          stripeStore,
          cache,
          myCardsV2Store,
          noteService,
          generatePath(SLACK_TEAMS_CARDS_URL, {
            workspaceUrl: workspaceUrl,
          }),
        ),
    );

    // return <InitiatorContributorCardDetailsHandler cardDetailsStore={store} />;

    return (
      <Observer>
        {() => {
          return tsPatternMatch(store.state)
            .with({ data: P.not(P.nullish) }, (state) => {
              return (
                <Switch>
                  <IllumeRoute
                    exact
                    isPrivate
                    component={() => {
                      return (
                        <Observer>
                          {() => {
                            return (
                              <InitiatorContributorCardDetailsHandler
                                disableContactEditing={true}
                                disableGifting={true}
                                addNotePath={generatePath(SLACK_TEAMS_CARDS_DETAILS_ADD_NOTE_URL, {
                                  cardCode: cardCode,
                                  workspaceUrl: workspaceUrl,
                                })}
                                cardDetailsStore={store}
                              />
                            );
                          }}
                        </Observer>
                      );
                    }}
                    path={SLACK_TEAMS_CARDS_DETAILS_URL}
                  />

                  <Route
                    render={({ match }) => (
                      <Observer>
                        {() => {
                          return (
                            <MyCardsAddNoteRoutes
                              onNoteCreated={store.refreshCard}
                              redirectUrl={generatePath(SLACK_TEAMS_CARDS_DETAILS_URL, {
                                cardCode: cardCode,
                                workspaceUrl: workspaceUrl,
                              })}
                              match={match}
                              card={state.data}
                            />
                          );
                        }}
                      </Observer>
                    )}
                    path={SLACK_TEAMS_CARDS_DETAILS_ADD_NOTE_URL}
                  />
                </Switch>
              );
            })
            .with({ type: 'error' }, () => {
              return <div>error while loading card</div>;
            })
            .otherwise(() => {
              return <Loading text={getRandomLoadingLabel()} />;
            });
        }}
      </Observer>
    );
  },
);

const SlackCardsRoutesModule: React.FC<{
  match: any;
}> = (props) => {
  const client = useHttpClient();
  const workspaceUrl = props.match.params.workspaceUrl as string; // teamillume.slack.com
  const userEmail = new URLSearchParams(location.search).get('email');
  const [service] = useState(() => new IllumeAPISlackCardService(client, workspaceUrl));
  const { cache, userProfileStore, authenticationStore } = useStores();
  const [myCardsV2Store] = useState(
    () => new MyCardsV2Store(service, cache, `slack-cards-${workspaceUrl}`),
  );
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const basePath = props.match.path as string; // --> /my-cards

  useEffect(() => {
    const dispose = reaction(
      () => userProfileStore.email,
      async (email) => {
        if (email && userEmail) {
          if (email !== userEmail) {
            enqueueErrorSnackbar(
              'please login with illume account associated with the slack account and workspace',
            );
            authenticationStore.logout();
          }
        }
      },
    );
    return () => {
      dispose();
    };
  }, []);

  return (
    <Switch>
      <IllumeRoute
        exact
        path={basePath}
        isPrivate
        component={() => {
          return (
            <Observer>
              {() => {
                return <TeamsCards cardStore={myCardsV2Store} workspaceUrl={workspaceUrl} />;
              }}
            </Observer>
          );
        }}
      />

      <Route
        path={SLACK_TEAMS_CARDS_DETAILS_URL}
        render={({ match }) => {
          return (
            <Observer>
              {() => {
                return (
                  <CardCRUDModule
                    cardCode={match.params.cardCode}
                    workspaceUrl={workspaceUrl}
                    myCardsV2Store={myCardsV2Store}
                    match={match}
                  />
                );
              }}
            </Observer>
          );
        }}
      />
    </Switch>
  );
};

export const SlackCardsRoutes = observer(SlackCardsRoutesModule);
