import { Maybe } from '@illume/shared';
import Cookie from 'js-cookie';
import { v4 as uuid } from 'uuid';

const DEFAULT_COOKIE_EXPIRATION = 365;
const ILLUME_AUTH = 'illumeAuth';
const ILLUME_DEVICE_ID = 'illumeDeviceId';

export function getAuthCookie() {
  return Cookie.getJSON(ILLUME_AUTH) as Maybe<{ token: string }>;
}

export function writeAuthCookie(token: string) {
  const cookiePayload = { token };
  const { protocol } = new URL(window.location.href);

  Cookie.set(ILLUME_AUTH, cookiePayload, {
    // domain: hostname, // removed because we don't need sub-domains!
    expires: DEFAULT_COOKIE_EXPIRATION, // days, we need something so it's not a session cookie
    secure: protocol === 'https:', // check protocol instead of domain to allow for aliased localhost adddresses
    sameSite: 'strict',
  });
}

export function deleteAuthCookie() {
  return Cookie.remove(ILLUME_AUTH);
}

// extracted from analytics.js callback
export function writeDeviceIdCookie() {
  const deviceId = Cookie.get(ILLUME_DEVICE_ID);
  if (!deviceId) {
    Cookie.set(ILLUME_DEVICE_ID, uuid(), { expires: DEFAULT_COOKIE_EXPIRATION });
  }
}

export function getTrackValues() {
  // for each cookie if it doesn't exist jscookie will return undefined
  const aaid = Cookie.get('ajs_anonymous_id') || '';
  const anonymousId = aaid ? aaid.replace(/"/g, '') : ''; // remove extra double quotes
  const deviceId = Cookie.get(ILLUME_DEVICE_ID) || uuid();
  const gaId = Cookie.get('_ga') || '';
  return { deviceId, anonymousId, gaId };
}
