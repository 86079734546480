const PlusBlob = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M0.00466597 8.24696C-0.0937915 6.2557 1.3726 4.13517 3.77119 2.52548C6.33108 0.807369 9.16959 0.113035 12.2281 0.311118C13.9919 0.423713 15.6531 0.903283 17.0483 2.05634C17.9553 2.80488 18.6571 3.72441 19.2625 4.72525C20.3309 6.48923 21.2254 8.32828 21.7302 10.3404C22.4299 13.1261 21.747 15.5844 19.9622 17.7716C18.0915 20.0632 15.6049 21.2266 12.7078 21.6103C10.6821 21.8793 8.70872 21.6666 6.79194 20.9577C4.45201 20.0923 2.77195 18.4931 1.71405 16.2704C0.658256 14.056 0.082175 11.7145 0.00466597 8.24696Z"
          fill="#282829"
          fillOpacity="0.4"
        />
        <line
          x1="11.2671"
          y1="7.02832"
          x2="11.2671"
          y2="15.5662"
          stroke="#F5F0E1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="6.75"
          y1="11.0488"
          x2="15.2878"
          y2="11.0488"
          stroke="#F5F0E1"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="21.443" fill="white" transform="translate(0 0.27832)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusBlob;
