import React from 'react';

import { css } from '@emotion/css';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import qs from 'query-string';
import { useAsyncCallback } from 'react-async-hook';
import { Link, Redirect } from 'react-router-dom';

import { Avatar } from 'components/avatar';
import { Footer } from 'components/footer';
import Button from 'components/illume/buttons/Button';
import { Pencil, FourSquares, PlusBlob, TwoPeople, LogoutIcon } from 'components/illume/icons';
import { Surface } from 'components/illume/surface';
import { Text } from 'components/illume/text';
import WhyTooltip from 'components/illume/why-tooltip/WhyTooltip';
import { Loading } from 'components/Loading';
import { colors, rem, spacing } from 'constants/design';
import { EDIT_PROFILE_URL, LOGIN_URL, MY_CARDS_URL } from 'constants/strings';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { useUserSummary } from 'hooks/illume/useUserSummary';
import { mq } from 'infra/emotion/breakpoints';
import { Theme } from 'infra/mui-stylesheet';
import { TABS } from 'pages/illume/my-cards/MyCards';
import { LayoutV2 } from 'views/components-v2/elements/layout/LayoutV2';

import { getRandomLoadingLabel } from '../marketplace/pages/getRandomLoadingLabel';

const MY_PROFILE_MAX_WIDTH = 1000;
const useStyles = makeStyles<Theme, { desktop: boolean }>((theme) =>
  createStyles({
    root: {
      color: colors.darkText, // importing the color directly here, since I can't do <Text color="primary" /> and I can't find the color reference on theme object
    },
    button: {
      paddingRight: spacing[6],
      paddingLeft: spacing[6],
      height: rem[2000], // we should make another small version of the button
      width: '100%',
      marginTop: spacing[4],
    },
    logout: {
      marginTop: ({ desktop }) => (desktop ? theme.spacing(4) : theme.spacing(2)),
    },
    logoutButton: {
      background: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        background: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    footer: {
      flexGrow: 1,
      alignContent: 'flex-end',
      marginTop: ({ desktop }) => (desktop ? theme.spacing(4) : theme.spacing(2)),
    },
    container: {
      maxWidth: MY_PROFILE_MAX_WIDTH,
      margin: '0 auto',
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    tooltip: {
      alignSelf: 'center',
      marginLeft: spacing[1],
    },
    viewMyPlanText: {
      fontSize: 11,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
  }),
);

const Profile = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles({ desktop });
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const handleError = (err: Error) => {
    enqueueErrorSnackbar(err?.message);
  };
  const { userSummaryDto: userSummary, loading, error } = useUserSummary({ onError: handleError });
  const { authenticationStore, userProfileStore } = useStores();
  const { userInfo, userStats } = userSummary || {};
  const { joinDate, name, picture } = userInfo || {};
  const { cardsCreated, livesTouched, notesSent } = userStats || {};
  const { userService } = useServices();
  const shouldShow = !!userProfileStore.userProfileDTO?.subscriptionPlans.standard;

  const asyncGetBillingUrl = useAsyncCallback(userService.getBillingRedirectionUrl, {
    onSuccess: (url) => {
      // open url in new tab
      window.open(url, '_blank');
    },
    onError: (err) => {
      enqueueErrorSnackbar(err?.message);
    },
  });

  const handleLogout = () => {
    return authenticationStore.logout();
  };

  const handleViewMyPlan = () => asyncGetBillingUrl.execute();

  if (loading) {
    return <Loading text={getRandomLoadingLabel()} />;
  }

  if (error) {
    return <Redirect to={LOGIN_URL} />;
  }

  return (
    <LayoutV2
      containerAs={Grid}
      containerProps={{
        className: css`
          padding-top: 50px !important;
          ${mq.desktopSmall} {
            padding-top: 100px !important;
          }
        `,
      }}
    >
      {() => {
        return (
          <Grid className={classes.container} container spacing={3}>
            {/** Title and avatar */}
            <Grid item container spacing={4} justifyContent="center">
              <Grid item xs={12}>
                <Text fontWeight={900} fontSize={{ mobile: rem[1375], desktop: rem[2250] }}>
                  MY PROFILE
                </Text>
              </Grid>

              <Grid item container alignItems="center" justifyContent="center">
                <Grid item>
                  <Avatar imgUrl={picture} size={desktop ? 145 : 225} name={name} />
                </Grid>
                {/* summary and button */}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  xs={12}
                  md={3}
                  spacing={2}
                >
                  <Grid item>
                    <Text
                      align={!desktop ? 'center' : 'left'}
                      fontWeight={700}
                      fontSize={rem[1750]}
                    >
                      {name}
                    </Text>
                    <Text
                      align={!desktop ? 'center' : 'left'}
                      color={colors.gray40}
                      fontSize={rem[875]}
                    >
                      beamer since <i>{new IllumeDate(joinDate).getMonthYear()}</i>
                    </Text>
                    <Link to={EDIT_PROFILE_URL}>
                      <Button rounded className={classes.button}>
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item>
                            <Pencil showPerson size={15} style={{ margin: 0 }} />
                          </Grid>
                          <Grid item>
                            <Text color={colors.contrastText} fontSize={rem[800]}>
                              &nbsp; edit profile
                            </Text>
                          </Grid>
                        </Grid>
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {shouldShow && (
                <Grid item xs={5}>
                  <Button
                    className={classes.viewMyPlanText}
                    loading={asyncGetBillingUrl.loading}
                    disabled={asyncGetBillingUrl.loading}
                    onClick={handleViewMyPlan}
                    customcolor="darkGray"
                    variant="outlined"
                    fullWidth
                  >
                    View My Subscription
                  </Button>
                </Grid>
              )}
            </Grid>

            {/* stats */}
            <Grid item xs={12}>
              {[
                {
                  icon: () => <FourSquares color={colors.gray40} />,
                  text: 'notes sent',
                  number: notesSent,
                  rounded: ['top'],
                  link: {
                    pathname: MY_CARDS_URL,
                    search: qs.stringify({ type: TABS.SENT }),
                  },
                },
                {
                  icon: () => <PlusBlob />,
                  text: 'cards created',
                  number: cardsCreated,
                  link: {
                    pathname: MY_CARDS_URL,
                    search: qs.stringify({ type: TABS.PENDING }),
                  },
                },
                {
                  icon: () => <TwoPeople color={colors.gray40} />,
                  text: 'lives touched',
                  number: livesTouched,
                  rounded: ['bottom'],
                },
              ].map(({ rounded, link, icon: Icon, text, number }, index) => (
                <Surface
                  key={index}
                  width="100%"
                  style={{ marginBottom: 2 }}
                  rounded={rounded as ('bottom' | 'top')[]}
                  pr={4}
                >
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item container xs={10}>
                      <Box mr={2}>
                        <Link
                          to={link ? link : '/#'}
                          style={!link ? { pointerEvents: 'none' } : undefined}
                        >
                          <Icon />
                        </Link>
                      </Box>
                      <Link
                        to={link ? link : '/#'}
                        style={!link ? { pointerEvents: 'none' } : undefined}
                      >
                        <Text color={colors.gray40} fontWeight={500} fontSize={rem[1000]}>
                          {text}
                        </Text>
                      </Link>
                      <Grid item className={classes.tooltip}>
                        {text === 'lives touched' && (
                          <WhyTooltip
                            tooltipText={
                              <React.Fragment>
                                {'lives touched = '}
                                <b>{'notes sent'}</b>
                                {' + # '}
                                <b>{'of contributors from cards created.'}</b>{' '}
                              </React.Fragment>
                            }
                            tooltipBackgroundColor={colors.primaryTooltip}
                            title={false}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Link
                        to={link ? link : '/#'}
                        style={!link ? { pointerEvents: 'none' } : undefined}
                      >
                        <Text align="right" color={colors.primary} fontWeight={700}>
                          {number}
                        </Text>
                      </Link>
                    </Grid>
                  </Grid>
                </Surface>
              ))}
            </Grid>
            <Grid className={classes.logout} container direction="row" justifyContent="center">
              <Button className={classes.logoutButton} onClick={handleLogout}>
                <Box>
                  <LogoutIcon />
                </Box>
                <Text color={colors.gray40} fontWeight={500} fontSize={rem[1000]}>
                  log out
                </Text>
              </Button>
            </Grid>
            <Grid className={classes.footer} container justifyContent="center">
              <Box mt={2}>
                <Footer />
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </LayoutV2>
  );
};

export default observer(Profile);
