import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { IllumeLayout } from 'components/layouts';
import { NoteForm } from 'components/reusable-forms/note-form';
import { NoteFormRawValues } from 'components/reusable-forms/note-form/NoteForm';
import { DEFAULT_PALETTE, PRIMARY_THEME } from 'constants/design';
import { Palette } from 'constants/design/cardTheme';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { ContributorCardDTO, InitiatorCardViewDTO, PaletteDTO } from 'types';
import { spellNames } from 'utils/string';

interface AddNoteProps {
  card: InitiatorCardViewDTO | ContributorCardDTO;
  note: Partial<NoteFormRawValues>;
  onSubmitSuccess: (note: NoteFormRawValues) => any;
  onBack: () => any;
  defaultPalette: PaletteDTO;
}

const AddNoteContainer: React.FC<AddNoteProps> = ({
  onSubmitSuccess,
  onBack,
  card,
  note,
  defaultPalette,
}) => {
  const { recipientData, occasionName, inviteCode } = card;
  const { themepackStore } = useStores();
  const t = themepackStore.getThemepack(card.themepack);
  const paletteDto = defaultPalette || (t ? t.palettes[0] : DEFAULT_PALETTE);
  const bg = new Palette(paletteDto).generateBackground();
  const analytics = useAnalytics();
  const role = card.userContext.role;
  const { suggestionService, noteService } = useServices();

  return (
    <IllumeLayout background={bg} hideHamburger topRightButtons={null}>
      <NoteForm
        suggestionService={suggestionService}
        analytics={analytics}
        role={role}
        onBack={onBack}
        recipientName={spellNames(recipientData) || ''}
        defaultValues={note}
        onSubmitSuccess={onSubmitSuccess}
        // TODO: get correct theme color
        theme={PRIMARY_THEME}
        palette={toJS(paletteDto)}
        occasionName={occasionName}
        draftValidationService={(val) => {
          return noteService.createNoteDraft(inviteCode, {
            ...val,
            photos: val.photos && val.photos.map((p) => p.photoCode),
            videos: val.videos && val.videos.map((v) => v.videoCode),
            gifs: val.gifs && val.gifs.map((gif) => gif.gifCode),
          });
        }}
      />
    </IllumeLayout>
  );
};

export const AddNote = observer(AddNoteContainer);

export default AddNote;
