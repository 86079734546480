import Grid from '@material-ui/core/Grid';

import progressCircleBlack from 'assets/progress-circle-black.svg';
import progressCircleLight from 'assets/progress-circle-light.svg';

import { ProgressCircles as ProgressCirclesSvg } from '../icons';

const ProgressCircle = ({ stepOrder, propStep, color }) => {
  const type = stepOrder > propStep ? 'dark' : color === 'primary' ? 'primary' : 'light';
  return <ProgressCirclesSvg type={type} />;
};

const StepCircle = ({ stepOrder, propStep }) => {
  return stepOrder === propStep ? (
    <img src={progressCircleLight} alt="Progress Circle Pending" />
  ) : (
    <img src={progressCircleBlack} alt="Progress Circle Complete" />
  );
};

export const ProgressCircles = ({ step = 0, color = 'light', steps = 3, type = 'progress' }) => {
  const progressSteps = [...Array(steps).keys()];
  return (
    <Grid container justifyContent="center" spacing={1}>
      {progressSteps.map((stepOrder) => (
        <Grid item key={stepOrder}>
          {type === 'progress' ? (
            <ProgressCircle stepOrder={stepOrder} propStep={step} color={color} />
          ) : (
            <StepCircle stepOrder={stepOrder} propStep={step} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ProgressCircles;
