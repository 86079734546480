import { OccasionType } from 'domain/interfaces/IOccasionService';

import DiamondIcon from './Diamond.icon';
import HandGiftIcon from './HandGift.icon';
import LoveGiftIcon from './LoveGift.icon';
import SmallShop from './SmallShop.icon';

export default class OccasionToIconMapper {
  private occasionToIconMap: Record<OccasionType, any> = {
    [OccasionType.ANNIVERSARY]: DiamondIcon,
    [OccasionType.BABY_SHOWER]: HandGiftIcon,
    [OccasionType.BIRTHDAY]: HandGiftIcon,
    [OccasionType.CONGRATULATIONS]: DiamondIcon,
    [OccasionType.FAREWELL]: LoveGiftIcon,
    [OccasionType.GRADUATION]: SmallShop,
    [OccasionType.JUST_BECAUSE]: DiamondIcon,
    [OccasionType.THANK_YOU]: LoveGiftIcon,
    [OccasionType.THINKING_OF_YOU]: LoveGiftIcon,
    [OccasionType.WEDDING]: LoveGiftIcon,
    [OccasionType.OTHER]: SmallShop,
    [OccasionType.MOTHER_FATHERS_DAY]: SmallShop,
    [OccasionType.BIRTH]: HandGiftIcon,
    [OccasionType.GET_WELL_SOON]: HandGiftIcon,
    [OccasionType.NEW_JOB]: DiamondIcon,
    [OccasionType.CELEBRATE_MILESTONE]: HandGiftIcon,
    [OccasionType.WELCOME_TEAM_MEMBER]: SmallShop,
    [OccasionType.GREAT_COLLEAGUE]: LoveGiftIcon,
    [OccasionType.HARD_WORK]: DiamondIcon,
    [OccasionType.CHRISTMAS]: HandGiftIcon,
    [OccasionType.MOTHERS_DAY]: SmallShop,
    [OccasionType.CELEBRATION_OF_LIFE_DAY]: LoveGiftIcon,
    [OccasionType.VALENTINES]: DiamondIcon,
    [OccasionType.INTERNATIONAL_COWORKING_DAY]: HandGiftIcon,
    [OccasionType.NEW_YEARS]: SmallShop,
    [OccasionType.MEMORIAL_DAY]: HandGiftIcon,
    [OccasionType.FATHERS_DAY]: SmallShop,
    [OccasionType.FATHER_IN_LAW_DAY]: LoveGiftIcon,
    [OccasionType.WORLD_LISTENING_DAY]: SmallShop,
    [OccasionType.PARENTS_DAY]: HandGiftIcon,
  };
  getOcassionIcon(type: OccasionType) {
    return this.occasionToIconMap[type];
  }
}
