import { useState } from 'react';

import { ContributorCardDTO, InitiatorCardViewDTO, NoteDTO } from '@illume/shared';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { noop } from 'lodash';
import { generatePath } from 'react-router';
import { match, P } from 'ts-pattern';

import { BaseIconButton } from 'components/illume/buttons';
import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import CloseCard from 'components/illume/close-card';
import { GroupGiftModal } from 'components/illume/group-gift-modal';
import { GroupGiftModalDTOMapper } from 'components/illume/group-gift-modal/GroupGiftModalDTOMapper';
import { Info } from 'components/illume/icons';
import Arrow from 'components/illume/icons/Arrow';
import { TextBox } from 'components/illume/text-box';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { PreviewModal } from 'components/preview-modal';
import { colors } from 'constants/design';
import { MY_CARDS_URL, SLACK_TEAMS_CARDS_URL } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import Gift from 'domain/entities/gift/gift';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import AnyGiftDetailsModal from 'pages/illume/initiator/add-gift/any-gift/AnyGiftDetailsModal';
import { TABS } from 'pages/illume/my-cards/MyCards';
import { isGift } from 'types/guard';
import { spellNames } from 'utils/string';

import ContributeCardGift, {
  fromExternalGift,
  fromGiftV2Dto,
} from '../card-details-v2-pending/initiator/components/ContributeCardGift';
import { SendCardSchema } from '../card-details-v2-pending/initiator/constants/constants';
import { NoteThubmnail } from '../note-thumbnail';
import { useCardDetailsV2SentStyles } from './CardDetailsV2Sent.styles';
import SentCardDetailsModal from './sent-card-details-modal/SentCardDetailsModal';

export default function CardDetailsV2Sent(props: {
  card: InitiatorCardViewDTO | ContributorCardDTO;
  resendCardService: (payload: SendCardSchema) => Promise<any>;
}) {
  const { card, resendCardService } = props;
  const [showAnyGift, setShowAnyGift] = useState(false);
  const [showGroupGiftModal, setShowGroupGiftModal] = useState(false);
  const history = useIllumeHistory();
  const [showModalCardDetail, setShowModalCardDetail] = useState(false);
  const [displayedNote, setDisplayedNote] = useState<NoteDTO | null>(null);
  const theme = useTheme();
  const moreThanSmallDevice = useMediaQuery(theme.breakpoints.up('sm'));
  const { recipientData, notes, giftV2 } = card;
  const spelledRecipientNames = spellNames(recipientData);
  const desktop = useIsDesktop();
  const classes = useCardDetailsV2SentStyles();
  const externalGift = card.externalGift && ExternalGift.fromDto(card.externalGift);
  const analytics = useAnalytics();
  function handleGoBack() {
    const cardListUrl = card.workspaceUrl
      ? generatePath(SLACK_TEAMS_CARDS_URL, { workspaceUrl: card.workspaceUrl })
      : MY_CARDS_URL;
    history.replace(`${cardListUrl}?type=${TABS.SENT}`);
  }

  return (
    <>
      <IllumeLayout
        contentProps={{
          justifyContent: 'center',
        }}
      >
        <div className={classes.container}>
          <div className="header">
            <div className="title">
              <Text
                maxWidth={moreThanSmallDevice ? 850 : 200}
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: moreThanSmallDevice ? 'break-spaces' : 'nowrap',
                  overflow: 'hidden',
                }}
                align="center"
                fontWeight={900}
                fontSize={{ mobile: 22, desktop: 36 }}
              >
                {`${spelledRecipientNames?.toUpperCase()}'S GROUP CARD`}
              </Text>
              <BaseIconButtonNew
                icon={Info}
                iconProps={{ size: moreThanSmallDevice ? 24 : 12 }}
                onClick={() => setShowModalCardDetail(true)}
              />
            </div>
            <div className="arrow">
              <BaseIconButton onClick={handleGoBack} size={desktop ? 36 : 20} icon={Arrow} />
            </div>
            <div className="status">
              <Text fontSize={14} color={colors.gray40}>
                status: {card.status.toLowerCase()}
              </Text>
            </div>
          </div>
          {externalGift && externalGift.giftMetGoals && (
            <ContributeCardGift
              gift={fromExternalGift(externalGift)}
              onViewGroupGiftDetails={() => setShowAnyGift(true)}
              className="gift-container"
            />
          )}
          {match(giftV2)
            .with({ product: P._ }, (gift) => {
              const g = Gift.fromDto(gift);

              if (g.giftMetGoals) {
                return (
                  <ContributeCardGift
                    gift={fromGiftV2Dto(gift)}
                    onViewGroupGiftDetails={() => setShowGroupGiftModal(true)}
                    className="gift-container"
                  />
                );
              } else {
                return null;
              }
            })
            .with({ errMsg: P._ }, (err) => (
              <CloseCard
                style={{ width: '100%', marginBottom: 24 }}
                text={err.errMsg}
                variant="secondary"
                size="default"
                clickable={false}
                onClick={undefined}
                background={undefined}
                fixedSize={undefined}
                role={undefined}
              />
            ))
            .with(undefined, () => null)
            .exhaustive()}

          <div className="notes-container">
            {notes.map((note, index) => {
              return (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                  key={note.viewCode}
                >
                  <NoteThubmnail
                    cardCode={match(card)
                      .with({ userContext: { role: 'Contributor' } }, (card) => card.inviteCode)
                      .with({ userContext: { role: 'Initiator' } }, (card) => card.viewCode)
                      .exhaustive()}
                    note={NoteThubmnail.noteFromDTO(note)}
                    recipientName={spellNames(card.recipientData)}
                    previewable={!note.redacted}
                    editable={false}
                    onPencilClick={noop}
                    // the trashbin icon
                    hideBadge={true}
                    onViewNote={undefined}
                  />
                </motion.div>
              );
            })}
          </div>
          {card.replies && (
            <div className="replies-container">
              {card.replies.map(({ message, status, replier, id }) => {
                return (
                  <TextBox
                    key={id}
                    className={classNames('fs-mask', 'replies')}
                    minHeight={64}
                    text={message}
                    label={`${replier}'s Reply`}
                    shadow={status === 'Unread'}
                    color={colors.gray100}
                    dark
                  />
                );
              })}
            </div>
          )}
        </div>
      </IllumeLayout>
      {isGift(card.giftV2) && (
        <GroupGiftModal
          defaultValues={{}}
          hideModal={() => setShowGroupGiftModal(false)}
          onContributeToGroupGift={() => setShowGroupGiftModal(false)}
          removable={false}
          showModal={showGroupGiftModal}
          gift={GroupGiftModalDTOMapper.fromGiftV2Dto(card.giftV2)}
        />
      )}
      {externalGift && (
        <AnyGiftDetailsModal
          showModal={showAnyGift}
          hideModal={() => setShowAnyGift(false)}
          product={{
            description: externalGift.description || '',
            formattedPriceBreakdown: externalGift?.formattedPriceBreakdown,
            image: externalGift.image,
            title: externalGift.title,
          }}
        />
      )}
      <SentCardDetailsModal
        onResendSuccess={noop}
        resendCardService={resendCardService}
        show={showModalCardDetail}
        cardV2={card}
        onClose={() => setShowModalCardDetail(false)}
      />
      {displayedNote && (
        <PreviewModal
          open={!!displayedNote}
          notes={PreviewModal.notesFromDto([displayedNote])}
          showNavigationButtons={false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name={spelledRecipientNames!}
          onClose={() => setDisplayedNote(null)}
          onRetry={noop}
          showPrivacyModal={false}
        />
      )}
    </>
  );
}
