import { colors } from 'constants/design';

interface FourSquaresProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}
const FourSquares = ({ size = 22, color = colors.contrastText, ...props }: FourSquaresProps) => {
  return (
    <svg
      className="img"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.59063 1.32031H1.98C1.37249 1.32031 0.880005 1.8128 0.880005 2.42031V8.40156C0.880005 9.00908 1.37249 9.50156 1.98 9.50156H8.59063C9.19814 9.50156 9.69063 9.00908 9.69063 8.40156V2.42031C9.69063 1.8128 9.19814 1.32031 8.59063 1.32031Z"
        fill={color}
      />
      <path
        d="M20.0306 1.32031H13.4199C12.8124 1.32031 12.3199 1.8128 12.3199 2.42031V8.40156C12.3199 9.00908 12.8124 9.50156 13.4199 9.50156H20.0306C20.6381 9.50156 21.1306 9.00908 21.1306 8.40156V2.42031C21.1306 1.8128 20.6381 1.32031 20.0306 1.32031Z"
        fill={color}
      />
      <path
        d="M8.59063 12.54H1.98C1.37249 12.54 0.880005 13.0325 0.880005 13.64V19.6213C0.880005 20.2288 1.37249 20.7213 1.98 20.7213H8.59063C9.19814 20.7213 9.69063 20.2288 9.69063 19.6213V13.64C9.69063 13.0325 9.19814 12.54 8.59063 12.54Z"
        fill={color}
      />
      <path
        d="M20.0306 12.54H13.4199C12.8124 12.54 12.3199 13.0325 12.3199 13.64V19.6213C12.3199 20.2288 12.8124 20.7213 13.4199 20.7213H20.0306C20.6381 20.7213 21.1306 20.2288 21.1306 19.6213V13.64C21.1306 13.0325 20.6381 12.54 20.0306 12.54Z"
        fill={color}
      />
    </svg>
  );
};

export default FourSquares;
