import { NoteDTO } from '@illume/shared';
import { match } from 'ts-pattern';

import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { CustomError } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';

import { CreateNoteOptions } from '../../domain/interfaces/INoteService/CreateNoteOptions';
import { INoteService } from '../../domain/interfaces/INoteService/INoteService';

export class NoteService implements INoteService {
  // replaces: GET /note/view-code/:inviteCode
  constructor(private httpClient: IHttpClient) {}
  getNote = async (code: string) => {
    const requestObj = {
      url: `/note/${code}`,
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{ note: NoteDTO }>(requestObj);

    return match(resp)
      .with({ success: true }, (r) => r.note)
      .with({ success: false }, (r) => {
        throw CustomError.fromServiceErrorDto(r);
      })
      .exhaustive();
  };

  createNote = async (
    inviteCode: string,
    {
      message,
      signature,
      theme,
      palette,
      photos = [],
      videos = [],
      gifs = [],
      isPublic = false,
    }: CreateNoteOptions,
  ) => {
    const data = {
      message,
      signature,
      theme,

      /**
       * the palette uuid a.k.a paletteCode
       */
      palette,

      photos,
      videos,
      gifs,
      isPublic,
    };
    if (checkFF(FLAGS.PUBLIC_TOGGLE_NOTE)) {
      data.isPublic = isPublic;
    }

    const requestObj = {
      url: `/card/${inviteCode}/note`,
      method: 'post',
      data,
    };
    const resp = await this.httpClient.makeRequest<{ note: NoteDTO }>(requestObj);
    if (resp.success) {
      return resp.note;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  createNoteDraft = async (
    inviteCode: string,
    {
      message = '',
      signature = '',
      photos = [],
      gifs = [],
      videos = [],
      isPublic = false,
    }: Partial<CreateNoteOptions>,
  ) => {
    const data = { message, signature, photos, gifs, videos, isPublic };
    if (checkFF(FLAGS.PUBLIC_TOGGLE_NOTE)) {
      data.isPublic = isPublic;
    }

    const requestObj = {
      url: `/card/${inviteCode}/noteDraft`,
      method: 'post',
      data,
    };
    const resp = await this.httpClient.makeRequest<{ note: NoteDTO }>(requestObj);

    if (resp.success) {
      return resp.note;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  updateNote = async (code: string, payload: Partial<CreateNoteOptions>) => {
    const {
      message,
      signature,
      theme,
      palette,
      videos = [],
      photos = [],
      gifs = [],
      isPublic,
    } = payload;

    const data = {
      message,
      signature,
      theme,
      palette,
      photos,
      videos,
      gifs,
      isPublic,
    };
    if (checkFF(FLAGS.PUBLIC_TOGGLE_NOTE)) {
      data.isPublic = isPublic;
    }

    const requestObj = {
      url: `/note/${code}`,
      method: 'patch',
      data,
    };
    const resp = await this.httpClient.makeRequest<{
      note: { code: string; cardCode: string };
    }>(requestObj);
    if (resp.success) {
      return;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  deleteNote = async (code: string) => {
    const requestObj = {
      url: `/note/${code}`,
      method: 'delete',
    };
    const resp = await this.httpClient.makeRequest(requestObj);
    if (resp.success) {
      return resp.success;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };
}
