import { AxiosRequestConfig } from 'axios';
import { omitBy, isUndefined, isEmpty } from 'lodash';
import { validate as validateUUID } from 'uuid';

import { IHttpClient } from 'domain/interfaces/IHttpClient';
import illumeApiHttpClient from 'infra/client';
import { ShopifyShippingInfoOnlyEmail } from 'pages/illume/recipient/ShippingFormEmailOnly';
import {
  ContributorCardDTO,
  InitiatorCardViewDTO,
  RecipientCardV2Dto,
  RecipientDTO,
  ReplyDTO,
  MyCardsCardDTO,
  CardUpdateResponseDTO,
  ExternalGiftDTO,
  CreateCardSetReminderPayloadDTo,
  CreateCardResponseDTO,
  ShopifyShippingInfo,
  MerchantGiftCreateOrUpdatePayloadDTO,
  GiftV2Dto,
} from 'types';
import CustomError from 'utils/CustomError';

import { ICardService } from '../../domain/interfaces/ICardService';

export enum CodeType {
  ViewCode = 'VIEW_CODE',
  UUIDRecipientOrContributorInvite = 'SEND_CODE',
  InviteCode = 'INVITE_CODE',
}

type RecipientContact = { recipientId: string; contact: string };

type Base = {
  initiatorEmail: string;
  initiatorName: string;
  receiveNotifications: boolean;
  themepack: string;
  recipientData: Partial<RecipientDTO>[];
  deadlineTz: string;
  introMessage: string;
  recipientMessage?: string;
};

export type UpdateCardPayloadOpts = Partial<Base>;

export type AnyGiftPayload = {
  title: string;
  variant: string;
  photoURL: string;
  productURL: string;
  description: string;
  price: number; //must be integer in cents
  suggestedAmount: number;
  isGiftCard: boolean;
};

export class IllumeAPISlackCardService implements ICardService {
  get slackBaseUrl() {
    return `/team/${this.workspaceUrl}`;
  }
  createCard = async (payload: CreateCardSetReminderPayloadDTo) => {
    const requestData = {
      initiatorName: payload.initiatorName,
      recipientData: payload.recipientData,
      occasionName: payload.occasionName,
      deadline: payload.deadline,
      deadlineTz: payload.deadlineTz,
    };

    const requestObj = {
      url: `${this.slackBaseUrl}/card`,
      method: 'post',
      data: requestData,
    };

    const resp = await this.client.makeRequest<{ card: CreateCardResponseDTO }>(requestObj);
    if (resp.success) {
      return resp.card;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  getAllCards = async (type: string) => {
    const requestObj = {
      // url: `/all/team/:slackWorkspaceUrl/:status`,
      url: `${this.slackBaseUrl}/v2/card/all/${type}`,
      method: 'get',
    };
    const resp = await this.client.makeRequest<{ cards: MyCardsCardDTO[] }>(requestObj);
    if (resp.success) {
      return resp.cards;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  getInitiatorCard = async (cardCode: string) => {
    const requestObj: AxiosRequestConfig = {
      url: `${this.slackBaseUrl}/v2/card/view/${cardCode}`,
      method: 'get',
    };
    const resp = await this.client.makeRequest<{ card: InitiatorCardViewDTO }>(requestObj);
    if (resp.success) {
      return resp.card;
    }
    throw new CustomError({
      errorCode: resp.errorCode,
      message: resp.message,
      statusCode: resp.statusCode,
    });
  };

  getContributorCard = async (inviteCode: string) => {
    const requestObj = {
      url: `${this.slackBaseUrl}/v2/card/contribute/${inviteCode}`,
      method: 'get',
    };
    const resp = await this.client.makeRequest<{ card: ContributorCardDTO }>(requestObj);
    if (resp.success) {
      return resp.card;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  getReceivedCard = async (sendCode: string): Promise<RecipientCardV2Dto> => {
    const requestObj = {
      url: `${this.slackBaseUrl}/v2/card/receive/${sendCode}`,
      method: 'get',
    };
    const resp = await this.client.makeRequest<{ card: RecipientCardV2Dto }>(requestObj);
    if (resp.success) {
      return resp.card;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  sendReply = async (sendCode: string, payload: { message: string }) => {
    const { message } = payload || {};
    const requestObj = {
      url: `${this.slackBaseUrl}/v2/card/receive/${sendCode}/reply`,
      method: 'post',
      data: { message },
    };
    const resp = await this.client.makeRequest<{ reply: ReplyDTO }>(requestObj);
    if (resp.success) {
      return resp.reply;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  sendCard = async (
    viewCode: string,
    payload: { message?: string; recipientsContacts: RecipientContact[] },
  ) => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${viewCode}/send`,
      method: 'post',
      data: payload,
    };
    const resp = await this.client.makeRequest(requestObj);
    if (resp.success) {
      return true;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  updateCard = async (code: string, payload: UpdateCardPayloadOpts) => {
    const data = omitBy({ ...payload }, isUndefined);

    const requestObj = {
      url: `${this.slackBaseUrl}/card/${code}`,
      method: 'patch',
      data,
    };
    const resp = await this.client.makeRequest<{ card: CardUpdateResponseDTO }>(requestObj);
    if (resp.success) {
      return resp.card;
    } else {
      const errMsg = resp?.message || 'Failed to update the card';
      throw Error(errMsg);
    }
  };

  saveAnyGiftToCard = async (cardCode: string, payload: AnyGiftPayload) => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${cardCode}/externalGift`,
      method: 'post',
      data: payload,
    };
    const res = await this.client.makeRequest<{ gift: ExternalGiftDTO }>(requestObj);
    if (res.success) {
      return res.gift;
    } else {
      throw CustomError.fromServiceErrorDto(res);
    }
  };

  saveProductToCard = async (code: string, payload: MerchantGiftCreateOrUpdatePayloadDTO) => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${code}/cardGift`,
      method: 'post',
      data: payload,
    };
    const resp = await illumeApiHttpClient.makeRequest<{ gift: GiftV2Dto }>(requestObj);
    if (resp.success) {
      return resp.gift;
    } else {
      const { message } = resp || {};
      const errMsg = message || 'failed to save merchant product to card ';
      throw CustomError.fromServiceErrorDto({ ...resp, message: errMsg });
    }
  };

  updateSavedGift = async (
    code: string,
    payload: Partial<MerchantGiftCreateOrUpdatePayloadDTO & { promoCode: string }>,
  ): Promise<GiftV2Dto> => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${code}/cardGift`,
      method: 'PATCH',
      data: payload,
    };
    const resp = await illumeApiHttpClient.makeRequest<{ success: true; gift: GiftV2Dto }>(
      requestObj,
    );
    if ('success' in resp && resp.success) {
      return resp.gift;
    } else {
      throw new Error(resp.message);
    }
  };

  applyPromoCode = (code: string, cardCode: string) => {
    return this.updateSavedGift(cardCode, { promoCode: code }).catch((e: Error) => {
      // fine grain error, because API doesn't provide the code
      if (e.message.includes('code not found')) {
        throw new CustomError({ errorCode: 'NotFound', message: e.message, statusCode: 500 });
      } else {
        throw e;
      }
    });
  };

  deleteCard = async (cardCode: string): Promise<void> => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${cardCode}`,
      method: 'delete',
    };
    const resp = await this.client.makeRequest(requestObj);
    if (resp.success) {
      return;
    } else {
      CustomError.fromServiceErrorDto(resp);
    }
  };

  updateAnyGift = async (cardCode: string, payload: Partial<AnyGiftPayload>) => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${cardCode}/externalGift`,
      method: 'patch',
      data: payload,
    };
    const res = await this.client.makeRequest<{ gift: ExternalGiftDTO }>(requestObj);
    if (res.success) {
      return res.gift;
    } else {
      throw new Error(res.message || 'unexpected error');
    }
  };

  deleteGift = async (cardCode: string) => {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${cardCode}/cardGift`,
      method: 'DELETE',
    };
    const resp = await this.client.makeRequest<void>(requestObj);
    if (resp.success) {
      return;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  getClientSecretForCardPayment = async (payload: {
    plan: 'Basic' | 'Premium' | 'Standard';
    promoCode: string;
  }) => {
    const requestObj = {
      method: 'post',
      url: `${this.slackBaseUrl}/payment/subscription`,
      data: payload,
    };
    const resp = await this.client.makeRequest<{ clientSecret: string }>(requestObj);
    if (resp.success) {
      return resp.clientSecret;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  async deleteExternalGift(cardCode: string) {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${cardCode}/externalGift`,
      method: 'DELETE',
    };
    const resp = await illumeApiHttpClient.makeRequest<{ message: string }>(requestObj);
    if (resp.success) {
      return;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  }

  constructor(private client: IHttpClient, private workspaceUrl: string) {}

  async inviteContributors(cardCode: string, payload: { inviteContacts: string[] }) {
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${cardCode}/invite`,
      method: 'post',
      data: payload,
    };
    const resp = await illumeApiHttpClient.makeRequest<{ sentEmails: any; unsentEmails: any }>(
      requestObj,
    );
    if (resp.success) {
      const { sentEmails, unsentEmails } = resp;
      return { sentEmails, unsentEmails };
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  }

  saveExternalGiftShippingInfo = async (
    code: string,
    payload: ShopifyShippingInfo | ShopifyShippingInfoOnlyEmail,
  ): Promise<string> => {
    const data = omitBy({ ...payload }, isEmpty);
    const requestObj = {
      url: `${this.slackBaseUrl}/card/${code}/externalGift/shipping`,
      method: 'post',
      data: data,
    };
    const resp = await illumeApiHttpClient.makeRequest<{ message: string }>(requestObj);
    if (resp.success) {
      return resp.message;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };
}

export const determineCodeType = (cardCode: string): CodeType | undefined => {
  const code = cardCode.toString();
  if (code.startsWith('g_')) {
    return CodeType.ViewCode;
  }
  if (code.startsWith('i_')) {
    return CodeType.InviteCode;
  }
  if (validateUUID(code)) {
    return CodeType.UUIDRecipientOrContributorInvite;
  }
};
