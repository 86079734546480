const LogoutIcon = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7307 10.4201L14.8195 6.84056C14.7158 6.7688 14.595 6.72572 14.4693 6.71566C14.3436 6.7056 14.2174 6.72893 14.1036 6.78329C13.9856 6.84278 13.8863 6.93381 13.8168 7.04627C13.7473 7.15873 13.7103 7.28824 13.7099 7.42045V8.85227H7.26669C7.07682 8.85227 6.89473 8.92769 6.76047 9.06195C6.62621 9.19621 6.55078 9.37831 6.55078 9.56818V12.4318C6.55078 12.6217 6.62621 12.8038 6.76047 12.938C6.89473 13.0723 7.07682 13.1477 7.26669 13.1477H13.7099V14.5795C13.7103 14.7117 13.7473 14.8413 13.8168 14.9537C13.8863 15.0662 13.9856 15.1572 14.1036 15.2167C14.2034 15.2678 14.3137 15.2948 14.4258 15.2955C14.5774 15.296 14.7253 15.2484 14.8482 15.1594L19.7593 11.5799C19.851 11.5135 19.9256 11.4264 19.9771 11.3256C20.0285 11.2247 20.0554 11.1132 20.0554 11C20.0554 10.8868 20.0285 10.7753 19.9771 10.6745C19.9256 10.5736 19.851 10.4865 19.7593 10.4201H19.7307Z"
        fill="#282829"
        fillOpacity="0.4"
      />
      <path
        d="M10.8184 16.0114H5.807V5.98864H10.1025C10.4822 5.98864 10.8464 5.83778 11.1149 5.56927C11.3834 5.30075 11.5343 4.93656 11.5343 4.55682C11.5343 4.17708 11.3834 3.81289 11.1149 3.54437C10.8464 3.27585 10.4822 3.125 10.1025 3.125H4.37518C3.99544 3.125 3.63125 3.27585 3.36273 3.54437C3.09421 3.81289 2.94336 4.17708 2.94336 4.55682V17.4432C2.94336 17.8229 3.09421 18.1871 3.36273 18.4556C3.63125 18.7241 3.99544 18.875 4.37518 18.875H10.8184C11.1981 18.875 11.5623 18.7241 11.8308 18.4556C12.0993 18.1871 12.2502 17.8229 12.2502 17.4432C12.2502 17.0634 12.0993 16.6993 11.8308 16.4307C11.5623 16.1622 11.1981 16.0114 10.8184 16.0114Z"
        fill="#282829"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export default LogoutIcon;
