import { ReplyDTO, SendCardContextDTO as SendCardContextReplyDTO } from '@illume/shared';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { get } from 'lodash';

import { TextBox } from 'components/illume/text-box';
import { colors } from 'constants/design';

const useStyles = makeStyles(() => {
  return createStyles({
    replyListCard: {
      maxWidth: '100%',
      whiteSpace: 'pre-line',
    },
  });
});

export const Read: React.FC<{
  replies: ReplyDTO[];
  sendCardContext: SendCardContextReplyDTO[];
  recipientMessage?: string;
}> = ({ sendCardContext, replies = [], recipientMessage }) => {
  const introMessage = get(sendCardContext[0], 'introMessage') as string; // get intro message from any of the recipients(since it has the same intro message)

  const recipients = sendCardContext || [];

  const classes = useStyles();

  return (
    <Grid container direction="column" item spacing={3}>
      <Grid item container direction="column" spacing={2}>
        {recipients.map((r) => {
          const { name, recipientId } = r;
          const text = 'recipientEmail' in r ? r.recipientEmail : r.recipientPhone;
          return (
            <Grid item key={recipientId}>
              <TextBox dark label={`${name}'s Email Address or Phone Number`} text={text} />
            </Grid>
          );
        })}

        {/* intro message FOR recipient */}
        {introMessage && (
          <Grid item>
            <TextBox dark className="fs-mask" text={introMessage} label={'Intro Message'} />
          </Grid>
        )}

        {/** teams intro message */}
        {recipientMessage && (
          <Grid item>
            <TextBox dark className="fs-mask" text={recipientMessage} label={'Intro Message'} />
          </Grid>
        )}
      </Grid>

      {replies.map(({ message, status, replier, self }, i) => {
        const isMyReply = self;
        return (
          <Grid item key={i}>
            <TextBox
              className={cx('fs-mask', classes.replyListCard)}
              minHeight={64}
              text={message}
              label={isMyReply ? 'Your Reply' : `${replier}'s Reply`}
              shadow={status === 'Unread'}
              color={colors.gray100}
              dark
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
