import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
  },
  content: {
    maxWidth: 420,
    width: '100%',
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    paddingBottom: theme.spacing(6.5),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
  },
  formField: {
    background: ({ closable }) => closable && colors.backgroundGray,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

export default useStyles;
