import { makeStyles } from '@material-ui/core/styles';

export const useModalStyles = makeStyles(() => ({
  root: {
    outline: 'none',
  },
}));

export const usePaperStyles = makeStyles((theme) => ({
  root: {
    outline: 0,
    background: ({ background }) => background,
    position: 'relative',
    margin: '0 auto',
    marginTop: theme.spacing(10),
    maxWidth: 1024,
    zIndex: theme.zIndex.modal,
    overflow: 'hidden',
    borderRadius: 16,
  },
}));
