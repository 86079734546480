import { useCallback, useEffect, useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';

import { Edit, Instagram, Save } from 'components/illume/icons';
import Text from 'components/illume/text/Text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import { IllumeLayout } from 'components/layouts';
import { SentryErrorFallback } from 'components/sentry-error-fallback';
import { colors, rem } from 'constants/design';
import { MY_CARDS_URL, OPEN_INSTAGRAM } from 'constants/strings';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import Sentry from 'infra/sentry/sentry';
import { TABS } from 'pages/illume/my-cards/MyCards';
import analytics from 'services/analytics';
import { getMobileOperatingSystem, noop } from 'utils';
import logger from 'utils/logger';

import { useStyles, useExampleStyles } from './SocialShares.styles';

const TOOLTIP_MESSAGE = 'add your own thank you message here';
const TOOLTIP_FEELING = 'tell people how it made you feel';
const TOOLTIP_SAVEPIC = 'just tap and hold the above png & save it to your phone!';
const TOOLTIP_TIMEOUT = 10000;

const ShareExample = ({ showTooltipPicture }) => {
  const classes = useExampleStyles();

  logger.log('render the example');

  return (
    <Grid item className={classes.inputBox}>
      <Tooltip
        variant="secondary"
        text={TOOLTIP_MESSAGE}
        showTooltip={showTooltipPicture}
        placement="top-start"
        widthArrow="10%"
      >
        <div className={classes.tooltipMessage}></div>
      </Tooltip>
      <Tooltip
        variant="secondary"
        text={TOOLTIP_FEELING}
        showTooltip={showTooltipPicture}
        placement="bottom-end"
        widthArrow="10%"
      >
        <div className={classes.tooltipFeeling}></div>
      </Tooltip>
    </Grid>
  );
};

const IOSButtons = ({ onSave = noop, showTooltipSave, classes }) => {
  const handleSave = () => {
    analytics.track('social share - recipient', { device: 'ios', action: 'download' });
    onSave();
  };

  const handleOpenInstagram = () => {
    window.location.href = OPEN_INSTAGRAM;
  };

  const ButtonText = ({ children }) => (
    <Text
      color={colors.contrastText}
      fontSize={{ mobile: rem[1125], desktop: rem[1500] }}
      fontWeight={500}
      align="center"
    >
      {children}
    </Text>
  );

  return (
    <Grid container className={classes.socialButtons} direction="column" justifyContent="center">
      {/** Save Image */}
      <Grid item onClick={handleSave}>
        <Button disableRipple className={classes.iOSButton}>
          <Tooltip
            variant="primaryDark"
            text={TOOLTIP_SAVEPIC}
            showTooltip={showTooltipSave}
            placement="top"
            widthArrow="4%"
          >
            <div>
              <Save />
            </div>
          </Tooltip>
        </Button>
      </Grid>
      <Grid item onClick={handleSave}>
        <ButtonText>#1: save the image</ButtonText>
      </Grid>

      {/** Open Instagram */}
      <Grid item onClick={handleOpenInstagram}>
        <Button disableRipple className={classes.iOSButton}>
          <Instagram />
        </Button>
      </Grid>
      <Grid item onClick={handleOpenInstagram}>
        <ButtonText>#2: click here to open instagram</ButtonText>
      </Grid>

      {/** Edit! */}
      <Grid item>
        <Button disableRipple className={classes.iOSButton}>
          <Edit />
        </Button>
      </Grid>
      <Grid item>
        <ButtonText>#3: add your own text</ButtonText>
      </Grid>
    </Grid>
  );
};

const AndroidButtons = ({ classes }) => {
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  // use this state variable to pre-load the share image for Android users
  const [file, setFile] = useState(undefined);

  const getShareFile = async () => {
    const response = await fetch('/share-illume.png');
    const blob = await response.blob();
    const shareFile = new File([blob], 'illume.png', { type: 'image/jpeg' });
    return shareFile;
  };

  const loadShareFile = useCallback(async () => {
    const shareFile = await getShareFile();
    setFile(shareFile);
  }, []);

  useEffect(() => {
    loadShareFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShareFile = async () => {
    try {
      analytics.track('social share - recipient', { device: 'android', action: 'share' });
      // attempt to get the pre-loaded file, but if it's not there yet, we can explicitly go get it.
      const sharingData = {
        files: [!file ? await getShareFile() : file],
      };
      if (navigator.canShare && navigator.canShare(sharingData)) {
        await navigator.share(sharingData);
      } else {
        enqueueErrorSnackbar('your device is not compatible, download it instead ;)');
      }
    } catch (err) {
      logger.error(err.message, { errorName: err.name });
    }
  };

  const handleDownload = () => {
    analytics.track('social share - recipient', { device: 'android', action: 'download' });
  };

  return (
    <Grid container className={classes.socialButtons} direction="row" justifyContent="center">
      <Grid item>
        <Button disableRipple className={classes.button}>
          <Link
            to="/share-illume.png"
            target="_blank"
            download="illume-made-me-feel"
            onClick={handleDownload}
          >
            <Save />
          </Link>
        </Button>
      </Grid>
      {/** Open Native Android share Dialog */}
      <Grid item>
        <Button onClick={handleShareFile} disableRipple className={classes.button}>
          <Instagram />
        </Button>
      </Grid>
    </Grid>
  );
};

const SocialShares = () => {
  const mobileOS = getMobileOperatingSystem();
  const iOS = mobileOS === 'iOS';
  const [showPicture, setShowPicture] = useState(false);
  const [showTooltipSave, setShowTooltipSave] = useState(false);
  const [showTooltipPicture, setShowTooltipPicture] = useState(true);
  const classes = useStyles({ iOS, showTooltipSave });

  if (mobileOS === 'unknown') {
    return <Redirect to={{ pathname: MY_CARDS_URL, state: { search: TABS.RECEIVED } }} />;
  }

  const handleShowPicture = () => {
    setShowTooltipPicture(false);
    setShowPicture(true);
    setShowTooltipSave(true);
    const turnOffTooltip = setTimeout(() => {
      setShowTooltipSave(false);
    }, TOOLTIP_TIMEOUT);
    return () => {
      clearTimeout(turnOffTooltip);
    };
  };

  return (
    <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
      <IllumeLayout background={colors.primary}>
        <Grid container direction="column" className={classes.container}>
          <div style={{ display: !showPicture && 'none' }}>
            <img src={'/share-illume.png'} alt="illume pic" />
          </div>
          <div style={{ display: showPicture && 'none' }}>
            <ShareExample showTooltipPicture={showTooltipPicture} />
          </div>
          <Grid className={classes.headerBox}>
            <Text
              color={colors.contrastText}
              fontSize={{ mobile: rem[2250], desktop: rem[3000] }}
              fontWeight={800}
              align="center"
            >
              share on social!
            </Text>
          </Grid>
          <Grid className={classes.textBox}>
            <Text align="center" fontSize={rem[1125]} fontWeight={300}>
              write your own message and add it to your story.
            </Text>
          </Grid>
          {iOS ? (
            <IOSButtons
              onSave={handleShowPicture}
              showTooltipSave={showTooltipSave}
              classes={classes}
            />
          ) : (
            <AndroidButtons classes={classes} />
          )}
        </Grid>
      </IllumeLayout>
    </Sentry.ErrorBoundary>
  );
};

export default SocialShares;
