import React, { FunctionComponent, useState } from 'react';

import { Box } from '@material-ui/core';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { action, autorun, makeAutoObservable, observable, runInAction, toJS, when } from 'mobx';
import { observer } from 'mobx-react';
import { FileWithPath } from 'react-dropzone';

import Dropzone, { DropzoneStoreGeneric } from 'components/dropzone/Dropzone';
import FileUploadLinearProgress from 'components/dropzone/LinearProgress';
import { Button, CancelButton } from 'components/illume/buttons';
import { Modal } from 'components/illume/modal';
import { Text } from 'components/illume/text';
import { TextInput } from 'components/illume/text-inputs';
import { TextProps } from 'components/illume/text/Text';
import { colors } from 'constants/design';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import OccasionStore from 'contexts/store/occasion-store/Occasion.store';
import { UserProfileStore } from 'contexts/user';

import useStyles from './BatchCardUploadModal.styles';
import Dropdown from './Dropdown';
import Warn from './Warn.icon';

type UploadService = (
  payload: {
    files: FileWithPath[];
    batchName: string;
    occasionName: string;
    initiatorName: string;
  },
  onProgress: (progress: number) => any,
  signal: AbortSignal,
) => Promise<any>;

interface BatchCardUploadModalProps {
  show: boolean;
  uploadService: UploadService;
  onCancel: () => any;
  onSuccessDismiss: () => any;
}

class FileUploadModalStore {
  private _abort?: AbortController;

  private getTask = () =>
    new ObservablePromise(() => {
      this._abort = new AbortController();
      if (!this.dropzoneStore.acceptedFiles) {
        return Promise.reject(new Error('no file provided'));
      }

      return this.uploadService(
        this.dto,
        (progress) => runInAction(() => (this.progressRatio = progress)),
        this._abort.signal,
      ).then((result) => {
        this._abort = undefined;
        return result;
      });
    });

  uploadTask: ObservablePromise<any> = this.getTask();

  reinitTask = () => {
    this.uploadTask = this.getTask();
    this.progressRatio = 0;
  };

  get dto() {
    return toJS({
      files: this.dropzoneStore.acceptedFiles || [],
      batchName: this.form.batchName,
      occasionName: this.form.occasionOpt.value,
      initiatorName: this.form.initiatorName,
    });
  }

  progressRatio?: number;

  form = observable({
    batchName: '',
    occasionOpt: { label: '', value: '' },
    initiatorName: '',
    get isValid() {
      return this.batchName && this.occasionOpt.value && this.initiatorName;
    },
  });

  constructor(
    private uploadService: UploadService,
    private dropzoneStore: DropzoneStoreGeneric,
    private _occasionStore: OccasionStore,
    private _userProfileStore: UserProfileStore,
  ) {
    makeAutoObservable(this);
    autorun(() => {
      console.log('error: ', this.uploadTask.error);
    });
    // prefill form when data available
    when(
      () => !!this._userProfileStore.fullName,
      action(() => {
        this.form.initiatorName = this._userProfileStore.fullName!;
      }),
    );

    when(
      () => !!this.occasionOpts,
      action(() => {
        if (this.occasionOpts && this.occasionOpts[0]) {
          this.form.occasionOpt = this.occasionOpts[0];
        }
      }),
    );
  }
  public abortUpload() {
    if (this._abort) this._abort.abort();
  }
  get error() {
    return this.uploadTask.error;
  }
  get status() {
    if (this.uploadTask.isExecuting) {
      return 'uploading';
    }

    if (this.uploadTask.isError) {
      return 'error';
    }
    if (this.uploadTask.wasSuccessful) {
      return 'success';
    }
    return 'idle';
  }
  get occasionOpts() {
    return (
      this._occasionStore.legacyOccasions?.map((o) => ({
        label: o.name,
        value: o.name,
      })) || null
    );
  }
}

const BatchCardUploadModal: FunctionComponent<BatchCardUploadModalProps> = ({
  show,
  uploadService = () => Promise.resolve(),
  onSuccessDismiss,
  onCancel,
}) => {
  const { occasionStore, userProfileStore } = useStores();
  const { batchCardService } = useServices();
  const [dropzoneStore] = useState(
    () =>
      new DropzoneStoreGeneric((files) =>
        batchCardService.validateBatch({ presetRecipients: files[0] }),
      ),
  );
  const [fileUploadStore] = useState(
    () => new FileUploadModalStore(uploadService, dropzoneStore, occasionStore, userProfileStore),
  );

  const classes = useStyles({ fileUploadStatus: fileUploadStore.status });

  function getTitleConfig(): {
    align: TextProps['align'];
    title: string;
    fontSize: TextProps['fontSize'];
    icon?: React.FC<any>;
  } {
    switch (fileUploadStore.status) {
      case 'idle':
        return { align: 'left', title: 'Upload file', fontSize: { mobile: 28, desktop: 24 } };
      case 'uploading':
        return {
          align: 'center',
          title: 'working our magic...',
          fontSize: { desktop: 48, mobile: 28 },
        };
      case 'error':
        return {
          icon: Warn,
          align: 'center',
          title: 'please check your file...',
          fontSize: { desktop: 48, mobile: 28 },
        };
      default:
        return { align: 'left', title: 'Upload file', fontSize: { mobile: 28, desktop: 24 } };
    }
  }

  function handleSubmit() {
    fileUploadStore.uploadTask.execute().catch();
  }

  const uploadingContent = (
    <div className={classes.uploadingContent}>
      <div style={{ flexGrow: 1 }}>
        <FileUploadLinearProgress
          style={{ width: 'auto' }}
          value={100 * (fileUploadStore.progressRatio || 0)}
          variant="determinate"
        />
      </div>
      <Text fontWeight={400} fontSize={{ mobile: 14, desktop: 18 }}>
        Dotting all the i’s and crossing all the t’s!
      </Text>
    </div>
  );

  const titleProps = {
    align: getTitleConfig().align,
    fontSize: getTitleConfig().fontSize,
    fontWeight: 700,
  };

  const TitleIcon = getTitleConfig().icon;

  return (
    <Modal className={classes.root} zIndex={3000} show={show}>
      <div className={classes.content}>
        <div>
          {TitleIcon && (
            <Box display={'flex'} justifyContent={'center'} mb={1}>
              <TitleIcon />
            </Box>
          )}
          <Box mb={2}>
            <Text {...titleProps}>{getTitleConfig().title}</Text>
          </Box>
          {fileUploadStore.status === 'idle' && (
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <TextInput
                placeholder="e.g Birthdays"
                label="Batch Name"
                name="batchName"
                labelTextProps={{ fontWeight: 400 }}
                onChange={action((e) => (fileUploadStore.form.batchName = e.target.value))}
                value={fileUploadStore.form.batchName}
              />
              <TextInput
                label="Initiator Name"
                placeholder="e.g Sohale"
                name="initiatorName"
                labelTextProps={{ fontWeight: 400 }}
                onChange={action((e) => (fileUploadStore.form.initiatorName = e.target.value))}
                value={fileUploadStore.form.initiatorName}
              />
              <Dropdown
                options={fileUploadStore.occasionOpts}
                name="occasionName"
                label="Occasion Type"
                onChange={action((opt: any) => (fileUploadStore.form.occasionOpt = opt))}
                value={fileUploadStore.form.occasionOpt}
              />
              <Dropzone
                mode="file"
                accept={{
                  'file/plain': ['.csv'],
                }}
                store={dropzoneStore}
              />
            </div>
          )}
          {fileUploadStore.status === 'uploading' && uploadingContent}
          {fileUploadStore.status === 'error' && (
            <div>
              <Text align="center" fontWeight={400} fontSize={{ desktop: 18, mobile: 16 }}>
                Uh-oh! Something is wrong with your .csv file. Please follow formatting instructions{' '}
                <span style={{ color: colors.gray60, textDecoration: 'underline' }}>
                  <a href="/teams_example.csv" target="_blank" download rel="noopener noreferrer">
                    here.
                  </a>
                </span>
              </Text>
              {fileUploadStore.error && (
                <Text align="center" fontWeight={400} fontSize={{ desktop: 18, mobile: 16 }}>
                  <b>Reason: </b>
                  {fileUploadStore.error.reasons || fileUploadStore.error.message}
                </Text>
              )}
            </div>
          )}
          {fileUploadStore.status === 'success' && (
            <div>
              <Text>Your file have been uploaded!</Text>
            </div>
          )}
        </div>
        <div className={classes.buttonGroup}>
          {(fileUploadStore.status === 'idle' || fileUploadStore.status === 'uploading') && (
            <CancelButton
              style={{ width: 150 }}
              onClick={() => {
                fileUploadStore.abortUpload();
                onCancel();
              }}
              disabled={false}
              loading={false}
            >
              Cancel
            </CancelButton>
          )}
          {fileUploadStore.status === 'error' && (
            <CancelButton
              style={{ width: 150 }}
              onClick={() => {
                fileUploadStore.reinitTask();
              }}
              disabled={false}
              loading={false}
            >
              go back
            </CancelButton>
          )}
          {fileUploadStore.status === 'idle' && (
            <Button
              disabled={dropzoneStore.state.status === 'idle' || !fileUploadStore.form.isValid}
              style={{ width: 150 }}
              onClick={handleSubmit}
            >
              submit
            </Button>
          )}
          {fileUploadStore.status === 'success' && (
            <Button
              disabled={dropzoneStore.state.status === 'idle' || !fileUploadStore.form.isValid}
              style={{ width: 150 }}
              onClick={onSuccessDismiss}
            >
              dismiss
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default observer(BatchCardUploadModal);
