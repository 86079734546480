import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import { RightArrowButton } from 'components/illume/nav-arrows';
import ProgressCircle from 'components/illume/progress-circles/ProgressCircles';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import { useAuth } from 'contexts/authentication';
import { useConfettiCannon } from 'hooks';
import { useUserSummary } from 'hooks/illume/useUserSummary';
import { RecipientDTO } from 'types';
import { pluralize } from 'utils';
import { spellNames } from 'utils/string';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    paddingBottom: theme.spacing(5),
    rowGap: 24,
    '& .title': {
      marginTop: theme.spacing(15),
      rowGap: 24,
      display: 'flex',
      flexDirection: 'column',
    },
  },
  moneyPot: {
    marginTop: theme.spacing(4),
  },
  navFooter: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'flex-end',
    rowGap: 24,
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      justifyContent: 'flex-start',
      marginTop: theme.spacing(10),
    },
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}));

const ThanksPage: React.FC<{
  recipientData: RecipientDTO[];
  contributorName: string;
  onNext: () => any;
}> = ({ recipientData: recipientData, contributorName, onNext }) => {
  useConfettiCannon();
  const { userSummaryDto: userSummary } = useUserSummary();
  const { authenticated } = useAuth() || {};
  const notesSent = userSummary?.userStats?.notesSent;
  const classes = useStyles();
  const spelledNames = spellNames(recipientData);

  return (
    <IllumeLayout
      fullheight
      hideHamburger={!authenticated}
      contentProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        },
        className: classes.container,
      }}
    >
      <div className="title">
        <Text
          align="center"
          color={colors.darkText}
          fontSize={{ mobile: rem[1500], desktop: rem[2250] }}
          fontWeight={900}
          className={classes.upperCase}
          lineHeight={rem[3000]}
        >
          THANK YOU, {contributorName?.toUpperCase()}!
        </Text>

        <Text color={colors.darkText} fontWeight={300} align="center">
          You're awesome:) You just added a note to {spelledNames}'s card. We'll let you know if
          they send a reply!
        </Text>

        <Text color={colors.darkText} fontWeight={300} align="center">
          You've now sent{' '}
          {notesSent ? (
            <b>
              {notesSent} {pluralize('note', notesSent)}
            </b>
          ) : (
            <CircularProgress size={18} />
          )}
          . Keep it up!
        </Text>
      </div>

      <div className={classes.navFooter}>
        <RightArrowButton color={colors.primary} onClick={onNext} />
        <ProgressCircle color="primary" step={2} />
      </div>
    </IllumeLayout>
  );
};

export default observer(ThanksPage);
