import React, { forwardRef, useState, useEffect } from 'react';

import { Box, Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { FieldError } from 'react-hook-form';

import { CalendarDatePicker, QuestionMark } from 'components/illume/icons';
import Text from 'components/illume/text/Text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import { dateFormats, colors, rem } from 'constants/design';
import { noop } from 'utils';

import { datePickerStyles, labelStyles } from './DatePicker.styles';

// RFC: Removing tooltip because the datepicker will (and should) always hide the tooltip on mobile.
// We can change this for hover once we get to desktop.

const LabelDatePicker = ({ label, whyButtonText, onClickWhyButton, labelTextProps }: any) => {
  const classes = labelStyles();
  return (
    <Box display="flex" className={classes.label}>
      <label htmlFor="date-picker">
        <Text
          fontSize={{ desktop: rem[1000], mobile: rem[875] }}
          color={colors.labelText}
          fontWeight={500}
          {...labelTextProps}
        >
          {label}
        </Text>
      </label>
      {whyButtonText && (
        <Button onClick={onClickWhyButton} className={classes.button}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box>
              <QuestionMark />
            </Box>
            <Box ml={0.3} pr={1}>
              <Text color={colors.tooltipText} fontSize={rem[750]} decoration="underline">
                {whyButtonText}
              </Text>
            </Box>
          </Box>
        </Button>
      )}
    </Box>
  );
};

export interface DatePickerProps extends Omit<KeyboardDatePickerProps, 'error'> {
  icon: boolean;
  shadow?: boolean;
  error?: Error | FieldError;
  errorMessage?: string;
  tooltip?: string;
  whyButtonText?: string;
  onClickWhyButton?: (e: React.SyntheticEvent) => any;
  onClose?: () => any;
  labelTextProps?: any;
  background?: 'main' | 'secondary';
  compact?: boolean;
  iconProps?: any;
}

const DatePicker = forwardRef<any, DatePickerProps>(
  (
    {
      disablePast,
      icon = false,
      format = dateFormats.US_READABLE,
      label,
      onChange,
      value,
      shadow = false,
      tooltip,
      placeholder,
      error,
      errorMessage,
      whyButtonText,
      onClickWhyButton,
      onClose = noop,
      labelTextProps,
      background = 'main',
      compact,
      iconProps,
      minDateMessage = 'Date should be today or later',
      ...props
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const classes = datePickerStyles({ value, whyButtonText, background, shadow, compact });

    const TOOLTIP_TIMEOUT = 3000;

    useEffect(() => {
      if (showTooltip) {
        const turnOffTooltip = setTimeout(() => {
          setShowTooltip(false);
        }, TOOLTIP_TIMEOUT);
        return () => {
          clearTimeout(turnOffTooltip);
        };
      }
    }, [showTooltip]);

    return (
      <FormControl fullWidth>
        <LabelDatePicker
          labelTextProps={labelTextProps}
          label={label}
          onClickWhyButton={onClickWhyButton}
          whyButtonText={whyButtonText}
        />
        <Tooltip text={tooltip} showTooltip={showTooltip} mb={10}>
          <span>
            <KeyboardDatePicker
              DialogProps={{
                PaperProps: { className: 'date_picker_content' },
                className: 'date_picker_dialog',
              }}
              style={{ width: '100%' }}
              minDateMessage={minDateMessage}
              value={value}
              data-testid="keyboard-picker"
              disablePast={disablePast}
              inputRef={ref}
              onChange={onChange}
              id="date-picker"
              format={format}
              emptyLabel={placeholder}
              InputProps={{
                className: classes.textInput,
                disableUnderline: true,
                readOnly: true,
              }}
              KeyboardButtonProps={{
                'data-testid': 'date-btn',
              }}
              onClick={() => setOpen(true)}
              open={open}
              autoOk={true}
              onClose={() => {
                onClose();
                setOpen(false);
                setShowTooltip(true);
              }}
              keyboardIcon={
                icon ? <CalendarDatePicker size={24} color={colors.gray40} {...iconProps} /> : null
              }
              orientation="portrait"
              {...props}
            />
          </span>
        </Tooltip>
        {error && (
          <Box mt={1}>
            <Text color={colors.alertRed}>
              {error.message || errorMessage || 'please provide a valid input'}
            </Text>
          </Box>
        )}
      </FormControl>
    );
  },
);

export default DatePicker;
