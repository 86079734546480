import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import cx from 'classnames';
import { get } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Box } from 'components/box';
import { BaseIconButton, Button } from 'components/illume/buttons';
import { Info, TrashBin } from 'components/illume/icons';
import ThinPlus from 'components/illume/icons/ThinPlus';
import { Modal } from 'components/illume/modal';
import { Text } from 'components/illume/text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import { colors, rem } from 'constants/design';
import { MAX_RECIPIENT_NUMBER } from 'constants/strings';
import { usePrompContext } from 'contexts/prompt';
import { IIllumeModal } from 'types';
import { noop } from 'utils';
import { isValid } from 'utils/form';

import { TextInput } from '../../../../../../../components/illume/text-inputs';
import { pageStyles } from '../../../../MyCards.styles';
export const FIELDS_NAME = 'recipientList';

type IRecipient = {
  name: string;
  id: string;
};

export type RecipientNameModalFormValues = { recipientList: IRecipient[] };
export interface IRecipientNamesModalProps extends IIllumeModal {
  recipientData: IRecipient[];
  onSave: ({ recipientList }: RecipientNameModalFormValues) => any;
  disableRecipientEdit: boolean;
  canAddMore?: boolean;
}
const RecipientNamesModal: React.FunctionComponent<IRecipientNamesModalProps> = ({
  show = false,
  onSave = noop,
  onClose = noop,
  recipientData: recipientData,
  disableRecipientEdit = false,
  canAddMore = true,
}) => {
  const pageUIStyles = pageStyles();
  // recipientName field-name from the api
  const FIELD_NAME = 'name';
  const PLACEHOLDER_NAMES = ['Jackson', 'Sohale', 'Oona', 'Maxine', 'Andy', 'Phil'];

  const [showAddRecipientTooltip, setShowAddRecipientTooltip] = useState(false);
  const { prompt } = usePrompContext();
  const TooltipText = 'create one group card for multiple individuals.';

  const schema = {
    [FIELDS_NAME]: yup
      .array()
      .of(
        yup.object().shape({
          [FIELD_NAME]: yup.string().required('recipient name is required'),
          id: yup.string().optional().nullable(),
        }),
      )
      .required()
      .max(MAX_RECIPIENT_NUMBER, 'maximum recipients reached'),
  };
  const {
    handleSubmit,
    register,
    control,
    errors,
    formState: { isSubmitted, isSubmitting },
  } = useForm<{ [FIELDS_NAME]: IRecipient[] }>({
    resolver: yupResolver(yup.object().shape(schema)),
    defaultValues: { [FIELDS_NAME]: recipientData },
  });

  const disableSubmitButton = !isValid(errors) && isSubmitted;
  const { fields, append, remove } = useFieldArray({
    control,
    name: FIELDS_NAME,
    keyName: 'reactHookFormId',
  });

  function handleAddRecipient() {
    disableRecipientEdit
      ? prompt({
          title: 'Oops! Card with a gift only supports one recipient!',
          subtitle: 'Currently, our gifting feature only supports one recipient at a time.',
          onOk: noop,
          okText: 'alright',
          onCancel: undefined,
        })
      : append({});
  }
  return (
    <Modal
      show={show}
      onClose={onClose}
      id="edit_recipient_modal"
      contentId="edit_recipient_content"
    >
      <Grid item container direction="column">
        <Grid item>
          <Text
            fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
            align="center"
            fontWeight={900}
            color={colors.gray100}
          >
            EDIT RECIPIENT(S)
          </Text>
        </Grid>
        <form onSubmit={handleSubmit(onSave)}>
          <Box className={cx('customIllumeScrollbar-gray', pageUIStyles.contributorModal)}>
            <Grid container spacing={1} direction="column">
              {fields.map((field, index, fieldsArr) => {
                return (
                  <Grid item key={field.reactHookFormId}>
                    <TextInput
                      label="Recipient Name"
                      error={get(errors, [FIELDS_NAME, index, FIELD_NAME])}
                      name={`${FIELDS_NAME}[${index}].[${FIELD_NAME}]`}
                      placeholder={`e.g. ${PLACEHOLDER_NAMES[index % PLACEHOLDER_NAMES.length]}`}
                      // it is important to use register() instead of register
                      // see: https://spectrum.chat/react-hook-form/help/usefieldarray-append-remove-clearing-form-data~37c65e57-411e-489b-a4dd-2f0df7277954?m=MTU5MjQ4MTQ2NTE4MA==
                      ref={register()}
                      defaultValue={field[FIELD_NAME]}
                      endIcon={
                        fieldsArr.length > 1
                          ? () => (
                              <BaseIconButton
                                icon={TrashBin}
                                iconProps={{ color: colors.gray60 }}
                                onClick={() => remove(index)}
                              />
                            )
                          : undefined
                      }
                    />
                    <input
                      style={{ display: 'none' }}
                      type="text"
                      ref={register()}
                      defaultValue={field.id}
                      name={`${FIELDS_NAME}[${index}].[id]`}
                    />
                  </Grid>
                );
              })}
              {
                // hide the button if reaches maximum number of recipient
                fields.length < MAX_RECIPIENT_NUMBER && canAddMore && (
                  <Grid item container alignItems="center" spacing={1}>
                    <Grid item>
                      <BaseIconButton
                        onClick={handleAddRecipient}
                        style={{ background: colors.backgroundGray, padding: 10 }}
                        size={12}
                        icon={ThinPlus}
                      />
                    </Grid>
                    <Grid item>
                      <Text onClick={handleAddRecipient} color={colors.gray40}>
                        add another recipient
                      </Text>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        text={TooltipText}
                        showTooltip={showAddRecipientTooltip}
                        variant="primary"
                        widthArrow={'10%'}
                        placement="top"
                        size="small"
                        maxWidth={'150px'}
                      >
                        <div
                          onMouseEnter={() => setShowAddRecipientTooltip(true)}
                          onMouseLeave={() => setShowAddRecipientTooltip(false)}
                        >
                          <Info color={colors.gray40}></Info>
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Box>
          <Grid item>
            <Button disabled={disableSubmitButton} loading={isSubmitting} fullWidth>
              Save
            </Button>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

export default RecipientNamesModal;
