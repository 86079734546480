import { useCallback } from 'react';

import { Button } from '@material-ui/core';
import { OptionsObject, useSnackbar } from 'notistack';

import { Close } from 'components/illume/icons';
import { useStyles } from 'components/reusable-forms/note-form/NoteForm.styles';
import { colors } from 'constants/design';
import { AUTO_HIDE_DURATION } from 'constants/strings';
import { noop } from 'utils';

export const useIllumeSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar, ...rest } = useSnackbar();
  const classes = useStyles();
  const enqueueErrorSnackbar = useCallback(
    (message, extraConfig?: OptionsObject) => {
      enqueueSnackbar(message, {
        autoHideDuration: AUTO_HIDE_DURATION,
        variant: 'error',
        ...extraConfig,
      });
    },
    [enqueueSnackbar],
  );

  const enqueueWarningSnackbar = useCallback(
    (message, extraConfig: OptionsObject = {}) => {
      enqueueSnackbar(message, {
        autoHideDuration: AUTO_HIDE_DURATION,
        variant: 'warning',
        ...extraConfig,
      });
    },
    [enqueueSnackbar],
  );

  const enqueueSuccessSnackbar = useCallback(
    (message, extraConfig: OptionsObject = {}) => {
      enqueueSnackbar(message, {
        autoHideDuration: AUTO_HIDE_DURATION,
        variant: 'success',
        ...extraConfig,
      });
    },
    [enqueueSnackbar],
  );

  const enqueueInfoSnackbar = useCallback(
    ({ message, onClick = noop, onClose = noop, buttonText }) => {
      enqueueSnackbar(message, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        persist: true,
        action: (key) => (
          <>
            <Button
              onClick={() => onClick(key)}
              variant="outlined"
              className={classes.skipButton}
              data-testid="skip-button"
            >
              {buttonText}
            </Button>
            <Button
              onClick={() => {
                onClose(key);
                closeSnackbar();
              }}
              className={classes.closeButton}
            >
              <Close size={13} color={colors.contrastText} />
            </Button>
          </>
        ),
      });
    },
    [classes.closeButton, classes.skipButton, closeSnackbar, enqueueSnackbar],
  );

  return {
    enqueueWarningSnackbar,
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar,
    enqueueInfoSnackbar,
    closeSnackbar,
    ...rest,
  };
};
