import Select from 'react-select';

import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';

interface DropdownProps {
  options: any;
  name?: string;
  label?: string;
  id?: string;
  value: any;
  onChange: any;
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  options,
  name,
  label,
  id,
  onChange,
  value,
}) => {
  const customStyles = {
    control: (base: any) => ({
      ...base,
      background: colors.textInputBackground,
      border: 'none',
      borderRadius: 8,
      padding: 6,
      boxShadow: 'none',
      fontWeight: 500,
      fontSize: rem[1000],
      color: colors.darkText,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      paddingLeft: 15,
      background: state.isSelected ? colors.primary : colors.textInputBackground,
      color: state.isSelected ? colors.contrastText : colors.gray40,
      '&:hover': {
        background: colors.primary,
        color: colors.contrastText,
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      top: 27,
      background: colors.textInputBackground,
      borderRadius: '0px 0px 8px 8px',
      paddingBottom: 50,
      height: 260,
      overflow: 'hidden',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: colors.darkText,
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: colors.gray40,
      };
    },
  };

  return (
    <div>
      <label htmlFor={name}>
        <Text
          fontWeight={400}
          color={colors.labelText}
          fontSize={{ mobile: rem[875], desktop: rem[1000] }}
        >
          {label}
        </Text>
      </label>
      <Select
        onChange={onChange}
        id={id}
        name={name}
        options={options}
        value={value}
        styles={customStyles}
        components={{ IndicatorSeparator: () => null }}
      />
    </div>
  );
};

export default Dropdown;
