import { colors } from 'constants/design';

export default function ThinPlus({ size, color = colors.gray40 }) {
  return (
    <svg
      width={(12 / 13) * size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.496 11.66C5.496 11.856 5.664 12.024 5.86 12.024H6.308C6.504 12.024 6.672 11.856 6.672 11.66V6.872H11.46C11.656 6.872 11.824 6.704 11.824 6.508V6.088C11.824 5.892 11.656 5.724 11.46 5.724H6.672V0.879999C6.672 0.683999 6.504 0.543999 6.308 0.543999H5.86C5.664 0.543999 5.496 0.683999 5.496 0.879999V5.724H0.68C0.484 5.724 0.316 5.892 0.316 6.088V6.508C0.316 6.704 0.484 6.872 0.68 6.872H5.496V11.66Z"
        fill={color}
      />
    </svg>
  );
}
