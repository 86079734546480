import { useState, useEffect, CSSProperties } from 'react';

import { NoteDTO } from '@illume/shared';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { isSafari } from 'react-device-detect';

import { GifDisplay } from 'components/illume/gif-display';
import { Modal } from 'components/illume/modal';
import { RightArrowButton, LeftArrowButton } from 'components/illume/nav-arrows';
import PhotoDisplay from 'components/illume/photos/PhotoDisplay';
import { HiddenWords } from 'components/illume/text';
import Text, { TextProps } from 'components/illume/text/Text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { IllumeLayout } from 'components/layouts';
import { IllumeLayoutProps } from 'components/layouts/IllumeLayout';
import {
  generateBackgroundFromPalette,
  generateContrastFromPalette,
} from 'constants/design/cardTheme';
import {
  rem,
  colors,
  generateContrastFromTheme,
  generateColorFromTheme,
  PRIMARY_THEME,
  DEFAULT_PALETTE,
} from 'constants/design/index';
import { useCardContext } from 'contexts/card';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import useClipPathID from 'hooks/useClipPathID';
import { checkFF, FLAGS } from 'utils/featureFlag';

import { noteDisplayStyles } from './NoteDisplay.styles';

interface NoteDisplayContentProps {
  notes: INoteDisplayContentNote[];
  recipientName?: string;
  showPrivacyModal?: boolean;
  onLastNoteClick: () => void;
  noteIndex: number;
  setNoteIndex: React.Dispatch<React.SetStateAction<number>>;
  marginTop?: CSSProperties['marginTop'];
  marginBottom?: CSSProperties['marginBottom'];
  paddingBottom?: CSSProperties['paddingBottom'];
  paddingLeft?: CSSProperties['paddingLeft'];
  paddingRight?: CSSProperties['paddingRight'];
  paddingTop?: CSSProperties['paddingTop'];
  showNavigationButtons: boolean;
  messageFontSize?: TextProps['fontSize'];
  textBoxMaxHeight?: CSSProperties['maxHeight'];
  mediaSize?: number;
  gifSize?: number;
  lastNoteTooltipText?: string;
}

export interface INoteDisplayContentNote {
  redacted: boolean;
  length: number;
  theme: string;
  message: string;
  signature: string;
  photos: { url: string }[];
  videos: { url: string }[];
  gifs: { url: string }[];
  palette: string;
}

export const NoteDisplayContent = ({
  notes,
  recipientName,
  showPrivacyModal: initialPrivacyModalState = false,
  onLastNoteClick,
  noteIndex,
  setNoteIndex,
  marginTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  showNavigationButtons,
  messageFontSize = { mobile: rem[1750], desktop: rem[2250] },
  marginBottom,
  textBoxMaxHeight,
  mediaSize,
  gifSize,
  lastNoteTooltipText,
}: NoteDisplayContentProps) => {
  const note = notes[noteIndex];
  const [showTooltipNext, setShowTooltipNext] = useState(false);
  const [showTooltipPrevious, setShowTooltipPrevious] = useState(false);
  const [showTooltipRedacted, setShowTooltipRedacted] = useState(false);
  const [hasShownTooltipRedacted, setHasShownTooltipRedacted] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(initialPrivacyModalState);

  const hasPreviousNote = noteIndex > 0;
  const {
    redacted,
    length,
    theme = PRIMARY_THEME,
    message = '',
    signature,
    photos = [],
    videos = [],
    gifs = [],
    palette: paletteCode,
  } = note || {};

  const { getPaletteDto: getPalette } = useCardContext();
  const palette = getPalette(paletteCode);
  // const background = generateBackgroundFromPalette(palette, theme, {});
  const background = undefined;

  const classes = noteDisplayStyles({
    marginTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    background,
    paddingTop,
    marginBottom,
    textBoxMaxHeight,
  });
  const tooltipText = ['tap to read the next one', 'tap to read the previous one'][noteIndex] || '';
  const TOOLTIP_TIMEOUT = 3000;
  const isNoteMultiple = notes?.length > 1;
  const appTheme = useTheme();
  const isDesktop = useMediaQuery(appTheme.breakpoints.up('md'));
  const mediaPreviewSize = mediaSize || isDesktop ? 348 : 264;
  const gifMediaPreviewSize = gifSize || isDesktop ? 300 : 225;
  const safariVideoPreviewSize = 300;
  const redactedInfo = `In order to protect the privacy of each note, you're seeing a redacted version of this
  note. Trust us, the messages will all be there when you share it with ${recipientName}!`;
  const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateContrastFromPalette(palette, theme)
    : generateContrastFromTheme(theme);
  const [nextBtnHover, setNextBtnHover] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [noteIndex]);

  useEffect(() => {
    if (isNoteMultiple && tooltipText && !showPrivacyModal) {
      if (noteIndex === 0) {
        setShowTooltipPrevious(false);
        setShowTooltipNext(true);
      }
      if (noteIndex === 1) {
        setShowTooltipNext(false);
        setShowTooltipPrevious(true);
      }
    }
    const turnOffTooltip = setTimeout(() => {
      setShowTooltipNext(false);
      setShowTooltipPrevious(false);
    }, TOOLTIP_TIMEOUT);
    return () => {
      clearTimeout(turnOffTooltip);
    };
  }, [tooltipText, showPrivacyModal, isNoteMultiple, noteIndex]);

  useEffect(() => {
    if (redacted && !hasShownTooltipRedacted) {
      setShowTooltipRedacted(true);
      setHasShownTooltipRedacted(true);
    }
    const turnOffTooltip = setTimeout(() => {
      setShowTooltipRedacted(false);
    }, TOOLTIP_TIMEOUT);
    return () => {
      clearTimeout(turnOffTooltip);
    };
  }, [redacted, hasShownTooltipRedacted]);

  const handlePreviousClick = () => {
    setNoteIndex((prev) => prev - 1);
  };
  const handleNextClick = async () => {
    if (noteIndex === notes.length - 1) {
      onLastNoteClick();
      return;
    }
    setNoteIndex((prev) => prev + 1);
  };

  const showTooltip = () => {
    setShowTooltipRedacted(true);
  };

  const hideTooltip = () => {
    setShowTooltipRedacted(false);
  };

  const [photo, video, gif] = [photos[0], videos[0], gifs[0]];
  const isMediaExists = photo || video || gif;
  const videoClipPathId = useClipPathID();
  const imageClipPathId = useClipPathID();

  return (
    <>
      <Grid className={classes.container}>
        <Grid container justifyContent="center">
          {isMediaExists && (
            <Box mt={-5} mb={6}>
              {photo ? (
                <PhotoDisplay
                  imgUrl={photos[0].url}
                  size={mediaPreviewSize}
                  color={contrastColor}
                  redacted={redacted}
                  clipPathID={imageClipPathId}
                />
              ) : video ? (
                <VideoDisplay
                  vidUrl={video.url}
                  size={isSafari ? safariVideoPreviewSize : mediaPreviewSize}
                  color={contrastColor}
                  redacted={redacted}
                  clipPathId={videoClipPathId}
                />
              ) : gif ? (
                <GifDisplay
                  giphyCode={gif.url}
                  size={gifMediaPreviewSize}
                  color={contrastColor}
                  redacted={redacted}
                />
              ) : null}
            </Box>
          )}
        </Grid>
        <Grid className={classes.textBox}>
          {redacted ? (
            <>
              <Tooltip
                variant="other"
                text={redactedInfo}
                showTooltip={showTooltipRedacted}
                placement="top"
                widthArrow={'10%'}
              >
                <div
                  onClick={() => setShowTooltipRedacted((prev) => !prev)}
                  onPointerEnter={showTooltip}
                  onPointerLeave={hideTooltip}
                >
                  <HiddenWords charLength={length} isSmall={undefined} />
                </div>
              </Tooltip>
            </>
          ) : (
            <Text
              className="fs-mask"
              wordBreak="break-word"
              fontSize={messageFontSize}
              color={contrastColor}
              fontWeight={700}
            >
              {message.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </Text>
          )}
        </Grid>
        <Grid className={classes.senderBox}>
          <Text
            wordBreak="break-word"
            fontSize={{ mobile: rem[1750], desktop: rem[2250] }}
            fontWeight={900}
            data-testid="sender-text"
          >
            {signature || 'anonymous'}
          </Text>
        </Grid>
        <Grid container justifyContent="center" className={classes.buttonBox}>
          {showNavigationButtons && (
            <>
              {hasPreviousNote && (
                <Grid item>
                  <Tooltip
                    variant={'other'}
                    text={tooltipText}
                    showTooltip={showTooltipPrevious}
                    placement="top"
                    size="small"
                    widthArrow={'10%'}
                  >
                    <div>
                      <LeftArrowButton
                        inverted
                        color={colors.contrastText}
                        onClick={handlePreviousClick}
                        data-testid="left-arrow-button"
                      />
                    </div>
                  </Tooltip>
                </Grid>
              )}
              {lastNoteTooltipText && noteIndex === notes.length - 1 ? (
                <Grid
                  item
                  onMouseEnter={() => setNextBtnHover(true)}
                  onMouseLeave={() => setNextBtnHover(false)}
                >
                  <Tooltip
                    variant={'other'}
                    text={lastNoteTooltipText}
                    showTooltip={nextBtnHover}
                    placement="top"
                    size="small"
                    widthArrow={'10%'}
                  >
                    <div>
                      <RightArrowButton
                        onClick={handleNextClick}
                        data-testid="right-arrow-button"
                      />
                    </div>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item>
                  <Tooltip
                    variant={'other'}
                    text={tooltipText}
                    showTooltip={showTooltipNext}
                    placement="top"
                    size="small"
                    widthArrow={'10%'}
                  >
                    <div>
                      <RightArrowButton
                        onClick={handleNextClick}
                        data-testid="right-arrow-button"
                      />
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {isNoteMultiple && (
          <Grid className={classes.pageNumberBox}>
            <Text> {`${noteIndex + 1}/${notes?.length}`}</Text>
          </Grid>
        )}
      </Grid>
      <Modal
        show={showPrivacyModal}
        title="HEY THERE!"
        onClose={() => setShowPrivacyModal(false)}
        promptConfig={{
          onOk: () => setShowPrivacyModal(false),
          okText: 'alright',
        }}
      >
        <Text align="center" fontWeight={300}>
          {redactedInfo}
        </Text>
      </Modal>
    </>
  );
};
interface INoteDisplay {
  hideHamburger: boolean;
  notes: INoteDisplayContentNote[];
  recipientName: string;
  showPrivacyModal: boolean;
  onLastNoteClick: () => void;
  topRightButtons?: IllumeLayoutProps['topRightButtons'];
  index: number;
  marginTop?: number;
  showNavigationButtons: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
  lastNoteTooltipText?: string;
}
const NoteDisplay = ({
  hideHamburger,
  notes = [],
  recipientName,
  showPrivacyModal,
  onLastNoteClick,
  index = 0,
  marginTop,
  showNavigationButtons,
  contentRef,
  topRightButtons,
  lastNoteTooltipText,
}: INoteDisplay) => {
  const [noteIndex, setNoteIndex] = useState(index);
  const { theme = PRIMARY_THEME, palette: paletteCode = DEFAULT_PALETTE.paletteCode } =
    notes[noteIndex] || {};
  const { getPaletteDto: getPalette } = useCardContext();
  const palette = getPalette(paletteCode);
  const desktop = useIsDesktop();

  const mainColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateBackgroundFromPalette(palette, theme, { isMobile: !desktop })
    : generateColorFromTheme(theme);

  return (
    <IllumeLayout
      background={mainColor}
      hideHamburger={hideHamburger}
      topRightButtons={topRightButtons}
      contentProps={{ style: { flex: 1 } }}
      containerProps={{ innerRef: contentRef }}
    >
      <NoteDisplayContent
        recipientName={recipientName}
        marginTop={marginTop}
        notes={notes}
        noteIndex={noteIndex}
        setNoteIndex={setNoteIndex}
        showPrivacyModal={showPrivacyModal}
        onLastNoteClick={onLastNoteClick}
        showNavigationButtons={showNavigationButtons}
        lastNoteTooltipText={lastNoteTooltipText}
      />
    </IllumeLayout>
  );
};

export const notesFromDto = (notes: NoteDTO[]): INoteDisplayContentNote[] => {
  return notes.map((dto) => ({
    length: dto.length,
    message: dto.message,
    palette: dto.palette,
    photos: dto.photos.map((photo) => ({ url: photo.deliveryUrl })),
    redacted: dto.redacted,
    signature: dto.signature,
    theme: dto.theme,
    videos: dto.videos.map((video) => ({ url: video.deliveryUrl })),
    gifs: dto.gifs.map((gif) => ({
      url: gif.giphyCode,
    })),
  }));
};

export default NoteDisplay;
