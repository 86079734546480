import { RouterStore } from '@superwf/mobx-react-router';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { autorun, makeAutoObservable, observable } from 'mobx';
import qs from 'query-string';
import { generatePath } from 'react-router';

import {
  MY_CARDS_URL,
  RECEIVE_READ_URL,
  RECEIVE_SHIP_URL,
  RECEIVE_CELEBRATORY_URL,
  SLACK_TEAMS_CARDS_URL,
} from 'constants/strings';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { GiftMerchantProduct } from 'domain/entities/gift/gift';
import { IShippingService } from 'domain/interfaces/IShippingService';
import cardServiceV2 from 'infra/card-service/cardService';
import { ShopifyShippingInfo, GiftV2Dto, GiftV2ErrorDTO, RecipientCardV2Dto } from 'types';
import { isGift } from 'types/guard';
import { pluralize } from 'utils';
import logger from 'utils/logger';
import { formatPhoneNumber } from 'utils/phoneNumber';
import { spellNames } from 'utils/string';

import { ShopifyShippingInfoOnlyEmail } from '../ShippingFormEmailOnly';

type SearchParamsObject = {
  phoneNumber: string;
  searchInviteCode: string;
  inviteCode: string;
  c: string;
  email: string;
};

export class RecipientStore {
  /**
   * TODO: don't use directly in app component!
   */
  @observable _recipientCardDto?: RecipientCardV2Dto;

  get card() {
    if (!this._recipientCardDto) {
      return undefined;
    }
    const {
      id,
      notes,
      replies,
      recipientData,
      cardReplyContext,
      themepack,
      giftV2,
      externalGift,
      //@ts-expect-error
      workspaceUrl,
    } = this._recipientCardDto;

    return {
      id,
      notes,
      replies,
      recipientData,
      cardReplyContext,
      themepack,
      giftV2,
      externalGift,
      workspaceUrl,
    };
  }

  get giftV2MetGoal() {
    const gift = this.cardGift;
    if (!gift) {
      return false;
    }
    return gift?.financial?.current >= gift?.financial.goal;
  }

  get externalGiftMetGoal() {
    const gift = this.card?.externalGift;

    if (!gift) {
      return false;
    }

    return gift.financial.current >= gift.financial.goal;
  }

  get externalGift() {
    return this.card?.externalGift && ExternalGift.fromDto(this.card.externalGift);
  }

  get cardListUrl() {
    return this.card?.workspaceUrl
      ? generatePath(SLACK_TEAMS_CARDS_URL, { workspaceUrl: this.card.workspaceUrl })
      : MY_CARDS_URL;
  }

  setCard = (card: RecipientCardV2Dto) => {
    this._recipientCardDto = card;
  };

  patchRecipientCardDto = (card: any) => {
    this._recipientCardDto = {
      ...this._recipientCardDto,
      ...card,
    };
  };

  get routes() {
    const { email, c: sendCode } = qs.parse(this.location.search) || {};
    return {
      [RECEIVE_READ_URL]: {
        pathname: RECEIVE_SHIP_URL,
      },
      [RECEIVE_SHIP_URL]: {
        pathname: RECEIVE_CELEBRATORY_URL,
        search: qs.stringify({ c: sendCode }),
      },
      [RECEIVE_CELEBRATORY_URL]: {
        pathname: `${this.cardListUrl}/received/${sendCode}`,
        search: qs.stringify({ c: sendCode, email: email }),
      },
    };
  }

  get cardGift(): GiftV2Dto | undefined {
    return isGift(this._recipientCardDto?.giftV2) ? this._recipientCardDto?.giftV2 : undefined;
  }

  get giftMerchantProduct() {
    return this.cardGift && new GiftMerchantProduct(this.cardGift.product);
  }

  get giftError(): GiftV2ErrorDTO | undefined {
    return !isGift(this._recipientCardDto?.giftV2) ? this._recipientCardDto?.giftV2 : undefined;
  }

  get location() {
    return this.routerStore.location;
  }
  get history() {
    return this.routerStore.history;
  }
  get initializing() {
    return this.urlSendcode && !this._recipientCardDto && !this.getCardTask.error;
  }

  get initError() {
    return this.getCardTask.error;
  }

  get refetching() {
    return this.getCardTask.isExecuting && this._recipientCardDto;
  }

  get urlSendcode() {
    const res = qs.parse(this.location.search).c;
    console.log('res', res);
    return res;
  }
  get cardCode() {
    // not sure if `viewCode` exists
    // return this.card?.viewCode || this.card?.code || this.urlViewCode;
    return this._recipientCardDto?.id || (this.urlSendcode as string);
  }

  get nextRoute() {
    return this.routes[this.location.pathname as keyof typeof this.routes];
  }

  get searchParams(): SearchParamsObject {
    return qs.parse(this.history.location.search) as SearchParamsObject;
  }

  get contactMethod() {
    const { email: recipientEmail, phoneNumber } = this.searchParams;

    return recipientEmail
      ? { email: recipientEmail }
      : phoneNumber && { phoneNumber: formatPhoneNumber(Number(phoneNumber)) };
  }

  pushToNext = (arg?: { params: string[] }) => {
    const { params } = arg || {};
    if (this.nextRoute) {
      const { pathname, ...rest } = this.nextRoute;
      this.history.push({
        search: qs.stringify({ viewCode: this.cardCode }),
        pathname: params ? pathname.concat('/', params.join('/')) : pathname,
        ...rest,
      });
    } else {
      return console.error('oops! no next route configured');
    }
  };

  goBack = () => this.history.back();

  skipStep = () => {
    return this.history.push(this.nextRoute);
  };

  getCardTask = new ObservablePromise((code) =>
    cardServiceV2.getReceivedCard(code).then((card) => {
      this.setCard(card);
    }),
  );

  get otherNoteSenderCount() {
    if (!this._recipientCardDto?.contributorCount) {
      return 0;
    }
    return this._recipientCardDto.contributorCount - 1;
  }

  get contributorSummary() {
    const { contributorCount, initiatorName } = this._recipientCardDto || {};
    const noteSender =
      initiatorName === undefined || initiatorName === '(anonymous)' ? 'Someone' : initiatorName;

    const singleContributorText = `${noteSender} shared appreciation.`;
    const multipleContributorsText = `${noteSender} + ${
      this.otherNoteSenderCount
    } other ${pluralize(
      'individual',
      this.otherNoteSenderCount,
    )} came together to share their appreciation.`;
    let text = contributorCount === 1 ? singleContributorText : multipleContributorsText;

    // Override it for the illume team account. Using name not ID because that's all the info we have in this scope
    if (noteSender === 'the illume team' && contributorCount === 1)
      text = 'the illume team shared their appreciation';

    return text;
  }

  get spelledNames() {
    return spellNames(this._recipientCardDto?.recipientData || []);
  }

  refetchCard = () => this.getCardTask.execute(this.cardCode).catch();

  constructor(private routerStore: RouterStore, private shippingService: IShippingService) {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
      if (this.urlSendcode) {
        this.getCardTask.execute(this.urlSendcode).catch();
      }
    });
  }

  saveShippingInfoTask = new ObservablePromise(
    async (code: string, payload: ShopifyShippingInfo | ShopifyShippingInfoOnlyEmail) => {
      logger.log('updating shipping info..');
      const service = this.card?.externalGift
        ? cardServiceV2.saveExternalGiftShippingInfo
        : this.shippingService.saveShippingInfo;
      return service(code, payload);
    },
  );

  getTrackingShipmentInfoTask = new ObservablePromise(async (code: string) => {
    logger.log('tracking shipment info..');
    const response = await this.shippingService.getProductTrackingInfo(code);
    const { fulfillments } = response;
    return fulfillments[0].tracking_url;
  });
}
