import { GiftV2Dto, Maybe } from 'types';

function isGift(object: Maybe<{ id: string } | { errType: string }>): object is GiftV2Dto {
  if (!object) {
    return false;
  }
  return (object as GiftV2Dto).id !== undefined;
}

export { isGift };
