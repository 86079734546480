import { RouterStore } from '@superwf/mobx-react-router';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { isEmpty } from 'lodash';
import { autorun, makeAutoObservable, observable, when } from 'mobx';
import qs from 'query-string';
import { generatePath } from 'react-router-dom';

import { DEFAULT_THEMEPACK } from 'constants/design/cardTheme';
import {
  CONTRIBUTOR_ADD_NOTE_URL,
  CONTRIBUTOR_CELEBRATE_MORE_REMIND,
  CONTRIBUTOR_CELEBRATE_MORE_OCCASION,
  CONTRIBUTOR_GIFT_URL,
  CONTRIBUTOR_REPLY_URL,
  CONTRIBUTOR_SELECT_THEME_URL,
  CONTRIBUTOR_THANKS_URL,
  CONTRIBUTOR_WELCOME_URL,
  INITIATOR_BASIC_INFO_URL as INITIATOR_BASIC_INFO_URL,
  SLACK_TEAMS_CARDS_URL,
  MY_CARDS_URL,
} from 'constants/strings';
import ThemepacksStore from 'contexts/card/store';
import AuthenticationStore from 'contexts/store/auth-store/auth.store';
import { UserProfileStore } from 'contexts/user';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { INoteService } from 'domain/interfaces/INoteService';
import { shootConfetti } from 'hooks/useConfettiCanon';
import cardServiceV2 from 'infra/card-service/cardService';
import { ContributorCardDTO } from 'types';
import { isGift } from 'types/guard';
import { toCent } from 'utils/currency';
import logger from 'utils/logger';
import { formatPhoneNumber } from 'utils/phoneNumber';

import AddNoteStore from './AddNote.store';

type SearchParamsObject = {
  phoneNumber: string;
  searchInviteCode: string;
  inviteCode: string;
  c: string;
  email: string;
};

/**
 * form related data that we want to persist
 */
export class ContributionForm {
  rawDollarValue?: string;
  userData: {
    name?: string;
    email?: string;
  } = {};
  constructor(userProfileStore: UserProfileStore) {
    makeAutoObservable(this);
    when(
      () => userProfileStore.fetchUserTask.wasExecuted,
      () => {
        if (userProfileStore.email && userProfileStore.firstName) {
          this.setUserData({
            email: userProfileStore.email,
            name: userProfileStore.firstName,
          });
        }
      },
    );
    const urlEmail = new URLSearchParams(window.location.search).get('email');
    if (urlEmail) {
      this.setUserData({ email: urlEmail });
    }
  }
  setDollar = (value: string) => {
    this.rawDollarValue = value;
  };

  setUserData = (value: { name?: string; email?: string }) => {
    this.userData = { ...this.userData, ...value };
  };

  resetContribution() {
    this.rawDollarValue = '';
  }

  get cents() {
    // raw data only accept integer for now
    // but just future proofing
    if (!this.rawDollarValue) {
      return undefined;
    }
    return toCent(this.rawDollarValue);
  }
}

export type ContributorCard = Pick<ContributorCardDTO, 'recipientData'>;

export default class ContributorStore {
  @observable private _contributorCardV2Dto?: ContributorCardDTO = undefined;

  get cardDto() {
    return this._contributorCardV2Dto;
  }

  private get _authenticated() {
    return this._authenticationStore.authenticated;
  }

  formStore: ContributionForm;

  setCard = (value: ContributorCardDTO) => {
    this._contributorCardV2Dto = value;
  };

  get card() {
    if (!this._contributorCardV2Dto) {
      return undefined;
    }

    const {
      recipientData: recipientData,
      deadline,
      initiatorName,
      inviteCode,
      occasionName,
      giftV2,
      introMessage,
      notes,
      userContext,
      themepack,
      externalGift,
      scheduledTimestamp,
    } = this._contributorCardV2Dto;

    return {
      externalGift,
      recipientData: recipientData,
      deadline,
      initiatorName,
      inviteCode,
      occasionName,
      giftV2,
      introMessage,
      notes,
      userContext,
      themepackCode: themepack,
      scheduledTimestamp,
    };
  }

  get externalGift() {
    return (
      this._contributorCardV2Dto?.externalGift &&
      ExternalGift.fromDto(this._contributorCardV2Dto.externalGift)
    );
  }

  get cardGift() {
    return this?._contributorCardV2Dto?.giftV2;
  }

  get recipientData() {
    return this._contributorCardV2Dto?.recipientData;
  }

  get hasCard() {
    return !!this._contributorCardV2Dto;
  }

  get myNotes() {
    const myNoteCodes = this._contributorCardV2Dto?.userContext.noteCodes;
    if (isEmpty(myNoteCodes)) {
      return undefined;
    }
    return (
      this._contributorCardV2Dto?.notes
        .filter((note) => myNoteCodes?.includes(note.viewCode))
        .map((note) => {
          return { ...note, palette: this.themepackStore.getPaletteDto(note.palette) };
        }) || []
    );
  }

  get themepack() {
    const themepackCode = this._contributorCardV2Dto?.themepack;
    return themepackCode ? this.themepackStore.getThemepack(themepackCode) : DEFAULT_THEMEPACK;
  }

  get routes() {
    return {
      [CONTRIBUTOR_WELCOME_URL]: {
        pathname: CONTRIBUTOR_GIFT_URL,
      },
      [CONTRIBUTOR_GIFT_URL]: {
        pathname: CONTRIBUTOR_WELCOME_URL,
      },
      [CONTRIBUTOR_ADD_NOTE_URL]: {
        pathname: CONTRIBUTOR_SELECT_THEME_URL,
      },
      [CONTRIBUTOR_SELECT_THEME_URL]: {
        pathname: this._authenticated ? CONTRIBUTOR_THANKS_URL : CONTRIBUTOR_REPLY_URL,
      },
      [CONTRIBUTOR_THANKS_URL]: {
        pathname: CONTRIBUTOR_CELEBRATE_MORE_OCCASION,
      },
      [CONTRIBUTOR_CELEBRATE_MORE_OCCASION]: {
        pathname: INITIATOR_BASIC_INFO_URL,
      },
      [CONTRIBUTOR_CELEBRATE_MORE_REMIND]: {
        pathname: this.cardListUrl,
      },
      [CONTRIBUTOR_REPLY_URL]: {
        pathname: CONTRIBUTOR_CELEBRATE_MORE_OCCASION,
      },
    };
  }

  // TODO: edge case:
  // from refresh
  backFromAddNote() {
    return this.history.back();
  }
  backFromTheme() {
    return this.history.back();
  }
  get nextRoute() {
    return this.routes[this.history.location.pathname as keyof typeof this.routes];
  }

  get cardCode() {
    return this._contributorCardV2Dto?.inviteCode || this.searchInviteCode;
  }

  get cardListUrl() {
    return this._contributorCardV2Dto?.workspaceUrl
      ? generatePath(SLACK_TEAMS_CARDS_URL, {
          workspaceUrl: this._contributorCardV2Dto.workspaceUrl,
        })
      : MY_CARDS_URL;
  }

  pushToNext = (arg?: { params?: string[]; searchObj?: Record<string, boolean | string> }) => {
    const { params, searchObj } = arg || {};
    if (this.nextRoute) {
      logger.log('pushing to next..', this.nextRoute);
      const { pathname } = this.nextRoute;
      this.history.push({
        search: qs.stringify({ inviteCode: this.cardCode, ...searchObj }),
        pathname: params && pathname ? pathname.concat('/', params.join('/')) : pathname,
      });
    } else {
      return console.error('oops! no next route configured');
    }
  };

  resetAddNoteFlow() {
    this.formStore.resetContribution();
    this.addnoteStore = new AddNoteStore(this, this.themepackStore, this.noteService);
  }

  addnoteStore;
  constructor(
    private routerStore: RouterStore,
    private themepackStore: ThemepacksStore,
    private userProfileStore: UserProfileStore,
    private _authenticationStore: AuthenticationStore,
    private noteService: INoteService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.formStore = new ContributionForm(this.userProfileStore);
    this.addnoteStore = new AddNoteStore(this, themepackStore, this.noteService);

    autorun(() => {
      if (this.searchInviteCode) {
        this.getCardTask.execute(this.searchInviteCode).catch();
      }
    });
  }
  get history() {
    return this.routerStore;
  }
  get searchParams(): SearchParamsObject {
    return qs.parse(this.history.location.search) as SearchParamsObject;
  }
  get searchInviteCode(): string {
    const { c: inviteCodeInUUID, inviteCode: inviteCodeThatStartsWith_i } = this.searchParams;
    return inviteCodeInUUID || inviteCodeThatStartsWith_i;
  }

  get contactMethod() {
    const { email: contributorEmail, phoneNumber } = this.searchParams;

    return contributorEmail
      ? { email: contributorEmail }
      : phoneNumber && { phoneNumber: formatPhoneNumber(Number(phoneNumber)) };
  }

  getCardTask = new ObservablePromise((code) =>
    cardServiceV2.getContributorCard(code).then((card) => {
      this.setCard(card);
    }),
  );

  get initializing() {
    return this.searchInviteCode && !this.getCardTask.wasExecuted;
  }

  refetchCard = () => {
    if (!this._contributorCardV2Dto?.inviteCode) {
      return;
    }
    return this.getCardTask.execute(this._contributorCardV2Dto.inviteCode).catch();
  };

  //TODO: Event handler should not belong here
  handleSuccessfulPayment = async (dollarAmount: number) => {
    // mimic optimistic refetching
    if (this._contributorCardV2Dto?.giftV2 && isGift(this._contributorCardV2Dto.giftV2)) {
      this._contributorCardV2Dto.giftV2.yourContribution = dollarAmount;
    }
    if (this.card?.externalGift) {
      this.card.externalGift.yourContribution = dollarAmount;
    }
    await this.refetchCard();
    this.pushToNext();
    shootConfetti({});
  };
}
