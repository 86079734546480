import { useRef } from 'react';

import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';

import Button from 'components/illume/buttons/Button';
import { Pencil, Blob } from 'components/illume/icons';
import { PhotoUploader } from 'components/illume/photo-uploader';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { noop } from 'utils';

const useStyles = makeStyles((theme) => ({
  letter: {
    zIndex: 1000,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  blob: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: ({ size }) => size,
    height: ({ size }) => size,
  },
  root: {
    position: 'relative',
    width: ({ size }) => size,
    height: ({ size }) => size,
  },
  icon: { position: 'relative' },
  button: { position: 'absolute', bottom: 15, zIndex: 1000 },
  avatarButton: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    width: theme.spacing(20),
  },
  uploadcareWidgetContainer: {
    display: 'none',
  },
}));

const AvatarInitialName = ({ size, name }) => {
  const classes = useStyles({ size });
  return (
    <Grid item className={classes.root}>
      <Box className={classes.letter}>
        <Text color={colors.contrastText} align="center" fontWeight={700} fontSize="8rem">
          {name ? name[0].toUpperCase() : 'i'}
        </Text>
      </Box>
      <Box width={225} className={classes.blob}>
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, repeatType: 'loop', duration: 40, ease: 'linear' }}
        >
          <Blob />
        </motion.div>
      </Box>
    </Grid>
  );
};

export const AvatarPicture = ({
  size,
  imgUrl,
  editable,
  shadow = true,
  flipVertically = false,
}) => {
  return (
    <Grid item>
      <svg
        width={size}
        height={size}
        viewBox="0 0 229 224"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="user-space" clipPathUnits="userSpaceOnUse">
            <path
              // flip vertically
              transform={flipVertically ? 'scale(1, -1) translate(0, -224)' : undefined}
              d="M0.0468707 135.572C-0.942155 155.605 13.788 176.939 37.8824 193.134C63.597 210.419 92.1104 217.404 122.833 215.411C140.552 214.278 157.239 209.454 171.254 197.853C180.365 190.323 187.415 181.072 193.496 171.003C204.228 153.256 213.214 134.754 218.285 114.511C225.313 86.4858 218.453 61.7538 200.525 39.7488C181.733 16.6949 156.755 4.98972 127.652 1.12993C107.304 -1.57613 87.481 0.563538 68.2265 7.69576C44.7214 16.4013 27.8448 32.4907 17.218 54.8523C6.61232 77.1301 0.825465 100.687 0.0468707 135.572Z"
              fill="#FEB81C"
            />
          </clipPath>
        </defs>
        {shadow && (
          <path
            d="M0.0468707 135.572C-0.942155 155.605 13.788 176.939 37.8824 193.134C63.597 210.419 92.1104 217.404 122.833 215.411C140.552 214.278 157.239 209.454 171.254 197.853C180.365 190.323 187.415 181.072 193.496 171.003C204.228 153.256 213.214 134.754 218.285 114.511C225.313 86.4858 218.453 61.7538 200.525 39.7488C181.733 16.6949 156.755 4.98972 127.652 1.12993C107.304 -1.57613 87.481 0.563538 68.2265 7.69576C44.7214 16.4013 27.8448 32.4907 17.218 54.8523C6.61232 77.1301 0.825465 100.687 0.0468707 135.572Z"
            fill="#FECD60"
            transform="translate(7, 9)"
          />
        )}
        <image
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          xlinkHref={imgUrl}
          clipPath="url(#user-space)"
        />
        {editable && (
          <path
            d="M8.5 164.5C19.6382 179.889 22.938 181.497 35 191C60.6844 208.309 80.6784 213.996 101.573 215.008C130.5 215.5 137.5 215.008 157.5 206.5C173 199.906 185.341 184.492 190 177C194.659 169.508 195 169 197.5 164C124.418 164.876 81.5824 163.623 8.5 164.5Z"
            fill="black"
            fillOpacity="0.75"
          />
        )}
      </svg>
    </Grid>
  );
};

/**
 *  Avatar component
 * @param {object} props
 * @param {string} props.name - name of the user
 * @param {number} [props.size=225] - size of the avatar
 * @param {string} [props.imgUrl] - url of the avatar image
 * @param {boolean} [props.editable=false] - whether the avatar is editable
 * @param {function} [props.onFileSelect=noop] - callback function when file is selected
 * @param {function} [props.beforeFileSelect=noop] - callback function before file is selected
 * @param {function} [props.onError] - callback function when error occurs
 * @param {boolean} [props.loading] - whether the avatar is loading
 * @returns {JSX.Element}
 */
export const Avatar = ({
  name,
  size = 225,
  imgUrl,
  editable = false,
  onFileSelect = noop,
  beforeFileSelect = noop,
  onError,
  loading,
}) => {
  const classes = useStyles({ size });
  const widgetRef = useRef(null);
  const buttonText = imgUrl ? 'edit photo' : 'upload photo';

  const handleUploadPhotoClick = () => {
    widgetRef.current.openDialog();
  };

  const renderPicture = () => {
    if (imgUrl) {
      return <AvatarPicture editable={editable} size={size} imgUrl={imgUrl} />;
    } else {
      return <AvatarInitialName name={name} size={225} />;
    }
  };

  return (
    // TODO: it seems like there's better way to do this without editing the svg manually
    <Grid container alignItems="center" direction="column" className={classes.icon}>
      {loading ? (
        <CircularProgress size={128} />
      ) : (
        <>
          {renderPicture()}
          {editable && (
            <Grid item className={classes.button}>
              <Button className={classes.avatarButton} onClick={handleUploadPhotoClick}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Pencil showPerson size={20} style={{ margin: 0 }} />
                  </Grid>
                  <Grid item>
                    <Text color={colors.contrastText}>&nbsp; {buttonText}</Text>
                  </Grid>
                  <Grid item className={classes.uploadcareWidgetContainer}>
                    <PhotoUploader
                      beforeFileSelect={beforeFileSelect}
                      ref={widgetRef}
                      onFileSelect={onFileSelect}
                      onError={onError}
                    />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
