import { CircularProgress } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';

import { RightArrow } from 'components/illume/icons';
import { colors } from 'constants/design';

const useStyles = makeStyles(() => ({
  root: {
    '&:focus': { outline: 'none' },
    '&:hover': { background: 'none' },
    padding: 0,
  },
}));

interface RightArrowButtonProps extends Omit<ButtonProps, 'color' | 'size'> {
  loading?: boolean;
  loadingColor?: string;
  color?: string;
  arrowColor?: string;
  size?: number;
}

const RightArrowButton: React.FC<RightArrowButtonProps> = ({
  onClick,
  classes: propClasses,
  color = colors.contrastText,
  arrowColor,
  disabled = false,
  onMouseEnter,
  loading = false,
  loadingColor,
  size = 60,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      disableRipple
      classes={{ ...classes, ...propClasses }}
      onClick={onClick}
      {...props}
    >
      {loading ? (
        <CircularProgress style={{ color: loadingColor || color }} />
      ) : (
        <motion.button onMouseEnter={onMouseEnter} whileTap={{ scale: disabled ? 1 : 0.8 }}>
          <RightArrow
            blobColor={color}
            arrowColor={arrowColor}
            size={size}
            fillOpacity={disabled ? 0.6 : 1}
          />
        </motion.button>
      )}
    </Button>
  );
};

export default RightArrowButton;
