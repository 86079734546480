import { loadStripe } from '@stripe/stripe-js';

import config from 'config';
import logger from 'utils/logger';

import illumeApiHttpClient from '../infra/client';
import { track } from './analytics';

type SupportedCurrencies = 'usd';
export interface SendPaymentIntentProps {
  currency?: SupportedCurrencies;
  amount: number;
  giftId: string;
  receiptEmail: string;
}
export interface SetupIntentProps {
  currency?: SupportedCurrencies;
  amount: number;
  identifier: string;
  receiptEmail: string;
}

const setupIntent: (props: SetupIntentProps) => Promise<string> = async ({
  currency = 'usd',
  amount,
  identifier: cardGiftId,
  receiptEmail,
}) => {
  if (!cardGiftId) throw Error('gift id needs to be included for payment intent');
  if (!receiptEmail) throw Error('receiptEmail needs to be included for payment intent');
  const reqObject = {
    url: '/payment/setupIntent',
    method: 'POST',
    data: { currency, amount, cardGiftId, receiptEmail },
  };

  const resp = await illumeApiHttpClient.makeRequest<{ clientSecret: string }>(reqObject);

  if (resp.success) {
    return resp.clientSecret;
  } else {
    const errMsg = resp?.message || 'Oops! payment failed.';
    track('error - payment intent', { code: resp?.errorCode, message: errMsg });
    logger.error('error - payment intent', resp);
    throw Error(errMsg);
  }
};

const anyGiftSetupIntent: (props: SetupIntentProps) => Promise<string> = async ({
  currency = 'usd',
  amount,
  identifier: cardGiftId,
  receiptEmail,
}) => {
  if (!cardGiftId) throw Error('gift id needs to be included for payment intent');
  if (!receiptEmail) throw Error('receiptEmail needs to be included for payment intent');
  const reqObject = {
    url: '/payment/setupIntent',
    method: 'POST',
    data: { currency, amount, externalGiftId: cardGiftId, receiptEmail },
  };

  const resp = await illumeApiHttpClient.makeRequest<{ clientSecret: string }>(reqObject);

  if (resp.success) {
    return resp.clientSecret;
  } else {
    const errMsg = resp?.message || 'Oops! payment failed.';
    track('error - payment intent', { code: resp?.errorCode, message: errMsg });
    logger.error('error - payment intent', resp);
    throw Error(errMsg);
  }
};

const validatePromoCode = async (promoCode: string, pricingType: 'Standard' | 'Premium') => {
  const reqObject = {
    url: '/payment/checkPromo',
    method: 'POST',
    data: { promoCode, plan: pricingType },
  };

  const resp = await illumeApiHttpClient.makeRequest<{
    valid: true;
    discount: number;
    discountedPrice: number;
  }>(reqObject);

  if (resp.success) {
    return {
      message: resp.valid ? 'valid' : ('invalid' as 'valid' | 'invalid'),
      discount: resp.discount,
      discountedPrice: resp.discountedPrice,
    };
  } else {
    const errMsg = resp?.message || 'Oops! payment failed.';
    track('error - payment intent', { code: resp?.errorCode, message: errMsg });
    logger.error('error - payment intent', resp);
    throw Error(errMsg);
  }
};

const stripePromise = () => {
  return loadStripe(config.stripePublicKey);
};

const stripeService = {
  setupIntent,
  stripePromise,
  anyGiftSetupIntent,
  validatePromoCode,
};

export default stripeService;
