import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { Modal } from 'components/illume/modal';
import { Text } from 'components/illume/text';
import { TextArea } from 'components/illume/text-area';
import { colors, rem } from 'constants/design';
import { IIllumeModal } from 'types';
import { noop } from 'utils';
import { isValid } from 'utils/form';

import { pageStyles } from '../../../../MyCards.styles';

export type ContributorModalFormValues = {
  message: string;
};

export interface ContributorIntroMessageModalProps extends IIllumeModal {
  onSave: (values: ContributorModalFormValues) => any;
  onCancel: () => any;
  defaultValues: Partial<ContributorModalFormValues>;
}
export const ContributorIntroMessageModal: React.FunctionComponent<
  ContributorIntroMessageModalProps
> = ({ show = false, onClose = noop, onSave = noop, defaultValues, onCancel = noop }) => {
  const pageUIStyles = pageStyles();

  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitted, isSubmitting },
  } = useForm<ContributorModalFormValues>({
    defaultValues,
  });

  const disableSubmitButton = !isValid(errors) && isSubmitted;

  return (
    <Modal
      show={show}
      onClose={onClose}
      promptConfig={{
        onOk: handleSubmit(onSave),
        okText: 'save',
        okLoading: isSubmitting,
        disabled: disableSubmitButton,
        cancelText: 'cancel',
        onCancel,
      }}
    >
      <Grid item container direction="column">
        <Grid item>
          <Text
            fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
            align="center"
            fontWeight={900}
            color={colors.gray100}
          >
            {`contributor intro message`.toUpperCase()}
          </Text>
        </Grid>
        <Grid className={classNames(pageUIStyles.contributorModal, 'customIllumeScrollbar-orange')}>
          <TextArea
            style={{ minHeight: 120 }}
            label="Intro message to contributors"
            placeholder={'start a message for contributors!'}
            name="message"
            ref={register}
            error={errors.message}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
