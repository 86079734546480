// Contributor
export const CONTRIBUTOR_URL = '/contributor' as const;
export const CONTRIBUTOR_WELCOME_URL = `${CONTRIBUTOR_URL}/welcome` as const;
export const CONTRIBUTOR_ADD_NOTE_URL = `${CONTRIBUTOR_URL}/add-note` as const;
export const CONTRIBUTOR_SELECT_THEME_URL = `${CONTRIBUTOR_URL}/select-theme` as const;
export const CONTRIBUTOR_REPLY_URL = `${CONTRIBUTOR_URL}/reply` as const;
export const CONTRIBUTOR_THANKS_URL = `${CONTRIBUTOR_URL}/thanks` as const;
export const CONTRIBUTOR_GIFT_URL = `${CONTRIBUTOR_URL}/gift` as const;
export const CONTRIBUTOR_CELEBRATE_MORE_REMIND =
  `${CONTRIBUTOR_URL}/celebrate-more/remind` as const;
export const CONTRIBUTOR_CELEBRATE_MORE_OCCASION =
  `${CONTRIBUTOR_URL}/celebrate-more/occasion` as const;

// custom gifting route
export const TIPPING_SEASON_URL = '/givethanks' as const;
export const DEMO_CARD_URL = '/demo' as const;

// Initiator
export const INITIATOR_URL = '/create' as const;
export const INITIATOR_PAYWALL_URL = `${INITIATOR_URL}/select-plan` as const;
export const INITIATOR_SHARE_URL = `${INITIATOR_URL}/share` as const;
export const INITIATOR_BASIC_INFO_URL = `${INITIATOR_URL}/info` as const;
export const INITIATOR_SELECT_THEMEPACKS_URL = `${INITIATOR_URL}/themes` as const;
export const INITIATOR_CHOOSE_GROUP_GIFT = `${INITIATOR_URL}/choose` as const;
export const INITIATOR_PAY_FOR_GROUP_GIFT = `${INITIATOR_URL}/gift` as const;
export const INITIATOR_MARKETPLACE_URL = `${INITIATOR_URL}/marketplace` as const;
export const INITIATOR_MARKETPLACE_GIFTS_URL = `${INITIATOR_MARKETPLACE_URL}/gifts` as const;
export const INITIATOR_MARKETPLACE_GIFTS_SEARCH_RESULTS_URL =
  `${INITIATOR_MARKETPLACE_URL}/gifts/search-results` as const;

// marketplace urls
const INITIATOR_MARKETPLACE_PRODUCT_LINE_DETAILS_URL = `${INITIATOR_MARKETPLACE_URL}/product-line`;
export const INITIATOR_MARKETPLACE_PRODUCT_LINE_DETAILS_URL_PATTERN =
  `${INITIATOR_MARKETPLACE_PRODUCT_LINE_DETAILS_URL}/:id` as const;

export const getInitiatorMarketplaceProductLineDetailsUrl = (id: string) =>
  `${INITIATOR_MARKETPLACE_PRODUCT_LINE_DETAILS_URL}/${id}`;

export const INITIATOR_TEAMS_URL = `${INITIATOR_URL}/teams` as const;
export const INITIATOR_SPLIT_GIFTS = `${INITIATOR_URL}/splitanygifts` as const;
export const INITIATOR_SPLIT_GIFTS_CONFIRM = `${INITIATOR_SPLIT_GIFTS}/confirm` as const;
export const INITIATOR_SPLIT_GIFTS_PAY = `${INITIATOR_SPLIT_GIFTS}/pay` as const;

/**
 * @deprecated - note editing should be a part of a flow, not a separate page
 */
export const ADD_NOTE_URL = `/add-note` as const;

export const ADD_NOTE_URL_CREATE = `${ADD_NOTE_URL}/add` as const;
export const ADD_NOTE_URL_SELECT_THEME = `${ADD_NOTE_URL}/theme` as const;

// Misc
export const PRIVACY_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/7830743/full-legal' as const;
export const TERMS_OF_SERVICE_URL = 'https://illumenotes.com/terms.html' as const;
export const CONTACT_US_EMAIL = 'email@illumenotes.com' as const;
export const LOGO_URL = '/logo' as const;
export const SIGNUP_URL = '/signup' as const;
export const LOGIN_URL = '/login' as const;
export const LOGIN_EMAIL_URL = '/login/email' as const;
export const EDIT_NOTE_URL = '/edit' as const;
export const OPEN_INSTAGRAM = 'instagram://camera' as const;
export const REDESIGNED_APP_FEATURE_FLAG = 'REDESIGNED_APP' as const;
export const SOCIAL_SHARE_URL = '/share' as const;
export const SAVE_THE_DATE_URL = '/save-the-date' as const;
export const MERCHANT_VERIFICATION_URL = '/merchant' as const;
export const FILE_UPLOAD_INSTRUCTION = '/how-to-upload' as const;

// slacks
export const SLACK_APP_PLANS = '/slack-plans' as const;
export const SLACK_TEAMS_CARDS_URL = '/team-cards/:workspaceUrl' as const;
export const SLACK_TEAMS_CARDS_DETAILS_URL = `${SLACK_TEAMS_CARDS_URL}/:cardCode` as const;
export const SLACK_TEAMS_CARDS_DETAILS_ADD_NOTE_URL =
  `${SLACK_TEAMS_CARDS_DETAILS_URL}/add-note` as const;

// My Cards
export const MY_CARDS_URL = '/my-cards' as const;
export const MY_CARDS_DETAILS_URL = `${MY_CARDS_URL}/:cardCode` as const;
export const MY_CARDS_CREATE_NOTE_URL = `${MY_CARDS_DETAILS_URL}/add-note` as const;
export const OPEN_URL = '/open' as const;
export const CARD_SEND_SUCCESS = '/success' as const;
export const CARD_DETAILS_PAY_FOR_GIFT = '/pay' as const;

// Notifications
export const NOTIFICATIONS_URL = '/notifications' as const;

// Profile
export const PROFILE_URL = '/me' as const;
export const EDIT_PROFILE_URL = '/edit-profile' as const;

// Recipient
export const RECEIVE_URL = '/receive' as const;
export const RECEIVE_READ_URL = `${RECEIVE_URL}/read` as const;
export const RECEIVE_GIFT_URL = `${RECEIVE_URL}/gift` as const;
export const RECEIVE_SUMMARY_URL = `${RECEIVE_URL}/summary` as const;
export const RECEIVE_SAVE_NOTES_URL = `${RECEIVE_URL}/save-notes` as const;
export const RECEIVE_VERIFY_URL = `${RECEIVE_URL}/verify` as const;
export const RECEIVE_WELCOME_URL = `${RECEIVE_URL}/welcome` as const;
export const RECEIVE_SHIP_URL = `${RECEIVE_URL}/ship` as const;
export const RECEIVE_CELEBRATORY_URL = `${RECEIVE_URL}/celebratory` as const;
export const RECEIVE_SHIP_TRACK_URL = `${RECEIVE_URL}/track` as const;

//Teams
export const TEAMS_URL = '/teams' as const;
export const TEAM_SUCCESFUL_CREATE_URL = `${TEAMS_URL}/success` as const;
