import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { motion } from 'framer-motion';

import { LeftArrow as Icon } from 'components/illume/icons';

const useStyles = makeStyles(() => ({
  root: {
    '&:focus': { outline: 'none' },
    padding: 0,
    '&:hover': { background: 'none' },
    // MUI has 64 by default
    // we decrease by half
    // probably it's ok with 0
    minWidth: 32,
  },
}));

interface LeftArrowButtonProps extends Omit<ButtonProps, 'color'> {
  color?: string;
  inverted?: boolean;
  width?: number;
  height?: number;
  classes?: any;
  iconProps?: any;
  disabled?: boolean;
}

const LeftArrowButton: React.FC<LeftArrowButtonProps> = ({
  onClick = () => {},
  color,
  inverted,
  width,
  height,
  classes: propClasses,
  disabled = false,
  iconProps,
  ...props
}) => {
  const classes = useStyles();
  const colorConfig = inverted ? { arrowColor: color } : { blobColor: color };
  return (
    <motion.div whileTap={{ scale: !disabled ? 0.8 : undefined }}>
      <Button
        disabled={disabled}
        disableRipple
        classes={{ ...classes, ...propClasses }}
        onClick={onClick}
        {...props}
      >
        <Icon width={width} height={height} {...{ ...colorConfig, ...iconProps }} />
      </Button>
    </motion.div>
  );
};

export default LeftArrowButton;
