import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const MAX_GIFT_PAGE_WIDTH = 950;
export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: MAX_GIFT_PAGE_WIDTH,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6.5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
  },
  skipGift: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  branding: {
    display: 'flex',
    justifyContent: 'center',
    padding: '4px',
    marginBottom: theme.spacing(4),
  },
  giftThumbWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    [theme.breakpoints.up('md')]: {
      padding: 24,
      marginTop: 0,
    },
  },
  detailBtn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 24,
      marginTop: 0,
    },
  },
  promoTextBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 8,
    width: 220,
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
}));

export const useStepperStyles = makeStyles(() => ({
  root: {
    // reset default padding
    paddingLeft: 0,
    paddingRight: 0,
    '& *': {
      borderColor: colors.gray20,
    },
  },
}));
