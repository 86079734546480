import { useState } from 'react';

import { Observer, observer } from 'mobx-react';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { P, match } from 'ts-pattern';

import { IllumeRoute } from 'components/illume-route';
import { Loading } from 'components/Loading';
import { MY_CARDS_CREATE_NOTE_URL, MY_CARDS_DETAILS_URL, MY_CARDS_URL } from 'constants/strings';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { MyCardsV2 } from 'views/pages-v2/my-cards';

import { CardDetailsStore } from '../card-details-v2/CardDetaillsV2PendingStore';
import InitiatorContributorCardDetailsHandler from '../card-details-v2/InitiatorContributorCardDetailsHandler';
import { getRandomLoadingLabel } from '../marketplace/pages/getRandomLoadingLabel';
import { MyCardsAddNoteRoutes } from './MyCardsAddNoteRoutes';

export const [IN_THE_WORKS, READY_TO_SEND] = ['IN_THE_WORKS', 'READY_TO_SEND'];

const CardsCRUDModule = observer((props: { match: any; location: any }) => {
  const basePath = props.match.path;
  const cardCode = props.match.params.cardCode as string;
  const email = new URLSearchParams(props.location.search).get('email');

  const history = useHistory();
  const { authenticationStore, stripeStore, userProfileStore, routerStore, cache, myCardsV2Store } =
    useStores();
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const { noteService } = useServices();

  const [store] = useState(
    () =>
      new CardDetailsStore(
        cardCode,
        email,
        authenticationStore,
        enqueueErrorSnackbar,
        history.push,
        routerStore,
        userProfileStore,
        stripeStore,
        cache,
        myCardsV2Store,
        noteService,
        MY_CARDS_URL,
      ),
  );

  return (
    <Observer>
      {() => {
        return match(store.state)
          .with({ data: P.not(P.nullish) }, (state) => {
            return (
              <Switch>
                <IllumeRoute
                  exact
                  component={() => (
                    <InitiatorContributorCardDetailsHandler
                      disableContactEditing={false}
                      cardDetailsStore={store}
                      addNotePath={generatePath(MY_CARDS_CREATE_NOTE_URL, {
                        cardCode: cardCode,
                      })}
                    />
                  )}
                  path={basePath}
                />

                <Route
                  render={({ match }) => (
                    <Observer>
                      {() => {
                        return (
                          <MyCardsAddNoteRoutes
                            redirectUrl={generatePath(MY_CARDS_DETAILS_URL, { cardCode })}
                            match={match}
                            card={state.data}
                          />
                        );
                      }}
                    </Observer>
                  )}
                  path={`${basePath}/add-note`}
                />
              </Switch>
            );
          })
          .with({ type: 'error' }, () => {
            return <div>error while loading card</div>;
          })
          .otherwise(() => {
            return <Loading text={getRandomLoadingLabel()} />;
          });
      }}
    </Observer>
  );
});

const MyCardsRoutes = (props: any) => {
  const basePath = props.match.path; // --> /my-cards

  return (
    <Switch>
      {/* My Cards */}
      <IllumeRoute exact isPrivate component={MyCardsV2} path={basePath} />

      <Route component={CardsCRUDModule} path={`${basePath}/:cardCode`} />

      <Route component={() => <div>404</div>} path="*" />
    </Switch>
  );
};

export default observer(MyCardsRoutes);
