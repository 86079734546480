import random from 'lodash/random';

import { toKeyValuePairs } from './arr-to-key-value-pairs/arrToKeyValuePairs';
import { wait } from './async-helper/wait';
import { getMobileOperatingSystem } from './getMobileOpeeratingSystem';
import { isHex } from './string/isHex';
import { wrapslice } from './wrapSlice';

export function noop() {}
export { getMobileOperatingSystem };

export { pluralize } from './string/pluralize';
export { storageAvailable } from './storageAvailability';

/** @type {jest.Expect} */
// @ts-ignore
let expect = global.expect;

export { expect };

export { wait };

export { toKeyValuePairs };

export { isHex };

export { wrapslice };

export function randomSlice<T>(arr: T[], sliceLen: number): T[] {
  // Generate a random index between 0 and arr.length - sliceLen
  const startIndex = random(0, arr.length - sliceLen + 1);
  // Create a new array that is a copy of the original array
  const newArr = [...arr];
  // Return a slice of the new array with the desired length, starting at the random index
  return newArr.slice(startIndex, startIndex + sliceLen);
}
export function scrollIntoViewPromise(element: Element) {
  return new Promise((resolve) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        resolve(null);
      }
    });
    observer.observe(element);
  });
}

export const withPreventDefault =
  <T extends Event>(fn: (e: T) => void) =>
  (e: T): void => {
    e.preventDefault();
    fn(e);
  };

export function keyValuePairsToMap<T, Name extends string>(obj: Record<Name, T>): Map<Name, T> {
  return new Map(Object.entries(obj)) as Map<Name, T>;
}

/**
 * move to cardV2 service file
 */
export { default as CustomError } from './CustomError';
