import {
  INITIATOR_BASIC_INFO_URL,
  CONTRIBUTOR_WELCOME_URL,
  MY_CARDS_URL,
  OPEN_URL,
  RECEIVE_URL,
} from 'constants/strings';

export default async function getLegacyRedirectPath(location = window.location) {
  const { hash, pathname } = location;

  // handle old links
  // https://stackoverflow.com/questions/56981230/how-to-redirect-old-react-router-hashrouter-with-the-to-browserrouter/59532438#59532438
  // these seems like a hack, but they're work for now
  const PRE_DESIGNED_PATH_REDIRECTS = {
    '#/collaborative': '/receive',
    '#/write': '/contribute/write',
    '#/login': '/login',
    '#login': '/login',
    '#/magic': '',
    '#magic/': '',
    '#magic': '',
  };

  const REDESIGNED_PATHS_REDIRECTS = {
    // from old hash route
    '#/collaborative/notes': RECEIVE_URL,
    '#/collaborative': RECEIVE_URL,
    '#/write': CONTRIBUTOR_WELCOME_URL,
    '#/magic': '',
    '#magic/': '',
    '#magic': '',
    // from predesigned
    '/contribute/write': CONTRIBUTOR_WELCOME_URL,
    '/compose': INITIATOR_BASIC_INFO_URL,
    '/my-notes': MY_CARDS_URL,
    '/recieve': RECEIVE_URL,
    [OPEN_URL]: RECEIVE_URL,
  };

  const REDESIGNED_PATHS_REDIRECTS_MAP = new Map(Object.entries(REDESIGNED_PATHS_REDIRECTS));

  function getUrl(pathname: string, search: string) {
    const u = new URL(location.href);
    u.pathname = pathname;
    // remove hash
    u.hash = '';
    u.search = search;

    // return normalized url
    return u;
  }

  const getOldRoutRedirectPath = (paths: Map<string, string>) => {
    if (hash) {
      for (let oldValue in REDESIGNED_PATHS_REDIRECTS) {
        if (hash.startsWith(oldValue)) {
          const result = REDESIGNED_PATHS_REDIRECTS_MAP.get(oldValue);
          const hashSearch = location.href.substring(location.href.indexOf('?'));

          return getUrl(result || '', hashSearch);
        }
      }
    } else {
      const newPath = paths.get(pathname);
      if (newPath) {
        const result = newPath;
        return getUrl(result, location.search);
      }
    }
  };

  const redirectPath = getOldRoutRedirectPath(
    new Map(
      Object.entries({
        ...PRE_DESIGNED_PATH_REDIRECTS,
        ...REDESIGNED_PATHS_REDIRECTS,
      }),
    ),
  );

  return redirectPath;
}
