import { useState } from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { Theme } from 'infra/mui-stylesheet';

import ChevronIcon from './ChevronIcon';
import { HeightBasedCollapsible } from './HeightBasedCollapsible';
import { Faq } from './StripePaywall';

export const useFaqElementStyles = makeStyles<
  Theme,
  {
    color: string;
    backgroundColor: string;
  }
>(() =>
  createStyles({
    container: {
      color: ({ color }) => color,
      display: 'flex',
      borderRadius: 12,
      flexDirection: 'column',
      padding: '16px 20px',
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      '& .title': {
        display: 'flex',
        columnGap: 16,
        alignItems: 'center',
        cursor: 'pointer',
      },
    },
  }),
);

export const FaqElement = ({ faq }: { faq: Faq }) => {
  // collapse state
  const [open, setOpen] = useState(false);
  const classes = useFaqElementStyles({
    color: open ? colors.contrastText : colors.gray100,
    backgroundColor: open ? colors.primary : colors.backgroundGray,
  });
  return (
    <div className={classes.container}>
      <div
        onClick={(e) => {
          e.preventDefault();
          return setOpen(!open);
        }}
        className="title"
        style={{ marginBottom: open ? 12 : 0 }}
      >
        <BaseIconButtonNew
          icon={ChevronIcon}
          iconProps={{
            direction: open ? 'down' : 'right',
            color: open ? colors.contrastText : colors.gray100,
            opacity: '1',
          }}
        />
        <Text fontWeight={500} fontSize={{ desktop: 22, mobile: 16 }}>
          {faq.question}
        </Text>
      </div>
      <HeightBasedCollapsible isOpen={open}>
        <Text fontWeight={500} fontSize={{ desktop: 18, mobile: 16 }}>
          {faq.answer}
        </Text>
      </HeightBasedCollapsible>
    </div>
  );
};
