export const Pencil = ({ color = '#FFFDF3', size = 24, showPerson = false, ...props }) => {
  const personEdit = (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.17718 5.89292C6.81862 5.89292 8.14928 4.57374 8.14928 2.94646C8.14928 1.31917 6.81862 0 5.17718 0C3.53573 0 2.20508 1.31917 2.20508 2.94646C2.20508 4.57374 3.53573 5.89292 5.17718 5.89292Z"
        fill={color}
      />
      <path
        d="M8.08539 12.2618L10.0668 8.87174C9.65133 7.95295 8.88434 7.22426 7.89364 6.93912C7.06273 6.68566 3.32364 6.68566 2.49273 6.93912C0.9907 7.35099 0 8.74501 0 10.2658V11.6281C0 12.04 0.351539 12.3885 0.766993 12.3885H8.02147C8.05343 12.3251 8.05343 12.2934 8.08539 12.2618Z"
        fill={color}
      />
      <path
        d="M12.3678 6.27344L12.1121 6.3368L11.3771 7.6041L13.3585 8.74466L14.0935 7.47737L14.0296 7.22391L12.3678 6.27344ZM7.95757 13.5287L7.86169 15.9999L9.93897 14.6693L13.1348 9.2199L11.1534 8.07933L7.95757 13.5287Z"
        fill={color}
      />
    </svg>
  );

  const pencilEdit = (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.7956 6.94066L16.8413 4.18325C16.5812 3.93892 16.1538 3.93892 15.8937 4.18325L5.54424 13.904C5.33985 14.096 5.22837 14.3229 5.1912 14.6021L4.02062 18.1972C3.96488 18.4241 4.02062 18.6335 4.20643 18.808C4.33649 18.9302 4.50372 19 4.68953 19H4.81959L8.70295 17.918C8.98166 17.8831 9.26037 17.7609 9.44618 17.5864L19.7956 7.86562C19.9257 7.74346 20 7.58639 20 7.41187C19.9814 7.23735 19.9071 7.06283 19.7956 6.94066ZM16.2095 9.41885L8.51714 16.6265L6.52901 14.7592L14.2214 7.53403L16.2095 9.41885Z" />
    </svg>
  );

  return showPerson ? personEdit : pencilEdit;
};
