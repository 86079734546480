import { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { colors, rem } from 'constants/design';

import { QuestionMark } from '../icons';
import Text from '../text/Text';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: (props) =>
      props.tooltipBackgroundColor ?? theme.palette.primary.primaryTooltip,
    color: colors.darkText,
    marginBottom: theme.spacing(1),
    maxWidth: theme.spacing(20),
  },
  customArrow: {
    color: (props) => props.tooltipBackgroundColor ?? theme.palette.primary.primaryTooltip,
  },
  button: {
    cursor: 'pointer',
  },
}));

const WhyTooltip = ({ tooltipText, tooltipBackgroundColor, title = true }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles({ tooltipBackgroundColor });
  return (
    <Tooltip
      arrow
      // Note: This is the default, but if it won't fit onscreen MUI will place it somehwere else.
      placement="top-end"
      open={showTooltip}
      title={tooltipText}
      aria-label={tooltipText}
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
    >
      <div
        className={classes.button}
        onClick={() => setShowTooltip((prev) => !prev)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box>
            <QuestionMark />
          </Box>
          {title && (
            <Box ml={0.3} pr={1}>
              <Text color={colors.tooltipText} fontSize={rem[750]} decoration="underline">
                why?
              </Text>
            </Box>
          )}
        </Box>
      </div>
    </Tooltip>
  );
};

export default WhyTooltip;
