import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Text } from 'components/illume/text';
import { Spinner } from 'components/spinner';

const useStyles = makeStyles(() => ({ root: { height: '100vh' } }));

const Loading = ({ text = '' }) => {
  const classes = useStyles();
  return (
    <Grid justifyContent="center" alignItems="center" classes={classes} container>
      <Box
        justifyContent="center"
        flexDirection="column"
        alignItems={'center'}
        display="flex"
        style={{ rowGap: 10 }}
      >
        <Spinner />
        {text && <Text align={'center'}>{text}</Text>}
      </Box>
    </Grid>
  );
};

export default Loading;
