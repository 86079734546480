import { useEffect, useState } from 'react';

import { Grid, Link } from '@material-ui/core';

import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import logger from 'utils/logger';

const text = 'sample text';
const title = 'file title';
const url = 'www.illumenotes.com';

const Share = () => {
  const [errMsg, setErrMsg] = useState(undefined);

  const [files, setFiles] = useState([]);

  const initFiles = async () => {
    const response = await fetch('/share-illume.png');
    const blob = await response.blob();
    const file1 = new File([blob], 'illume.png', { type: 'image/png' });
    const response2 = await fetch('/illume-meta-tag.png');
    const blob2 = await response2.blob();
    const file2 = new File([blob2], 'illume2.png', { type: 'image/png' });

    // try to create files as .ig and .igo
    const file3 = new File([blob2], 'illume3.ig', { type: 'image/png' });
    const file4 = new File([blob2], 'illume4.igo', { type: 'image/png' });

    const response5 = await fetch('https://www.w3schools.com/html/movie.mp4');
    const blob5 = await response5.blob();
    const file5 = new File([blob5], 'movie.mp4', { type: 'video/mp4' });

    setFiles([file1, file2, file3, file4, file5]);
  };

  useEffect(() => {
    if (!navigator.share || !navigator.canShare)
      setErrMsg('Sharing is not supported on this device/browser');
    initFiles();
  }, []);

  const handleShare = async ({ data }) => {
    try {
      if (navigator.canShare(data)) {
        await navigator.share(data);
      } else {
        logger.log('unsupported share', { data });
        setErrMsg('this type of share is unsupported');
      }
    } catch (err) {
      logger.error('failure during native share', err);
    }
  };

  const experiments = [
    {
      name: 'one file',
      data: { files: [files[0]] },
    },
    {
      name: 'all three fields (text, title, files)',
      data: { text, title, files: [files[0]] },
    },
    {
      name: 'title and file',
      data: { title, files: [files[0]] },
    },
    {
      name: 'multiple files',
      data: { files: [files[0], files[1]] },
    },
    {
      name: 'share url',
      data: { title: 'link title', url, text },
    },
    {
      name: 'share url (without text)',
      data: { title: 'link title', url },
    },
    {
      name: 'png file',
      data: { files: [files[1]] },
    },
    {
      name: '.ig file',
      data: { files: [files[2]] },
    },
    {
      name: '.igo file',
      data: { files: [files[3]] },
    },
    {
      name: '.mp4 file',
      data: { files: [files[4]] },
    },
  ];

  return (
    <IllumeLayout background={colors.primary}>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        style={{ marginTop: '20px' }}
        spacing={4}
      >
        <Grid item>
          <Text fontWeight={700} fontSize={rem[2000]}>
            Web Share Tests
          </Text>
        </Grid>
        <Grid item>
          <Link
            color="textPrimary"
            style={{ fontSize: 20, fontStyle: 'italic' }}
            href="https://www.notion.so/illumenotes/Social-Shares-cd73be5772b04b4187afc13c3412aeb7#e99f12d8542d4fb786962bf2c5e47c10"
            target="_blank"
          >
            See Notion Page
          </Link>
        </Grid>

        {errMsg && (
          <Grid item>
            <Text color="red">{errMsg}</Text>
          </Grid>
        )}
        <Grid item container direction="column" alignItems="center">
          {experiments.map(({ name, data }) => (
            <Grid item key={name}>
              <Button onClick={() => handleShare({ data })}>{name}</Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default Share;
