export const TrashBin = ({ color, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09948 2.00477C8.54977 1.9513 8.06085 2.35358 8.00738 2.90327L7.93802 3.61674C7.9342 3.65601 7.93267 3.69495 7.93341 3.73347L4.71002 3.39298C4.43539 3.36396 4.18927 3.56309 4.16024 3.83768L4.0028 5.32838C3.97381 5.603 4.17291 5.84913 4.44751 5.87815L12.7045 6.75028H6.6882C6.09375 6.75028 5.63055 7.26575 5.69387 7.85682L6.97958 19.8568C7.03402 20.365 7.46286 20.7503 7.9739 20.7503H15.1768C15.6878 20.7503 16.1166 20.365 16.171 19.8568L17.4568 7.85682C17.4809 7.63139 17.4285 7.41697 17.3214 7.23792L18.364 7.34806C18.6386 7.37708 18.8848 7.17799 18.9138 6.90336L19.0712 5.41267C19.1002 5.13804 18.9012 4.89191 18.6265 4.86292L15.4533 4.52775C15.4694 4.47083 15.4807 4.41169 15.4866 4.35075L15.556 3.63731C15.6094 3.08759 15.2071 2.59867 14.6574 2.5452L9.09948 2.00477ZM9.57531 11.2503C9.57531 10.9741 9.79919 10.7503 10.0753 10.7503C10.3515 10.7503 10.5753 10.9741 10.5753 11.2503V18.2503C10.5753 18.5264 10.3515 18.7503 10.0753 18.7503C9.79919 18.7503 9.57531 18.5264 9.57531 18.2503V11.2503ZM13.0753 10.7503C12.7992 10.7503 12.5753 10.9741 12.5753 11.2503V18.2503C12.5753 18.5264 12.7992 18.7503 13.0753 18.7503C13.3515 18.7503 13.5753 18.5264 13.5753 18.2503V11.2503C13.5753 10.9741 13.3515 10.7503 13.0753 10.7503Z"
      fill={color}
    />
  </svg>
);
