import DineroFactory, { Dinero } from 'dinero.js';

import { IMoney } from 'domain/interfaces/IMoney';
import { toCent } from 'utils/currency';

type MoneyOpts = {
  amount: number;
};

export class Money implements IMoney {
  private dinero: Dinero;
  add(money: IMoney): IMoney {
    const addedMoney = DineroFactory({ amount: money.getAmount() });
    const newVal = this.dinero.add(addedMoney);
    return new Money({ amount: newVal.getAmount() });
  }
  getAmount() {
    return this.dinero.getAmount();
  }
  percentage(percentage: number): IMoney {
    const newVal = this.dinero.percentage(percentage);
    return new Money({ amount: newVal.getAmount() });
  }
  subtract(money: IMoney): IMoney {
    const substractor = DineroFactory({ amount: money.getAmount() });
    const newVal = this.dinero.subtract(substractor);
    return new Money({ amount: newVal.getAmount() });
  }
  constructor(opts: MoneyOpts) {
    this.dinero = DineroFactory({ amount: opts.amount });
  }
  static fromDineroOpts(opts: Dinero.Options) {
    return new Money({ amount: opts.amount || 0 });
  }
  static fromCents(cents: number) {
    return new Money({ amount: cents });
  }
  static fromDollarString(dollarString: string) {
    const cent = toCent(dollarString);
    return new Money({ amount: cent });
  }
  divide(divider: number): IMoney {
    const newVal = this.dinero.divide(divider);
    return new Money({ amount: newVal.getAmount() });
  }
  greaterThanOrEqual(money: IMoney): boolean {
    const comparator = DineroFactory({ amount: money.getAmount() });
    return this.dinero.greaterThanOrEqual(comparator);
  }
  lessThanOrEqual(money: IMoney): boolean {
    const comparator = DineroFactory({ amount: money.getAmount() });
    return this.dinero.lessThanOrEqual(comparator);
  }
  lessThan(money: IMoney): boolean {
    const comparator = DineroFactory({ amount: money.getAmount() });
    return this.dinero.lessThan(comparator);
  }
  equalsTo(money: IMoney): boolean {
    const comparator = DineroFactory({ amount: money.getAmount() });
    return this.dinero.equalsTo(comparator);
  }
  toFormat(): string {
    return this.dinero.toFormat();
  }
  toUnit(): number {
    return this.dinero.toUnit();
  }
  greaterThan(money: IMoney): boolean {
    const comparator = DineroFactory({ amount: money.getAmount() });
    return this.dinero.greaterThan(comparator);
  }
  toRoundedUnit(digits: number, arg2?: DineroFactory.RoundingMode | undefined): number {
    return this.dinero.toRoundedUnit(digits, arg2);
  }
}
