import {
  CustomHeaders as TrackValues,
  IllumeHeaderOverrides,
  IllumeHttpClientRequestObject,
} from '../client.types';

export function inject_x_illumeHeaders(
  requestObj: IllumeHttpClientRequestObject,
  customHeaders: TrackValues,
) {
  const newRequestObj = { ...requestObj };

  const headers = new Map(
    Object.entries({
      'X-Requested-With': 'XMLHttpRequest',
      'x-illume-platform': 'web',
    }),
  );

  const pairs = new Map<string, keyof TrackValues>([
    ['x-illume-device-id', 'deviceId'],
    ['x-illume-anonymous-id', 'anonymousId'],
    ['x-illume-ga', 'gaId'],
  ]);

  pairs.forEach((customHeaderKey, key) => {
    const v = customHeaders[customHeaderKey];
    if (v) {
      headers.set(key, v);
    }
  });
  if (!newRequestObj.headers) {
    newRequestObj.headers = {};
  }

  Object.assign(newRequestObj.headers, Object.fromEntries(headers));

  return newRequestObj;
}

export const injectAuthHeader = (requestObj: IllumeHttpClientRequestObject, jwt: string) => {
  const newRequestObj = { ...requestObj };

  newRequestObj.headers = {
    ...(requestObj?.headers || {}),
    Authorization: `Bearer ${jwt}`,
  };

  return newRequestObj;
};

export const injectHeader = (
  requestObj: IllumeHttpClientRequestObject,
  overrides: IllumeHeaderOverrides,
) => {
  const newRequestObj = Object.assign({}, requestObj);

  newRequestObj.headers = {
    ...(requestObj?.headers || {}),
    ...overrides,
  };

  return newRequestObj;
};
