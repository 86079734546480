import React from 'react';

import { GiftV2Dto } from '@illume/shared';
import { LinearProgress } from '@material-ui/core';
import classNames from 'classnames';

import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import Gift from 'domain/entities/gift/gift';
import { IllumeMoneyFactory } from 'utils/currency';

import { useContributeCardStyles } from './ContributeCardGift.style';

interface IGift {
  yourContribution: number;
  product: {
    name: string;
    image: string;
  };
  financial: {
    current: number;
    goal: number;
  };
}
class ViewModel {
  constructor(private _gift: IGift) {}
  get title() {
    return this._gift.product.name;
  }
  get current() {
    return IllumeMoneyFactory({ amount: this._gift.financial.current });
  }
  get goal() {
    return IllumeMoneyFactory({ amount: this._gift.financial.goal });
  }
  get image_thumb() {
    return this._gift.product.image;
  }
  get yourContribution() {
    return IllumeMoneyFactory({ amount: this._gift.yourContribution });
  }
  get _progressRatio() {
    const r = this.current.getAmount() / this.goal.getAmount();

    if (r === 0) {
      return 0.05; // add a little nudge, for ui purpose only
    }
    return r;
  }
  get progress() {
    const p = this._progressRatio * 100;
    return p;
  }
}

export function fromGiftV2Dto(gift: GiftV2Dto): IGift {
  return {
    financial: {
      current: gift.financial.current,
      goal: gift.financial.goal,
    },
    product: {
      image: gift.product.variant.img,
      name: gift.product.name,
    },
    yourContribution: gift.yourContribution,
  };
}

export function fromGift(gift: Gift): IGift {
  return {
    financial: {
      current: gift.financial.current.getAmount(),
      goal: gift.financial.goal.getAmount(),
    },
    product: {
      image: gift.product.variant.img,
      name: gift.product.name,
    },
    yourContribution: gift.myContribution.getAmount(),
  };
}

export function fromExternalGift(gift: ExternalGift): IGift {
  return {
    financial: {
      current: gift.financial.current.getAmount(),
      goal: gift.financial.goal.getAmount(),
    },
    product: {
      image: gift.image,
      name: gift.title,
    },
    yourContribution: gift.yourContribution?.getAmount() || 0,
  };
}

export interface ContributeCardGiftProps {
  gift: IGift;
  onViewGroupGiftDetails: () => any;
  className?: string;
  component?: React.ElementType;
}

const ContributeCardGift: React.FunctionComponent<ContributeCardGiftProps> = ({
  gift,
  onViewGroupGiftDetails,
  className,
  component = 'div',
  ...rest
}) => {
  const C = component;
  const vm = new ViewModel(gift);
  const classes = useContributeCardStyles({
    background: `${colors.transparent}`,
  });

  return (
    <C className={classNames(classes.boxCard, className)} {...rest}>
      <div className="info">
        <div className="title">
          <Text fontWeight={900} lineHeight={'29px'} fontSize={{ desktop: 28, mobile: 24 }}>
            {vm.title}
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
          <Text fontWeight={900} lineHeight={'29px'} fontSize={{ desktop: 28, mobile: 24 }}>
            {vm.current.toFormat()}
          </Text>
          <Text lineHeight={'22px'} fontSize={16} color={colors.gray60}>
            raised of {vm.goal.toFormat()} goal.
          </Text>
        </div>
        <div className="progress">
          <LinearProgress className={classes.progress} variant="determinate" value={vm.progress} />
          <Text
            className={classes.contributedText}
            color="#23CE6B"
            lineHeight={'22px'}
            fontSize={16}
            style={{ marginTop: 8 }}
          >
            You’ve already contributed {vm.yourContribution.toFormat()}
          </Text>
        </div>
        <div className="cta">
          <Button customcolor={'background'} fullWidth onClick={onViewGroupGiftDetails}>
            <Text color={colors.gray60} fontSize={{ desktop: '16px', mobile: '14px' }}>
              View Group Gift Details
            </Text>
          </Button>
        </div>
      </div>
      <div className="image">
        <img src={vm.image_thumb} alt={vm.title} />
      </div>
    </C>
  );
};

export default ContributeCardGift;
