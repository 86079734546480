import { AxiosRequestConfig } from 'axios';

import { IVerificationService, IVerifyChallengeOpts } from 'domain/interfaces/IVerificationService';
import { IllumeApiHttpClient } from 'infra/client';
import CustomError from 'utils/CustomError';
import logger from 'utils/logger';

export class VerificationService implements IVerificationService {
  // send the 4-digit code to the email provided and a nonce back to the user
  constructor(private httpClient: IllumeApiHttpClient) {}
  issueChallenge = async (email: string) => {
    const requestObj: AxiosRequestConfig = {
      url: '/token/issueChallenge',
      method: 'post',
      data: { email, type: '4DigitEmail' },
    };
    const response = await this.httpClient.makeRequest<{ nonce: string; expiration: string }>(
      requestObj,
    );

    if (response.success) {
      return { nonce: response.nonce, expiration: response.expiration };
    } else {
      const errorMsg = 'Failed to issue challenge.';
      logger.error(errorMsg, response);
      throw new CustomError(response);
    }
  };

  verifyChallenge = async ({ digits, nonce, profile }: IVerifyChallengeOpts) => {
    const requestObj: AxiosRequestConfig = {
      url: '/token/verifyChallenge',
      method: 'post',
      data: { digits, nonce, profile },
    };
    const response = await this.httpClient.makeRequest<{ token: string }>(requestObj);
    if (response.success) {
      return this.httpClient.setInMemoryJWT(response.token);
    } else {
      logger.error('failed to verify challenge', response);
      throw new CustomError(response);
    }
  };
}
