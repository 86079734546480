import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';

import { colors, generateContrastFromTheme, generateColorFromTheme } from 'constants/design';
import { oldToClassicThemepackMapping } from 'constants/design/cardTheme';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

import { ThemeSelectItemIcon } from '../icons';

const themeControlStyles = makeStyles(() => ({
  child: {
    backgroundColor: colors.gray60,
  },
  root: {
    paddingRight: 0,
  },
}));

const ThemeIcon = ({ theme, selected }) => {
  const isDesktop = useIsDesktop();
  const radioSize = isDesktop ? 53 : 37;
  return (
    <ThemeSelectItemIcon
      selected={selected}
      size={radioSize}
      innerFill={generateContrastFromTheme(theme)}
      outerFill={generateColorFromTheme(theme)}
    />
  );
};

// Note: checkedIcon must be set or else the visual will revert to a regular radio button.
const ThemeOption = ({ theme }) => {
  const { child, ...classes } = themeControlStyles();

  return (
    <FormControlLabel
      value={theme}
      control={
        <Radio
          disableRipple
          TouchRippleProps={{ classes: { child } }}
          icon={<ThemeIcon selected={false} theme={theme} />}
          checkedIcon={<ThemeIcon selected={true} theme={theme} />}
          data-testid={`${theme}-theme-selector`}
          classes={classes}
        />
      }
    />
  );
};

export const ThemeSelector = ({ theme, setTheme }) => {
  const handleChange = (event) => {
    setTheme(event.target.value);
  };

  return (
    <Grid>
      <FormControl component="fieldset">
        <FormLabel component="legend" aria-label="Theme selector" />
        <RadioGroup
          aria-label="theme"
          value={theme}
          onChange={handleChange}
          row
          style={{ flexWrap: 'nowrap', columnGap: 10 }}
        >
          {Object.keys(oldToClassicThemepackMapping)
            // exclude 'transparent' and 'background'
            .filter((themeName) => !['transparent', 'background'].includes(themeName))
            .map((themeName) => (
              <ThemeOption key={themeName} theme={themeName} />
            ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default ThemeSelector;
