import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const useIllumeLocation = () => {
  const location = useLocation();

  function getSearch() {
    return qs.parse(location.search);
  }

  return { location, getSearch };
};

export default useIllumeLocation;
