import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { To } from 'history';

import { colors } from 'constants/design';

import { Text } from '../text';

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
}));

interface LinkProps extends Omit<MuiLinkProps, 'underline'> {
  underline?: boolean;
  active?: any;
  children?: any;
  fontSize?: any;
  fontWeight?: any;
  italic?: boolean;
  color?: any;
  align?: any;
  onClick?: any;
  hover?: any;
  component?: React.ReactNode;
  to?: To;
}

const Link: React.FC<LinkProps> = ({
  active,
  children,
  fontSize,
  fontWeight,
  underline,
  italic = false,
  color,
  align,
  onClick = () => null,
  hover,
  ...props
}) => {
  const classes = useStyles();
  const colorText = active ? colors.primary : !hover && colors.gray20;

  return (
    <MuiLink classes={{ root: classes.root }} underline="none" onClick={onClick} {...props}>
      <Text
        italic={italic}
        align={align}
        fontSize={fontSize}
        fontWeight={fontWeight}
        decoration={underline ? 'underline' : 'none'}
        color={color ? color : colorText}
        hover={hover}
      >
        {children}
      </Text>
    </MuiLink>
  );
};

export default Link;
