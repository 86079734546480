import { makeStyles } from '@material-ui/core/styles';

import { colors, rem, spacing } from 'constants/design';

export const pageStyles = makeStyles((theme) => ({
  screen: {
    cursor: ({ loading }) => (loading ? 'wait' : 'default'),
    minHeight: '100vh',
  },
  cardListing: {
    width: '100%',
    padding: theme.spacing(6.5),
    paddingTop: theme.spacing(7),
  },
  title: {
    marginBottom: 8,
    fontSize: rem[1375],
    fontWeight: 900,
    [theme.breakpoints.up('md')]: {
      fontSize: rem[2250],
    },
  },
  links: {
    marginBottom: spacing[2],
    [theme.breakpoints.up('md')]: {
      marginBottom: spacing[4],
    },
  },
  link: {
    color: colors.gray20,
    '& p': {
      fontSize: rem[1125],
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        fontSize: rem[1500],
      },
    },
  },
  contributorModal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxHeight: theme.spacing(50),
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  tabs: {
    marginTop: theme.spacing(2),
  },
  tab: {
    '& p': {
      fontSize: rem[1000],
      color: colors.gray40,
      [theme.breakpoints.up('md')]: {
        fontSize: rem[1125],
      },
    },
  },
  tabsDivider: {
    padding: '20px 10px',
  },
}));
