import { css } from '@emotion/css';

export const Typography = {
  XL_BOLD: css`
    font-family: brandon-grotesque, sans-serif;
    font-size: 20px;
    // in figma, but it too looks thin
    // font-weight: 450;
    font-weight: 600;
    line-height: 32px;
  `,
  L_MEDIUM: css`
    //styleName: L/Medium;
    font-size: 16px;
    font-weight: 420;
    line-height: 24px;
  `,
  HEADING_2: css`
    //styleName: Heading/2;
    font-size: 40px;
    // font-weight: 450; // figma
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.02em;
  `,
  HEADING_3: css`
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.01em;
  `,
};
