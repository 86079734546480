import analytics from 'services/analytics';

import logger from './logger';

export const shareLink = async (sharingData) => {
  try {
    analytics.track('contributor link share', { device: 'iOS', action: 'share' });
    if (navigator.canShare && navigator.canShare(sharingData)) {
      await navigator.share(sharingData);
    } else {
      logger.error('unsupported navigator share');
    }
  } catch (err) {
    logger.error(err.message, { errorName: err.name });
  }
};
