const RecorderStop = () => {
  return (
    <svg width="86" height="84" viewBox="0 0 86 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#recorder-stop)">
        <path
          d="M0.518134 30.969C0.135492 23.2303 5.83441 14.9891 15.1562 8.73329C25.1049 2.05608 36.1363 -0.642359 48.0227 0.127465C54.8776 0.565049 61.3337 2.42883 66.7558 6.91002C70.281 9.81914 73.0083 13.3927 75.3612 17.2824C79.5132 24.1379 82.9896 31.2851 84.9516 39.1049C87.6708 49.931 85.0167 59.4849 78.0803 67.9854C70.8102 76.8911 61.1464 81.4128 49.887 82.9038C42.0144 83.9491 34.3453 83.1226 26.896 80.3674C17.8021 77.0045 11.2728 70.7892 7.16144 62.151C3.05822 53.5451 0.819362 44.445 0.518134 30.969Z"
          fill="#FFFDF3"
        />
        <g clipPath="url(#recorder-stop1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 42.075C16 27.1219 28.1186 15 43.075 15C58.0281 15 70.15 27.1186 70.15 42.075C70.15 57.0281 58.0314 69.15 43.075 69.15C28.1219 69.15 16 57.0314 16 42.075ZM30.5788 31.6591C30.5788 30.5102 31.5083 29.5788 32.6592 29.5788H54.1851C55.334 29.5788 56.2654 30.5082 56.2654 31.6591V53.1851C56.2654 54.334 55.336 55.2654 54.1851 55.2654H32.6592C31.5103 55.2654 30.5788 54.336 30.5788 53.1851V31.6591Z"
            fill="#FEB81C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="recorder-stop">
          <rect width="85.5" height="83.3354" fill="white" transform="translate(0.5)" />
        </clipPath>
        <clipPath id="recorder-stop1">
          <rect width="54.15" height="54.15" fill="white" transform="translate(16 15)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RecorderStop;
