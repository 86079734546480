import {
  ContributorCardDTO,
  InitiatorCardViewDTO,
  MerchantGiftCreateOrUpdatePayloadDTO,
  MyCardsCardDTO,
  MyCardsType,
  RecipientCardV2Dto,
  RolesDTO,
} from '@illume/shared';
import { makeAutoObservable, observable, ObservableMap } from 'mobx';

import { IStorage } from 'contexts/store';
import { ICardService, SendCardPayload } from 'domain/interfaces/ICardService';
import { AnyGiftPayload, UpdateCardPayloadOpts } from 'infra/card-service/cardService';
import { ExperimentalAsyncCachedStore } from 'pages/illume/my-cards/ExperimentalAsyncCacheStore';
import { CreateCardSetReminderPayloadDTo } from 'types';
import { wait } from 'utils';

export class MyCardsV2Store {
  asyncCachedCardsMap: ObservableMap<MyCardsType, ExperimentalAsyncCachedStore<MyCardsCardDTO[]>>;

  constructor(
    private service: ICardService,
    private cache: IStorage<MyCardsCardDTO[]>,
    private cacheIdentifier: string,
  ) {
    makeAutoObservable(this);
    // execute all store in map
    this.asyncCachedCardsMap = observable.map(
      new Map<MyCardsType, ExperimentalAsyncCachedStore<MyCardsCardDTO[]>>(
        Object.values(MyCardsType).map((type) => [type, this.createAsyncCachedStore(type)]),
      ),
    );
    this.asyncCachedCardsMap.forEach((store) => store.execute());
  }

  private createAsyncCachedStore = (type: MyCardsType) => {
    const key = `${this.cacheIdentifier}-${type}`;
    return new ExperimentalAsyncCachedStore(
      () =>
        this.service.getAllCards(type).then(
          // immutably limit to 100 cards
          (cards) => cards.slice(0, 100),
        ),
      {
        get: () => this.cache.get(key),
        set: (data: any) => this.cache.set(key, data),
      },
    );
  };

  // conditionally type the getCard method based on the RolesDTO type and the return types of the service methods
  getCard = async <T extends RolesDTO>(
    code: string,
    role: T,
  ): Promise<
    T extends 'Initiator'
      ? InitiatorCardViewDTO
      : T extends 'Contributor'
      ? ContributorCardDTO
      : T extends 'Recipient'
      ? RecipientCardV2Dto
      : never
  > => {
    switch (role) {
      case 'Initiator':
        return this.service.getInitiatorCard(code) as any;
      case 'Contributor':
        return this.service.getContributorCard(code) as any;
      case 'Recipient':
        return this.service.getReceivedCard(code) as any;
      default:
        return Promise.reject(new Error(`Invalid role: ${role}`));
    }
  };

  private bruteUpdatePendingCardsInBackground = <T>(res: T) => {
    // brute force update all cards
    // NOTE: this is a fire and forget operation
    // TODO: more api effiecient way to update cards
    wait(1000).then(() => {
      this.asyncCachedCardsMap.get(MyCardsType.PENDING)?.refresh();
    });

    return res;
  };
  updateCard = async (code: string, payload: UpdateCardPayloadOpts) => {
    return this.service.updateCard(code, payload);
    // .then(this.bruteUpdatePendingCardsInBackground);
  };
  createCard = async (payload: CreateCardSetReminderPayloadDTo) => {
    return this.service.createCard(payload);
    // .then(this.bruteUpdatePendingCardsInBackground)
  };
  deleteCard = async (cardId: string) => {
    return this.service.deleteCard(cardId).then(this.bruteUpdatePendingCardsInBackground);
  };
  // external gifts crud
  addExternalGiftToCard = (code: string, payload: AnyGiftPayload) => {
    return this.service
      .saveAnyGiftToCard(code, payload)
      .then(this.bruteUpdatePendingCardsInBackground);
  };
  updateExternalGiftToCard = (code: string, payload: Partial<AnyGiftPayload>) => {
    return this.service.updateAnyGift(code, payload);
  };
  deleteExternalGiftOfACard = async (code: string) => {
    return this.service.deleteExternalGift(code).then(this.bruteUpdatePendingCardsInBackground);
  };

  // shopigy gifts addition
  addShopifyGiftToCard = (code: string, payload: MerchantGiftCreateOrUpdatePayloadDTO) => {
    return this.service
      .saveProductToCard(code, payload)
      .then(this.bruteUpdatePendingCardsInBackground);
  };
  updateShopifyGiftOfACard = (code: string, payload: MerchantGiftCreateOrUpdatePayloadDTO) => {
    return this.service
      .updateSavedGift(code, payload)
      .then(this.bruteUpdatePendingCardsInBackground);
  };
  deleteShopifyGiftOfACard = async (code: string) => {
    return this.service.deleteGift(code).then(this.bruteUpdatePendingCardsInBackground);
  };

  // miscs
  inviteContributors = async (code: string, payload: { inviteContacts: string[] }) => {
    return this.service
      .inviteContributors(code, payload)
      .then(this.bruteUpdatePendingCardsInBackground);
  };
  // card sending
  sendCard = async (code: string, p: SendCardPayload) => {
    return this.service.sendCard(code, p).then(this.bruteUpdatePendingCardsInBackground);
  };

  // derived states
  get hasMoreThanTwoInitiatorCards() {
    let length;
    const pendingState = this.asyncCachedCardsMap.get(MyCardsType.PENDING)?.state;
    const senCardState = this.asyncCachedCardsMap.get(MyCardsType.SENT)?.state;
    if (
      pendingState &&
      'data' in pendingState &&
      pendingState.data &&
      senCardState &&
      'data' in senCardState &&
      senCardState.data
    ) {
      length = pendingState.data.length + senCardState.data.length;
    }

    return length ? length > 2 : false;
  }
}
