import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { makeAutoObservable, observable } from 'mobx';

import { track } from 'services/analytics';

export class FormData<FormValues> {
  task: ObservablePromise<any>;
  @observable data?: FormValues;
  _defaultValues?: Partial<FormValues> = {};
  constructor(
    taskPromise: (data: FormValues) => Promise<any>,
    private _taskName?: string,
    defaultValues?: FormValues,
  ) {
    makeAutoObservable(this);
    this.task = new ObservablePromise(taskPromise);
    this._defaultValues = defaultValues ? { ...defaultValues } : undefined;
    this.data = defaultValues ? { ...defaultValues } : undefined;
  }
  setData(data: FormValues) {
    this.data = data;
  }
  patchData(data: Partial<FormValues>) {
    const prevData = this.data as FormValues;
    this.data = {
      ...prevData,
      ...data,
    };
  }
  reset() {
    this.data = this._defaultValues ? { ...this._defaultValues } : undefined;
  }

  /**
   *
   * @deprecated
   */
  completeAndWait(formValues: FormValues) {
    this.data = formValues;
    track(this._taskName, formValues);
    return this.task.execute(this.data);
  }

  submit(processedData?: any) {
    const payload = processedData || this.data; //default to raw data
    return this.task.execute(payload);
  }
}
