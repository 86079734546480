import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { colors } from 'constants/design/index';
import { useWindowDimensions } from 'hooks';

const useStyles = makeStyles((theme) => ({
  hiddenWords: {
    background: colors.contrastText,
    height: ({ isSmall }) => (isSmall ? 20 : 48),
    borderRadius: (isSmall) => !isSmall && 8,
    marginBottom: ({ isSmall }) => isSmall && '-5px',

    [theme.breakpoints.up('md')]: {
      height: ({ isSmall }) => (isSmall ? 35 : 48),
      marginBottom: ({ isSmall }) => isSmall && -theme.spacing(1),
    },
  },
}));

const HiddenWords = ({ charLength, isSmall }) => {
  const { width: screenWidth } = useWindowDimensions();
  const referenceSentence = 'hello world hello world'; // approx 250px
  const row = Math.ceil(
    (charLength * (isSmall ? 750 : 250)) / (referenceSentence.length * screenWidth),
  );
  const hiddenRow = isSmall && row > 4 ? 4 : row;
  const classes = useStyles({ isSmall });

  return (
    <Grid container>
      {Array.from({ length: hiddenRow }).map((_, index) => {
        const last = index === hiddenRow - 1;
        const isPartialWidth = last && hiddenRow > 1;
        return (
          <Grid item key={index} xs={isPartialWidth ? 8 : 12}>
            <Skeleton className={classes.hiddenWords} animation={false} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default HiddenWords;
