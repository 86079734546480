import { flexRender, Table as TableType } from '@tanstack/react-table';

import useStyles from './Table.styles';

export interface TableOptions {
  footer: boolean;
}

interface TableProps<T> {
  table: TableType<T>;
  options?: TableOptions;
  shadow: boolean;
}

function Table<T>(props: React.PropsWithChildren<TableProps<T>>) {
  const table = props.table;
  const options = props.options || { footer: false };
  const classes = useStyles({ shadow: props.shadow });
  return (
    <div className={classes.wrapper}>
      <table className={classes.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {options.footer && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
}

export default Table;
