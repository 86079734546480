import { ValueOf } from '@illume/shared';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { colors } from 'constants/design';

import { makeCustomStyles } from '../utils';
import { styleFunctions } from './BaseText.styles';

const useStyles = makeStyles((theme) => {
  function getFontsize(fontSize: number | { mobile: number; desktop: number }) {
    if (typeof fontSize === 'object') {
      const { mobile, desktop } = fontSize;
      return {
        fontSize: mobile,
        [theme.breakpoints.up('md')]: {
          fontSize: desktop,
        },
      };
    } else {
      return { fontSize };
    }
  }

  return {
    root: ({ align, hover, fontSize, ...rest }: any) => ({
      ...getFontsize(fontSize),
      '&:hover': hover,
      textAlign: align,
      ...rest,
    }),
  };
});

export interface BaseTextProps
  extends Omit<TypographyProps, 'color'>,
    Pick<
      React.CSSProperties,
      | 'fontWeight'
      | 'fontStyle'
      | 'maxWidth'
      | 'overflowWrap'
      | 'wordBreak'
      | 'lineHeight'
      | 'hyphens'
    > {
  component?: React.ElementType<any>;
  decoration?: React.CSSProperties['textDecoration'];
  hover?: React.CSSProperties;
  italic?: boolean;
  color?: ValueOf<typeof colors>;
  fontSize?:
    | React.CSSProperties['fontSize']
    | { desktop: React.CSSProperties['fontSize']; mobile: React.CSSProperties['fontSize'] };
}

const BaseText: React.FC<BaseTextProps> = ({
  align,
  children,
  fontWeight,
  color,
  italic,
  display,
  decoration,
  overflowWrap,
  hyphens,
  wordBreak,
  fontSize,
  hover,
  lineHeight,
  className,
  maxWidth,
  fontStyle,
  style,
  component = 'p',
  ...props
}) => {
  const styles = styleFunctions(italic, decoration);
  const classes = useStyles({
    fontSize,
    hover,
    align,
    fontWeight,
    color,
    overflowWrap,
    hyphens,
    wordBreak,
    lineHeight,
    maxWidth,
    fontStyle,
  });
  const textStyles = makeCustomStyles(styles, {});

  return (
    <Typography
      display={display}
      className={className}
      classes={classes}
      style={{ ...textStyles, ...style }}
      component={component}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default BaseText;
