import { CardGrid, CardTiles, Close, Preview } from 'components/illume/icons';
import { DownloadIcon } from 'components/illume/icons/DownloadIcon';

export const NOTIFICATION_TYPES = {
  INVITE_CONTRIBUTE: 'inviteContribute',
  RECEIVE_REPLY: 'receiveReply',
  READY_TO_SEND: 'readyToSend',
  RECEIVE_CARD: 'receiveCard',
  REMIND_CONTRIBUTE: 'remindContribute',
};

export const APPBAR_ICONS = {
  CLOSE: Close,
  PREVIEW: Preview,
  TILED: CardTiles,
  GRID: CardGrid,
  DOWNLOAD: DownloadIcon,
} as const;

export const PREVIEW_TOOLTIP_TEXT = 'preview what the recipient will see';
export const GRID_TOOLTIP_TEXT = 'tap here for a tiled view';
