import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { ISuggestionService } from 'domain/interfaces/ISuggestionService';
import logger from 'utils/logger';

export class SuggestionService implements ISuggestionService {
  constructor(private httpClient: IHttpClient) {}
  getSuggestions = async (ocassion: string) => {
    const requestObj = {
      url: `/suggestion/${ocassion}`,
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{ suggestions: string[] }>(requestObj);
    if (resp.success) {
      return resp.suggestions;
    } else {
      let errMsg = resp.message;
      if (!errMsg) {
        errMsg = 'Failed to get suggestions';
      }
      logger.error(errMsg, resp);
      throw Error(errMsg);
    }
  };
}
