import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  root: {
    fontSize: 18,
    fontFamily: 'Avenir-Black',
    color: ({ color }) => color,
  },
}));
export const IllumeText = ({ backgroundColor, onClick }) => {
  const color = backgroundColor;
  const classes = useStyle({ color });
  return (
    <div onClick={onClick}>
      <p className={classes.root}>illume</p>
    </div>
  );
};
