import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import ExperimentalVerificationProvider from 'components/experimental-verification-provider';
import { LOGIN_URL, LOGIN_EMAIL_URL } from 'constants/strings';

import Login from './Login';

const LoginRoutes = () => {
  return (
    <Switch>
      <ExperimentalVerificationProvider>
        <Route exact path={LOGIN_URL} component={Login} />
        <Route exact path={LOGIN_EMAIL_URL} render={() => <Login showEmailForm />} />;
      </ExperimentalVerificationProvider>
    </Switch>
  );
};

export default observer(LoginRoutes);
