import { useState } from 'react';

import { css } from '@emotion/css';
import { MyCardsType } from '@illume/shared';
import { Slide } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { ContributorsModal } from 'components/illume/contributors-modal';
import { Text } from 'components/illume/text';
import { KUDOBOARD_BLOG_URL, MY_CARDS_URL, SLACK_TEAMS_CARDS_URL } from 'constants/strings';
import { CardThumbnail } from 'domain/entities/card-thumbnail/CardThumbnail';
import { mq } from 'infra/emotion/breakpoints';
import { thinGrayScrollbar } from 'infra/emotion/scrollbars';
import { AnnouncmentToast } from 'pages/illume/my-cards/AnnouncementToast';
import ActivityIndicator from 'pages/illume/my-cards/components/activity-indicator';
import { LayoutV2 } from 'views/components-v2/elements/layout/LayoutV2';

import { CardList } from '../my-cards/CardList';
import { MyCardsV2Store } from '../my-cards/MyCardsV2.store';
import { ITab, TabContainer } from '../my-cards/TabContainer';

export const toggle = (prev: boolean) => !prev;

function Animate({ type, active, children }: AnimationProps) {
  if (type === 'mui') {
    return (
      <Slide direction="up" in={active} mountOnEnter>
        <div>{children}</div>
      </Slide>
    );
  } else {
    return (
      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ opacity: 0, y: 500 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 500 }}
            transition={{ type: 'spring', stiffness: 200, damping: 20 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}

export const TABS = [MyCardsType.PENDING, MyCardsType.SENT];
class UI {
  // how do I maintain currenct active tab?
  // is htis correct
  get tabs(): ITab[] {
    return TABS.map((tab, i) => ({
      id: i,
      state: tab,
      count: match(this.cardsStore.asyncCachedCardsMap.get(tab)?.state)
        .with(
          {
            data: P.not(P.nullish),
          },
          (state) => state.data.length,
        )
        .otherwise(() => 0),
    }));
  }
  // check with query params
  activeTab: ITab =
    this.tabs.find(
      (tab) => tab.state === new URLSearchParams(window.location.search).get('type'),
    ) || this.tabs[0];

  constructor(private cardsStore: MyCardsV2Store) {
    makeAutoObservable(this);
  }

  currentCardToInspect: CardThumbnail | null = null;
  setCurrentCardToInspect = (card: CardThumbnail | null) => {
    this.currentCardToInspect = card;
  };

  setActiveTab = (tab: ITab) => {
    this.activeTab = tab;
  };

  get currentAsyncCachedCards() {
    return this.cardsStore.asyncCachedCardsMap.get(this.activeTab.state);
  }

  get renderedCards() {
    return this.currentAsyncCachedCards &&
      'data' in this.currentAsyncCachedCards.state &&
      this.currentAsyncCachedCards.state.data
      ? this.currentAsyncCachedCards.state.data
          // TODO: conditional logic for other types
          .map((dto) => CardThumbnail.fromApiDTO(dto, this.activeTab.state))
      : [];
  }

  annoucementToastOpen = true;
  setAnnoucementToastOpen = (open: boolean) => {
    this.annoucementToastOpen = open;
  };
}

export const styles = {
  container: css`
    height: 100vh;
    ${thinGrayScrollbar}
    overflow-y: auto;
    ${mq.mobile} {
      padding: 0 24px;
      padding-top: 98px;
      padding-bottom: 50px;
    }
    ${mq.tablet} {
      padding: 0 40px;
      padding-top: 150px;
      padding-bottom: 100px;
    }
    ${mq.desktop} {
      padding: 0 90px;
      padding-top: 150px;
      padding-bottom: 100px;
    }
  `,
};

const MyCardsContent = observer(
  ({
    active,
    containerRef,
    cardStore,
    workspaceUrl,
  }: {
    active: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    cardStore: MyCardsV2Store;
    workspaceUrl: string;
  }) => {
    const [vm] = useState(() => new UI(cardStore));
    const history = useHistory();

    return (
      <>
        <div
          ref={containerRef}
          className={css`
            margin: 0 auto;
            max-width: 1024px;
          `}
        >
          <TabContainer
            title={'Team Cards'}
            selectedTab={vm.activeTab}
            data={vm.tabs}
            onTabClick={(tab) => {
              vm.setActiveTab(tab);
              history.push(`${window.location.pathname}?type=${tab.state}`);
              vm.currentAsyncCachedCards?.refresh();
            }}
          />
          <Animate type="mui" active={active}>
            <CardList
              className={css`
                margin-top: 32px;
              `}
              data={vm.renderedCards.map((card) => {
                const redirectToDetails = () => {
                  return match(card.type)
                    .with(MyCardsType.PENDING, () =>
                      history.push(`/team-cards/${workspaceUrl}/${card.code}`),
                    )
                    .with(MyCardsType.SENT, () =>
                      history.push(`${SLACK_TEAMS_CARDS_URL}/${card.code}`),
                    )
                    .with(MyCardsType.RECEIVED, () =>
                      history.push(`${MY_CARDS_URL}/received/${card.code}`),
                    )
                    .exhaustive();
                };

                return CardList.fromPendingCardDomain(
                  card,
                  redirectToDetails,
                  redirectToDetails,
                  () => {
                    vm.setCurrentCardToInspect(card);
                  },
                );
              })}
            />
          </Animate>
        </div>
        {match(vm.currentCardToInspect)
          .with(
            {
              contributorList: P.not(P.nullish),
            },
            (card) => {
              return (
                <ContributorsModal
                  inviteList={card.inviteList}
                  contributorList={card.contributorList}
                  show={true}
                  onClose={() => vm.setCurrentCardToInspect(null)}
                />
              );
            },
          )
          .otherwise(() => null)}
        <ActivityIndicator
          {...match(vm.currentAsyncCachedCards?.state)
            .with(
              {
                type: P.union('pending', 'refreshing', 'cache_retrieve'),
              },
              (state) => ({
                open: true,
                text: state.type === 'refreshing' ? 'refreshing' : 'loading',
              }),
            )
            .otherwise(() => ({
              open: false,
              text: '',
            }))}
        />
        <AnnouncmentToast
          open={vm.annoucementToastOpen}
          onClose={() => {
            return vm.setAnnoucementToastOpen(false);
          }}
          announcmentText={
            (
              <Text align="center" fontWeight={400} fontSize={{ mobile: 14, desktop: 16 }}>
                Major Announcement:{' '}
                <a
                  href={KUDOBOARD_BLOG_URL}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    textUnderlineOffset: '2px',
                  }}
                >
                  we are teaming up with Kudoboard
                </a>{' '}
                to ensure you have access to a terrific group card experience.{' '}
                <a href="https://www.tryillume.com">tryillume.com</a> will no longer be accessible
                after December 26th, 2023. Please download any notes before then if you’d like to
                save them. For a free upgrade on Kudoboard.com, create your first Kudoboard before
                January 31st & email illume@kudoboard.com with the subject line “upgrade.”
              </Text>
            ) as unknown as string
          }
        />
      </>
    );
  },
);

type AnimationProps = {
  type: 'mui' | 'css';
  active: boolean;
  children: React.ReactNode;
};

const TeamsCardsView: React.FC<{
  cardStore: MyCardsV2Store;
  workspaceUrl: string;
}> = ({ cardStore, workspaceUrl }) => {
  return (
    <LayoutV2>
      {({ navActive, userMenuActive, scrollRef }) => (
        <MyCardsContent
          workspaceUrl={workspaceUrl}
          active={!navActive && !userMenuActive}
          containerRef={scrollRef}
          cardStore={cardStore}
        />
      )}
    </LayoutV2>
  );
};

export const TeamsCards = observer(TeamsCardsView);
