import MuiLinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const LinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: colors.backgroundGray,
  },
  bar: {
    borderRadius: 5,
  },
}))(MuiLinearProgress);

export default LinearProgress;
