import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
      borderRadius: 8,
      border: `1px solid ${colors.gray60}`,
      boxShadow: `10px 10px 0px 0px ${colors.backgroundGray}`,
    },
    table: {
      width: '100%',
      borderStyle: 'hidden',
      '& thead': {
        background: colors.gray100,
        '& tr': {},
        '& th': {
          color: colors.contrastText,
          fontWeight: 400,
          padding: '4px 0',
        },
      },
      '& tbody': {
        '& td': {
          padding: '10px 8px',
          border: `1px solid ${colors.gray40}`,
          fontSize: 10,
        },
        '& tr:nth-of-type(even)': {
          background: '#f5f0e2',
        },
      },
    },
  }),
);

export default useStyles;
// .il-table {
//   border-radius: 6px;
//   overflow: hidden;
// 	border-collapse: collapse;

//   tbody {
//     border-bottom: 1px solid lightgray;
//   }

//   th,
//   td {
//     padding: 1em;
//     background: #ddd;
//     border-bottom: 2px solid white;
//   }

//   tfoot {
//     color: gray;
//   }

//   tfoot th {
//     font-weight: normal;
//   }
// }
