import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import Button from 'components/illume/buttons/Button';
import { Pencil, Blob } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { EDIT_PROFILE_URL } from 'constants/strings';

const useStyle = makeStyles(() => ({
  letter: {
    zIndex: 1000,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  blob: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: ({ size }) => size,
    height: ({ size }) => size,
  },
  root: {
    position: 'relative',
    width: ({ size }) => size,
    height: ({ size }) => size,
  },
  button: { position: 'absolute', marginTop: '46%' },
  avatarButton: {
    background: 'transparent',
    boxShadow: 'none',
  },
}));

/**
 * TODO: hope that this component only used in profile page, since it's not that responsive. or at least have size variants
 * @param {Object} props
 * @param {string} props.name - user's name
 * @param {number} props.size - size in px
 */
export const AvatarInitialName = ({ name, size = 225, editable = false }) => {
  const classes = useStyle({ size });
  const fontSize = (size) => (8 * 16 * size) / 225;
  return (
    // TODO: it seems like there's better way to do this without editing the svg manually
    <Grid container alignItems="center" direction="column">
      <Grid item className={classes.root}>
        <Box className={classes.letter}>
          <Text color={colors.contrastText} align="center" fontWeight={700} fontSize={fontSize()}>
            {name[0].toUpperCase()}
          </Text>
        </Box>
        <Box width={size} className={classes.blob}>
          <Blob size={size} />
        </Box>
      </Grid>
      {editable && (
        <Grid item className={classes.button}>
          <Button className={classes.avatarButton}>
            <Link to={EDIT_PROFILE_URL}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Pencil showPerson size={20} style={{ margin: 0 }} />
                </Grid>
                <Grid item>
                  <Text color={colors.contrastText} size={rem[1125]}>
                    add photo
                  </Text>
                </Grid>
              </Grid>
            </Link>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
