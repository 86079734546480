import logger from 'utils/logger';

import parseProgress from './parseProgress';

const getCore = async ({ onProgress }) => {
  const resolves = {};
  let resolveExit = null;
  let _id = 0;
  const getID = () => _id++;
  const worker = new Worker('/ffmpeg-st/worker.js');

  const getHandler = (payload) =>
    new Promise((resolve) => {
      const id = getID();
      worker.postMessage({ id, ...payload });
      resolves[id] = resolve;
    });

  worker.onmessage = (e) => {
    const { id, data, type, message } = e.data;

    // TODO: I believe this is a bit sloppy
    if (type === 'INFO') {
      parseProgress(message, onProgress);
    } else {
      resolves[id](data);
    }
  };

  await getHandler({ type: 'INIT' });

  return {
    getHandler,
    exit: () =>
      new Promise((resolve) => {
        worker.terminate();
        resolveExit = resolve;
        resolveExit();
      }),
    FS: ['mkdir', 'writeFile', 'readFile'].reduce((acc, cmd) => {
      acc[cmd] = (...args) => getHandler({ type: 'FS', cmd, args });
      return acc;
    }, {}),
  };
};

const ffmpeg = ({ core, args }) => core.getHandler({ type: 'RUN', args });

export const runFFmpeg = async (inputFileName, data, args, outputFileName, onProgress) => {
  const core = await getCore({ onProgress });
  await core.FS.writeFile(inputFileName, data);
  await ffmpeg({ core, args });
  logger.log('calling ffmpeg with', { core, args });
  const file = await core.FS.readFile(outputFileName);
  await core.exit();
  return { file };
};

export default runFFmpeg;
