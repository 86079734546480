import { AxiosRequestConfig } from 'axios';

import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IMerchantProductsService } from 'domain/interfaces/IMerchantGiftService';
import { MerchantProductDTO } from 'types';
import { CustomError, wait } from 'utils';

export type FeaturedProductLineupDTO = {
  products: MerchantProductDTO[];
  vendor: string;
  productsTitle: string;
  shortDesc: string;
  longDesc: string;
  bannerImage: string;
  thumbnailImage: string;
};

export class MerchantProductsService implements IMerchantProductsService {
  getBestSellingMerchantProducts: () => Promise<MerchantProductDTO[]> = async () => {
    const requestObj = {
      url: '/cardGift/merchantProducts/best',
      method: 'get',
    };
    const resp = await this.httpClient.makeRequest<{ bestSellerProducts: MerchantProductDTO[] }>(
      requestObj,
    );
    if (resp.success) {
      return resp.bestSellerProducts;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };
  getMerchantProducts = async (config?: AxiosRequestConfig) => {
    const requestObj = {
      url: '/cardGift/merchantProducts',
      method: 'get',
      ...config,
    };

    const resp = await this.httpClient.makeRequest<{ products: MerchantProductDTO[] }>(requestObj);
    if (resp.success) {
      return resp.products;
    } else {
      if (resp.message[0].includes('"Shopify is throttling requests')) {
        const res = await wait(2100).then(() =>
          this.httpClient.makeRequest<{ products: MerchantProductDTO[] }>(requestObj),
        );
        if (res.success) {
          return res.products;
        } else {
          throw new Error('oops! something went fetching the data');
        }
      }
      throw new Error('oops! something went fetching the data');
    }
  };

  getMerchantProductsByOccassion = async (occasion: string): Promise<MerchantProductDTO[]> => {
    // not recommended to have GET request with body
    // this is due to mistake in the API design
    // and as now(2021-05-05) we have deployment issues
    return this.getMerchantProducts({
      data: { occasion },
      transformRequest: [(data) => JSON.stringify(data)],
    });
  };

  getMerchantProduct = async (listingId: string) => {
    const requestObj = {
      url: `/cardGift/merchantProducts/${listingId}`,
      method: 'get',
    };

    const resp = await this.httpClient.makeRequest<MerchantProductDTO>(requestObj);
    if (resp.success) {
      return resp;
    } else {
      const { message } = resp || {};
      const errMsg = message || 'failed to get merchant product ';
      throw CustomError.fromServiceErrorDto({ ...resp, message: errMsg });
    }
  };

  getFeaturedProductLine = async () => {
    const requestObj = {
      url: '/cardGift/merchantProducts/featured',
      method: 'get',
    };

    const resp = await this.httpClient.makeRequest<{ products: FeaturedProductLineupDTO[] }>(
      requestObj,
    );

    if (resp.success) {
      return resp.products;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  constructor(private httpClient: IHttpClient) {}
}
