import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ActionableGiftThumbnail from 'components/actionable-gift-thumbnail';
import { Button } from 'components/illume/buttons';
import { HtmlRenderer } from 'components/illume/html-renderer';
import { LeftArrow } from 'components/illume/icons';
import { ShipGiftModal } from 'components/illume/ship-gift-modal';
import { Surface } from 'components/illume/surface';
import { Text } from 'components/illume/text';
import { TextInput } from 'components/illume/text-inputs';
import { IllumeLayout } from 'components/layouts';
import { colors } from 'constants/design/index';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import { GiftMerchantProduct } from 'domain/entities/gift/gift';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { HighlightDTO, Maybe, ShopifyShippingInfo } from 'types';

import useStyles from './ShippingFormEmailOnly.styles';

const shippingFormSchema = yup.object().shape({
  email: yup.string().email('must be a valid email').required('email is required'),
});

export type ShopifyShippingInfoOnlyEmail = Pick<ShopifyShippingInfo, 'email'>;

export interface ShippingFormProps {
  data: IShippingFormProduct;
  onSubmitted: (formState: ShopifyShippingInfoOnlyEmail) => any;
  onGoBack: () => any;
}

interface IShippingFormProduct {
  name: string;
  description: string;
  img: string;
  vendor: Maybe<string>;
  highlights: Maybe<HighlightDTO[]>;
}

const ShippingFormEmailOnly = ({ data, onGoBack, onSubmitted }: ShippingFormProps) => {
  const classes = useStyles();
  const desktop = useIsDesktop();

  const [showGiftModal, setShowGiftModal] = useState(false);

  // const { id: sendCode } = store.card!;

  const product = data;

  const { handleSubmit, formState, register, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(shippingFormSchema),
  });

  const onViewGiftDetails = () => {
    setShowGiftModal(true);
  };

  const formattedProductTitle = `${product.name} Gift Card`;
  const formattedVendor = `By ${product.vendor}`;

  function renderTitleAndSub() {
    return (
      <div className="title-and-sub">
        <Text fontWeight={900} fontSize={{ mobile: 22, desktop: 36 }}>
          {`let’s send your gift! `.toUpperCase()}
        </Text>
        <Text fontSize={16} fontWeight={300}>
          The group came together to get you a {product.name}. You’ll receive the gift card
          information to your email within 24 hours.
        </Text>
      </div>
    );
  }

  return (
    // Commented because header on mobile and desktop is different on figma
    <IllumeLayout contentProps={{ direction: 'column', alignItems: 'center' }}>
      <div className={classes.container}>
        <div onClick={onGoBack} className={classes.leftArrow}>
          <LeftArrow blobColor={colors.backgroundGray} arrowColor={colors.gray100} size={32} />
        </div>
        <div className={classes.leftContent}>
          <div className={classes.giftThumbnailContainer}>{renderTitleAndSub()}</div>

          <ActionableGiftThumbnail
            overlayAlwaysActive={!desktop}
            imageMode="contain"
            alt="product"
            className={classes.thumbnail}
            action={{
              content: 'View Details',
              onAction: () => onViewGiftDetails(),
            }}
            img={product.img}
          />

          <div className={classes.infoContainer}>
            <div className="titleAndSub">
              <Text fontWeight={'bold'} fontSize={28}>
                {formattedProductTitle}
              </Text>
              <Text fontSize={16} fontWeight={300}>
                {formattedVendor}
              </Text>
            </div>
            <div className="desc">
              <Text fontSize={14} fontWeight={330} italic>
                {<HtmlRenderer html={product.description} />}
              </Text>
            </div>

            {product.highlights && (
              <div className={classes.tags}>
                {product.highlights.map(({ highlight, color }) => {
                  return (
                    <Surface
                      rounded={['top', 'bottom']}
                      paddingX={1}
                      paddingY={0.5}
                      borderRadius={4}
                      background={color}
                      key={highlight}
                    >
                      <Text fontSize={{ desktop: 14, mobile: 14 }} color={colors.contrastText}>
                        {highlight}
                      </Text>
                    </Surface>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className={classes.rightContent}>
          {renderTitleAndSub()}
          <form className="input-and-cta" onSubmit={handleSubmit(onSubmitted)}>
            <TextInput
              error={errors.email}
              name="email"
              inputRef={register}
              placeholder="Email address"
            />
            <div>
              <Button disabled={formState.isSubmitting} style={{ marginBottom: 8 }} fullWidth>
                Send my gift
              </Button>
              <Text fontSize={12} align="center" fontWeight={390} italic color={colors.gray40}>
                By clicking “Send my gift” agree to illume's privacy and terms of service.
              </Text>
            </div>
          </form>
        </div>
      </div>
      {product && (
        <ShipGiftModal
          product={{
            description: product.description,
            image: product.img,
            name: product.name,
            vendor: product.vendor,
          }}
          showModal={showGiftModal}
          hideModal={() => setShowGiftModal(false)}
          tags={product.highlights?.map((h, index) => ({
            text: h.highlight,
            color: h.color,
            id: index.toString(),
          }))}
        />
      )}
    </IllumeLayout>
  );
};

ShippingFormEmailOnly.productDataFromGiftMerchantProduct = (
  gift: GiftMerchantProduct,
): IShippingFormProduct => ({
  description: gift.description,
  img: gift.variant.img,
  name: gift.name,
  highlights: gift.highlights,
  vendor: gift.vendor,
});

ShippingFormEmailOnly.productDataFromExternalGift = (gift: ExternalGift): IShippingFormProduct => ({
  description: gift.description,
  img: gift.image,
  name: gift.title,
  highlights: null,
  vendor: null,
});

export default observer(ShippingFormEmailOnly);
