export const DisabledLock = ({ color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.661 10.6964H13.2654L13.2638 8.78156C13.2622 6.69556 11.5149 4.99845 9.36706 5C7.21928 5.00156 5.4719 6.69867 5.4735 8.78467L5.47671 10.6996H5.33576C4.59741 10.7011 3.9984 11.2829 4 12V17.7011C4 18.4182 4.60061 19 5.33897 19L13.6642 18.9953C14.4026 18.9953 15.0016 18.4136 15 17.6964V11.9938C15 11.2767 14.3994 10.6964 13.661 10.6964ZM6.91177 10.6996L6.90856 8.78467C6.90856 7.46711 8.01048 6.39533 9.36706 6.39378C10.7236 6.39378 11.8272 7.46556 11.8272 8.78156L11.8304 10.6964L6.91177 10.6996Z"
        fill={color}
      />
    </svg>
  );
};
