import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

const SELECT_THEME_FORM_MAX_WIDTH = 800; // from figma
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6.5),
    marginTop: theme.spacing(2),
  },
  progressCircle: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  selectTheme: {
    maxWidth: SELECT_THEME_FORM_MAX_WIDTH,
    margin: '0 auto',
    paddingLeft: theme.spacing(6.5),
    paddingTop: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    marginTop: theme.spacing(8),
  },
  themeSelector: {
    marginTop: theme.spacing(2),
  },
  note: {
    width: '100%',
    marginTop: theme.spacing(8),
  },
  name: {
    marginTop: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(10),
  },
  arrowLeft: {
    display: 'block',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  arrowRight: {
    display: 'block',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  footerText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  loading: {
    color: colors.contrastText,
  },
}));
