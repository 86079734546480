export const Info = ({ color = '#282829', size = 14, isBlob = false, ...props }) => {
  const renderCircle = ({ color }) => (
    <svg
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 450 450"
      x="0px"
      y="0px"
      {...props}
    >
      <title>more info</title>
      <g data-name="Calque 2">
        <g data-name="Calque 2">
          <circle cx="225" cy="133.5" r="31.25" />
          <path d="M251,219.12v103a26,26,0,0,1-52,0v-103a26,26,0,0,1,52,0Z" />
          <path d="M225,0C100.74,0,0,100.74,0,225S100.74,450,225,450,450,349.26,450,225,349.26,0,225,0ZM355.81,355.81A185,185,0,1,1,94.19,94.19,185,185,0,1,1,355.81,355.81Z" />
        </g>
      </g>
    </svg>
  );

  const renderBlob = () => (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00608311 10.4047C-0.122278 7.80472 1.78948 5.03592 4.91656 2.93414C8.25394 0.690783 11.9546 -0.215814 15.9419 0.0428245C18.2415 0.18984 20.4072 0.816019 22.2261 2.32157C23.4087 3.29895 24.3236 4.49958 25.1129 5.80639C26.5057 8.10964 27.6719 10.5109 28.3301 13.1381C29.2423 16.7754 28.3519 19.9852 26.0251 22.8412C23.5862 25.8332 20.3444 27.3524 16.5673 27.8533C13.9264 28.2045 11.3537 27.9268 8.85478 27.0012C5.80416 25.8713 3.61384 23.7832 2.23464 20.881C0.85818 17.9896 0.107133 14.9323 0.00608311 10.4047Z"
        fill="#282829"
      />
      <rect x="12.5469" y="7.34131" width="3.27931" height="13.6638" fill="#FFFDF3" />
      <path
        d="M14.1853 25.0132C16.4373 25.0132 18.6388 24.3454 20.5113 23.0942C22.3838 21.8431 23.8432 20.0647 24.705 17.9841C25.5669 15.9035 25.7924 13.6141 25.353 11.4053C24.9137 9.19657 23.8292 7.16769 22.2368 5.57526C20.6443 3.98283 18.6155 2.89838 16.4067 2.45903C14.1979 2.01968 11.9085 2.24517 9.82789 3.10698C7.74728 3.9688 5.96896 5.42823 4.7178 7.30073C3.46663 9.17323 2.79883 11.3747 2.79883 13.6267C2.80365 16.6451 4.00484 19.5385 6.13917 21.6729C8.27351 23.8072 11.1669 25.0084 14.1853 25.0132ZM14.1853 7.93348C14.4105 7.93348 14.6307 8.00026 14.8179 8.12538C15.0052 8.25049 15.1511 8.42833 15.2373 8.63639C15.3235 8.84445 15.346 9.07339 15.3021 9.29427C15.2581 9.51514 15.1497 9.71803 14.9905 9.87727C14.8312 10.0365 14.6283 10.145 14.4074 10.1889C14.1866 10.2328 13.9576 10.2103 13.7496 10.1241C13.5415 10.0379 13.3637 9.89198 13.2386 9.70473C13.1134 9.51748 13.0467 9.29733 13.0467 9.07213C13.0467 8.77014 13.1666 8.48052 13.3802 8.26698C13.5937 8.05344 13.8833 7.93348 14.1853 7.93348ZM13.0467 12.4881C13.0467 12.1861 13.1666 11.8965 13.3802 11.6829C13.5937 11.4694 13.8833 11.3494 14.1853 11.3494C14.4873 11.3494 14.7769 11.4694 14.9905 11.6829C15.204 11.8965 15.324 12.1861 15.324 12.4881V18.1813C15.324 18.4833 15.204 18.7729 14.9905 18.9865C14.7769 19.2 14.4873 19.32 14.1853 19.32C13.8833 19.32 13.5937 19.2 13.3802 18.9865C13.1666 18.7729 13.0467 18.4833 13.0467 18.1813V12.4881Z"
        fill="#282829"
      />
    </svg>
  );

  return isBlob ? renderBlob() : renderCircle({ color });
};
