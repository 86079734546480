import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { RightArrowButton } from 'components/illume/nav-arrows';
import Header1 from 'components/illume/text/Header1';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { SentryErrorFallback } from 'components/sentry-error-fallback';
import { colors, rem, spacing } from 'constants/design';
import { RECEIVE_READ_URL } from 'constants/strings';
import Sentry from 'infra/sentry/sentry';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    justifyContent: 'center',
  },
  headerBox: {
    fontWeight: 'bold',
    color: colors.contrastText,
    paddingTop: theme.spacing(5),
  },
  header: {
    fontSize: rem[3750],
    fontWeight: 'bold',
  },
  textBox: {
    paddingTop: theme.spacing(2),
    color: colors.contrastText,
    fontSize: rem[1000],
  },
  buttonBox: {
    marginTop: spacing[12],
  },
}));

const Welcome = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
      <IllumeLayout background={colors.primary}>
        <Grid container className={classes.container}>
          <Grid className={classes.headerBox}>
            <Header1 className={classes.header}>welcome to illume!</Header1>
          </Grid>
          <Grid className={classes.textBox}>
            <Text>
              At illume, we make it easier than ever to share gratitude and praise. <br /> <br />
              By joining illume, you’re agreeing to be a part of a community of beamers. Beamers are
              people who believe in spreading love and positivity. Not only because it makes others
              feel good, but because it makes the world a better place. <br />
              <br />
              Now, let’s spread some light.
            </Text>
          </Grid>
          <Grid className={classes.buttonBox}>
            <RightArrowButton
              onClick={() => history.push(RECEIVE_READ_URL)}
              data-testid="right-arrow"
              color={colors.primary}
            />
          </Grid>
        </Grid>
      </IllumeLayout>
    </Sentry.ErrorBoundary>
  );
};

export default Welcome;
