import { FunctionComponent } from 'react';

import { colors } from 'constants/design';

interface ConfettiIconProps {
  color: string;
  size: number;
}

const ConfettiIcon: FunctionComponent<ConfettiIconProps> = ({
  color = colors.backgroundGray60,
  size = 26,
}) => {
  return (
    <svg
      width={size}
      height={(27 * size) / 26}
      viewBox="0 0 26 27"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path d="M1.75099 26.8997H2.02099L9.851 24.1097C9.49278 22.7517 8.79145 21.5086 7.81429 20.4999C6.83703 19.4912 5.61687 18.7508 4.27092 18.3496L0.940914 25.6396C0.820446 25.9154 0.845946 26.2332 1.0087 26.4865C1.17144 26.7396 1.44998 26.8946 1.75092 26.8996L1.75099 26.8997Z" />
      <path d="M11.5611 23.4801L14.6211 22.4001C14.8289 22.319 14.9992 22.1636 15.0991 21.964C15.1988 21.7645 15.221 21.535 15.1611 21.3201C14.7243 19.4157 13.7706 17.6687 12.4051 16.2714C11.0396 14.8741 9.31489 13.8805 7.42105 13.4001C7.21696 13.3341 6.99599 13.3432 6.79799 13.4257C6.5999 13.5082 6.43789 13.6587 6.34105 13.8501L5.08105 16.5501C6.65502 17.0703 8.07313 17.9774 9.20521 19.1884C10.3373 20.3992 11.1474 21.875 11.561 23.4801L11.5611 23.4801Z" />
      <path d="M13.0012 12.0499H12.4612C12.0637 12.03 11.6685 12.1204 11.319 12.3109C10.9696 12.5014 10.6797 12.7849 10.4812 13.1299C10.3543 13.3429 10.321 13.5991 10.3891 13.8376C10.4573 14.0762 10.6208 14.2759 10.8412 14.3899H11.2012C11.3643 14.3954 11.5259 14.3564 11.6686 14.2771C11.8112 14.1979 11.9297 14.0812 12.0112 13.9398H12.5512C12.9487 13.9597 13.344 13.8693 13.6934 13.6788C14.0428 13.4883 14.3328 13.2048 14.5312 12.8598C14.6751 12.4804 14.7092 12.0681 14.6297 11.6702C14.5501 11.2722 14.36 10.9048 14.0812 10.6098L13.8112 10.1598H14.3512C14.7487 10.1797 15.144 10.0893 15.4934 9.89875C15.8428 9.70825 16.1328 9.42485 16.3312 9.07985C16.4582 8.86675 16.4914 8.61062 16.4233 8.37213C16.3551 8.13354 16.1916 7.93375 15.9712 7.81985C15.7667 7.72122 15.5326 7.7035 15.3156 7.77025C15.0986 7.8371 14.9148 7.98335 14.8012 8.17984H14.2612C13.8637 8.15996 13.4685 8.25034 13.119 8.44093C12.7696 8.63143 12.4797 8.91484 12.2812 9.25984C12.1373 9.63925 12.1032 10.0515 12.1828 10.4495C12.2624 10.8475 12.4524 11.2149 12.7312 11.5098L13.0012 12.0499Z" />
      <path d="M19.2108 12.4101H19.7508L20.8309 12.5901C21.2638 12.5936 21.6819 12.4328 22.0009 12.1401C22.2797 11.8452 22.4697 11.4778 22.5493 11.0798C22.6289 10.6818 22.5948 10.2695 22.4509 9.89013C22.4069 9.71275 22.3768 9.53219 22.3609 9.35013H22.8109C23.1742 9.51794 23.5768 9.58159 23.9741 9.53387C24.3715 9.48625 24.7477 9.32913 25.0609 9.08013C25.2384 8.92404 25.3472 8.70429 25.3641 8.46861C25.381 8.23283 25.3044 7.99997 25.1509 7.82014C24.9948 7.64267 24.775 7.53382 24.5394 7.51695C24.3036 7.50007 24.0707 7.57667 23.8909 7.73013H23.3509C22.9876 7.56232 22.5849 7.49867 22.1876 7.54638C21.7903 7.59401 21.4141 7.75113 21.1009 8.00013C20.8221 8.29506 20.632 8.66248 20.5524 9.06044C20.4728 9.45841 20.5069 9.87073 20.6509 10.2501C20.6945 10.4276 20.7246 10.6081 20.7409 10.7901H20.2909C19.9276 10.6223 19.5249 10.5586 19.1276 10.6064C18.7303 10.654 18.3541 10.8111 18.0408 11.0601C17.8634 11.2162 17.7545 11.436 17.7376 11.6716C17.7208 11.9074 17.7974 12.1403 17.9508 12.3201C18.1069 12.4976 18.3267 12.6064 18.5624 12.6233C18.7981 12.6402 19.031 12.5636 19.2108 12.4101V12.4101Z" />
      <path d="M6.97075 7.90983C6.94563 8.1488 7.01716 8.38787 7.16922 8.57377C7.32138 8.75977 7.54161 8.87716 7.78075 8.89984H7.87075C8.09462 8.90096 8.31091 8.81855 8.4773 8.66883C8.64371 8.51902 8.74834 8.31258 8.77075 8.08983L9.22075 7.81983C9.59388 7.69327 9.92537 7.46706 10.1792 7.16564C10.433 6.86422 10.5996 6.49909 10.6608 6.10983C10.647 5.70886 10.5218 5.3196 10.2993 4.98586C10.0767 4.65202 9.76556 4.38679 9.40075 4.21982L8.95075 3.94982L9.40075 3.67982C9.77388 3.55326 10.1054 3.32705 10.3592 3.02563C10.613 2.72421 10.7796 2.35908 10.8408 1.96982C10.9232 1.7183 10.8907 1.44331 10.752 1.21794C10.6133 0.992476 10.3824 0.83956 10.1208 0.799816C9.89688 0.798691 9.68059 0.881097 9.5142 1.03082C9.34779 1.18063 9.24316 1.38707 9.22075 1.60982L8.77075 1.87982C8.36846 1.99888 8.00958 2.2325 7.7379 2.55211C7.46612 2.87179 7.29342 3.26366 7.24074 3.67982C7.25452 4.08079 7.37968 4.47005 7.60223 4.80379C7.82479 5.13763 8.13594 5.40286 8.50074 5.56983L8.95075 5.83983L8.50074 6.10983C8.09846 6.22889 7.73958 6.46251 7.4679 6.78212C7.19612 7.1018 7.02342 7.49367 6.97074 7.90983H6.97075Z" />
      <path d="M18.581 18.6195L19.031 18.9795C19.447 19.3826 19.9852 19.6359 20.561 19.6995H21.191C21.5991 19.5146 21.9396 19.2074 22.1654 18.8204C22.3911 18.4334 22.491 17.9858 22.451 17.5395C22.4712 17.3877 22.5013 17.2373 22.541 17.0895L22.991 17.3595C23.2681 17.6372 23.6156 17.8342 23.9961 17.9292C24.3766 18.0244 24.7759 18.0141 25.151 17.8995C25.3797 17.8185 25.566 17.6486 25.6677 17.4283C25.7693 17.2081 25.7777 16.9561 25.691 16.7295C25.61 16.5009 25.4401 16.3146 25.2198 16.2129C24.9996 16.1112 24.7476 16.1028 24.521 16.1895L24.071 15.8295C23.794 15.5518 23.4465 15.3549 23.0659 15.2598C22.6854 15.1647 22.2861 15.175 21.911 15.2895C21.5181 15.469 21.188 15.7622 20.9634 16.1312C20.7388 16.5002 20.6299 16.9281 20.651 17.3595C20.6309 17.5113 20.6008 17.6617 20.561 17.8095L20.111 17.5395C19.834 17.2618 19.4865 17.0649 19.1059 16.9698C18.7254 16.8747 18.3261 16.885 17.951 16.9995C17.7224 17.0805 17.5361 17.2504 17.4344 17.4707C17.3327 17.6909 17.3243 17.9429 17.411 18.1695C17.5093 18.382 17.6864 18.5477 17.9048 18.6317C18.1232 18.7157 18.3658 18.7113 18.581 18.6195V18.6195Z" />
      <path d="M16.1511 3.49961C16.1511 3.99667 15.7481 4.39961 15.2511 4.39961C14.754 4.39961 14.3511 3.99667 14.3511 3.49961C14.3511 3.00255 14.754 2.59961 15.2511 2.59961C15.7481 2.59961 16.1511 3.00255 16.1511 3.49961Z" />
      <path d="M3.55098 14.2994C3.55098 14.7965 3.14804 15.1994 2.65098 15.1994C2.15391 15.1994 1.75098 14.7965 1.75098 14.2994C1.75098 13.8024 2.15391 13.3994 2.65098 13.3994C3.14804 13.3994 3.55098 13.8024 3.55098 14.2994Z" />
      <path d="M20.6511 24.1998C20.6511 24.6969 20.2481 25.0998 19.7511 25.0998C19.254 25.0998 18.8511 24.6969 18.8511 24.1998C18.8511 23.7027 19.254 23.2998 19.7511 23.2998C20.2481 23.2998 20.6511 23.7027 20.6511 24.1998Z" />
      <path d="M4.45088 8.9C4.45088 9.39706 4.04794 9.8 3.55088 9.8C3.05381 9.8 2.65088 9.39706 2.65088 8.9C2.65088 8.40294 3.05381 8 3.55088 8C4.04794 8 4.45088 8.40294 4.45088 8.9Z" />
      <path d="M25.1511 4.4C25.1511 4.89706 24.7481 5.3 24.2511 5.3C23.754 5.3 23.3511 4.89706 23.3511 4.4C23.3511 3.90294 23.754 3.5 24.2511 3.5C24.7481 3.5 25.1511 3.90294 25.1511 4.4Z" />
    </svg>
  );
};

export default ConfettiIcon;
