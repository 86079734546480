import { useState } from 'react';

import { observer } from 'mobx-react';

import { IllumeLayout } from 'components/layouts';
import { NoteFormRawValues } from 'components/reusable-forms/note-form/NoteForm';
import { DEFAULT_PALETTE } from 'constants/design';
import { Palette } from 'constants/design/cardTheme';
import { APPBAR_ICONS, PREVIEW_TOOLTIP_TEXT } from 'constants/types';
import { useCardContext } from 'contexts/card';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { SelectThemeForm } from 'pages/illume/select-theme-form';
import { SelectThemeFormValues } from 'pages/illume/select-theme-form/SelectThemeForm';
import { ContributorCardDTO, InitiatorCardViewDTO, PaletteDTO } from 'types';

interface ISelectThemeNote
  extends Pick<NoteFormRawValues, 'message' | 'signature' | 'photos' | 'videos' | 'gifs'> {
  palette?: string;
}

interface ISelectThemeProps {
  card: InitiatorCardViewDTO | ContributorCardDTO;
  note: ISelectThemeNote;
  onSubmit: (data: SelectThemeFormValues) => any;
  onBack: () => any;
  submitting: boolean;
  defaultPalette: PaletteDTO;
}

const SelectTheme: React.FC<ISelectThemeProps> = ({
  card,
  note,
  onBack,
  onSubmit,
  submitting = false,
  defaultPalette,
}) => {
  const {
    message,
    signature,
    photos,
    videos,
    gifs,
    // TODO:
    // probably use the raw values instead of the code
    // so we don\'t transform the palette to generate background back and forth
    palette: paletteCode,
  } = note;
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { getThemepack, getPaletteDto } = useCardContext();
  const themepack = getThemepack(card.themepack);
  const notePalette = paletteCode && getPaletteDto(paletteCode);
  const [paletteDto, setPaletteDto] = useState<PaletteDTO>(
    defaultPalette || notePalette || themepack?.palettes[0] || DEFAULT_PALETTE,
  );
  const bg = new Palette(paletteDto).generateBackground();
  const desktop = useIsDesktop();

  const handlePaletteChange = (palette: PaletteDTO) => {
    setPaletteDto(palette);
  };
  return (
    <IllumeLayout
      topRightButtons={[
        {
          tooltipText: PREVIEW_TOOLTIP_TEXT,
          icon: <APPBAR_ICONS.PREVIEW size={desktop ? 51 : 44} />,
          action: {
            onAction: () => setShowPreviewModal(true),
          },
        },
      ]}
      background={bg}
    >
      <SelectThemeForm
        onPaletteChange={handlePaletteChange}
        value={paletteDto}
        palettes={themepack?.palettes || []}
        submitting={submitting}
        message={message}
        signature={signature}
        onBack={onBack}
        onSubmit={onSubmit}
        photos={photos}
        videos={videos}
        gifs={gifs}
        setShowPreviewModal={setShowPreviewModal}
        showPreviewModal={showPreviewModal}
      />
    </IllumeLayout>
  );
};

export default observer(SelectTheme);
