import { css, cx } from '@emotion/css';
import { Maybe } from '@illume/shared';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { colors } from 'constants/design';
import { ColorsV2 } from 'domain/theme/colorsV2';
import { Action } from 'types';
import { Avatar } from 'views/components-v2/core/avatar';
import Button from 'views/components-v2/core/button';
import { ChevronDownAndClose } from 'views/components-v2/core/icons/ChevronDown';
import { ArrowAndDivider } from 'views/components-v2/core/icons/Logout';
import { NotificationIcon as NotificationIcon } from 'views/components-v2/core/icons/NotificationIcon';

import { getRippleContext } from '..';
import { createRipple } from '../createRipple';
import { getWiggleOnHoverStyle } from '../getWiggleOnHoverStyle';
import { PencilIcon } from '../icons/PencilIcon';
import menuBg from './medium_to_large_screen_menu_bg.svg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  open: boolean;
}

const openStyle = css`
  background-color: ${colors.background};
  border-radius: 45%;
  & > svg {
    fill: ${ColorsV2.gray100};
  }
`;
const normalStyle = css`
  transition: border-radius 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 20%;
`;
export function ChevronDownButtonWithHoverEffect({ onClick, className, open, ...props }: Props) {
  return (
    <button
      onClick={(e) => {
        createRipple(e);
        onClick?.();
      }}
      className={classNames(
        'chevron-button',
        open ? openStyle : normalStyle,
        getRippleContext(),
        className,
      )}
      {...props}
    >
      <ChevronDownAndClose
        open={open}
        className={classNames(
          css`
            fill: ${ColorsV2.gray40};
            transition: fill 0.2s ease-in-out;
            // smaller when open
            scale: ${open ? 0.85 : 1};
          `,
        )}
      />
    </button>
  );
}

interface UserMenuProps {
  active: boolean;
  className?: string;
  user: Maybe<{
    name: string;
    subtitle: string;
    avatar: string;
  }>;
  logoutAction: Action;
  editProfileAction: Action;
  showNotificationDot: boolean;

  onChevronClick?: () => void;
  onProfileContainerClick?: () => void;
  onNotificationClick: () => void;
}
export const UserMenu = ({
  onChevronClick,
  active,
  onProfileContainerClick,
  className,
  user,

  logoutAction,
  editProfileAction,
  showNotificationDot: showRedDot,
  onNotificationClick,
}: UserMenuProps) => {
  const inactiveClase = css`
    &:hover {
      .avatar {
        transform: scale(1.05);
      }

      .beamer-copy {
        color: ${ColorsV2.gray80};
        // move top a bit
        transform: translateY(-1px);
      }

      .name-copy {
        transform: translateY(-1px);
      }

      .chevron-button {
        background-color: ${colors.primary};
        border-radius: 45%;
        & > svg {
          fill: ${ColorsV2.gray80};
        }
      }
    }
  `;

  function baseStyles() {
    return css`
      position: absolute;
      top: -20px;
      right: -20px;
      z-index: -1;
      transform-origin: top right;
      transform: scale(0);
      transition: transform 600ms;
    `;
  }

  function activeStyles() {
    return css`
      transform: scale(1);
    `;
  }

  return (
    <div
      className={cx(
        css`
          align-items: flex-start;
        `,
        className,
      )}
    >
      <button
        onClick={onNotificationClick}
        className={classNames(
          getWiggleOnHoverStyle(),
          css`
            margin-right: 25px;
            margin-top: ${user ? 8 : 0}px;
          `,
        )}
      >
        <NotificationIcon showRedDot={showRedDot} />
      </button>
      <div>
        <img src={menuBg} className={classNames(baseStyles(), active && activeStyles())} alt="" />
        <div
          className={classNames(
            css`
              display: flex;
              align-items: center;
              column-gap: 23px;
            `,
            active ? '' : inactiveClase,
          )}
        >
          <div
            onClick={onProfileContainerClick}
            className={css`
              display: flex;
              column-gap: 16px;
              align-items: center;
            `}
          >
            {user ? (
              <>
                <div>
                  <Avatar
                    identifier="avatar"
                    flipVertically
                    shadow={active}
                    imgUrl={user.avatar}
                    size={44}
                    editable={false}
                    className={classNames(
                      'avatar',
                      css`
                        transition: transform 0.2s ease-in-out;
                      `,
                    )}
                  />
                </div>
                <section aria-label="Name and Year">
                  <h2
                    className={cx(
                      css`
                        font-size: 16px;
                        font-weight: ${active ? '900' : '420'};
                        line-height: 16px;
                        color: ${ColorsV2.gray100};
                        margin-bottom: 4px;
                      `,
                      'name-copy',
                    )}
                  >
                    {user.name}
                  </h2>
                  <p
                    className={classNames(
                      css`
                        color: ${active ? ColorsV2.gray100 : ColorsV2.gray40};
                        font-size: 12px;
                        font-weight: 420;
                        line-height: 14px;
                        transition: color 0.2s ease-in-out;
                      `,
                      'beamer-copy',
                    )}
                  >
                    {user.subtitle}
                  </p>
                </section>
              </>
            ) : (
              'loading...'
            )}
          </div>
          <div>
            <ChevronDownButtonWithHoverEffect onClick={onChevronClick} open={active} />
          </div>
        </div>
        {
          <AnimatePresence>
            {active && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={css`
                  justify-content: flex-end;
                  column-gap: 8px;
                  margin-top: 24px;
                  display: flex;
                `}
              >
                <Button
                  background={ColorsV2.gray90}
                  color={ColorsV2.softGray}
                  outlineColor={'none'}
                  // hover styles
                  // hoverColor={ColorsV2.notBlack}
                  // hoverOutline={ColorsV2.gray100}
                  className={css`
                    padding: 4px 8px;
                  `}
                  hoverBackground={ColorsV2.gray80}
                  leftIcon={<PencilIcon />}
                  onClick={editProfileAction.onAction}
                >
                  {editProfileAction.text}
                </Button>

                <Button
                  background="transparent"
                  onClick={logoutAction.onAction}
                  color={ColorsV2.gray80}
                  outlineColor={ColorsV2.ellipse401}
                  hoverBackground={colors.background}
                  hoverOutline={colors.background}
                  hoverColor={ColorsV2.gray80}
                  rightIcon={<ArrowAndDivider />}
                  className={css`
                    padding: 4px 8px;
                  `}
                >
                  {logoutAction.text}
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        }
      </div>
    </div>
  );
};
