/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@material-ui/core';
import { isMobile, isMobileSafari } from 'react-device-detect';
import sf from 'screenfull';

import { colors } from 'constants/design';
import { noop } from 'utils';
import logger from 'utils/logger';

import IllumeCircularProgress from '../circular-progress';
import {
  Loading,
  PauseButton,
  PlayButton,
  PreviewPlayButton,
  Redacted,
  SliderInput,
  VideoCloseButton,
} from './components';
import { useStyles } from './VideoDisplay.styles';

const VideoControls = ({ videoState, onPause = noop, onPlay = noop }) => {
  const { elapsed, status, duration } = videoState || {};

  // TODO: implement seeking
  const handleSeekChange = (event, newValue) => {};

  const handleSeekMouseDown = () => {};

  const handleSeekMouseUp = (_event, newValue) => {};

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        {status === 'playing' ? (
          <PauseButton size={14} onClick={onPause} />
        ) : (
          <PlayButton size={18} onClick={onPlay} />
        )}
      </Grid>
      <Grid item xs>
        <SliderInput
          onMouseDown={handleSeekMouseDown}
          onChangeCommitted={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
          min={0}
          max={duration}
          value={elapsed}
        />
      </Grid>
    </Grid>
  );
};

export interface VideoDisplayProps {
  size?: number;
  vidUrl?: string;
  onClick?: () => any;
  redacted?: boolean;
  color?: string;
  onClose?: () => any;
  onPreviewPlay?: () => any;
  clipPathId: string;
}

const VideoDisplay: React.FC<VideoDisplayProps> = ({
  size,
  vidUrl,
  onClick = noop,
  redacted,
  color,
  onClose = noop,
  onPreviewPlay = noop,
  clipPathId,
}) => {
  const videoRef = useRef();
  const urlRef = useRef(vidUrl);
  const containerRef = useRef();
  const [metadataLoading, setMetadataLoading] = useState(true);
  const [previewRevealed, setPreviewRevealed] = useState(false);
  const classes = useStyles({
    size,
    revealed: previewRevealed,
    clipPathId,
  });
  const thubmnailStartSecond = '0.5';
  const initialState = {
    status: 'paused',
    elapsed: null,
    duration: null,
  };
  const [videoState, setVideoState] = useState(initialState);

  function handlePreviewPlay() {
    // by default safari on iphone goes to fullscreen when the video is played
    if (isMobile && !isMobileSafari) {
      if (sf.isEnabled) sf.request(containerRef.current);
    }
    setPreviewRevealed(true);
    playVideo();
    onPreviewPlay();
  }

  async function playVideo() {
    await videoRef.current.play();
    setVideoState((prev) => ({ ...prev, status: 'playing' }));
  }

  function pauseVideo() {
    if (videoState.status !== 'paused') videoRef.current.pause();
  }

  const stopVideo = useCallback(() => {
    setPreviewRevealed(false);
    setVideoState((prev) => ({ ...prev, status: 'paused' }));
    if (videoState.status !== 'paused') videoRef.current.pause();
    videoRef.current.currentTime = 0;
  }, [videoState.status]);

  function handleClose() {
    if (sf.isFullscreen) {
      sf.exit().then(() => onClose());
    } else {
      stopVideo();
      onClose();
    }
  }

  // refresh video metadata on url change
  useEffect(() => {
    if (urlRef.current === vidUrl) return;
    if (videoRef.current) {
      setPreviewRevealed(false);
      setMetadataLoading(true);
      videoRef.current.load();
    }
    logger.log('loading source', vidUrl);

    urlRef.current = vidUrl;
  }, [vidUrl]);

  useEffect(() => {
    function handleChange() {
      if (!sf.isFullscreen) {
        stopVideo();
      }
    }

    if (sf.isEnabled) {
      sf.on('change', handleChange);
      return () => {
        if (sf.isEnabled) sf.off('change', handleChange);
      };
    }
    // clean up listener
  }, [stopVideo]);

  // listen to safari mobile exit video fullscreen
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('webkitendfullscreen', stopVideo);
    } else return;

    // techincally wont reach this because of the previous return statement, but just in case
    return () => video?.removeEventListener('webkitendfullscreen', stopVideo);
  }, [stopVideo]);

  if (redacted) return <Redacted color={color} size={size} />;
  logger.log('status', videoState.status);

  return (
    <>
      <div className={classes.videoCutContainer} ref={containerRef} onClick={onClick}>
        <div className={classes.playButton}>
          {metadataLoading ? (
            <Loading size={64} />
          ) : (
            <PreviewPlayButton onClick={handlePreviewPlay} size={28} />
          )}
        </div>
        {videoState.status === 'buffering' && (
          <div className={classes.bufferingSpinner}>
            <IllumeCircularProgress size={64} color={colors.contrastText} />
          </div>
        )}
        <video
          onWaiting={() => setVideoState((prev) => ({ ...prev, status: 'buffering' }))}
          // TODO: this is really imperative
          // probably need to implement state machine here
          onPause={() => {
            setVideoState((prev) => ({ ...prev, status: 'paused' }));
          }}
          className={classes.videoPlayer}
          onPlay={() => {
            logger.log('playing..');
            setVideoState((prev) => ({ ...prev, status: 'playing' }));
          }}
          onCanPlay={() => {
            logger.log('can play..');
            setVideoState((prev) => ({
              ...prev,
              status: 'playing',
              duration: videoRef.current.duration,
            }));
            setMetadataLoading(false);
          }}
          onTimeUpdate={() =>
            setVideoState((prev) => ({
              ...prev,
              elapsed: videoRef.current.currentTime,
              duration: videoRef.current.duration,
            }))
          }
          ref={videoRef}
          onEnded={() => {
            videoRef.current.currentTime = 0;
            logger.log('onEnded called');
            setVideoState((prev) => ({ ...prev, status: 'paused' }));
          }}
          controls={false}
          preload="metadata"
          onLoadedMetadata={() => setMetadataLoading(false)}
          crossOrigin="anonymous"
        >
          <source src={`${vidUrl}/video#t=${thubmnailStartSecond}`} type="video/mp4"></source>
          Your browser does not support HTML video.
        </video>
        {previewRevealed && !isMobileSafari && (
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
            className={classes.playerControl}
          >
            <Grid item>
              <VideoCloseButton size={24} onClick={handleClose} />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <VideoControls onPlay={playVideo} onPause={pauseVideo} videoState={videoState} />
            </Grid>
          </Grid>
        )}
        {/*
          these article is reallly helpful when dealing with svg clip path:
            - https://cssfordesigners.com/articles/clip-path-scaling
            - https://stackoverflow.com/questions/31210466/convert-svg-path-data-to-0-1-range-to-use-as-clippath-with-objectboundingbox/54090749 
          */}
        <svg className={classes.svgCutout}>
          <defs>
            <clipPath
              id={clipPathId}
              clipPathUnits="objectBoundingBox"
              transform="scale(0.0040322, 0.0041841)"
            >
              <path
                d="M0.0518401 88.534C-1.04205 66.4105 15.25 42.8508 41.899 24.9666C70.3402 5.87789 101.877 -1.83637 135.857 0.364395C155.454 1.61536 173.911 6.94353 189.412 19.7543C199.489 28.0709 207.286 38.2871 214.012 49.4067C225.882 69.0051 235.82 89.4375 241.43 111.793C249.203 142.742 241.616 170.055 221.786 194.356C201.002 219.815 173.376 232.742 141.187 237.005C118.681 239.993 96.7566 237.63 75.4606 229.754C49.4632 220.14 30.7972 202.372 19.0437 177.677C7.31342 153.074 0.912989 127.059 0.0518401 88.534Z"
                fill="#C21E9E"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default VideoDisplay;
