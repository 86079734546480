import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Box, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import { Route, Switch, useParams, Redirect, useLocation } from 'react-router-dom';
import { match } from 'ts-pattern';

import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import { Close } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { Loading } from 'components/Loading';
import { NoteFormRawValues } from 'components/reusable-forms/note-form/NoteForm';
import { colors, DEFAULT_PALETTE, PRIMARY_THEME } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { ADD_NOTE_URL, MY_CARDS_URL } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import cardServiceV2, { CodeType, determineCodeType } from 'infra/card-service/cardService';
import { CARD_ERRORS, ContributorCardDTO, InitiatorCardViewDTO } from 'types';
import { wait } from 'utils';
import CustomError from 'utils/CustomError';
import logger from 'utils/logger';

import { getRandomLoadingLabel } from '../marketplace/pages/getRandomLoadingLabel';
import AddNote from './add-note';
import { AddNoteStore } from './AddNote.store';
import SelectTheme from './select-theme';

export const [IN_THE_WORKS, READY_TO_SEND] = ['IN_THE_WORKS', 'READY_TO_SEND'];

interface AddNoteRoutesProps {}

/**
 *
 * @deprecated - register the note adding/edition no each flow's routes
 */
const AddNoteRoutes: FunctionComponent<AddNoteRoutesProps> = () => {
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const { cardCode } = useParams<{ cardCode: string }>();
  const history = useIllumeHistory();
  const location = useLocation();
  const { themepackStore } = useStores();
  const [showSnackbar, setShowSnakcbar] = useState(false);
  const analytics = useAnalytics();
  const { noteService } = useServices();
  /**
   * @deprecated - we're not going to use react-query anymore
   */
  const CARD_QUERY_KEY = 'card';

  const defaultPalette = useMemo(() => {
    const paletteCode = new URLSearchParams(location.search).get('paletteCode');
    if (paletteCode) {
      return themepackStore.getPalette(paletteCode);
    }
  }, [location.search, themepackStore]);

  const [addNoteStore] = useState(
    () =>
      new AddNoteStore(
        themepackStore,
        defaultPalette
          ? {
              palette: defaultPalette.toDto(),
            }
          : {},
        noteService,
      ),
  );
  const note = addNoteStore.formData.note;

  // hacky
  const [redirectTo] = useState(
    () => location?.state?.redirectTo || { pathname: `${MY_CARDS_URL}/${cardCode}` },
  );

  const handleFetchV2Err = (e: CustomError) => {
    const message =
      e.errorCode === CARD_ERRORS.PERMISSION_DENIED
        ? 'please login with the account assoicated with the card 🙂'
        : e.message;
    enqueueErrorSnackbar(message);
    console.log({ code: e.errorCode });
    logger.error(e);
  };

  const cardV2QueryInfo = useQuery<InitiatorCardViewDTO | ContributorCardDTO, CustomError>(
    [CARD_QUERY_KEY, cardCode],
    () => {
      const type = determineCodeType(cardCode);
      const service =
        type === CodeType.InviteCode
          ? cardServiceV2.getContributorCard
          : cardServiceV2.getInitiatorCard;
      return service(cardCode).then((card) => {
        addNoteStore.setCard(card);
        return card;
      });
    },
    {
      onError: handleFetchV2Err,
    },
  );

  const card = cardV2QueryInfo.data!;

  const handleFinalSubmit = async ({ palette = DEFAULT_PALETTE, theme = PRIMARY_THEME }) => {
    addNoteStore.setPalette(palette, theme);

    return addNoteStore
      .syncNote()
      .then(() => history.push(redirectTo))
      .catch((e: any) => {
        enqueueErrorSnackbar(e?.message || 'something went wrong');
        logger.error(e);
      });
  };

  function handleSkip() {
    history.push(redirectTo);
    analytics.track(EVENT_NAMES.SKIP_NOTE_INITIATOR.name);
  }

  useEffect(() => {
    wait(2000).then(() => setShowSnakcbar(true));
  }, []);

  if (cardV2QueryInfo.isLoading) {
    return <Loading text={getRandomLoadingLabel()} />;
  }
  function handleSubmitSuccess(note: NoteFormRawValues) {
    addNoteStore.patchNote(note);
    history.push(`${ADD_NOTE_URL}/${cardCode}/theme`);
    // Warning: is this supposable a reusable route?
    // should not have initiator logic here
    analytics.track(EVENT_NAMES.SUBMIT_NOTE_INITIATOR.name);
  }
  return (
    <Switch>
      <Route exact path={`${ADD_NOTE_URL}/:cardCode`}>
        <AddNote
          defaultPalette={addNoteStore.formData.palette}
          card={card}
          note={note ? note : {}}
          onSubmitSuccess={handleSubmitSuccess}
          onBack={history.back}
        />

        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          open={showSnackbar}
          onClose={() => setShowSnakcbar(false)}
        >
          <Alert
            style={{ display: 'flex', alignItems: 'center' }}
            action={
              <Box pr={1}>
                <BaseIconButtonNew
                  icon={Close}
                  iconProps={{ color: colors.contrastText, size: 12 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    return setShowSnakcbar(false);
                  }}
                />
              </Box>
            }
            variant="filled"
            severity="info"
          >
            <Text fontWeight={400} fontSize={{ mobile: 14, desktop: 16 }}>
              <span style={{ marginRight: 4 }}>want to write your note later?</span>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleSkip();
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                skip for now
              </a>
            </Text>
          </Alert>
        </Snackbar>
      </Route>
      <Route exact path={`${ADD_NOTE_URL}/:cardCode/theme`}>
        {note ? (
          <SelectTheme
            defaultPalette={addNoteStore.formData.palette}
            submitting={addNoteStore.syncTask.isExecuting}
            card={card}
            note={note}
            onSubmit={({ palette, theme }) => {
              analytics.track(
                match(card.userContext.role)
                  .with('Contributor', () => EVENT_NAMES.PICK_THEME_CONTRIBUTOR.name)
                  .with('Initiator', () => EVENT_NAMES.PICK_THEME_INITIATOR.name)
                  .exhaustive(),
                { themeType: palette.paletteCode },
              );
              handleFinalSubmit({ palette, theme });
              logger.warn('theme is not used', theme);
            }}
            onBack={() => {
              console.log('going back..');
              history.back();
            }}
          />
        ) : (
          <Redirect to={`${ADD_NOTE_URL}/${cardCode}`} />
        )}
      </Route>
    </Switch>
  );
};

export default observer(AddNoteRoutes);
