import { ReactPortal } from 'react';

import { Tooltip as MuiTooltip, TooltipProps as MUITooltipProps, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { colors, rem, Z_INDEXES } from 'constants/design/index';

const tooltipVariants: Record<Variants, { backgroundColor: string; color: string }> = {
  primary: {
    backgroundColor: colors.primaryTooltip,
    color: colors.darkText,
  },
  primaryDark: {
    backgroundColor: colors.primaryDark,
    color: colors.contrastText,
  },
  shadow: {
    backgroundColor: colors.gray40,
    color: colors.contrastText,
  },
  other: {
    backgroundColor: colors.lightTooltipBackground,
    color: colors.black60,
  },
};

const useTooltipStyles = makeStyles<
  Theme,
  Pick<TooltipProps, 'mb' | 'size' | 'widthArrow' | 'variant' | 'maxWidth'>
>((theme) =>
  createStyles({
    customTooltip: ({ variant, size, maxWidth, mb }) => {
      return {
        ...(tooltipVariants[variant] ? tooltipVariants[variant] : tooltipVariants.other),
        minWidth: size === 'small' ? theme.spacing(10) : theme.spacing(23.5),
        fontSize: rem[750],
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        lineHeight: rem[1000],
        fontWeight: 400,
        marginBottom: mb,
        maxWidth: maxWidth,
        zIndex: Z_INDEXES.TOOLTIP,
      };
    },
    customArrow: ({ variant, widthArrow }) => ({
      color: tooltipVariants[variant]
        ? tooltipVariants[variant].backgroundColor
        : tooltipVariants.other.backgroundColor,
      width: widthArrow ? widthArrow : '80%',
    }),
    tooltipPlacementTop: {
      margin: '12px 0', // default '24px 0'
    },
    tooltipPlacementBottom: {
      margin: '12px 0',
    },
    tooltipPopper: {
      zIndex: Z_INDEXES.TOOLTIP,
    },
  }),
);
type Variants = 'primary' | 'primaryDark' | 'shadow' | 'other';
export interface TooltipProps extends Omit<MUITooltipProps, 'title'> {
  mb?: number;
  text?: boolean | React.ReactChild | React.ReactFragment | ReactPortal;
  showTooltip?: boolean;
  size?: 'large' | 'small';
  widthArrow?: string;
  variant: Variants;
  maxWidth?: number | string;
}

const Tooltip: React.FC<TooltipProps> = ({
  mb,
  text = '',
  showTooltip,
  children,
  placement = 'top-end',
  size = 'large',
  widthArrow,
  variant = 'primary',
  maxWidth = '',
  ...props
}) => {
  const classes = useTooltipStyles({ mb, size, widthArrow, variant, maxWidth });
  return (
    <MuiTooltip
      arrow
      placement={placement}
      open={showTooltip}
      title={text}
      disableFocusListener
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
        tooltipPlacementTop: classes.tooltipPlacementTop,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
        popper: classes.tooltipPopper,
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
