import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles<
  Theme,
  { fileUploadStatus: 'uploading' | 'idle' | 'error' | 'success' }
>((t) =>
  createStyles({
    root: {
      maxWidth: 400,
      [t.breakpoints.up('md')]: {
        maxWidth: 550,
      },
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: (props) =>
        props.fileUploadStatus === 'uploading' || props.fileUploadStatus === 'error'
          ? 'center'
          : 'flex-end',
      columnGap: 12,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      padding: (props) => (props.fileUploadStatus === 'idle' ? 24 : 36),
      [t.breakpoints.up('md')]: {
        rowGap: 36,
        padding: 57,
      },
    },
    uploadingContent: {
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      rowGap: 12,
    },
  }),
);

export default useStyles;
