import { RecipientDTO, UserProfileDTO } from '@illume/shared';

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

export type PickRenameMulti<
  T,
  R extends { [K in keyof R]: K extends keyof T ? PropertyKey : 'Error: key not in T' },
> = Omit<T, keyof R> &
  UnionToIntersection<{ [P in keyof R & keyof T]: { [PP in R[P]]: T[P] } }[keyof R & keyof T]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export interface IIllumeModal {
  show: boolean;
  onClose: () => any;
}

export type CreateCardSetReminderPayloadDTo = {
  recipientData: MakeOptional<RecipientDTO, 'id' | 'contactType' | 'contact'>[];
  deadline: string;
  deadlineTz: string;
  occasionName: string;
  initiatorName?: string;
};

export type CreateCardAutomaticDTO = {
  recipientData: MakeOptional<RecipientDTO, 'id' | 'contactType' | 'contact'>[];
  scheduledTimestamp: string;
  occasionName: string;
  initiatorName?: string;
};

export const CARD_ERRORS = {
  UNEXPECTED_ERROR: 'UnexpectedError',
  CARD_NOT_FOUND: 'CardNotFound',
  PERMISSION_DENIED: 'PermissionDenied',
  SubscriptionNotFound: 'SubscriptionNotFound',
} as const;

export type Action = {
  onAction: () => any;
  loading: boolean;
  text: string;
};

export type UserProfileRequestDTO = UserProfileDTO;

export * from '@illume/shared/types';
