import React, { createContext, useContext } from 'react';

import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { SegmentAnalytics } from 'infra/analytics/segment/SegmentAnalytics';

// Create the analytics context object
const AnalyticsContext = createContext<IAnalytics>(new SegmentAnalytics());

// Provide the analytics context object
export const AnalyticsProvider = ({
  children,
  analytics,
}: {
  children: React.ReactNode;
  analytics: IAnalytics;
}) => {
  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

// Consume the analytics context object
export const useAnalytics = () => useContext(AnalyticsContext);
