export default function stateDebug(state, { position = 'left' } = {}) {
  const showStateDebug = process.env.NODE_ENV === 'development' ? 'block' : 'none';
  return (
    <pre
      style={{
        display: showStateDebug,
        position: 'absolute',
        zIndex: 1000,
        bottom: 0,
        [position]: 0,
        backgroundColor: 'rgba(0, 0, 0 , 0.4)',
        color: 'white',
      }}
    >
      <p>
        <b style={{ color: 'papayawhip' }}>This won't be shown in production/development!</b>
      </p>
      {JSON.stringify({ value: state.value, context: state.context }, null, 2)}
    </pre>
  );
}
