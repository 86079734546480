export const Calendar = ({ color, size }) => {
  return (
    <svg
      height={size}
      width={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path d="M31.3,65.9H20.9c-1.5,0-2.7,1.2-2.7,2.7V74c0,1.5,1.2,2.7,2.7,2.7h10.5c1.5,0,2.7-1.2,2.7-2.7v-5.4     C34.1,67.1,32.9,65.9,31.3,65.9z" />
            <path d="M31.3,45.9H20.9c-1.5,0-2.7,1.2-2.7,2.7v5.4c0,1.5,1.2,2.7,2.7,2.7h10.5c1.5,0,2.7-1.2,2.7-2.7v-5.4     C34.1,47.1,32.9,45.9,31.3,45.9z" />
            <path d="M79.1,65.9H68.7c-1.5,0-2.7,1.2-2.7,2.7V74c0,1.5,1.2,2.7,2.7,2.7h10.5c1.5,0,2.7-1.2,2.7-2.7v-5.4     C81.8,67.1,80.6,65.9,79.1,65.9z" />
            <path d="M79.1,45.9H68.7c-1.5,0-2.7,1.2-2.7,2.7v5.4c0,1.5,1.2,2.7,2.7,2.7h10.5c1.5,0,2.7-1.2,2.7-2.7v-5.4     C81.8,47.1,80.6,45.9,79.1,45.9z" />
            <path d="M55.2,65.9H44.8c-1.5,0-2.7,1.2-2.7,2.7V74c0,1.5,1.2,2.7,2.7,2.7h10.5c1.5,0,2.7-1.2,2.7-2.7v-5.4     C58,67.1,56.7,65.9,55.2,65.9z" />
            <path d="M55.2,45.9H44.8c-1.5,0-2.7,1.2-2.7,2.7v5.4c0,1.5,1.2,2.7,2.7,2.7h10.5c1.5,0,2.7-1.2,2.7-2.7v-5.4     C58,47.1,56.7,45.9,55.2,45.9z" />
            <path d="M87.5,14.1h-10v-2.7c0-2-1.6-3.6-3.6-3.6h-5.5c-2,0-3.6,1.6-3.6,3.6v2.7H35.2v-2.7c0-2-1.6-3.6-3.6-3.6h-5.5     c-2,0-3.6,1.6-3.6,3.6v2.7h-10c-5.5,0-10,4.5-10,10v58.2c0,5.5,4.5,10,10,10h75c5.5,0,10-4.5,10-10V24.1     C97.5,18.5,93,14.1,87.5,14.1z M87.5,86.8h-75c-2.5,0-4.5-2-4.5-4.5V35.9H92v46.3C92,84.7,90,86.8,87.5,86.8z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
