import { forwardRef, useRef } from 'react';

import { RecipientCardV2Dto } from '@illume/shared';
import { useReactToPrint } from 'react-to-print';

import { notesFromDto } from 'pages/illume/note-display/NoteDisplay';

import { Cover } from './Cover';
import { NotesToPrint } from './Note';

import './Note.scss';

interface CoverAndNotesToPrintProps {
  data: {
    card: RecipientCardV2Dto;
  };
  ref: React.RefObject<HTMLDivElement>;
}

// create custom hook that useses react-to-print hook the returns the ref to the div
export const usePrintRef = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return { printRef, handlePrint };
};

export const CoverAndNotesToPrint = forwardRef<HTMLDivElement, CoverAndNotesToPrintProps>(
  (props, ref) => {
    const { data } = props;

    return (
      <div style={{ display: 'none' }}>
        <div ref={ref}>
          <Cover
            data={{
              notes: data.card.notes,
              contributorCount: data.card.contributorCount,
              initiatorName: data.card.initiatorName,
              recipientData: data.card.recipientData,
            }}
          />
          <NotesToPrint
            data={{
              notes: notesFromDto(data.card.notes),
            }}
          />
        </div>
      </div>
    );
  },
);
