import { createContext, useContext } from 'react';

import { IHttpClient } from 'domain/interfaces/IHttpClient';

const HttpClientContext = createContext({} as IHttpClient);

export const HttpClientProvider: React.FC<{ client: IHttpClient }> = ({ children, client }) => {
  return <HttpClientContext.Provider value={client}>{children}</HttpClientContext.Provider>;
};

export const useHttpClient = () => useContext(HttpClientContext);
