import { makeStyles } from '@material-ui/core/styles';

import { borderRadiusSmall, colors, rem, borderRadius } from 'constants/design';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      backgroundColor: colors.textInputBackground,
      color: colors.gray40,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    className: 'customIllumeScrollbar-orange',
  },
};

export const inputStyles = makeStyles((theme) => ({
  root: ({ background, shadow, shadowColor, radius, error }) => ({
    fontWeight: 500,
    minHeight: rem[2250],
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    overflowY: 'hidden',
    backgroundColor:
      background === 'main'
        ? theme.palette.illume.textInputBackground.main
        : theme.palette.illume.textInputBackground.contrastText,
    borderRadius: radius === 'small' ? borderRadiusSmall : borderRadius,
    border: error ? '2px solid red' : null,
    boxShadow: shadow && `5px 5px ${shadowColor}`,
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      borderRadius: radius === 'small' ? borderRadiusSmall : borderRadius,
      backgroundColor:
        background === 'main'
          ? theme.palette.illume.textInputBackground.main
          : theme.palette.illume.textInputBackground.contrastText,
    },
  }),
}));

export const useStyles = makeStyles((theme) => ({
  label: { color: colors.gray60, opacity: 0.5, fontSize: rem[875], lineHeight: rem[1500] },
  textInput: {
    backgroundColor: colors.textInputBackground,
    margin: 'auto',
    color: ({ value }) => (value ? colors.gray100 : colors.gray25),
    borderRadius,
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  menuItem: {
    '&:hover': {
      background: colors.primary,
      color: colors.contrastText,
    },
  },
  selected: {
    background: 'none !important',
    color: colors.black60,
    justifyContent: 'space-between',
    '&:hover': {
      background: `${colors.primary} !important`,
      color: colors.contrastText,
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  arrow: {
    top: 'calc(50% - 8px)',
    color: 'rgba(0, 0, 0, 0.54)',
    right: 20,
    position: 'absolute',
    pointerEvents: 'none',
  },
}));
