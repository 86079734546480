import { Box } from '@material-ui/core';
import { isSafari } from 'react-device-detect';

import { colors } from 'constants/design';

const InnerCircle = ({ size, activeColor = colors.contrastText, color, active = false }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {active && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.9135 5.44911C3.24424 9.35242 -0.221684 14.4945 0.0110284 19.323C0.194227 27.7313 1.55584 33.4093 4.0513 38.7789C6.55172 44.1687 10.5227 48.0468 16.0533 50.145C20.5838 51.8641 25.2479 52.3798 30.0358 51.7276C36.8835 50.7973 42.7607 47.976 47.1822 42.4193C51.4008 37.1155 53.0149 31.1543 51.3611 24.3994C50.1679 19.5202 48.0537 15.0608 45.5285 10.7833C44.0976 8.35637 42.4389 6.12663 40.2949 4.31149C36.9974 1.51546 33.071 0.352561 28.902 0.0795312C21.673 -0.400798 14.964 1.28288 8.9135 5.44911ZM10.885 7.82037C5.8699 11.2733 2.8039 15.822 3.00976 20.0935C3.17182 27.5316 4.37632 32.5544 6.58385 37.3044C8.79575 42.0723 12.3085 45.5029 17.201 47.3591C21.2087 48.8798 25.3347 49.336 29.5701 48.759C35.6277 47.936 40.8268 45.4403 44.7381 40.5248C48.4699 35.8329 49.8978 30.5596 48.4349 24.5841C47.3793 20.2679 45.509 16.323 43.2752 12.5391C42.0094 10.3922 40.5421 8.41971 38.6455 6.81401C35.7284 4.3406 32.2551 3.31188 28.5671 3.07035C22.1723 2.64545 16.2374 4.13486 10.885 7.82037Z"
          fill={activeColor}
        />
      )}
      <path
        d="M11.0065 22.4728C10.8684 19.6059 12.9262 16.5529 16.2923 14.2353C19.8847 11.7617 23.8681 10.762 28.1601 11.0472C30.6354 11.2093 32.9667 11.8998 34.9245 13.5599C36.1975 14.6376 37.1823 15.9615 38.0319 17.4024C39.5312 19.9421 40.7865 22.5899 41.4949 25.4868C42.4768 29.4974 41.5185 33.0368 39.0138 36.1859C36.3886 39.485 32.899 41.1601 28.8333 41.7125C25.9906 42.0998 23.2213 41.7936 20.5314 40.7729C17.2477 39.5271 14.89 37.2245 13.4054 34.0244C11.9238 30.8363 11.1153 27.4651 11.0065 22.4728Z"
        fill={color}
      />
    </svg>
  );
};

export default function ThemeSelectItemIcon({ selected, innerFill, outerFill, size, activeColor }) {
  return (
    <div
      style={{
        position: 'relative',
        width: size,
        height: size,
        display: 'inline-block',
      }}
    >
      <Box
        zIndex={1000}
        style={{
          clipPath: isSafari ? 'ellipse()' : 'url(#main-fill)',
          background: outerFill,
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
        }}
      >
        <InnerCircle size={'100%'} color={innerFill} active={selected} activeColor={activeColor} />
      </Box>
      <svg style={{ maxHeight: '100%', maxWidth: '100%', display: 'block' }}>
        <defs>
          <clipPath
            transform="scale(0.0187055, 0.01919)"
            clipPathUnits="objectBoundingBox"
            id="main-fill"
          >
            <path
              d="M0.0113386 19.3643C-0.227919 14.5254 3.33549 9.37238 9.16421 5.46074C15.3849 1.28562 22.2826 -0.401654 29.7149 0.0797009C34.0012 0.353313 38.038 1.5187 41.4283 4.32069C43.6326 6.1397 45.3379 8.3742 46.8091 10.8063C49.4053 15.0929 51.579 19.5619 52.8058 24.4514C54.506 31.2208 52.8465 37.1947 48.5093 42.5098C43.9634 48.0783 37.9209 50.9057 30.8806 51.838C25.958 52.4916 21.1627 51.9748 16.5048 50.252C10.8187 48.1493 6.736 44.263 4.16526 38.8617C1.5996 33.4806 0.19969 27.7905 0.0113386 19.3643Z"
              fill="#E3C3C9"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
