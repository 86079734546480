import React from 'react';

import { CircularProgress, IconButton, IconButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HTMLMotionProps, motion } from 'framer-motion';

import { colors } from 'constants/design';

const useStyles = makeStyles(() => ({
  root: {
    '&:focus': { outline: 'none' },
    padding: 0,
    '&:hover': { background: 'none' },
  },
}));

export interface BaseIconButtonProps extends Omit<IconButtonProps, 'color' | 'size'> {
  onClick: () => any;
  icon: React.ElementType;
  classes?: any;
  disabled?: boolean;
  size?: number | string;
  color?: string;
  iconProps?: any;
  loading?: boolean;
  containerProps?: JSX.IntrinsicElements['div'];
}

const BaseIconButton: React.FC<BaseIconButtonProps> = ({
  onClick,
  classes: propClasses,
  disabled = false,
  icon,
  size,
  color,
  iconProps,
  loading,
  containerProps,
  ...props
}) => {
  const classes = useStyles();
  const returnNull = () => null;
  const Icon = icon || returnNull;

  return (
    <motion.div whileTap={{ scale: !disabled ? 0.8 : undefined }} {...containerProps}>
      <IconButton
        disabled={disabled}
        disableRipple
        classes={{ ...classes, ...propClasses }}
        onClick={onClick}
        {...props}
      >
        {loading ? (
          <CircularProgress style={{ color: colors.contrastText }} size={Number(size) * 0.8} />
        ) : (
          <Icon size={size} color={color} {...iconProps} />
        )}
      </IconButton>
    </motion.div>
  );
};

interface BaseIconButtonNewProps<T> extends HTMLMotionProps<'button'> {
  icon: React.ElementType<T>;
  iconProps?: T;
}

export function BaseIconButtonNew<T>({
  iconProps,
  icon: Icon,
  ...props
}: BaseIconButtonNewProps<T>) {
  return (
    <motion.button whileTap={{ scale: 0.8 }} {...props}>
      <Icon {...iconProps} />
    </motion.button>
  );
}

export default BaseIconButton;
