//@ts-check
const Person = ({ size = 20, hasBadge = false, ...props }) => {
  const personBadge = (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M13.7458 12.3815C12.5308 11.9985 6.92184 11.9985 5.70686 12.3815C4.62828 12.7151 3.68567 13.3864 3.01819 14.2961C2.35071 15.2058 1.99375 16.3059 1.99999 17.4338V19.4724C1.99998 19.623 2.0299 19.772 2.08803 19.911C2.14615 20.0499 2.23132 20.1759 2.33859 20.2817C2.44586 20.3875 2.57309 20.471 2.7129 20.5272C2.85271 20.5835 3.00232 20.6115 3.15304 20.6095H16.3143C16.6162 20.6095 16.9058 20.4897 17.1193 20.2765C17.3327 20.0632 17.4527 19.774 17.4527 19.4724V17.4338C17.4584 16.306 17.1013 15.2062 16.4339 14.2965C15.7665 13.3869 14.8241 12.7155 13.7458 12.3815Z"
          fill="#FFFDF3"
        />
        <path
          d="M9.72635 10.8143C12.1628 10.8143 14.138 8.84112 14.138 6.40713C14.138 3.97314 12.1628 2 9.72635 2C7.28984 2 5.31467 3.97314 5.31467 6.40713C5.31467 8.84112 7.28984 10.8143 9.72635 10.8143Z"
          fill="#FFFDF3"
        />
        <path
          d="M21.8585 20.4239L18.8652 15.2391C18.7732 15.0794 18.6406 14.9467 18.4809 14.8545C18.3212 14.7622 18.14 14.7137 17.9555 14.7137C17.771 14.7137 17.5898 14.7622 17.4301 14.8545C17.2704 14.9467 17.1378 15.0794 17.0457 15.2391L14.0643 20.4239C13.9731 20.5816 13.9246 20.7602 13.9235 20.9423C13.9224 21.1243 13.9688 21.3035 14.058 21.4623C14.1473 21.621 14.2764 21.7538 14.4326 21.8475C14.5888 21.9413 14.7668 21.9928 14.949 21.9971H20.9502C21.1344 21.9969 21.3153 21.9483 21.4748 21.8562C21.6343 21.7641 21.7667 21.6317 21.8587 21.4723C21.9507 21.3129 21.9992 21.1321 21.9991 20.9481C21.9991 20.7641 21.9506 20.5833 21.8585 20.4239V20.4239Z"
          fill="black"
        />
        <path
          d="M21.3217 20.2884L18.7414 15.8283C18.6614 15.6913 18.5468 15.5777 18.4092 15.4988C18.2716 15.4199 18.1156 15.3783 17.9569 15.3783C17.7982 15.3783 17.6423 15.4199 17.5047 15.4988C17.367 15.5777 17.2525 15.6913 17.1725 15.8283L14.5892 20.2884C14.5101 20.4259 14.4685 20.5817 14.4688 20.7403C14.469 20.8989 14.5109 21.0546 14.5904 21.1919C14.6698 21.3291 14.784 21.4431 14.9215 21.5224C15.059 21.6017 15.2149 21.6435 15.3736 21.6435H20.552C20.7108 21.6435 20.8667 21.6017 21.0042 21.5224C21.1416 21.4431 21.2558 21.3291 21.3353 21.1919C21.4148 21.0546 21.4567 20.8989 21.4569 20.7403C21.4571 20.5817 21.4156 20.4259 21.3365 20.2884H21.3217ZM18.2312 20.4092C18.2312 20.4787 18.2035 20.5454 18.1543 20.5946C18.1051 20.6438 18.0383 20.6714 17.9687 20.6714V20.6714C17.8991 20.6714 17.8324 20.6438 17.7831 20.5946C17.7339 20.5454 17.7063 20.4787 17.7063 20.4092V20.4092C17.7063 20.3749 17.7131 20.3409 17.7263 20.3093C17.7395 20.2776 17.7589 20.2489 17.7833 20.2248C17.8077 20.2007 17.8367 20.1817 17.8685 20.1688C17.9003 20.156 17.9344 20.1496 17.9687 20.1499V20.1499C18.0031 20.1496 18.0371 20.156 18.0689 20.1688C18.1008 20.1817 18.1297 20.2007 18.1541 20.2248C18.1785 20.2489 18.1979 20.2776 18.2112 20.3093C18.2244 20.3409 18.2312 20.3749 18.2312 20.4092V20.4092ZM18.2312 19.6049C18.2351 19.6415 18.2312 19.6785 18.2199 19.7135C18.2086 19.7485 18.19 19.7807 18.1654 19.808C18.1407 19.8354 18.1107 19.8572 18.077 19.8722C18.0434 19.8872 18.007 19.8949 17.9702 19.8949C17.9334 19.8949 17.897 19.8872 17.8634 19.8722C17.8297 19.8572 17.7997 19.8354 17.775 19.808C17.7504 19.7807 17.7318 19.7485 17.7205 19.7135C17.7092 19.6785 17.7053 19.6415 17.7092 19.6049L17.5411 17.2482C17.5411 17.1038 17.836 16.93 17.9717 16.93C18.1073 16.93 18.3993 17.1038 18.3993 17.2482L18.2312 19.6049Z"
          fill="#FFFDF3"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );

  const person = (
    <svg
      height={size}
      width={size}
      fill="#FFFDF3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      style={{
        enableBackground: 'new 0 0 100 100',
        marginRight: '10px',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path d="M5273.1 2400.1v-2c0-2.8-5-4-9.7-4s-9.7 1.3-9.7 4v2c0 1.8.7 3.6 2 4.9l5 4.9c.3.3.4.6.4 1v6.4c0 .4.2.7.6.8l2.9.9c.5.1 1-.2 1-.8v-7.2c0-.4.2-.7.4-1l5.1-5c1.3-1.3 2-3.1 2-4.9zm-9.7-.1c-4.8 0-7.4-1.3-7.5-1.8.1-.5 2.7-1.8 7.5-1.8s7.3 1.3 7.5 1.8c-.2.5-2.7 1.8-7.5 1.8z" />
      <path d="M5268.4 2410.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1zM5272.7 2417h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.5-.4-1-1-1zM70.5 55.5c-6.2-1.9-34.8-1.9-41 0-11.3 3.4-18.9 14-18.9 25.8v10.4c0 3.2 2.6 5.8 5.8 5.8h67.2c3.2 0 5.8-2.6 5.8-5.8V81.3c0-11.8-7.6-22.4-18.9-25.8z" />
      <circle cx={50} cy={25} r={22.5} />
    </svg>
  );

  return hasBadge ? personBadge : person;
};

export default Person;
