import { Box } from '@material-ui/core';
import { GoogleLogin as ReactGoogleLogin } from 'react-google-login';

import { GoogleButton } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import config from 'config';
import { colors } from 'constants/design';

export const GoogleLogin = ({ onSuccess, onFailure, disabled = false, unavailable }) => {
  return (
    <>
      <ReactGoogleLogin
        clientId={config.googleClientId} // TODO: change this to the correct clientId in allConfig.json
        buttonText="Sign in with Google"
        render={({ onClick }) => (
          <GoogleButton disabled={disabled || unavailable} fullWidth onClick={onClick} />
        )}
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
      />
      {unavailable && (
        <Box mt={1}>
          <Text color={colors.contrastText} align="center" italic fontSize={14}>
            *Google Login is not available on some browsers' incognito mode.
          </Text>
        </Box>
      )}
    </>
  );
};
