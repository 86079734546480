import { ObservablePromise } from '@thezano/mobx-observable-promise';
import { autorun, makeAutoObservable } from 'mobx';
import { fromStream, IStreamListener } from 'mobx-utils';
import { fromEvent, debounceTime, switchMap, of } from 'rxjs';

import { DEFAULT_PALETTE, DESKTOP_MIN_WIDTH } from 'constants/design';
import { Palette } from 'constants/design/cardTheme';
import { mockThemeService } from 'infra/theme-service/MockThemeService';
import { PaletteDTO, ThemepackDTO } from 'types';
import { isHex, wait } from 'utils';
import logger from 'utils/logger';

interface Repo<T> {
  findbyId(id: string | number): T | undefined;
}

class PaletteStore implements Repo<Palette> {
  constructor(readonly _themepacks: ThemepackDTO[]) {}

  get palettes() {
    const result = this._themepacks?.reduce((acc, themepack) => {
      return [...acc, ...themepack.palettes];
    }, [] as PaletteDTO[]);
    return result;
  }

  findbyId = (id: string | number): Palette | undefined => {
    const dto = this.palettes?.find((p) => p.paletteCode === id);
    return dto && new Palette(dto);
  };
}

class ThemepacksStore {
  themepacksObservablePromise = new ObservablePromise(mockThemeService.getThemepacks);

  winWidth: IStreamListener<number | undefined>;

  private _winWidth$ = fromEvent(window, 'resize').pipe(
    switchMap(() => of(window.innerWidth)),
    debounceTime(200),
  );

  get isDesktop() {
    if (!this.winWidth.current) {
      return false;
    }
    return this.winWidth.current > DESKTOP_MIN_WIDTH;
  }

  constructor() {
    makeAutoObservable(this);
    this.fetchThemepacks();
    this.winWidth = fromStream(this._winWidth$);
    autorun(() => {
      this.prefetchPaletteImages();
    });
  }
  fetchThemepacks = () => {
    return this.themepacksObservablePromise.execute().catch();
  };
  get themepacks(): ThemepackDTO[] | undefined {
    return this.themepacksObservablePromise.getResult(undefined);
  }

  get themepacksError() {
    return this.themepacksObservablePromise.error;
  }
  get themepacksFetching() {
    return this.themepacksObservablePromise.isExecuting;
  }
  get _palettes() {
    return new PaletteStore(this.themepacks || []).palettes;
  }

  prefetchPaletteImages() {
    /**
     * get all urls
     */
    const urls = this._palettes
      ? this._palettes
          /** if the main colors are hex codes, don't bother fetching them */
          .filter((palette) => palette.main && !isHex(palette.main))
          .map((palette) =>
            this.isDesktop
              ? palette.main
              : /** use url optimized for mobile, if doesn't exist, fallback to main */
                palette.mobile || palette.main,
          )
      : [];

    // this is a large resources
    // waiting 2s is a hack to not load the images immediately
    // as it will slows down the network requests for other more important resources
    wait(2000)
      /* it's ok if one of the images fails to load */
      .then(() => {
        /**
         *  fetch the images
         */
        logger.log('prefetching the images with urls: ', urls);
        const imagePromises = urls.map((url) => {
          const img = new Image();
          img.src = url;
          return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = () => reject(`error loading ${url}`);
          });
        });

        return typeof Promise.allSettled === 'function'
          ? Promise.allSettled(imagePromises)
          : // safari 12 does not recognize Promise.allsettled
            Promise.all(
              imagePromises.map((p) =>
                Promise.resolve(p).then(
                  (value) => ({ status: 'fulfilled', value }),
                  (reason) => ({ status: 'rejected', reason }),
                ),
              ),
            );
      })
      .then((results = []) => {
        const rejected = results.filter((result) => result.status === 'rejected');
        logger.log(`done preloading images, ${rejected.length} image(s) are failed to load`);
      });
  }

  getThemepack = (code: string) => {
    return this.themepacks?.find((t) => t.themepackCode === code);
  };

  getPalette = (paletteCode: string): Palette => {
    return (
      new PaletteStore(this.themepacks || []).findbyId(paletteCode) || new Palette(DEFAULT_PALETTE)
    );
  };

  getPaletteDto = (paletteCode: string): PaletteDTO => {
    return (
      new PaletteStore(this.themepacks || []).findbyId(paletteCode)?.toDto() || DEFAULT_PALETTE
    );
  };
}

export default ThemepacksStore;
