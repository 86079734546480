import React, { useEffect } from 'react';

import { Observer } from 'mobx-react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';

import { LOGGED_IN, LOGIN_URL, NOT_LOGGED_IN } from 'constants/strings';
import { useAuth } from 'contexts/authentication';
import useIllumeLocation from 'hooks/illume/useIllumeLocation';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import ScrollToTop from 'hooks/ScrollToTop';

type Props = RouteComponentProps<{
  workspaceUrl: string;
}>;

export const IllumeRoute: React.FC<{
  component?: React.FC<Props>;
  isPrivate?: boolean;
  isGuestOnly?: boolean;
  path?: string;
  guestOnlyNext?: string;
  exact?: boolean;
}> = ({
  component: Component,
  isPrivate = false,
  isGuestOnly = false,
  guestOnlyNext,
  path,
  ...rest
}) => {
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();
  const { authenticated } = useAuth() || {};
  const { location } = useIllumeLocation();
  const history = useHistory();

  const errors = [
    {
      type: 'guestOnly',
      condition: isGuestOnly && authenticated && guestOnlyNext,
      message: LOGGED_IN,
      redirectPath: guestOnlyNext,
    },
    {
      type: 'private',
      condition: isPrivate && !authenticated,
      message: NOT_LOGGED_IN,
      redirectPath: LOGIN_URL,
    },
  ];

  const error = errors.find(({ condition }) => condition);

  useEffect(() => {
    if (error) {
      error.message === LOGGED_IN
        ? enqueueSuccessSnackbar(error.message)
        : enqueueErrorSnackbar(error.message);

      const config = {
        pathname: error.redirectPath + (location.search || ''),
        state: { from: location.pathname, search: location.search },
      };
      history.push(config, config.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    // logger.log('redirection to previous path: ', { config });
    return null;
  }

  return (
    <ScrollToTop>
      <Route
        {...rest}
        path={path}
        render={(matchProps) => {
          return (
            <Observer>
              {() => {
                return <Component {...matchProps} {...rest} />;
              }}
            </Observer>
          );
        }}
      />
    </ScrollToTop>
  );
};
