import React, { FunctionComponent } from 'react';

import { Grid } from '@material-ui/core';

import arrowUrl from 'assets/svg/arrow_url.svg';
import { Link as IllumeLink } from 'components/illume/link';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

import { useStyles } from './CelebrateMoreA.style';

type ImageRef = HTMLImageElement | null;

interface ContentSectionProps {
  url: string;
  title: string;
  subtitle: string;
  mainImg: string;
  mainImgAlt: string;
  direction?: 'left' | 'right';
  imgRef?: (ref: ImageRef) => void;
}

const ContentSection: FunctionComponent<ContentSectionProps> = ({
  url,
  title,
  subtitle,
  mainImg,
  mainImgAlt,
  direction = 'left',
  imgRef,
}) => {
  const isMobile = !useIsDesktop();
  const classes = useStyles({
    direction,
  });
  return (
    <Grid className={classes.containerLayout}>
      <Grid className={classes.textContainer}>
        {isMobile ? (
          <Text
            className={classes.title}
            fontSize={40}
            color={colors.black}
            fontWeight={480}
            lineHeight={'48px'}
          >
            {title}
          </Text>
        ) : (
          <a href={url} className={classes.title} rel="noreferrer" target="_blank">
            <IllumeLink
              fontWeight={420}
              color={colors.darkOrangeNew}
              fontSize={32}
              hover={{ textDecoration: 'underline' }}
            >
              {title}
            </IllumeLink>
            <img src={arrowUrl} alt={'icon url'} />
          </a>
        )}

        <Text
          className={classes.subtitle}
          fontSize={{ desktop: 20, mobile: 16 }}
          color={colors.subHeaderGray}
          fontWeight={400}
          style={{
            maxWidth: subtitle.length < 60 ? '250px' : '339px',
          }}
        >
          {subtitle}
        </Text>
        {isMobile && (
          <a href={url} className={classes.urlText} rel="noreferrer" target="_blank">
            <IllumeLink fontWeight={450} color={colors.primaryDark} fontSize={18} underline>
              Learn More
            </IllumeLink>
          </a>
        )}
      </Grid>
      <Grid style={{ maxWidth: '314px' }}>
        <Grid style={{ position: 'relative', marginLeft: 10, marginRight: 10, marginBottom: 24 }}>
          <Grid className={classes.imageContainer}>
            <img src={mainImg} alt={mainImgAlt} ref={imgRef} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContentSection;
