import { makeStyles } from '@material-ui/core/styles';

import { colors, spacing } from 'constants/design';

export const navigationItem = makeStyles(() => ({
  root: {
    margin: '0 0 18px',
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    opacity: 0,
    '& .img': {
      marginRight: spacing[2],
      opacity: 0.7,
    },
    '& a': {
      color: colors.contrastText,
      fontSize: 16,
      fontWeight: 600,
      opacity: 0.7,
    },
    '&:hover': {
      '& .img': {
        opacity: 1,
      },
    },
    '&:hover a': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
}));

export const menuBackgroundStyles = {
  backgroundColor: colors.primary,
  height: 3,
  width: 1.2,
  position: 'absolute',
  top: 20,
  right: 25,
  zIndex: 0,
};

/**
 * @type {import('framer-motion').MotionStyle}
 */
export const menuItemContainerStyles = {
  margin: 0,
  position: 'absolute',
  top: 82,
  width: 188,
  right: 27,
};
