import { makeStyles } from '@material-ui/core/styles';

import cardNote from 'assets/svg/illume-made-me-feel.svg';
import { colors } from 'constants/design';

const SOCIAL_SHARES_MAX_WIDTH = 550;

export const useExampleStyles = makeStyles(() => ({
  inputBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundImage: `url(${cardNote})`,
    width: 256,
    height: 460,
  },
  tooltipMessage: {
    marginTop: '50px',
    marginLeft: '80px',
    width: '50px',
  },
  tooltipFeeling: {
    marginBottom: '90px',
    marginRight: '80px',
    justifyContent: 'flex-end',
  },
}));

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    maxWidth: SOCIAL_SHARES_MAX_WIDTH,
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    marginTop: theme.spacing(5),
  },
  headerBox: {
    paddingTop: theme.spacing(2),
  },
  textBox: {
    paddingTop: theme.spacing(1),
    color: colors.contrastText,
  },
  socialButtons: {
    paddingTop: ({ iOS, showTooltipSave }) =>
      iOS && showTooltipSave ? theme.spacing(8) : theme.spacing(2),
    paddingBottom: theme.spacing(5),
    textAlign: 'center',
  },
  button: {
    '&:focus': { outline: 'none' },
    '&:hover': { background: 'none' },
  },
  iOSButton: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&:focus': { outline: 'none' },
    '&:hover': { background: 'none' },
  },
}));
