import { css, cx } from '@emotion/css';
import { NotificationTypes } from '@illume/shared/types';
import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { runInAction } from 'mobx';
import { observer, Observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { match, P } from 'ts-pattern';

import { Button } from 'components/illume/buttons';
import { HtmlRenderer } from 'components/illume/html-renderer';
import {
  InviteContribute,
  ReadyToSend,
  ReceiveReply,
  ReceiveCard,
  RemindContribute,
  General,
} from 'components/illume/notification';
import { ConcreateNotification } from 'components/illume/notification/types';
import { ISurfaceProps } from 'components/illume/surface/Surface';
import { Text } from 'components/illume/text';
import { rem } from 'constants/design';
import { INITIATOR_BASIC_INFO_URL } from 'constants/strings';
import {
  StateError,
  StateLoading,
  StateSuccess,
  useNotificationContext,
} from 'contexts/notification';
import { useServices } from 'contexts/services';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { LayoutV2 } from 'views/components-v2/elements/layout/LayoutV2';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(7),
      paddingTop: theme.spacing(7),
    },
    emptyNotification: {
      marginTop: theme.spacing(20),
      maxWidth: 475,
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(8),
      },
    },
    button: {
      [theme.breakpoints.up('md')]: {
        minWidth: 350,
      },
    },
    progress: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
    },
  }),
);

const Notifications = () => {
  const classes = useStyles();
  const notifContext = useNotificationContext();
  const { notificationService } = useServices();

  const { fetchUnreadCount } = notifContext;

  const isDesktop = useIsDesktop();
  const history = useIllumeHistory();

  const markAsRead = (id: string) => {
    return notificationService
      .markAsRead(id)
      .then(() => {
        runInAction(() => {
          if (notifContext.state instanceof StateSuccess) {
            const n = notifContext.state.notifications.find((n) => n.id === id);
            if (n) return (n.read = true);
          }
        });
      })
      .then(fetchUnreadCount);
  };

  const renderNotification = (
    type: NotificationTypes,
    props: Pick<ConcreateNotification<any>, 'rounded' | 'notification'>,
  ) => {
    return match(type)
      .with(NotificationTypes.INVITE_CONTRIBUTE, () => (
        <InviteContribute markAsRead={markAsRead} {...props} />
      ))
      .with(NotificationTypes.READY_TO_SEND, () => (
        <ReadyToSend markAsRead={markAsRead} {...props} />
      ))
      .with(NotificationTypes.RECEIVE_CARD, () => (
        <ReceiveCard markAsRead={markAsRead} {...props} />
      ))
      .with(NotificationTypes.RECEIVE_REPLY, () => (
        <ReceiveReply markAsRead={markAsRead} {...props} />
      ))
      .with(NotificationTypes.GENERAL, () => <General markAsRead={markAsRead} {...props} />)
      .with(NotificationTypes.REMIND_CONTRIBUTE, () => (
        <RemindContribute markAsRead={markAsRead} {...props} />
      ))
      .exhaustive();
  };

  return (
    <LayoutV2
      containerAs={Grid}
      containerProps={{
        spacing: 3,
        className: cx(
          classes.container,
          css`
            padding-top: 100px !important;
          `,
        ),
      }}
    >
      {() => {
        return (
          <>
            <Grid
              item
              xs={12}
              style={isDesktop ? { zIndex: 100, position: 'relative' } : undefined}
            >
              <Text fontWeight={900} fontSize={{ mobile: rem[1375], desktop: rem[2250] }}>
                MY NOTIFICATIONS
              </Text>
            </Grid>
            {match(notifContext.state)
              .with(P.instanceOf(StateLoading), () => (
                <Grid container item xs={12} justifyContent="center">
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                </Grid>
              ))
              .with(P.instanceOf(StateSuccess), (s) => {
                const isNotifEmpty = isEmpty(s.notifications);
                if (isNotifEmpty) {
                  return (
                    <Grid container justifyContent="center" item>
                      <Grid
                        item
                        container
                        className={classes.emptyNotification}
                        direction="column"
                        spacing={2}
                      >
                        <Grid item>
                          <Text
                            fontWeight={900}
                            align="center"
                            fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
                          >
                            OOPS!
                          </Text>
                        </Grid>
                        <Grid item>
                          <Text fontWeight={300} align="center">
                            You haven’t received any notifications yet. Try illume and start a group
                            card today and populate this page.
                          </Text>
                        </Grid>
                        <Grid item>
                          <Box mt={2} className={classes.button}>
                            <Button
                              onClick={() => history.push(INITIATOR_BASIC_INFO_URL)}
                              fullWidth
                            >
                              start a group card
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } else {
                  const hasMore = s.total > s.notifications.length;

                  return (
                    <AnimatePresence>
                      <Grid
                        component={motion.div}
                        // animate bottom to top
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        item
                        xs={12}
                      >
                        <Observer>
                          {() => (
                            <InfiniteScroll
                              className="customIllumeScrollbar-gray"
                              scrollThreshold={0.9}
                              height={isDesktop ? '70vh' : undefined}
                              style={{
                                marginLeft: -20,
                                paddingLeft: 20,
                                paddingTop: isDesktop ? 0 : 30, // 30px to accomodate scroll to refresh!
                              }}
                              pullDownToRefresh={!isDesktop}
                              pullDownToRefreshThreshold={100}
                              pullDownToRefreshContent={
                                <Observer>
                                  {() => (
                                    <Text align="center">
                                      <HtmlRenderer
                                        html={
                                          s.refreshing
                                            ? 'refreshing...'
                                            : '&#8595; Pull down to refresh'
                                        }
                                      />
                                    </Text>
                                  )}
                                </Observer>
                              }
                              releaseToRefreshContent={
                                <Text align="center">&#8593; Release to refresh</Text>
                              }
                              loader={
                                <div className={classes.progress}>
                                  <Text>loading..</Text>
                                  <CircularProgress size={16} style={{ marginLeft: 10 }} />
                                </div>
                              }
                              next={s.loadMoreNotifications}
                              hasMore={hasMore}
                              refreshFunction={s.refreshNotifications}
                              dataLength={s.notifications.length}
                            >
                              {s.notifications.map((notification, idx, arr) => {
                                const first = idx === 0;
                                const last = idx === arr.length - 1;
                                const { type } = notification;
                                const rounded: ISurfaceProps['rounded'] =
                                  arr.length === 1
                                    ? ['top', 'bottom']
                                    : first
                                    ? ['top']
                                    : last
                                    ? ['bottom']
                                    : [];
                                return (
                                  <Box key={idx} style={{ marginBottom: 2 }}>
                                    {renderNotification(type, { notification, rounded })}
                                  </Box>
                                );
                              })}
                            </InfiniteScroll>
                          )}
                        </Observer>
                      </Grid>
                    </AnimatePresence>
                  );
                }
              })
              .with(P.instanceOf(StateError), () => <div>there was an issue fetching the data</div>)
              .otherwise(() => (
                <div>invalid state..</div>
              ))}
          </>
        );
      }}
    </LayoutV2>
  );
};

export default observer(Notifications);
