import { ContributorCardDTO, InitiatorCardViewDTO, ValueOf } from '@illume/shared';
import { RouterStore } from '@superwf/mobx-react-router';
import { assign } from 'lodash';
import { action, makeAutoObservable, when } from 'mobx';
import { match } from 'ts-pattern';

import { LOGIN_URL } from 'constants/strings';
import { IStorage } from 'contexts/store';
import AuthenticationStore from 'contexts/store/auth-store/auth.store';
import StripeStore from 'contexts/store/StripeStore.store';
import { UserProfileStore } from 'contexts/user';
import { INoteService } from 'domain/interfaces/INoteService';
import { CodeType, determineCodeType, UpdateCardPayloadOpts } from 'infra/card-service/cardService';
import { CARD_ERRORS } from 'types';
import logger from 'utils/logger';
import { MyCardsV2Store } from 'views/pages-v2/my-cards/MyCardsV2.store';

import { SendCardSchema } from './card-details-v2-pending/initiator/constants/constants';
import { GiftPaymentFromCardDetailsStore } from './CardDetailsV2.store';

type State<T, E = Error> =
  | { type: 'idle' }
  | { type: 'loading' }
  | { type: 'loaded'; data: T; giftPaymentFromCardDetailsStore: GiftPaymentFromCardDetailsStore }
  | {
      type: 'refreshing';
      data: T;
      giftPaymentFromCardDetailsStore: GiftPaymentFromCardDetailsStore;
    }
  | { type: 'error'; error: E };
export class CardDetailsStore {
  readonly cacheOn = false;
  state: State<InitiatorCardViewDTO | ContributorCardDTO> = { type: 'idle' };
  constructor(
    private code: string,
    private email: string | null,
    private authStore: Pick<AuthenticationStore, 'authenticated'>,
    private onError: any,
    private redirect: any,
    private routerStore: RouterStore,
    private userProfileStore: UserProfileStore,
    private stripeStore: StripeStore,
    private cache: IStorage<InitiatorCardViewDTO | ContributorCardDTO>,
    private myCardsV2Store: MyCardsV2Store,
    private noteService: INoteService,
    public readonly cardlistUrl: string,
  ) {
    makeAutoObservable(this);
    this.init();
  }

  patchCard(card: Partial<InitiatorCardViewDTO & ContributorCardDTO>) {
    if (this.state.type === 'loaded') {
      this.state.data = assign(this.state.data, card);
    }
  }

  getCard2 = () => {
    const service = match(determineCodeType(this.code))
      .with(
        CodeType.ViewCode,
        () => (code: string) => this.myCardsV2Store.getCard(code, 'Initiator'),
      )
      .with(
        CodeType.InviteCode,
        () => (code: string) => this.myCardsV2Store.getCard(code, 'Contributor'),
      )
      /**
       * we don't have recipient card in this card details,
       * recipient is in separete page
       */
      .with(
        CodeType.UUIDRecipientOrContributorInvite,
        () => (code: string) => this.myCardsV2Store.getCard(code, 'Contributor'),
      )
      .with(undefined, () => () => Promise.reject('invalid code type'))
      .exhaustive();

    return service(this.code).then((card) => card);
  };

  init = () => {
    this.state = { type: 'loading' };
    return this.cacheOn
      ? this.cache
          .get(this.code)
          .then(
            action((data) => {
              if (data) {
                const ps = new GiftPaymentFromCardDetailsStore(
                  this.stripeStore,
                  this.userProfileStore,
                  this.routerStore,
                  this.refreshCard,
                  data,
                );
                return (this.state = {
                  type: 'refreshing',
                  data: data,
                  giftPaymentFromCardDetailsStore: ps,
                });
              } else {
                return logger.log(`no cache date found for ${this.code}`);
              }
            }),
          )
          .then(this.getCard2)
      : this.getCard2()
          .then(
            action((card) => {
              const paymentStore = new GiftPaymentFromCardDetailsStore(
                this.stripeStore,
                this.userProfileStore,
                this.routerStore,
                this.refreshCard,
                card,
              );
              this.state = {
                type: 'loaded',
                data: card,
                giftPaymentFromCardDetailsStore: paymentStore,
              };
              this.cache
                .set(this.code, card)
                .then(() => logger.log(`cache set for card: ${this.code}`));
              return card;
            }),
          )
          .catch((e: any) => {
            const errCode = e.errorCode as ValueOf<typeof CARD_ERRORS>;
            const email = this.email;
            console.error(e);

            match(errCode)
              // use case: only with initiator
              // we allow anonymous user to see contributor card
              .with(CARD_ERRORS.PERMISSION_DENIED, () => {
                const message = email
                  ? `please login with the account assoicated with the card: ${email} 🙂`
                  : 'please login with the account assoicated with the card 🙂';
                this.onError(message);
                return !this.authStore.authenticated
                  ? this.redirect({ pathname: LOGIN_URL }, { from: window.location.pathname })
                  : this.redirect({ pathname: this.cardlistUrl });
              })
              .with(CARD_ERRORS.CARD_NOT_FOUND, () => {
                this.onError('card not found');
                this.redirect({ pathname: this.cardlistUrl });
              })
              .with(CARD_ERRORS.UNEXPECTED_ERROR, () => {
                this.onError('something went wrong');
                this.redirect({ pathname: this.cardlistUrl });
              })
              .with(CARD_ERRORS.SubscriptionNotFound, () => {
                this.onError('something went wrong');
                this.redirect({ pathname: this.cardlistUrl });
              })
              .otherwise(() => {
                this.onError('unexpected error');
                this.redirect({ pathname: this.cardlistUrl });
              });
          });
  };

  refreshCard = () => {
    if (this.state.type === 'loaded') {
      this.state = { ...this.state, type: 'refreshing' };
      return this.getCard2()
        .then(
          action((card) => {
            if (this.state.type === 'refreshing') {
              this.state = { ...this.state, type: 'loaded', data: card };
            }
            this.cache
              .set(this.code, card)
              .then(() => logger.log(`cache set for card: ${this.code}`));
          }),
        )
        .catch((e) => logger.error('error when refreshing the card', e));
    }

    return Promise.resolve(null);
  };

  deleteCardNoteTask = (code: string) =>
    this.noteService.deleteNote(code).then(() => {
      if ('data' in this.state) {
        this.patchCard({ notes: this.state.data.notes.filter((n) => n.viewCode !== code) });
      }
      this.refreshCard();
    });

  removeCardGiftTask = async () => {
    return this.myCardsV2Store
      .deleteShopifyGiftOfACard(this.code)
      .then(() => this.patchCard({ giftV2: undefined }))
      .then(() => {
        this.refreshCard();
      });
  };

  removeExternalGiftTask = async () => {
    return this.myCardsV2Store
      .deleteExternalGiftOfACard(this.code)
      .then(() => this.patchCard({ externalGift: undefined }))
      .then(() => {
        this.refreshCard();
      });
  };

  removeCardTask = () =>
    this.myCardsV2Store.deleteCard(this.code).then(() => {
      return this.cache.del(this.code);
    });

  inviteContributors = (contacts: string[]) =>
    this.myCardsV2Store.inviteContributors(this.code, { inviteContacts: contacts }).then(() => {
      // not returning this
      this.refreshCard();
    });

  sendCard = async ({ message }: { message: string }) => {
    if (this.state.type === 'loaded') {
      const result = await this.myCardsV2Store.sendCard(this.code, {
        message,
        recipientsContacts: this.state.data.recipientData.map((r) => ({
          recipientId: r.id,
          contact: r.contact,
        })),
      });
      this.patchCard({ status: 'Delivered' });

      this.refreshCard();
      return result;
    }
  };

  resendWithMaybeNewContacts = async (payload: SendCardSchema) => {
    if (this.state.type === 'loaded') {
      const result = await this.myCardsV2Store.sendCard(this.code, {
        message: payload.message,
        recipientsContacts: payload.recipientsContacts,
      });

      this.refreshCard();
      return result;
    }
  };

  upsertIntroMessage = (message: string) => {
    return this.updateCard({ introMessage: message });
  };

  updateCard = (payload: UpdateCardPayloadOpts) => {
    if (payload.scheduledTimestamp) {
      const targetDate = new Date(payload.scheduledTimestamp).getTime();
      const now = new Date().getTime();
      const diffInMs = targetDate - now;
      const greaterThan24Hrs = diffInMs > 24 * 60 * 60 * 1000;

      if (!greaterThan24Hrs) {
        return Promise.reject(new Error('Time scheduled should be >24 hrs from now'));
      }
    }
    return this.myCardsV2Store.updateCard(this.code, payload).then(async (card) => {
      await when(() => this.state.type === 'loaded');
      this.patchCard({
        initiatorName: card.initiatorName,
        recipientData: card.recipientData,
        themepack: card.themepack,
        deadline: card.deadline,
        scheduledTimestamp: card.scheduledTimestamp ? card.scheduledTimestamp : undefined,
        introMessage: card.introMessage,
        recipientMessage: card.recipientMessage,
      });
      if ('data' in this.state) {
        this.cache.set(this.code, this.state.data);
      }
      return;
    });
  };
}
