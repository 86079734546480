import { Elements } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';

import { useStores } from 'contexts/store';

import { PayForGift } from '../contributor/ContributorGift';
import GiftPaymentFromCardDetailsStore from './CardDetailsV2.store';

function CardDetailsPayForGift({ store }: { store: GiftPaymentFromCardDetailsStore }) {
  const { stripeStore } = useStores();

  return (
    <Elements stripe={stripeStore.stripe}>
      <PayForGift store={store} />
    </Elements>
  );
}

export default observer(CardDetailsPayForGift);
