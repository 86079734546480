import { useState } from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CardElement, PaymentRequestButtonElement, useElements } from '@stripe/react-stripe-js';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { Box } from 'components/box';
import { Button } from 'components/illume/buttons';
import { HtmlRenderer } from 'components/illume/html-renderer';
import { Text } from 'components/illume/text';
import { CreditCardInputLight } from 'components/illume/text-inputs/CreditCardInputLight';
import { IllumeLayout } from 'components/layouts';
import { colors } from 'constants/design';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import StripeStore from 'contexts/store/StripeStore.store';
import { Money } from 'domain/entities/money/Money';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { checkFF } from 'utils/featureFlag';
import logger from 'utils/logger';

import { FAQS, IPricing, SLACK_APP_PRICING_DATA } from '../initiator/paywall/constants';
import { FaqElement } from '../initiator/paywall/FaqElement';
import PricingCard from '../initiator/paywall/PricingCard';
import RadioSelect from '../initiator/paywall/RadioSelect';

// import { PRICING_DATA, FAQS, IPricing } from './constants';
// import { FaqElement } from './FaqElement';
// import PricingCard from './PricingCard';
// import RadioSelect from './RadioSelect';

interface SlackAppPaywallProps {
  stripeStore: StripeStore;
  onPaymentSuccess: (workspaceUrl: string) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      maxWidth: 1201,
      width: '100%',
      padding: '100px 25px 150px 25px ',
    },
    faqContent: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& > .faqs': {
        width: '100%',
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 16,
      },
    },
    content: {
      // flex justify between
      marginBottom: 120,
      display: 'flex',
      rowGap: 48,
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        margin: '0 auto',
      },
      '& .preview': {
        marginTop: 0,
        [theme.breakpoints.up('md')]: {
          marginTop: 120,
        },
      },
      '& .plans': {
        width: '100%',
        maxWidth: 500,
        '& > .title': {
          marginBottom: 24,
        },
        '& .payment-container': {
          marginTop: 24,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 24,
          '& > .payment-button': {},
          '& > .cc-input': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 12,
          },
          '& > .total': {},
          '& > .cta': {},
        },
        '& > .copy': {
          marginTop: 30,
        },
        '& > .radio-group': {
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          '& .radio': {
            width: '100%',
          },
        },
      },
    },
  }),
);

export type Faq = {
  question: string;
  answer: string;
};

class UI {
  selectedPlan: IPricing = SLACK_APP_PRICING_DATA[1];
  setSelectedPlan = (plan: IPricing) => {
    this.selectedPlan = plan;
  };

  constructor() {
    makeAutoObservable(this);
    // check if is allowed
    const planFromUrl = SLACK_APP_PRICING_DATA.find(
      (p) => p.pricingType === new URLSearchParams(window.location.search).get('pricingType'),
    );

    if (planFromUrl) {
      this.selectedPlan = planFromUrl;
    }
  }
}

export const SlackAppPayment = observer(
  ({ stripeStore, onPaymentSuccess }: SlackAppPaywallProps) => {
    const classes = useStyles();
    const [ui] = useState(() => new UI());
    const selectedPlan = ui.selectedPlan;
    const ctaVariant = selectedPlan.tag ? 'primary' : 'secondary';
    const { userProfileStore } = useStores();
    const [ready, setReady] = useState(false);
    const elements = useElements();
    const stripe = stripeStore.stripe!;
    const { cardService } = useServices();
    const paymentIntentService = cardService.getClientSecretForCardPayment;
    const workspaceUrl = new URLSearchParams(window.location.search).get('workspace') || '';

    const hasReachedCardLimit = false;

    const shouldShowPayment = selectedPlan.pricingType !== 'Basic';

    console.log('selectedPlan', selectedPlan);
    const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useIllumeSnackbar();

    const asyncConfirmCard = useAsyncCallback(stripe.confirmCardPayment, {
      onError: (error) => {
        logger.error('Error confirming card', error);
        enqueueErrorSnackbar('Error confirming card', {
          variant: 'error',
        });
      },
      onSuccess: ({ paymentIntent }) => {
        if (paymentIntent) {
          enqueueSuccessSnackbar('Payment successful');
          return onPaymentSuccess(workspaceUrl);
        } else {
          enqueueErrorSnackbar('Payment failed');
        }
      },
    });

    const asyncGetClientSecret = useAsyncCallback(paymentIntentService, {
      onError: (error) => {
        logger.error('Error creating payment intent', error);
        enqueueErrorSnackbar(
          `Error creating payment intent: ${error.message || 'unexpected error'}`,
        );
      },
    });

    const asyncButtonInit = useAsync(
      (plan: IPricing) =>
        stripeStore.initStripePaymentButtonForCardPlan(plan, {
          onError: (error) => {
            logger.error('error while paying with google/apple pay. try CC method instead', error);
            enqueueErrorSnackbar('Apple/Google pay error');
          },
          onSuccess: () => onPaymentSuccess(workspaceUrl),
        }),
      [selectedPlan],
      {
        onSuccess: () => {
          setReady(false);
        },
      },
    );

    const paymentRequest = asyncButtonInit.result;

    const handleChange = (e: any) => {
      SLACK_APP_PRICING_DATA.find((item) => {
        if (item.pricingType === e.target.value) {
          ui.setSelectedPlan(item);
        }
      });
    };

    async function handleCompletePurchase() {
      // special case for basic plan
      if (selectedPlan.pricingType === 'Basic') {
        // make it immediately success
        return onPaymentSuccess(workspaceUrl);
      }

      // GUARDS
      if (!elements) {
        logger.error('stripe Elements is not defined');
        return enqueueErrorSnackbar('Something went wrong. Please try again later.');
      }
      const element = elements.getElement(CardElement);
      if (!element) {
        logger.error('stripe CardElement is not defined');
        return enqueueErrorSnackbar('Something went wrong. Please try again later.');
      }

      const payload = {
        plan: selectedPlan.pricingType as 'Slack Standard' | 'Slack Basic',
        workspace: workspaceUrl,
      };

      const clientSecret = await asyncGetClientSecret.execute(payload);

      // confirm card
      await asyncConfirmCard.execute(clientSecret, {
        payment_method: {
          card: element,
          billing_details: {
            name: userProfileStore.fullName || '',
          },
        },
      });
    }

    const CTA_COPY = selectedPlan.pricingType === 'Basic' ? 'Continue' : 'Complete Purchase';

    return (
      <IllumeLayout
        contentProps={{
          style: {
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      >
        <div className={classes.container}>
          <div className={classes.content}>
            <div className="plans">
              <div className="title">
                <Text fontSize={{ desktop: 64, mobile: 40 }} fontWeight={700}>
                  Select a Plan
                </Text>
              </div>
              <div className="radio-group">
                <RadioSelect
                  containerProps={{ className: 'radio' }}
                  value={selectedPlan.pricingType}
                  onChange={handleChange}
                  onDisabledClick={() => {
                    enqueueErrorSnackbar('Ineligible plan; you’ve already sent 2 cards.');
                  }}
                  options={SLACK_APP_PRICING_DATA.map((item, index) => {
                    // free if the price is 0
                    const formattedPrice =
                      item.price.getAmount() === 0
                        ? 'Free'
                        : // check if ends with .00
                        item.price.toFormat().endsWith('.00')
                        ? // remove .00
                          item.price.toFormat().slice(0, -3)
                        : // else just return the formatted price
                          item.price.toFormat();

                    return {
                      value: item.pricingType,
                      label: item.copy,
                      title: item.copy,
                      subtitle: `${formattedPrice} ${item.frequency?.toLowerCase() || ''}`,
                      disabled: item.pricingType === 'Basic' && hasReachedCardLimit,
                      subtitleColor: colors.gray60,
                    };
                  })}
                />
              </div>
              <div className="copy">
                <Text fontWeight={400} fontSize={{ desktop: 18, mobile: 14 }} color={colors.gray60}>
                  <HtmlRenderer html={selectedPlan.description} />
                </Text>
              </div>

              <div className="payment-container">
                {checkFF('APPLE_PAY') && shouldShowPayment && (
                  <div className="payment-button">
                    {
                      paymentRequest ? (
                        <>
                          <PaymentRequestButtonElement
                            onReady={() => setReady(true)}
                            options={{ paymentRequest }}
                          />
                          {!ready && (
                            <Box display={'flex'} flexDirection="center" alignItems="center">
                              {/*text that says waiting for google apple to ready*/}
                              <Text
                                fontSize={{ desktop: 14, mobile: 12 }}
                                fontWeight={400}
                                color={colors.gray60}
                              >
                                Waiting for Google/Apple Pay to be ready...
                              </Text>
                            </Box>
                          )}
                        </>
                      ) : null
                      // <Alert severity="warning">
                      //   Either your browser does not support the Google/Apple Payment, or you do
                      //   not have a
                      //   <MuiLink href="https://support.google.com/accounts/answer/9244912">
                      //     {' '}
                      //     saved payment method.
                      //   </MuiLink>
                      // </Alert>
                    }
                  </div>
                )}
                {shouldShowPayment && (
                  <div className="cc-input">
                    <CreditCardInputLight
                      className="fs-mask"
                      error={asyncConfirmCard.error}
                      onChange={asyncConfirmCard.error ? () => asyncConfirmCard.reset() : undefined}
                    />
                  </div>
                )}

                <div className="total">
                  <Text
                    // strike through the price if there is a promo code
                    style={
                      false
                        ? {
                            textDecoration: 'line-through',
                            color: colors.gray60,
                          }
                        : {}
                    }
                    fontWeight={700}
                  >
                    {selectedPlan.price.equalsTo(Money.fromCents(0))
                      ? 'Total: Free'
                      : selectedPlan.pricingType === 'Premium'
                      ? `Total: ${selectedPlan.price.toFormat()} `
                      : `Total: ${selectedPlan.price.toFormat()} (one time charge)`}
                  </Text>
                </div>
                <div className="cta">
                  <Button
                    disabled={asyncGetClientSecret.loading || asyncConfirmCard.loading}
                    style={{ minWidth: 168, maxWidth: 250 }}
                    onClick={handleCompletePurchase}
                  >
                    <Text color={colors.contrastText} fontSize={18} fontWeight={500}>
                      {asyncGetClientSecret.loading
                        ? 'Creating order...'
                        : asyncConfirmCard.loading
                        ? 'Confirming Order...'
                        : CTA_COPY}
                    </Text>
                  </Button>
                </div>
              </div>
            </div>
            <div className="preview">
              <PricingCard
                // force re-render when selectedPlan changes
                // for restarting collapse purpose
                key={selectedPlan.pricingType}
                banner={selectedPlan.banner}
                note={selectedPlan.note}
                per={selectedPlan.frequency}
                label={selectedPlan.copy}
                subtitle={selectedPlan.subtitle}
                tag={selectedPlan.tag}
                benefits={selectedPlan.benefits}
                hiddenBenefits={selectedPlan.hiddenBenefits}
                formattedPrice={
                  selectedPlan.price.equalsTo(Money.fromCents(0))
                    ? 'Free'
                    : // check if ends with .00
                    selectedPlan.price.toFormat().endsWith('.00')
                    ? // slice off the .00
                      selectedPlan.price.toFormat().slice(0, -3)
                    : selectedPlan.price.toFormat()
                }
                ctaVariant={ctaVariant}
                hiddenBenefitsEnabled={true}
                action={undefined}
              />
            </div>
          </div>
          <div className={classes.faqContent}>
            <Text fontSize={{ desktop: 64, mobile: 40 }} style={{ marginBottom: 40 }}>
              Frequently Asked Questions
            </Text>
            <div className={'faqs'}>
              {FAQS.map((faq, index) => (
                <FaqElement key={index} faq={faq} />
              ))}
            </div>
          </div>
        </div>
      </IllumeLayout>
    );
  },
);
