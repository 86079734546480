import { ShopifyShippingInfo, ShopifyShippingTrackInfoDTO } from '@illume/shared/types';
import { isEmpty, omitBy } from 'lodash';

import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { IShippingService } from 'domain/interfaces/IShippingService';
import { ShopifyShippingInfoOnlyEmail } from 'pages/illume/recipient/ShippingFormEmailOnly';
import { CustomError } from 'utils';

export class ShopifyShippingService implements IShippingService {
  constructor(private httpClient: IHttpClient) {}

  saveShippingInfo = async (
    code: string,
    payload: ShopifyShippingInfo | ShopifyShippingInfoOnlyEmail,
  ): Promise<string> => {
    const data = omitBy({ ...payload }, isEmpty);
    const requestObj = {
      url: `/shopify/shipping/${code}`,
      method: 'post',
      data: data,
    };
    const resp = await this.httpClient.makeRequest<{ message: string }>(requestObj);
    if (resp.success) {
      return resp.message;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  getProductTrackingInfo = async (code: string) => {
    const requestObj = {
      url: `/shopify/trackOrder/${code}`,
      method: 'get',
    };

    const resp = await this.httpClient.makeRequest<ShopifyShippingTrackInfoDTO>(requestObj);
    if (resp.success) {
      return resp;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };
}
