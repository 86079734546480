import { CSSProperties, useEffect } from 'react';

import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { isMobileSafari } from 'react-device-detect';
import { match } from 'ts-pattern';

import HideOnScroll from 'components/hide-on-scroll';
import Appbar, { AppBarProps } from 'components/layouts/appbar/Appbar';
import { BreadCrumbsAppBar } from 'components/layouts/breadcrumbs-appbar';
import { colors } from 'constants/design';

import { BreadCrumbsAppBarProps } from './breadcrumbs-appbar/BreadCrumbsAppBar';
import styles from './IllumeLayout.module.scss';
import LoginAndCreateCardAppBar from './login-and-create-cta';
type Props = {
  background: string;
  overflow: CSSProperties['overflow'];
  overflowX: CSSProperties['overflowX'] | null;
};
const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    container: ({ background, overflow, overflowX }) => {
      return {
        background: background,
        overflow: overflow ? overflow : 'initial',
        paddingLeft: 0,
        paddingRight: 0,
        overflowX: overflowX ? overflowX : undefined,
        minHeight: isMobileSafari ? '100vh' : undefined,
        transition: theme.transitions.create(['background'], {
          duration: 300,
          easing: 'ease',
        }),
      };
    },

    appbar: {
      position: 'fixed',
      width: '100%',
      zIndex: theme.zIndex.appBar,
    },
    content: {
      // only the root container is scrollable
      // avoid double scrollbar
      overflowY: 'hidden',
      // overflowX: 'hidden',
    },
  }),
);

export type IllumeLayoutProps = {
  hideHamburger?: AppBarProps['hideHamburger'];
  contentProps?: any;
  overflow?: CSSProperties['overflow'];
  overflowX?: CSSProperties['overflowX'] | null;
  lockScroll?: boolean;
  fullHeight?: boolean;
  showIllumeLogo?: boolean;
  appBarVariant?: 'default' | 'breadcrumbs' | 'login_and_create_card_cta' | 'none';
  breadcrumbs?: BreadCrumbsAppBarProps['navs'];
  background?: string;
  contentComponent?: React.ElementType;
  disableAppbar?: boolean;
  containerProps?: any;
  topRightButtons?: AppBarProps['topRightButtons'];
};

export const IllumeLayout: React.FC<IllumeLayoutProps> = ({
  children,
  hideHamburger = false,
  contentProps = undefined,
  overflow = undefined,
  overflowX = 'hidden',
  lockScroll = undefined,
  fullHeight = true,
  showIllumeLogo = undefined,
  appBarVariant = 'default',
  breadcrumbs = [],
  background = colors.background,
  contentComponent: Content = Grid,
  disableAppbar = false,
  containerProps,
  topRightButtons,
}) => {
  const illumeLayoutBg = background;
  const illumeLogoColor = ['transparent', colors.background].includes(illumeLayoutBg)
    ? colors.primary
    : colors.contrastText;

  const classes = useStyles({
    background: illumeLayoutBg,
    overflow,
    overflowX,
  });

  useEffect(() => {
    if (lockScroll) {
      document.body.style.height = '100vh';
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.height = 'auto';
      document.body.style.overflowY = 'visible';
    }
  }, [lockScroll]);

  const { className: contentClassname, ...contentPropsRest } = contentProps || {};

  const appBar = match(appBarVariant)
    .with('default', () => (
      <Appbar
        showIllumeTitle={showIllumeLogo}
        illumeLogoColor={illumeLogoColor}
        hideHamburger={hideHamburger}
        topRightButtons={topRightButtons}
      />
    ))
    .with('breadcrumbs', () => <BreadCrumbsAppBar navs={breadcrumbs} />)
    .with('none', () => null)
    .with('login_and_create_card_cta', () => <LoginAndCreateCardAppBar />)
    .otherwise(() => 'invalid appbar type');

  return (
    <>
      {!disableAppbar && (
        <HideOnScroll>
          <Box className={classes.appbar}>{appBar}</Box>
        </HideOnScroll>
      )}
      <Container
        maxWidth={false}
        className={cx(classes.container, { [styles['browsers-100vh']]: fullHeight })}
        data-testid="illume-layout"
        {...containerProps}
      >
        <Content container className={cx(classes.content, contentClassname)} {...contentPropsRest}>
          {children}
        </Content>
      </Container>
    </>
  );
};
