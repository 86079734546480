import { FunctionComponent } from 'react';

import { ServiceErrorDTO } from '@illume/shared';
import { CircularProgress, Theme } from '@material-ui/core';
import { CheckCircleOutline, CloseOutlined, Image } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopy from '@material-ui/icons/FileCopy';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { action, makeAutoObservable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import ReactDropzone, { DropzoneProps as ReactDropzoneProps, FileWithPath } from 'react-dropzone';
import { match } from 'ts-pattern';

import { Text } from 'components/illume/text';
import { TextProps } from 'components/illume/text/Text';
import { colors } from 'constants/design';

class DropzoneValidationError {
  private constructor(public reasons: string[], public message: string) {}

  static fromServiceError(err: ServiceErrorDTO) {
    return new DropzoneValidationError(
      err.validation?.body.reasons || [],
      err.validation?.body.message || err.message || 'unkown error',
    );
  }
}

export class DropzoneStoreGeneric {
  constructor(private validateService: (files: FileWithPath[]) => Promise<any>) {
    makeAutoObservable(this);
  }

  state:
    | { status: 'idle' }
    | { status: 'validating'; acceptedFiles: FileWithPath[] }
    | { status: 'validated'; acceptedFiles: FileWithPath[]; artefacts?: any }
    | { status: 'error'; error: DropzoneValidationError; acceptedFiles: FileWithPath[] } = {
    status: 'idle',
  };

  assignAndValidate(files: FileWithPath[]) {
    this.state = { status: 'validating', acceptedFiles: files };

    this.validateService(this.state.acceptedFiles)
      .then(
        action((result) => {
          if (this.state.status === 'validating')
            this.state = { status: 'validated', acceptedFiles: files, artefacts: result };
        }),
      )
      .catch(
        action((e: ServiceErrorDTO) => {
          this.state = {
            status: 'error',
            error: DropzoneValidationError.fromServiceError(e),
            acceptedFiles: files,
          };
        }),
      );
  }
  // compatibility with old tightly coupled implementation
  get acceptedFiles() {
    return 'acceptedFiles' in this.state ? this.state.acceptedFiles : null;
  }
  get validating() {
    return this.state.status === 'validating';
  }

  get fileOk() {
    return this.state.status === 'validated' && !!this.acceptedFiles;
  }

  get validationError() {
    return this.state.status === 'error' ? this.state.error : null;
  }
}

interface DropzoneProps extends ReactDropzoneProps {
  store: DropzoneStoreGeneric;
  contentClassName?: string;
  labelTextProps?: TextProps;
  label?: string;
  placeholder?: string;
  mode: 'image' | 'file';
}

const textColor = colors.unknownGrayColor;

const useStyles = makeStyles<Theme, Pick<DropzoneStoreGeneric, 'state'>>((t) =>
  createStyles({
    focus: {
      borderWidth: 2,
      borderRadius: 2,
      borderColor: colors.primarySoft,
      borderStyle: 'dashed',
    },
    dropzone: {
      borderRadius: 8,
      background: colors.backgroundGray,
      '&:hover': {
        background: colors.backgroundGray70,
        cursor: 'pointer',
      },
    },
    dropzoneImageMode: (props) => {
      return match(props.state)
        .with({ status: 'validated' }, (state) => {
          return {
            borderRadius: 8,
            background: `${colors.backgroundGray} url(${state.artefacts}) center/contain no-repeat`,
            '&:hover': {
              background: `${colors.backgroundGray} url(${state.artefacts}) center/contain no-repeat`,
              cursor: 'pointer',
              opacity: 0.8,
            },
          };
        })
        .otherwise(() => {
          return {
            borderRadius: 8,
            background: colors.backgroundGray,
            '&:hover': {
              background: colors.backgroundGray70,
              cursor: 'pointer',
            },
          };
        });
    },
    icon: {
      fontSize: 36,
      color: colors.gray40,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: 12,
      minHeight: 264,
      [t.breakpoints.up('md')]: {
        minHeight: 140,
      },
    },
  }),
);

const Dropzone: FunctionComponent<DropzoneProps> = ({
  store,
  contentClassName,
  labelTextProps,
  label,
  placeholder = `Drag and drop a file here or click`,
  mode,
  ...props
}) => {
  const classes = useStyles({ state: store.state });
  return (
    // Note that there will be nothing logged when files are dropped
    <ReactDropzone onDrop={(files) => store.assignAndValidate(files)} {...props}>
      {({ getRootProps, getInputProps, isDragAccept }) => (
        <Observer>
          {() => (
            <section>
              {label && (
                <label>
                  <Text
                    fontWeight={600}
                    color={colors.labelText}
                    fontSize={{ mobile: '0.875rem', desktop: '1rem' }}
                    {...labelTextProps}
                  >
                    {label}
                  </Text>
                </label>
              )}
              <div
                {...getRootProps({
                  className: classNames(
                    mode === 'image' ? classes.dropzoneImageMode : classes.dropzone,
                    { [classes.focus]: isDragAccept },
                  ),
                })}
              >
                <input {...getInputProps()} />
                {
                  <div className={classNames(classes.content, contentClassName)}>
                    {match(store.state)
                      .with({ status: 'idle' }, () => (
                        <>
                          {mode === 'image' ? (
                            <Image fontSize="inherit" className={classes.icon} />
                          ) : (
                            <CloudUploadIcon className={classes.icon} fontSize="inherit" />
                          )}
                          <Text
                            fontWeight={400}
                            color={textColor}
                            fontSize={{ mobile: 14, desktop: 18 }}
                          >
                            {placeholder}
                          </Text>
                        </>
                      ))
                      .with({ status: 'validating' }, () => null)
                      .with({ status: 'validated' }, (state) => {
                        return mode === 'image' ? null : (
                          <>
                            <FileCopy className={classes.icon} fontSize="inherit" />
                            <ul>
                              {state.acceptedFiles?.map((file) => (
                                <li key={file.path}>
                                  <Text
                                    fontWeight={400}
                                    color={textColor}
                                    fontSize={{ mobile: 14, desktop: 18 }}
                                  >
                                    {file.path}
                                  </Text>
                                </li>
                              ))}
                            </ul>
                          </>
                        );
                      })
                      .with({ status: 'error' }, () => (
                        <>
                          <CloudUploadIcon className={classes.icon} fontSize="inherit" />
                          <Text
                            fontWeight={400}
                            color={textColor}
                            fontSize={{ mobile: 14, desktop: 18 }}
                          >
                            {placeholder}
                          </Text>
                        </>
                      ))
                      .exhaustive()}
                  </div>
                }
              </div>

              <div
                style={{ maxHeight: 200, overflowY: 'scroll', margin: '12px 0' }}
                className="customIllumeScrollbar-orange"
              >
                {store.validating && (
                  <Text color={colors.gray60}>
                    validating <CircularProgress size={12} />{' '}
                  </Text>
                )}
                {store.fileOk && (
                  <Text color={colors.gray60}>
                    file ok!{' '}
                    <CheckCircleOutline style={{ fontSize: 20, color: colors.alertGreen }} />
                  </Text>
                )}
                {store.validationError && (
                  <>
                    <Text color={colors.gray60}>
                      {store.validationError.message.toLocaleLowerCase().includes('invalid upload')
                        ? 'Oops! we ran into validation issue, please check your file:'
                        : store.validationError.message}

                      <CloseOutlined style={{ fontSize: 20, color: colors.alertRed }} />
                    </Text>
                    {store.validationError?.reasons && (
                      <ul style={{ paddingLeft: 4 }}>
                        {store.validationError.reasons.map((r, index) => (
                          <li key={index}>{r}</li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
            </section>
          )}
        </Observer>
      )}
    </ReactDropzone>
  );
};

export default observer(Dropzone);
