import { formatDistanceToNowStrict } from 'date-fns';
import locale from 'date-fns/locale/en-US';

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}mo',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

function formatDistance(token, count, options) {
  const { addSuffix, comparison } = options || {};
  const result = formatDistanceLocale[token].replace('{{count}}', count);

  if (addSuffix) {
    if (comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
}

const distanceFormatter = (datems) =>
  formatDistanceToNowStrict(datems, {
    addSuffix: false,
    locale: {
      ...locale,
      formatDistance,
    },
  });

export default distanceFormatter;
