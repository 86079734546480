import parsePhoneNumber from 'libphonenumber-js';

/**
 *
 * @param {number|string} value
 * @returns
 */
export const formatPhoneNumber = (value) => {
  const phoneNumber = parsePhoneNumber(value, 'US');
  return phoneNumber ? phoneNumber.number : value;
};
