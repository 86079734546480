import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ValueOf } from '@illume/shared';
import { Grid } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { Alert } from '@material-ui/lab';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Maybe } from 'yup/lib/types';

import { Box } from 'components/box';
import { Button } from 'components/illume/buttons';
import { PoweredByStripe } from 'components/illume/icons/PoweredBy';
import { LinedText } from 'components/illume/text';
import { TextInput } from 'components/illume/text-inputs';
import CreditCardInputDark from 'components/illume/text-inputs/CreditCardInput';
import { colors } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { VALIDATION_ERRORS } from 'constants/strings/copywriting';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { ROLES } from 'pages/illume/card-details-v2/shared/constants';
import stripeService from 'services/stripeService';
import { noop } from 'utils';
import { IllumeMoneyFactory } from 'utils/currency';
import { checkFF } from 'utils/featureFlag';
import { isValid } from 'utils/form';
import logger from 'utils/logger';

import { useStyles } from '../../../pages/illume/initiator/add-gift/PayAndSaveForGift.styles';
import { usePayWithCC } from '../../../pages/illume/initiator/add-gift/usePayWithCC';

interface IGift {
  id: string;
}
interface IPaymentFormProps {
  email: string;
  userData?: {
    fullName?: string;
  };
  setUserData: (fv: PaymentFormValues) => any;
  onCCPaymentSuccess: ({ amount, paymentIntent }: { amount: number; paymentIntent: any }) => any;
  gift: IGift;
  paymentRequest: Maybe<PaymentRequest>;
  amount: number;
  role?: ValueOf<typeof ROLES>;
  setupIntentService: typeof stripeService.setupIntent;
  onPay?: () => any;
  analytics: IAnalytics;
}

export interface PaymentFormValues {
  fullName: string;
}

const PaymentForm: React.FunctionComponent<IPaymentFormProps> = ({
  paymentRequest,
  gift,
  amount,
  onCCPaymentSuccess = noop,
  setUserData = noop,
  userData,
  email,
  role = 'user',
  setupIntentService,
  onPay = noop,
  analytics,
}) => {
  // forms
  const paymentFormSchema = yup.object().shape({
    fullName: yup.string().required(VALIDATION_ERRORS.name.required),
  });

  const defaultValues = {
    fullName: userData?.fullName,
  };
  const { pay, paymentIntentError, stripeError, clearErrors, paying } =
    usePayWithCC(setupIntentService);
  const { enqueueErrorSnackbar } = useIllumeSnackbar();

  const onPaySubmit = async ({ fullName }: PaymentFormValues) => {
    onPay();
    const { paymentIntentError, stripeError, setupIntent } = await pay(
      amount,
      gift.id,
      fullName,
      email,
    );
    if (paymentIntentError || stripeError) {
      console.log({ stripeError, paymentIntentError });

      analytics.track(EVENT_NAMES.ERROR_GIFT.name, { paymentIntentError, stripeError });
      logger.error('failed to pay to stripe', { paymentIntentError, stripeError });
      return enqueueErrorSnackbar('something went wrong');
    }

    onCCPaymentSuccess({ amount, paymentIntent: setupIntent });
  };

  const {
    errors,
    register,
    formState: { isSubmitted, isSubmitting, isDirty },
    handleSubmit,
    getValues,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(paymentFormSchema),
    // #1 documentation says that shouldUnregister = false should persist the data
    // when the component unmount
    // but somehow that doesn't work
    shouldUnregister: false,
    defaultValues,
  });

  const classes = useStyles();
  const [ready, setReady] = useState(false);

  // #2 This is a really dirty hack to solve #1
  useEffect(() => {
    return () => {
      setTimeout(() => {
        const fullName = getValues().fullName;
        if (fullName) setUserData({ fullName });
      }, 300);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item container direction="column" spacing={2}>
      {checkFF('APPLE_PAY') && (
        <Grid item>
          {paymentRequest ? (
            <>
              <PaymentRequestButtonElement
                onReady={() => setReady(true)}
                options={{ paymentRequest }}
              />
              {!ready && 'loading apple/google pay button..'}
            </>
          ) : (
            <Alert severity="warning">
              Either your browser does not support the Google/Apple Payment, or you do not have a
              <MuiLink href="https://support.google.com/accounts/answer/9244912">
                {' '}
                saved payment method.
              </MuiLink>
            </Alert>
          )}
        </Grid>
      )}
      <Grid item>
        <Box mb={2} mt={3}>
          <LinedText
            textBackground={colors.background}
            color={colors.labelText}
            fontWeight={500}
            text="or enter card details"
          />
        </Box>
      </Grid>
      <Grid item>
        <TextInput
          inputId="full-name"
          placeholder="Full name"
          label="Full Name"
          error={errors.fullName}
          name="fullName"
          ref={register}
          data-testid="fullName"
        />
      </Grid>

      <Grid item>
        <CreditCardInputDark
          className="fs-mask"
          error={paymentIntentError || stripeError}
          onChange={clearErrors}
        />
      </Grid>
      <Grid item>
        <Button
          role="pay"
          onClick={handleSubmit(onPaySubmit)}
          loading={paying || isSubmitting}
          disabled={
            !isValid(errors) ||
            (!isDirty && isSubmitted) ||
            stripeError?.type === 'validation_error' ||
            !gift ||
            !amount ||
            paying ||
            isSubmitting
          }
          type="submit"
          fullWidth
          customcolor="green"
        >
          {
            /*
             * right now, I think only stripe api errors that makes sense for retrying
             * https://stripe.com/docs/api/errors
             */
            stripeError?.type &&
            ['api_error', 'api_connection_error', 'validation_error'].includes(stripeError.type)
              ? 'Retry'
              : `Pay ${IllumeMoneyFactory({ amount }).toFormat()}`
          }
        </Button>
      </Grid>
      <Box className={classes.branding}>
        <Box width="100px">
          <PoweredByStripe />
        </Box>
      </Box>
    </Grid>
  );
};

export default PaymentForm;
