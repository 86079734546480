/* eslint-disable @typescript-eslint/no-unused-vars */
import { intervalToDuration } from 'date-fns';
import { isChrome, isSafari } from 'react-device-detect';
import { assign, createMachine, send } from 'xstate';

import getSupportedMimeTypes from 'components/video-recorder/getSupportedMediaMimeTypes';
import logger from 'utils/logger';

import { MAX_RECORDING_TIME, TOOLTIP_SHOW_AFTER, TOOLTIP_SHOW_DURATION } from './constants';
import { ffmpegTranscoder } from './service';

const getStreamService = () => {
  return navigator.mediaDevices.getUserMedia({
    video: {
      width: { min: 1024, ideal: 1280, max: 1920 },
      height: { min: 576, ideal: 720, max: 1080 },
    },
    audio: true,
  });
};

const stopStream = ({ stream }) => {
  stream.getTracks().forEach((track) => track.stop());
};

const recordCallbacksHandler = (context, _event) => (send, onReceive) => {
  const { stream } = context;
  const supportedMimeTypes = getSupportedMimeTypes();
  logger.log('supported mimetypes in this browser', supportedMimeTypes);
  const options = isSafari
    ? {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2000000, // if the video bitrate is not specified. it'll output ~11Mb/s and the file size is 6x larger for the same duration
        // the only mimeType supported, at least on safari 14.1
        mimeType: 'video/mp4',
      }
    : isChrome
    ? { mimeType: 'video/webm;codecs=h264' }
    : // firefox doesn't record in h264 format, but instead vp9/vp8
      { mimeType: supportedMimeTypes && supportedMimeTypes[0] };

  const mediaRecorder = new MediaRecorder(stream, options);
  logger.log('recording media with options: ', mediaRecorder);

  let chunks = [];
  mediaRecorder.ondataavailable = (e) => {
    chunks.push(e.data);
  };
  mediaRecorder.onstop = () => {
    const blob = new Blob(chunks, { type: 'video/mp4' });
    send({ type: 'DONE', data: blob });
  };
  mediaRecorder.onerror = (e) => {
    send('ERROR');
  };

  mediaRecorder.start();

  onReceive((e) => {
    if (e.type === 'STOP') {
      mediaRecorder.stop();
    }
  });

  // immediately stop when reaches the max recording time
  const timeout = setTimeout(() => {
    mediaRecorder.stop();
  }, MAX_RECORDING_TIME.seconds * 1000);

  return () => clearTimeout(timeout);
};

const clear = assign({ blob: () => null });

const timerService = () => (send) => {
  const id = setInterval(() => send('TICK'), 1000);
  // Perform cleanup
  return () => {
    clearInterval(id);
  };
};

const setDuration = assign({
  elapsed: (context, _event) => intervalToDuration({ start: context.startTime, end: new Date() }),
});

// show tooltip after 1 sec, then hide it after 5 sec
const tooltip = {
  id: 'tooltip',
  initial: 'tooltip_inactive',
  states: {
    tooltip_inactive: {
      after: {
        [TOOLTIP_SHOW_AFTER]: { target: 'tooltip_active' },
      },
    },
    tooltip_active: {
      after: {
        [TOOLTIP_SHOW_DURATION]: { target: 'tooltip_done' },
      },
    },
    tooltip_done: {
      type: 'final',
    },
  },
};

export const recorderMachine = createMachine({
  id: 'recorder',
  initial: 'initializing',
  context: {
    startTime: null,
    elapsed: { minutes: 0, seconds: 0 },
    stream: null,
    blob: null,
    isSafari,
  },
  on: {
    PROGRESS_UPDATE: {
      actions: assign({ post_process_progress: (_ctx, ev) => ev.progress * 100 }),
    },
  },
  states: {
    initializing: {
      invoke: {
        src: getStreamService,
        onError: 'initFail',
        onDone: {
          target: 'ready',
          actions: [
            assign({
              stream: (_ctx, event) => event.data,
            }),
          ],
        },
      },
    },
    initFail: {
      type: 'final', // maybe try to re-ask the user to give permission to camera?
    },
    ready: {
      onDone: [
        // we don't transcode the video recorded from safari
        // since it outputs the format we want
        // which is audio: aac, video: h264, container: mp4
        { target: 'finished', cond: (ctx) => ctx.isSafari },
        { target: 'ffmpegTranscoding' },
      ],
      initial: 'idle',
      exit: stopStream,
      states: {
        idle: {
          entry: ['playStream', clear],
          on: { RECORD: 'recording' },
          ...tooltip,
        },
        recording: {
          invoke: [
            { src: timerService },
            {
              id: 'recordCallbacksHandler',
              src: recordCallbacksHandler,
            },
          ],
          on: {
            TICK: {
              actions: [setDuration],
            },
            STOP: { actions: send('STOP', { to: 'recordCallbacksHandler' }) },
            ERROR: 'idle',
            DONE: [
              {
                target: 'recorded',
                actions: [assign({ blob: (_ctx, ev) => ev.data })],
              },
            ],
          },
          entry: [assign({ startTime: () => new Date() })],
          exit: [assign({ startTime: () => null, elapsed: () => ({ minutes: 0, seconds: 0 }) })],
        },
        recorded: {
          initial: 'paused',
          entry: ['switchSource'],
          states: {
            playing: {
              on: { PAUSE: 'paused', END: 'ended' },
              entry: 'play',
            },
            paused: {
              on: { PLAY: 'playing' },
              entry: 'pause',
            },
            ended: {
              on: { PLAY: { target: 'playing', actions: 'reset' } },
            },
          },
          on: {
            THROW_AWAY: { target: 'idle' },
            ACCEPT: { target: 'accepted' },
          },
        },
        accepted: {
          type: 'final',
        },
      },
    },
    ffmpegTranscoding: {
      invoke: {
        src: ffmpegTranscoder,
        onDone: { target: 'finished', actions: assign({ blob: (_, ev) => ev.data }) },
        onError: { target: 'initializing', actions: 'alertErr' },
      },
    },
    finished: {
      type: 'final',
      invoke: {
        src: 'onFinished',
      },
    },
  },
});
