import { FC } from 'react';

import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';

import { borderRadius, colors } from 'constants/design';

import { buttonStyles } from './BaseButton.styles';

export type IllumeButtonVariants =
  | 'gray'
  | 'grey'
  | 'primaryShaded'
  | 'green'
  | 'white'
  | 'darkGray'
  | 'darkGrey'
  | 'background';

export interface IllumeBaseButtonProps extends Omit<ButtonProps, 'size' | 'variant'> {
  loading?: boolean;
  customcolor?: IllumeButtonVariants;
  rounded?: boolean;
  borderRadius?: number;
  size?: 'compact' | 'default';
  circularProgressProps?: CircularProgressProps;
  variant?: 'outlined' | 'contained';
  height?: string;
}

const BaseButton: FC<IllumeBaseButtonProps> = ({
  children,
  loading,
  size,
  customcolor,
  rounded,
  borderRadius,
  circularProgressProps,
  variant = 'contained',
  height,
  ...props
}) => {
  const classes = buttonStyles({
    color: customcolor,
    rounded,
    size,
    borderRadius,
    variant,
    height,
  });
  return (
    <Button variant="contained" classes={classes} {...props}>
      {loading ? (
        <CircularProgress
          size={24}
          style={{ color: colors.contrastText }}
          {...circularProgressProps}
        />
      ) : (
        children
      )}
    </Button>
  );
};

BaseButton.defaultProps = {
  color: 'primary',
  loading: false,
  rounded: false,
  borderRadius: borderRadius,
};

export default BaseButton;
