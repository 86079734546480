import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { GeneralNotificationDTO } from 'types';
import { noop } from 'utils';
import distanceFormatter from 'utils/date/distanceFormatter';

import { HtmlRenderer } from '../html-renderer';
import { Notification } from './Notification';
import { ConcreateNotification } from './types';

const General: React.FC<ConcreateNotification<GeneralNotificationDTO>> = ({
  notification,
  rounded,
  style,
  markAsRead = noop,
}) => {
  const { timestamp, read, payload, id } = notification || {};
  const { message, redirectLink } = payload || {};

  const history = useIllumeHistory();

  const handleClick = () => {
    const url = new URL(redirectLink);
    const isRelativePath = url.hostname === window.location.hostname;

    let pathname = isRelativePath ? url.pathname : redirectLink;

    const config = redirectLink
      ? {
          pathname,
        }
      : null;
    if (config) {
      history.push(config);
    }
    markAsRead(id);
  };

  return (
    <Notification dotted={!read} rounded={rounded} style={style} onClick={handleClick}>
      <Text display="inline" fontSize={rem[875]} color={colors.gray40}>
        <HtmlRenderer html={message} />
      </Text>
      <Text fontSize={rem[875]} display="inline" color={colors.gray20}>
        {' '}
        {distanceFormatter(new Date(timestamp))}
      </Text>
    </Notification>
  );
};

export default General;
