import { Suspense, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Elements } from '@stripe/react-stripe-js';
import { Observer, observer } from 'mobx-react';
import { isIE } from 'react-device-detect';
import { QueryClient, QueryClientProvider } from 'react-query';
import { generatePath, Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { HtmlHeaders } from 'App.helmet';
import { BirthdayModalProvider } from 'components/birthday-modal-provider/BirthdayModalProvider';
import { Boom } from 'components/boom';
import ExperimentalVerificationProvider from 'components/experimental-verification-provider';
import { IllumeRoute } from 'components/illume-route';
import { IENotSupported } from 'components/illume/ie-not-supported';
import { IllumeSnackbarProvider } from 'components/illume/illume-snackbar-provider';
import { SaveTheDate } from 'components/illume/save-the-date';
import { Loading } from 'components/Loading';
import { Router } from 'components/router';
import { SentryErrorFallback } from 'components/sentry-error-fallback';
import config from 'config';
import {
  INITIATOR_BASIC_INFO_URL,
  CONTRIBUTOR_URL,
  EDIT_NOTE_URL,
  EDIT_PROFILE_URL,
  INITIATOR_URL,
  LOGO_URL,
  MY_CARDS_URL,
  NOTIFICATIONS_URL,
  PROFILE_URL,
  RECEIVE_URL,
  SIGNUP_URL,
  LOGIN_URL,
  CARD_SEND_SUCCESS,
  SOCIAL_SHARE_URL,
  SAVE_THE_DATE_URL,
  INITIATOR_MARKETPLACE_URL,
  ADD_NOTE_URL,
  MERCHANT_VERIFICATION_URL,
  TEAMS_URL,
  SLACK_APP_PLANS,
  SLACK_TEAMS_CARDS_URL,
} from 'constants/strings';
import { AnalyticsProvider } from 'contexts/analytics/AnalyticsContext';
import { AuthenticationProvider } from 'contexts/authentication';
import { ClipboardProvider } from 'contexts/clipboard';
import { HttpClientProvider } from 'contexts/httpClient';
import { NotificationProvider } from 'contexts/notification';
import { PromptProvider } from 'contexts/prompt';
import { IServices, ServiceProvider } from 'contexts/services';
import { Store, StoreProvider, useStores } from 'contexts/store';
import { IStore } from 'contexts/store/IStore';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { usePageViewTrack } from 'hooks/use-page-view-tracks/usePageViewTracks';
import { AuthenticationService } from 'infra/authentication-service/AuthService';
import { BatchCardService } from 'infra/batch-card-service/BatchCardService';
import { IllumeAPICardService } from 'infra/card-service/cardService';
import illumeApiHttpClient from 'infra/client';
import { InternetExplorerClipboard } from 'infra/clipboard/InternetExplorerClipboard';
import { MerchantProductsService } from 'infra/merchant-product-service/MerchantProductsService';
import theme from 'infra/mui-stylesheet';
import { NoteService } from 'infra/note-service/NoteService';
import { NotificationService } from 'infra/notification-service/NotificationService';
import { OccasionService } from 'infra/occassion-service/OccasionService';
import { ProductLineService } from 'infra/product-line-service/ProductLineService';
import Sentry from 'infra/sentry/sentry';
import { ShopifyShippingService } from 'infra/shipping-service/ShopifyShippingService';
import { SuggestionService } from 'infra/suggestion-service/SuggestionService';
import { UserService } from 'infra/user-service/UserService';
import { VerificationService } from 'infra/verification-service/VerificationService';
import { Error404 } from 'pages/illume/404';
import { AddNoteRoutes } from 'pages/illume/add-note-routes';
import CardDetailsV2Received from 'pages/illume/card-details-v2/card-details-v2-received/CardDetailsV2Received';
import { CardSendSuccess } from 'pages/illume/card-details-v2/shared/CardSendSuccess';
import { ComponentDisplays } from 'pages/illume/component-displays/ComponentDisplays';
import { default as ContributorNotesRoutes } from 'pages/illume/contributor/ContributorNotesRoutes';
import { default as EditNote } from 'pages/illume/edit-note/EditNote';
import { default as EditProfile } from 'pages/illume/edit-profile/EditProfile';
import { ExperimentRoutes } from 'pages/illume/experiments';
import { default as LoginRoutes } from 'pages/illume/login/LoginRoutes';
import { default as LogoScreen } from 'pages/illume/logo-screen/LogoScreen';
import MerchantVerification from 'pages/illume/merchant-verification/MerchantVerification';
import MyCardsRoutes from 'pages/illume/my-cards-routes/MyCardsRoutes';
import { ComingSoon } from 'pages/illume/notifications/ComingSoon';
import { default as Notifications } from 'pages/illume/notifications/Notifications';
import { default as Profile } from 'pages/illume/profile/Profile';
import { default as ReceiveNotesRoutes } from 'pages/illume/recipient/ReceiveNotesRoutes';
import { default as SocialShares } from 'pages/illume/recipient/SocialShares';
import { default as SignUp } from 'pages/illume/sign-up/SignUp';
import { SlackAppPayment } from 'pages/illume/slack-app-payment/SlackAppPayment';
import { SlackCardsRoutes } from 'pages/illume/slack-cards-routes/SlackCardsRoutes';
import TeamsRoute from 'pages/illume/teams-module/TeamsRoute';
import { checkFF, FLAGS } from 'utils/featureFlag';
import logger from 'utils/logger';

const IllumeRoutes = observer(({ analytics }: { analytics: IAnalytics }) => {
  const { routerStore, authenticationStore, stripeStore, myCardsV2Store } = useStores();
  const authenticated = authenticationStore.authenticated;
  const notificationFeatureOff = !checkFF('NOTIFICATION');
  const history = useHistory();

  usePageViewTrack(analytics, routerStore.location);
  return (
    <Suspense fallback={null}>
      <Switch location={routerStore.location}>
        <Redirect exact from="/" to={authenticated ? MY_CARDS_URL : SIGNUP_URL} />

        {/* Contributor Flow */}
        <IllumeRoute component={ContributorNotesRoutes} path={CONTRIBUTOR_URL} />

        {/* Initiator Flow */}
        <IllumeRoute
          component={SignUp}
          exact
          path={SIGNUP_URL}
          isGuestOnly
          guestOnlyNext={INITIATOR_BASIC_INFO_URL}
        />
        <Redirect
          from={INITIATOR_URL}
          to={{
            pathname: MY_CARDS_URL,
            search: new URLSearchParams({
              from: INITIATOR_URL,
            }).toString(),
          }}
        />
        {/* <IllumeRoute component={InitiatorNotesRoutes} path={INITIATOR_URL} /> */}

        {/* Recipient Flow */}
        <IllumeRoute component={ReceiveNotesRoutes} path={RECEIVE_URL} />

        {/* Teams Flow */}
        {checkFF('TEAMS_BATCH_CREATION') && <IllumeRoute component={TeamsRoute} path={TEAMS_URL} />}

        {/* Profile */}
        <IllumeRoute isPrivate component={Profile} path={PROFILE_URL} />
        <IllumeRoute
          isPrivate
          component={notificationFeatureOff ? ComingSoon : Notifications}
          path={NOTIFICATIONS_URL}
        />
        <IllumeRoute isPrivate component={EditProfile} path={EDIT_PROFILE_URL} />

        <IllumeRoute exact path={`${MY_CARDS_URL}/received/:cardCode`}>
          <ExperimentalVerificationProvider>
            <CardDetailsV2Received />
          </ExperimentalVerificationProvider>
        </IllumeRoute>

        {/* My Cards */}
        <IllumeRoute path={MY_CARDS_URL} component={MyCardsRoutes} />

        <IllumeRoute component={SlackCardsRoutes} path={SLACK_TEAMS_CARDS_URL} />

        <IllumeRoute exact path={CARD_SEND_SUCCESS}>
          <CardSendSuccess />
        </IllumeRoute>

        {/* Marketplace */}
        <IllumeRoute
          exact
          component={() => <Redirect to={INITIATOR_MARKETPLACE_URL} />}
          path={'/marketplace'}
        />
        <IllumeRoute
          exact
          component={() => <Redirect to={INITIATOR_MARKETPLACE_URL} />}
          path={'/marketplace/gifts'}
        />

        {/* Misc. */}
        <IllumeRoute component={LogoScreen} path={LOGO_URL} />
        <IllumeRoute component={EditNote} path={`${EDIT_NOTE_URL}/:cardCode/:noteCode`} />
        <IllumeRoute
          component={LoginRoutes}
          path={LOGIN_URL}
          isGuestOnly
          guestOnlyNext={MY_CARDS_URL}
        />
        <IllumeRoute component={MerchantVerification} path={MERCHANT_VERIFICATION_URL} />
        {checkFF(FLAGS.SAVE_THE_DATE) && (
          <IllumeRoute isPrivate path={SAVE_THE_DATE_URL}>
            <ExperimentalVerificationProvider>
              <SaveTheDate />
            </ExperimentalVerificationProvider>
          </IllumeRoute>
        )}
        {checkFF(FLAGS.SOCIAL_SHARE) && (
          <IllumeRoute component={SocialShares} path={SOCIAL_SHARE_URL} />
        )}
        {checkFF(FLAGS.EXPERIMENTS) && (
          <IllumeRoute component={ExperimentRoutes} path="/experiments" />
        )}
        <IllumeRoute component={AddNoteRoutes} path={`${ADD_NOTE_URL}/:cardCode`} />
        <IllumeRoute
          isPrivate
          component={() => {
            return (
              <Observer>
                {() => {
                  return (
                    <Elements stripe={stripeStore.stripe}>
                      {stripeStore.stripe ? (
                        <SlackAppPayment
                          onPaymentSuccess={(workspaceUrl) => {
                            if (workspaceUrl) {
                              history.push(
                                generatePath(SLACK_TEAMS_CARDS_URL, {
                                  workspaceUrl,
                                }),
                              );
                            }
                          }}
                          stripeStore={stripeStore}
                        />
                      ) : (
                        'loading..'
                      )}
                    </Elements>
                  );
                }}
              </Observer>
            );
          }}
          path={SLACK_APP_PLANS}
        />

        <IllumeRoute component={Boom} path="/boom" />
        {(config.env === 'dev' || config.env === 'local') && (
          <Route component={ComponentDisplays} path="/components" />
        )}
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
});

export const VendorProvider: React.FC<{}> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst={false}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Sentry.ErrorBoundary fallback={(props) => <SentryErrorFallback {...props} />}>
            <IllumeSnackbarProvider>{children}</IllumeSnackbarProvider>
          </Sentry.ErrorBoundary>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </MuiThemeProvider>
  </QueryClientProvider>
);

export const queryClient = new QueryClient();

const App = ({ analytics }: { analytics: IAnalytics }) => {
  const [clipboard] = useState(() => new InternetExplorerClipboard());
  const [rootStore, setRootStore] = useState<IStore | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const client = illumeApiHttpClient;

  // services is stored here then injected to consumer via context provider
  // this way, the services is swappable and easier for testing
  const [services] = useState<IServices>({
    merchantGiftService: new MerchantProductsService(client),
    occasionService: new OccasionService(client),
    suggestionService: new SuggestionService(client),
    cardService: new IllumeAPICardService(client),
    shippingService: new ShopifyShippingService(client),
    noteService: new NoteService(client),
    verificationService: new VerificationService(client),
    authenticationService: new AuthenticationService(client),
    batchCardService: new BatchCardService(client),
    notificationService: new NotificationService(client),
    userService: new UserService(client),
    productLineService: new ProductLineService(client),
  });

  useEffect(() => {
    console.log(config.testMessage);
  }, []);

  // Create a client
  if (isIE) return <IENotSupported />;

  useEffect(() => {
    client
      .initialize()
      .then((initializedClient) => {
        const store = new Store(initializedClient, analytics, services);
        setRootStore(store);
      })
      .catch((e: unknown) => {
        logger.error('error occured during init: ', e);
        setError(e as Error);
      });
  }, [analytics, client, services]);

  if (error) {
    return (
      <SentryErrorFallback
        myCardsNavEnabled={false}
        error={error}
        componentStack={undefined}
        resetError={undefined}
      />
    );
  }

  if (!rootStore) {
    return (
      <MuiThemeProvider theme={theme}>
        <Loading />;
      </MuiThemeProvider>
    );
  }

  return (
    <>
      <HtmlHeaders />
      <StoreProvider store={rootStore}>
        <VendorProvider>
          <AuthenticationProvider>
            <HttpClientProvider client={client}>
              <ServiceProvider services={services}>
                <NotificationProvider>
                  <PromptProvider>
                    <BirthdayModalProvider
                      service={services.userService.setBirthday}
                      analytics={analytics}
                    >
                      <Router>
                        <AnalyticsProvider analytics={analytics}>
                          <ClipboardProvider clipboard={clipboard}>
                            <IllumeRoutes analytics={analytics} />
                          </ClipboardProvider>
                        </AnalyticsProvider>
                      </Router>
                    </BirthdayModalProvider>
                  </PromptProvider>
                </NotificationProvider>
              </ServiceProvider>
            </HttpClientProvider>
          </AuthenticationProvider>
        </VendorProvider>
      </StoreProvider>
    </>
  );
};
export default App;
