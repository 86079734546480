export function PencilIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4 1.3L13.2 3.1C13.6 3.5 13.6 4.1 13.2 4.5L5.7 12H2.5V8.8L10 1.3C10.4 0.9 11 0.9 11.4 1.3ZM12.5 3.8L10.7 2L9.2 3.5L11 5.3L12.5 3.8ZM3.5 9.2V11H5.3L10.3 6L8.5 4.2L3.5 9.2ZM1.5 14V13H15.5V14H1.5Z"
        fill="#F5F0E1"
      />
    </svg>
  );
}
