import { observer } from 'mobx-react';

import { useStores } from 'contexts/store';
import { INoteDisplayContentNote, NoteDisplayContent } from 'pages/illume/note-display/NoteDisplay';
import { noop } from 'utils';

interface NoteProps {
  data: {
    notes: INoteDisplayContentNote[];
  };
  printRef?: React.RefObject<HTMLDivElement>;
}

export const NotesToPrint = observer(({ data, printRef }: NoteProps) => {
  const { themepackStore } = useStores();

  const getPageMargins = (marginString: string) => {
    return `@page { margin: ${marginString} !important; }`;
  };

  return (
    <div
      ref={printRef}
      className="print-container"
      style={{
        margin: '0',
        padding: '0',
        // page breaking the notes
        pageBreakBefore: 'auto',
        pageBreakInside: 'avoid',
        pageBreakAfter: 'auto',
      }}
    >
      {data.notes.map((note, i) => {
        const bg = themepackStore.getPalette(note.palette).generateBackground();

        function getFontSize(message: string) {
          switch (true) {
            case message.length <= 70:
              return 36;
            case message.length <= 200:
              return 34;
            case message.length <= 300:
              return 32;
            case message.length <= 400:
              return 30;
            default:
              return 28;
          }
        }
        function getFontSizeForNoteWithImage(message: string) {
          switch (true) {
            case message.length <= 70:
              return 28;
            case message.length <= 200:
              return 26;
            case message.length <= 300:
              return 24;
            case message.length <= 400:
              return 22;
            default:
              return 20;
          }
        }

        const notez = note;

        const hasMedia =
          notez.gifs.length > 0 || notez.photos.length > 0 || notez.videos.length > 0;

        return (
          <>
            <style>{getPageMargins('0')}</style>
            <div
              style={{
                // height: '100vh',
                minHeight: '100vh',
                background: bg,
                // page breaking the notes
                pageBreakBefore: 'auto',
                pageBreakInside: 'avoid',
                pageBreakAfter: 'auto',
              }}
            >
              <NoteDisplayContent
                // messageFontSize={22}
                mediaSize={200}
                gifSize={200}
                key={i}
                marginTop={0}
                messageFontSize={
                  hasMedia ? getFontSizeForNoteWithImage(notez.message) : getFontSize(notez.message)
                }
                marginBottom={0}
                paddingTop={72 * 2}
                paddingBottom={0}
                textBoxMaxHeight={undefined}
                paddingLeft={48}
                paddingRight={48}
                notes={[notez]}
                recipientName={''}
                showPrivacyModal={false}
                onLastNoteClick={noop}
                showNavigationButtons={false}
                noteIndex={0}
                setNoteIndex={noop}
              />
            </div>
          </>
        );
      })}
    </div>
  );
});
