import React from 'react';

import { Helmet } from 'react-helmet';

const Seo = ({ noIndex = false, children }: { noIndex?: boolean; children?: React.ReactNode }) => {
  return (
    <Helmet>
      {noIndex && <meta name="robots" content="noindex" />}
      {children}
    </Helmet>
  );
};

export default Seo;
