const DownPolygonArrow = ({ className = '', color = '#282829', width = '14', height = '11' }) => {
  return (
    // probably better to wrap this in 24 24 viewbox like the others
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.97723 10.5C7.59233 11.1667 6.63008 11.1667 6.24518 10.5L1.04903 1.5C0.664127 0.833333 1.14525 0 1.91505 0L12.3074 0C13.0772 0 13.5583 0.833333 13.1734 1.5L7.97723 10.5Z"
        fill={color}
      />
    </svg>
  );
};

export default DownPolygonArrow;
