import { ButtonBase, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Close } from 'components/illume/icons';

const desktop = {
  small: 66,
  medium: 89,
  large: 89,
};

const mobile = {
  small: 51,
  large: 66,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '&:focus': { outline: 'none' },
    borderRadius: '100%',
    width: ({ size }) => (size ? mobile[size] : '100%'),
    maxWidth: 89,
    [theme.breakpoints.up('md')]: {
      width: ({ size }) => (size ? desktop[size] : '100%'),
    },
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'default'),
  },
  image: {
    width: '100%',
  },
}));

const CloseButton = ({
  size,
  clickable = true,
  blobColor,
  loading = false,
  plusColor,
  ...props
}) => {
  const classes = useStyles({ size: size, clickable: clickable });
  return (
    <ButtonBase className={classes.root} {...props}>
      {loading ? (
        <CircularProgress size={24} style={{ color: plusColor }} />
      ) : (
        <Close color={blobColor} blobSize={66} hasBlob />
      )}
    </ButtonBase>
  );
};

export default CloseButton;
