import { colors } from 'constants/design';

const Pause = ({ color = colors.contrastText, size = 17 }) => (
  <svg
    width={size}
    height={(size * 24) / 17}
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.81893 0H1.41564C0.633803 0 0 0.633803 0 1.41564V22.5844C0 23.3662 0.633803 24 1.41564 24H3.81893C4.60077 24 5.23457 23.3662 5.23457 22.5844V1.41564C5.23457 0.633803 4.60077 0 3.81893 0Z"
      fill={color}
    />
    <path
      d="M14.9132 0H12.5099C11.728 0 11.0942 0.633803 11.0942 1.41564V22.5844C11.0942 23.3662 11.728 24 12.5099 24H14.9132C15.695 24 16.3288 23.3662 16.3288 22.5844V1.41564C16.3288 0.633803 15.695 0 14.9132 0Z"
      fill={color}
    />
  </svg>
);

export default Pause;
