import { useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { GoogleLogin } from 'components/google-login';
import Button from 'components/illume/buttons/Button';
import { Link } from 'components/illume/link';
import { Modal } from 'components/illume/modal';
import LinedText from 'components/illume/text/LinedText';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { rem, spacing, colors } from 'constants/design';
import {
  TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
  MY_CARDS_URL,
  INITIATOR_BASIC_INFO_URL,
} from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useStores } from 'contexts/store';
import { checkFF, FLAGS } from 'utils/featureFlag';
import logger from 'utils/logger';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(6.5)}px`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: '0 auto',
    maxWidth: 500,
    marginTop: theme.spacing(18),
    [theme.breakpoints.up('md')]: {
      maxWidth: 700,
      marginTop: theme.spacing(15),
    },
  },
  policyText: {
    textAlign: 'center',
    paddingLeft: spacing[5],
    paddingRight: spacing[5],
    marginTop: theme.spacing(2),
  },
  welcomeText: {
    marginTop: theme.spacing(4),
  },
  googleButton: {
    fontSize: rem[1000],
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1125],
    },
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  dividerText: {
    [theme.breakpoints.up('md')]: {
      fontSize: rem[1125],
    },
    marginTop: theme.spacing(4),
    fontSize: '1.125rem',
  },
  continueButton: {
    marginTop: theme.spacing(4),
  },
}));

const SignUp = () => {
  const { authenticationStore } = useStores();
  const history = useHistory();
  const [httpErrMessage, setHttpErrMessage] = useState(undefined);
  const [googleErr, setGoogleErr] = useState({});
  const [errModal, setErrModal] = useState(false);
  const analytics = useAnalytics();

  const sendToNext = () => {
    history.push(MY_CARDS_URL);
  };

  const handleGoogleLoginSuccess = async ({ tokenId }) => {
    try {
      await authenticationStore.googleLogin(tokenId);
      sendToNext();
    } catch (error) {
      setHttpErrMessage(error.message);
      setErrModal(true);
    }
  };

  const GOOGLE_ERRORS = {
    IDPIFRAME_INITIALIZATION_FAILED: 'idpiframe_initialization_failed', // initialization of the Google Auth API failed (this will occur if a client doesn't have [third party cookies enabled](https://github.com/google/google-api-javascript-client/issues/260))
    POPUP_CLOSED_BY_USER: 'popup_closed_by_user', // The user closed the popup before finishing the sign in flow.
    ACCESS_DENIED: 'access_denied', // The user denied the permission to the scopes required
    IMMEDIATE_FAILED: 'immediate_failed', // No user could be automatically selected without prompting the consent flow.
  };

  const handleGoogleLoginFailure = ({ error, details }) => {
    logger.log({ error, details }, 'failure from google');
    // this one is user initiated - so not an error!
    // this can be deceiving as sometimes the user does do anything but the browser closes it on their behalf!
    if (error === GOOGLE_ERRORS.POPUP_CLOSED_BY_USER) return;

    setGoogleErr({ error, details });
    analytics.track('error - google login', { error, page: 'signup' });

    if (error === GOOGLE_ERRORS.IMMEDIATE_FAILED) {
      setErrModal(true);
      setHttpErrMessage(
        "sorry, sign in with google isn't working right now. This sometimes occurs when third party cookie setting is disabled",
      );
    }
  };

  const showGoogle =
    checkFF(FLAGS.GOOGLE_SIGN_IN) === true &&
    // hide component if third party cookie is disabled
    // alternative: a disabled state on the button, and show a tooltip to enable third party cookie
    googleErr.error !== GOOGLE_ERRORS.IDPIFRAME_INITIALIZATION_FAILED;

  const classes = useStyles();
  const theme = useTheme();
  const centered = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <IllumeLayout background={colors.primary} hideHamburger>
        <Grid className={classes.container} item>
          <Grid container direction="column" alignItems={centered ? 'center' : 'flex-start'}>
            <Grid item>
              <Text
                align="center"
                fontSize={{ mobile: rem[2250], desktop: rem[3000] }}
                fontWeight={900}
              >
                hello, sunshine.
              </Text>
            </Grid>
            <Grid item className={classes.welcomeText}>
              <Text align="center" fontSize={rem[1125]}>
                Welcome to illume.{' '}
                {showGoogle && <>To continue, sign in with Google or continue as guest.</>}
              </Text>
            </Grid>
          </Grid>
          {showGoogle && (
            <>
              <Grid className={classes.googleButton}>
                <GoogleLogin
                  onFailure={handleGoogleLoginFailure}
                  onSuccess={handleGoogleLoginSuccess}
                />
              </Grid>
              <Grid className={classes.dividerText}>
                <LinedText
                  textBackground={colors.primary}
                  color={colors.labelText}
                  fontWeight={500}
                  text="or"
                />
              </Grid>
            </>
          )}
          <Grid className={classes.continueButton}>
            <Button
              fullWidth
              customcolor="primaryShaded"
              onClick={() => history.push(INITIATOR_BASIC_INFO_URL)}
              disableFocusRipple
              disableRipple
              focusRipple={false}
            >
              <Text
                color={colors.contrastText}
                fontSize={{ desktop: rem[1125], mobile: rem[1000] }}
              >
                Continue as Guest
              </Text>
            </Button>
          </Grid>
          <Grid container className={classes.policyText} direction="row" justifyContent="center">
            <Text color={colors.gray40}>by continuing, I agree to illume’s &nbsp;</Text>
            <Link href={TERMS_OF_SERVICE_URL} underline>
              terms of service
            </Link>
            <Text color={colors.gray40}>&nbsp; and &nbsp;</Text>
            <Link href={PRIVACY_POLICY_URL} underline>
              privacy policy
            </Link>
            <Text color={colors.gray40}>.</Text>
          </Grid>
        </Grid>
      </IllumeLayout>

      <Modal
        show={errModal}
        title={'Oops!'.toUpperCase()}
        onClose={() => setErrModal(false)}
        promptConfig={{
          onOk: () => setErrModal(false),
          okText: 'close',
        }}
      >
        <Text align="center" fontWeight={300}>
          {httpErrMessage}
        </Text>
      </Modal>
    </>
  );
};

export default observer(SignUp);
