import { motion } from 'framer-motion';

import { colors } from 'constants/design';

import { menuBackgroundStyles, menuBlobSvg } from './MenuBlob.styles';
import { menuBlobVariants, menuBlobBackgroundVariants } from './MenuBlob.variants';

export const MenuBlob = ({ menuVisible }) => {
  return (
    <motion.div
      variants={{
        hidden: { overflow: 'hidden', transition: { delay: 0.7 } },
        visible: { overflow: 'visible' },
      }}
      style={menuBackgroundStyles}
    >
      <motion.svg
        initial={false}
        animate={menuVisible ? 'visible' : 'hidden'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="188"
        height="315"
        style={menuBlobSvg}
      >
        <motion.path variants={menuBlobBackgroundVariants} fill={colors.primaryDark} />
        <motion.path variants={menuBlobVariants} fill={colors.primary} />
      </motion.svg>
    </motion.div>
  );
};
