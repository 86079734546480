import { makeStyles } from '@material-ui/core/styles';

import { spacing, colors, rem } from 'constants/design';

const REPLY_FORM_MAX_WIDTH = 700;

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    marginTop: theme.spacing(8),
    width: '100%',
    maxWidth: REPLY_FORM_MAX_WIDTH,
    margin: '0 auto',
  },
  textBox: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
  },
  form: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    marginTop: theme.spacing(4),
  },
  copiedText: {
    backgroundColor: colors.primary,
    height: 40,
    borderRadius: 8,
    display: 'flex',
  },
  checkbox: {
    paddingLeft: 0,
    marginRight: -spacing[1],
  },
  navFooter: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(12),
    },
    marginTop: theme.spacing(28),
  },
  progressCircles: {
    marginTop: theme.spacing(2),
  },
  navText: {
    marginTop: theme.spacing(2),
  },
  checkboxText: {
    marginTop: theme.spacing(1),
  },
  footer: {
    flexGrow: 1,
    alignContent: 'flex-end',
    marginTop: ({ desktop }) => (desktop ? theme.spacing(4) : theme.spacing(2)),
  },
}));

export const inputStyles = makeStyles({
  root: {
    '&::placeholder': {
      color: colors.contrastText,
    },
    color: colors.contrastText,
    background: colors.primary,
    fontSize: rem[1500],
    outline: 'none',
    resize: 'none',
    fontWeight: 700,
  },
  name: {
    '&::placeholder': {
      color: colors.gray40,
    },
    color: colors.gray40,
    background: colors.primary,
    fontSize: rem[1500],
    outline: 'none',
    resize: 'none',
    fontWeight: 900,
  },
});

export const labelStyles = {
  color: colors.labelText,
};
