import { Route, Switch } from 'react-router-dom';

import { EXPERIMENTS_URL } from 'constants/strings';
import VideoRecordPage from 'pages/illume/experiments/video-record-test';

import SocialShares from '../recipient/SocialShares';
import MobileVideoRecord from './MobileVideoRecord';
import OpenInstagram from './OpenInstagram';
import Share from './Share';
import TOC from './TOC';
import UploadVideo from './UploadVideo';
import VideoRecordTest2 from './video-record-test-2';
import VideoPlayerExp from './VideoPlayerExp';

export const experiments = [
  {
    path: '/video',
    title: 'video recording',
    component: VideoRecordPage,
    status: 'disabled',
  },
  {
    path: '/upload-video',
    title: 'video upload',
    component: UploadVideo,
    status: 'disabled',
  },
  {
    path: '/web-share',
    title: 'web api share',
    component: Share,
  },
  {
    path: '/social-share',
    title: 'social sharing',
    component: SocialShares,
  },
  {
    path: '/open-instagram',
    title: 'open instagram',
    component: OpenInstagram,
  },
  {
    path: '/video-record-full',
    title: 'video record(desktop)',
    component: VideoRecordTest2,
  },
  {
    path: '/video-record-mobile',
    title: 'video record (mobile)',
    component: MobileVideoRecord,
  },
  {
    path: '/video-player',
    title: 'video player',
    component: VideoPlayerExp,
  },
];

const ExperimentRoutes = () => {
  return (
    <Switch>
      <Route exact path="/experiments" component={TOC} />
      {experiments.map(({ path, component }) => {
        return <Route exact path={EXPERIMENTS_URL + path} component={component} key={path} />;
      })}
    </Switch>
  );
};

export default ExperimentRoutes;
