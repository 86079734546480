import { GiftV2Dto } from '@illume/shared';

import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import Gift from 'domain/entities/gift/gift';
import { IllumeMoneyFactory } from 'utils/currency';

import { IGroupGiftModalGift } from './GroupGiftModal';

export class GroupGiftModalDTOMapper {
  static fromGiftV2Dto(dto: GiftV2Dto): IGroupGiftModalGift {
    const gift = Gift.fromDto(dto);
    return {
      financial: {
        current: gift.current,
        formattedPriceBreakdown: gift.formattedPriceBreakdown,
        giftMetGoals: gift.giftMetGoals,
        goal: gift.goal,
        goalRemaining: gift.goalRemaining,
        myContribution: gift.myContribution,
        progressRatio: gift.progressRatio,
        suggested: gift.suggested,
      },
      product: {
        description: gift.giftMerchantProduct.description,
        img: gift.giftMerchantProduct.variant.img,
        name: gift.giftMerchantProduct.name,
        highlights: gift.giftMerchantProduct.highlights,
        opinion: gift.giftMerchantProduct.opinion,
        vendor: gift.giftMerchantProduct.vendor,
      },
    };
  }
  static formGiftV2(gift: Gift): IGroupGiftModalGift {
    return {
      financial: {
        current: gift.current,
        formattedPriceBreakdown: gift.formattedPriceBreakdown,
        giftMetGoals: gift.giftMetGoals,
        goal: gift.goal,
        goalRemaining: gift.goalRemaining,
        myContribution: gift.myContribution,
        progressRatio: gift.progressRatio,
        suggested: gift.suggested,
      },
      product: {
        description: gift.giftMerchantProduct.description,
        img: gift.giftMerchantProduct.variant.img,
        name: gift.giftMerchantProduct.name,
        highlights: gift.giftMerchantProduct.highlights,
        opinion: gift.giftMerchantProduct.opinion,
        vendor: gift.giftMerchantProduct.vendor,
      },
    };
  }
  static fromExternalGift(externalGift: ExternalGift): IGroupGiftModalGift {
    return {
      financial: {
        current: externalGift.financial?.current,
        goal: externalGift.financial?.goal,
        giftMetGoals: externalGift.giftMetGoals,
        myContribution: externalGift.yourContribution || IllumeMoneyFactory({ amount: 0 }),
        progressRatio: parseFloat(
          (
            externalGift.financial.current.getAmount() / externalGift.financial.goal.getAmount()
          ).toFixed(2),
        ),
        suggested: externalGift.financial.suggestedAmount,
        get goalRemaining() {
          return this.goal.subtract(this.current);
        },
        get formattedPriceBreakdown() {
          return {
            'Product Total': externalGift.price.toFormat(),
            'Tax & Handling': externalGift.taxAndHandling.toFormat(),
            Shipping: externalGift.shipping.toFormat(),
            Total: this.goal.toFormat(),
          };
        },
      },
      product: {
        description: externalGift.description || '',
        img: externalGift.image,
        name: externalGift.title,
      },
    };
  }
}
