import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

export const useContributorStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    marginTop: theme.spacing(8),
    maxWidth: 900,
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  textBox: {
    marginBottom: theme.spacing(3),
    maxWidth: 650,
    alignSelf: 'center',
  },
  donation: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    columnGap: 48,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  thumbnail: {
    flex: 1,
    marginBottom: 10,
  },
  paymentFormContainer: {
    flex: 1,
  },
  skipGift: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  branding: {
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
}));

export const useStepperStyles = makeStyles(() => ({
  root: {
    // reset default padding
    paddingLeft: 0,
    paddingRight: 0,
    '& *': {
      borderColor: colors.gray20,
    },
  },
}));
