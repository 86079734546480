import { forwardRef } from 'react';

import { TextareaAutosize, TextareaAutosizeProps } from '@material-ui/core';
import cx from 'classnames';

import { Box } from 'components/box';
import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';

import { TextProps } from '../text/Text';
import { textAreaStyles } from './TextArea.styles';

interface TextAreaProps extends TextareaAutosizeProps {
  text?: TextAreaProps['value'];
  dark?: boolean;
  shadowColor?: typeof colors;
  shadow?: boolean;
  label?: React.ReactNode;
  error?: Omit<Error, 'name' | 'message'> & { message?: string };
  errorMessage?: string;
  inputId?: React.LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  labelSuffix?: string | React.ReactNode;
  labelTextProps?: TextProps;
}

export const TextArea = forwardRef<any, TextAreaProps>(
  (
    {
      dark,
      onChange,
      disabled,
      shadow = false,
      shadowColor = colors.alertRed,
      label,
      text,
      placeholder,
      error,
      errorMessage,
      className,
      inputId,
      labelSuffix,
      labelTextProps,
      ...props
    },
    ref,
  ) => {
    const classes = textAreaStyles({ dark, label, shadow, shadowColor });
    return (
      <>
        <Box mr={2}>
          <label htmlFor={inputId} className={classes.labelParrent}>
            <Text
              className={classes.mainLabel}
              fontWeight={600}
              color={colors.labelText}
              fontSize={{ mobile: rem[875], desktop: rem[1000] }}
              {...labelTextProps}
            >
              {label}
            </Text>

            {labelSuffix && (
              <Text
                color={colors.labelText}
                fontWeight={300}
                fontSize={{ mobile: rem[875], desktop: 11 }}
              >
                {labelSuffix}
              </Text>
            )}
          </label>
        </Box>
        <TextareaAutosize
          id={inputId}
          className={cx(classes.root, className)}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          disabled={disabled}
          value={text}
          {...props}
        />
        {error && (
          <Box data-testid="textarea-error" role="alert" mt={1}>
            <Text color={colors.alertRed}>
              {error.message || errorMessage || 'please provide a valid input'}
            </Text>
          </Box>
        )}
      </>
    );
  },
);
