import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Button } from 'components/illume/buttons';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { rem } from 'constants/design';
import { useConfettiCannon } from 'hooks';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      width: '100%',
      height: '100vh',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      overflow: 'hidden',
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    headingBox: {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
    },
    subHeading: {
      width: 265,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(5),
      width: 264,
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
    },
    wrapper: {
      margin: 'auto',
    },
  });
});

const SuccessfulCreate = ({ continueUrl }) => {
  const fireConfettiCannon = useConfettiCannon({ auto: false });
  const desktop = useIsDesktop();

  const classes = useStyles();

  React.useEffect(() => {
    fireConfettiCannon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IllumeLayout
      contentProps={{
        justifyContent: 'center',
      }}
    >
      <Grid className={classes.container}>
        <div className={classes.wrapper}>
          <Grid className={classes.headingBox}>
            <Text
              fontWeight={600}
              lineHeight={desktop ? '120%' : '48px'}
              maxWidth={desktop ? 800 : 300}
              fontSize={{ mobile: rem[2250], desktop: rem[3750] }}
            >
              woohoo! your cards have been created!
            </Text>
          </Grid>
          <Text className={classes.subHeading} fontWeight={300} fontSize={rem[1000]}>
            Continue to your my cards page to see the details for all your cards. We’ll also send
            you a list of contributor links via email.
          </Text>
          <Grid item className={classes.button}>
            <Link to={continueUrl}>
              <Button fullWidth>Continue to my cards</Button>
            </Link>
          </Grid>
        </div>
      </Grid>
    </IllumeLayout>
  );
};

export default SuccessfulCreate;
