import { useState } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import ResizeObserver from 'react-resize-observer';

import { Button } from 'components/illume/buttons';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';

const useGiftImageStyles = makeStyles<
  Theme,
  {
    overlayActive: boolean;
    squared?: { size: number };
    img?: string;
  }
>(() => {
  return {
    root: (props) => {
      return {
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        borderRadius: 8,
        position: 'relative',
        background: props.img
          ? `${colors.backgroundGray} url(${props.img}) center/cover no-repeat`
          : colors.background40,
        width: props.squared && props.squared.size,
        height: props.squared && props.squared.size,
      };
    },
    overlay: {
      position: 'absolute',
      display: ({ overlayActive }) => (overlayActive ? 'flex' : 'none'),
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: 20,
      height: '100%',
      borderRadius: 8,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'display 0.3s',

      backgroundColor: 'rgba(0, 0, 0, 0.35)',
      cursor: 'pointer',
    },
  };
});

interface Action<T = void> {
  content: React.ReactNode;
  onAction: (arg: T) => any;
}

interface IActionableGiftThumbnailProps {
  img: string;
  action: Action;
  squared?: {
    size: number;
  };
  imageMode?: 'cover' | 'contain';
  alt: string;
  overlayAlwaysActive?: boolean;
  className?: string;
}

const ActionableGiftThumbnail: React.FC<IActionableGiftThumbnailProps> = ({
  action,
  img,
  squared,
  imageMode = 'cover',
  alt,
  overlayAlwaysActive = true,
  className,
}) => {
  // overlay state
  const [overlayActive, setOverlayActive] = useState(false);
  const classes = useGiftImageStyles({
    overlayActive: overlayAlwaysActive || overlayActive,
    img: imageMode === 'cover' ? img : undefined,
    squared,
  });
  return (
    <Box
      className={classNames(classes.root, className)}
      // overlay toggle on hover
      onMouseEnter={() => setOverlayActive(true)}
      onMouseLeave={() => setOverlayActive(false)}
    >
      {imageMode === 'contain' && <img src={img} alt={alt} />}
      <div className={classes.overlay}>
        <Button customcolor="white" fullWidth onClick={() => action.onAction()}>
          <Text color={colors.primary}>{action.content}</Text>
        </Button>
      </div>
    </Box>
  );
};

export const ResponsiveSquaredActionableGiftThumbnail: React.FC<IActionableGiftThumbnailProps> = (
  props,
) => {
  const [width, setWidth] = useState(200);
  return (
    <div>
      <ActionableGiftThumbnail {...props} squared={{ size: width }} />
      <ResizeObserver onResize={({ width }) => setWidth(width)} />
    </div>
  );
};

export default ActionableGiftThumbnail;
