import config from 'config';
import baseConfig from 'config/base.config';

export const FLAGS: Record<string, BaseConfigKeys> = {
  GOOGLE_SIGN_IN: 'GOOGLE_SIGN_IN',
  SUBSCRIBE_TO_NOTES: 'SUBSCRIBE_TO_NOTES',
  EXPERIMENTS: 'EXPERIMENTS',
  VIDEO_UPLOAD: 'VIDEO_UPLOAD',
  SOCIAL_SHARE: 'SOCIAL_SHARE',
  INVITE_CONTRIBUTORS: 'INVITE_CONTRIBUTORS',
  GIPHY: 'GIPHY',
  PUBLIC_TOGGLE_NOTE: 'PUBLIC_TOGGLE_NOTE',
  MULTI_THREADED_VIDEO_CONVERSION: 'MULTI_THREADED_VIDEO_CONVERSION',
  SNAPCHAT_SHARE: 'SNAPCHAT_SHARE',
  NEW_CARD_THEMING: 'NEW_CARD_THEMING',
  GROUP_GIFT: 'GROUP_GIFT',
  APPLE_PAY: 'APPLE_PAY',
  SAVE_THE_DATE: 'SAVE_THE_DATE',
  TEAMS_BATCH_CREATION: 'TEAMS_BATCH_CREATION',
  ADD_GIFT_REQUIRES_LOGIN: 'ADD_GIFT_REQUIRES_LOGIN',
};

export const init = () => {
  window.FF = { ...config.FF };
};
export type BaseConfigKeys = keyof typeof baseConfig.FF;
export const checkFF = (flagName: BaseConfigKeys) => {
  let result;
  // allow window.FF (i.e. user) to override (but only if it's set!)
  if (window.FF && window.FF[flagName] !== undefined) {
    result = window.FF[flagName] ? true : false; // beware not the set it as "false" (string)!
  } else {
    // not set by the user, default to environment config:
    result = config.FF && config.FF[flagName] ? true : false;
  }

  return result;
};
