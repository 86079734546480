import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const useIsDesktop = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(
    theme.breakpoints.up('md'),
    /**
     * [useMediaQuery] always returns false at the first call #21142
     * https://github.com/mui-org/material-ui/issues/21142
     */
    { noSsr: true },
  );

  return isDesktop;
};
