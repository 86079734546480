// copied from:

import logger from 'utils/logger';

// https://github.com/ffmpegwasm/ffmpeg.wasm/blob/master/src/utils/parseProgress.js
let duration = 0;
let ratio = 0;

const ts2sec = (ts) => {
  const [h, m, s] = ts.split(':');
  return parseFloat(h) * 60 * 60 + parseFloat(m) * 60 + parseFloat(s);
};

const parseProgress = (message, progress) => {
  logger.log({ duration, ratio, message });
  if (typeof message === 'string') {
    if (message.startsWith('  Duration')) {
      const ts = message.split(', ')[0].split(': ')[1];
      const d = ts2sec(ts);
      progress({ duration: d, ratio });
      if (duration === 0 || duration > d) {
        duration = d;
      }
    } else if (message.startsWith('frame') || message.startsWith('size')) {
      const ts = message.split('time=')[1].split(' ')[0];
      const t = ts2sec(ts);
      // sometimes `time=` results in negative value, just fallback to 0
      ratio = t < 0 ? 0 : t / duration;
      progress({ ratio, time: t });
    } else if (message.startsWith('video:')) {
      progress({ ratio: 1 });
      duration = 0;
    }
  }
};

export default parseProgress;
