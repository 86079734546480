import { createContext } from 'react';

import { IClipboard } from 'domain/interfaces/IClipboard';
import { InternetExplorerClipboard } from 'infra/clipboard/InternetExplorerClipboard';

// Create the context object
export const ClipboardContext = createContext<IClipboard>(new InternetExplorerClipboard());

// Create a context provider component
export const ClipboardProvider: React.FC<{ clipboard: IClipboard }> = ({ clipboard, children }) => {
  return <ClipboardContext.Provider value={clipboard}>{children}</ClipboardContext.Provider>;
};
