import { Snackbar, SnackbarProps } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';

interface IllumeSnackbarProps extends SnackbarProps {
  severity: AlertProps['severity'];
  onClose?: AlertProps['onClose'];
  message: string;
  autoHideDuration?: number;
}

const IllumeSnackbar: React.FC<IllumeSnackbarProps> = ({
  onClose,
  severity,
  message,
  autoHideDuration = 3000,
  action,
  ...props
}) => {
  return (
    <Snackbar autoHideDuration={autoHideDuration} onClose={onClose} {...props}>
      <Alert onClose={onClose} severity={severity} action={action}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default IllumeSnackbar;
