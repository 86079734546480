import { CSSProperties, forwardRef, useCallback, useEffect } from 'react';

// ensure the modules is not lazy loaded
import { FileUpload, LocaleTranslations, Validator, Widget } from '@uploadcare/react-widget';

import config from 'config';
import { noop } from 'utils/index';
import logger from 'utils/logger';

const MAX_FILE_SIZE_MEGA_BYTES = 15;
const SUPPORTED_FILE_TYPES = ['png', 'jpg', 'jpeg'];
const DEFAULT_BUTTON_TEXT = 'choose a photo';
const DEFAULT_UPLOAD_MESSAGE = 'drag and drop a photo';
const FILE_TYPE_ERROR = 'fileType';
const fILE_MAXIMUM_SIZE_ERROR = 'fileMaximumSize';

const { uploadCareId } = config;

const maxFileSize = (mb: number): Validator => {
  const size = mb * 1024 * 1024;
  return function (fileInfo) {
    if (fileInfo.size !== null && fileInfo.size > size) {
      throw new Error(fILE_MAXIMUM_SIZE_ERROR);
    }
  };
};

function arrayToSentence(array: string[]): string {
  var last = array.pop();
  return array.join(', ') + ' or ' + last;
}
const fileTypeLimit = (types: string[]): Validator => {
  return function (fileInfo) {
    if (fileInfo.name === null) {
      return;
    }
    const extension = fileInfo.name.split('.').pop();

    if (extension && !types.includes(extension)) {
      throw new Error(FILE_TYPE_ERROR);
    }
  };
};

// this seems hacky
// but this is actually in the docs :)
// see: https://uploadcare.com/docs/uploads/validation/
const errors: LocaleTranslations = {
  errors: {
    [FILE_TYPE_ERROR]: 'Format is not supported',
  },
  dialog: {
    tabs: {
      preview: {
        error: {
          [FILE_TYPE_ERROR]: {
            title: 'Oops! Filetype error',
            text: `Currently we only support files in ${arrayToSentence(SUPPORTED_FILE_TYPES)}`,
            back: 'Retry with other file',
          },
          [fILE_MAXIMUM_SIZE_ERROR]: {
            title: 'Oops! Exceeding allowable file size limit',
            text: `Please upload a file no more than ${MAX_FILE_SIZE_MEGA_BYTES} MB`,
            back: 'Retry with smaller file',
          },
        },
      },
    },
  },
};

// TODO: Confirm appropriate file size limit. Currently set at 15 MB file limit.
// This is the largest file size I found of some current 64MP cameras.
const validators = [maxFileSize(MAX_FILE_SIZE_MEGA_BYTES), fileTypeLimit(['png', 'jpg', 'jpeg'])];
interface PhotoUploaderProps {
  buttonText?: string;
  text?: string;
  beforeFileSelect?: () => any;
  onFileSelect?: ((fileInfo: FileUpload) => void) | undefined;
  onError?: (e: Error) => any;
  className?: string;
  style?: CSSProperties | undefined;
}
const PhotoUploader = forwardRef<any, PhotoUploaderProps>(
  (
    {
      buttonText = DEFAULT_BUTTON_TEXT,
      text = DEFAULT_UPLOAD_MESSAGE,
      beforeFileSelect = noop,
      onFileSelect = noop,
      onError = noop,
      className = '',
      style,
    },
    widgetRef,
  ) => {
    // I think it's a nice thing to be able to configure the button text color
    const changeButtonText = useCallback(() => {
      const button = document.querySelector('.uploadcare--tab__action-button');
      const desktopUploadText = document.querySelector(
        '.uploadcare--draganddrop__supported',
      )?.firstElementChild;
      if (button) button.textContent = buttonText;
      if (desktopUploadText) desktopUploadText.textContent = text;
    }, [buttonText, text]);

    useEffect(() => {
      changeButtonText();
    }, [changeButtonText, text, buttonText]);

    const handleFileSelect = (e) => {
      beforeFileSelect();
      e.done(onFileSelect);
      e.catch((err: Error) => {
        onError(err);
        logger.log(err);
      });
    };

    return (
      <span className={className} style={style}>
        <Widget
          localeTranslations={errors}
          clearable
          imagesOnly
          crop="1:1"
          onDialogOpen={changeButtonText}
          onFileSelect={handleFileSelect}
          publicKey={uploadCareId}
          ref={widgetRef}
          validators={validators}
        />
      </span>
    );
  },
);

export default PhotoUploader;
