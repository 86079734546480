import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'constants/design';

export const useStyles = makeStyles(() => ({
  videoContainer: {
    width: (props) => props.width || 864, // 720? 1080?
    minHeight: 480,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > video': {
      transform: 'rotateY(180deg)',
    },
  },
  bottomMenu: {
    position: 'absolute',
    bottom: 0,
    height: 90,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  timerContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  timer: {
    padding: '2px 6px',
    marginTop: 25,
    borderRadius: 4,
    background: colors.contrastText,
  },
}));
