import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { INotificationService } from 'domain/interfaces/INotificationService';
import { NotificationList } from 'types';
import { CustomError } from 'utils';

export class NotificationService implements INotificationService {
  constructor(private httpClient: IHttpClient) {}
  getNotifications = async ({ offset, count }: { offset: number; count: number }) => {
    const requestObj = {
      url: '/notification',
      method: 'get',
      params: { offset, count },
    };

    const result = await this.httpClient.makeRequest<{
      notifications: NotificationList;
      total: number;
    }>(requestObj);
    if (result.success) {
      return { notifications: result.notifications, total: result.total };
    } else {
      throw CustomError.fromServiceErrorDto(result);
    }
  };

  markAsRead = async (id: string | number) => {
    const requestObj = {
      url: `/notification/${id}`,
      method: 'post',
    };

    const result = await this.httpClient.makeRequest<{}>(requestObj);
    if (result.success) {
      return;
    } else {
      throw CustomError.fromServiceErrorDto(result);
    }
  };

  getUnreadCount = async () => {
    const requestObj = {
      url: '/notification/unreadCount',
      method: 'get',
    };
    const result = await this.httpClient.makeRequest<{ unread: number }>(requestObj);
    if (result.success) {
      return result.unread;
    } else {
      throw CustomError.fromServiceErrorDto(result);
    }
  };
}
