import { IllumeLayout } from 'components/layouts';
import VideoRecorder from 'components/video-recorder';

const VideoRecordTest2 = () => {
  return (
    <IllumeLayout
      contentProps={{ justifyContent: 'center', alignItems: 'center', style: { flex: 1 } }}
    >
      <VideoRecorder />
    </IllumeLayout>
  );
};

export default VideoRecordTest2;
