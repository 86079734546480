export default function getSubArrayCyclic<T>(arr: T[], startIndex: number, length: number) {
  if (length > arr.length) {
    throw new Error('length is outside bound');
  }

  let result = [];
  for (let index = startIndex; index < length + startIndex; index++) {
    const refIndex = index % arr.length;
    result.push(arr[refIndex]);
  }
  return result;
}
