import { useEffect, useState } from 'react';

import { Box, Grid, CircularProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { isSafari } from 'react-device-detect';

import { GifDisplay } from 'components/illume/gif-display';
import { LeftArrowButton, RightArrowButton } from 'components/illume/nav-arrows';
import { NewThemeSelector } from 'components/illume/new-theme-selector';
import PhotoDisplay from 'components/illume/photos/PhotoDisplay';
import ProgressCircle from 'components/illume/progress-circles/ProgressCircles';
import Text from 'components/illume/text/Text';
import ThemeSelector from 'components/illume/theme-selector/ThemeSelector';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { PreviewModal } from 'components/preview-modal';
import {
  colors,
  rem,
  generateContrastFromTheme,
  DEFAULT_PALETTE,
  PRIMARY_THEME,
} from 'constants/design';
import {
  DEFAULT_THEMEPACK,
  generateContrastFromPalette,
  oldToClassicThemepackMapping,
} from 'constants/design/cardTheme';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import useClipPathID from 'hooks/useClipPathID';
import { IllumeGifDTO, PaletteDTO, PhotoDTO, VideoDTO } from 'types';
import { checkFF, FLAGS } from 'utils/featureFlag';

import { useStyles } from './SelectTheme.styles';

export interface SelectThemeFormValues {
  palette: PaletteDTO;
  theme: string;
}
interface SelectThemeFormProps {
  onPaletteChange: (paletteDTO: PaletteDTO) => any;
  message: string;
  signature?: string;
  onBack: () => any;
  onSubmit: (payload: SelectThemeFormValues) => any;
  submitting: boolean;
  palettes: PaletteDTO[];
  photos?: PhotoDTO[];
  videos?: VideoDTO[];
  gifs?: IllumeGifDTO[];
  setShowPreviewModal: (val: boolean) => any;
  showPreviewModal: boolean;
  value: PaletteDTO;
  recipientName?: string;
}

export const SelectThemeForm: React.FC<SelectThemeFormProps> = ({
  message,
  signature = 'Anonymous',
  onBack,
  onSubmit,
  submitting,
  palettes,
  photos = [],
  videos = [],
  gifs = [],
  setShowPreviewModal,
  showPreviewModal,
  onPaletteChange,
  value: palette,
  // card info
  recipientName,
}) => {
  const [theme, setTheme] = useState<keyof typeof oldToClassicThemepackMapping>(PRIMARY_THEME);
  const classes = useStyles();
  const muiTheme = useTheme();
  // we can move `centered` as props if necessary
  const centered = useMediaQuery(muiTheme.breakpoints.up('md'));
  const desktop = useIsDesktop();
  const safariVideoPreviewSize = 300;
  const videoPreviewSize = desktop ? 348 : 244;
  const [photo, video, gif] = [photos[0], videos[0], gifs[0]];
  const isMediaExists = photo || video || gif;
  const photoClipPathID = useClipPathID();
  const videoClipPathId = useClipPathID();

  const contrastColor = checkFF(FLAGS.NEW_CARD_THEMING)
    ? generateContrastFromPalette(palette, theme)
    : generateContrastFromTheme(theme);
  // ensure you aren't looking at the middle of a long note!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if there's no palette on a note, fallback to theme
  const paletteValue = palette || oldToClassicThemepackMapping[theme] || DEFAULT_PALETTE;
  const palettesValue = palettes || DEFAULT_THEMEPACK.palettes;

  return (
    <Grid container direction="column" className={classes.selectTheme}>
      <Grid container item alignItems={centered ? 'center' : 'flex-start'} direction="column">
        <Grid item>
          <Text
            fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
            fontWeight="bold"
            color={colors.contrastText}
          >
            SELECT A THEME
          </Text>
        </Grid>
        <Grid item className={classes.themeSelector}>
          {checkFF(FLAGS.NEW_CARD_THEMING) ? (
            <NewThemeSelector
              gap={20}
              value={paletteValue}
              palettes={palettesValue}
              onChange={onPaletteChange}
            />
          ) : (
            <ThemeSelector theme={theme} setTheme={setTheme} />
          )}
        </Grid>
      </Grid>

      {isMediaExists && (
        <Grid item>
          <Box className="fs-mask" mt={5} mb={-5}>
            <Grid container alignItems="center" direction="column">
              <Grid item>
                {photo ? (
                  <PhotoDisplay
                    imgUrl={photo.url || photo.deliveryUrl}
                    size={225}
                    clipPathID={photoClipPathID}
                  />
                ) : video ? (
                  <VideoDisplay
                    vidUrl={video?.url || video?.deliveryUrl}
                    size={isSafari ? safariVideoPreviewSize : videoPreviewSize}
                    clipPathId={videoClipPathId}
                  />
                ) : gif ? (
                  <GifDisplay giphyCode={gif.giphyCode} size={300} />
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
      <Grid item className={classes.note}>
        <Text
          className="fs-mask"
          fontSize={{ mobile: rem[1750], desktop: rem[2250] }}
          fontWeight="bold"
          color={contrastColor}
          overflowWrap="break-word"
          hyphens="auto"
        >
          {message?.split('\n').map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}
        </Text>
      </Grid>
      <Grid item className={classes.name}>
        <Text
          fontSize={{ mobile: rem[1750], desktop: rem[2250] }}
          fontWeight="bold"
          color={colors.darkText}
          overflowWrap="break-word"
          hyphens="auto"
        >
          {signature}
        </Text>
      </Grid>
      <Grid container className={classes.footer}>
        <Grid container justifyContent="center" direction="row">
          {submitting ? (
            <CircularProgress classes={{ colorPrimary: classes.loading }} />
          ) : (
            <>
              <Grid item className={classes.arrowLeft} onClick={onBack}>
                <LeftArrowButton inverted color={colors.contrastText} />
              </Grid>
              <Grid
                item
                className={classes.arrowRight}
                onClick={() => onSubmit({ palette, theme })}
                data-testid="select-theme-submit"
              >
                <RightArrowButton />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item className={classes.progressCircle}>
          <ProgressCircle step={1} />
        </Grid>
        <Grid container direction="column" className={classes.footerText}>
          <Grid container justifyContent="center">
            <Text italic fontSize={rem[750]} color={colors.labelText}>
              submit your note!
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <PreviewModal
        showNavigationButtons={false}
        showPrivacyModal={false}
        notes={PreviewModal.notesFromDto([
          {
            message,
            signature,
            theme,
            palette: paletteValue.paletteCode,
            photos,
            videos,
            gifs,
            // props below does not matter
            // just to make typescript happy
            length: message.length,
            isPublic: false,
            redacted: false,
            themepack: DEFAULT_THEMEPACK.name,
            viewCode: '123xyzdoesntmatter',
          },
        ])}
        open={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        name={recipientName}
        loading={undefined}
        error={undefined}
      />
    </Grid>
  );
};
