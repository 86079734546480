import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Maybe, RecipientDTO } from '@illume/shared';
import { useTour } from '@reactour/tour';
import classNames from 'classnames';
import { addDays } from 'date-fns';
import { action, autorun, makeAutoObservable, runInAction, when } from 'mobx';
import { observer } from 'mobx-react';
import { useAsyncCallback } from 'react-async-hook';
import { match, P } from 'ts-pattern';

import { BaseIconButton, Button } from 'components/illume/buttons';
import { Dropdown } from 'components/illume/dropdown';
import { Eye, Info, Pencil, TwoPeople } from 'components/illume/icons';
import Arrow from 'components/illume/icons/Arrow';
import { MailIcon } from 'components/illume/icons/Mail';
import { Link } from 'components/illume/link';
import { Text } from 'components/illume/text';
import { TextArea } from 'components/illume/text-area';
import { DatePicker, TextInput } from 'components/illume/text-inputs';
import CopyLink from 'components/illume/text-inputs/CopyLink';
import IllumeTimePicker from 'components/illume/text-inputs/TimePicker';
import PhoneAndEmailInvites from 'components/mulitple-contacts-sender/MultipleContactsSender';
import { colors, dateFormats } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useStores } from 'contexts/store';
import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { InitiatorCard } from 'domain/entities/initiator-card/InitiatorCard';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { UpdateCardPayloadOpts } from 'infra/card-service/cardService';
import { MerchantProduct } from 'pages/illume/marketplace/store/store';
import { noop, wait } from 'utils';
import getSubArrayCyclic from 'utils/getSubArrayCyclic';
import { spellNames } from 'utils/string';

import { useInitiatorCardDetailsV2PendingStyles } from '../CardDetailsV2.initiator.styles';
import { Carousel } from '../components/Carousel';
import { AutomaticDeliverySwitchValue } from '../components/SetAReminderVsAutomaticDeliverySwitch';
import {
  automaticDeliverySchema,
  AutomaticDeliverySchema,
  schemaRecipient,
  setAReminderSchema,
  SetReminderSchema,
} from '../constants/schemas';
import {
  useGiftSectionStyles,
  useReadyToSendSectionStyles,
  useRecipientInfoSectionStyles,
  userShareLinkSectionStyles,
  useSectionStyles,
} from './sections.styles';

interface SectionProps {
  children: React.ReactNode;
}
type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

const SectionContext = createContext(null);

export const Section = ({ children }: SectionProps) => {
  const classes = useInitiatorCardDetailsV2PendingStyles();
  return (
    <SectionContext.Provider value={null}>
      <div className={classes.content}>{children}</div>
    </SectionContext.Provider>
  );
};

export interface RecipientInfoProps {
  card: InitiatorCard;
  onPencilClick?: () => any;
  cardUpdateService: (val: RecipientInfoStore['dto']) => Promise<any>;
  store: RecipientInfoStore;
  disableContactEditing: boolean;
}

export class RecipientInfoStore {
  formValues: Partial<UpdateCardPayloadOpts> = {};
  patchFormValues = (values: Partial<UpdateCardPayloadOpts>) => {
    // clear error on change
    for (const key in values) {
      if (key in this.errors) {
        this.errors[key] = null;
      }
    }

    this.formValues = { ...this.formValues, ...values };
  };
  constructor(public analytics: IAnalytics) {
    makeAutoObservable(this);
  }

  get isValid() {
    return schemaRecipient.isValidSync(this.formValues);
  }

  errors: Record<string, Maybe<Error>> = {};
  recipientData: MakeOptional<RecipientDTO, 'id'>[] = [];
  get dto(): UpdateCardPayloadOpts {
    let recipiendDataOverWire = this.formValues.recipientData;
    return match(this.formValues)
      .with({ recipientMessage: P.nullish }, () => {
        return {
          recipientData: recipiendDataOverWire,
          recipientMessage: this.formValues.recipientMessage,
        };
      })
      .otherwise(() => ({
        recipientData: recipiendDataOverWire,
        recipientMessage: this.formValues.recipientMessage,
      }));
  }
}

Section.RecipientInfo = observer(
  ({
    card,
    cardUpdateService,
    onPencilClick = noop,
    store,
    disableContactEditing,
  }: RecipientInfoProps) => {
    const ctx = useContext(SectionContext);
    const classes = useRecipientInfoSectionStyles();
    const classes2 = useSectionStyles();
    const labelProps = { fontSize: 14, color: colors.gray40, fontWeight: 500 };
    const updateAsync = useAsyncCallback(cardUpdateService);
    const [message, setMessage] = useState(card.recipientMessage || undefined);

    useEffect(() => {
      const dispose = autorun(() => {
        const defaultValues = {
          recipientData: card.recipientData,
          recipientMessage: card.recipientMessage || undefined,
        };
        runInAction(() => {
          store.formValues = defaultValues;
        });
      });
      return () => dispose();
    }, [card, store]);

    function handleSave() {
      return updateAsync.execute(store.dto);
    }

    if (ctx) {
      throw new Error('content cannot be used outside of Section wrapper ');
    }
    return (
      <div
        className={classNames(classes2.container, 'section', 'customIllumeScrollbar-gray')}
        data-tut="section_recipient_info"
      >
        <div className={classes.title}>
          <Text fontSize={22} fontWeight={700} lineHeight={'24px'}>
            To: {spellNames(card.recipientData)}
          </Text>
          {disableContactEditing ? null : (
            <BaseIconButton color={colors.darkText} icon={Pencil} onClick={onPencilClick} />
          )}
        </div>
        <div className={classes.formContainer}>
          {store.formValues.recipientData?.map((r, i) => {
            return (
              <div key={r.id || i}>
                <TextInput
                  // TODO: handle err
                  // error={store.errors.contact}
                  // TOFO: alternative way to show that card deadline has been passed
                  // shadow={card.timeLimit.type === 'deadline' ? isPast(card.timeLimit.value) : false}
                  compact
                  disabled={disableContactEditing}
                  labelTextProps={labelProps}
                  onChange={action((e) => {
                    let result = e.target.value;
                    // check if starts with + or number
                    if (e.target.value.startsWith('+') || /^\d+$/.test(e.target.value)) {
                      // strip all non digits
                      result = e.target.value.replace(/\D/g, '');
                    }

                    r.contact = result;
                  })}
                  value={r.contact}
                  label={`${r.name}'s Email or Phone Number`}
                  placeholder={`e.g ${
                    r.name?.split(' ')[0] // first name
                  }@illumenotes.com`.toLowerCase()}
                />
              </div>
            );
          })}
          <div>
            <TextArea
              //@ts-ignore
              className={classNames(classes.textIntro, 'fs-mask')}
              labelTextProps={labelProps}
              name="message"
              inputId="intro-message"
              data-testid="intro-message"
              label="Intro Message to Recipient"
              placeholder="Type your message here"
              labelSuffix="*optional"
              value={message}
              onChange={(e: any) => {
                setMessage(e.target.value);
                store.patchFormValues({ recipientMessage: e.target.value });
              }}
            />
          </div>
          <Button
            style={{ height: 40, marginBottom: 12 }}
            disabled={!store.isValid}
            fullWidth
            onClick={handleSave}
          >
            {match(updateAsync.status)
              .with('loading', () => 'saving changes..')
              .otherwise(() => 'Save')}
          </Button>
        </div>
      </div>
    );
  },
);

interface ShareLinkSectionProps {
  card: InitiatorCard;
  onPencilClick?: () => any;
  onShowList?: () => any;
  onAddOrEdit?: () => any;
  sendinvitationService: (recipients: any[]) => Promise<any>;
}

Section.ShareLink = ({
  card,
  onPencilClick = noop,
  onShowList,
  sendinvitationService = (r) => wait(2000),
  onAddOrEdit,
}: ShareLinkSectionProps) => {
  const ctx = useContext(SectionContext);
  const labelProps = { fontSize: 14, color: colors.gray40, fontWeight: 500 };
  if (ctx) {
    throw new Error('content cannot be used outside of Section wrapper ');
  }
  const classes = userShareLinkSectionStyles();
  const query = useAsyncCallback(sendinvitationService);
  const classes2 = useSectionStyles();
  const analytics = useAnalytics();
  return (
    <div className={classNames(classes2.container, 'section')}>
      <div className={classes.title}>
        <Text fontSize={22} fontWeight={700} lineHeight={'24px'}>
          From: {card.initiatorName}
        </Text>
        <BaseIconButton color={colors.darkText} icon={Pencil} onClick={onPencilClick} />
      </div>
      <div className={classes.contributorsInfo}>
        <div style={{ display: 'flex', alignItems: 'center', color: colors.gray60 }}>
          <BaseIconButton
            icon={TwoPeople}
            iconProps={{ size: 30, color: colors.gray60 }}
            onClick={noop}
          />
          <Text fontSize={14}># of contributors: {card.contributorCount}</Text>
        </div>
        <Link
          onClick={onShowList}
          align="right"
          italic
          underline={true}
          fontSize={14}
          color={colors.gray40}
        >
          show list
        </Link>
      </div>
      <div className={classes.contributorsMessage}>
        <div style={{ display: 'flex', alignItems: 'center', color: colors.gray60, columnGap: 8 }}>
          <div style={{ marginLeft: 4 }}>
            <MailIcon color={colors.gray60} size={16} />
          </div>
          <Text fontSize={14}>contributor intro message</Text>
        </div>
        <Link
          onClick={onAddOrEdit}
          align="right"
          italic
          underline={true}
          fontSize={14}
          color={colors.gray40}
        >
          {card.contributorIntroMessage ? 'edit' : 'add'}
        </Link>
      </div>
      <div className={classes.share}>
        <PhoneAndEmailInvites
          label="Invite via email or SMS"
          placeholder="example@illumenotes.com"
          onSendInvitation={query.execute}
          error={query.error}
          loading={query.loading}
          sent={query.status === 'success'}
          reset={query.reset}
          labelTextProps={labelProps}
          compact
        />
        <CopyLink
          compact
          height={20}
          labelTextProps={labelProps}
          label={'Invite via shareable link'}
          style={{ fontSize: 14 }}
          background="main"
          link={card.contributerLink}
          onClick={() => {
            analytics.track(EVENT_NAMES.INVITE_CONTRIBUTORS_MY_CARDS.name, {
              noteCount: card.contributorCount,
            });
          }}
          spelledNames={spellNames(card.recipientData)}
        />
      </div>
    </div>
  );
};

export class SendCardStore {
  static values: AutomaticDeliverySwitchValue[] = ['None', 'Set a Reminder', 'Automatic Delivery'];
  automaticDeliverySwitchVal = SendCardStore.values[0];
  formValues: Partial<AutomaticDeliverySchema> | Partial<SetReminderSchema> = {};
  patchFormValues = (values: Partial<AutomaticDeliverySchema | SetReminderSchema>) => {
    // clear error on change
    for (const key in values) {
      if (key in this.errors) {
        this.errors[key] = null;
      }
    }

    this.formValues = { ...this.formValues, ...values };
  };
  constructor(public analytics: IAnalytics) {
    makeAutoObservable(this);
  }
  get schema() {
    return match(this.automaticDeliverySwitchVal)
      .with('Automatic Delivery', () => automaticDeliverySchema)
      .with('Set a Reminder', () => setAReminderSchema)
      .with('None', () => setAReminderSchema)
      .exhaustive();
  }

  get isValid() {
    return this.schema.isValidSync(this.formValues);
  }

  errors: Record<string, Maybe<Error>> = {};
  get dto(): UpdateCardPayloadOpts {
    return match(this.automaticDeliverySwitchVal)
      .with('Automatic Delivery', () => {
        const scheduledTimestamp = this.formValues.date && new Date(this.formValues.date);

        if ('time' in this.formValues && this.formValues.time && scheduledTimestamp) {
          scheduledTimestamp.setHours(this.formValues.time.getHours()); // we only care about the hours
          // we only care about the hours
          // BE wants to floor the minutes
          scheduledTimestamp.setMinutes(0);
          scheduledTimestamp.setSeconds(0);
        }
        return {
          scheduledTimestamp: scheduledTimestamp?.toISOString(),
        };
      })
      .with('Set a Reminder', () => ({
        deadlineTz: new IllumeDate(this.formValues.deadline).getTz(),
        deadline:
          this.formValues.deadline && new IllumeDate(this.formValues.deadline).toAPIString(),
      }))
      .with('None', () => ({}))
      .exhaustive();
  }

  handleChangeDeadline = (date: Maybe<Date>) => {
    this.analytics.track(EVENT_NAMES.CHANGE_DEADLINE_MY_CARDS.name);
    return date && this.patchFormValues({ deadline: date });
  };

  handleChangeDate = (date: Maybe<Date>) => {
    this.analytics.track(EVENT_NAMES.CHANGE_REMINDER_DATE_MY_CARDS.name);
    return date && this.patchFormValues({ date });
  };
}

interface SendCardSectionProps {
  action: {
    onAction: () => any;
    loading?: boolean;
    disabled?: boolean;
  };
  onPreviewCard: () => any;
  previewable?: boolean;
  store: SendCardStore;
  card: InitiatorCard;
  cardUpdateService: (val: SendCardStore['dto']) => Promise<any>;
  hasSavedEmail: boolean;
}

Section.SendCard = observer(
  ({
    action: stateAction,
    onPreviewCard = noop,
    previewable,
    store,
    card,
    cardUpdateService,
    hasSavedEmail,
  }: SendCardSectionProps) => {
    const ctx = useContext(SectionContext);
    if (ctx) {
      throw new Error('content cannot be used outside of Section wrapper ');
    }
    const classes = useReadyToSendSectionStyles();
    const classes2 = useSectionStyles();
    const updateAsync = useAsyncCallback(cardUpdateService);
    const { cache } = useStores();
    const { setIsOpen, setSteps } = useTour();

    useEffect(() => {
      const dispose = autorun(() => {
        const defaultValues = {
          deadline: card.timeLimit.type === 'deadline' ? card.timeLimit.value : undefined,
          date:
            card.timeLimit.type === 'scheduled_timestamp'
              ? card.timeLimit.value
              : addDays(card.createdAt, 2),
          time: card.timeLimit.type === 'scheduled_timestamp' ? card.timeLimit.value : undefined,
        };
        runInAction(() => {
          store.formValues = defaultValues;
          store.automaticDeliverySwitchVal =
            card.timeLimit.type === 'scheduled_timestamp' ? 'Automatic Delivery' : 'Set a Reminder';
        });
      });
      return () => dispose();
    }, [card, store]);

    function handleSave() {
      return updateAsync.execute(store.dto);
    }

    function getItems() {
      // currentStep > 0 means user already filled the contact
      if (card.recipientData[0]?.contact && hasSavedEmail) {
        return SendCardStore.values;
      }
      return SendCardStore.values.map((key) => ({
        name: key,
        value: key,
        style:
          key === 'Automatic Delivery'
            ? {
                opacity: '0.5',
              }
            : {},
      }));
    }

    return (
      <div className={classNames(classes2.container, 'section')} data-tut="section_send_card">
        <div className={classes.title}>
          <Text fontSize={22} fontWeight={700} lineHeight={'24px'}>
            Ready To Send?
          </Text>
        </div>
        <div className={classes.form}>
          <div>
            <Dropdown
              items={getItems()}
              background="main"
              onChange={action((v: any) => {
                when(() => v.target.value === 'Automatic Delivery')
                  .then(() => cache.get('has_view_reminder'))
                  .then((hasView) => (!card.recipientData[0]?.contact && !hasView ? true : false))
                  .then((show) => {
                    if (show) {
                      setSteps([
                        {
                          selector: '[data-tut="section_recipient_info"]',
                          content: (
                            <Text fontSize={20}>
                              Please be sure to input and save the email of phone number of the
                              recipient.
                            </Text>
                          ),
                          position: 'bottom',
                          highlightedSelectors: ['.date_picker_content', '#edit_recipient_content'],
                          mutationObservables: ['.date_picker_dialog', '#edit_recipient_modal'],
                        },
                      ]);
                      setIsOpen(show);
                      cache.set('has_view_reminder', { timestamp: new Date() });
                    }
                  });
                if (v.target.value === 'Automatic Delivery') {
                  if (card.recipientData[0]?.contact && hasSavedEmail) {
                    return (store.automaticDeliverySwitchVal = v.target.value);
                  }
                } else {
                  return (store.automaticDeliverySwitchVal = v.target.value);
                }
              })}
              value={store.automaticDeliverySwitchVal}
              label={'Select schedule'}
              labelSuffix={'*optional'}
              placeholder={'Select schedule'}
              name="occasionName"
              children={undefined}
              labelTextProps={undefined}
              shadow={undefined}
              shadowColor={undefined}
              radius={undefined}
              errorMessage={undefined}
              fontSize={undefined}
              icon={undefined}
              error={undefined}
            />
          </div>
          {store.automaticDeliverySwitchVal === 'Set a Reminder' && (
            <div className="date-time-container">
              <DatePicker
                placeholder={`e.g. February 26th, ${new Date().getFullYear()}`}
                minDateMessage={null}
                disablePast={true}
                icon={true}
                inputProps={{
                  'data-testid': 'deadline-picker',
                }}
                onChange={store.handleChangeDeadline}
                value={store.formValues.deadline}
                // compact styles
                compact
                format={dateFormats.US_COMPACT}
                iconProps={{ size: 18 }}
                KeyboardButtonProps={{ style: { padding: 8 } }}
                InputAdornmentProps={{ style: { margin: 0 } }}
              />
            </div>
          )}
          {store.automaticDeliverySwitchVal === 'Automatic Delivery' && (
            <div className="date-time-container">
              <DatePicker
                // shadow={store.formValues.date && isPast(store.formValues.date)}
                placeholder={`e.g. February 26th, ${new Date().getFullYear()}`}
                minDateMessage={null}
                disablePast={true}
                icon={true}
                onChange={store.handleChangeDate}
                inputProps={{
                  'data-testid': 'reminder-date-input',
                }}
                value={store.formValues.date}
                // compact styles
                compact
                format={dateFormats.US_COMPACT}
                iconProps={{ size: 18 }}
                KeyboardButtonProps={{ style: { padding: 8 } }}
                InputAdornmentProps={{ style: { margin: 0 } }}
              />
              <IllumeTimePicker
                views={['hours']}
                format={'hh a'}
                compact
                autoOk={false}
                inputProps={{ style: { fontSize: 14 } }}
                InputAdornmentProps={{ style: { margin: 0 } }}
                icon={true}
                name="time"
                error={undefined}
                onClose={noop}
                onChange={(date) => date && store.patchFormValues({ time: date })}
                value={'time' in store.formValues ? store.formValues.time : undefined}
              />
            </div>
          )}
          <div className={classes.buttonGroup}>
            <Button
              style={{ height: 40 }}
              customcolor={
                !store.isValid || store.automaticDeliverySwitchVal === 'None'
                  ? 'darkGray'
                  : undefined
              }
              fullWidth
              onClick={() =>
                !store.isValid || store.automaticDeliverySwitchVal === 'None' ? null : handleSave()
              }
              disabled={!store.isValid || store.automaticDeliverySwitchVal === 'None'}
            >
              <Text color={colors.contrastText} fontSize={{ desktop: '16px', mobile: '14px' }}>
                {match(updateAsync.status)
                  .with('loading', () => 'saving..')
                  .otherwise(() => 'Save')}
              </Text>
            </Button>
            <Text color={colors.gray40} fontSize={'14px'}>
              or
            </Text>
            <Button
              style={{ height: 40 }}
              // startIcon={<PaperPlane color={colors.contrastText} />}
              customcolor={'green'}
              fullWidth
              onClick={() => stateAction.onAction()}
              loading={stateAction.loading}
              disabled={stateAction.loading}
            >
              <Text color={colors.contrastText} fontSize={{ desktop: '16px', mobile: '14px' }}>
                Send now
              </Text>
            </Button>
          </div>
          <Button
            style={{ height: 40 }}
            startIcon={<Eye color={colors.gray60} />}
            customcolor={'grey'}
            disabled={previewable}
            fullWidth
            onClick={onPreviewCard}
          >
            <Text color={colors.gray60} fontSize={{ desktop: '16px', mobile: '14px' }}>
              preview card
            </Text>
          </Button>
        </div>
      </div>
    );
  },
);

Section.AddAGroupGift = observer(
  ({
    onExplore,
    onAddGift,
    products,
    onInfoClick,
  }: {
    onExplore: () => any;
    onInfoClick: () => any;
    onAddGift: (dto: MerchantProduct) => any;
    products: MerchantProduct[];
  }) => {
    const classes = useSectionStyles();
    const giftClasses = useGiftSectionStyles();
    const ctx = useContext(SectionContext);
    const [state, setState] = useState(0);

    if (ctx) {
      throw new Error('content cannot be used outside of Section wrapper ');
    }

    const merchantProducts = useMemo(
      () =>
        products ? (products.length > 3 ? getSubArrayCyclic(products, state, 3) : products) : null,
      [state, products],
    );

    return (
      <div className={classNames(classes.giftContainer, 'gift-container')}>
        <div className={giftClasses.info}>
          <div className={giftClasses.title}>
            <Text fontSize={{ mobile: 24, desktop: 28 }} fontWeight={700}>
              Add a group gift...
            </Text>
            <BaseIconButton
              onClick={onInfoClick}
              icon={Info}
              iconProps={{
                size: 14,
              }}
            />
          </div>
          <Text fontSize={16} color={colors.gray60} fontWeight={500}>
            Easily split the cost of a gift amongst your group and send it to your recipient, no
            address necessary.
          </Text>
          <Button
            style={{ height: 40 }}
            customcolor="grey"
            className={'button'}
            onClick={onExplore}
          >
            <Text
              color={colors.gray60}
              fontWeight={700}
              fontSize={{ desktop: '16px', mobile: '14px' }}
            >
              Explore all gifts
            </Text>
          </Button>
        </div>
        <Carousel
          products={merchantProducts}
          onRight={() => setState((prev) => prev + 1)}
          onLeft={() => setState((prev) => (prev - 1 > 0 ? prev - 1 : 0))}
          onAddGift={onAddGift}
          loading={false}
        />
      </div>
    );
  },
);

Section.Title = ({
  onLeftArrow,
  spelledNames,
}: {
  spelledNames: string;
  onLeftArrow: () => any;
}) => {
  const desktop = useIsDesktop();
  return (
    <div className={'title'}>
      <div style={{ position: 'absolute', left: 0 }}>
        <BaseIconButton
          icon={Arrow}
          iconProps={{ size: desktop ? 30 : 24 }}
          onClick={onLeftArrow}
        />
      </div>
      <Text align="center" fontWeight={900} fontSize={{ mobile: 22, desktop: 36 }}>
        {`${spelledNames.toUpperCase()}’S GROUP CARD`}
      </Text>
    </div>
  );
};
