import { CardStatus } from 'types';

export const STATUS_COPIES: Record<CardStatus, string> = {
  InProgress: 'in the works',
  ReadyToSend: 'ready to send',
  Delivered: 'unread',
  Read: 'read',
  Draft: 'draft',
};

export const ROLES = {
  INITIATOR: 'Initiator',
  CONTRIBUTOR: 'Contributor',
  RECIPIENT: 'Recipient',
} as const;

export const CONTRIBUTORS = {
  INVITED: 'Invited',
  CONTRIBUTED: 'Contributed',
};

export type TAB_STATUS = 'IN_THE_WORKS' | 'READY_TO_SEND';
export const [IN_THE_WORKS, READY_TO_SEND]: TAB_STATUS[] = ['IN_THE_WORKS', 'READY_TO_SEND'];
