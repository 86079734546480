import { Text } from 'components/illume/text';
import { colors, rem } from 'constants/design';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { ReadyToSendNotificationDTO } from 'types';
import { noop } from 'utils';
import distanceFormatter from 'utils/date/distanceFormatter';

import { Notification } from './Notification';
import { ConcreateNotification } from './types';

const ReadyToSend: React.FC<ConcreateNotification<ReadyToSendNotificationDTO>> = ({
  notification,
  rounded,
  style,
  markAsRead = noop,
}) => {
  const { timestamp, read, payload, id } = notification || {};
  const { recipientNames, cardCode } = payload || {};

  const history = useIllumeHistory();

  const handleClick = () => {
    const config = {
      pathname: `/my-cards/${cardCode}`,
    };
    history.push(config);
    markAsRead(id);
  };

  return (
    <Notification dotted={!read} rounded={rounded} style={style} onClick={handleClick}>
      <Text display="inline" fontSize={rem[875]} color={colors.gray40}>
        <b>{recipientNames}’s</b> card is <b>ready to send</b>! prepare to make their day.
      </Text>
      <Text fontSize={rem[875]} display="inline" color={colors.gray20}>
        {' '}
        {distanceFormatter(new Date(timestamp))}
      </Text>
    </Notification>
  );
};

export default ReadyToSend;
