import { FunctionComponent } from 'react';

import { ButtonBase } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';

import HideOnScroll from 'components/hide-on-scroll';
import { Button } from 'components/illume/buttons';
import { IllumeText } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';
import { getProdMarketingSiteUrl, INITIATOR_URL, LOGIN_URL } from 'constants/strings';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      gridArea: 'header',
      position: 'sticky',
      top: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '34px 36px 0',
      background: colors.transparent,
      zIndex: 1000,
      [theme.breakpoints.up('md')]: {
        padding: '24px 48px 0',
      },
    },
    buttonGroup: {
      display: 'flex',
      columnGap: 15,
      [theme.breakpoints.up('md')]: {
        marginBottom: 16,
      },
    },
    buttonContainer: {
      width: 160,
      [theme.breakpoints.up('md')]: {
        width: 214,
      },
    },
  });
});

interface LoginAndCreateCardAppBarProps {}

const LoginAndCreateCardAppBar: FunctionComponent<LoginAndCreateCardAppBarProps> = () => {
  const isMobile = !useIsDesktop();
  const classes = useStyles();
  return (
    <HideOnScroll>
      <div className={classes.root}>
        <div>
          <a href={getProdMarketingSiteUrl('')}>
            <IllumeText onClick={noop} backgroundColor={colors.primary} />
          </a>
        </div>
        <div className={classes.buttonGroup}>
          <div className={classes.buttonContainer}>
            <Button borderRadius={8} size="compact" fullWidth>
              <Link to={INITIATOR_URL}>
                <Text fontWeight={500} fontSize={isMobile ? 11 : 18}>
                  Create your group gift
                </Text>
              </Link>
            </Button>
          </div>
          <ButtonBase>
            <Link to={LOGIN_URL}>
              <Text fontSize={isMobile ? 11 : 18} fontWeight={400}>
                Login
              </Text>
            </Link>
          </ButtonBase>
        </div>
      </div>
    </HideOnScroll>
  );
};

export default LoginAndCreateCardAppBar;
