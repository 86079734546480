export function Boom() {
  const goBoom = () => {
    throw Error('Boom!');
  };

  return (
    <>
      {goBoom()}
      Didn't Boom :(
    </>
  );
}
