import React, { useEffect, useState } from 'react';

import { ButtonBase, Grid } from '@material-ui/core';
import { ObservablePromise } from '@thezano/mobx-observable-promise';
import cx from 'classnames';
import { makeAutoObservable, makeObservable, when, observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from 'components/illume/buttons';
import { CircleChecklist } from 'components/illume/icons/CircleChecklist';
import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { colors } from 'constants/design';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useStores } from 'contexts/store';
import OccasionStore, { Occasion } from 'contexts/store/occasion-store/Occasion.store';
import { UserProfileStore } from 'contexts/user';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { OccasionType } from 'domain/interfaces/IOccasionService';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { wait } from 'utils';
import logger from 'utils/logger';

import ContributorStore from '../../Contributor.store';
import { useStyles } from './CelebrateMoreB.style';

const mockReminderService = (occasions: string[]) =>
  wait(3000).then(() => {
    return logger.log('mock reminder', { payload: occasions });
  });

class CheckableOccasion extends Occasion {
  @observable checked: boolean = false;
  @action toggleCheck = () => {
    this.checked = !this.checked;
  };
  constructor(occasionType: OccasionType) {
    super(occasionType);
    makeObservable(this);
  }
}

class UI {
  get occassionList(): CheckableOccasion[] {
    return this._occasionStore?.ABTestOccasions?.map((o) => new CheckableOccasion(o.name)) || [];
  }
  remindTask = new ObservablePromise(this._reminderService);
  constructor(
    private _reminderService: (occassionList: OccasionType[]) => Promise<any>,
    private _occasionStore: OccasionStore,
    private _userProfileStore: UserProfileStore,
    private analytics: IAnalytics,
  ) {
    makeAutoObservable(this);
    when(
      () => this.remindTask.wasSuccessful,
      () => {
        analytics.track('remind task/reminders set', {
          contributorEmail: this._userProfileStore.userProfileDTO?.email,
          occasionsToRemind: this.occasionListDTO,
        });
      },
    );
  }

  get checkedElements() {
    return this.occassionList.filter((o) => o.checked);
  }

  get occasionListDTO() {
    return this.checkedElements.map((o) => o.name);
  }
}

const CelebrateMoreA: React.FunctionComponent<{ contributorStore: ContributorStore }> = ({
  contributorStore,
}) => {
  const classes = useStyles();
  const mobile = !useIsDesktop();
  const { occasionStore, userProfileStore } = useStores();
  const analytics = useAnalytics();
  const [store] = useState(
    () => new UI(mockReminderService, occasionStore, userProfileStore, analytics),
  );

  useEffect(() => {
    analytics.track('remind task/celebrate-more');
  }, [analytics]);

  return (
    <IllumeLayout
      contentProps={{
        justifyContent: 'center',
      }}
    >
      <Grid className={classes.container}>
        <Text
          className={classes.paragraphBox}
          fontWeight={500}
          lineHeight={'27px'}
          fontSize={{ desktop: 20, mobile: 16 }}
        >
          Did you know...
        </Text>
        <Text
          className={classes.headingText}
          fontWeight={800}
          fontSize={{ desktop: 64, mobile: 28 }}
        >
          98% of people <span style={{ color: colors.primary }}>like receiving cards</span> for
          special occasions
        </Text>
        <Text
          className={classes.paragraphBox}
          fontWeight={400}
          lineHeight={'27px'}
          fontSize={{ desktop: 20, mobile: 16 }}
        >
          What about your friends, family or colleagues?
        </Text>

        <div className={classes.parrentBox}>
          <Text
            className={classes.selectOccasion}
            fontWeight={700}
            lineHeight={'34px'}
            fontSize={{ desktop: 32, mobile: 24 }}
          >
            Set reminders for special celebration days
          </Text>

          <Text
            className={cx(classes.paragraphBox, classes.willRemindYou)}
            fontWeight={400}
            lineHeight={'27px'}
            fontSize={{ desktop: 18, mobile: 14 }}
          >
            We'll remind you 2 weeks in advanced to create your group card
          </Text>
          <div className={classes.gridBox}>
            {store.occassionList.map((element, index) => {
              const { icon: Icon, name: text, checked } = element;
              const isChecked = checked;
              const key = index;
              return (
                <ButtonBase
                  key={key}
                  onClick={element.toggleCheck}
                  className={cx(
                    classes.gridItem,
                    isChecked ? classes.activeCard : classes.notActiveCard,
                  )}
                >
                  <div className={classes.labelContainer}>
                    <div className={classes.iconContainer}>
                      <Icon size={30} />
                    </div>
                    <div>
                      <Text
                        lineHeight="20px"
                        fontWeight={400}
                        fontSize={{ desktop: 18, mobile: 14 }}
                      >
                        {text}
                      </Text>
                    </div>
                  </div>
                  {isChecked && (
                    <div className={classes.checklistContainer}>
                      <CircleChecklist />
                    </div>
                  )}
                </ButtonBase>
              );
            })}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            loading={store.remindTask.isExecuting}
            fullWidth={mobile}
            className={classes.sendReminderBtn}
            onClick={() =>
              store.remindTask
                .execute(store.occasionListDTO)
                .then(() => contributorStore.pushToNext())
            }
          >
            <Text
              color={colors.contrastText}
              fontSize={{ desktop: '16px', mobile: '14px' }}
              fontWeight={500}
            >
              Set reminders
            </Text>
          </Button>
        </div>
      </Grid>
    </IllumeLayout>
  );
};

export default observer(CelebrateMoreA);
