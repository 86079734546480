import { Badge, ButtonBase, ButtonBaseProps, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { RightChevron } from 'components/illume/icons/RightChevron';
import { Surface } from 'components/illume/surface';
import { colors } from 'constants/design';

import { ISurfaceProps } from '../surface/Surface';

const cardStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    '&:focus': { outline: 'none' },
    borderRadius: 10,
    width: '100%',
  },
}));

const badgeStyles = makeStyles(() => ({
  root: { width: '100%' },
  anchorOriginTopLeftRectangular: {
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
  },
  colorSecondary: {
    backgroundColor: colors.alertRed,
    top: '28px',
    left: '-16px',
  },
}));

export interface INotificationProps extends ButtonBaseProps {
  dotted: boolean;
  rounded: ISurfaceProps['rounded'];
}

export const Notification: React.FC<INotificationProps> = ({
  children,
  dotted,
  rounded,
  ...props
}) => {
  const cardClasses = cardStyles();
  const badgeClasses = badgeStyles();

  return (
    <Badge
      classes={badgeClasses}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      invisible={!dotted}
      color="secondary"
      badgeContent=" "
      variant="dot"
    >
      <ButtonBase role="notification" classes={cardClasses} {...props}>
        <Surface px={3} py={2} rounded={rounded} fullWidth>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              {children}
            </Grid>
            <Grid item container justifyContent="flex-end" xs={2}>
              <Grid item>
                <RightChevron size={18} color={colors.gray60} />
              </Grid>
            </Grid>
          </Grid>
        </Surface>
      </ButtonBase>
    </Badge>
  );
};
