export const menuBackgroundStyles = {
  position: 'absolute',
  top: 17,
  right: 16,
  width: 44,
  height: 44,
};

export const menuBlobSvg = {
  position: 'absolute',
  top: -23,
  right: -15,
  transformOrigin: 'top right',
  overflow: 'visible',
};
