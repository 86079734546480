import { yupResolver } from '@hookform/resolvers/yup';
import { RecipientDTO } from '@illume/shared';
import { Box, Grid } from '@material-ui/core';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useBirthdayModalContext } from 'components/birthday-modal-provider/BirthdayModalProvider';
import { Footer } from 'components/footer';
import { Checkbox } from 'components/illume/checkbox';
import { RightArrowButton } from 'components/illume/nav-arrows';
import ProgressCircle from 'components/illume/progress-circles/ProgressCircles';
import TextInput from 'components/illume/text-inputs/TextInput';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useStores } from 'contexts/store';
import { useConfettiCannon } from 'hooks';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { wait } from 'utils';
import { isValid } from 'utils/form';
import logger from 'utils/logger';
import { spellNames } from 'utils/string';

import ContributorStore from './Contributor.store';
import { useStyles } from './Reply.styles';

type ReplyFormValues = yup.InferType<typeof replyFormSchema>;

const replyFormSchema = yup.object().shape({
  email: yup.string().required(`please provide your email`).email('must be a valid email'),
  subscribing: yup.boolean().nullable(),
});

const ReplyContainer = ({
  recipientData,
  defaultValues,
  contributorStore,
}: {
  contributorStore: ContributorStore;
  recipientData?: RecipientDTO[];
  defaultValues?: Partial<ReplyFormValues>;
}) => {
  useConfettiCannon();
  const classes = useStyles();
  const spelledNames = recipientData && spellNames(recipientData);

  const {
    handleSubmit,
    register,
    errors,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(replyFormSchema),
    defaultValues,
  });

  const { enqueueWarningSnackbar } = useIllumeSnackbar();
  const { verificationStore, userProfileStore } = useStores();
  const { show: showBirthdayModal } = useBirthdayModalContext();
  const analytics = useAnalytics();

  const disableSubmitButton = !isValid(errors);

  const onSubmit = ({ email, subscribing }: ReplyFormValues) => {
    logger.warn('subscribing is not used: ', subscribing);
    analytics.track(EVENT_NAMES.SELECT_EMAIL_REPLY_CONTRIBUTOR.name);
    return verificationStore
      .beginVerification(email, undefined)
      .then(() => analytics.track(EVENT_NAMES.SUBMIT_NOTE_JOIN_CONTRIBUTOR.name))
      .then(() => contributorStore.pushToNext())
      .then(async () => {
        await when(() => !!userProfileStore.userProfileDTO);
        if (!userProfileStore.birthday) {
          // don't return it! or it will wait for 1.5s
          wait(1500).then(showBirthdayModal);
        }
      })
      .catch((e) => enqueueWarningSnackbar(e?.message));
  };

  return (
    <IllumeLayout hideHamburger>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <Text
              align={'center'}
              color={colors.darkText}
              fontSize={{ mobile: rem[1500], desktop: rem[2250] }}
              fontWeight={800}
            >
              DON'T MISS <br /> {spelledNames?.toUpperCase()}'S REPLY!
            </Text>
          </Grid>
          <Grid item className={classes.textBox}>
            <Text align={'center'} color={colors.darkText} fontWeight={300}>
              You're awesome:) You just added a note to {spelledNames}'s card. Make sure you don't
              miss their reply!
            </Text>
          </Grid>
        </Grid>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item style={{ width: '100%' }}>
              <TextInput
                ref={register}
                inputId="email"
                name="email"
                data-testid="email"
                label="Your Email"
                placeholder="e.g. johnsmith@gmail.com"
                error={errors?.email}
                errorMessage={errors?.email?.message}
              />
            </Grid>

            <Grid item style={{ width: '100%' }}>
              <Checkbox
                name="subscribing"
                textSize={{ mobile: rem[875], desktop: 14 }}
                text="sign up to receive updates and newsletters from illume."
                inputRef={register}
              />
            </Grid>

            <Grid container direction="column" className={classes.navFooter}>
              <Grid container direction="column" spacing={1} alignItems="center">
                <Grid item onClick={() => trigger()}>
                  <RightArrowButton
                    color={colors.primary}
                    loading={isSubmitting}
                    disabled={disableSubmitButton}
                    type="submit"
                  />
                </Grid>
                <Grid item className={classes.progressCircles}>
                  <ProgressCircle color="primary" step={2} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid container justifyContent="center" className={classes.navText}>
          <Text fontSize={rem[750]} color={colors.labelText} italic>
            your note was submitted! let’s just verify your email real quick;)
          </Text>
        </Grid>
        <Grid className={classes.footer} container justifyContent="center">
          <Box mt={2}>
            <Footer />
          </Box>
        </Grid>
      </Grid>
    </IllumeLayout>
  );
};

export default observer(ReplyContainer);
