import { Grid } from '@material-ui/core';
import { PaymentRequest } from '@stripe/stripe-js';
import { noop } from 'lodash';
import { action, computed, makeObservable, observable, override } from 'mobx';
import { observer } from 'mobx-react';

import { ResponsiveSquaredActionableGiftThumbnail } from 'components/actionable-gift-thumbnail';
import { GroupGiftModal } from 'components/illume/group-gift-modal';
import { GroupGiftModalFormValues } from 'components/illume/group-gift-modal/GroupGiftModal';
import { GroupGiftModalDTOMapper } from 'components/illume/group-gift-modal/GroupGiftModalDTOMapper';
import Text from 'components/illume/text/Text';
import { IllumeLayout } from 'components/layouts';
import { colors, rem } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useAuth } from 'contexts/authentication';
import { useStores } from 'contexts/store';
import StripeStore, { IProduct } from 'contexts/store/StripeStore.store';
import { ExternalGift } from 'domain/entities/external-gift/externalGift';
import Gift from 'domain/entities/gift/gift';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { useValue } from 'hooks/useValue';
import stripeService from 'services/stripeService';
import {
  ContributorCardDTO,
  GiftV2Dto,
  GiftV2ErrorDTO,
  InitiatorCardViewDTO,
  Maybe,
  RecipientDTO,
} from 'types';
import { isGift } from 'types/guard';
import { wait } from 'utils';
import { showNames } from 'utils/string';

import { ROLES } from '../card-details-v2/shared/constants';
import PaymentForm from './archive/PaymentForm';
import ContributorStore, { ContributionForm } from './Contributor.store';
import { useContributorStyles } from './ContributorGift.styles';

type ModalType = 'groupGift' | 'anyGift' | null;

interface IProps {
  store: IPayForGiftStore;
}
export interface IPayForGiftStore {
  modalShown: ModalType;
  setModalShown(type: ModalType): void;
  formStore: ContributionForm;
  get email(): string | undefined;
  /**
   * @deprecated - using dto directly is discouraged
   */
  get gift(): GiftV2Dto | null;
  get externalGift(): Maybe<ExternalGift>;
  get giftError(): GiftV2ErrorDTO | undefined;
  get recipientData(): RecipientDTO[];
  get amount(): number | undefined;
  get paymentRequest(): PaymentRequest | null;
  get formattedGiftPrice(): string;
  get formattedGiftGoal(): string;
  onPaid(amount: number): void;
  reinitializeStripeButton({ amount, email }: GroupGiftModalFormValues): any;
}

export abstract class BasePayForGiftStore implements IPayForGiftStore {
  modalShown: ModalType = null;
  setModalShown(type: ModalType) {
    this.modalShown = type;
  }
  protected abstract stripeStore: StripeStore;
  abstract formStore: ContributionForm;
  abstract card: ContributorCardDTO | InitiatorCardViewDTO;
  constructor() {
    makeObservable(this, {
      modalShown: observable,
      setModalShown: action.bound,
      email: computed,
      amount: computed,
      paymentRequest: computed,
      formattedGiftPrice: computed,
      gift: computed,
      recipientData: computed,
      reinitializeStripeButton: action.bound,
      onPaid: action.bound,
    });
  }

  get email() {
    return this.formStore.userData.email;
  }

  get gift() {
    return isGift(this.card.giftV2) ? this.card.giftV2 : null;
  }

  get externalGift() {
    return this.card.externalGift && ExternalGift.fromDto(this.card.externalGift);
  }

  get giftEntity() {
    return this.gift && Gift.fromDto(this.gift);
  }

  get giftError() {
    return !isGift(this.card.giftV2) ? this.card.giftV2 : undefined;
  }

  get recipientData() {
    return this.card.recipientData || [];
  }

  get amount() {
    const amount = this.formStore.cents;
    return amount;
  }

  get paymentRequest() {
    return this.stripeStore.paymentRequest;
  }

  get formattedGiftPrice() {
    return this.giftEntity ? this.giftEntity.productPrice.toFormat() : '';
  }

  get formattedGiftGoal() {
    return this.giftEntity?.goal.toFormat() || this.externalGift?.financial?.goal.toFormat() || '';
  }

  abstract onPaid(amount: number): any;

  // reinitialize google or apple payment button
  reinitializeStripeButton({ amount, email }: GroupGiftModalFormValues) {
    const gift: Maybe<IProduct> = this.gift
      ? { type: 'shopify', id: this.gift.id, product: this.gift.product }
      : this.externalGift
      ? { type: 'external', id: this.externalGift.id, product: this.externalGift.product }
      : null;
    if (!gift) {
      return console.error('no gift in card');
    }
    this.formStore.setDollar(amount);

    if (!this.formStore.cents) {
      return console.error('no amount set');
    }
    const cents = this.formStore.cents;
    return wait(300)
      .then(() => this.stripeStore.initStripePaymentButtonForGift(gift, cents, email))
      .then(() => this.setModalShown(null));
  }
}

class ContributorPayForGiftStore extends BasePayForGiftStore implements IPayForGiftStore {
  card: ContributorCardDTO;
  formStore: ContributionForm;

  constructor(protected stripeStore: StripeStore, private contributorStore: ContributorStore) {
    super();
    this.formStore = contributorStore.formStore;
    this.card = contributorStore.cardDto!;
    makeObservable(this, { onPaid: override });
  }

  onPaid(amount: number) {
    this.contributorStore.handleSuccessfulPayment(amount);
  }
}

/**
 * this is used also by initiator, better move this somewhere else
 *
 */
export const PayForGift: React.FC<IProps> = observer(({ store: ui }) => {
  const { authenticated } = useAuth() || {};
  const classes = useContributorStyles();
  const desktop = useIsDesktop();
  const analytics = useAnalytics();
  const productImage = ui.externalGift?.image || ui.gift?.product.variant.img;
  const productName = ui.externalGift?.title || ui.gift?.product.name || '';
  const spelledRecipientNames = showNames(ui.recipientData);

  return (
    <IllumeLayout showIllumeLogo background="transparent" hideHamburger={!authenticated}>
      <Grid container direction="column" className={classes.container} justifyContent="center">
        {/*Title*/}
        <Grid item container spacing={2} direction="column" className={classes.textBox}>
          <Grid item>
            <Text
              align="center"
              color={colors.darkText}
              fontSize={{ mobile: rem[1375], desktop: rem[2250] }}
              fontWeight={900}
              hover={undefined}
            >
              {ui.gift
                ? `CONTRIBUTE TO ${spelledRecipientNames?.toUpperCase()}'S GROUP GIFT!`
                : `CONTRIBUTE TO ${spelledRecipientNames?.toUpperCase()}'S CARD!`}
            </Text>
          </Grid>
          <Grid item>
            <Text
              color={colors.darkText}
              fontWeight={desktop ? 300 : 400}
              fontSize={{ mobile: rem[875], desktop: rem[1000] }}
              align="center"
            >
              The group is coming together to get {spelledRecipientNames} a {productName}.
              Contribute to the group gift below.
            </Text>
          </Grid>
        </Grid>
        <Grid item container className={classes.donation}>
          <div className={classes.thumbnail}>
            {productImage ? (
              <ResponsiveSquaredActionableGiftThumbnail
                img={productImage}
                action={{
                  onAction: () =>
                    ui.gift
                      ? ui.setModalShown('groupGift')
                      : ui.externalGift
                      ? ui.setModalShown('anyGift')
                      : noop(),
                  content: `VIEW DETAILS | ${ui.formattedGiftGoal}`,
                }}
              />
            ) : (
              'Oops! we cannot find the product, this must be our mistake. please reach us on: support@illumenotes.com'
            )}
          </div>
          {ui.amount && (
            <div className={classes.paymentFormContainer}>
              {ui.gift && (
                <PaymentForm
                  analytics={analytics}
                  role={ROLES.CONTRIBUTOR}
                  gift={ui.gift || ui.externalGift}
                  amount={ui.amount}
                  paymentRequest={ui.paymentRequest}
                  userData={{ fullName: ui.formStore.userData?.name }}
                  setUserData={({ fullName }) => ui.formStore.setUserData({ name: fullName })}
                  email={ui.formStore.userData.email!}
                  onCCPaymentSuccess={({ amount, paymentIntent }) => {
                    analytics.track(EVENT_NAMES.CONTRIBUTOR_PAYS_GIFT.name, { paymentIntent });
                    return ui.onPaid(amount);
                  }}
                  setupIntentService={stripeService.setupIntent}
                />
              )}

              {ui.externalGift && (
                <PaymentForm
                  analytics={analytics}
                  role={ROLES.CONTRIBUTOR}
                  gift={ui.externalGift}
                  amount={ui.amount}
                  paymentRequest={ui.paymentRequest}
                  userData={{ fullName: ui.formStore.userData?.name }}
                  setUserData={({ fullName }) => ui.formStore.setUserData({ name: fullName })}
                  email={ui.formStore.userData.email!}
                  onCCPaymentSuccess={({ amount, paymentIntent }) => {
                    analytics.track(EVENT_NAMES.CONTRIBUTOR_PAYS_GIFT.name, { paymentIntent });
                    return ui.onPaid(amount);
                  }}
                  setupIntentService={stripeService.anyGiftSetupIntent}
                />
              )}
            </div>
          )}
        </Grid>
      </Grid>
      {ui.externalGift && (
        <GroupGiftModal
          removable={false}
          showModal={ui.modalShown === 'anyGift'}
          hideModal={() => ui.setModalShown(null)}
          gift={GroupGiftModalDTOMapper.fromExternalGift(ui.externalGift)}
          onContributeToGroupGift={(values) => ui.reinitializeStripeButton(values)}
          defaultValues={{
            email: ui.email,
            amount: ui.formStore.rawDollarValue,
          }}
        />
      )}
      {ui.gift && isGift(ui.gift) && (
        <GroupGiftModal
          removable={false}
          showModal={ui.modalShown === 'groupGift'}
          hideModal={() => ui.setModalShown(null)}
          gift={GroupGiftModalDTOMapper.fromGiftV2Dto(ui.gift)}
          onContributeToGroupGift={(values) => ui.reinitializeStripeButton(values)}
          defaultValues={{
            email: ui.email,
            amount: ui.formStore.rawDollarValue,
          }}
        />
      )}
    </IllumeLayout>
  );
});

const ContributorPayForGift: React.FC<{ contributorStore: ContributorStore }> = ({
  contributorStore,
}) => {
  const { stripeStore } = useStores();
  const store = useValue(new ContributorPayForGiftStore(stripeStore, contributorStore));
  return <PayForGift store={store} />;
};

export default ContributorPayForGift;
