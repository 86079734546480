import { useRef, useEffect, useState } from 'react';

import { motion } from 'framer-motion';

import { MenuBlob } from 'components/illume/menu-blob';
import { MenuToggle } from 'components/illume/menu-toggle';
import { Navigation } from 'components/illume/navigation';

import { menuStyles } from './Menu.styles';

const useDimensions = (ref) => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth;
    dimensions.current.height = ref.current.offsetHeight;
  }, [ref]);

  return dimensions.current;
};

export const Menu = ({ unread }) => {
  const containerRef = useRef(null);
  const [menuVisible, setMenuVisibile] = useState(false);

  const { height } = useDimensions(containerRef);

  const handleClickOutside = (e) => {
    // Click inside element
    if (containerRef.current.contains(e.target)) return;

    // Click outside of element
    setMenuVisibile(false);
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <motion.nav
      initial={false}
      animate={menuVisible ? 'visible' : 'hidden'}
      custom={height}
      ref={containerRef}
      style={menuStyles}
    >
      {/* Blob background */}
      <MenuBlob menuVisible={menuVisible} />

      {/* Navigation */}
      <Navigation menuVisible={menuVisible} unread={unread} />

      {/* Hamburger menu trigger */}
      <MenuToggle
        unread={unread}
        toggle={() => setMenuVisibile((prev) => !prev)}
        menuVisible={menuVisible}
      />
    </motion.nav>
  );
};
