import { Grid, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { Box } from 'components/box';
import Text from 'components/illume/text/Text';
import { Loading } from 'components/Loading';
import ThreeDotsWave from 'components/Loading/ThreeDotsWaveLoading';
import { colors } from 'constants/design';
import { InitiatorCard } from 'domain/entities/initiator-card/InitiatorCard';

import { getRandomLoadingLabel } from '../marketplace/pages/getRandomLoadingLabel';
import ContributorCardDetailsV2Pending from './card-details-v2-pending/contributor/CardDetailsV2Pending';
import { InitiatorCardDetailsContent } from './card-details-v2-pending/initiator/CardDetailsV2.initiator';
import { IllumeTourProvider } from './card-details-v2-pending/initiator/components/IllumeTourProvider';
import { steps } from './card-details-v2-pending/initiator/components/tour_steps';
import CardDetailsV2Sent from './card-details-v2-sent/CardDetailsV2Sent';
import { CardDetailsStore } from './CardDetaillsV2PendingStore';
import CardDetailsPayForGift from './CardDetailsPayForGift';

const InitiatorContributorCardDetailsHandler: React.FunctionComponent<{
  cardDetailsStore: CardDetailsStore;
  addNotePath: string;
  disableGifting?: boolean;
  disableContactEditing?: boolean;
}> = ({ cardDetailsStore, addNotePath, disableGifting = false, disableContactEditing }) => {
  // card code from email is always in viewCode regardless the role
  // but we now that the new RAPID endpoint can't query a card with viewCode
  // unless the requester is an Initiator

  const history = useHistory();

  const store = cardDetailsStore;

  let { path } = useRouteMatch();

  const goCreateNote = () => {
    const path = addNotePath;
    history.push({
      pathname: path,
    });
  };
  return match(store.state)
    .with({ type: P.union('loading', 'idle') }, () => <Loading text={getRandomLoadingLabel()} />)
    .with({ type: P.union('loaded', 'refreshing') }, (state) => {
      return (
        <Switch>
          <Route path={path}>
            {match(state.data)
              .with(
                {
                  status: P.union('ReadyToSend', 'InProgress'),
                  userContext: { role: 'Contributor' },
                },
                (card) => {
                  return (
                    <Switch>
                      <Route exact path={path + '/pay'}>
                        <CardDetailsPayForGift store={state.giftPaymentFromCardDetailsStore} />
                      </Route>
                      <Route exact path={path}>
                        <ContributorCardDetailsV2Pending
                          onCreateNote={goCreateNote}
                          store={store}
                          card={card}
                          giftPaymentFromCardDetailsStore={state.giftPaymentFromCardDetailsStore}
                        />
                      </Route>
                    </Switch>
                  );
                },
              )
              .with(
                {
                  status: P.union('ReadyToSend', 'InProgress'),
                  userContext: { role: 'Initiator' },
                },
                (card) => {
                  return (
                    <Switch>
                      <Route exact path={path + '/pay'}>
                        <CardDetailsPayForGift store={state.giftPaymentFromCardDetailsStore} />
                      </Route>
                      <Route exact path={path}>
                        <IllumeTourProvider steps={steps}>
                          <InitiatorCardDetailsContent
                            disableContactEditing={disableContactEditing}
                            disableGifting={disableGifting}
                            onCreateNote={goCreateNote}
                            card={InitiatorCard.fromDto(card)}
                            store={store}
                            giftPaymentFromCardDetailsStore={state.giftPaymentFromCardDetailsStore}
                            onDeleteCardSuccess={() =>
                              history.replace(cardDetailsStore.cardlistUrl)
                            }
                          />
                        </IllumeTourProvider>
                      </Route>
                    </Switch>
                  );
                },
              )
              .with({ status: 'Delivered' }, (c) => (
                <CardDetailsV2Sent card={c} resendCardService={store.resendWithMaybeNewContacts} />
              ))
              .with({ status: 'Read' }, (c) => (
                <CardDetailsV2Sent card={c} resendCardService={store.resendWithMaybeNewContacts} />
              ))
              .with({ status: 'Draft' }, () => null) // not implemented
              .with({ status: 'Received' }, () => null)
              .exhaustive()}

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              open={state.type === 'refreshing'}
            >
              <Grid item>
                <Alert severity="warning" icon={false}>
                  <Box display="flex" alignItems={'center'}>
                    <Box mr={1}>
                      <Text>refreshing</Text>
                    </Box>
                    <Box mt={1}>
                      <ThreeDotsWave color={colors.threeDotsBrown} />
                    </Box>
                  </Box>
                </Alert>
              </Grid>
            </Snackbar>
          </Route>
        </Switch>
      );
    })
    .with({ type: 'error' }, () => <div>oops! something went wrong</div>)
    .exhaustive();
};

export default observer(InitiatorContributorCardDetailsHandler);
