const Blob = ({ size = 231 }) => {
  return (
    <svg
      width={size}
      height={(220 / 231) * size}
      viewBox="0 0 231 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0489917 138.248C-0.984786 158.677 14.4118 180.432 39.5965 196.948C66.4747 214.573 96.2784 221.696 128.392 219.663C146.912 218.509 164.354 213.589 179.003 201.76C188.526 194.08 195.896 184.647 202.252 174.379C213.47 156.282 222.862 137.414 228.163 116.772C235.508 88.1934 228.338 62.9732 209.598 40.5338C189.956 17.0248 163.848 5.08851 133.428 1.15252C112.159 -1.60696 91.4395 0.574943 71.3137 7.84797C46.745 16.7254 29.1048 33.1324 17.9971 55.9355C6.91152 78.653 0.862818 102.675 0.0489917 138.248Z"
        fill="#E5A619"
      />
      <path
        d="M0.0468707 135.572C-0.942155 155.605 13.788 176.939 37.8824 193.134C63.597 210.419 92.1104 217.404 122.833 215.411C140.552 214.278 157.239 209.454 171.254 197.853C180.365 190.323 187.415 181.072 193.496 171.003C204.228 153.256 213.214 134.754 218.285 114.511C225.313 86.4861 218.453 61.7541 200.525 39.7491C181.733 16.6952 156.755 4.99 127.652 1.13021C107.304 -1.57585 87.481 0.563816 68.2265 7.69604C44.7214 16.4016 27.8448 32.491 17.218 54.8526C6.61232 77.1304 0.825465 100.687 0.0468707 135.572Z"
        fill="#FEB81C"
      />
    </svg>
  );
};

export default Blob;
