import { Grid, Modal as MUIModal, Paper } from '@material-ui/core';
import classNames from 'classnames';

import { BaseIconButton, Button } from 'components/illume/buttons';
import { Close } from 'components/illume/icons';
import { Surface } from 'components/illume/surface';
import Text from 'components/illume/text/Text';
import { colors, rem } from 'constants/design';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';
import { Maybe, TagDTO } from 'types';

import { HtmlRenderer } from '../html-renderer';
import { modalStyles } from './ShipGiftModal.styles';

interface IProduct {
  vendor: Maybe<string>;
  description: string;
  name: string;
  image: string;
}
export interface ShipGiftModalProps {
  showModal: boolean;
  hideModal: () => any;
  tags: Maybe<TagDTO[]>;
  product: IProduct;
}

const ShipGiftModal: React.FC<ShipGiftModalProps> = ({ showModal, hideModal, product, tags }) => {
  const desktop = useIsDesktop();
  const classes = modalStyles({ desktop });

  const byStoreNameFormatted = product.vendor && `by ${product.vendor}`;

  const descriptionHtmlText = product.description;

  const handleCloseShipGiftModal = hideModal;

  return (
    <MUIModal onClose={handleCloseShipGiftModal} open={showModal} className={classes.modal}>
      <Paper className={classNames(classes.paper, 'customIllumeScrollbar-gray')}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} direction="column" container spacing={2}>
            <Grid item className={classes.closeModalIcon}>
              <BaseIconButton
                onClick={hideModal}
                icon={() => <Close color={colors.gray100} size={16} />}
              />
            </Grid>
            <Grid item>
              <img src={product.image} alt={'Product'}></img>
            </Grid>
            <Grid item>
              <Text fontWeight={700} lineHeight={'18px'} fontSize={{ desktop: 28, mobile: 24 }}>
                {product.name}
              </Text>
            </Grid>
            {byStoreNameFormatted && (
              <Grid item>
                <Text lineHeight={'18px'} fontWeight={400} fontSize={18}>
                  {byStoreNameFormatted}
                </Text>
              </Grid>
            )}
            <Grid item>
              <Text italic lineHeight={'18px'} fontWeight={400} fontSize={14}>
                <HtmlRenderer html={descriptionHtmlText} />
              </Text>
            </Grid>
            {tags && (
              <Grid item container direction="row" className={classes.tagWrapper}>
                {tags.map(({ id, text, color }) => {
                  return (
                    <Surface
                      key={id}
                      className={classes.tagContainer}
                      background={color}
                      borderRadius={4}
                    >
                      <Text fontSize={rem[687.5]} color={colors.contrastText}>
                        {text}
                      </Text>
                    </Surface>
                  );
                })}
              </Grid>
            )}
            <Grid item>
              <Button onClick={hideModal} fullWidth className={classes.closeButton}>
                close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </MUIModal>
  );
};

export default ShipGiftModal;
