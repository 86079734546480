import { Maybe } from '@illume/shared';
import { makeAutoObservable } from 'mobx';

import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { IAnalytics } from 'domain/interfaces/IAnalytics';

import {
  Challenge,
  IRootVerifyStore,
  IssueStatuses,
  VerifyDigitStatus,
  VerifyingUserProfile,
} from './IRootVerifyStore';

export interface IRootVerifyStoreServices {
  issueChallenge: (email: string) => Promise<Challenge>;
  verifyChallenge: (
    challenge: { nonce: string; digits: string },
    profile: { name?: string; email: string },
  ) => Promise<void>;
}

export class RootVerifyStoreSimplified implements IRootVerifyStore {
  private resolve: Maybe<() => any>;
  private reject: Maybe<(e: Error) => any>;
  private challenge: Maybe<Challenge>;
  userProfile?: VerifyingUserProfile | undefined;
  verificationFormVisible: boolean = false;
  cancel(): void {
    //resets
    this.verificationFormVisible = false;
    this.challenge = null;
    this.userProfile = undefined;
    if (this.reject) this.reject(new Error('verification process was cancelled'));
  }
  async resendChallenge(): Promise<void> {
    if (!this.userProfile?.email) {
      throw new Error("no email have set, probably haven't issued a challenge");
    }
    this.analytics.track(EVENT_NAMES.RESEND_CODE_VERIFY.name);
    return this.issueChallenge(this.userProfile.email);
  }
  issueChallengeError: Maybe<Error>;
  verifyDigitsError: Maybe<Error>;
  issueChallengeStatus: IssueStatuses = 'idle';
  verifyDigitsStatus: VerifyDigitStatus = 'idle';
  async verifyDigits(digits: string) {
    if (!this.challenge) {
      throw new Error('attempt to verify without challenge');
    }
    if (!this.userProfile?.email) {
      throw new Error('somehow email is not provided');
    }
    this.verifyDigitsStatus = 'verifying';

    return this.services
      .verifyChallenge(
        { digits, nonce: this.challenge.nonce },
        { email: this.userProfile?.email, name: this.userProfile.name },
      )
      .then(() => {
        this.analytics.track(EVENT_NAMES.ENTERED_CORRECT_CODE_VERIFY.name);
        this.verifyDigitsStatus = 'verified';
        this.verificationFormVisible = false;
        if (this.resolve) this.resolve();
      })
      .catch((e) => {
        this.analytics.track(EVENT_NAMES.ERROR_VERIFY.name);
        this.verifyDigitsError = e;
        this.verifyDigitsStatus = 'failed';
      });
  }
  private async issueChallenge(email: string) {
    this.issueChallengeStatus = 'issuing';
    try {
      this.challenge = await this.services.issueChallenge(email);
    } catch (e) {
      this.issueChallengeError = e as unknown as Error;
      this.issueChallengeStatus = 'failed';
    }
  }
  async beginVerification(email: string, name?: string | undefined): Promise<void> {
    this.analytics.track(EVENT_NAMES.VIEW_PAGE_VERIFY.name);
    this.userProfile = { name, email };
    await this.issueChallenge(email);
    this.verificationFormVisible = true;
    return new Promise<void>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  constructor(private services: IRootVerifyStoreServices, private analytics: IAnalytics) {
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
