export const Preview = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00696311 11.8918C-0.139967 8.9202 2.04836 5.75568 5.62784 3.3535C9.44803 0.789513 13.684 -0.24666 18.2482 0.0489453C20.8805 0.216973 23.3595 0.932648 25.4416 2.65338C26.7952 3.77046 27.8425 5.14268 28.746 6.63627C30.3403 9.26871 31.6752 12.0132 32.4286 15.0159C33.4727 19.173 32.4536 22.8416 29.7901 26.1057C26.9984 29.5254 23.2876 31.2617 18.9641 31.8342C15.9411 32.2356 12.9963 31.9183 10.1358 30.8603C6.64385 29.569 4.13665 27.1824 2.55793 23.8654C0.982332 20.5608 0.122632 17.0665 0.00696311 11.8918Z"
      fill="#282829"
      fillOpacity="0.4"
    />
    <path
      d="M9.14001 16C10.442 13.8448 13.0236 12.3976 16 12.3976C18.9764 12.3976 21.558 13.8448 22.8397 15.9634L22.86 16C21.558 18.1552 18.9764 19.6024 16 19.6024C13.0236 19.6024 10.442 18.1552 9.16031 16.0366L9.14001 16V16ZM16 18.4C17.5463 18.4 18.8 17.3254 18.8 16C18.8 14.6746 17.5463 13.6 16 13.6C14.4537 13.6 13.2 14.6746 13.2 16C13.2 17.3254 14.4537 18.4 16 18.4ZM16 17.2C15.2265 17.2 14.6 16.663 14.6 16C14.6 15.337 15.2265 14.8 16 14.8C16.7735 14.8 17.4 15.337 17.4 16C17.4 16.663 16.7735 17.2 16 17.2Z"
      fill="#FFFDF3"
    />
  </svg>
);
