export const Eye = ({ color, size = 24 }) => (
  <svg
    fill={color}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.55 8C6.834 8 3 12.536 3 12.536C3 12.536 6.834 17.072 11.55 17.072C16.266 17.072 20.1 12.536 20.1 12.536C20.1 12.536 16.266 8 11.55 8ZM11.55 15.47C9.93 15.47 8.616 14.156 8.616 12.536C8.616 10.916 9.93 9.602 11.55 9.602C13.17 9.602 14.484 10.916 14.484 12.536C14.484 14.156 13.17 15.47 11.55 15.47Z" />
    <path d="M11.55 14.0661C12.395 14.0661 13.08 13.3811 13.08 12.5361C13.08 11.6911 12.395 11.0061 11.55 11.0061C10.705 11.0061 10.02 11.6911 10.02 12.5361C10.02 13.3811 10.705 14.0661 11.55 14.0661Z" />
  </svg>
);
