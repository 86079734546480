import * as yup from 'yup';

import { MAX_RECIPIENT_NUMBER } from 'constants/strings';

const MIN_LENGTH = 'thanks'.length;

export const phoneRegex = /^\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?$/;
export const emailRegex = /^([A-Za-z0-9_.+-]+@[\dA-Za-z.-]+\.[A-Za-z.]{2,6})$/;

export type SendCardSchema = Pick<yup.InferType<typeof schema>, 'recipientsContacts' | 'message'>;

export const schema = yup.object().shape({
  recipientsContacts: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          emailOrPhone: yup
            .string()
            .required('please provide email or phone number')
            .test('email-phone-test', 'must be a valid email/phone number', (value?: string) => {
              if (!value) {
                return false;
              }
              const isValidEmail = emailRegex.test(value);
              const isValidPhone = phoneRegex.test(value);
              if (!isValidEmail && !isValidPhone) {
                return false;
              }
              return true;
            }),
        })
        .required(),
    )
    .max(MAX_RECIPIENT_NUMBER, 'maximum recipients reached')
    .required(),
  message: yup.string().test('len', `message must be at least ${MIN_LENGTH} characters`, (val) => {
    if (val && val !== '') return val.length >= MIN_LENGTH;
    else return true;
  }),
});
