import { Maybe } from '@illume/shared';

export interface IClipboard {
  copy(text: string): Promise<string>;
  getCopiedText(): Maybe<string>;
}

export abstract class BaseClipboard implements IClipboard {
  protected copiedText?: string;
  getCopiedText(): Maybe<string> {
    return this.copiedText;
  }
  copy(text: string): Promise<string> {
    return Promise.resolve((this.copiedText = text));
  }
}
