import { CardStatus, MyCardsCardDTO, MyCardsType } from '@illume/shared';
import { match } from 'ts-pattern';

import { spellNames } from 'utils/string';

function getCardTypeFromDto(card: MyCardsCardDTO): MyCardsType {
  if (card.status === 'InProgress' || card.status === 'ReadyToSend' || card.status === 'Draft') {
    return MyCardsType.PENDING;
  }

  if (card.role === 'Recipient') {
    return MyCardsType.RECEIVED;
  }

  if (card.status === 'Delivered' || card.status === 'Read') {
    return MyCardsType.SENT;
  }

  return MyCardsType.PENDING; // code above is sufficient, just to make TS happy
}

/**
 * README:
 * things that affects cards changes
 *
 * - cards' CRUD
 * - adding note
 * - misc actions
 *   - sending card
 *   - adding external gift
 *   - adding shopify gift
 *   - adding note
 *   - adding contributors
 *
 */

export class CardThumbnail {
  private constructor(
    public code: string,
    public recipientData: { name: string }[],
    public date: Date,
    public contributorsCount: number,
    public type: MyCardsType,
    public status: CardStatus,
    public contributorList: string[],
    public inviteList: string[],
    public initiatorName: string,
    public workspaceUrl: string | null,
  ) {}

  get shouldAlert() {
    return match(this.type)
      .with(MyCardsType.PENDING, () => this.status === 'ReadyToSend')
      .with(MyCardsType.SENT, () => false)
      .with(MyCardsType.RECEIVED, () => this.status !== 'Read')
      .exhaustive();
  }

  /**
   * TODO: should not be generic like this
   * maybe fine grained with different DTOs
   * so we don't mismatch the type
   */
  static fromApiDTO(dto: MyCardsCardDTO, _type: MyCardsType) {
    const type = _type || getCardTypeFromDto(dto);

    const d = match(type)
      .with(MyCardsType.PENDING, () =>
        dto.scheduledTimestamp
          ? dto.scheduledTimestamp
          : dto.deadline
          ? dto.deadline
          : dto.createdAt,
      )
      .with(MyCardsType.SENT, () =>
        'sentAt' in dto ? dto.sentAt.toString() : new Date(dto.createdAt),
      )
      .with(MyCardsType.RECEIVED, () =>
        'receivedAt' in dto ? dto.receivedAt.toString() : new Date(dto.createdAt),
      )
      .exhaustive();

    return new CardThumbnail(
      dto.code,
      dto.recipientData.map((r) => ({ name: r.name })),
      new Date(d),
      dto.contributorCount || 0,
      type,
      dto.status,
      dto.contributorList || [],
      dto.inviteList,
      dto.initiatorName,
      dto.workspaceUrl || null,
    );
  }

  get formattedRecipientsName() {
    return spellNames(this.recipientData);
  }

  get formattedInitiatorName() {
    // get only first name except the one that named 'the illume team'
    const texts = this.initiatorName.split(' ');

    // iterate throught tthe texts until having non-empty string
    let name = '';
    for (let text of texts) {
      if (text !== '') {
        name = text;
        break;
      }
    }

    return this.initiatorName === 'the illume team' ? 'the illume team' : name;
  }
}
