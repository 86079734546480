import { AxiosRequestConfig } from 'axios';

import { IllumeDate } from 'domain/entities/illume-date/illumeDate';
import { IHttpClient } from 'domain/interfaces/IHttpClient';
import { MyCardsType, GetBatchedCardResponseDTO } from 'types';
import { CustomError } from 'utils';

import { IBatchCardService, UploadPayload } from '../../domain/interfaces/IBatchCardService';

export class BatchCardService implements IBatchCardService {
  constructor(private httpClient: IHttpClient) {}
  getBatchedCards = async (type: Omit<MyCardsType, MyCardsType.PENDING>) => {
    const requestObj: AxiosRequestConfig = {
      method: 'GET',
      url: `v2/card/all/cardBatches/${type}`,
    };
    const res = await this.httpClient.makeRequest<GetBatchedCardResponseDTO>(requestObj);
    if (res.success) {
      return res.cardBatches;
    } else {
      throw CustomError.fromServiceErrorDto(res);
    }
  };

  validateBatch = async (payload: Pick<UploadPayload, 'presetRecipients'>) => {
    const formData = new FormData();
    const deadlineTz = new IllumeDate(new Date()).getTz();

    for (let key in Object.assign(payload, { deadlineTz })) {
      formData.append(key, payload[key as keyof typeof payload]);
    }

    const requestObj: AxiosRequestConfig = {
      method: 'post',
      url: `card/cardsBatchDraft`,
      data: formData,
    };

    const resp = await this.httpClient.makeRequest<null>(requestObj);
    if (resp.success) {
      return true;
    } else {
      throw CustomError.fromServiceErrorDto(resp);
    }
  };

  uploadBatch = async (
    payload: Omit<UploadPayload, 'deadlineTz'>,
    onProgress: (ratio: number) => any,
    abortSignal: AbortSignal,
  ) => {
    const formData = new FormData();
    const deadlineTz = new IllumeDate(new Date()).getTz();
    for (let key in Object.assign(payload, { deadlineTz })) {
      formData.append(key, payload[key as keyof typeof payload]);
    }

    const requestObj: AxiosRequestConfig = {
      method: 'post',
      url: `card/cardsBatch`,
      onUploadProgress: (e) => onProgress(e.loaded / e.total),
      data: formData,
      signal: abortSignal,
    };
    const res = await this.httpClient.makeRequest(requestObj);
    if (res.success) {
      return true;
    } else {
      throw CustomError.fromServiceErrorDto(res);
    }
  };

  editBatch = async (
    batchId: string,
    payload = {
      batchName: '',
      batchDeadline: '',
      deadlineTz: new IllumeDate(new Date()).getTz(),
    },
  ) => {
    const requestObj: AxiosRequestConfig = {
      method: 'patch',
      url: `card/cardsBatch/${batchId}`,
      data: payload,
    };
    const res = await this.httpClient.makeRequest(requestObj);
    if (res.success) {
      return;
    } else {
      throw CustomError.fromServiceErrorDto(res);
    }
  };

  deleteBatch = async (batchId: string) => {
    const requestObj: AxiosRequestConfig = {
      method: 'delete',
      url: `card/cardsBatch/${batchId}`,
    };
    const res = await this.httpClient.makeRequest(requestObj);
    if (res.success) {
      return;
    } else {
      throw CustomError.fromServiceErrorDto(res);
    }
  };
}
