import axios from 'axios';

import config from 'config';

import { IllumeApiHttpClient } from './client';

export * from './client';

const illumeApiHttpClient = new IllumeApiHttpClient(axios.create({ baseURL: config.baseURL_new }));

export default illumeApiHttpClient;

//@ts-expect-error
// for debugging purpose
window.illumeHttpclient = illumeApiHttpClient;
