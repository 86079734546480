import { Box, Snackbar, SnackbarProps } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Text } from 'components/illume/text';
import ThreeDotsWave from 'components/Loading/ThreeDotsWaveLoading';

interface ActivityIndicatorProps extends SnackbarProps {
  text?: string;
}

const ActivityIndicator: React.FunctionComponent<ActivityIndicatorProps> = ({
  text = 'loading..',
  ...props
}) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} {...props}>
      <Alert severity="warning" icon={false}>
        <Box display="flex" alignItems={'center'}>
          <Box mr={1}>
            <Text>{text}</Text>
          </Box>
          <Box mt={1}>
            <ThreeDotsWave color={'rgb(102, 60, 0)'} />
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default ActivityIndicator;
