/**
 * TODO: review these(typos, tone, grammatical errors, etc.),
 * after that, use this in the pages that still hardcoded these values
 */
export const VALIDATION_ERRORS = {
  giftContribution: {
    min: 'too low..',
    max: 'too generous..',
    required: 'please input contribution amount',
  },
  giftSuggestion: {
    required: 'suggested amount is required',
  },
  name: {
    required: 'please input your name',
    min: 'minimum of 2 character',
    max: 'name is too long',
  },
  email: {
    required: 'please provide your email',
  },
  message: {
    max: 'message is too long',
    required: 'message is required',
  },
};

export const GiftTypes = {
  NON_PROFIT: 'NonProfit',
  GIFT_CARD: 'GiftCard',
};
