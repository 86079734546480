import { match } from 'ts-pattern';

import { IllumeApiHttpClient } from 'infra/client';
import { IllumeHttpClientRequestObject } from 'infra/client/client.types';
import { ValueOf } from 'types';
import { CustomError } from 'utils';
const ERROR_CODES = {
  INVALID_CREDENTIALS: 'InvalidCredentials',
  MISCONFIGURED: 'Misconfigured',
  ALREADY_LOGGED_IN: 'AlreadyLoggedIn',
} as const;

type KnownError = ValueOf<typeof ERROR_CODES>;

export class AuthenticationService {
  constructor(private httpClient: IllumeApiHttpClient) {}
  /**
   * @param {string} tokenId - token from google signin response
   */
  loginWithGoogle = async (tokenId: string) => {
    // example error codes from the API

    const requestObj: IllumeHttpClientRequestObject = {
      url: '/token',
      method: 'post',
      data: { googleToken: tokenId },
    };
    const resp = await this.httpClient.makeRequest<{ token: string }>(requestObj);
    if (resp.success) {
      return this.httpClient.setInMemoryJWT(resp.token);
    } else {
      //TODO: this logic should be on consumer/client
      const errMsg = match(resp.errorCode as KnownError)
        .with('InvalidCredentials', () => 'invalid credentials')
        .with('Misconfigured', () => 'misconfigured')
        .with('AlreadyLoggedIn', () => "user's already logged in")
        .otherwise(() => "Something went wrong and we can't log you in. Please try again.");

      throw CustomError.fromServiceErrorDto({ ...resp, message: errMsg });
    }
  };
}
