import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Text } from 'components/illume/text';
import { IllumeLayout } from 'components/layouts';
import { rem } from 'constants/design';
import { useConfettiCannon } from 'hooks';
import { useIsDesktop } from 'hooks/illume/useIsDesktop';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `0 ${theme.spacing(6.5)}px`,
    maxWidth: 500,
    marginTop: theme.spacing(16),
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: 550,
      marginTop: theme.spacing(20),
    },
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  returnText: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  shopifyButton: {
    width: 350,
  },
}));

const MerchantVerification = () => {
  const desktop = useIsDesktop();
  const classes = useStyles();
  useConfettiCannon();

  return (
    <IllumeLayout hideHamburger={!desktop} contentProps={{ justifyContent: 'center' }}>
      <Grid container direction="column" className={classes.container}>
        <Grid className={classes.title} item>
          <Text fontSize={desktop ? rem[2000] : rem[1500]} fontWeight={800}>
            woohoo! your account has been verified.
          </Text>
        </Grid>
        <Grid item className={classes.returnText}>
          <Text fontWeight={400}>
            Please return to illume’s Shopify app to complete onboarding.
          </Text>
        </Grid>
        {/* <Grid item className={classes.shopifyButton}>
          <Button fullWidth>Open Illume Shopify App</Button>
        </Grid> */}
      </Grid>
    </IllumeLayout>
  );
};

export default MerchantVerification;
