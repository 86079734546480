const Copy = ({ size = 24, ...props }) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 7h-6a1.5 1.5 0 0 0-1.5 1.5v9A1.5 1.5 0 0 0 10 19h6a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 16 7Z"
        fill="#FFFDF3"
      />
      <path
        d="M7 16.5a.5.5 0 0 1-.5-.5V7.5A2.503 2.503 0 0 1 9 5h5.5a.5.5 0 0 1 0 1H9a1.5 1.5 0 0 0-1.5 1.5V16a.5.5 0 0 1-.5.5Z"
        fill="#FFFDF3"
      />
    </svg>
  );
};

export default Copy;
