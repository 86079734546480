//@ts-check
import { useState } from 'react';

import { InputProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { isBoolean } from 'lodash';

import Tooltip from 'components/illume/tooltip/Tooltip';
import WhyTooltip from 'components/illume/why-tooltip/WhyTooltip';
import { colors, rem } from 'constants/design';

import { Text } from '../text';
import { TextProps } from '../text/Text';
import { useStyles, inputStyles, endImageStyles, startImageStyles } from './BaseTextInput.styles';

// @ts-ignore
const Adornment = ({
  icon,
  iconColor = colors.contrastText,
  iconBackground,
  placement,
  onClick,
  setTooltipOpen,
}) => {
  const classes = useStyles();
  const styles = placement === 'start' ? startImageStyles : endImageStyles;
  const Icon = icon;
  return (
    <InputAdornment position="start" className={classes.root}>
      <div onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
        <button onClick={onClick} disabled={!onClick}>
          {typeof icon === 'function' ? (
            <div
              style={
                iconBackground && {
                  width: 48,
                  height: 60,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: isBoolean(iconBackground) ? colors.primary : iconBackground,
                }
              }
            >
              <Icon color={iconColor} />
            </div>
          ) : (
            <img style={styles} src={icon} alt="" />
          )}
        </button>
      </div>
    </InputAdornment>
  );
};

export interface BaseTextInputProps extends Omit<InputProps, 'error'> {
  inputId?: string;
  label?: string;
  endIcon?: any;
  startIcon?: any;
  showWhyTooltip?: boolean;
  whyTooltipText?: string;
  tooltip?: string;
  iconClick?: () => any;
  iconColor?: any;
  background?: 'main' | 'secondary';
  shadow?: boolean;
  shadowColor?: string;
  multiline?: boolean;
  radius?: number | string;
  labelSuffix?: any;
  iconBackground?: boolean | string;
  labelTextProps?: TextProps;
  onBlur?: () => any;
  fontSize?: number | string;
  border?: string;
  rightIcon?: any;
  onRightIconClick?: any;
  customWidth?: React.CSSProperties['width'];
  compact?: boolean;
  labelSuffixSpacing?: number;
  labelSuffixTextProps?: TextProps;
  placeHolderColor?: string;
}

const BaseTextInput: React.FC<BaseTextInputProps> = ({
  inputId,
  label,
  endIcon,
  startIcon,
  showWhyTooltip,
  whyTooltipText,
  tooltip = '',
  iconClick,
  iconColor,
  background = 'main',
  shadow = false,
  shadowColor = colors.alertRed,
  radius,
  onBlur,
  onFocus,
  className,
  disabled = false,
  labelSuffix,
  labelSuffixSpacing = 2,
  labelSuffixTextProps,
  iconBackground,
  labelTextProps,
  fontSize,
  border,
  rightIcon,
  customWidth,
  compact,
  placeHolderColor,
  ...props
}) => {
  const inputClasses = inputStyles({
    background,
    shadow,
    shadowColor,
    radius,
    fontSize,
    border,
    customWidth,
    compact,
    placeHolderColor,
  });
  const [open, setOpen] = useState(false);
  const handleBlur = () => {
    if (onBlur) onBlur();
    setOpen(false);
  };
  const handleFocus = () => {
    if (onFocus) onFocus();
    setOpen(open);
  };
  return (
    <FormControl fullWidth>
      <Box display="flex" alignItems="center">
        <Box mr={labelSuffixSpacing}>
          <label htmlFor={inputId}>
            <Text
              fontWeight={600}
              color={colors.labelText}
              fontSize={{ mobile: rem[875], desktop: rem[1000] }}
              {...labelTextProps}
            >
              {label}
            </Text>
          </label>
        </Box>
        {labelSuffix && (
          <Box>
            <Text fontWeight={300} fontSize={12} color={colors.labelText} {...labelSuffixTextProps}>
              {labelSuffix}
            </Text>
          </Box>
        )}
        {showWhyTooltip && (
          <WhyTooltip tooltipText={whyTooltipText} tooltipBackgroundColor={colors.primaryTooltip} />
        )}
      </Box>

      <Tooltip title={tooltip} text={tooltip} showTooltip={tooltip ? open : false} mb={12}>
        <Input
          disabled={disabled}
          autoComplete="off"
          /* fix number change while scrolling
           * https://stackoverflow.com/a/20838527
           */
          onWheel={(e) => e.target.blur()}
          /** */
          onFocus={handleFocus}
          onClick={() => (disabled ? setOpen((prev) => !prev) : setOpen(true))} // because onBlur doesn't work for disabled field
          onBlur={handleBlur}
          classes={inputClasses}
          type="string"
          className={className}
          id={inputId}
          disableUnderline
          endAdornment={
            endIcon ? (
              <Adornment
                icon={endIcon}
                iconColor={iconColor}
                placement="end"
                onClick={iconClick}
                setTooltipOpen={setOpen}
                iconBackground={iconBackground}
              />
            ) : rightIcon ? (
              rightIcon
            ) : null
          }
          startAdornment={
            startIcon ? (
              <Adornment
                icon={startIcon}
                iconColor={iconColor}
                placement="start"
                onClick={iconClick}
                setTooltipOpen={setOpen}
                iconBackground={iconBackground}
              />
            ) : null
          }
          {...props}
        />
      </Tooltip>
    </FormControl>
  );
};
export default BaseTextInput;
