import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import check from 'assets/svg/check.svg';
import { colors, rem } from 'constants/design/index';
import { Theme } from 'infra/mui-stylesheet';

import Text, { TextProps } from '../text/Text';

const useCheckboxStyles = makeStyles<Theme, { variant: CheckboxProps['variant'] }>(() =>
  createStyles({
    checkbox: {
      color: colors.textInputBackground,
      paddingLeft: 0,
      '&:hover': {
        background: 'none !important', //kind of hack to remove the hover for both uncheck and checked box
      },
    },
    checkboxIcon: {
      borderRadius: 3,
      width: rem[1000],
      height: rem[1000],
      backgroundColor: (props) =>
        props.variant === 'contained' ? colors.textInputBackground : 'transparent',
      border: (props) => (props.variant === 'outlined' ? `1px solid ${colors.gray40}` : 'none'),
    },
    checkedIcon: {
      backgroundColor: (props) =>
        props.variant === 'contained' ? colors.textInputBackground : 'transparent',
      border: (props) => (props.variant === 'outlined' ? `1px solid ${colors.gray40}` : 'none'),
      '&:before': {
        display: 'block',
        width: rem[1000],
        height: rem[1000],
        backgroundImage: `url(${check})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        content: '""',
      },
    },
    labelRoot: {
      // there's weird marginLeft: -11px from MUI defaults
      marginLeft: 0,
    },
    label: {
      width: '100%',
    },
  }),
);

export interface CheckboxProps extends MuiCheckboxProps {
  name: string;
  onChange?: () => any;
  text: string;
  textSize?: TextProps['fontSize'];
  textProps?: TextProps;
  variant: 'contained' | 'outlined';
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    name = '',
    onChange = () => {},
    text,
    textSize,
    textProps,
    variant = 'contained',
    ...rest
  } = props;
  const classes = useCheckboxStyles({ variant });
  return (
    <FormControlLabel
      classes={{ root: classes.labelRoot }}
      control={
        <MuiCheckbox
          checkedIcon={<span className={cx(classes.checkboxIcon, classes.checkedIcon)} />}
          className={classes.checkbox}
          disableRipple
          icon={<span className={classes.checkboxIcon} />}
          name={name}
          onChange={onChange}
          {...rest}
        />
      }
      label={
        <div className={classes.label}>
          <Text fontWeight={400} color={colors.labelText} fontSize={textSize} italic {...textProps}>
            {text}
          </Text>
        </div>
      }
    />
  );
};

export default Checkbox;
