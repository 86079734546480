import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  const divStyles = {
    width: '100%',
    textAlign: 'center',
    borderBottom: ({ color }) => `1px solid ${color}`,
    lineHeight: '0.1em',
  };
  const spanStyles = {
    background: ({ textBackground }) => textBackground,
    padding: '0 10px',
    color: ({ color }) => color,
    fontFamily: 'brandon-grotesque',
    fontWeight: ({ fontWeight }) => fontWeight,
  };
  return {
    divStyles,
    spanStyles,
  };
});
