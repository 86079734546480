import { forwardRef, ReactNode } from 'react';

import { Maybe } from '@illume/shared';
import { motion } from 'framer-motion';

import { Box } from 'components/box';
import { Text } from 'components/illume/text';
import { colors } from 'constants/design';

import BaseTextInput, { BaseTextInputProps } from './BaseTextInput';

export interface TextInputProps extends BaseTextInputProps {
  errorMessage?: string;
  error?: Maybe<Partial<Error>>;
  border?: string;
  rightIcon?: ReactNode;
  errorTextProps?: any;
  compact?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ error, errorMessage, border, errorTextProps, compact, ...props }, ref) => {
    return (
      <>
        <BaseTextInput border={border} inputRef={ref} compact={compact} {...props} />
        {error && (
          <Box
            data-testid="input-error"
            component={motion.div}
            initial={{ y: 10 }}
            animate={{ y: 0 }}
            exit={{ y: 10 }}
            role="alert"
            mt={compact ? 0 : 1}
          >
            <Text {...errorTextProps} color={colors.alertRed}>
              {errorMessage || error.message || 'please provide a valid input'}
            </Text>
          </Box>
        )}
      </>
    );
  },
);

export default TextInput;
