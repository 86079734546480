import { createStyles, makeStyles } from '@material-ui/core/styles';

import { spacing } from 'constants/design';

export const useModalStyles = makeStyles(() => ({
  root: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const usePaperStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: ({ background }) => background,
      border: 'none',
      outline: 0,
      borderRadius: 12,
      marginLeft: spacing[4],
      marginRight: spacing[4],
      width: '100%',
      maxWidth: 512,
      position: 'relative',
      zIndex: theme.zIndex.modal,
      [theme.breakpoints.up('md')]: {
        maxWidth: 810,
      },
    },
  }),
);
