import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Box, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { generatePath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { match as patternMatch } from 'ts-pattern';

import { BaseIconButtonNew } from 'components/illume/buttons/BaseIconButton';
import { Close } from 'components/illume/icons';
import { Text } from 'components/illume/text';
import { NoteFormRawValues } from 'components/reusable-forms/note-form/NoteForm';
import { colors, DEFAULT_PALETTE, PRIMARY_THEME } from 'constants/design';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
// import { MY_CARDS_CREATE_NOTE_URL, MY_CARDS_DETAILS_URL } from 'constants/strings';
import { useAnalytics } from 'contexts/analytics/AnalyticsContext';
import { useServices } from 'contexts/services';
import { useStores } from 'contexts/store';
import { useIllumeHistory } from 'hooks/illume/useIllumeHistory';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import { ContributorCardDTO, InitiatorCardViewDTO, NoteDTO } from 'types';
import { noop, wait } from 'utils';
import logger from 'utils/logger';

import AddNote from '../add-note-routes/add-note';
import { AddNoteStore } from '../add-note-routes/AddNote.store';
import SelectTheme from '../add-note-routes/select-theme';

export const [IN_THE_WORKS, READY_TO_SEND] = ['IN_THE_WORKS', 'READY_TO_SEND'];

interface AddNoteRoutesProps {
  match: any;
  card: InitiatorCardViewDTO | ContributorCardDTO;
  redirectUrl: string;
  onNoteCreated?: (noteDto: NoteDTO) => void;
}

const MyCardsAddNoteRoutesView: FunctionComponent<AddNoteRoutesProps> = ({
  match,
  card,
  redirectUrl,
  onNoteCreated = noop,
}) => {
  const basePath = match.path;
  const { enqueueErrorSnackbar } = useIllumeSnackbar();
  const history = useIllumeHistory();
  const location = useLocation();
  const { themepackStore } = useStores();
  const [showSnackbar, setShowSnakcbar] = useState(false);
  const analytics = useAnalytics();
  const { noteService } = useServices();

  const defaultPalette = useMemo(() => {
    const paletteCode = new URLSearchParams(location.search).get('paletteCode');
    if (paletteCode) {
      return themepackStore.getPalette(paletteCode);
    }
  }, [location.search, themepackStore]);

  const [addNoteStore] = useState(
    () =>
      new AddNoteStore(
        themepackStore,
        defaultPalette
          ? {
              palette: defaultPalette.toDto(),
            }
          : {},
        noteService,
        card,
      ),
  );
  const inMemoryNoteData = addNoteStore.formData.note;
  console.log('inMemoryNoteData', inMemoryNoteData);

  const handleFinalSubmit = async ({ palette = DEFAULT_PALETTE, theme = PRIMARY_THEME }) => {
    addNoteStore.setPalette(palette, theme);

    return addNoteStore
      .syncNote()
      .then((noteDto) => {
        console.log('note created successfully');
        console.log('pushing to', match.url);
        onNoteCreated(noteDto);
        return history.push(redirectUrl);
      })
      .catch((e: any) => {
        enqueueErrorSnackbar(e?.message || 'something went wrong');
        logger.error(e);
      });
  };

  function handleSkip() {}

  useEffect(() => {
    wait(2000).then(() => setShowSnakcbar(true));
  }, []);

  function handleSubmitSuccess(note: NoteFormRawValues) {
    addNoteStore.patchNote(note);
    history.push(`${match.url}/theme`);
    logger.log(
      'note succsessfully validated, going to theme selection with url',
      `${match.url}/theme`,
    );
    // Warning: is this supposable a reusable route?
    // should not have initiator logic here
    analytics.track(EVENT_NAMES.SUBMIT_NOTE_INITIATOR.name);
  }
  return (
    <Switch>
      <Route exact path={basePath}>
        <AddNote
          defaultPalette={addNoteStore.formData.palette}
          card={card}
          note={inMemoryNoteData ? inMemoryNoteData : {}}
          onSubmitSuccess={handleSubmitSuccess}
          onBack={history.back}
        />

        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          open={showSnackbar}
          onClose={() => setShowSnakcbar(false)}
        >
          <Alert
            style={{ display: 'flex', alignItems: 'center' }}
            action={
              <Box pr={1}>
                <BaseIconButtonNew
                  icon={Close}
                  iconProps={{ color: colors.contrastText, size: 12 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    return setShowSnakcbar(false);
                  }}
                />
              </Box>
            }
            variant="filled"
            severity="info"
          >
            <Text fontWeight={400} fontSize={{ mobile: 14, desktop: 16 }}>
              <span style={{ marginRight: 4 }}>want to write your note later?</span>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handleSkip();
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                skip for now
              </a>
            </Text>
          </Alert>
        </Snackbar>
      </Route>
      <Route exact path={`${basePath}/theme`}>
        {inMemoryNoteData ? (
          <SelectTheme
            defaultPalette={addNoteStore.formData.palette}
            submitting={addNoteStore.syncTask.isExecuting}
            card={card}
            note={inMemoryNoteData}
            onSubmit={({ palette, theme }) => {
              analytics.track(
                patternMatch(card.userContext.role)
                  .with('Contributor', () => EVENT_NAMES.PICK_THEME_CONTRIBUTOR.name)
                  .with('Initiator', () => EVENT_NAMES.PICK_THEME_INITIATOR.name)
                  .exhaustive(),
                { themeType: palette.paletteCode },
              );
              handleFinalSubmit({ palette, theme });
              logger.warn('theme is not used', theme);
            }}
            onBack={() => {
              console.log('going back..');
              history.back();
            }}
          />
        ) : (
          <Redirect
            to={generatePath(basePath, {
              ...match.params,
            })}
          />
        )}
      </Route>
    </Switch>
  );
};

export const MyCardsAddNoteRoutes = observer(MyCardsAddNoteRoutesView);
