import React, { useCallback, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControlLabel,
  Grid,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FileUpload } from '@uploadcare/react-widget';
import { useMachine } from '@xstate/react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import get from 'lodash/get';
import { isFirefox, isIE } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';

import { GiphyModal } from 'components/giphy-modal';
import { BaseIconButton, LightBulbButton } from 'components/illume/buttons';
import { Checkbox } from 'components/illume/checkbox';
import IllumeCircularProgress from 'components/illume/circular-progress';
import { GifDisplay } from 'components/illume/gif-display';
import {
  GifUpload,
  ImageUpload,
  Info,
  QuestionMark,
  VideoUpload,
  Close,
} from 'components/illume/icons';
import { Modal } from 'components/illume/modal';
import { LeftArrowButton, RightArrowButton } from 'components/illume/nav-arrows';
import { PhotoUploader } from 'components/illume/photo-uploader';
import PhotoDisplay from 'components/illume/photos/PhotoDisplay';
import ProgressCircle from 'components/illume/progress-circles/ProgressCircles';
import { SuggestionModal } from 'components/illume/suggestion-modal';
import Text from 'components/illume/text/Text';
import Tooltip from 'components/illume/tooltip/Tooltip';
import VideoDisplay from 'components/illume/videos/VideoDisplay';
import { VideoRecorderModal } from 'components/video-recorder-modal';
import {
  colors,
  DEFAULT_PALETTE,
  generateContrastFromTheme,
  PRIMARY_THEME,
  rem,
} from 'constants/design';
import { generateContrastFromPalette } from 'constants/design/cardTheme';
import { EVENT_NAMES } from 'constants/event-names/EventNames';
import { LOW_SENTIMENT_SCORE } from 'constants/strings';
import { IAnalytics } from 'domain/interfaces/IAnalytics';
import { ISuggestionService } from 'domain/interfaces/ISuggestionService';
import { useIllumeSnackbar } from 'hooks/illume/useIllumeSnackbar';
import useClipPathID from 'hooks/useClipPathID';
import { IllumeGifDTO, PhotoDTO, VideoDTO, PaletteDTO, IllumeErrorDTO } from 'types';
import { noop } from 'utils';
import { checkFF, FLAGS } from 'utils/featureFlag';
import { isValid } from 'utils/form';
import logger from 'utils/logger';

import { inputStyles, Toggle, useStyles } from './NoteForm.styles';
import { uploadMachine } from './uploadMachine';
import { NoteFormFields, schema, VIDEO_MAX_DURATION } from './utils';

const MAX_NAME_COLS = 20;
const TOOLTIP_TEXT = 'not sure what to say? check out our inspiration library.';
const PHOTO_TOOLTIP_TEXT = 'add photo!';
const GIF_TOOLTIP_TEXT = 'add a GIF!';
const VIDEO_TOOLTIP_TEXT = 'add a video message!';

export interface NoteFormRawValues extends NoteFormFields {
  videos?: VideoDTO[];
  photos?: PhotoDTO[];
  gifs?: IllumeGifDTO[];
}

// ('allow the rest of the group to see your note, or keep it private.');
interface NoteFormProps {
  recipientName?: string;
  palette?: PaletteDTO;
  onBack?: () => any;
  defaultValues?: Partial<NoteFormRawValues>;
  ableToBack?: boolean;
  theme?: string;
  occasionName: string;
  onSubmitSuccess?: (note: NoteFormRawValues) => any;
  enableLightBulbTooltip?: boolean;
  draftValidationService: (forValues: NoteFormRawValues) => Promise<any>;
  analytics: IAnalytics;
  role: 'Initiator' | 'Contributor';
  suggestionService: Pick<ISuggestionService, 'getSuggestions'>;
}

export const NoteForm = ({
  role,
  recipientName,
  palette = DEFAULT_PALETTE,
  onBack,
  draftValidationService,
  defaultValues,
  ableToBack = true,
  theme = PRIMARY_THEME,
  onSubmitSuccess = () => null,
  occasionName = 'birthday', // default to birthday for now, just to make the suggestion available
  enableLightBulbTooltip = false,
  analytics,
  suggestionService,
}: NoteFormProps) => {
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar, enqueueWarningSnackbar } =
    useIllumeSnackbar();

  const alertErr = (_ctx: any, event: any) => {
    enqueueErrorSnackbar(event?.data?.message || "oops! there's an error");
  };

  const alertSuccess = (_ctx: any, event: any) => {
    enqueueSuccessSnackbar(event?.data?.message || 'successful action!');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ffmpegAlert = () => {
    enqueueWarningSnackbar(
      "oops! we're unable to process the video, uploaded video might be a little bigger in size",
    );
  };

  const [state, send] = useMachine(uploadMachine, {
    actions: { alertErr, alertSuccess, ffmpegAlert },
    context: {
      videos: defaultValues?.videos,
      photos: defaultValues?.photos,
      gifs: defaultValues?.gifs,
    },
  });

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showRecorder, setShowRecorder] = useState(false);
  const [showGiphyModal, setShowGiphyModal] = useState(false);
  const {
    data: suggestions,
    error: suggestionsError,
    isLoading: suggestionsLoading,
  } = useQuery<string[], Error>(['SUGGESTIONS', occasionName], () =>
    suggestionService.getSuggestions(occasionName),
  );

  const isTheme = useTheme();
  const desktop = useMediaQuery(isTheme.breakpoints.up('md'));
  const defaultPlaceholder = ` to start writing your note for ${recipientName}.`;
  const defaultCommandPlaceholder = desktop ? 'click here' : 'tap here';
  const [commandPlaceholder, setCommandPlaceholder] = useState(() => defaultCommandPlaceholder);
  const [placeholderText, setPlaceholderText] = useState(() => defaultPlaceholder);
  const widgetRef = useRef({ openDialog: () => {} });

  const [showSentimentModal, setShowSentimentModal] = useState(false);
  const [showVideoRecordNotice, setShowVideoRecordNotice] = useState(false);
  const LOW_SENTIMENT = 'LowSentiment';
  const [lightbulbHover, setLightbulbHover] = useState(false);
  const [toggleHover, setToggleHover] = useState(false);
  const [photoHover, setPhotoHover] = useState(false);
  const [videoHover, setVideoHover] = useState(false);
  const [gifHover, setGifHover] = useState(false);
  const videUploadInputRef = useRef<HTMLInputElement>(null);
  const [mediaDeletable, setMediaDeletable] = useState(true);
  const showToggle = checkFF('PUBLIC_TOGGLE_NOTE');
  const baseDefault: Partial<NoteFormRawValues> = { isPublic: false, anonymous: false };

  const { handleSubmit, setValue, register, control, errors, setError, watch, formState } =
    useForm<NoteFormFields>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: { ...baseDefault, ...defaultValues },
    });

  const { isDirty, isSubmitting } = formState;

  const disableSubmitButton = !isValid(errors) || !isDirty;

  const clearAnonymous = () => {
    setValue('anonymous', false, { shouldValidate: true, shouldDirty: true });
  };

  const onSelectSuggestion = (message: string) => {
    const currentText = watch('message');
    setValue('message', currentText.concat(message), { shouldValidate: true, shouldDirty: true });
  };

  const onCloseSuggestion = () => {
    setShowSuggestions(false);
  };
  const handleLightBulbClick = () => {
    setShowSuggestions(true);
  };

  const removePlaceholder = useCallback(() => {
    setPlaceholderText('');
    setCommandPlaceholder('');
  }, []);

  const resetPlaceholder = useCallback(() => {
    // Timeout to push action to next thread
    // When suggestion is clicked when no placeholder is present
    // Prevent UI jump
    const turnOffReset = setTimeout(() => {
      if (placeholderText === '') {
        setPlaceholderText(defaultPlaceholder);
        setCommandPlaceholder(defaultCommandPlaceholder);
      }
    }, 100);
    return () => {
      clearTimeout(turnOffReset);
    };
  }, [placeholderText, defaultPlaceholder, defaultCommandPlaceholder]);

  const handleUploadPhotoClick = () => {
    analytics.track(EVENT_NAMES.UPLOAD_PHOTO.name, { role });
    widgetRef?.current?.openDialog();
  };

  const handleMediaUpload = async (file: Blob) => {
    analytics.track(EVENT_NAMES.UPLOAD_VIDEO.name, { role });
    if (!file) throw Error('File does not exist');
    send('START_VIDEO_UPLOAD', { media: file });
  };

  const handleImageUpload = (file: FileUpload) => {
    if (!file) throw Error('File does not exist');
    send('START_IMAGE_UPLOAD', { media: file });
  };

  const handleVideoButtonClick = () => {
    if (desktop) {
      setShowRecorder(true);
    } else {
      setShowVideoRecordNotice(true);
    }
  };

  const onVideoModalConfirm = () => videUploadInputRef?.current?.click();

  // BAD, types should be inferred automatically if uploadMachine.js is typed correctly
  const { photos, videos, gifs } = state.context as {
    videos: VideoDTO[];
    gifs: IllumeGifDTO[];
    photos: PhotoDTO[];
  };

  const onNoteSubmit = async ({ message, signature, isPublic, anonymous }: NoteFormFields) => {
    const payload = {
      anonymous,
      gifs,
      isPublic,
      message,
      photos,
      signature,
      videos,
    };
    try {
      analytics.track(
        match(role)
          .with('Contributor', () => EVENT_NAMES.DRAFT_SUBMISSION_CONTRIBUTOR.name)
          .with('Initiator', () => EVENT_NAMES.DRAFT_SUBMISSION_INITIATOR.name)
          .exhaustive(),
        {
          noteLength: message.length,
        },
      );
      await draftValidationService(payload);
      onSubmitSuccess(payload);
    } catch (e) {
      const { errorCode, message } = e as IllumeErrorDTO;
      logger.log('cathed err: ', e, { errorCode, message });
      if (errorCode === 'NoteValidation') {
        setError('message', { message: LOW_SENTIMENT_SCORE });
      } else enqueueErrorSnackbar(message);
    }
  };

  const handleDeleteMedia = () => {
    send('CLEAR');
  };

  const videoPreviewSize = desktop ? 348 : 244;

  const handleGifUploadClick = () => {
    analytics.track(EVENT_NAMES.UPLOAD_GIF.name);
    return setShowGiphyModal(true);
  };

  useEffect(() => {
    // special handling to check anonymous on page load:
    if (defaultValues) {
      const { message, signature } = defaultValues;
      if (message && signature === '') {
        setValue('anonymous', true, { shouldValidate: true, shouldDirty: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const video = videos && videos[0];
  const photo = photos && photos[0];
  const gif = gifs && gifs[0];
  const mediaExists = video || photo || gif;
  const contrastColor = checkFF('NEW_CARD_THEMING')
    ? generateContrastFromPalette(palette, theme)
    : generateContrastFromTheme(theme);
  const modalsAreShown = showGiphyModal || showSuggestions || showSentimentModal;

  // force hide when there's modal shown
  const shouldShowLightBulbTooltip = !modalsAreShown && (enableLightBulbTooltip || lightbulbHover);
  const classes = useStyles();
  const inputClasses = inputStyles({ mediaExists });
  const [showVideoNotSupportedTooltip, setShowVideoNotSupportedTooltip] = useState(false);
  const isBrowserRestricted = isFirefox || isIE;
  const videoClipPathId = useClipPathID();
  const imageClipPathId = useClipPathID();

  return (
    <>
      <Grid container justifyContent="center" className={classes.container}>
        {['videoUpload', 'transcoding', 'imageUpload', 'validating', 'gifSave'].some(
          state.matches,
        ) && (
          <Grid item>
            <IllumeCircularProgress
              label={
                state.matches('videoUpload')
                  ? 'uploading..'
                  : state.matches('transcoding')
                  ? 'processing video, this may take a few minutes..'
                  : state.matches('validating')
                  ? 'validating..'
                  : state.matches('gifSave')
                  ? 'saving gif..'
                  : 'loading..'
              }
              variant={
                ['validating', 'gifSave'].some(state.matches) ? 'indeterminate' : 'determinate'
              }
              value={
                state.matches('videoUpload')
                  ? state.context.uploadProgress
                  : state.matches('transcoding')
                  ? state.context.transcodeProgress
                  : undefined
              }
              size={64}
              color={contrastColor}
            />
          </Grid>
        )}

        {state.matches('idle') &&
          (mediaExists ? (
            <Grid container justifyContent="center" className={classes.avatar}>
              <Grid item>
                <Box className="fs-mask" position="relative">
                  {mediaDeletable && (
                    <Box position="absolute" right={0} top={-40}>
                      <motion.div
                        whileTap={{ scale: 0.8 }}
                        onClick={handleDeleteMedia}
                        style={{ cursor: 'pointer' }}
                      >
                        <Close hasBlob />
                      </motion.div>
                    </Box>
                  )}
                  {/* TODO: Find out why these APIs return different shapes for photos */}
                  {photo ? (
                    <div className={classes.imageWrapper}>
                      <PhotoDisplay
                        imgUrl={photo.url || photo.deliveryUrl}
                        size={desktop ? 348 : 244}
                        color={contrastColor}
                        clipPathID={imageClipPathId}
                      />
                    </div>
                  ) : video ? (
                    <VideoDisplay
                      vidUrl={video.url || video.deliveryUrl}
                      // DEBT: somehow the svg blob breaks below 300
                      size={videoPreviewSize}
                      color={contrastColor}
                      onPreviewPlay={() => setMediaDeletable(false)}
                      onClose={() => setMediaDeletable(true)}
                      clipPathId={videoClipPathId}
                    />
                  ) : gif ? (
                    <GifDisplay giphyCode={gif.giphyCode} size={300} color={contrastColor} />
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={3}
              direction={desktop ? 'row' : 'column'}
              className={classes.mediaButtons}
            >
              <Grid
                item
                className={classes.addMedia}
                onMouseEnter={() => setPhotoHover(true)}
                onMouseLeave={() => setPhotoHover(false)}
              >
                <Grid item className={classes.button}>
                  <Tooltip
                    text={PHOTO_TOOLTIP_TEXT}
                    variant="other"
                    widthArrow={'10%'}
                    placement="bottom-start"
                    showTooltip={photoHover && !modalsAreShown}
                    size="small"
                  >
                    <div>
                      <BaseIconButton
                        size={desktop ? 36 : 28}
                        onClick={handleUploadPhotoClick}
                        icon={ImageUpload}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Text
                    onClick={handleUploadPhotoClick}
                    color={colors.labelText}
                    fontSize={{ desktop: rem[1500], mobile: rem[1125] }}
                  >
                    add photo
                  </Text>
                </Grid>
                <PhotoUploader
                  style={{ display: 'none' }}
                  ref={widgetRef}
                  beforeFileSelect={() => {}}
                  onFileSelect={handleImageUpload}
                  onError={(message) => {
                    enqueueErrorSnackbar(message);
                  }}
                />
              </Grid>
              {checkFF('VIDEO_UPLOAD') && (
                <Grid
                  item
                  className={classes.addMedia}
                  onMouseEnter={() =>
                    isBrowserRestricted
                      ? setShowVideoNotSupportedTooltip(true)
                      : setVideoHover(true)
                  }
                  onMouseLeave={() =>
                    isBrowserRestricted
                      ? setShowVideoNotSupportedTooltip(false)
                      : setVideoHover(false)
                  }
                >
                  <Grid item className={classes.button}>
                    <Tooltip
                      text={
                        isBrowserRestricted
                          ? 'Firefox and IE support coming soon! try to use in Safari or Chrome'
                          : VIDEO_TOOLTIP_TEXT
                      }
                      variant="other"
                      widthArrow={'10%'}
                      placement="bottom-start"
                      showTooltip={
                        (videoHover && !modalsAreShown && !isBrowserRestricted) ||
                        (isBrowserRestricted && showVideoNotSupportedTooltip)
                      }
                    >
                      <div>
                        <BaseIconButton
                          disabled={isBrowserRestricted}
                          size={desktop ? 36 : 28}
                          onClick={handleVideoButtonClick}
                          icon={VideoUpload}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Text
                      onClick={isBrowserRestricted ? noop : handleVideoButtonClick}
                      color={colors.labelText}
                      fontSize={{ desktop: rem[1500], mobile: rem[1125] }}
                    >
                      add video
                    </Text>
                  </Grid>
                  <input
                    ref={videUploadInputRef}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) => handleMediaUpload(get(e, ['target', 'files', 0]) as Blob)}
                    // capture using OS's video recorder
                    accept="video/*"
                    capture="user"
                  />
                </Grid>
              )}
              {checkFF(FLAGS.GIPHY) && (
                <Grid
                  item
                  className={classes.addMedia}
                  onMouseEnter={() => setGifHover(true)}
                  onMouseLeave={() => setGifHover(false)}
                >
                  <Grid item className={classes.button}>
                    <Tooltip
                      text={GIF_TOOLTIP_TEXT}
                      variant="other"
                      widthArrow={'10%'}
                      placement="bottom-start"
                      showTooltip={gifHover && !modalsAreShown}
                    >
                      <div>
                        <BaseIconButton
                          size={desktop ? 36 : 28}
                          onClick={handleGifUploadClick}
                          icon={GifUpload}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Text
                      onClick={handleGifUploadClick}
                      color={colors.labelText}
                      fontSize={{ desktop: rem[1500], mobile: rem[1125] }}
                    >
                      add gif
                    </Text>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}

        <form style={{ width: '100%' }}>
          <Grid container direction="row">
            <Grid item className={inputClasses.container} xs={12}>
              <Typography className={cx(inputClasses.ghost, 'fs-mask')} component="div">
                {watch('message') ? (
                  watch('message')
                ) : (
                  <span>
                    <span>{commandPlaceholder}</span> {placeholderText}
                  </span>
                )}
                <div
                  className={classes.lightIcon}
                  onMouseEnter={() => setLightbulbHover(true)}
                  onMouseLeave={() => setLightbulbHover(false)}
                >
                  <Tooltip
                    text={TOOLTIP_TEXT}
                    variant="other"
                    widthArrow={'5%'}
                    placement="bottom"
                    showTooltip={shouldShowLightBulbTooltip}
                  >
                    <LightBulbButton
                      // used to be a wrong props name
                      // fixing this issue might introduce bugs
                      containerClassName={classes.bulb}
                      onClick={handleLightBulbClick}
                      size={desktop ? 44 : 32}
                    />
                  </Tooltip>
                </div>
              </Typography>
              <TextareaAutosize
                className={cx(inputClasses.root, 'fs-mask')}
                onFocus={removePlaceholder}
                onBlur={resetPlaceholder}
                name="message"
                data-testid="message"
                ref={register}
              />
            </Grid>
          </Grid>
          <Grid item>
            {errors.message && (
              <Text color={colors.errorText}>
                {errors.message.message}{' '}
                {errors.message.type === LOW_SENTIMENT && (
                  /* override tailwind's default styling on svg */
                  <Info
                    onClick={() => setShowSentimentModal(true)}
                    style={{ display: 'inline', marginBottom: 2 }}
                    color={colors.errorText}
                  />
                )}
              </Text>
            )}
          </Grid>
          {showToggle && (
            <Grid item>
              <Controller
                name="isPublic"
                control={control}
                render={(props) => {
                  const checked = props.value;
                  return (
                    <FormControlLabel
                      control={
                        <Toggle
                          checked={checked || false} // to prevent uncontrolled input errors
                          onChange={(e) => props.onChange(e.target.checked)}
                          data-testid="toggle"
                        />
                      }
                      label={
                        <Grid
                          container
                          alignItems="center"
                          direction="row"
                          onMouseEnter={() => setToggleHover(true)}
                          onMouseLeave={() => setToggleHover(false)}
                        >
                          <Grid item>
                            <Text color={colors.labelText} fontSize={rem[1000]} italic>
                              public note&nbsp;
                            </Text>
                          </Grid>
                          <Tooltip
                            text={
                              <React.Fragment>
                                <p>
                                  allow the rest of the group to see your note, or keep it private.
                                </p>
                                <br />
                                <p>
                                  <span style={{ opacity: '25%', fontWeight: 900 }}>
                                    public note:{' '}
                                  </span>
                                  <span
                                    style={{
                                      opacity: !checked ? '40%' : undefined,
                                      fontWeight: 900,
                                      color: checked && colors.primary,
                                    }}
                                  >
                                    {checked ? 'ON' : 'OFF'}
                                  </span>
                                </p>
                              </React.Fragment>
                            }
                            variant="other"
                            widthArrow={'7%'}
                            placement="bottom"
                            showTooltip={toggleHover}
                            maxWidth="200px"
                          >
                            <Grid item>
                              <QuestionMark />
                            </Grid>
                          </Tooltip>
                        </Grid>
                      }
                    />
                  );
                }}
              />
            </Grid>
          )}

          <Grid container className={classes.footer} direction="column">
            <Grid item>
              <TextareaAutosize
                cols={MAX_NAME_COLS}
                className={inputClasses.name}
                onFocus={clearAnonymous}
                placeholder="sign your name here."
                name="signature"
                ref={register}
                data-testid="signature"
              />
            </Grid>
            <Grid item>
              {errors.signature && <Text color={colors.errorText}>{errors.signature.message}</Text>}
            </Grid>
            <Grid container alignItems="center">
              <Controller
                name="anonymous"
                control={control}
                render={({ name, onChange, value }) => {
                  return (
                    <Checkbox
                      name={name}
                      onChange={() => {
                        // reset signature value on change
                        setValue('signature', '');
                        return onChange(!value);
                      }}
                      checked={value}
                      text="Want to remain anonymous? No problem."
                    />
                  );
                }}
              />
            </Grid>
            <Grid container justifyContent="center" className={classes.navArrows}>
              {ableToBack && (
                <Grid item className={classes.arrowLeft}>
                  <LeftArrowButton onClick={onBack} color={colors.contrastText} inverted />
                </Grid>
              )}
              <Grid
                item
                onClick={handleSubmit(onNoteSubmit)}
                className={classes.arrowRight}
                data-testid="arrow"
              >
                <RightArrowButton
                  disabled={disableSubmitButton}
                  loading={isSubmitting}
                  loadingColor={contrastColor}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.progressCircle}>
              <ProgressCircle step={1} />
            </Grid>
            {!disableSubmitButton && (
              <Grid container justifyContent="center" className={classes.busyText}>
                <Text fontSize={rem[750]} italic color={colors.labelText}>
                  continue to pick a theme for your note
                </Text>
              </Grid>
            )}
          </Grid>
        </form>
        {/* step 1 footer */}
      </Grid>
      {suggestions && (
        <SuggestionModal
          suggestions={suggestions}
          error={suggestionsError}
          loading={suggestionsLoading}
          onSelect={onSelectSuggestion}
          onClose={onCloseSuggestion}
          show={showSuggestions}
        />
      )}
      <Modal
        show={showSentimentModal}
        title="WE FILTER OUT HATE"
        onClose={() => setShowSentimentModal(false)}
        promptConfig={{
          onOk: () => setShowSentimentModal(false),
          okText: 'close',
        }}
      >
        <Box mb={3}>
          <Text align="center" fontWeight={300}>
            We don't tolerate hate on illume. Our sentiment analysis tries to strike a balance that
            facilitates authenticity while protecting recipients from hate. It's not perfect and
            we're always trying to get better.
          </Text>
        </Box>
        <Text align="center" fontWeight={300}>
          Thank you.
        </Text>
      </Modal>
      <VideoRecorderModal
        theme={theme}
        palette={palette}
        onFinished={(blob: Blob) => {
          setShowRecorder(false);
          handleMediaUpload(blob);
        }}
        open={showRecorder}
        onClose={() => setShowRecorder(false)}
      />
      <GiphyModal
        theme={theme}
        palette={palette}
        open={showGiphyModal}
        onClose={() => setShowGiphyModal(false)}
        onSelectGif={(gif) => send('SAVE_GIF', { media: gif })}
      />
      <Modal
        show={showVideoRecordNotice}
        title="recording notice!"
        onClose={() => setShowVideoRecordNotice(false)}
        promptConfig={{
          onOk: () => {
            onVideoModalConfirm();
            setShowVideoRecordNotice(false);
          },
          okText: 'record!',
          cancelText: 'cancel',
          onCancel: () => {
            setShowVideoRecordNotice(false);
          },
        }}
      >
        <Box mb={3}>
          <Text align="center" fontWeight={300}>
            You can only record up to <b>{VIDEO_MAX_DURATION} seconds</b>, otherwise you'll need to
            retry the recording session.
          </Text>
        </Box>
        <Text align="center" fontWeight={300}>
          Are you ready?
        </Text>
      </Modal>
    </>
  );
};
