const RecorderPause = () => (
  <svg width="86" height="84" viewBox="0 0 86 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#pause-button)">
      <path
        d="M0.518134 30.969C0.135492 23.2303 5.83441 14.9891 15.1562 8.73329C25.1049 2.05608 36.1363 -0.642359 48.0227 0.127465C54.8776 0.565049 61.3337 2.42883 66.7558 6.91002C70.281 9.81914 73.0083 13.3927 75.3612 17.2824C79.5132 24.1379 82.9896 31.2851 84.9516 39.1049C87.6708 49.931 85.0167 59.4849 78.0803 67.9854C70.8102 76.8911 61.1464 81.4128 49.887 82.9038C42.0144 83.9491 34.3453 83.1226 26.896 80.3674C17.8021 77.0045 11.2728 70.7892 7.16144 62.151C3.05822 53.5451 0.819362 44.445 0.518134 30.969Z"
        fill="#FFFDF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0229 42.043C17.0229 27.6584 28.6585 16.0229 43.0431 16.0229C57.4277 16.0229 69.1271 27.6584 69.1271 42.043C69.1271 56.4276 57.4277 68.1271 43.0431 68.1271C28.6585 68.1271 17.0229 56.4276 17.0229 42.043ZM34.0287 52.2081V31.9418C34.0287 31.0468 34.732 30.4075 35.5631 30.4075H39.3351C40.1662 30.4075 40.8694 31.0468 40.8694 31.9418V52.2081C40.8694 53.0392 40.1662 53.7425 39.3351 53.7425H35.5631C34.732 53.7425 34.0287 53.0392 34.0287 52.2081ZM45.2807 52.2081V31.9418C45.2807 31.0468 45.9839 30.4075 46.815 30.4075H50.5231C51.4181 30.4075 52.0574 31.0468 52.0574 31.9418V52.2081C52.0574 53.0392 51.4181 53.7425 50.5231 53.7425H46.815C45.9839 53.7425 45.2807 53.0392 45.2807 52.2081Z"
        fill="#FEB81C"
      />
    </g>
    <defs>
      <clipPath id="pause-button">
        <rect width="85.5" height="83.3354" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RecorderPause;
