import base from './base.config';
import { DeepPartial } from './type';

const env: DeepPartial<typeof base> = {
  mockEnabled: false,
  testMessage: 'Greetings from local!',
  env: 'local',
  // baseURL_new: 'http://localhost:8080/api',
  baseURL_new: 'https://web.dev.illumenotes.com/api',
  stripePublicKey: 'pk_test_taQJ10KWtWGHWa1q04YFugQi00xZtJnU0O',
  segmentWriteKey: 'GJt9C7sMOhhdPRdc40LNAIDFwjPhbqrZ',
  FF: {
    APPLE_PAY: true,
    EXPERIMENTS: true,
    SAVE_THE_DATE: true,
    TEAMS_BATCH_CREATION: true,
  },
  gtm: {
    auth: 'DAui4H3SKKYyU78fgF7F4A',
    preview: 'env-24',
  },
  sentry: {
    dsn: '',
  },
  uploadCareId: 'c792ee9f561b94b89683',
};

export default env;
